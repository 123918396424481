import styled from "styled-components";

const ExitIconWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 100px;
  padding: 0.5265rem;
  z-index: 1;
  ${({ absolute }) =>
  absolute ?
  `position: absolute;
   top: 1rem;
   right: 1rem;`
   :
   ``}
`;

interface ExitIconProps {
  absolute?: boolean;
  color: string;
  bgColor: string;
  onClick: () => void;
}

function ExitIconButton({ absolute, color, bgColor, onClick }: ExitIconProps) {
  return(
    <ExitIconWrapper
      style={{ backgroundColor: bgColor }}
      onClick={onClick}
      absolute={absolute}
    >
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.15385 15L0 13.8462L6.34615 7.5L0 1.15385L1.15385 0L7.5 6.34615L13.8462 0L15 1.15385L8.65385 7.5L15 13.8462L13.8462 15L7.5 8.65385L1.15385 15Z" fill={color}/>
      </svg>
    </ExitIconWrapper>
  );
}

export default ExitIconButton;
