import { AmazonHelpers } from "../../../../amazon";
import { useContext, useMemo } from "react";
import { FlexRow, GraphCenter, GraphWindow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, PanelText, PanelBlurb, PanelBlurbHeroText } from "../../styles/generics";
import { usd } from "./utils";

import AmazonContext from "components/Contexts/AmazonContext";
import SpendingForecastLineChart from "./charts/SpendingForecastLineChart";
import styled from "styled-components";
import ViewAllArrow from "./buttons/ViewAllArrow";
import { useNavigate } from "react-router-dom";

const SpendingPanelText = styled(PanelText)`
  padding-top: 20px;
`;

const SpendPrice = styled(PanelBlurbHeroText)`
`;

const SpendText = styled(PanelText)`
`;

const PanelSpan = styled.span`
  font-weight: 700;
`;

function SpendingForecastPanel() {
  // TODO hoist these calculations into amazon API and add affirming unit tests
  // because this is super easy to mess up
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const { labels, spending, trend, year } = useMemo(() => {
    const labels: number[] = [];
    const yearData = AmazonHelpers.SplitOrdersByDay(amazonHandler.getOrders());
    const year = new Date().getFullYear();
    const trend: { thisYear: number[], lastYear: number[] } = { thisYear: [], lastYear: [] };

    const spending = { thisYear: 0, lastYear: 0};

    if (!yearData[year - 1]) {
      return {
        labels,
        spending,
        trend,
        undefined,
      }
    }
    // if there's no data in the current year, create empty year
    if (!yearData[year]) {
      yearData[year] = [];
    }

    for (let i = 0; i < 365; i ++) {
      labels.push(i);

      if(yearData[year]) {
        if (yearData[year][i]) {
          spending.thisYear += AmazonHelpers.CalculateSpending(yearData[year][i]).totalSpending;
        }
        trend.thisYear.push(spending.thisYear);
      }

      if (yearData[year - 1][i]) {
        spending.lastYear += AmazonHelpers.CalculateSpending(yearData[year - 1][i]).totalSpending;
      }
      trend.lastYear.push(spending.lastYear);
    }

    // Leap Year edge case.
    if(yearData[year]) {
      if (yearData[year][365]) {
        spending.thisYear += AmazonHelpers.CalculateSpending(yearData[year][365]).totalSpending;
      }
    }
    if (yearData[year - 1][365]) {
      spending.lastYear += AmazonHelpers.CalculateSpending(yearData[year - 1][365]).totalSpending;
    }
    trend.thisYear.push(spending.thisYear);
    trend.lastYear.push(spending.lastYear);

    // Extrapolate data using linear regression
    // with special handling where we don't have data in either year
    const dayOfYear = AmazonHelpers.dayOfYear(new Date());
    const currentYearSlope = trend.thisYear[dayOfYear] / dayOfYear;
    let factor = 0; // how much to boost or dampen the delta
    if (trend.thisYear[dayOfYear] > 0) {
      factor = Number(trend.thisYear[dayOfYear]) / Number(trend.lastYear[dayOfYear]);
    } else {
      // no purchases so far this year, so dampen the line by the number of days
      factor = 1.0 - dayOfYear / 365.0;
    }

    trend.thisYear.forEach((n, i) => {
      if (i > dayOfYear) {
        if (trend.lastYear[i] > 0) {
          // y = b + mx
          trend.thisYear[i] =  trend.thisYear[i-1] + factor * (trend.lastYear[i] - trend.lastYear[i-1]);
        } else {
          // no purchases last year up until this point,
          // so only use the slope of the current year
          trend.thisYear[i] = i * currentYearSlope;
        }
        spending.thisYear = trend.thisYear[i];
      }
    });

    // fake the dashed lines
    trend.thisYear.forEach((n, i) => {
      if (i > dayOfYear && i % 8 < 4) {
        //@ts-ignore
        trend.thisYear[i] = undefined;
      }
    });

    return {
      labels,
      trend,
      spending,
      year,
    };
  }, [amazonHandler]);

  const { delta, negative, percentage } = useMemo(() => {
    const delta = spending.thisYear - spending.lastYear;
    const negative = delta < 0;
    const denom = spending.lastYear === 0 ? Infinity : spending.lastYear;
    const percentage = Math.round(Math.abs(delta) * 100 / denom);

    return { delta, negative, percentage };
  }, [spending.lastYear, spending.thisYear]);

  const thisYear = year === undefined ? new Date().getFullYear() : year;
  const lastYear = thisYear - 1;

  return <PanelWrapper>
    <FlexRow>
      <Left style={{ display: 'flex', alignItems: 'center' }}>
        <PanelTitle>
          Annual spending comparison
        </PanelTitle>
      </Left>
      <Right onClick={() => navigate(`/spending/by-trend`)}>
        <ViewAllArrow />
      </Right>
    </FlexRow>
    <SpendingPanelText>
      Based on your spending last year,
      you will spend {
        negative ?
          <span><PanelSpan>{usd.format(Math.abs(delta))}</PanelSpan> less</span>
          :
          <span>an additional <PanelSpan>{usd.format(delta)}</PanelSpan></span>
      } this year compared to last year.  Spending will be
      {
        negative ? ' down ' : ' up '
      }
      {
        negative ?
        <PanelSpan>↓ {percentage}%</PanelSpan>
        :
        <PanelSpan>↑ {percentage}%</PanelSpan>
      }.
    </SpendingPanelText>
    <GraphWindow height={'150px'}>
      <GraphCenter height={'150px'}>
        <SpendingForecastLineChart
          labels={labels}
          thisYear={trend.thisYear.slice(0, 365)}
          lastYear={trend.lastYear.slice(0, 365)}
        />
      </GraphCenter>
    </GraphWindow>
    <div style={{ display: 'flex', marginTop: 10 }}>
      <PanelBlurb>
        <SpendPrice color={'#5ECAD9'}>
          {usd.format(spending.lastYear)}
        </SpendPrice>
        <SpendText>
          {lastYear} Spent
        </SpendText>
      </PanelBlurb>
      <PanelBlurb>
        <SpendPrice color={'#6B6BD4'}>
          {usd.format(spending.thisYear)}
        </SpendPrice>
        <SpendText>
          {thisYear} Predicted
        </SpendText>
      </PanelBlurb>
    </div>
  </PanelWrapper>
}

export default SpendingForecastPanel;
