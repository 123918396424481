import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import SwipeToClosePanel from "../../styles/SwipeToClosePanel";
import { PanelWrapper, PanelWidthColoredButton, SmallText } from "../../styles/generics";

import ExitIconButton from "../../../../icons/dynamic-icons/ExitIconButton";
import Uskissa from "../../../../images/uskissa_share_image.svg";

const SharePanelWrapper = styled(PanelWrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 2rem);
  gap: 1rem;
  background: linear-gradient(100deg, #9D7DE9, #6890FA);
  color: #FFFFFF;
  margin: 0 1rem;
`;
const ShareTopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
const SharePanelImg = styled.img`
  width: 9rem;
`;
const SharePanelText = styled(SmallText)`
  width: 6.5rem;
  text-align: left;
`;
const SharePanelButton = styled(PanelWidthColoredButton)`
  background: none;
  border: 0.5px solid #FFFFFF;
`;

interface SharePanelProps{
  showSharePanel: boolean;
  setShowSharePanel: (b: boolean) => void;
}

function SharePanel({ showSharePanel, setShowSharePanel }: SharePanelProps ){
  const navigate = useNavigate();

  return(
    <>
      {showSharePanel ?
          <SwipeToClosePanel
            showPanel={showSharePanel}
            setShowPanel={setShowSharePanel}
          >
            <SharePanelWrapper>
              <ExitIconButton
                absolute={true}
                color={'#FFFFFF'}
                bgColor={'#B9A6FD'}
                onClick={() => setShowSharePanel(false)}
              />
              <ShareTopRow>
                <SharePanelImg src={Uskissa} />
                <SharePanelText>
                  Love these stats? Share them with your friends!
                </SharePanelText>
              </ShareTopRow>
              <SharePanelButton onClick={() => navigate("/share")}>
                Choose what to share
              </SharePanelButton>
            </SharePanelWrapper>
          </SwipeToClosePanel>
          :
          null}
    </>
  );
}

export default SharePanel;
