import { AmazonHandler, AmazonHelpers } from "amazon";
import AmazonContext from "components/Contexts/AmazonContext";
import { useContext, useMemo } from "react";
import styled from "styled-components";
import { PanelWrapper, PanelTitle, SmallText } from "../../styles/generics";

import CreditCardIcon from './icons/credit-card.svg';
import DollarSignIcon from './icons/dollar-sign.svg';
import ShoppingBagIcon from './icons/shopping-bag.svg';
import ShoppingTagIcon from './icons/shopping-tag.svg';

const PurchasesTitle = styled(PanelTitle)`
  padding: 1rem 1rem 0 1rem;
`;

const SummaryPanelWrapper = styled(PanelWrapper)`
  padding: 0;
`;

const SummaryItemWrapper = styled.div`
  background: #F9F9FF;
  border-radius: 15px;

  flex-grow: 1;
  width: 50%;
  max-width: 50%;

  padding: 8px;

  border: 8px solid #ffffffff;
`;

const SummaryItemImg = styled.img`
  max-height: 50px;
  max-width: 130px;

  margin-bottom: 9px;
`;

const SummaryItemText = styled(SmallText)`
line-height: 17px;
`;

const SummaryItemTitle = styled(SummaryItemText)`
font-weight: 700;
`;

type SummaryPanelProps = {
  img: string,
  title: string,
  text: string
}

function SummaryPanel({img, title, text}: SummaryPanelProps) {
  return <SummaryItemWrapper>
    <SummaryItemImg src={img} alt="Summary Icon" />
    <SummaryItemTitle>{title}</SummaryItemTitle>
    <SummaryItemText>{text}</SummaryItemText>
  </SummaryItemWrapper>
}

function PurchaseSummaryPanel() {
  const { amazonHandler } = useContext(AmazonContext);
  // Get last year data.
  const yearHandler = useMemo(() => {
    const today = new Date();
    const yearAgo = new Date();

    yearAgo.setDate(today.getDate() - 365);

    return amazonHandler.sliceDateRange(yearAgo, today);
  }, [amazonHandler]);

  const monthlySpending = useMemo(() => {
    return AmazonHelpers.SplitOrdersByMonth(yearHandler.getOrders() || [])
      .map((data) => AmazonHandler.loadAmazonHandler(data))
      .map((h) => h.getTotalSpending());
  }, [yearHandler]);

  const {
    avg_spent,
    total_purchases,
    total_spent,
    avg_item_cost
  } = useMemo(() => {
    const total_spent = monthlySpending.reduce((acc, ms) => (acc + ms.totalSpending), 0);
    const total_purchases = monthlySpending.reduce((acc, ms) => (acc + ms.orders), 0);
    const avg_spent = total_spent / 12;
    const avg_item_cost = total_purchases <= 0 ? 0 : total_spent / total_purchases;
    return { total_spent, total_purchases, avg_spent, avg_item_cost };
  }, [monthlySpending]);

  return <SummaryPanelWrapper>
    <PurchasesTitle>Purchase Summary: Last 365 Days</PurchasesTitle>
    <div
      style={{ display: 'flex', padding: 8, flexWrap: 'wrap'}}
    >
      <SummaryPanel
        img={DollarSignIcon}
        title={AmazonHelpers.priceFormat(avg_spent || 0)}
        text={"Average spent per month"}
      />
      <SummaryPanel
        img={ShoppingBagIcon}
        title={AmazonHelpers.numberFormat(total_purchases || 0)}
        text={"Total items purchased"}
      />
      <SummaryPanel
        img={CreditCardIcon}
        title={AmazonHelpers.priceFormat(total_spent || 0)}
        text={`Total spent in last 365 days`}
      />
      <SummaryPanel
        img={ShoppingTagIcon}
        title={AmazonHelpers.priceFormat(avg_item_cost || 0)}
        text={"Average cost per item"}
      />
    </div>
  </SummaryPanelWrapper>
}

export default PurchaseSummaryPanel;
