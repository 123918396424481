export type LinkedList<E> = {
  name: string;
  value: E;
  next?: LinkedList<E>;
};

export function add<E>(name: string, value: E, next?: LinkedList<E>): LinkedList<E> {
  return { name, value, next };
}

export function find<E>(name: string, lst?: LinkedList<E>): E | undefined {
  while (lst) {
    if (lst.name === name) return lst.value;
    lst = lst.next;
  }
  return undefined;
}
