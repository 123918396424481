import { useContext, useMemo, useState, useEffect } from "react";
import { UskoCategory } from "../../../../amazon";
import { DisableContentsContainer, FlexRow, GraphCenter, GraphWindow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, PanelText } from "../../styles/generics";
import AmazonContext from "components/Contexts/AmazonContext";
import LoadingDoughnutChart from "./charts/LoadingDoughnutChart";
import SpendingDoughnutChart from "./charts/SpendingDoughnutChart";
import styled from "styled-components";
import LoadingIcon from "../panels/icons/LoadingIcon";
import ViewAllArrow from "./buttons/ViewAllArrow";
import SpendingTableChart from "./charts/SpendingTableChart";
import { useNavigate } from "react-router-dom";

const SpendingWrapper = styled(PanelWrapper)`
  position: relative;
`;

const SpendingTitle = styled(PanelTitle)`
  padding-right: 0.625rem;
  appearance: none;
`;

const YearlyExpendituresSelect = styled.select`
  -webkit-appearance: auto;
  -moz-appearance: auto;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.36125rem;
  border: none;
  background-color: white;
  color: #4D4C4C;
`;

const LoadingModalWrapper = styled.div`
  position: absolute;
  top: 5rem;
  left: 0;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0);
  padding-top: 4.5rem;
  z-index: 1;
`;

const LoadingModalMessage = styled(PanelText)`
  text-align: center;
  background-color: white;
  width: 92.5%;
  border-radius: 0.5rem;
  box-shadow: 0px -4px 14px rgba(138, 138, 138, 0.25);
  padding: 0.5rem;
  z-index: 1;
`;

const LoadingTableChart = styled.div`
  display: flex;
  margin-top: 1.1875rem;
  flex-direction: column;
`;

const LoadingTableChartRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.125rem;
`;

const LoadingTableChartLeft = styled.div`
  background-color: #F7F6FF;
  height: 1.3125rem;
  width: 11.5625rem;
  border-radius: 6.25rem;
`;

const LoadingTableChartRight = styled.div`
  outilne: 2px solid blue;
  background-color: #F7F6FF;
  height: 1.3125rem;
  width: 4.125rem;
  border-radius: 6.25rem;
`;

const num_cat = 4;

function YearlyExpenditurePanel() {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const [year, setYear] = useState(new Date().getFullYear());
  const [userSetYear, setUserSetYear] = useState(false);
  const years = useMemo(() => amazonHandler.getYears().reverse(), [amazonHandler]);

  function selectYear (e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setYear(parseInt(value));
    setUserSetYear(true);
  }

  const yearHandler = useMemo(() =>
    amazonHandler.sliceDateRange(
      new Date(year, 0, 1),
      new Date(year + 1, 0, 0)
    )
  , [amazonHandler, year]);

  useEffect(() => {
    // only set the year if the user hasn't already set it
    // because we don't want to override that
    if(!userSetYear && years.length > 0) setYear(years[0]);
  }, [years, userSetYear]);

  const category_data = useMemo(() => yearHandler.getCategoriesSpendingTableProps(), [yearHandler]);

  const { isLoading, labels, tableData } = useMemo(() => {
    const isLoading = !category_data || category_data.size <= 0 || (category_data.size === 1 && category_data.get(UskoCategory.Processing));
    const sorted = [...category_data.entries()].sort((a, b) => {
      return b[1] - a[1];
    });

    let labels: UskoCategory[] = [];
    let tableData: number[] = [];
    let count = 0;
    sorted.forEach((s) => {
      if (count < num_cat) {
        labels.push(s[0]);
        tableData.push(s[1]);
      }
      else if(count === num_cat) {
        labels.push(UskoCategory.Other);
        tableData.push(s[1]);
      }
      else {
        tableData[num_cat] += s[1];
      }
      count += 1;
    });

    return { isLoading, labels, tableData };
  }, [category_data]);

  const loadingTableChartRows = Array(5).fill('').map((r,i) => {
    return(
      <LoadingTableChartRow key={i} >
        <LoadingTableChartLeft />
        <LoadingTableChartRight />
      </LoadingTableChartRow>
    );
  });

  return <SpendingWrapper>
    <FlexRow>
      <Left style={{ display: 'flex', alignItems: 'center'}}>
        <SpendingTitle>Categories:</SpendingTitle>
        <YearlyExpendituresSelect
          value={year}
          onChange={selectYear}
          name="purchase-breakdown-month"
          id="purchase-breakdown-month"
        >
          {years.map((y) => <option value={y} key={y}>{y}</option>)}
        </YearlyExpendituresSelect>
      </Left>
      <Right onClick={isLoading ? undefined : () => navigate(`/spending/by-category/yearly`)}>
        {isLoading ? <LoadingIcon size={20} thickness={3} color={'#9090df'}/>  : <ViewAllArrow />}
      </Right>
    </FlexRow>
    {isLoading ?
      <LoadingModalWrapper>
        <LoadingModalMessage>
          We are categorizing each item, which might take some time.
        </LoadingModalMessage>
      </LoadingModalWrapper>
      :
      null}
    <DisableContentsContainer>
      <GraphWindow height={'225px'}>
        <GraphCenter height={'225px'}>
          {isLoading ?
            <LoadingDoughnutChart />
            :
            <SpendingDoughnutChart labels={labels} tableData={tableData}/>}
        </GraphCenter>
      </GraphWindow>
    </DisableContentsContainer>
    {isLoading ?
      <LoadingTableChart>
        {loadingTableChartRows}
      </LoadingTableChart>
      :
      <SpendingTableChart labels={labels} tableData={tableData} />}
  </SpendingWrapper>;
}

export default YearlyExpenditurePanel;
