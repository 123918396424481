import { AmazonHelpers } from "../../../../amazon";
import { useContext, useMemo, useState } from "react";
import AmazonContext from "components/Contexts/AmazonContext";
import styled from "styled-components";
import DateDropdown from "../modals/DateDropdown";
import ViewAllArrow from "./buttons/ViewAllArrow";
import WeeklySpendingChart from "./charts/WeeklySpendingChart";
import { DisableContentsContainer, FlexRow, GraphCenter, GraphWindow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, PanelText, PanelBlurb, PanelBlurbHeroText } from "../../styles/generics";
import { getWeekData, months, usd } from "./utils";
import { useNavigate } from "react-router-dom";

const SpendingTitle = styled(PanelTitle)`
  padding-right: 0.625rem;
`;

const SpendPrice = styled(PanelBlurbHeroText)`
`;

const SpendText = styled(PanelText)`
`;

function WeeklySpendingPanel() {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const [month, setMonth] = useState(months[new Date().getMonth()]);
  const [year, setYear] = useState(new Date().getFullYear());

  const yearData = useMemo(() =>
    AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders(), [year, year - 1])
  , [amazonHandler, year]);

  const monthData = useMemo(() => {
    const data = {
      thisYear: AmazonHelpers.SplitOrdersByMonth(yearData[year] || []),
      lastYear: AmazonHelpers.SplitOrdersByMonth(yearData[year - 1] || [])
    }
    return data;
  }, [year, yearData]);

  const monthSpending = useMemo(() => {
    const monthIndex = months.indexOf(month);
    return {
      thisYear: AmazonHelpers.CalculateSpending(monthData.thisYear[monthIndex]).totalSpending,
      lastYear: AmazonHelpers.CalculateSpending(monthData.lastYear[monthIndex]).totalSpending
    }
  }, [month, monthData.lastYear, monthData.thisYear]);

  const { weekLabels, weekData } = useMemo(() =>
    getWeekData(month, monthData, year)
  , [month, monthData, year]);

  return <PanelWrapper>
    <FlexRow>
      <Left style={{display: 'flex', alignItems: 'center'}}>
        <SpendingTitle>Weekly Spending: </SpendingTitle>
        <DateDropdown
          month={month}
          months={months}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
        />
      </Left>
      <Right>
        <div onClick={() => navigate('/spending/by-date/weekly')}>
          <ViewAllArrow />
        </div>
      </Right>
    </FlexRow>
    <DisableContentsContainer>
      <GraphWindow height={'150px'}>
        <GraphCenter height={'150px'}>
          <WeeklySpendingChart labels={weekLabels} tableData={weekData}/>
        </GraphCenter>
      </GraphWindow>
      <div style={{display: 'flex'}}>
        <PanelBlurb>
          <SpendPrice color={'#5ECAD9'}>
            {usd.format(monthSpending.lastYear)}
          </SpendPrice>
          <SpendText>
            {month + ' ' + (year - 1)} Spent
          </SpendText>
        </PanelBlurb>
        <PanelBlurb>
          <SpendPrice color={'#6B6BD4'}>
            {usd.format(monthSpending.thisYear)}
          </SpendPrice>
          <SpendText>
            {month + ' ' + year} Spent
          </SpendText>
        </PanelBlurb>
      </div>
    </DisableContentsContainer>
  </PanelWrapper>;
}

export default WeeklySpendingPanel;
