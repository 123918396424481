import { FlexRow, Left, Right } from "components/App/Dashboard/panels/generics";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import DollarIcon from "icons/dollar-icon.svg";
import PrimeIcon from "icons/prime-logo.svg";
import ProductIcon from "icons/product-icon.svg";
import {useCallback, useContext, useEffect} from "react";
import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_CLICK_LINK } from "components/Bridge/outgoing_events";
import AmazonContext from "components/Contexts/AmazonContext";
import { AmazonData, AmazonHelpers } from "../../../../amazon";

const usd = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

const ProductImgWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 38px;
`;

const ProductImg = styled.img`
  max-height: 193.33px;
  max-width: 193.33px;
`;

const ProductTitle = styled.div`
  padding: 16px 16px 0px 16px;

  font-size: 22px;
  line-height: 27px;
`;

const ProductInfoFlexRow = styled(FlexRow)`
  align-items: center;
  padding: 21px 16px 21px 16px;
`;

const ProductViewButton = styled.button`
  border: none;

  padding: 7.75px 19px 7.75px 19px;
  margin-left: 11.5px;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

  background: #7253cb;
  border-radius: 35px;
`;

const PurchaseInfoRow = styled(FlexRow)`
  height: 53px;

  align-items: center;

  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  padding-left: 29.33px;
  padding-right: 26px;

  color: #6b6bd4;

  border-width: 1px 0px;
  border-style: solid;
  border-color: #e4e4e4;
`;

const PurchaseTableHeader = styled(FlexRow)`
  padding-left: 16px;
  padding-right: 16px;

  align-items: center;

  height: 36px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #4d4c4c;

  background: #e9e9ff;
  opacity: 0.8;
`;

const PurchaseTableRow = styled(FlexRow)`
  padding-left: 16px;
  padding-right: 16px;

  align-items: center;
  height: 47px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  border-bottom: 1px solid #e0e0e0;
`;

const TableFourth = styled.div`
  width: 25%;
`;

const TableHalf = styled.div`
  width: 50%;
`;

function ProductPage() {
  const { asin } = useParams();

  const { amazonHandler } = useContext(AmazonContext);
  const orders = amazonHandler.getOrdersByAsin(asin || "");
  const item = orders[0]; // TODO error in ui if nonexistent

  const amazonLink = AmazonHelpers.asin_to_url(item?.asin || "");

  const handleClickView = useCallback(() => {
    MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, amazonLink);
  }, [amazonLink]);

  useEffect(() => {
    MobileEventHandler.analytics("product_page_wv")
  }, [])

  const constructTable = useCallback(() => {
    const blocks = [];
    blocks.push(
      <PurchaseTableHeader key="product_header">
        <TableFourth>Quantity</TableFourth>
        <TableHalf>Date</TableHalf>
        <TableFourth style={{ textAlign: "right" }}>Price Per Unit</TableFourth>
        <TableFourth style={{ textAlign: "right" }}>Total</TableFourth>
      </PurchaseTableHeader>,
    );

    orders?.forEach((p, i) => {
      blocks.push(
        <PurchaseTableRow key={"product_row" + i}>
          <TableFourth>{p.quantity}</TableFourth>
          <TableHalf>{p.orderDate?.toDateString()}</TableHalf>
          <TableFourth style={{ textAlign: "right" }}>{usd(p.pricePerUnit)}</TableFourth>
          <TableFourth style={{ textAlign: "right" }}>{usd(p.price)}</TableFourth>
        </PurchaseTableRow>,
      );
    });

    return blocks;
  }, [orders]);

  return (
    <div>
      <ProductImgWrapper>
        <ProductImg src={AmazonHelpers.asin_to_image(item?.asin || "", 500)} alt="Product" />
      </ProductImgWrapper>
      <ProductTitle>{item?.title || ""}</ProductTitle>
      <ProductInfoFlexRow>
        <Left>${item?.price || 0}</Left>
        <Right>
          <img src={PrimeIcon} alt="Amazon Prime Icon" />
          <ProductViewButton onClick={handleClickView}>View</ProductViewButton>
        </Right>
      </ProductInfoFlexRow>
      <PurchaseInfoRow>
        <Left>
          <div style={{ display: "flex" }}>
            <img src={ProductIcon} alt="Product Icon" style={{ marginRight: 11.33 }} />
            Purchased {orders.reduce(
              (acc: number, p: AmazonData) => acc + Number(p.quantity),
              0,
            )}{" "}
            times
          </div>
        </Left>
        <Right>
          <img style={{ paddingRight: 14 }} src={DollarIcon} alt="Dollar Icon" />
          Total spent {usd(orders.reduce((acc, p) => acc + p.price, 0))}
        </Right>
      </PurchaseInfoRow>
      {constructTable()}
    </div>
  );
}

export default ProductPage;
