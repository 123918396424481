import { AmazonSort, SortOrder } from "amazon";

const transaction_sort_types = [
  "Newest" , 
  "Oldest" , 
  "Most Expensive" , 
  "Least Expensive", 
  "Most Purchased",
  "Product Name (A-Z)", 
  "Product Name (Z-A)"
] as const;

export const isTypeTransactionSort = (str: string): str is TransactionSort => {
  return (transaction_sort_types as readonly string[]).includes(str);
};
export type TransactionSort = (typeof transaction_sort_types)[number];

export const sortOptions: {
  [id in TransactionSort]: {
    type: AmazonSort,
    order: SortOrder,
    agg?: boolean
  }
} = {
  "Newest": {
    type: "orderDate",
    order: "DESC",
  },
  "Oldest": {
    type: "orderDate",
    order: "ASC"
  },
  "Most Expensive": {
    type: "price",
    order: "DESC"
  },
  "Least Expensive": {
    type: "price",
    order: "ASC"
  },
  "Most Purchased": {
    type: "quantity",
    order: "DESC",
    agg: true
  },
  "Product Name (A-Z)": {
    type: "title",
    order: "ASC"
  },
  "Product Name (Z-A)": {
    type: "title",
    order: "DESC"
  }
};

export const date_option_types = [
  "This Month", 
  "Last Month", 
  "Last 6 Months",
  "This Year", 
  "Last Year", 
  "All Time", 
] as const;
export type TransactionDateOptionType = (typeof date_option_types)[number];
export const isTypeTransactionDateOption = (str: string): str is TransactionDateOptionType => {
  return (date_option_types as readonly string[]).includes(str);
}

const getToday = () => new Date();
const getMonth = (day:number) => {
  const today = getToday();
  return new Date(today.getFullYear(), today.getMonth(), day);
}
const getLastMonth = (day:number) => {
  const today = getToday();
  return new Date(today.getFullYear(), today.getMonth() - 1, day);
}
const get6Months = (day:number) => {
  const today = getToday();
  return new Date(today.getFullYear(), today.getMonth() - 6, day);
}
const getYear = (day:number) => {
  const today = getToday();
  return new Date(today.getFullYear(), 0, day);
}
const getLastYear = (day:number) => {
  const today = getToday();
  return new Date(today.getFullYear() - 1, 0, day);
}

export const dateOptions: {
  [id in TransactionDateOptionType] : () => [Date, Date]
} = {
  "All Time": () => [new Date(0), new Date()],
  "This Month": () => [getMonth(1), new Date()],
  "Last Month": () => [getLastMonth(1), getMonth(0)],
  "This Year": () => [getYear(1), new Date()],
  "Last Year": () => [getLastYear(1), getYear(0)],
  "Last 6 Months": () => [get6Months(1), new Date()],
}