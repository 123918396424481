import { Bar } from "react-chartjs-2";
import { GRAPH_NUMBER } from "../../../styles/generics";

type WeeklyPurchasesChartProps = {
  labels: string[],
  tableData: {[id: string]: number[]}
  horizontal?: boolean
}

function WeeklyPurchasesChart({labels, tableData, horizontal}: WeeklyPurchasesChartProps) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x1: {
        grid: {
          display: false,
        },
      },
      y1: {
        grid: {
          drawBorder: false,
        },
        fonts: {
          size: GRAPH_NUMBER,
        },
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        backgroundColor: '#8A7DD7',
        barThickness: 15,
        barPrecentage: 0.5,
        borderColor: '#00000000',
        borderRadius: 100,
        borderWidth: 2,
        data: tableData.thisYear,
      },
      {
        backgroundColor: '#5ECAD9',
        barThickness: 15,
        barPrecentage: 0.5,
        borderColor: '#00000000',
        borderRadius: 100,
        borderWidth: 2,
        data: tableData.lastYear,
      }
    ],
  };

  return <Bar options={options} data={data} />
}

export default WeeklyPurchasesChart;
