import { AmazonHandler, AmazonHelpers } from "amazon";

const usd = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

export function spendingAndChange(today: Date, amazonHandler: AmazonHandler) {
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDayOfMonth = today.getDate();

  const years = [ currentYear - 2, currentYear - 1, currentYear ];
  const ordersByYear = AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders(), years);
  const [y0, y1, y2] = [ordersByYear[years[0]], ordersByYear[years[1]], ordersByYear[years[2]]];

  const spending : number[] = [
    AmazonHelpers.CalculateSpending(y0).totalSpending,
    AmazonHelpers.CalculateSpending(y1).totalSpending,
    AmazonHelpers.CalculateSpending(y2).totalSpending,
  ];
  const from = new Date("01/01/"+(currentYear-1));
  const to = new Date(`${currentMonth}/${currentDayOfMonth}/${currentYear}`);
  const lastYearYTD = AmazonHandler.loadAmazonHandler(y1).sliceDateRange(from, to);
  const lastYearSpendingYTD = AmazonHelpers.CalculateSpending(lastYearYTD.getOrders()).totalSpending || 0;
  const pctSpend = (a:number, b:number) => b > 0 ? ((a / b - 1.0) * 100).toFixed(2) : 0;

  return {
    cy: years[2],
    cySpend: spending[2],
    cySpendUSD: usd(spending[2]),
    cyChangeYTD: pctSpend(spending[2], lastYearSpendingYTD),
    cyMinus1: years[1],
    cyMinus1Spend: spending[1],
    cyMinus1SpendUSD: usd(spending[1]),
    cyMinus1Change: pctSpend(spending[1], spending[0]),
    cyMinus2: years[0],
    cyMinus2Spend: spending[0],
    cyMinus2SpendUSD: usd(spending[0]),
  };
}

export function predictSpend(today:Date, amazonHandler: AmazonHandler) {
  const labels: number[] = [];
  const yearData = AmazonHelpers.SplitOrdersByDay(amazonHandler.getOrders());
  const year = today.getFullYear();
  const trend: { thisYear: number[], lastYear: number[] } = { thisYear: [], lastYear: [] };

  const spending = { thisYear: 0, lastYear: 0};

  if (!yearData[year - 1]) {
    return {
      labels,
      spending,
      trend,
      year,
    }
  }
  // if there's no data in the current year, create empty year
  if (!yearData[year]) {
    yearData[year] = [];
  }

  for (let i = 0; i < 365; i ++) {
    labels.push(i);

    if(yearData[year]) {
      if (yearData[year][i]) {
        spending.thisYear += AmazonHelpers.CalculateSpending(yearData[year][i]).totalSpending;
      }
      trend.thisYear.push(spending.thisYear);
    }

    if (yearData[year - 1][i]) {
      spending.lastYear += AmazonHelpers.CalculateSpending(yearData[year - 1][i]).totalSpending;
    }
    trend.lastYear.push(spending.lastYear);
  }

  // Leap Year edge case.
  if(yearData[year]) {
    if (yearData[year][365]) {
      spending.thisYear += AmazonHelpers.CalculateSpending(yearData[year][365]).totalSpending;
    }
  }
  if (yearData[year - 1][365]) {
    spending.lastYear += AmazonHelpers.CalculateSpending(yearData[year - 1][365]).totalSpending;
  }
  trend.thisYear.push(spending.thisYear);
  trend.lastYear.push(spending.lastYear);

  // Extrapolate data using linear regression
  // with special handling where we don't have data in either year
  const dayOfYear = AmazonHelpers.dayOfYear(today);
  const currentYearSlope = trend.thisYear[dayOfYear] / dayOfYear;
  let factor = 0; // how much to boost or dampen the delta
  if (trend.thisYear[dayOfYear] > 0) {
    factor = Number(trend.thisYear[dayOfYear]) / Number(trend.lastYear[dayOfYear]);
  } else {
    // no purchases so far this year, so dampen the line by the number of days
    factor = 1.0 - dayOfYear / 365.0;
  }

  trend.thisYear.forEach((n, i) => {
    if (i > dayOfYear) {
      if (trend.lastYear[i] > 0) {
        // y = b + mx
        trend.thisYear[i] =  trend.thisYear[i-1] + factor * (trend.lastYear[i] - trend.lastYear[i-1]);
      } else {
        // no purchases last year up until this point,
        // so only use the slope of the current year
        trend.thisYear[i] = i * currentYearSlope;
      }
      spending.thisYear = trend.thisYear[i];
    }
  });

  // fake the dashed lines
  trend.thisYear.forEach((n, i) => {
    if (i > dayOfYear && i % 8 < 4) {
      //@ts-ignore
      trend.thisYear[i] = undefined;
    }
  });

  return {
    labels,
    trend: {
      thisYear: trend.thisYear.slice(0, 365),
      lastYear: trend.lastYear.slice(0, 365),
    },
    spending,
    year,
  };
}
