import Fuse from "fuse.js";
import { MarketplaceItem } from "./marketplaceTypes";

const options: Fuse.IFuseOptions<any> = {
  isCaseSensitive: false,
  // includeScore: true,
  shouldSort: true,
  includeMatches: false,
  // findAllMatches: true,
  minMatchCharLength: 2,
  location: 0,
  threshold: 0.025,
  distance: 0,
  // useExtendedSearch: false,
  ignoreLocation: true,
  ignoreFieldNorm: true,
  fieldNormWeight: 0.1,
  keys: ["productName"],
};

/**
export class AmazonSearch {
  static search(query: string, ah: AmazonHandler, limit?: number, removeDuplicates = true) {
    const amazonIndex = Fuse.createIndex(options.keys || ["title"], ah.getOrders());
    const fuse: Fuse<AmazonData> = new Fuse(ah.getOrders(), options, amazonIndex);

    const res = limit ? fuse.search(query, { limit }) : fuse.search(query);

    // Return results as is if called without wanting to remove duplicates
    if (!removeDuplicates) return res;

    // Only return unique values
    const unique: Fuse.FuseResult<AmazonData>[] = [
      ...res.reduce((a, c) => a.set(c.item.asin, c), new Map()).values(),
    ];

    return unique;
  }
}
**/

export class FuseMarketSearch {
  static search(query: string, allMarketItems: MarketplaceItem[], limit?: number, removeDuplicates = true) {
      const marketplaceIndex = Fuse.createIndex(options.keys || ["productName"], allMarketItems);
      const fuse: Fuse<MarketplaceItem> = new Fuse(allMarketItems, options, marketplaceIndex);

      const res = limit ? fuse.search(query, { limit }) : fuse.search(query);

      // Return results as is if called without wanting to remove duplicates
      if(!removeDuplicates) return res;

      // Only return unique values
      const unique: Fuse.FuseResult<MarketplaceItem>[] = [
        ...res.reduce((a, c) => a.set(c.item.productName, c), new Map()).values(),
      ];

      return unique;
  }
}
