import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SmallText } from "../../../styles/generics";
import { usd } from "../utils";

const CategoryComparisonWrapper = styled.div`
  padding: 1rem;
`;

const FlexRow = styled.div`
  display: flex;
`;

const Left = styled.div`
  display: flex;
  align-items: center;

  width: 30%;
`;

const Middle = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
`;

const Right = styled.div`
  display: flex;
  width: 30%;

  align-items: center;
  justify-content: right;
`;

const Header = styled(SmallText)`
  font-weight: 700;
`;

const ItemRow = styled(SmallText)<any>`
  display: flex;

  padding-top: 1rem;

  ${props => props.active ? '' : 'opacity: 0.5;'}
`;

const ChangeContainer = styled.div<any>`
  background-color: ${props => props.color};
  border-radius: 50px;

  padding: 5px;
`;

const LabelTick = styled.div<any>`
  width: 10px;
  height: 0px;

  margin-right: 10px;

  border-bottom: 4px solid ${props => props.color};
`

type CategoryComparisonChartProps = {
  labels: number[],
  spending: number[],
  trend_colors: string[],
  onClicks: (() => void)[]
}

const colors = {
  infinite: 'yellow',
  increase: '#FFB6B6',
  decrease: '#D1F274',
  same: '#C4ABFF'
}

function SpendingTrendTableChart({labels, spending, trend_colors, onClicks}: CategoryComparisonChartProps) {
  const [actives, setActives] = useState<boolean[]>(labels.map((l) => true));

  useEffect(() => {
    if (labels.length > actives.length) {
      setActives((prev) => {
        while (labels.length > actives.length) prev.push(true);
        return prev;
      });
    }
  }, [actives.length, labels, labels.length]);

  const generateOnClick = useCallback((i: number) => {
    return () => {
      setActives(actives.map((b, index) => index === i ? !b : b));
      onClicks[i]();
    }
  }, [actives, onClicks]);

  const rows = useMemo(() => {
    return labels.slice(0, labels.length).map((l, i) => {
      const current_spending = i > 0 ? spending[i-1] : spending[i];
      const second = spending[i];
      const percentage = (second - current_spending) * 100 / current_spending;
      const change = isNaN(percentage) || percentage === 0 ?
        "N/A"
        :
        isFinite(percentage) ?
        Math.round(percentage) + '%'
        :
        percentage.toString();
      const color = isNaN(percentage) || percentage === 0 ?
        colors.same
        :
        !isFinite(percentage) ?
        colors.infinite
        :
        percentage > 0 ?
        colors.increase
        :
        colors.decrease;
      return <ItemRow
        onClick={generateOnClick(i)}
        key={`cat_comp_row_${i}`}
        active={actives[i]}
      >
        <Left><LabelTick color={trend_colors[i]}/>{l}</Left>
        <Middle>{usd.format(spending[i])}</Middle>
        <Right><ChangeContainer color={color}>{change}</ChangeContainer></Right>
      </ItemRow>
    })
  }, [actives, generateOnClick, labels, spending, trend_colors]);

  return <CategoryComparisonWrapper>
    <FlexRow>
      <Left><Header>Year</Header></Left>
      <Middle><Header>Amount</Header></Middle>
      <Right><Header>Change</Header></Right>
    </FlexRow>
    {rows}
  </CategoryComparisonWrapper>
}

export default SpendingTrendTableChart;
