import { UskoCategory, AmazonHelpers } from 'amazon';
import { Chart } from 'chart.js';
import { category_colors } from 'components/App/Dashboard/categories/categories';
import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

type SpendingDoughnutChartProps = {
  labels: UskoCategory[],
  tableData: number[]
}

function SpendingDoughnutChart({labels, tableData} : SpendingDoughnutChartProps) {
  const navigate = useNavigate();

  const data = {
    labels,
    datasets: [{
      data: tableData,
      backgroundColor: labels.map(l => category_colors[l]),
      hoverOffset: 4
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    containerId: "spending-doughnut-legend-id",
    plugins: {
      legend: {
        display: false
      },
      htmlLegend: {
        containerId: "spending-doughnut-legend-id",
      }
    },
    layout: {
      padding: 10
    },
    offset: 0,
    spacing: 0,
    borderWidth: 0,
    cutout: '80%',
  };

  // <block:plugin:0>
  const getOrCreateLegendList = (chart: any, id: string) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      //@ts-ignore
      listContainer.style.margin = 0;
      //@ts-ignore
      listContainer.style.padding = 0;

      legendContainer?.appendChild(listContainer);
    }

    return listContainer;
  };

  const plugins = [
    {
      beforeDraw: (chart: any) => {
        var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
        ctx.restore();
        let price, item;
        if (chart._active.length > 0) {
          price = chart.config.data.datasets[chart._active[0].datasetIndex].data[chart._active[0].index];
          item = chart.config.data.labels[chart._active[0].index];
        }
        else {
          price = chart.config.data.datasets[0].data.reduce((acc: number, n: number) => acc + n);
          item = "Total Spent";
        }
        //Price

        var fontSize = (height / 150).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `${AmazonHelpers.priceFormat(price)}`,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2 - 10;
        ctx.fillText(text, textX, textY);
        ctx.save();

        // Item Type
        fontSize = (height / 350).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        text = `${item}`;
        textX = Math.round((width - ctx.measureText(text).width) / 2);
        textY = height / 2 + (height / 15);
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
    {
      id: 'htmlLegend',
      afterDraw: (chart: any, args: any, options: any) => {
        const ul = getOrCreateLegendList(chart, "spending-doughnut-legend-id");

        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.reverse().forEach((item: any) => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '10px';
          li.style.marginRight = '10px';
          li.style.marginBottom= '10px';

          li.onclick = (event: any) => {
            event.stopPropagation();
            navigate(`/transactions?category=${encodeURIComponent(item.text)}`);
            // Visible/Non-Visible
            // const {type} = chart.config;
            //
            // if (type === 'pie' || type === 'doughnut') {
            //   // Pie and doughnut charts only have a single dataset and visibility is per item
            //   chart.toggleDataVisibility(item.index);
            // } else {
            //   chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            // }
            // chart.update();
          };

          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.borderRadius = '100px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '10px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '10px';

          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;

          textContainer.style.margin = '0px';
          textContainer.style.padding = '0px';
          textContainer.style.fontSize = '14px';
          textContainer.style.fontWeight = '400';

          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const text = document.createTextNode(item.text + " →");
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        })
      },
    }
  ];

  const chartRef = useRef<Chart>(null);

  return <Doughnut
    // @ts-ignore
    ref={chartRef}
    options={options}
    data={data}
    // @ts-ignore
    plugins={plugins}
  />;
}

export default SpendingDoughnutChart;
