import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled from "styled-components";
import { FlexRow, Left, Right } from "../Dashboard/panels/generics";

import CheckMarkIcon from "icons/checkmark-outlined.svg";

type TransactionSettingsProps = {
  q: string,
  options: string[],
  text?: string[]
}

const Row = styled.div<any>`

  padding-left: 32px;

  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  
  border-bottom: 1px solid #E4E4E4;

  height: 56px;

  ${props => props.active ? `font-weight: 700;` : ''}
`;

function TransactionSettings({q, options, text}:TransactionSettingsProps) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const selected = useMemo(() => {
    const s = params.get(q);
    if (s) return options.indexOf(decodeURIComponent(s));
    return 0;
  }, [options, params, q]);

  const rows = useMemo(() => {
    const cpyParams = new URLSearchParams(params.toString());
    return options.map((o, i) => {
      cpyParams.get(q) ? cpyParams.set(q, encodeURIComponent(o)) : cpyParams.append(q, encodeURIComponent(o));
      const queryStr = cpyParams.toString();
      return <Row active={selected === i} key={`${q}_${o}`} onClick={() => navigate(`/purchases/filter/${q}?${queryStr}`)}>
        <FlexRow style={{ height: '100%', alignItems: 'center' }}>
          <Left>{text?.[i] || o}</Left>
          <Right>
            {selected === i ? <img style={{margin: 10}} src={CheckMarkIcon} alt={"Selected"} /> : null}
          </Right>
        </FlexRow>
      </Row>
    });
  }, [navigate, options, params, q, selected, text])
  
  return <>
    {rows}
  </>;
}

export default TransactionSettings;
