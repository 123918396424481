export type HandlerObj = {
  handler: (data: any) => void,
  label: string
}

export type EventHandler = {
  type: string,
  handlers: HandlerObj[]
}

export const AMAZON_INCREMENTAL_REPORT_EVENT: EventHandler = {
  type : 'amazonIncrementalReport',
  handlers: []
}

export const AMAZON_INITIAL_REPORT_EVENT: EventHandler = {
  type: 'amazonInitialReport',
  handlers: []
}

export const AMAZON_LAST_UPDATE_EVENT: EventHandler = {
  type: 'lastSuccessfulDataUpdate',
  handlers: []
}

export const AMAZON_LOGIN_STATUS_EVENT: EventHandler = {
  type: 'amazonLoginStatus',
  handlers: []
}

export const AMAZON_REFRESHING_DATA_EVENT: EventHandler = {
  type: 'refreshingData',
  handlers: []
}

export const AMAZON_REVIEW_BATCH_EVENT: EventHandler = {
  type: 'amazonReviewBatch',
  handlers: []
}

export const APP_MOVED_TO_FOREGROUND: EventHandler = {
  type: 'appMovedToForeground',
  handlers: []
}

export const MARKETPLACE_REF_LINK_LOADING: EventHandler = {
  type: 'marketplaceRefLinkLoading',
  handlers: []
}

export const MARKETPLACE_ALL_ITEMS_EVENT : EventHandler = {
  type: 'marketplaceAllItems',
  handlers: []
}

export const MARKETPLACE_ITEMS_EVENT : EventHandler = {
  type: 'marketplaceItems',
  handlers: []
}

export const MESSAGE_EVENT : EventHandler = {
  type: 'messages',
  handlers: []
}

export const NATIVE_IMAGE_DOWNLOAD: EventHandler = {
  type: 'downloadResponse',
  handlers: []
}

export const NATIVE_VARIABLES: EventHandler = {
  type: 'nativeVariables',
  handlers: []
}

export const VERSIONS_EVENT : EventHandler = {
  type: 'versions',
  handlers: []
}

export const USER_BALANCE : EventHandler = {
  type: 'userBalance',
  handlers: []
}

/* Returns a list of eventHandlers keyed by eventType */
export const RELEVANT_INCOMING_EVENTS: {[id: string]: EventHandler} = Object.fromEntries([
  AMAZON_INCREMENTAL_REPORT_EVENT,
  AMAZON_INITIAL_REPORT_EVENT,
  AMAZON_LAST_UPDATE_EVENT,
  AMAZON_LOGIN_STATUS_EVENT,
  AMAZON_REFRESHING_DATA_EVENT,
  AMAZON_REVIEW_BATCH_EVENT,
  APP_MOVED_TO_FOREGROUND,
  NATIVE_VARIABLES,
  NATIVE_IMAGE_DOWNLOAD,
  MESSAGE_EVENT,
  MARKETPLACE_REF_LINK_LOADING,
  MARKETPLACE_ALL_ITEMS_EVENT,
  MARKETPLACE_ITEMS_EVENT,
  VERSIONS_EVENT,
  USER_BALANCE,
].map((eh) => [eh.type, eh]));
