import { AmazonHelpers } from "../../../../amazon";
import { useContext, useState, useMemo, useEffect } from "react";
import { ViewWrapper } from "./generics";
import { useParams, useSearchParams } from "react-router-dom";

import AmazonContext from "components/Contexts/AmazonContext";

import DateDropdown from "components/App/Dashboard/modals/DateDropdown";
import WeeklySpendingHorizontalChart from "components/App/Dashboard/panelsv2/charts/WeeklySpendingHorizontalChart";
import ChartBlurb from "./ChartBlurb";

import { getWeekData, months, usd } from "components/App/Dashboard/panelsv2/utils";
import { GraphWindow, GraphCenter, NoStyleSelect } from "components/App/Dashboard/panelsv2/generics";
import { PanelText, PanelBlurb, PanelBlurbHeroText } from "../../styles/generics";

import HighlightsStandAlonePanel from "components/App/Dashboard/panels/HighlightsStandAlonePanel";
import styled from "styled-components";
import {MobileEventHandler} from "../../../Bridge/MobileEventHandler";

const NoStyleSelectWithCaret = styled(NoStyleSelect)`
  -webkit-appearance: auto;
  -moz-appearance: auto;
`;

const OptionText = styled.option`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.1875rem;

  color: #4C4C4C;
`;

const SpendPrice = styled(PanelBlurbHeroText)`
`;

const SpendText = styled(PanelText)`
`;

export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const YEARLY = 'yearly';

const num_of_years = 5;

function SpendingComparisonView() {
  const { by } = useParams();
  const [params] = useSearchParams();
  const { amazonHandler } = useContext(AmazonContext);
  const [month, setMonth] = useState(months[new Date().getMonth()]);

  useEffect(() => {
    MobileEventHandler.analytics("spending_by_date_wv")
  }, [])

  const initYear = useMemo(() => {
    const year = params.get(`year`);
    return year ? parseInt(year) : new Date().getFullYear();
  }, [params]);
  const [year, setYear] = useState(initYear);

  useEffect(() => {
    setYear(initYear);
  }, [initYear]);

  const years = useMemo(() => amazonHandler.getYears().reverse(), [amazonHandler]);
  const { yearsPresent } = useMemo(() => {
    const yearsPresent = [];
    const yearsPast = [];
    const yearPresent = new Date().getFullYear();
    const yearPast = new Date().getFullYear() - num_of_years;

    for (let i = 0; i < num_of_years; i++){
      yearsPresent.push(yearPresent - i);
      yearsPast.push(yearPast - i);
    }

    return { yearsPresent, yearsPast };
  }, []);

  const yearData = useMemo(() =>
    AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders())
  , [amazonHandler]);

  const monthData = useMemo(() => {
    const data = {
      thisYear: AmazonHelpers.SplitOrdersByMonth(yearData[year] || []),
      lastYear: AmazonHelpers.SplitOrdersByMonth(yearData[year - 1] || [])
    }
    return data;
  }, [year, yearData]);

  const monthSpending = useMemo(() => {
    const monthIndex = months.indexOf(month);
    return {
      thisYear: AmazonHelpers.CalculateSpending(monthData.thisYear[monthIndex]).totalSpending,
      lastYear: AmazonHelpers.CalculateSpending(monthData.lastYear[monthIndex]).totalSpending
    }
  }, [month, monthData.lastYear, monthData.thisYear]);

  const yearSpending = useMemo(() => {
    const spending: { [id: number]: number} = {};
    years.forEach((y) => {
      spending[y] = AmazonHelpers.CalculateSpending(yearData[y] || []).totalSpending;
    });

    return spending;
  }, [yearData, years])

  const spending = useMemo(() => {
    if (by === WEEKLY) {
      return monthSpending;
    }
    else if (by === MONTHLY){
      return {
        thisYear: yearSpending[year],
        lastYear: yearSpending[year - 1]
      }
    }
    else if(by === YEARLY) {
      return {
        thisYear: yearSpending[yearsPresent[0]] || 0,
        lastYear: yearSpending[yearsPresent[1]] || 0
      }
    }
    return monthSpending;
  }, [by, monthSpending, year, yearSpending, yearsPresent]);

  const { chartLabels, chartData } = useMemo(() => {
    if (by === WEEKLY) {
      const { weekLabels: chartLabels, weekData: chartData } = getWeekData(month, monthData, year);
      return { chartLabels, chartData }
    }
    else if (by === MONTHLY) {
      return {
        chartLabels: months,
        chartData: {
          thisYear: monthData.thisYear.map((ad) => AmazonHelpers.CalculateSpending(ad).totalSpending),
          lastYear: []
        }
      }
    }
    else if (by === YEARLY) {
      return {
        chartLabels: yearsPresent.map((y) => y.toString()),
        chartData: {
          thisYear: yearsPresent.map((y) => yearSpending[y]),
          lastYear: []
        }
      }
    }
    return {chartLabels: [], chartData: {thisYear: [], lastYear: []}};
  }, [by, month, monthData, year, yearSpending, yearsPresent]);

  const { delta, negative } = useMemo(() => {
    const delta = spending.thisYear - spending.lastYear;
    const negative = delta < 0;
    return { delta, negative };
  }, [spending.lastYear, spending.thisYear])

  const tableHeight = useMemo(() => {
    return chartLabels.length * 50;
  }, [chartLabels.length]);

  function selectYear (e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setYear(parseInt(value));
  }

  const selector = useMemo(() => {
    return by === WEEKLY ?
      <DateDropdown
        month={month}
        months={months}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
      />
      :
      by === MONTHLY ?
      <NoStyleSelectWithCaret
        value={year}
        onChange={selectYear}
      >
        {years.map((y) => <OptionText value={y} key={y}>{y}</OptionText>)}
      </NoStyleSelectWithCaret>
      :
      by === YEARLY ?
      <>{(year-num_of_years + 1) + '-' + (year)}</>
      :
      <>{null}</>
  }, [by, month, year, years]);

  const title = useMemo(() => {
    return `${by ? by.charAt(0).toUpperCase() + by.slice(1) : null} Spending:`
  }, [by]);

  const text = useMemo(() => {
    return `${negative ? 'less' : 'more'} than ${
      by === YEARLY ?
        (year-num_of_years + 1) + '-' + (year)
        :
        `last year ${month} ${year - 1}`
    }`
  }, [by, month, negative, year]);

  return <ViewWrapper>
    <ChartBlurb
      selector={selector}
      title={title}
      text={text}
      spending={spending.thisYear}
      delta={delta}
    />
    <GraphWindow height={tableHeight + 'px'}>
      <GraphCenter height={tableHeight + 'px'} width={'calc(100vw - 32px)'}>
        <WeeklySpendingHorizontalChart
          labels={chartLabels}
          tableData={chartData}
          type={by || WEEKLY}
          year={year}
        />
      </GraphCenter>
    </GraphWindow>
    <div style={{display: 'flex', padding: 16}}>
      <PanelBlurb>
        <SpendPrice color={'#5ECAD9'}>
          {usd.format(spending.lastYear)}
        </SpendPrice>
        <SpendText>
          {by !== YEARLY ? month : ''} {year - 1} Spent
        </SpendText>
      </PanelBlurb>
      <PanelBlurb>
        <SpendPrice color={'#6B6BD4'}>
          {usd.format(spending.thisYear)}
        </SpendPrice>
        <SpendText>
          {by !== YEARLY ? month : ''} {year} Spent
        </SpendText>
      </PanelBlurb>
    </div>
    <HighlightsStandAlonePanel />
    <div style={{ marginBottom: 10 }}/>
  </ViewWrapper>;
}

export default SpendingComparisonView;
