import styled from "styled-components";
import { Link } from "react-router-dom";
import {useEffect} from "react";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const StyledWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(rgb(157, 125, 233) 0%, rgb(104, 144, 250) 100%),
    linear-gradient(0deg, rgb(149, 127, 235), rgb(149, 127, 235));
  z-index: 2;
  position: relative;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  h3 {
    text-align: center;
    font-size: 1.2rem;
  }
  h1 {
    font-size: 3rem;
    font-weight: 700;
  }
`;
const StyledHighlightTitle = styled.span`
  color: #82d9f6;
`;
const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const StyledButtonPrimary = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 1rem;
  background: linear-gradient(270deg, rgb(117, 81, 203) 3.49%, rgb(81, 114, 200) 92.38%);
`;
const StyledButtonSecondary = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #fff;
  border-radius: 1rem;
`;

const ShoppingReportIntro = () => {
  useEffect(() => {
    MobileEventHandler.analytics("shopping_report_intro_wv")
  }, [])
  return (
    <StyledWrapper>
      <h3>We've gathered the data and now it's time for your results!</h3>
      <h1>
        Are you ready
        <br /> to learn
        <br /> some{" "}
        <StyledHighlightTitle>
          cool
          <br />
          facts
        </StyledHighlightTitle>{" "}
        about your <br />
        <StyledHighlightTitle>
          Amazon <br />
          data
        </StyledHighlightTitle>
        ?
      </h1>
      <StyledButtons>
        <StyledButtonPrimary to="/onboarding">Let's do it!</StyledButtonPrimary>
        <StyledButtonSecondary to="/">Skip</StyledButtonSecondary>
      </StyledButtons>
    </StyledWrapper>
  );
};

export default ShoppingReportIntro;
