import {useContext, useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";

import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_SHARE_SCREENSHOT } from "components/Bridge/outgoing_events";
import AmazonContext from "components/Contexts/AmazonContext";
import ShareIcon from "icons/share-icon.svg";
import SnakeBkg from "images/teal-snek-bg.png";
import SpendingForecastLineChart from "components/App/Dashboard/panelsv2/charts/SpendingForecastLineChart";
import { PanelBlurb, PanelBlurbHeroText, PanelText } from "components/App/styles/generics";

import styled from "styled-components";

import * as ReportHelpers from "./helpers"

const usd = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

const StyledWrapper = styled.div`
height: 100%;
min-height: 100vh;
width: 100%;
position: relative;
background: linear-gradient(rgb(157, 125, 233) 0%, rgb(104, 144, 250) 100%),
  linear-gradient(0deg, rgb(149, 127, 235), rgb(149, 127, 235));
padding: 3rem 1rem 0 1rem;
color: #fff;
overflow: hidden;
`;
const StyledProgressBar = styled.div`
display: flex;
gap: 4px;
> div {
cursor: pointer;
height: 4px;
flex: 1;
      background-color: #fff;
opacity: 0.5;
}
`;
const StyledNavOptions = styled.div`
display: flex;
padding: 0 1rem;
justify-content: space-between;
margin: 0.5rem 0 1rem 0;
svg {
height: 1.4rem;
width: auto;
}
`;
const StyledLinkHome = styled(Link)`
text-decoration: none;
color: #fff;
`;
const StyledNext = styled.div`
cursor: pointer;
`;
const StyledSwipeArea = styled.div`
height: 100%;
width: 110vw;
position: absolute;
z-index: 1;
margin-left: -5vw;
`;
const StyledButtonSecondary = styled(Link)`
color: #fff;
text-decoration: none;
text-align:center;
padding: 0.75em;
margin-top:2vh;
border-radius: 1.357rem;
background:rgba(80.0, 89.0, 200, 0.5);
z-index: 5;
position: relative;
`;


const SpendingChart = styled.div<any>`
margin: auto;
margin-top: 1rem;
margin-bottom: 1rem;
height: 200px;
background-color: white;
border-radius: 0.5rem;
canvas {
padding: 1em;
}
`;
const SpendingNumbers = styled.div<any>`
display: flex;
color: black;
text-align: center;
`;
const YearSpendCompare = styled.div`
font-weight: normal;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
`
const SpendNumber = styled.h1`
color: #5ECAD9;
color: #66C5BE;
color: #1dedd0;
color: #23e2d4;
text-align: right;
width: 100%;
`
const SpendChange = styled.div<{color:string}>`
background-color: ${p => p.color};
color: darkslategray;
border-radius: 1em;
height: fit-content;
width: fit-content;
padding: 1vw 1vh;
`
const SpendDesc = styled.div`
`
const SpendYear = styled.div`
width: 100%;
vertical-align: middle;
margin-top: -0.75vh;
align-self: center;
`
const SpendChangeSummary = styled.div`
text-align: left;
font-weight: normal;
display: flex;
flex-direction: row;

`
const SpendChangeGroup = styled.div`
`
const ShareButton = styled.div`
color: white;
background:rgba(80.0, 89.0, 200, 0.5);
display: table;
padding: 0.25em 0.5em;
margin: auto;
border-radius: 1.357em;
position:relative;
z-index:5;
`
const ShareButtonIcon = styled.img`
display: table-cell;
padding-right: 0.25em;
padding-left: 0.5em;
text-align: right;
width: 2rem;
height 2rem;
filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(105%) contrast(100%);
`
const ShareButtonText = styled.div`
display: table-cell;
padding-left: 0.25em;
padding-right: 0.25em;
text-align: left;
font-size: larger;
vertical-align: middle;
`
const PredictedSpendPage = styled.div`
font-weight: normal;
display: flex;
flex-direction: column;
justify-content: flex-end;
`
const PredictedSpendStats = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`
const PredictedSpendShareButton = styled.div`
`
const SvgNext = () => (
    <svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 24 24"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
    d="m13.022 14.999v3.251c0 .412.335.75.752.75.188 0 .375-.071.518-.206 1.775-1.685 4.945-4.692 6.396-6.069.2-.189.312-.452.312-.725 0-.274-.112-.536-.312-.725-1.451-1.377-4.621-4.385-6.396-6.068-.143-.136-.33-.207-.518-.207-.417 0-.752.337-.752.75v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z"
    fillRule="nonzero"
    />
    </svg>
    );

const StyledViewTitleText = styled.h1`
font-size: 5.875vw;
font-weight: 700;
text-align: center;
padding: 0 2rem;
word-break: break-word;
overflow: hidden;
text-overflow: ellipsis;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3;
display: -webkit-box;
z-index: 1;
`;
const ExitWrapper = styled.div`
`
const StyledExit = styled.div`
display: flex;
font-weight: normal;
flex-direction: column;
justify-content: space-evenly;
color: #fff;
margin-left: 1em;
h3 {
  text-align: center;
  font-size: 1.375rem;
}
`;
const StyledPage = styled.div`
display: flex;
position: relative;
height: 100%;
min-height: calc(100vh - 6.5rem);
z-index: 1;
`;
const SnakeBkgDiv = styled.div`
height: 100vh;
width: 105vw;
margin-left: -5vw;
position: absolute;
background:url(${SnakeBkg}) no-repeat bottom left;
z-index: -1;
`
const SpentSpan = styled.span`
color: #23e2d4;
font-weight: bold;
`

function shareClicked(){
  MobileEventHandler.post_event(WEBVIEW_SHARE_SCREENSHOT, {text: "These are my Amazon stats from Usko Privacy! What's yours?"});
}

function spendingChangePage(data : ReturnType<typeof ReportHelpers.spendingAndChange>) {
  const [upColor, downColor, unchColor]  = ["#ddec98", "#e9bcb9", "lightgray"];
  const [upDir, downDir, unchDir] = ["↑","↓", "±"];
  let changeColor = upColor;
  let cyChangeYTD = Number(data.cyChangeYTD);
  let cyChangeDir = upDir;
  if (cyChangeYTD < 0) {
    changeColor = downColor;
    cyChangeDir = downDir;
    cyChangeYTD = Math.abs(cyChangeYTD);
  } else if (cyChangeYTD === 0) {
    changeColor = unchColor;
    cyChangeDir = unchDir;
  }
  let m1Change = Number(data.cyMinus1Change);
  let m1ChangeColor = upColor;
  let m1ChangeDir = upDir;
  if (m1Change < 0) {
    m1ChangeColor = downColor;
    m1ChangeDir = downDir;
    m1Change = Math.abs(m1Change);
  } else if (m1Change === 0) {
    m1ChangeColor = unchColor;
    m1ChangeDir = unchDir;
  }
  return <YearSpendCompare>
    <div style={{display:"flex", flexDirection:"column", marginTop:"10vh", flex:1}}>
    <StyledViewTitleText style={{marginBottom:"3vh"}}>Spending Breakdown</StyledViewTitleText>
    <SpendChangeGroup>
      <SpendChangeSummary>
        <SpendChange color={changeColor}>{cyChangeDir}{cyChangeYTD}%</SpendChange>
        <SpendNumber>{data.cySpendUSD}</SpendNumber>
      </SpendChangeSummary>
      <SpendDesc>{data.cy} spend YTD compared to {data.cyMinus1} YTD</SpendDesc>
    </SpendChangeGroup>
    <hr/>
    <SpendChangeGroup>
      <SpendChangeSummary>
        <SpendChange color={m1ChangeColor}>{m1ChangeDir}{m1Change}%</SpendChange>
        <SpendNumber>{data.cyMinus1SpendUSD}</SpendNumber>
      </SpendChangeSummary>
      <SpendDesc>{data.cyMinus1} spend compared to {data.cyMinus2}</SpendDesc>
    </SpendChangeGroup>
    <hr/>
    <SpendChangeGroup>
      <SpendChangeSummary>
        <SpendYear>{data.cyMinus2} Spend</SpendYear>
        <SpendNumber>{data.cyMinus2SpendUSD}</SpendNumber>
      </SpendChangeSummary>
    </SpendChangeGroup>
    <hr/>
    </div>
    <SpendChangeGroup style={{marginBottom:"5vh"}}>
    <ShareButton onClick={shareClicked}><ShareButtonIcon src={ShareIcon} /><ShareButtonText>Share</ShareButtonText></ShareButton>
    </SpendChangeGroup>
    </YearSpendCompare>
}

function threeYearPage(data : ReturnType<typeof ReportHelpers.spendingAndChange>) {
  const { cySpend, cyMinus1Spend, cyMinus2Spend } = data;
  const totalSpend = usd(cySpend + cyMinus1Spend + cyMinus2Spend);
  const year = data.cyMinus2;
  // be super careful about messing with this css
  // super touchy! everything is done in this specific way and order for $reasons
  // check everything if you make changes - swipes, top nav pills, shares, etc
  return <div style={{width:"100%"}}>
    <SnakeBkgDiv></SnakeBkgDiv>
  <YearSpendCompare style={{height:"100%"}}>
      <div style={{flex:1, marginTop:"10vh"}}>
        <StyledViewTitleText>
        <div>You have spent</div>
        <div><SpentSpan>{totalSpend}</SpentSpan> since</div>
        <div>January 1, {year}</div>
        </StyledViewTitleText>
      </div>
      <div style={{marginBottom: "5vh"}}>
        <ShareButton onClick={shareClicked}><ShareButtonIcon src={ShareIcon} /><ShareButtonText>Share</ShareButtonText></ShareButton>
      </div>
    </YearSpendCompare>
  </div>
}

function netWorthPage (data : ReturnType<typeof ReportHelpers.spendingAndChange>) {
  const { cySpend, cyMinus1Spend, cyMinus2Spend } = data;
  const totalSpend = cySpend + cyMinus1Spend + cyMinus2Spend;
  const amazonNW = usd(totalSpend * 0.04);
  return <YearSpendCompare>
    <div style={{flex:1, display:"flex", flexDirection:"column", marginTop:"10vh", textAlign:"center"}}>
    <StyledViewTitleText>
      <SpentSpan style={{fontSize:"10vw"}}>{amazonNW}</SpentSpan>
    </StyledViewTitleText>
    <div style={{fontSize:"5.5vw", fontWeight:"bold"}}>Total You Could Have Saved</div>
    <div style={{marginTop:"5vh"}}>
      Usko's Discover tab lets you earn up to 18% cash back. If you had bought all of your items via Discover, you have earned a lot of cash back.</div><div style={{marginTop:"3vh"}}> Make sure to check out the Discover tab and take advantage of cashback without compromise.
    </div>
    </div>
    <div style={{marginBottom: "5vh"}}>
    <ShareButton onClick={shareClicked}><ShareButtonIcon src={ShareIcon} /><ShareButtonText>Share</ShareButtonText></ShareButton>
    </div>
    </YearSpendCompare>
}

function predictedSpendPage(data: ReturnType<typeof ReportHelpers.predictSpend>) {
  const { labels, trend, spending, year } = data;

  const { delta, negative, percentage } = (() => {
      const delta = spending.thisYear - spending.lastYear;
      const negative = delta < 0;
      const denom = spending.lastYear === 0 ? Infinity : spending.lastYear;
      const percentage = Math.round(Math.abs(delta) * 100 / denom);
      return { delta, negative, percentage };
      })();


  return <PredictedSpendPage>
    <PredictedSpendStats style={{flex:1, display:"flex", flexDirection:"column"}}>
    <StyledViewTitleText>Predicted Spend</StyledViewTitleText>
    <div>
    Based on your spending last year,
          you will spend {
            negative ?
              <span><b>{usd(Math.abs(delta))}</b> less</span>
              :
              <span>an additional <b>{usd(delta)}</b></span>
          } this year compared to last year.  Spending will be
  {
    negative ? ' down ' : ' up '
  }
  {
    negative ?
      <span><b>↓ {percentage}%</b></span>
      :
      <span><b>↑ {percentage}%</b></span>
  }.
  </div>

    <SpendingChart>
    <SpendingForecastLineChart labels={labels} thisYear={trend.thisYear} lastYear={trend.lastYear}/>
    </SpendingChart>
    <SpendingNumbers>
    <PanelBlurb>
    <PanelBlurbHeroText color={'#5ECAD9'}>
    {usd(spending.lastYear)}
  </PanelBlurbHeroText>
    <PanelText>
    {year - 1} Spent
  </PanelText>
    </PanelBlurb>
    <PanelBlurb>
    <PanelBlurbHeroText color={'#6B6BD4'}>
    {usd(spending.thisYear)}
  </PanelBlurbHeroText>
    <PanelText>
    {year} Predicted
  </PanelText>
    </PanelBlurb>
    </SpendingNumbers>
    </PredictedSpendStats>
    <PredictedSpendShareButton style={{marginBottom:"5vh"}}>
    <ShareButton onClick={shareClicked}><ShareButtonIcon src={ShareIcon}/><ShareButtonText>Share</ShareButtonText></ShareButton>
    </PredictedSpendShareButton>
    </PredictedSpendPage>;
}

function lastPageHtml() {
  return <ExitWrapper>
    <SnakeBkgDiv></SnakeBkgDiv>
    <StyledExit>
    <StyledViewTitleText>Visit the dashboard for more insights</StyledViewTitleText>
    <div>Be sure to leave the app open so the app can continue to download your data; this cannot run in the background.</div>
    <div>Also, you may receive an E-mail from Amazon about your shopping history report being ready. The app will download and analyze this report.</div>
    <StyledButtonSecondary to="/">See my profile</StyledButtonSecondary>
    </StyledExit>
    </ExitWrapper>;
}

const ShoppingReport = () => {
  const [viewIndex, setViewIndex] = useState(0);
  const { amazonHandler } = useContext(AmazonContext);

  const OnboardingRef = useRef<HTMLDivElement>(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipeDistance, setSwipeDistance] = useState(0);
  const MIN_SWIPE_DISTANCE = 50;

  const today = new Date();
  const spendingChangeData = ReportHelpers.spendingAndChange(today, amazonHandler);
  const threeYearSpend = threeYearPage(spendingChangeData);
  const spendingChange = spendingChangePage(spendingChangeData);
  const predictedSpendingData = ReportHelpers.predictSpend(today, amazonHandler);
  const predictedSpend = predictedSpendPage(predictedSpendingData);
  const amzNetWorth = netWorthPage(spendingChangeData);
  const lastPage = lastPageHtml();

  const panels = [
    threeYearSpend,
    spendingChange,
    predictedSpend,
    amzNetWorth,
    lastPage,
  ];
  const currentView = panels[viewIndex]


  const handleTouchStart = (e:any) => {
    setTouchEnd(null);
    setSwipeDistance(0);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e:any) => {
    setTouchEnd(e.targetTouches[0].clientX);
    touchStart && touchEnd && setSwipeDistance(touchStart - touchEnd);
    const transformDistance = -(swipeDistance / 5);
    if (((viewIndex !== 0 && swipeDistance < 0) || (viewIndex !== 7 && swipeDistance > 0)) && OnboardingRef.current) {
      OnboardingRef.current.style.transform = `translate(${transformDistance}%, 0)`;
    }
  };

  const handleTouchEnd = (e:any) => {
    if (!OnboardingRef.current) return;
    OnboardingRef.current.style.transform = `translate(0)`;
    if (!touchStart || !touchEnd) return;
    const isLeftSwipe = swipeDistance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = swipeDistance < -MIN_SWIPE_DISTANCE;
    if (isLeftSwipe || isRightSwipe) {
      isLeftSwipe && viewIndex < panels.length - 1 && setViewIndex((prev) => prev + 1);
      isRightSwipe && viewIndex > 0 && setViewIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    MobileEventHandler.analytics("shopping_report_wv")
  }, [])

    return (
        <StyledWrapper>
        <StyledProgressBar>
        {panels.map((val, index) => {
            if (index <= viewIndex) {
            return <div key={index} style={{ opacity: 1 }} onClick={() => setViewIndex(index)} />;
            }
            return <div key={index} onClick={() => setViewIndex(index)} />;
            })}
        </StyledProgressBar>
        <StyledNavOptions>
        <StyledLinkHome to="/">Skip</StyledLinkHome>
        {viewIndex < panels.length - 1 && (
            <StyledNext onClick={() => setViewIndex((prev) => prev + 1)}>
            <SvgNext />
            </StyledNext>
            )}
        </StyledNavOptions>
        <StyledPage ref={OnboardingRef}>
        { currentView }
        <StyledSwipeArea
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        />
        </StyledPage>
          </StyledWrapper>
          );
};

export default ShoppingReport;
