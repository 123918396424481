import { useEffect, useMemo, useState } from "react";
import { category_titles } from "../Dashboard/categories/categories";
import { MarketplaceItem } from "./marketplaceTypes";
import { UskoCategory } from "amazon";

import styled from "styled-components";

import ItemCarousel from "./ItemCarousel";

const MarketplaceWrapper = styled.div`
  padding: 0rem 0 1rem 1rem;
  min-height: 100vh;
  text-transform: capitalize;
`;
const Title = styled.div`
  padding-bottom: 0.375rem;
  text-transform: capitalize;
`;

const caughtUnsorted = new Map([
  ['All Departments', 'Other'],
  ['Arts, Crafts & Sewing', 'Arts, Crafts, & Sewing'],
  ['Automotive', 'Automotive Parts & Accessories'],
  ['Baby Products', 'Baby'],
  ['Clothing, Shoes & Jewelry', 'Clothing, Shoes and Jewelry'],
  ['Health & Household', 'Health, Household & Baby Care'],
  ['Medical Supplies & Equipment', 'Other'],
  ['Patio, Lawn & Garden', 'Garden & Outdoor'],
  ['Power Tool Parts & Accessories', 'Tools & Home Improvement'],
  ['Safety & Security', 'Other'],
  ['Shoe, Jewelry & Watch Accessories', 'Clothing, Shoes and Jewelry'],
  ['Small Appliance Parts & Accessories', 'Appliances'],
]);

type MarketplaceProps = {
  earnedCredits?: string;
  marketplaceAllItems: MarketplaceItem[];
  marketplaceItems: MarketplaceItem[];
}

function Marketplace({ earnedCredits, marketplaceAllItems, marketplaceItems }: MarketplaceProps) {
  const [allItemsSorted, setAllItemsSorted] = useState<Map<string, MarketplaceItem[]>|undefined>(undefined);
  const categoryStack = Object.values(UskoCategory);
  const categoryToTitles = useMemo(() => {
    const temp = new Map();
    const titleStack = Object.values(category_titles);
    categoryStack.forEach((cat, index) => {
      temp.set(cat, titleStack[index]);
    });
    return temp;
  }, [categoryStack]);

  const sortedProductsMap = useMemo(() => {
    const temp = new Map<string, MarketplaceItem[]>();
    temp.set('Automotive Parts & Accessories', []); // insert Automotive Parts & Accessories category first only for Miami Auto Weekend
    Array.from(categoryToTitles.keys()).forEach(title => {
      temp.set(title, []);
    });
    marketplaceItems.forEach(product => {
      if(product.productCategory && temp.has(product.productCategory)) {
        temp.get(product.productCategory)?.push(product);
      } else if (product.productCategory && caughtUnsorted.get(product.productCategory)) {
        temp.get(caughtUnsorted.get(product.productCategory) as string)?.push(product);
      } else {
        temp.get('Other')?.push(product);
      }
    });
    for(let [key, list] of temp) {
      const duplicatesRemoved: MarketplaceItem[] = [];
      list.sort((a, b) => a.productName.localeCompare(b.productName));
      list.forEach((product, index) => {
        if(index === 0 || product.productName.localeCompare(list[index - 1].productName) !== 0) duplicatesRemoved.push(product);
      });
      temp.set(key, duplicatesRemoved);
    }
    for(const [key, list] of temp) {
      if(list.length < 3) temp.delete(key);
    }
    for(const [key, list] of temp) {
      list.sort((a, b) => Number.parseFloat(b.averageRating) - Number.parseFloat(a.averageRating));
    }
    return temp;
  }, [marketplaceItems, categoryToTitles]);

  const carouselArray = useMemo(() => {
    const tempArray: JSX.Element[] = [];
    sortedProductsMap.forEach((value, key) => {
      tempArray.push(
        <ItemCarousel
          key={key}
          allItemsSorted={allItemsSorted}
          category={key}
          itemStack={value}
        />
      );
    });
    return tempArray;
  }, [allItemsSorted, sortedProductsMap]);

  useEffect(() => {
    const temp = new Map<string, MarketplaceItem[]>();
    Array.from(categoryToTitles.keys()).forEach(title => {
      temp.set(title, []);
    });
    marketplaceAllItems.forEach(product => {
      if(product.productCategory && temp.has(product.productCategory)) {
        temp.get(product.productCategory)?.push(product);
      } else if (product.productCategory && caughtUnsorted.get(product.productCategory)) {
        temp.get(caughtUnsorted.get(product.productCategory) as string)?.push(product);
      } else {
        temp.get('Other')?.push(product);
      }
    });
    for(let [key, list] of temp) {
      const duplicatesRemoved: MarketplaceItem[] = [];
      list.sort((a, b) => a.productName.localeCompare(b.productName));
      list.forEach((product, index) => {
        if(index === 0 || product.productName.localeCompare(list[index - 1].productName) !== 0) duplicatesRemoved.push(product);
      });
      temp.set(key, duplicatesRemoved);
    }
    for(const [key, list] of temp) {
      if(list.length < 3) temp.delete(key);
    }
    for(const [key, list] of temp) {
      list.sort((a, b) => Number.parseFloat(b.averageRating) - Number.parseFloat(a.averageRating));
    }
    setAllItemsSorted(temp);
  }, [marketplaceAllItems]);

  return(
    <MarketplaceWrapper>
      <Title>earn usko credits with purchase 🤑</Title>
      {carouselArray.map(elem => elem)}
    </MarketplaceWrapper>
  );
}

export default Marketplace;
