import styled from "styled-components";

import GenerateImage from "./components/GenerateImage";
import SnakeBg from "./components/SnakeBg";
import {useEffect} from "react";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const ShareWrapper = styled.div`
  display: flex;
  position: relative;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-family: "Inter", sans-serif;
  background: linear-gradient(180deg, #9d7de9 -3.25%, #6890fa 45.4%);
  text-align: left;
  line-height: 1.3;
  gap: 2rem;
  h2 {
    padding: 0.5rem 1rem;
  }
  p {
    padding: 0.5rem 1rem;
  }
`;
const StyledSvg = styled(SnakeBg)`
  position: absolute;
  top: 18.5vh;
  width: 100%;
  z-index: 0;
`;

function Share({ versions }: { versions: {[key: string]: any}}){
  useEffect(() => {
    MobileEventHandler.analytics("share_wrapper_wv")
  }, [])
	return(
		<ShareWrapper>
      <GenerateImage versions={versions} />
      <StyledSvg />
		</ShareWrapper>
	);
}

export default Share;
