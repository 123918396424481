import styled from "styled-components";

import ViewAllArrowIcon from '../icons/view-all-arrow.svg';

const ViewAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #EFEDFF;
  border-radius: 100px;

  width: 34px;
  height: 34px;
`;

function ViewAllArrow() {
  return <ViewAllWrapper>
    <img src={ViewAllArrowIcon} alt={'View All'}/>
  </ViewAllWrapper>
}

export default ViewAllArrow;