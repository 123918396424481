import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_CLICK_LINK } from "components/Bridge/outgoing_events";
import AmazonContext from "components/Contexts/AmazonContext";

import styled from "styled-components";
import { SmallText, TabLinkText } from "../styles/generics";

import coin from "icons/coin.svg";
import help from "icons/help.svg";
import redeem from "icons/redeem.svg";

const WalletWrapper = styled.div`
  min-height: calc(100vh - 7.8125rem);
`;
const BalanceCard = styled.div`
  width: calc(100vw - 2rem);
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 1rem 3.1875rem;
`;
const CurrentBalance = styled.div`
  width: 100%;
  height: 5.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8A7DD7;
  padding: 0.625rem 0;
  gap: 0.625rem;
  color: #fff;
`;
const CurrentBalanceTitle = styled(TabLinkText)`
  color: #FFF;
  text-transform: capitalize;
  line-height: 1.3125rem;
  letter-spacing: -0.03125rem;
`;
const CurrentBalanceText= styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 1.75rem;
`;
const CurrentBalanceCreditsDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.05875rem;
`;
const CoinImg = styled.img`
  width: 2.15375rem;
  margin-right: 0.588125rem;
`;
const USKSpan = styled.span`
  font-size: 0.80875rem;
  text-transform: uppercase;
  line-height: 0.80875rem;
`;
const PendingBalance = styled.div`
  width: 100%;
  height: 3.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: space-evenly;
  background-color: #EFEDFF;
`;
const PendingBalanceTitle = styled(SmallText)`
`;
const PendingBalanceCreditsDisplay = styled.div`
  display: flex;
  align-items: center;
`;
const PendingBalanceNum = styled(TabLinkText)`
  color: #4c4c4c;
  letter-spacing: -0.0735rem;
`;
const CoinImgSmall = styled.img`
  width: 0.720625rem;
  margin-right: 0.254375rem;
`;
const BalanceOptions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7rem;
  padding: 1rem 0;
  background-color: #FFF;
  gap: 2.5625rem;
`;
const HelpImg = styled.img`
  width: 3.125rem;
  padding: 0.459375rem;
  border-radius: 4.595562rem;
  background-color: #EFEDFF;
`;
const ButtonWrapper =styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
`;
const ButtonLabel = styled(TabLinkText)`
  text-transform: capitalize;
`;
const RedeemImg = styled.img`
  width: 3.125rem;
  padding: 0.459375rem;
  border-radius: 4.595562rem;
  background-color: #EFEDFF;
`;
const HistoryWrapper = styled.div`
`;
const HistoryTitle = styled(TabLinkText)`
  padding: 0 1rem 0.875rem;
  border-bottom: 1px solid #E8E8E8;
`;
/** Placeholder for History feature template, saved for use when feature is finished
const HistoryItemPlaceholderWrapper = styled.div`
  width: 100%;
  height: 4.9375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;
const HistoryItemDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 1.125rem;
`;
const HistoryItemImage = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  background-color: #FFF;
  border-radius: 100rem;
`;
const HistoryItemDesc = styled.div`
  width: 11.8125rem;
  height: 3.9375rem;
  background-color: #FFF;
`;
const HistoryItemCreditEarned = styled.div`
  width: 4.75rem;
  height: 1.25rem;
  background-color: #FFF;
  border-radius: 1.9375rem;
`;
const HistoryFeaturePlaceholder = Array(3).fill(
  <HistoryItemPlaceholderWrapper>
    <HistoryItemDetails>
      <HistoryItemImage></HistoryItemImage>
      <HistoryItemDesc></HistoryItemDesc>
    </HistoryItemDetails>
    <HistoryItemCreditEarned></HistoryItemCreditEarned>
  </HistoryItemPlaceholderWrapper>
);
**/
const FeatureNotAvailable = styled.div`
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const FeatureNotAvailableMsg = styled.div`
  padding: 1rem;
  border-radius: 1.25rem;
  background-color: #fff;
`;

type WalletProps = {
  earnedCredits?: string;
  pendingBalance: number|undefined;
  setLastCheckedBalance: React.Dispatch<React.SetStateAction<{[key: string]: number}>>;
}

function Wallet({ earnedCredits, pendingBalance, setLastCheckedBalance }: WalletProps) {
  const navigate = useNavigate();
  const { localClaimedTotal } = useContext(AmazonContext);
  const creditsBalance = useMemo(() => {
    return earnedCredits ? Number.parseInt(earnedCredits) + localClaimedTotal : localClaimedTotal;
  }, [earnedCredits, localClaimedTotal]);
  useEffect(() => {
    setLastCheckedBalance({
      current: earnedCredits ? Number.parseInt(earnedCredits) + localClaimedTotal : localClaimedTotal,
      pending: pendingBalance || 0,
    })
  }, [setLastCheckedBalance]);

  return(
    <WalletWrapper>
      <BalanceCard>
        <CurrentBalance>
          <CurrentBalanceTitle>current wallet balance</CurrentBalanceTitle>
          <CurrentBalanceCreditsDisplay>
            <CoinImg src={coin} />
            <CurrentBalanceText>
              {creditsBalance}<USKSpan>usk</USKSpan>
            </CurrentBalanceText>
          </CurrentBalanceCreditsDisplay>
        </CurrentBalance>
        <PendingBalance>
          <PendingBalanceTitle>Pending Balance (On it's way!)</PendingBalanceTitle>
          <PendingBalanceCreditsDisplay>
            <CoinImgSmall src={coin} />
            <PendingBalanceNum>
              {`${pendingBalance ?? '0'} USK`}
            </PendingBalanceNum>
          </PendingBalanceCreditsDisplay>
        </PendingBalance>
        <BalanceOptions>
          <ButtonWrapper
            onClick={() => {
              MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://www.instagram.com/p/CxV67TQLPV-/?igshid=MzRlODBiNWFlZA==')
            }}
          >
            <RedeemImg src={redeem} />
            <ButtonLabel>redeem</ButtonLabel>
          </ButtonWrapper>
          <ButtonWrapper onClick={() => navigate('/faq')}>
            <HelpImg src={help} />
            <ButtonLabel>help</ButtonLabel>
          </ButtonWrapper>
        </BalanceOptions>
      </BalanceCard>
      <HistoryWrapper>
        <HistoryTitle>History</HistoryTitle>
        <FeatureNotAvailable>
          <FeatureNotAvailableMsg>This feature is coming soon!</FeatureNotAvailableMsg>
        </FeatureNotAvailable>
      </HistoryWrapper>
    </WalletWrapper>
  );
}

export default Wallet;
