import { useMemo, useRef, useState } from "react";

import styled from "styled-components";
import { MIN_SWIPE_DISTANCE } from "./generics";

import CarouselIndicator from "../NotificationCarousel/CarouselIndicator";

const CarouselPanelWrapper = styled.div<any>`
  position: relative;
  width: 100%;
  overflow: hidden;
`;
const CarouselInner = styled.div<any>`
  display: flex;
  flex-wrap: nowrap;
  transition: 0.3s;
`;

function CarouselPanel({ children, numSlides }: {
    children: JSX.Element,
    numSlides: number
  }){

  const panelRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [swipeDistance, setSwipeDistance] = useState<number>(0);
  const currentTranslate = useMemo(() => ((currentSlide * 100) - 100),[currentSlide]);

  const handleTouchStart = (e: TouchEvent) => {
    setTouchEnd(null);
    setSwipeDistance(0);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
    touchEnd && touchStart && setSwipeDistance(touchStart - touchEnd);
    const transformDistance = -(swipeDistance/5);
    if(panelRef && panelRef.current){
      panelRef.current.style.transform = `translate(-${currentTranslate - transformDistance}%, 0)`;
    }
  };

  const handleTouchEnd = (e: TouchEvent) => {
    if(!touchStart || !touchEnd) return;
    const isLeftSwipe = swipeDistance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = swipeDistance < -(MIN_SWIPE_DISTANCE);
    if(isLeftSwipe && currentSlide !== numSlides){
      setCurrentSlide(prev => prev + 1);
      if(panelRef && panelRef.current) panelRef.current.style.transform = `translate(-${currentTranslate + 100}vw, 0)`;
      return;
    };
    if(isRightSwipe && currentSlide !== 1){
      setCurrentSlide(prev => prev - 1);
      if(panelRef && panelRef.current) panelRef.current.style.transform = `translate(-${currentTranslate - 100}vw, 0)`;
      return;
    };
    if(panelRef && panelRef.current) panelRef.current.style.transform = `translate(-${currentTranslate}vw, 0)`;
  };

  return(
    <CarouselPanelWrapper
    >
      <CarouselInner
        ref={panelRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >  
        {children}
      </CarouselInner>
      <CarouselIndicator currentSlide={currentSlide} totalSlides={numSlides} />
    </CarouselPanelWrapper>
  );
}

export default CarouselPanel;
