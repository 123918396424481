import styled from "styled-components";

import ExclamationIcon from 'icons/exclamation-icon.svg';

import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_RECONNECT_AMAZON } from "components/Bridge/outgoing_events";


const LoggedInPromptWrapper = styled.div`
  padding: 10px 19px 19px 19px;

  background-color: #FFFFFF;
`;

const LoggedInPromptHeader = styled.div`
  display: flex;
  align-content: center;

  padding-bottom: 14.5px;

  span {
    padding-left: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    color: #372F6D;
  }
`;

const LoggedInPromptBody = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
`;

const LoggedInButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin-top: 16px;
  margin-bottom: 16px;

  height: 52px;

  :active{
    box-shadow:1px 1px black;
  }

  background: linear-gradient(270deg, #7551CB 3.49%, #5172C8 92.38%);
  border-radius: 15px;
  border: none;
`;

const LoggedInButtonText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  color: #FFFFFF;
`;

const DisclaimerText = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

type LoggedInPromptProps = {
  logged_in: boolean; 
}

function LoggedInPrompt({ logged_in }: LoggedInPromptProps) {
  if (logged_in) {
    return null
  }
  return <LoggedInPromptWrapper>
    <LoggedInPromptHeader>
      <img alt="Circle with excalamation in it." src={ExclamationIcon} /><span>There was a problem</span>
    </LoggedInPromptHeader>
    <LoggedInPromptBody>
      We can’t sign in to your Amazon account because you’ve been signed out. 
      Please sign back in to get updated data results on your Usko dashboard*.  
    </LoggedInPromptBody>
    <LoggedInButton onClick={() => {
      MobileEventHandler.post_event(WEBVIEW_RECONNECT_AMAZON);
    }}>
      <LoggedInButtonText>Sign in to Amazon</LoggedInButtonText>
    </LoggedInButton>
    <DisclaimerText>*Data results shown below are currently out of date.</DisclaimerText>
  </LoggedInPromptWrapper>; 
}

export default LoggedInPrompt;