import { AmazonHelpers } from "../../../../amazon";
import { useContext, useMemo } from "react";
import { FlexRow, GraphCenter, GraphWindow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, SmallHeading } from "../../styles/generics";

import AmazonContext from "components/Contexts/AmazonContext";
import FiveYearPurchaseChart from "./charts/FiveYearPurchaseChart";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const PurchasesText = styled(SmallHeading)`
  line-height: 1.664375rem;
`;

function FiveYearPurchasePanel() {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const { years, data } = useMemo(() => {
    const ordersByYear = AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders());
    const years = Object.keys(ordersByYear)
      .map((year) => parseInt(year))
      .slice(-5);
    const data = years
      .map((year) => ordersByYear[year].length)
    return { years, data };
  }, [amazonHandler]);

  // Basic validation. Don't render if data and labels are not passed into the component.
  if (!data || !data.length) {
    console.log("WARNING: Missing {data} for FiveYearPurchasePanel. Graph did not render.");
    return null;
  }
  // Basic validation. Don't render if data and labels are not passed into the component.
  if (!years || !years.length) {
    console.log("WARNING: Missing {years} for FiveYearPurchasePanel. Graph did not render.");
    return null;
  }

  return <PanelWrapper>
    <FlexRow>
      <Left>
        <PanelTitle>Number of Purchases 5 Yr. Span</PanelTitle>
      </Left>
      <Right
        onClick={() => navigate(`/dashboard/purchases`)}
      >
        {/* <ViewAllArrow/> */}
      </Right>
    </FlexRow>
    <PurchasesText>{AmazonHelpers.numberFormat(data.reduce((acc, n) => acc + n))} Items</PurchasesText>
    <GraphWindow height={'150px'}>
      <GraphCenter height={'150px'}>
        <FiveYearPurchaseChart labels={years} tableData={data}/>
      </GraphCenter>
    </GraphWindow>
  </PanelWrapper>
}

export default FiveYearPurchasePanel;
