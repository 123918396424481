import styled, { keyframes } from "styled-components";


const rotate = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const LoadingRing = styled.div<any>`
  display: inline-block;
  position: relative;
  height: ${props => props.size ? props.size : '15px'};
  width: ${props => props.size ? props.size : '15px'};

  margin-top: -3px;

div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: ${props => props.size ? props.size : '15px'};
  width: ${props => props.size ? props.size : '15px'};
  margin: ${props => props.thickness ? props.thickness : '2px'};
  border: ${props => props.thickness ? props.thickness : '2px'} solid #fff;
  border-radius: 50%;
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${props => props.color ? props.color : '#6B6BD4'} transparent transparent transparent;
}

div:nth-child(1) {
  animation-delay: -0.45s;
}

div:nth-child(2) {
  animation-delay: -0.3s;
}

div:nth-child(3) {
  animation-delay: -0.15s;  
}
`;

type LoadingIconProps = {
  size: number,
  thickness: number,
  color?: string,
}

const LoadingIcon = ({
  size, 
  thickness,
  color
} : LoadingIconProps) => <LoadingRing 
  size={size+'px'} 
  thickness={thickness+'px'}
  color={color}
>
  <div></div><div></div><div></div><div></div>
</LoadingRing>

export default LoadingIcon;
