import styled from "styled-components";

import BackArrowIcon from "icons/back-arrow-icon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

const DashboardNavWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  padding-top: 10px;

  height: 50px;
  background: #F6F5FF;
`;

const CenterWrapper = styled.div`
  display: flex;

  color: #4D4C4C;
`;

const TransactionsNavIcon = styled.img`
  position: absolute;

  left: 15px;
`;

const UskoIconImg = styled.div`
  position: absolute;
  right: 5px;

  color: #4D4C4C;
`;

type ThinNavProps = {
  title?: string,
  url: string
}

function QueryPreserveBar({title, url}: ThinNavProps) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const oldParams = useMemo(() => params, []);

  const preserveQueryNav = useCallback(() => {
    navigate(url + '?' + params.toString());
  }, [navigate, params, url]);

  return <DashboardNavWrapper>
    <TransactionsNavIcon
      onClick={() => navigate(url + "?" + oldParams.toString())}
      src={BackArrowIcon}
      alt="Back to the Dashboard Icon"
    />
    <CenterWrapper>{title}</CenterWrapper>
    <UskoIconImg onClick={preserveQueryNav}>
      Done
    </UskoIconImg>
  </DashboardNavWrapper>
}

export default QueryPreserveBar;
