import LoadingContainer from "components/App/LoadingContainer";
import FiveYearSpendPanel from "./panelsv2/FiveYearSpendPanel";
import SpendingForecastPanel from "./panelsv2/SpendingForecastPanel";
import WeeklySpendingPanel from "./panelsv2/WeeklySpendingPanel";
import YearlyExpenditurePanel from "./panelsv2/YearlyExpenditurePanel";
import SharePanel from "./panelsv2/SharePanel";
import {useEffect} from "react";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

interface SpendingProps {
  showSharePanel: boolean;
  setShowSharePanel: (b: boolean) => void;
}

function Spending({ showSharePanel, setShowSharePanel }: SpendingProps) {
  useEffect(() => {
    MobileEventHandler.analytics("spending_screen_wv")
  }, [])
  return (
    <>
      <LoadingContainer>
        <SpendingForecastPanel />
      </LoadingContainer>
      <LoadingContainer>
        <FiveYearSpendPanel />
      </LoadingContainer>
      <SharePanel showSharePanel={showSharePanel} setShowSharePanel={setShowSharePanel} />
      <LoadingContainer>
        <YearlyExpenditurePanel />
      </LoadingContainer>
      <LoadingContainer>
        <WeeklySpendingPanel />
      </LoadingContainer>
    </>
  );
}

export default Spending;
