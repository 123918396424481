import { UskoCategoryMap } from "../../amazon";
import { UskoCategory } from "../../amazon/types/categories";

function getPrefix(s: string, i: number): string {
  return s.substring(0, i);
}

export function categoryLookup(s: string): UskoCategory {
  const uClass = UskoCategoryMap.get(getPrefix(s, 6));
  if (uClass) return uClass;

  const family = UskoCategoryMap.get(getPrefix(s, 4));
  if (family) return family;

  const segment = UskoCategoryMap.get(getPrefix(s, 2));
  if (segment) return segment;

  return UskoCategory.Unknown;
}

export const categories = [
  UskoCategory.Appliances,
  UskoCategory.Apps,
  UskoCategory.Automotive,
  UskoCategory.Baby,
  UskoCategory.Beauty,
  UskoCategory.Books,
  UskoCategory.CDs,
  UskoCategory.Cells,
  UskoCategory.Clothing,
  UskoCategory.Collectibles,
  UskoCategory.Computers,
  UskoCategory.Electronics,
  UskoCategory.Garden,
  UskoCategory.Groceries,
  UskoCategory.Health,
  UskoCategory.Home,
  UskoCategory.Industrial,
  UskoCategory.Kindle,
  UskoCategory.Luggage,
  UskoCategory.Movies,
  UskoCategory.Musical,
  UskoCategory.Office,
  UskoCategory.Pet,
  UskoCategory.Sports,
  UskoCategory.Tools,
  UskoCategory.Toys,
  UskoCategory.Video,
];
