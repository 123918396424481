import { AmazonHelpers } from "../../../../amazon";
import { useContext, useMemo, useState } from "react";
import { FlexRow, GraphCenter, GraphWindow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, PanelText, PanelBlurb, PanelBlurbHeroText } from "../../styles/generics";
import { getWeekPurchasesData, months } from "./utils";
import DateDropdown from "../modals/DateDropdown";

import AmazonContext from "components/Contexts/AmazonContext";
import WeeklyPurchasesChart from "./charts/WeeklyPurchasesChart";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const PurchasesAmount = styled(PanelBlurbHeroText)`
`;

const PurchasesText = styled(PanelText)`
`;

function WeeklyPurchasesPanel() {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const [month, setMonth] = useState(months[new Date().getMonth()]);
  const [year, setYear] = useState(new Date().getFullYear());

  const yearData = useMemo(() => 
    AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders(), [year, year - 1])
  , [amazonHandler, year]);

  const monthData = useMemo(() => {
    const data = {
      thisYear: AmazonHelpers.SplitOrdersByMonth(yearData[year] || []),
      lastYear: AmazonHelpers.SplitOrdersByMonth(yearData[year - 1] || [])
    }
    return data;
  }, [year, yearData]);

  const { weekLabels, weekData } = useMemo(() => 
    getWeekPurchasesData(month, monthData, year)
  , [month, monthData, year]);

  return <PanelWrapper>
    <FlexRow>
      <Left style={{display: 'flex', alignItems: 'center'}}>
        <PanelTitle>Weekly Purchases: </PanelTitle>
        <DateDropdown 
          month={month} 
          months={months} 
          setMonth={setMonth} 
          year={year} 
          setYear={setYear}
        />
      </Left>
      <Right>
        <div onClick={() => navigate('/dashboard/purchases')}>
          {/* <ViewAllArrow /> */}
        </div>
      </Right>
    </FlexRow>
    <GraphWindow height={'150px'}>
      <GraphCenter height={'150px'}>
        <WeeklyPurchasesChart labels={weekLabels} tableData={weekData} />
      </GraphCenter>
    </GraphWindow>
    <div style={{display: 'flex'}}>
      <PanelBlurb>
        <PurchasesAmount color={'#5ECAD9'}>
					{AmazonHelpers.numberFormat(monthData.lastYear[months.indexOf(month)].length)}
        </PurchasesAmount>
        <PurchasesText>
          {month + ' ' + (year - 1)} Purchases
        </PurchasesText>
      </PanelBlurb>
      <PanelBlurb>
        <PurchasesAmount color={'#6B6BD4'}>
					{AmazonHelpers.numberFormat(monthData.thisYear[months.indexOf(month)].length)}	
        </PurchasesAmount>
        <PurchasesText>
          {month + ' ' + year} Purchases
        </PurchasesText>
      </PanelBlurb>
    </div>
  </PanelWrapper>;
}

export default WeeklyPurchasesPanel;
