import { AmazonHelpers } from "amazon";
import { Bar } from "react-chartjs-2";
import { GRAPH_NUMBER } from "../../../styles/generics";

type WeeklySpendingChartProps = {
  labels: string[],
  tableData: {[id: string]: number[]}
  horizontal?: boolean
}

function WeeklySpendingChart({labels, tableData, horizontal}: WeeklySpendingChartProps) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x1: {
        grid: {
          display: false,
        },
      },
      y1: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          callback: function(value: any) {
            return AmazonHelpers.noDecimalUsdFormat(value);
          },
          font: {
            size: GRAPH_NUMBER,
          },
        }
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        backgroundColor: '#8A7DD7',
        barThickness: 15,
        barPrecentage: 0.5,
        borderColor: '#00000000',
        borderRadius: 100,
        borderWidth: 2,
        data: tableData.thisYear,
      }
    ],
  };

  return <Bar options={options} data={data} />
}

export default WeeklySpendingChart;
