import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_CLICK_LINK } from "components/Bridge/outgoing_events";

import styled from "styled-components";
import { PanelWrapper, SmallText, TabLinkText } from "../styles/generics";

const FAQPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;
const PanelQuestionTitle = styled(TabLinkText)`
  line-height: 1.3125rem;
  letter-spacing: -0.03125rem;
  padding-bottom: 0.5rem;
`;
const PanelAnswerBody = styled(SmallText)`
  letter-spacing: -0.03125rem;
`;
const BottomBlurb = styled(SmallText)`
  margin: 0 1rem;
  font-weight: 700;
  letter-spacing: -0.03125rem;
  text-align: center;
`;
const EmailSpan = styled.a`
  text-decoration: none;
`;

const QAStack = [
  {
    question: "What are Usko Credits?",
    answer: "Usko Credits are credits that you can earn in the Usko app when you purchase products in the Discover tab or read messages from brands in the Offers tab.",
  },
  {
    question: "How much are Usko Credits worth?",
    answer: "Currently, each Usko credit is worth 2 cents. This means that when you reach 1000 Usko credits, your balance is worth $20.",
  },
  {
    question: "I bought something on Usko Discover, but my credits haven’t updated. What’s going on?",
    answer: "Before we can issue credits to you, we have to verify your purchase with the brand that you bought from. This process can take up to 2 weeks. Don’t worry, as soon as we have confirmation, we are going to update your balance automatically.",
  },
  {
    question: "How do I cash out my credits?",
    answer: "Each month, Usko will host a cash out day when you cash out your credits. Stay tuned to our social media (Instagram and Twitter) for instructions on how you can cash out. As of September 2023, anyone with over 1000 credits earned since September 1st will be able to cash out.",
  },
  {
    question: "How do I get my cash after I cash out?",
    answer: "We will email you an Amazon gift card.",
  },
  {
    question: "How does Usko pay so much more cash back versus other cash back apps?",
    answer: "For a limited time, we are giving the *full* cash back that we earn from brands to you, our users. This is to thank you for being an early adopter and helping us grow the platform. In 2024, we expect the cash back amounts to reduce so tell all of your friends and family to shop now while the cash back amount is at its highest.",
  }
]

function FAQPage() {
  return(
    <FAQPageWrapper>
      {QAStack.map((q, i) => {
        return(
          <PanelWrapper key={`FAQPage_question_${i}`}>
            <PanelQuestionTitle>
              {q.question}
            </PanelQuestionTitle>
            <PanelAnswerBody>
              {q.answer}
            </PanelAnswerBody>
          </PanelWrapper>
        );
      })}
      <BottomBlurb>
        If you have a question that’s not answered here, please email&nbsp;
        <EmailSpan
          onClick={() => {
            MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'mailto:support@usko.app');
          }}
        >
          support@usko.app
        </EmailSpan>
        &nbsp; so we can answer your question and add it to the list.
      </BottomBlurb>
    </FAQPageWrapper>
  );
}

export default FAQPage;
