import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import DownChevron from 'icons/down-chevron-icon-black.svg';

const DropdownToggle = styled(Dropdown.Toggle)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :active {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DatePicker = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.36125rem;
`;

const DatePickerText = styled.span`
  font-size: 1rem;
  line-height: 1.21rem;
  text-align: center;

  padding-left: 1.6875rem;
  padding-right: 1.6875rem;
`;

const DropdownItem = styled(Dropdown.Item)`
  width: 75px;
  height: 37px;

  background: #FFFFFF;
  border: 2px solid #B8C3E9;
  border-radius: 100px;

  text-align: center;
  
  opacity: 0.5;

  margin-right: 8px;
  margin-bottom: 8px;
`;

type DateDropdownProps = {
  months: string[],
  month: string,
  setMonth: Dispatch<SetStateAction<string>>,
  year: number,
  setYear: Dispatch<SetStateAction<number>>
}

const DateDropdown = ({ months, month, setMonth, year, setYear }: DateDropdownProps) => {
  const options = useMemo(() => {
    return months.map(m => <DropdownItem
      key={m}
      eventKey={m}
    >
      {m}
    </DropdownItem>);
  }, [months]);

  const handleChangeYear = useCallback((shift: number) => {
    setYear(prevYear => {
      if(prevYear + shift > new Date().getFullYear()){
        return prevYear;
      }
      return prevYear + shift;
    });
  }, [setYear]);

  const handleOnSelect = useCallback((eventKey: any, event: any) => {
    setMonth(eventKey);
  }, [setMonth]);

  return <Dropdown onSelect={handleOnSelect}>
    <DropdownToggle>
      <DatePicker>{month + " " + year}</DatePicker>
    </DropdownToggle>
    <Dropdown.Menu>
      <div
        style={{display: 'flex', justifyContent: 'center', margin: 15}}
      >
        <img 
          style={{transform: 'rotate(90deg)'}}
          onClick={() => handleChangeYear(-1)}
          src={DownChevron} 
          alt="Back a Year"
        />
        <DatePickerText>{year}</DatePickerText>
        <img 
          style={{transform: 'rotate(-90deg)'}}
          onClick={() => handleChangeYear(1)}
          src={DownChevron} 
          alt="Forward a year"
        />
      </div>
      <div
        style={{ display: 'flex', padding: 15, minWidth: 300, flexFlow: 'row wrap', justifyContent: 'space-around' }}
      >
        {options}
      </div>
    </Dropdown.Menu>
  </Dropdown>
}

export default DateDropdown;
