import {useContext, useMemo, useState, useRef, ReactElement, useEffect} from "react";
import { Link } from "react-router-dom";
import { AmazonHandler, AmazonHelpers, UskoCategory, OrderStats, EmptyOrder } from "amazon";

import AmazonContext from "components/Contexts/AmazonContext";
import SnakeLeft from "./SnakeLeft";
import CategoriesSvgs from "./CategoriesSvgs";

import styled from "styled-components";
import {MobileEventHandler} from "../../../Bridge/MobileEventHandler";

const StyledWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(rgb(157, 125, 233) 0%, rgb(104, 144, 250) 100%),
    linear-gradient(0deg, rgb(149, 127, 235), rgb(149, 127, 235));
  position: relative;
  padding: 3rem 0 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;
`;
const StyledProgressBar = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 1rem;
  > div {
    cursor: pointer;
    height: 4px;
    flex: 1;
    background-color: #fff;
    opacity: 0.5;
  }
`;
const StyledNavOptions = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  margin: 0.5rem 0 1rem 0;
  svg {
    height: 1.4rem;
    width: auto;
  }
`;
const StyledLinkHome = styled(Link)`
  text-decoration: none;
  color: #fff;
`;
const StyledHighlightTitle = styled.span`
  color: #82d9f6;
`;
const StyledNext = styled.div`
  cursor: pointer;
`;
const StyledSwipeArea = styled.div`
  height: calc(100% - 7rem);
  width: 100vw;
  position: fixed;
  left: 0;
  top: 7rem;
  z-index: 1;
`;

const SvgNext = () => (
  <svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 24 24"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m13.022 14.999v3.251c0 .412.335.75.752.75.188 0 .375-.071.518-.206 1.775-1.685 4.945-4.692 6.396-6.069.2-.189.312-.452.312-.725 0-.274-.112-.536-.312-.725-1.451-1.377-4.621-4.385-6.396-6.068-.143-.136-.33-.207-.518-.207-.417 0-.752.337-.752.75v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z"
      fillRule="nonzero"
    />
  </svg>
);

const StyledViewTitleText = styled.h1`
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  padding: 0 2rem;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  z-index: 1;
`;
const StyledViewContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.375rem;
  font-weight: 700;
  text-align: center;
`;
const StyledGraph = styled.div`
  border-radius: 14rem;
  width: 14rem;
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid #82d9f6;
  font-size: 1.875rem;
  margin: 3rem 0;
`;
const StyledCategory = styled.div`
  border-radius: 14rem;
  width: 14rem;
  height: 11.5rem;
  overflow: hidden;
  margin: 3rem 0;
  img,
  svg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
const StyledListRow = styled.div`
  font-size: 1.625rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const StyledListRowText = styled.div`
  text-align: left;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledListRowSubText = styled.div`
  font-size: 1rem;
  text-align: left;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledListRowSvg = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: 5rem;
  overflow: hidden;
  flex-shrink: 0;

  img {
    height: 5rem;
    width: 5rem;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 2;
`;
const StyledButtonSecondary = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #fff;
  border-radius: 1rem;
`;
const StyledExit = styled.div`
  flex: 1;
  padding: 1rem 1rem 5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  h3 {
    text-align: center;
    font-size: 1.375rem;
  }
  h1 {
    font-size: 3rem;
    font-weight: 700;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
`;
const StyledSVGContainer = styled(SnakeLeft)`
  position: absolute;
  center: 0;
  height: 80%;
  max-height: 100vh;
  min-width: 45%;
  max-width: 100vw;
  margin-top: 3rem;
`;
const StyledPage = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 6.5rem);
  z-index: 1;
`;

interface OnboardingViewParams {
  type: string
  textOne?: (() => ReactElement) | (() => string)
  textTwo?: () => string
  textThree?: (() => ReactElement) | (() => string)
  image?: () => ReactElement
  list?: [UskoCategory,OrderStats][]
}

const OnboardingView = ({ image, list, type, textOne, textTwo, textThree } : OnboardingViewParams) => {
  switch (type) {
    case "title":
      return (
        <>
          <StyledContainer>
            <StyledViewTitleText style={{ display: "inline" }}>{textOne ? textOne() : ''}</StyledViewTitleText>
          </StyledContainer>
          <StyledSVGContainer>
            <SnakeLeft />
          </StyledSVGContainer>
        </>
      );
    case "graph":
      return (
        <StyledViewContainer>
          <div>{textOne ? textOne() : ''}</div>
          <StyledGraph>{textTwo ? textTwo() : ''}</StyledGraph>
          <div>{textThree ? textThree() : ''}</div>
        </StyledViewContainer>
      );
    case "image":
      return (
        <StyledViewContainer>
          <div>{textOne ? textOne() : ''}</div>
          <StyledCategory>{image ? image() : ''}</StyledCategory>
          <div>
            <StyledViewTitleText style={{ textTransform: "capitalize" }}>
              {textTwo ? textTwo() : ''}
            </StyledViewTitleText>
            <div style={{ marginTop: "2rem", fontSize: "1.125rem" }}>{textThree ? textThree() : ''}</div>
          </div>
        </StyledViewContainer>
      );
    case "list":
      return (
        <StyledViewContainer>
          <div>{textOne ? textOne() : ''}</div>
          {list?.map((value, index) => (
            <StyledListRow key={"list_"+index}>
              <StyledListRowText>
                #{index + 1} <span style={{ textTransform: "capitalize" }}>{value[0]}</span>
                <StyledListRowSubText>
                  {`${AmazonHelpers.numberFormat(
                    value[1].orders,
                  )} purchases, total ${AmazonHelpers.priceFormat(value[1].totalSpending)} spent`}
                </StyledListRowSubText>
              </StyledListRowText>
              <StyledListRowSvg>
                <CategoriesSvgs type={value[0]} />
              </StyledListRowSvg>
            </StyledListRow>
          ))}
        </StyledViewContainer>
      );
    case "exit":
      return (
        <StyledExit>
          <h3>Pretty interesting, huh? Well, there's more where that came from!</h3>
          <h1>
            Check out
            <br /> your profile to
            <br /> learn more <StyledHighlightTitle>cool facts</StyledHighlightTitle> about your{" "}
            <br />
            <StyledHighlightTitle>Amazon data!</StyledHighlightTitle>
          </h1>
          <StyledButtons>
            <StyledButtonSecondary to="/">See my profile</StyledButtonSecondary>
          </StyledButtons>
        </StyledExit>
      );
    default:
      return null;
  }
};

const OnboardingPage = () => {
  const [viewIndex, setViewIndex] = useState(0);
  const { amazonHandler } = useContext(AmazonContext);
  const spendingByYearOrMonthlyData = useMemo(
    () => amazonHandler.getSpendingYearlyOrMonthlyProps(),
    [amazonHandler],
  );
  const yearlyData = useMemo(
    () => AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders()),
    [amazonHandler],
  );
  const categoriesSpendingTableData = useMemo(
    () => {
      const cat = amazonHandler.getCategoriesSpendingTableProps();
      cat.delete(UskoCategory.Processing);
      return cat;
    },
    [amazonHandler],
  );
  const { mostExpensive, mostPurchased } = useMemo(() => {
    const highlights = amazonHandler.getHighlightsPanelProps();
    const mostExpensive = highlights.filter((h) => h.category === "Largest Purchase")[0];
    const mostPurchased = highlights.filter((h) => h.category === "Most Purchased")[0];

    return { mostExpensive, mostPurchased };
  }, [amazonHandler]);

  useEffect(() => {
    MobileEventHandler.analytics("onboarding_report_wv")
  }, [])

  const mostExpensiveItem = useMemo(() => {
    const items = amazonHandler.getOrdersByAsin(mostExpensive.asin);
    if (Array.isArray(items)) {
      return items
        .sort((a, b) => a.orderDate.getTime() - b.orderDate.getTime())
        .slice(-1)
        .pop();
    }
    return EmptyOrder;
  }, [amazonHandler, mostExpensive.asin]);

  const { mostPurchasedQuantity, mostPurchasedTotalSpent } = useMemo(() => {
    const mostPurchasedOrders = AmazonHelpers.CalculateSpending(
      amazonHandler.getOrdersByAsin(mostPurchased.asin),
    );
    const mostPurchasedQuantity = mostPurchasedOrders.orders;
    const mostPurchasedTotalSpent = mostPurchasedOrders.totalSpending;

    return { mostPurchasedQuantity, mostPurchasedTotalSpent };
  }, [amazonHandler, mostPurchased.asin]);

  const highestCategory = useMemo(() => {
    const emptyReturn = { name: "none", year: 0, month: 0, week: 0 };
    if (categoriesSpendingTableData.size === 0) {
      return emptyReturn;
    }

    const highestCategory = [...categoriesSpendingTableData.entries()].reduce((a, e) =>
      e[1] > a[1] ? e : a,
    );

    const yearHandler = AmazonHandler.loadAmazonHandler(
      AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrdersByCategory(highestCategory[0]) || [])[
        new Date().getFullYear() - 1
      ] || [],
    );

    if (!yearHandler) {
      return emptyReturn;
    }

    let spending = yearHandler.getTotalSpending().totalSpending;

    return {
      name: highestCategory[0],
      year: spending,
      month: spending / 12,
      week: spending / 52,
    };
  }, [amazonHandler, categoriesSpendingTableData]);

  const { categoriesSortedSpending, categoriesSortedQuantity } = useMemo(() => {
    const categoriesSortedSpending = [...categoriesSpendingTableData.entries()].sort(
      (a, b) => b[1] - a[1],
    );
    const categoriesSortedQuantity = categoriesSortedSpending
      ?.slice(0, 5)
      ?.map((cat) => AmazonHelpers.CalculateSpending(amazonHandler.getOrdersByCategory(cat[0])));

    return { categoriesSortedSpending, categoriesSortedQuantity };
  }, [amazonHandler, categoriesSpendingTableData]);

  const MOCK_TEXT : OnboardingViewParams[] = useMemo(() => {
    const lastYear =
      spendingByYearOrMonthlyData.yearly?.labels[
        spendingByYearOrMonthlyData.yearly?.labels?.length - 1
      ];
    const lastYearStats = AmazonHelpers.CalculateSpending(yearlyData[Number(lastYear)]);
    const monthlySpending = lastYearStats.totalSpending / 12;
    const weeklySpending = monthlySpending / 4;

    return [
      {
        type: "title",
        textOne: () => (
          <>
            You spent{" "}
            <StyledHighlightTitle>
              {AmazonHelpers.priceFormat(lastYearStats.totalSpending)}
            </StyledHighlightTitle>{" "}
            on Amazon last year on {AmazonHelpers.numberFormat(lastYearStats.orders)} purchases.
          </>
        ),
      },
      {
        type: "graph",
        textOne: () => "Your average monthly spending was",
        textTwo: () => `${AmazonHelpers.priceFormat(monthlySpending)}`,
        textThree: () => (
          <>
            That's an average of <b>{AmazonHelpers.priceFormat(weeklySpending)}</b> per week.
          </>
        ),
      },
      {
        type: "image",
        textOne: () => "Your most shopped category was",
        image: () => <CategoriesSvgs type={categoriesSortedSpending?.[0]?.[0]} />,
        textTwo: () => categoriesSortedSpending?.[0]?.[0],
        textThree: () =>
          `Your total spending in this category was ${AmazonHelpers.priceFormat(
            categoriesSortedSpending?.[0]?.[1],
          )} in the last year.`,
      },
      {
        type: "list",
        textOne: () => "Your most shopped categories",
        list: categoriesSortedSpending ?
          categoriesSortedSpending.slice(0, 5).map((val, index) => [val[0], categoriesSortedQuantity[index]]) :
          [] as [UskoCategory, OrderStats][],
      },
      {
        type: "image",
        textOne: () => "The most expensive item you have ever purchased was",
        image: () => <img src={mostExpensive.img} alt="most expensive purchase" />,
        textTwo: () => mostExpensive.item,
        textThree: () =>
          `You paid ${AmazonHelpers.priceFormat(
            mostExpensive.price,
          )} for this (on ${mostExpensiveItem?.orderDate.toLocaleDateString()}).`,
      },
      {
        type: "image",
        textOne: () =>
          "You bought a lot of items last year, but this was the one you repurchased the most",
        image: () => <img src={mostPurchased.img} alt="most purchased" />,
        textTwo: () => mostPurchased.item,
        textThree: () =>
          `You bought this ${AmazonHelpers.numberFormat(
            mostPurchasedQuantity,
          )} times. You have spent ${AmazonHelpers.priceFormat(mostPurchasedTotalSpent)} total.`,
      },
      {
        type: "graph",
        textOne: () => `Your average monthly spending for ${highestCategory.name} was`,
        textTwo: () => AmazonHelpers.priceFormat(highestCategory.month),
        textThree: () => (
          <>
            That's an average of <b>{AmazonHelpers.priceFormat(highestCategory.week)}</b> per week.
          </>
        ),
      },
      {
        type: "exit",
      },
    ];
  }, [
    categoriesSortedQuantity,
    categoriesSortedSpending,
    highestCategory.month,
    highestCategory.name,
    highestCategory.week,
    mostExpensive.img,
    mostExpensive.item,
    mostExpensive.price,
    mostExpensiveItem?.orderDate,
    mostPurchased.img,
    mostPurchased.item,
    mostPurchasedQuantity,
    mostPurchasedTotalSpent,
    spendingByYearOrMonthlyData.yearly?.labels,
    yearlyData,
  ]);

  const OnboardingRef = useRef<HTMLDivElement>(null);
  const currentView = useMemo(() => MOCK_TEXT[viewIndex], [MOCK_TEXT, viewIndex]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipeDistance, setSwipeDistance] = useState(0);
  const MIN_SWIPE_DISTANCE = 50;

  const handleTouchStart = (e:any) => {
    setTouchEnd(null);
    setSwipeDistance(0);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e:any) => {
    setTouchEnd(e.targetTouches[0].clientX);
    touchStart && touchEnd && setSwipeDistance(touchStart - touchEnd);
    const transformDistance = -(swipeDistance / 5);
    if (((viewIndex !== 0 && swipeDistance < 0) || (viewIndex !== 7 && swipeDistance > 0)) && OnboardingRef.current) {
      OnboardingRef.current.style.transform = `translate(${transformDistance}%, 0)`;
    }
  };

  const handleTouchEnd = (e:any) => {
    if (!OnboardingRef.current) return;
    OnboardingRef.current.style.transform = `translate(0)`;
    if (!touchStart || !touchEnd) return;
    const isLeftSwipe = swipeDistance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = swipeDistance < -MIN_SWIPE_DISTANCE;
    if (isLeftSwipe || isRightSwipe) {
      isLeftSwipe && viewIndex < MOCK_TEXT.length - 1 && setViewIndex((prev) => prev + 1);
      isRightSwipe && viewIndex > 0 && setViewIndex((prev) => prev - 1);
    }
  };

  return (
    <StyledWrapper>
      <StyledProgressBar>
        {MOCK_TEXT.map((val, index) => {
          if (index <= viewIndex) {
            return <div key={index} style={{ opacity: 1 }} onClick={() => setViewIndex(index)} />;
          }
          return <div key={index} onClick={() => setViewIndex(index)} />;
        })}
      </StyledProgressBar>
      <StyledNavOptions>
        <StyledLinkHome to="/">Skip</StyledLinkHome>
        {viewIndex < MOCK_TEXT.length - 1 && (
          <StyledNext onClick={() => setViewIndex((prev) => prev + 1)}>
            <SvgNext />
          </StyledNext>
        )}
      </StyledNavOptions>
      <StyledPage ref={OnboardingRef}>
        <OnboardingView {...currentView} />
        <StyledSwipeArea
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        />
      </StyledPage>
    </StyledWrapper>
  );
};

export default OnboardingPage;
