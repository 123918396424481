import LoadingContainer from "components/App/LoadingContainer";
import styled from "styled-components";
import WeeklyPurchasesPanel from "./panelsv2/WeeklyPurchasesPanel";
import FiveYearPurchasePanel from "./panelsv2/FiveYearPurchasePanel";
import InflationPanel from "./panelsv2/InflationPanel";
import PurchaseHighlightsPanel from "./panelsv2/PurchaseHighlightsPanel";
import PurchaseSummaryPanel from "./panelsv2/PurchaseSummaryPanel";
import RecentPurchasesPanel from "./panelsv2/RecentPurchasesPanel";
import SharePanel from "./panelsv2/SharePanel";
import {useEffect} from "react";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const PurchaseWrapper = styled.div`
`;

interface PurchasesProps{
  showSharePanel: boolean;
  setShowSharePanel: (b: boolean) => void;
}

function Purchases({ showSharePanel, setShowSharePanel }: PurchasesProps) {
  useEffect(() => {
    MobileEventHandler.analytics("purchases_screen_wv")
  }, [])
  return <PurchaseWrapper>
    <LoadingContainer>
      <PurchaseHighlightsPanel />
    </LoadingContainer>
    <LoadingContainer>
        <RecentPurchasesPanel />
    </LoadingContainer>
    <LoadingContainer>
      <PurchaseSummaryPanel />
    </LoadingContainer>
    <SharePanel showSharePanel={showSharePanel} setShowSharePanel={setShowSharePanel} />
		<LoadingContainer>
			<WeeklyPurchasesPanel />
    </LoadingContainer>
    <LoadingContainer>
			<FiveYearPurchasePanel />
		</LoadingContainer>
    <LoadingContainer>
      <InflationPanel />
    </LoadingContainer>
  </PurchaseWrapper>;
}

export default Purchases;
