import { useNavigate, useSearchParams } from "react-router-dom";

import styled from "styled-components";
import { FlexRow } from "../Dashboard/panelsv2/generics";
import { panel_titles } from "./TransactionsFilter";

const BarItemWrapper = styled.div<any>`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  padding-top: 8px;
  padding-bottom: 8px;

  ${props => props.active ? 'color: #6B6BD4;' : ''}
  ${props => props.active ? 'border-bottom: 3px solid #6B6BD4;' : ''}
`;

const DashBarWrapper = styled.div`
  padding: 0 16px 0 16px;
  border-bottom: 1px solid #E0E0E0;
`;

function BarItem({ text, active }: { text: string, active: boolean }) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  return <BarItemWrapper 
    active={active}
    onClick={() => navigate(`/purchases/filter/${text}?${params.toString()}`)}
  >
    {panel_titles[text]}
  </BarItemWrapper>
}

function FilterBar({ panels, selected }: { panels: string[], selected: number }) {
  return <DashBarWrapper>
    <FlexRow>
      {panels.map((text, i) => <BarItem key={`dash_` + text} text={text} active={selected === i}/>)}
    </FlexRow>
  </DashBarWrapper>
}

export default FilterBar;
