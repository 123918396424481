import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_CLICK_LINK } from "components/Bridge/outgoing_events";
import { FlexRow, Left } from "components/App/Dashboard/panelsv2/generics";
import { PanelWrapper, PageTitle, NormalText, PanelWidthColoredButton } from "../styles/generics";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import {useEffect} from "react";

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SettingsTitle = styled(PageTitle)`
`;

const SettingsPanelTitle = styled(NormalText)`
  font-weight: 700;

  padding-bottom: 1rem;
`;

const SettingsPanelText = styled(NormalText)`
  padding-bottom: 1rem;
`;

const SettingsButton = styled(PanelWidthColoredButton)`
`;

function Settings() {
  const navigate = useNavigate();

  useEffect(() => {
    MobileEventHandler.analytics("settings_wv")
  }, [])

  return <SettingsWrapper>
    <FlexRow style={{alignItems: 'center'}}>
      <Left>
        <SettingsTitle>Settings</SettingsTitle>
      </Left>
    </FlexRow>
    <PanelWrapper>
      <SettingsPanelTitle>What are Usko Credits?</SettingsPanelTitle>
      <SettingsPanelText>Usko Credits are credits that you can earn in the Usko app when you purchase products in the Discover tab or read messages from brands in the Offers tab.</SettingsPanelText>
      <SettingsButton
        onClick={() => {
          navigate('/faq');
        }}
      >
        View FAQ
      </SettingsButton>
    </PanelWrapper>
    <PanelWrapper>
      <SettingsPanelTitle>We appreciate you</SettingsPanelTitle>
      <SettingsPanelText>Thank you for participating in our early-access program! For more information please visit our website!</SettingsPanelText>
      <SettingsButton
        onClick={() => {
          MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://uskoprivacy.com/');
        }}
      >
        Visit Usko Website
      </SettingsButton>
    </PanelWrapper>
    <PanelWrapper>
      <SettingsPanelTitle>Join our discord</SettingsPanelTitle>
      <SettingsPanelText>Come join the Usko discord and give us your input!</SettingsPanelText>
      <SettingsButton
        onClick={() => {
          MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://discord.gg/etJmt2KBzG');
        }}
      >
	  	Join Usko Discord
      </SettingsButton>
    </PanelWrapper>
    <PanelWrapper>
      <SettingsPanelTitle>Contact Us</SettingsPanelTitle>
      <SettingsPanelText>If you've encountered a problem with our application, please reach out to our support team:&nbsp;
        <span
          onClick={() => {
            MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'mailto:support@uskoprivacy.com');
          }}
          style={{color: '#6B6BD4'}}
        >
          support@uskoprivacy.com
        </span>
      </SettingsPanelText>
      <SettingsButton
        onClick={() => {
          MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'mailto:support@uskoprivacy.com');
        }}
      >
        Email Usko Support
      </SettingsButton>
    </PanelWrapper>
    <PanelWrapper>
      <SettingsPanelTitle>Roadmap</SettingsPanelTitle>
      <SettingsPanelText>Curious what Usko has planned? See our roadmap to learn more.</SettingsPanelText>
      <SettingsButton
        onClick={() => {
          navigate('/earn')
        }}
      >
        View Roadmap
      </SettingsButton>
    </PanelWrapper>
    <PanelWrapper>
      <SettingsPanelTitle>Onboarding Report</SettingsPanelTitle>
      <SettingsPanelText>Revisit the onboarding report for a high-level summary of your past few years' purchases.</SettingsPanelText>
      <SettingsButton
        onClick={() => {
          navigate('/onboarding');
        }}
      >
        Onboarding Report
      </SettingsButton>
    </PanelWrapper>
  </SettingsWrapper>;
}

export default Settings;
