import { useRef, useState } from "react";

import styled from "styled-components";
import { MIN_SWIPE_DISTANCE } from "./generics";

const SwipeToClosePanelWrapper = styled.div<any>`
  display: flex;
  width: 100%;
`;

function SwipeToClosePanel(
  {
    children,
    showPanel,
    setShowPanel,
    handleClose
  }:
  {
    children: JSX.Element,
    showPanel: boolean,
    setShowPanel: (b: boolean) => void,
    handleClose?: () => void
  }
  ) {

  const panelRef = useRef<HTMLDivElement>(null);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [swipeDistance, setSwipeDistance] = useState<number>(0);

  const handleTouchStart = (e: TouchEvent) => {
    setTouchEnd(null);
    setSwipeDistance(0);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
    touchEnd && touchStart && setSwipeDistance(touchStart - touchEnd);
    const transformDistance = -(swipeDistance/5);
    if(panelRef && panelRef.current){
      panelRef.current.style.transform = `translate(${transformDistance}%, 0)`;
    }
  };

  const handleTouchEnd = (e: TouchEvent) => {
    if(!touchStart || !touchEnd) return;
    const isLeftSwipe = swipeDistance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = swipeDistance < -(MIN_SWIPE_DISTANCE);
    if(isLeftSwipe || isRightSwipe){
      if(handleClose) handleClose();
      setShowPanel(false);
      return;
    };
    if(panelRef && panelRef.current) panelRef.current.style.transform = `translate(0)`;
  };

  return(
    <SwipeToClosePanelWrapper
      ref={panelRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </SwipeToClosePanelWrapper>
  );
}

export default SwipeToClosePanel;
