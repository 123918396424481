import { AmazonData, AmazonHandler, AmazonHelpers } from "../../../../amazon";
import moment from "moment";

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const usd = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const getStepSize = (arr: number[]) => {
  const half_max = Math.max.apply(Math, arr.map(function(x) { return x; })) / 1.5;

  let powerOfTen = 1;
  while (half_max > powerOfTen) {
    powerOfTen *= 10;
  }

  powerOfTen /= 100;

  const step = Math.round(half_max/powerOfTen) * powerOfTen;

  return step;
};

export const getWeekData = (month: string, monthData: { thisYear: AmazonData[][], lastYear: AmazonData[][] }, year: number) => {
  const monthIndex = months.indexOf(month);
  const thisYearHandler = AmazonHandler.loadAmazonHandler(monthData.thisYear[monthIndex]);
  const lastYearHandler = AmazonHandler.loadAmazonHandler(monthData.lastYear[monthIndex]);
  const lastOfMonth = new Date(year, monthIndex + 1, 0);
  
  let day = 1;
  const labels = [];
  const data: {
    thisYear: number[],
    lastYear: number[]
  } = {
    thisYear: [],
    lastYear: []
  };

  let weekStart, weekEnd;
  weekEnd = new Date(year, monthIndex, day + 7);
  while (moment(weekEnd).isSameOrBefore(lastOfMonth)) {
    weekStart = new Date(year, monthIndex, day);
    weekEnd = new Date(year, monthIndex, day + 7);

    if (moment(weekEnd).isAfter(lastOfMonth)) {
      weekEnd = new Date(year, monthIndex + 1, 1);
    }
    labels.push(month + ` ${weekStart.getDate()}-${new Date(year, monthIndex + 1, weekEnd.getDate() - 1).getDate()}`);
    data.thisYear.push(
      AmazonHelpers.CalculateSpending(
        thisYearHandler
          .sliceDateRange(weekStart, weekEnd)
          .getOrders()
      ).totalSpending
    );
    data.lastYear.push(
      AmazonHelpers.CalculateSpending(
        lastYearHandler
          .sliceDateRange(
            new Date(year - 1, monthIndex, weekStart.getDate() - 1), 
            new Date(year - 1, monthIndex, weekEnd.getDate() - 1), 
          )
          .getOrders()
      ).totalSpending
    );

    day += 7;
  }

  return { weekLabels: labels, weekData: data };
}

export const getWeekPurchasesData = (month: string, monthData: { thisYear: AmazonData[][], lastYear: AmazonData[][] }, year: number) => {
  const monthIndex = months.indexOf(month);
  const thisYearHandler = AmazonHandler.loadAmazonHandler(monthData.thisYear[monthIndex]);
  const lastYearHandler = AmazonHandler.loadAmazonHandler(monthData.lastYear[monthIndex]);
  const lastOfMonth = new Date(year, monthIndex + 1, 0);
  
  let day = 1;
  const labels = [];
  const data: {
    thisYear: number[],
    lastYear: number[]
  } = {
    thisYear: [],
    lastYear: []
  };

  let weekStart, weekEnd;
  weekEnd = new Date(year, monthIndex, day + 7);
  while (moment(weekEnd).isSameOrBefore(lastOfMonth)) {
    weekStart = new Date(year, monthIndex, day);
    weekEnd = new Date(year, monthIndex, day + 7);

    if (moment(weekEnd).isAfter(lastOfMonth)) {
      weekEnd = new Date(year, monthIndex + 1, 1);
    }
    labels.push(month + ` ${weekStart.getDate()}-${new Date(year, monthIndex + 1, weekEnd.getDate() - 1).getDate()}`);
    data.thisYear.push(
    	thisYearHandler
      	.sliceDateRange(weekStart, weekEnd)
      	.getOrders().length
    );
    data.lastYear.push(
    	lastYearHandler
      	.sliceDateRange(
      		new Date(year - 1, monthIndex, weekStart.getDate() - 1), 
        	new Date(year - 1, monthIndex, weekEnd.getDate() - 1), 
        )
        .getOrders()
				.length
    );

    day += 7;
  }

  return { weekLabels: labels, weekData: data };
}

export const simpleDateFormat = (d: Date) => {
  const dateStr = d.toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}).split(',').reverse();
  dateStr[0] = dateStr[0].split(' ').join('');
  return dateStr.join(' ');
};
