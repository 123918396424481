export const MockData : string =
  `Order Date,Order ID,Title,Category,ASIN/ISBN,UNSPSC Code,Website,Release Date,Condition,Seller,Seller Credentials,List Price Per Unit,Purchase Price Per Unit,Quantity,Payment Instrument Type,Purchase Order Number,PO Line Number,Ordering Customer Email,Shipment Date,Shipping Address Name,Shipping Address Street 1,Shipping Address Street 2,Shipping Address City,Shipping Address State,Shipping Address Zip,Order Status,Carrier Name & Tracking Number,Item Subtotal,Item Subtotal Tax,Item Total,Tax Exemption Applied,Tax Exemption Type,Exemption Opt-Out,Buyer Name,Currency,Group Name
09/30/14,112-0935588-8048245,The Silmarillion: The legendary precursor to The Lord of the Rings,ABIS_BOOK,345325818,55111505,Amazon.com,1985-01-12 0:00:01,new,Amazon.com,,$8.99,$5.98,1,Visa - 0791,,,Redacto@gmail.com,10/02/14,Redact Redacterson,1234 Redact Way,1234 Redact Way,East Lansing,MI,48823,Shipped,SMARTPOST(9245890100130172798020),$5.98,$0.00,$5.98,,,,Redact,USD,
10/03/14,112-1333588-5991435,Tao of Jeet Kune Do,ABIS_BOOK,897500482,55101500,Amazon.com,1975-01-01 0:00,used good,Books-4-Everybody,,$16.95,$2.97,1,Visa - 0791,,,Redacto@gmail.com,10/04/14,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9449011201237709071258),$2.97,$0.00,$2.97,,,,Redact,USD,
07/15/15,002-5352979-4812251,JONNY IV Men's Leather Patchwork T-Shirt,SHIRT,B00X6RPDWO,53100000,Amazon.com,,new,JONNY IV,,$0.00,$34.95,1,MasterCard - 6651,,,Redacto@gmail.com,07/16/15,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-6027,Shipped,UPS(1ZA87R381336359111),$34.95,$0.00,$34.95,,,,Redact,USD,
07/15/15,002-5352979-4812251,Cards Against Humanity,TABLETOP_GAME,B004S8F7QM,60141100,Amazon.com,,new,"Cards Against Humanity, LLC",,$25.00,$25.00,1,MasterCard - 6651,,,Redacto@gmail.com,07/16/15,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-6027,Shipped,UPS(1ZA87R381336359111),$25.00,$0.00,$25.00,,,,Redact,USD,
08/27/15,112-6717388-6593009,Lilly Pulitzer Wink By Lilly Pulitzer For Women Eau De Parfum Spray 3.4 Oz,PERSONAL_FRAGRANCE,B002XQ1VG6,53131620,Amazon.com,,new,Amazon.com,,$68.00,$22.76,1,MasterCard - 6651,,,Redacto@gmail.com,08/27/15,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-6027,Shipped,UPS(1Z60642R0366725224),$22.76,$0.00,$22.76,,,,Redact,USD,
08/27/15,112-8202774-2372245,Kate Spade New York Laurel Way Stacy Saffiano Leather Wallet,HANDBAG,B00G5F0TLO,53121600,Amazon.com,,new,Dot Dot Love Luxury Goods,,$158.00,$68.54,1,MasterCard - 6651,,,Redacto@gmail.com,08/27/15,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-6027,Shipped,USPS(9402111899223530581146),$68.54,$0.00,$68.54,,,,Redact,USD,
11/27/15,106-2411511-2335448,,,B014N2IEM6,,Amazon.com,,new,,,$0.00,$24.00,1,MasterCard - 5968,,,Redacto@gmail.com,11/30/15,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-6027,Shipped,USPS(9505500005955334000697),$24.00,$0.00,$24.00,,,,Redact,USD,
01/10/16,109-3744047-9069045,Calvin Klein Women's Modern Cotton Unlined Wireless Bralette,BRA,B00GBCU18C,53100000,Amazon.com,,new,Amazon.com,,$28.00,$27.98,1,MasterCard - 5968,,,Redacto@gmail.com,01/11/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9374889879201311798923),$27.98,$1.68,$29.66,,,,Redact,USD,
01/10/16,109-3744047-9069045,Calvin Klein Women's Modern Cotton Stretch Bikini Panty,UNDERPANTS,B00GBD2RT2,53102303,Amazon.com,,new,Amazon.com,,$20.00,$18.50,1,MasterCard - 5968,,,Redacto@gmail.com,01/11/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9374889879201311798923),$18.50,$1.11,$19.61,,,,Redact,USD,
03/25/16,106-1709436-1985809,Me Before You (Me Before You Trilogy),ABIS_BOOK,143124544,55101500,Amazon.com,2013-07-30 0:00:01,new,Amazon.com,,$16.00,$9.79,1,Gift Certificate/Card,,,Redacto@gmail.com,03/26/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,GRAND BLANC,MI,48439-9724,Shipped,USPS(9361289687090014675397),$9.79,$0.59,$10.38,,,,Redact,USD,
04/10/16,106-1415387-6714628,Gordon Ramsay's Fast Food,ABIS_BOOK,1554700647,55101500,Amazon.com,2008-03-28 0:00:01,used verygood,Hippo Books,,$35.00,$2.28,1,Gift Certificate/Card,,,Redacto@gmail.com,04/11/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-6027,Shipped,DHL Global Mail(9241990101549210632187),$2.28,$0.00,$2.28,,,,Redact,USD,
04/21/16,106-8301581-9341837,Fantastic Beasts & Where to Find Them (Harry Potter),ABIS_BOOK,545850568,55101500,Amazon.com,2015-06-30 0:00:01,new,goHastings,,$9.99,$9.98,1,Gift Certificate/Card,,,Redacto@gmail.com,04/22/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9449010200989050497303),$9.98,$0.00,$9.98,,,,Redact,USD,
05/20/16,106-3362737-6257862,,,B00WLCDT9O,,Amazon.com,,new,California Concepts,,$0.00,$14.72,1,MasterCard - 5968,,,Redacto@gmail.com,05/21/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9361289687090037366487),$14.72,$0.00,$14.72,,,,Redact,USD,
05/23/16,112-8579223-9236222,,,B00WLCDT9O,,Amazon.com,,new,California Concepts,,$0.00,$14.72,1,Gift Certificate/Card,,,Redacto@gmail.com,05/24/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9361289687090038727461),$14.72,$0.00,$14.72,,,,Redact,USD,
05/30/16,113-8766023-0205861,I Will Teach You To Be Rich,ABIS_BOOK,761147489,55101500,Amazon.com,2009-03-23 0:00:01,new,Amazon.com,,$13.95,$7.84,1,MasterCard - 5968 and Gift Certificate/Card,,,Redacto@gmail.com,05/31/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9341989687090007325266),$7.84,$0.47,$8.31,,,,Redact,USD,
05/31/16,113-5501336-7107459,The 5 Love Languages: The Secret to Love that Lasts,ABIS_BOOK,080241270X,55101500,Amazon.com,2015-01-01 0:00:01,new,Amazon.com,,$15.99,$9.59,1,MasterCard - 5968,,,Redacto@gmail.com,05/31/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST LANSING,MI,48823-3215,Shipped,USPS(9341989687090007348265),$9.59,$0.58,$10.17,,,,Redact,USD,
09/04/16,106-9014322-4726649,"BlenderBottle Shaker Bottle with Pill Organizer and Storage for Protein Powder, ProStak System, 22-Ounce, Black/White",BOTTLE,B00IOO2YSK,24122000,Amazon.com,,new,Amazon.com,,$13.99,$9.93,2,MasterCard - 5968,,,Redacto@gmail.com,09/05/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090086607531),$19.86,$1.20,$21.06,,,,Redact,USD,
09/04/16,106-9014322-4726649,"BlenderBottle ProStak Twist n' Lock Storage Jars Expansion 3-Pak with Pill Tray, Black",BOTTLE,B00IXKT8KM,52152000,Amazon.com,,new,Amazon.com,,$8.99,$8.99,1,MasterCard - 5968,,,Redacto@gmail.com,09/05/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090086607531),$8.99,$0.54,$9.53,,,,Redact,USD,
09/08/16,106-4298999-1175453,Our Q&A a Day: 3-Year Journal for 2 People,ABIS_BOOK,770436684,44112006,Amazon.com,2013-09-24 0:00:01,new,Amazon.com,,$16.95,$11.39,1,MasterCard - 5968,,,Redacto@gmail.com,09/08/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9341989687090015906181),$11.39,$0.68,$12.07,,,,Redact,USD,
11/26/16,114-8303494-2914651,kate spade new york Cueva Rosa Stud Earrings,EARRING,B004EHQKAE,54100000,Amazon.com,,new,Amazon.com,,$48.00,$16.07,1,MasterCard - 5968,,,Redacto@gmail.com,11/27/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,SUREPOST(1Z300VW1YN01457417),$16.07,$0.96,$17.03,,,,Redact,USD,
11/26/16,114-8303494-2914651,The Underground Railroad: A Novel (Random House Large Print),ABIS_BOOK,1524736309,55101500,Amazon.com,2016-08-02 0:00:01,new,Amazon.com,,$27.00,$15.16,1,MasterCard - 5968,,,Redacto@gmail.com,11/28/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090130464561),$15.16,$0.91,$16.07,,,,Redact,USD,
11/26/16,114-8303494-2914651,5 Pcs Cocktail Shaker Set - Mixology Bartender Kit with Recipes for Martini Mimosa - Home Bar Tool Kit & Accessories - Bar Accessories Shakers Bartending Drink Mixer - Margarita Maker Alcohol Shaker,BAR_TOOL_SET,B00L0IDATO,48101713,Amazon.com,,new,Mixologist World,,$49.99,$19.99,1,MasterCard - 5968,,,Redacto@gmail.com,11/28/16,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090130464561),$19.99,$0.00,$19.99,,,,Redact,USD,
01/14/17,105-0620777-9341805,Perform Better Mini Band,EXERCISE_BAND,B017T3JFHE,49201609,Amazon.com,,new,Synergee USA,,$23.94,$14.95,1,MasterCard - 5968,,,Redacto@gmail.com,01/18/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,SUREPOST(1ZY4V346YN00012243),$14.95,$0.00,$14.95,,,,Redact,USD,
02/02/17,104-0281738-6017069,Mindfulness for Beginners: Reclaiming the Present Moment and Your Life,ABIS_BOOK,1622036670,55101500,Amazon.com,2016-07-01 0:00:01,new,Amazon.com,,$14.95,$10.05,1,MasterCard - 5968,,,Redacto@gmail.com,02/03/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,SUREPOST(1Z63V1583A00956537),$10.05,$0.60,$10.65,,,,Redact,USD,
05/12/17,114-4766455-3808268,EAS Myoplex Muscle Armor - 1.65 lb,PROTEIN_SUPPLEMENT_POWDER,B015INXHS0,50500000,Amazon.com,,new,Amazon.com,,$32.49,$31.68,1,MasterCard - 5968,,,Redacto@gmail.com,05/13/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090233924481),$31.68,$0.00,$31.68,,,,Redact,USD,
05/12/17,114-4766455-3808268,EAS Myoplex BCAA Electrolytes Powder - Blue Raspberry - 1.19 lb,HEALTH_PERSONAL_CARE,B015INQGN8,50500000,Amazon.com,,new,Amazon.com,,$23.99,$14.23,1,MasterCard - 5968,,,Redacto@gmail.com,05/13/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090233924481),$14.23,$0.00,$14.23,,,,Redact,USD,
11/03/17,111-2798223-6153024,The Daily Stoic Journal: 366 Days of Writing and Reflection on the Art of Living,ABIS_BOOK,525534393,55101500,Amazon.com,2017-11-14 0:00:01,new,Amazon.com,,$27.00,$18.56,1,MasterCard - 1337,,,Redacto@gmail.com,11/13/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,USPS(9341989682090104216134),$18.56,$0.93,$19.49,,,,Redact,USD,
11/03/17,111-2798223-6153024,The Daily Stoic Journal: 366 Days of Writing and Reflection on the Art of Living,ABIS_BOOK,525534393,55101500,Amazon.com,2017-11-14 0:00:01,new,Amazon.com,,$27.00,$18.56,1,MasterCard - 1337,,,Redacto@gmail.com,11/13/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,USPS(9341989682090104216134),$18.56,$0.93,$19.49,,,,Redact,USD,
11/06/17,114-7497569-1107462,Tribe: On Homecoming and Belonging,ABIS_BOOK,1455566381,55101500,Amazon.com,2016-05-24 0:00:01,new,Amazon.com,,$22.00,$14.95,1,MasterCard - 1337,,,Redacto@gmail.com,11/08/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,UPS(1Z2FW2350326747216),$14.95,$0.75,$15.70,,,,Redact,USD,
11/06/17,114-7497569-1107462,EAS Myoplex Muscle Armor - 1.65 lb,PROTEIN_SUPPLEMENT_POWDER,B015INXHS0,50500000,Amazon.com,,new,Amazon.com,,$32.49,$32.49,1,MasterCard - 1337,,,Redacto@gmail.com,11/08/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,UPS(1Z2FW2350326747216),$32.49,$1.62,$34.11,,,,Redact,USD,
11/07/17,111-4574680-8823430,The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to Living a Good Life,ABIS_BOOK,62457713,55101500,Amazon.com,2016-09-13 0:00:01,new,Amazon.com,,$24.99,$15.27,1,MasterCard - 1337,,,Redacto@gmail.com,11/07/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,USPS(9341989682019810067748),$15.27,$0.76,$16.03,,,,Redact,USD,
11/07/17,111-4574680-8823430,The Obstacle Is the Way: The Timeless Art of Turning Trials into Triumph,ABIS_BOOK,1591846358,55101500,Amazon.com,2014-05-01 0:00:01,new,Amazon.com,,$25.00,$20.00,1,MasterCard - 1337,,,Redacto@gmail.com,11/07/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,USPS(9341989682090103801317),$20.00,$1.00,$21.00,,,,Redact,USD,
11/07/17,114-0803755-2919437,How to Build Self-Discipline: Resist Temptations and Reach Your Long-Term Goals (Simple Self-Discipline),ABIS_BOOK,1508539332,55101500,Amazon.com,2015-03-04 0:00:01,new,Amazon.com,,$12.99,$12.37,1,MasterCard - 1337,,,Redacto@gmail.com,11/09/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,GREEN BAY,WI,54304-3927,Shipped,USPS(9341989682090103948333),$12.37,$0.62,$12.99,,,,Redact,USD,
11/14/17,111-4034631-0509058,What Do You Meme? Adult Party Game,TABLETOP_GAME,B01MRG7T0D,60141100,Amazon.com,,new,"What Do You Meme, LLC",,$0.00,$29.99,1,MasterCard - 1337,,,Redacto@gmail.com,11/15/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090357354157),$29.99,$0.00,$29.99,,,,Redact,USD,
11/18/17,111-0478440-9718610,Inflatable Lounger Air Sofa Chair with U-shape neck pillow and handy storage bag for Camping&Hiking & Swimming pool to use as mattress (outdoor&indoor) (Big Blue Sofa),OUTDOOR_RECREATION_PRODUCT,B06XB7DJV8,49000000,Amazon.com,,new,HAKE,,$0.00,$29.98,1,MasterCard - 1337,,,Redacto@gmail.com,11/18/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090360178252),$29.98,$0.00,$29.98,,,,Redact,USD,
11/27/17,111-0823921-1010657,"CytoSport Monster Milk Protein Shake, Chocolate, 48g Protein, 17 FL OZ, 12 Count",PROTEIN_DRINK,B01GVPUBHE,50500000,Amazon.com,,new,Zongle,,$64.99,$40.56,3,MasterCard - 1337,,,Redacto@gmail.com,11/28/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,HOBART,WI,54155-7704,Shipped,USPS(9361289682090467104838),$121.68,$0.00,$121.68,,,,Redact,USD,
11/29/17,112-0216016-9498676,PUMA Women's Fierce Vr Wn Sneaker,SHOES,B01MZ0EYUO,53111600,Amazon.com,,new,Amazon.com,,$110.00,$61.92,1,MasterCard - 1337,,,Redacto@gmail.com,11/29/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090371188905),$61.92,$3.72,$65.64,,,,Redact,USD,
12/12/17,112-4780900-8517812,On the High Line: Exploring America's Most Original Urban Park,ABIS_BOOK,500291411,55101500,Amazon.com,2014-05-20 0:00:01,new,Amazon.com,,$29.95,$17.49,1,MasterCard - 1337,,,Redacto@gmail.com,12/13/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9341989687090069188557),$17.49,$1.05,$18.54,,,,Redact,USD,
12/18/17,111-8890979-8842613,Sports Coverage NCAA Texas Tech Red Raiders Locker Room Toss Pillow,PILLOW,B0009PY598,53100000,Amazon.com,,new,Amazon.com,,$19.99,$10.34,1,MasterCard - 1337,,,Redacto@gmail.com,12/19/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,UPS(1Z9766AX0210770412),$10.34,$0.62,$10.96,,,,Redact,USD,
12/18/17,111-8890979-8842613,"Sports Coverage NCAA Unisex-Adult,Unisex-Children,Men Locker Room Pillow",PILLOW,B00466C1OC,52121505,Amazon.com,,new,Amazon.com,,$16.99,$16.99,1,MasterCard - 1337,,,Redacto@gmail.com,12/18/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,UPS(1ZW473W70357422048),$16.99,$1.02,$18.01,,,,Redact,USD,
12/21/17,112-7940791-6743420,Athletes Are Brands Too: How Brand Marketing Can Save Today's Athlete,ABIS_BOOK,999633201,55101500,Amazon.com,2017-12-01 0:00:01,new,Amazon.com,,$24.95,$24.95,1,MasterCard - 1337,,,Redacto@gmail.com,12/22/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,UPS(1ZA7810W0376256790),$24.95,$1.50,$26.45,,,,Redact,USD,
12/26/17,112-3429089-8329030,Wazor Hair Flat Iron 1 Inch Ionic Ceramic Hair Straightener Professional Flat Iron With LED Digital and Temperature Control From 284℉ to 446℉ Auto Shut Off,HAIR_IRON,B01A6O3A1U,52141700,Amazon.com,,new,Wazor stylists,,$109.99,$29.99,1,MasterCard - 1337,,,Redacto@gmail.com,12/27/17,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,MI,48176,Shipped,USPS(9361289687090404498193),$29.99,$0.00,$29.99,,,,Redact,USD,
01/03/18,114-1196175-8150604,"GG Scandinavian Bran Crispbread All Natural Bran Cracker Packages, 5 count, 3.5-Ounce Packages (Pack of 5)",CRACKER,B003G1ZRTY,50181909,Amazon.com,,new,FSN,,$17.45,$16.99,1,MasterCard - 1337,,,Redacto@gmail.com,01/04/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090410201640),$16.99,$0.00,$16.99,,,,Redact,USD,
01/05/18,111-6251836-9477809,"DrTung’s Stainless Tongue Scraper - Tongue Cleaner for Adults, Kids, Helps Freshens Breath, Easy to Use Comfort Grip Handle, Comes with Travel Case - Stainless Steel Tongue Scrapers (1 Pack)",BEAUTY,B00064JGBO,42181500,Amazon.com,,new,ProductResort,,$8.99,$7.79,1,MasterCard - 1337,,,Redacto@gmail.com,01/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,DHL(9274899998437701042602),$7.79,$0.00,$7.79,,,,Redact,USD,
01/07/18,111-6375693-3948220,Calvin Klein Men's Body Modal Pajama Pant,APPAREL,B002RS6ABQ,53100000,Amazon.com,,new,Best Service Online,,$42.00,$34.99,1,MasterCard - 1337,,,Redacto@gmail.com,01/08/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9300120111402438142145),$34.99,$0.00,$34.99,,,,Redact,USD,
01/15/18,114-3384096-9568207,Meister Glove Deodorizers for Boxing and All Sports - Absorbs Stink and Leaves Gloves Fresh,TEXTILE_DEODORIZER,B01BVW3OPS,53100000,Amazon.com,,new,Meister MMA,,$14.99,$11.99,1,MasterCard - 1337,,,Redacto@gmail.com,01/16/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,UPS(1ZA288930334506132),$11.99,$0.00,$11.99,,,,Redact,USD,
01/15/18,114-3384096-9568207,"Hayabusa T3 Boxing Gloves - Black/Grey, 16oz",BOXING_GLOVE,B075GGBRQB,49000000,Amazon.com,,new,Hayabusa Inc,,$99.99,$99.99,1,MasterCard - 1337,,,Redacto@gmail.com,01/16/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,UPS(1ZA288930334506132),$99.99,$0.00,$99.99,,,,Redact,USD,
01/16/18,111-9085795-6157821,"Coop Home Goods Original Loft Pillow Queen Size Bed Pillows for Sleeping - Adjustable Cross Cut Memory Foam Pillows - Medium Firm Back, Stomach and Side Sleeper Pillow - CertiPUR-US/GREENGUARD Gold",PILLOW,B00EINBSEW,52121505,Amazon.com,,new,Coop Home Goods,,$175.99,$59.99,1,MasterCard - 1337,,,Redacto@gmail.com,01/16/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090419155746),$59.99,$0.00,$59.99,,,,Redact,USD,
01/16/18,111-3239427-1521021,"Proactiv 3 Step Acne Treatment - Benzoyl Peroxide Face Wash, Repairing Acne Spot Treatment for Face and Body, Exfoliating Toner - 30 Day Complete Acne Skin Care Kit",BEAUTY,B00X6ZNWG0,53131600,Amazon.com,,new,Amazon.com,,$34.95,$34.95,1,MasterCard - 1337,,,Redacto@gmail.com,01/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,UPS(1ZW220500384008407),$34.95,$2.10,$37.05,,,,Redact,USD,
01/24/18,114-0163686-5229026,"DrTung’s Stainless Tongue Scraper - Tongue Cleaner for Adults, Kids, Helps Freshens Breath, Easy to Use Comfort Grip Handle, Comes with Travel Case - Stainless Steel Tongue Scrapers (1 Pack)",BEAUTY,B00064JGBO,42181500,Amazon.com,,new,Golden Boy,,$8.99,$7.00,1,MasterCard - 1337,,,Redacto@gmail.com,01/25/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9200192148980218625689),$7.00,$0.00,$7.00,,,,Redact,USD,
02/08/18,112-5267553-1691465,"Paderno World Cuisine 3-Blade Vegetable Slicer / Spiralizer, Counter-Mounted and includes 3 Stainless Steel Blades",FOOD_SLICER,B0007Y9WHQ,52150000,Amazon.com,,new,Amazon.com,,$35.95,$24.99,1,MasterCard - 1337,,,Redacto@gmail.com,02/08/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090434528495),$24.99,$1.50,$26.49,,,,Redact,USD,
04/23/18,111-9391085-7638614,TheraGun G2PRO Professional Massager,MASSAGER,B07578H2GX,53131700,Amazon.com,,new,TheraGun,,$0.00,$599.00,1,MasterCard - 1337,,,Redacto@gmail.com,04/23/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090092025362),$599.00,$0.00,$599.00,,,,Redact,USD,
04/26/18,111-6428017-0395459,The Daily Stoic Journal: 366 Days of Writing and Reflection on the Art of Living,ABIS_BOOK,525534393,55101500,Amazon.com,2017-11-14 0:00:01,new,Amazon.com,,$27.00,$15.05,1,MasterCard - 1337,,,Redacto@gmail.com,04/26/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,PHILADELPHIA,PA,19147-1832,Shipped,USPS(9341989681090084103746),$15.05,$0.90,$15.95,,,,Redact,USD,
04/26/18,111-6428017-0395459,Mindfulness for Beginners: Reclaiming the Present Moment and Your Life,ABIS_BOOK,1622036670,55101500,Amazon.com,2016-07-01 0:00:01,new,Amazon.com,,$14.95,$11.96,1,MasterCard - 1337,,,Redacto@gmail.com,04/26/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,PHILADELPHIA,PA,19147-1832,Shipped,USPS(9341989681090084103746),$11.96,$0.72,$12.68,,,,Redact,USD,
05/09/18,114-4364950-6424229,"True Roots: A Mindful Kitchen with More Than 100 Recipes Free of Gluten, Dairy, and Refined Sugar: A Cookbook",ABIS_BOOK,1623369169,55101500,Amazon.com,2018-04-03 0:00:01,new,Amazon.com,,$25.99,$15.59,1,Visa - 4900,,,Redacto@gmail.com,05/09/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,USPS(9341989687090099514401),$15.59,$0.94,$16.53,,,,Redact,USD,
05/16/18,111-4989140-6469046,"The Long Snapper: A Second Chance, a Super Bowl, a Lesson for Life",ABIS_BOOK,61691399,55101500,Amazon.com,2009-08-18 0:00:01,new,Orion LLC,,$24.99,$4.98,1,MasterCard - 3698,,,Redacto@gmail.com,05/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,NASHVILLE,TN,37203-3556,Shipped,DHL Global Mail(9241999996377207226629),$4.98,$0.00,$4.98,,,,Redact,USD,
06/04/18,114-2610816-2981051,OEE Luggage Packing Organizers Packing Cubes Set for Travel,STORAGE_BAG,B0753FSFVS,53120000,Amazon.com,,new,OEE,,$39.99,$19.99,1,MasterCard - 3698,,,Redacto@gmail.com,06/04/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090445848373),$19.99,$0.00,$19.99,,,,Redact,USD,
06/10/18,111-7951606-5294608,EAS Myoplex Muscle Armor - 1.65 lb,PROTEIN_SUPPLEMENT_POWDER,B015INXHS0,50500000,Amazon.com,,new,Amazon.com,,$32.49,$31.30,1,MasterCard - 3698,,,Redacto@gmail.com,06/12/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090518053547),$31.30,$0.00,$31.30,,,,Redact,USD,
06/10/18,111-0235883-0886615,EAS Myoplex Muscle Armor - 1.65 lb,PROTEIN_SUPPLEMENT_POWDER,B015INXHS0,50500000,Amazon.com,,new,Amazon.com,,$32.49,$31.30,1,MasterCard - 3698,,,Redacto@gmail.com,06/10/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090516453615),$31.30,$0.00,$31.30,,,,Redact,USD,
06/11/18,114-7582550-3023433,"Greetings From LeFlore County!: The Lord, the Law, and the Laughter in Rural Oklahoma",ABIS_BOOK,1641916524,55101500,Amazon.com,2018-04-06 0:00:01,new,Amazon.com,,$17.95,$17.95,1,MasterCard - 3698,,,Redacto@gmail.com,06/13/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090103123087),$17.95,$1.08,$19.03,,,,Redact,USD,
06/11/18,114-7582550-3023433,Magnetic,ABIS_BOOK,999334409,55101500,Amazon.com,2017-09-15 0:00:01,new,Amazon.com,,$14.99,$14.99,1,MasterCard - 3698,,,Redacto@gmail.com,06/13/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090103123087),$14.99,$0.90,$15.89,,,,Redact,USD,
07/04/18,112-3358462-7925024,"Freshware Meal Prep Containers [21 Pack] 3 Compartment with Lids, Food Storage Containers, Bento Box, BPA Free, Stackable, Microwave/Dishwasher/Freezer Safe (24 oz)",FOOD_STORAGE_CONTAINER,B073TRZ91C,52150000,Amazon.com,,new,Amazon.com,,$0.00,$15.49,2,MasterCard - 3698,,,Redacto@gmail.com,07/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090534336389),$30.98,$1.86,$32.84,,,,Redact,USD,
07/05/18,112-7988398-2945039,"BlenderBottle Classic Loop Top Shaker Bottle, 28-Ounce 2-Pack, All Black and Clear/Black",BOTTLE,B06XD3BJQ5,52152000,Amazon.com,,new,Amazon.com,,$0.00,$19.89,1,MasterCard - 3698,,,Redacto@gmail.com,07/06/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090534736189),$19.89,$1.19,$21.08,,,,Redact,USD,
07/05/18,112-7988398-2945039,OXO Good Grips 3-in-1 Avocado Slicer - Green,FOOD_SLICER,B0088LR592,52151600,Amazon.com,,new,Amazon.com,,$10.00,$9.99,1,MasterCard - 3698,,,Redacto@gmail.com,07/06/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090534736189),$9.99,$0.60,$10.59,,,,Redact,USD,
07/21/18,111-2139407-9965049,"Panasonic LUMIX G Lens, 25mm, F1.7 ASPH, Mirrorless Micro Four Thirds, H-H025K (USA Black)",CAMERA_LENSES,B014RD6RC0,45121603,Amazon.com,,new,RitzCamera,,$147.99,$147.99,1,MasterCard - 3698,,,Redacto@gmail.com,07/21/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090478173824),$147.99,$0.00,$147.99,,,,Redact,USD,
07/24/18,111-7767295-0597025,"Optimum Nutrition Pro Complex Whey Protein Powder Blend, Rich Milk Chocolate, 3.35 lbs (Packaging May Vary)",PROTEIN_SUPPLEMENT_POWDER,B00IA9QTIK,50500000,Amazon.com,,new,Amazon.com,,$99.99,$55.53,1,MasterCard - 3698,,,Redacto@gmail.com,07/25/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090551146022),$55.53,$0.00,$55.53,,,,Redact,USD,
07/26/18,112-5856264-6710616,"Manduka eQua Yoga Towel, Absorbent, Quick Drying, Non-Slip for Yoga, Gym, Pilates, Outdoor Fitness",TOWEL,B001OTZIMG,52120000,Amazon.com,,new,Amazon.com,,$16.00,$16.00,1,MasterCard - 3698,,,Redacto@gmail.com,07/27/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090482397957),$16.00,$0.96,$16.96,,,,Redact,USD,
07/28/18,112-7499730-2265869,Got Fight?: The 50 Zen Principles of Hand-to-Face Combat,ABIS_BOOK,61721727,55101500,Amazon.com,2010-05-18 0:00:01,new,Crosstown Books,,$16.99,$13.79,1,MasterCard - 3698,,,Redacto@gmail.com,07/29/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090107927681),$13.79,$0.00,$13.79,,,,Redact,USD,
07/31/18,112-5999682-8671437,"Screen Mom Screen Cleaner Kit for Laptop, Phone Cleaner, iPad, Eyeglass, LED, LCD, TV - Includes 2oz Spray and 2 Purple Cleaning Cloths",COMPUTER_COMPONENT,B074512LY1,43211900,Amazon.com,,new,Mom's Chosen Brands LLC.,,$14.99,$8.99,1,MasterCard - 3698,,,Redacto@gmail.com,08/01/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090485868041),$8.99,$0.00,$8.99,,,,Redact,USD,
08/15/18,111-4285143-2894648,"TF Publishing 19-9741A July 2018 - June 2019 Marble Large Weekly Monthly Planner, 9 x 11"", Black, White & Gold",ABIS_BOOK,1683758404,44112000,Amazon.com,2018-05-15 0:00:01,new,Amazon.com,,$16.99,$15.29,1,American Express - 1000,,,Redacto@gmail.com,08/20/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,USPS(9341989687090110454501),$15.29,$0.92,$16.21,,,,Redact,USD,
08/15/18,111-7099057-7584266,The Keto Reset Diet: Reboot Your Metabolism in 21 Days and Burn Fat Forever,ABIS_BOOK,1524762237,55101500,Amazon.com,2017-10-03 0:00:01,new,Amazon.com,,$27.99,$16.26,1,MasterCard - 3698,,,Redacto@gmail.com,08/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090110215232),$16.26,$0.98,$17.24,,,,Redact,USD,
08/18/18,112-3248649-5830617,"Exogenous Ketones Powder, BHB Beta-Hydroxybutyrate Salts Supplement, Best Fuel for Energy Boost, Mental Performance, Mix in Shakes, Milk, Smoothie Drinks for Ketosis – Chocolate, 8.57 oz (243 grs)",NUTRITIONAL_SUPPLEMENT,B01M7XI35O,50500000,Amazon.com,,new,Perfect Keto / Equip,,$0.00,$58.99,1,MasterCard - 3698,,,Redacto@gmail.com,08/19/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090498688216),$58.99,$0.00,$58.99,,,,Redact,USD,
08/21/18,111-5996755-5396253,"KETO-MOJO Bluetooth Ketone & Glucose Blood Testing Kit + APP, 20 Test Strips (10 Each), 1 Meter, 10 Lancets, 1 Lancing Device, Monitor Your Ketosis & Ketogenic Diet",GLUCOSE_METER,B0789G8KTG,41104100,Amazon.com,,new,keto-mojo,,$84.97,$59.99,1,MasterCard - 3698,,,Redacto@gmail.com,08/21/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,UPS(1ZY1Y5791346713319),$59.99,$3.60,$63.59,,,,Redact,USD,
08/24/18,112-3832510-4087411,"Keto-Mojo 50 Blood Ketone Test Strips, Precision Measurement for Diabetes & Low-Carb Weight Loss, Monitor Your Diabetic & Ketogenic Diet for Nutritional Ketosis, Strips Work Only in Keto-Mojo Meters",MEDICAL_TEST_STRIP,B078B426TM,50000000,Amazon.com,,new,keto-mojo,,$0.00,$49.50,1,MasterCard - 3698,,,Redacto@gmail.com,08/25/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090503618290),$49.50,$2.97,$52.47,,,,Redact,USD,
08/28/18,112-9695999-0696266,"Moon Cheese - 100% Natural Cheese Snack - Variety (2 Cheddar, Gouda, Pepper Jack) 2 oz - 4 pack",GROCERY,B07588H2CF,50000000,Amazon.com,,new,The Goodie Shoppe,,$0.00,$19.95,1,MasterCard - 3698,,,Redacto@gmail.com,08/29/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090506608403),$19.95,$0.00,$19.95,,,,Redact,USD,
08/30/18,112-7337878-3392237,The Fires of Merlin,ABIS_BOOK,399250220,55101500,Amazon.com,2007-09-20 0:00:01,used mint,bookconcern,,$10.99,$5.00,1,MasterCard - 3698,,,Redacto@gmail.com,08/30/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9449011899560178015591),$5.00,$0.00,$5.00,,,,Redact,USD,
08/30/18,112-1617325-5592230,The Lost Years of Merlin,ABIS_BOOK,399250204,55101500,Amazon.com,2007-09-20 0:00:01,used mint,YourOnlineBookstore,,$10.99,$5.31,1,MasterCard - 3698,,,Redacto@gmail.com,08/31/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,DHL Global Mail(9241990101546726793906),$5.31,$0.00,$5.31,,,,Redact,USD,
08/30/18,112-3919935-7905058,,,B01K961JHM,,Amazon.com,,used good,DiamondHardPaper,,$0.00,$10.47,1,MasterCard - 3698,,,Redacto@gmail.com,08/31/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9241990140832133681479),$10.47,$0.00,$10.47,,,,Redact,USD,
08/30/18,112-7528364-6041805,The Seven Songs of Merlin (Merlin Saga),ABIS_BOOK,399250212,55101500,Amazon.com,2007-09-20 0:00:01,new,coastsales,,$10.99,$5.53,1,MasterCard - 3698,,,Redacto@gmail.com,08/30/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9349020111402919484766),$5.53,$0.00,$5.53,,,,Redact,USD,
08/30/18,112-0731440-2197869,The Mirror of Merlin (Lost Years of Merlin),ABIS_BOOK,B001KOU186,55101500,Amazon.com,2007-09-20 0:00:01,new,mercado_b,,$10.99,$9.91,1,MasterCard - 3698,,,Redacto@gmail.com,08/31/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9349020111402931355228),$9.91,$0.00,$9.91,,,,Redact,USD,
09/02/18,112-3258160-3891407,"Fit Simplify Resistance Loop Exercise Bands with Instruction Guide and Carry Bag, Set of 5",EXERCISE_BAND,B01AVDVHTI,49201609,Amazon.com,,new,Fit_Simplify,,$44.97,$10.95,1,MasterCard - 3698,,,Redacto@gmail.com,09/03/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090510186683),$10.95,$0.00,$10.95,,,,Redact,USD,
09/05/18,111-5371799-5233057,The Rules of Magic: A Novel (The Practical Magic Series),ABIS_BOOK,1501137484,55101500,Amazon.com,2018-06-26 0:00:01,new,Amazon.com,,$16.00,$11.00,1,American Express - 1000,,,Redacto@gmail.com,09/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,USPS(9341989687090113502544),$11.00,$0.66,$11.66,,,,Redact,USD,
09/05/18,111-5371799-5233057,Practical Magic,ABIS_BOOK,425190374,55101500,Amazon.com,2003-08-05 0:00:01,new,Amazon.com,,$16.00,$13.26,1,American Express - 1000,,,Redacto@gmail.com,09/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,USPS(9341989687090113502544),$13.26,$0.80,$14.06,,,,Redact,USD,
09/08/18,112-4178582-2557830,"Muscle Milk Pro Series Creatine Powder Supplement, Unflavored, 1.1 Pound, 100 Servings, 5g Creatine Monohydrate, NSF Certified for Sport, Packaging May Vary",NUTRITIONAL_SUPPLEMENT,B0106ZC4T8,50500000,Amazon.com,,new,"MMS Warehouse Supply, LLC",,$25.99,$22.49,1,MasterCard - 3698,,,Redacto@gmail.com,09/08/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090583233547),$22.49,$0.00,$22.49,,,,Redact,USD,
09/13/18,112-2076875-7508254,Extreme Ownership: How U.S. Navy SEALs Lead and Win (New Edition),ABIS_BOOK,1250183863,55101500,Amazon.com,2017-11-21 0:00:01,new,Amazon.com,,$27.99,$14.80,1,MasterCard - 3698,,,Redacto@gmail.com,09/14/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090114823358),$14.80,$0.89,$15.69,,,,Redact,USD,
09/13/18,112-2076875-7508254,The Dichotomy of Leadership: Balancing the Challenges of Extreme Ownership to Lead and Win,ABIS_BOOK,1250195772,55101500,Amazon.com,2018-09-25 0:00:01,new,Amazon.com,,$28.99,$18.89,1,MasterCard - 3698,,,Redacto@gmail.com,09/25/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090116106541),$18.89,$1.13,$20.02,,,,Redact,USD,
09/16/18,111-8809875-4709810,Epsoak Epsom Salt 19 lb. Bulk Bag Magnesium Sulfate USP,BATHWATER_ADDITIVE,B004N7DQHA,53131600,Amazon.com,,new,San Francisco Salt Company,,$34.99,$27.99,1,MasterCard - 3698,,,Redacto@gmail.com,09/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090588631720),$27.99,$0.00,$27.99,,,,Redact,USD,
09/16/18,111-8809875-4709810,"Redmond Clay - Bentonite Facial Mask , Soothing Mud Mask | Clay of 1000 Uses, 24 Ounce",SKIN_TREATMENT_MASK,B00MH96JBG,53131613,Amazon.com,,new,Redmond Life,,$15.99,$15.49,1,MasterCard - 3698,,,Redacto@gmail.com,09/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090588565605),$15.49,$0.00,$15.49,,,,Redact,USD,
09/16/18,111-1631964-0712263,"DrTung’s Stainless Tongue Scraper - Tongue Cleaner for Adults, Kids, Helps Freshens Breath, Easy to Use Comfort Grip Handle, Comes with Travel Case - Stainless Steel Tongue Scrapers (1 Pack)",BEAUTY,B00064JGBO,42181500,Amazon.com,,new,Panda & Moose,,$8.99,$7.30,1,MasterCard - 3698,,,Redacto@gmail.com,09/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9200192148989966605028),$7.30,$0.00,$7.30,,,,Redact,USD,
09/18/18,111-0929568-6737043,"Cellucor Super HD Thermogenic Fat Burner, Appetite Suppressant & Metaboltism Booster for Weight Loss, 60 Capsules, G4",NUTRITIONAL_SUPPLEMENT,B00VAPRSP4,50500000,Amazon.com,,new,Nutrabolt Brands,,$29.99,$16.71,1,American Express - 1000,,,Redacto@gmail.com,09/19/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,USPS(9374889687090521083230),$16.71,$0.00,$16.71,,,,Redact,USD,
09/18/18,111-8381958-5145020,"ChocZero's Keto Bark, Milk Chocolate Hazelnuts, No Added Sugar, Low Carb, No Sugar Alcohols, Non-GMO (6oz per bag, 2 bags)",CHOCOLATE_CANDY,B06XKWR97N,50161800,Amazon.com,,new,ChocZero,,$0.00,$14.99,1,MasterCard - 3698,,,Redacto@gmail.com,09/19/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9305520111402960766447),$14.99,$0.00,$14.99,,,,Redact,USD,
09/28/18,111-9250055-2016201,goldenwarm 10 Pack Gold Cabinet Knobs Brushed Brass Drawer Knobs Gold Dresser Hardware - LS201GD Single Hole Handles for Kitchen Cupboard Doors Closet Hardware 2in Overall Length,HARDWARE_HANDLE,B01MQ7I8IJ,31162801,Amazon.com,,new,GoldenwarmDirect,,$0.00,$12.99,1,Visa - 4900,,,Redacto@gmail.com,09/28/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,USPS(9374889687090528007215),$12.99,$0.00,$12.99,,,,Redact,USD,
09/28/18,111-8236962-3703431,"SEI Furniture Colbi Glam Glass-Topped C-Table, Champagne with Faux Marble Glass",TABLE,B07BNV7C7Y,56000000,Amazon.com,,new,Furniture HotSpot,,$99.99,$79.99,2,Visa - 4900,,,Redacto@gmail.com,10/01/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,"UPS(1Z9765F01333343880),UPS(1Z9765F01331721297)",$159.98,$0.00,$159.98,,,,Redact,USD,
09/29/18,111-9413628-3965858,"ChocZero's Keto Bark, Milk Chocolate Hazelnuts, No Added Sugar, Low Carb, No Sugar Alcohols, Non-GMO (6oz per bag, 2 bags)",CHOCOLATE_CANDY,B06XKWR97N,50161800,Amazon.com,,new,ChocZero,,$0.00,$14.99,1,MasterCard - 3698,,,Redacto@gmail.com,09/30/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9305520111402983672510),$14.99,$0.00,$14.99,,,,Redact,USD,
09/29/18,111-9667712-8953866,Youth in Revolt: The Journals of Nick Twisp,ABIS_BOOK,385481969,55101500,Amazon.com,1996-03-15 0:00:01,new,Trinh Ho,,$19.00,$7.00,1,MasterCard - 3698,,,Redacto@gmail.com,09/29/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341920111402983470239),$7.00,$0.00,$7.00,,,,Redact,USD,
09/29/18,111-0435336-7750634,It's Kind of a Funny Story,ABIS_BOOK,078685197X,55101500,Amazon.com,2007-04-03 0:00:01,new,Amazon.com,,$9.99,$7.88,1,MasterCard - 3698,,,Redacto@gmail.com,09/30/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090116664591),$7.88,$0.47,$8.35,,,,Redact,USD,
10/12/18,111-8228071-8279425,"wall26 - 3 Piece Canvas Wall Art - Side Profile of a Lion in Black and White in The Kruger National Park, South Africa. - Modern Home Art Stretched and Framed Ready to Hang - 24""x36""x3 Panels",WALL_ART,B072L5CZ8Q,60120000,Amazon.com,,new,wall26,,$0.00,$79.99,1,American Express - 1000,,,Redacto@gmail.com,10/12/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,FedEx(783222602185),$79.99,$0.00,$79.99,,,,Redact,USD,
10/12/18,111-0479471-7562601,"mDesign Decorative Wood Wall Mount Storage Organizer Rack for Coats, Hoodies, Hats, Scarves, Purses, Leashes, Bath Towels, Robes, Men and Women's Clothing - 8 Metal Hanging Hooks - White/Gold Brass",STORAGE_HOOK,B01NH9V4UF,31162600,Amazon.com,,new,MetroDecor,,$29.99,$29.99,1,American Express - 1000,,,Redacto@gmail.com,10/14/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z3Y55R10317061069),$29.99,$1.80,$31.79,,,,Redact,USD,
10/12/18,111-1010190-2863415,"Classic Scroll Arm Velvet Fabric Accent Chair, Living Room Armchair (Black)",CHAIR,B0754MYXB7,56101500,Amazon.com,,new,Logistar,,$0.00,$99.99,1,American Express - 1000,,,Redacto@gmail.com,10/14/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,FedEx(783237485970),$99.99,$6.00,$105.99,,,,Redact,USD,
10/14/18,111-2311111-8878661,"Freshware Meal Prep Containers [21 Pack] 3 Compartment with Lids, Food Storage Containers, Bento Box, BPA Free, Stackable, Microwave/Dishwasher/Freezer Safe (24 oz)",FOOD_STORAGE_CONTAINER,B073TRZ91C,52150000,Amazon.com,,new,Amazon.com,,$0.00,$13.49,2,MasterCard - 3698,,,Redacto@gmail.com,10/14/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090603810307),$26.98,$1.62,$28.60,,,,Redact,USD,
10/14/18,111-2311111-8878661,,,B01D8DAYII,,Amazon.com,,new,Amazon.com,,$249.99,$149.99,1,MasterCard - 3698,,,Redacto@gmail.com,10/15/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,UPS(1Z9V54581302328050),$149.99,$9.00,$158.99,,,,Redact,USD,
10/14/18,111-4395483-3861802,"The Inheritance Cycle Series 4 Book Set Collection Eragon, Eldest, Brisngr",ABIS_BOOK,449813223,55101500,Amazon.com,2012-10-23 0:00:01,new,Amazon.com,,$55.96,$30.06,1,MasterCard - 3698,,,Redacto@gmail.com,10/15/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090118426975),$30.06,$1.80,$31.86,,,,Redact,USD,
10/16/18,112-3753000-8736232,12 Rules for Life: An Antidote to Chaos,ABIS_BOOK,345816021,55101500,Amazon.com,2018-01-23 0:00:01,new,Amazon.com,,$25.95,$15.57,1,MasterCard - 3698,,,Redacto@gmail.com,10/17/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090118630914),$15.57,$0.93,$16.50,,,,Redact,USD,
10/17/18,111-9994357-5237864,"Versanora Minimalista Dining Tables, Clear",TABLE,B0711YBGTY,56101543,Amazon.com,,new,Green Gifts for Kids,,$219.00,$189.99,1,American Express - 1000,,,Redacto@gmail.com,10/18/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z37Y9A20310647283),$189.99,$0.00,$189.99,,,,Redact,USD,
10/17/18,111-5287860-9207423,"Giantex DSW Dining Chair, Pre Assembled Mid Century Style Wood Dining Chairs, Modern DSW Chair, Shell Lounge Plastic Side Chair for Kitchen, Dining, Bedroom, Living Room, Set of 4, White",CHAIR,B01GUVDCES,56101500,Amazon.com,,new,MarevoStore,,$0.00,$89.99,1,Visa - 4900,,,Redacto@gmail.com,10/19/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z4V82R20334971684),$89.99,$0.00,$89.99,,,,Redact,USD,
10/23/18,112-7213876-4471431,"Brian Jacques Redwall Series 1-6 (Redwall, Mossflower, Mattimeo, Mariel of Redwall, Salamandastron, Martin the Warrior)",ABIS_BOOK,B003ZJAZRQ,55101500,Amazon.com,,used verygood,Second Editions Bookshop,,$0.00,$33.95,1,MasterCard - 3698,,,Redacto@gmail.com,10/23/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9449011899223775765428),$33.95,$0.00,$33.95,,,,Redact,USD,
11/04/18,111-3329282-3168231,"The Daily Stoic: 366 Meditations on Wisdom, Perseverance, and the Art of Living",ABIS_BOOK,735211736,55101500,Amazon.com,2016-10-18 0:00:01,new,Amazon.com,,$25.00,$17.00,1,MasterCard - 3698,,,Redacto@gmail.com,11/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,TUSCALOOSA,AL,35487,Shipped,USPS(9341989675090134243568),$17.00,$1.36,$18.36,,,,Redact,USD,
11/04/18,111-3329282-3168231,12 Rules for Life: An Antidote to Chaos,ABIS_BOOK,345816021,55101500,Amazon.com,2018-01-23 0:00:01,new,Amazon.com,,$25.95,$15.57,1,MasterCard - 3698,,,Redacto@gmail.com,11/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,TUSCALOOSA,AL,35487,Shipped,USPS(9341989675090134243568),$15.57,$1.25,$16.82,,,,Redact,USD,
11/04/18,112-7376433-3645831,,,B0768TVNR9,,Amazon.com,,new,Powerextra,,$0.00,$26.99,1,MasterCard - 3698,,,Redacto@gmail.com,11/05/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9374889687090551723243),$26.99,$0.00,$26.99,,,,Redact,USD,
11/26/18,114-0578387-2404213,"YETI Rambler 30 oz Tumbler, Stainless Steel, Vacuum Insulated with MagSlider Lid",DRINKING_CUP,B07FMBWPYR,52152100,Amazon.com,,new,YETI Authorized,,$34.99,$34.99,1,American Express - 1000,,,Redacto@gmail.com,11/30/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,USPS(9361289687090640158110),$34.99,$2.10,$37.09,,,,Redact,USD,
11/26/18,114-0578387-2404213,,,B075K72LRW,,Amazon.com,,new,LAYEE US,,$0.00,$26.98,1,American Express - 1000,,,Redacto@gmail.com,11/30/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,USPS(9361289687090640158110),$26.98,$0.00,$26.98,,,,Redact,USD,
12/03/18,114-2419735-7179437,Jade Roller & Gua Sha Scraping Massage Tool By Truweo - Himalayan Anti-aging 100% Natural Facial Jade Stone Set - Face Eye Neck Beauty Roller For Slimming & Firming - Rejuvenate Skin & Remove Wrinkles,MASSAGER,B07DQ9XYSY,53131600,Amazon.com,,new,RENCARE,,$0.00,$13.50,2,American Express - 1000,,,Redacto@gmail.com,12/04/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1ZY185470221418700),$27.00,$0.00,$27.00,,,,Redact,USD,
12/22/18,112-5806434-7467420,The Daily Stoic Journal: 366 Days of Writing and Reflection on the Art of Living,ABIS_BOOK,525534393,55101500,Amazon.com,2017-11-14 0:00:01,new,Amazon.com,,$27.00,$18.36,1,MasterCard - 3698,,,Redacto@gmail.com,12/22/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090130471465),$18.36,$1.10,$19.46,,,,Redact,USD,
12/26/18,112-7144980-4719450,Harry Potter Hardcover Boxed Set: Books 1-7,ABIS_BOOK,545044251,55101500,Amazon.com,2007-10-01 0:00:01,new,MB Commerce,,$205.93,$149.49,1,MasterCard - 3698,,,Redacto@gmail.com,12/28/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS,$149.49,$0.00,$149.49,,,,Redact,USD,
12/26/18,112-2875160-0545807,Hogwarts Library (Harry Potter),ABIS_BOOK,1338132326,55101500,Amazon.com,2017-03-14 0:00:01,new,Amazon.com,,$38.97,$25.02,1,MasterCard - 3698,,,Redacto@gmail.com,12/26/18,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9341989687090130851120),$25.02,$1.50,$26.52,,,,Redact,USD,
01/01/19,112-2686141-2612212,"TOROS-GROUP Adjustable Back Brace - Medical Device Premium Posture Corrector Providing Pain Relief with 100% Cotton Liner - Lumbar Support with Breathable Fabric - Unisex Bandage for Woman, Men & Kids",ORTHOPEDIC_BRACE,B079ZXM64T,46180000,Amazon.com,,new,TOROS-GROUP,,$0.00,$55.00,1,MasterCard - 3698,,,Redacto@gmail.com,01/02/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,USPS(9361289687090675012845),$55.00,$0.00,$55.00,,,,Redact,USD,
01/02/19,112-8286814-7794660,"Anthony Facial Scrub, 8 Fl Oz, Contains Aloe Vera, Sand, Algae, Chamomile, Vitamin C, Soothes, Protects, Refreshes and Removes Dead Skin Cells",SKIN_EXFOLIANT,B00MRFOTHG,53131613,Amazon.com,,new,Amazon.com,,$36.00,$36.00,1,MasterCard - 3698,,,Redacto@gmail.com,01/03/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA645209863000),$36.00,$2.16,$38.16,,,,Redact,USD,
01/04/19,112-5617266-3385840,"EPIC Wagyu Beef Steak Strips, Grass-Fed, Paleo Friendly, 0.8 Ounce (Pack of 20)",JERKY,B01N24QGE2,50192100,Amazon.com,,new,Amazon.com,,$39.80,$37.18,1,MasterCard - 3698,,,Redacto@gmail.com,01/05/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA652461466000),$37.18,$0.00,$37.18,,,,Redact,USD,
01/16/19,113-6776394-0821852,Shibari Mini Halo Powerful Handheld Rechargeable Massager Wireless Waterproof Wand Perfect for Arms Neck Legs Back Spine Hands Pain Muscle Stress Relaxing (Black),SEXUAL_STIMULATION_DEVICE,B010GLYPW6,53131700,Amazon.com,,new,Amazon.com,,$80.98,$29.53,1,American Express - 1000,,,Redacto@gmail.com,01/17/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA701403156000),$29.53,$1.77,$31.30,,,,Redact,USD,
02/01/19,112-0962993-8359430,"Pawfectchow Probiotics for Dogs + Natural Digestive Enzymes - Made in USA - 240 Billion CFUs Prebiotics Chews - Diarrhea, Gas, Constipation Relief-190 Chews",PET_FOOD,B00M9GI2IK,10121800,Amazon.com,,new,Organic Natural View,,$0.00,$20.17,1,MasterCard - 3698,,,Redacto@gmail.com,02/04/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,USPS(RR259286365TH),$20.17,$0.00,$20.17,,,,Redact,USD,
02/14/19,114-4583057-9469022,Windtech PG-2000 PopGard Windscreen and Mic Protector,SOUND_AND_RECORDING_EQUIPMENT,B00946ZJN4,52160000,Amazon.com,,new,Kellards,,$42.99,$29.95,1,MasterCard - 3698,,,Redacto@gmail.com,02/15/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA811244793000),$29.95,$0.00,$29.95,,,,Redact,USD,
02/20/19,114-8300379-9842616,Gonine DMW DCC8 AC Adapter AC10 AC8 DC Power Supply BLC12 Dummy Battery Charger Kit for Panasonic Lumix G7 G85 DMC FZ300 FZ2500 FZ1000 FZ200 GX8 G6 G5 GH2 DC-G90 G95 Cameras.,CHARGING_ADAPTER,B01D69P0UG,26111704,Amazon.com,,new,Gonine,,$25.00,$18.66,1,MasterCard - 3698,,,Redacto@gmail.com,02/21/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA832855315000),$18.66,$0.00,$18.66,,,,Redact,USD,
02/24/19,114-3289945-5187459,"Neewer 18-inch White LED Ring Light with Light Stand Lighting Kit Dimmable 50W 3200-5600K with Soft Filter, Hot Shoe Adapter, Cellphone Holder for Make-up Video Shooting (NO Carrying Bag)",CAMERA_CONTINUOUS_LIGHT,B07G281NTC,45121600,Amazon.com,,new,BestDealUSA,,$0.00,$139.99,1,MasterCard - 3698,,,Redacto@gmail.com,02/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z9702VX0324959293),$139.99,$0.00,$139.99,,,,Redact,USD,
03/12/19,112-0928726-9982641,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$52.52,1,MasterCard - 3698,,,Redacto@gmail.com,03/13/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,FEDEX(484527053626),$52.52,$3.15,$55.67,,,,Redact,USD,
04/08/19,112-6182524-9917034,A Discourse on the Method (Oxford World's Classics),ABIS_BOOK,199540071,55101500,Amazon.com,2008-06-15 0:00:01,new,Amazon.com,,$8.95,$7.52,1,MasterCard - 3698,,,Redacto@gmail.com,04/08/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA025539926000),$7.52,$0.45,$7.97,,,,Redact,USD,
04/08/19,112-6182524-9917034,The Problems of Philosophy,ABIS_BOOK,161427486X,55101500,Amazon.com,2013-09-11 0:00:01,new,Amazon.com,,$7.25,$4.95,1,MasterCard - 3698,,,Redacto@gmail.com,04/08/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z445E130311188064),$4.95,$0.30,$5.25,,,,Redact,USD,
04/18/19,114-6572235-5373005,"D'vine Dev 8 Inch Ceramic Flower Vase, Minimalist Design for Home Décor, Fireplace, Bedroom, Kitchen, Living Room, Centerpieces, Office Desk, Design Box Packaged, Cream White, VS-SW-8",VASE,B079JF79N7,56141500,Amazon.com,,new,DD LST,,$0.00,$14.99,1,American Express - 2008,,,Redacto@gmail.com,04/19/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA072080245000),$14.99,$0.00,$14.99,,,,Redact,USD,
04/18/19,114-6572235-5373005,Essentra Home White Marble Square Tissue Box Cover - Blanc Collection,PUMP_DISPENSER,B07H2T7QH7,47131704,Amazon.com,,new,Essentra Home,,$0.00,$19.94,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/18/19,114-6572235-5373005,"EssentraHome White Ceramic Soap Dispenser with Marble Look for Bedroom,Bathroom or Kitchen. Also Great for Hand Lotion and Essential Oils",HOME_BED_AND_BATH,B07DBSMG45,47131700,Amazon.com,,new,Essentra Home,,$0.00,$12.44,1,American Express - 2008,,,Redacto@gmail.com,04/18/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA069172530000),$12.44,$0.00,$12.44,,,,Redact,USD,
04/18/19,114-6572235-5373005,"Style Setter Canister Set 3-Piece Glass Jars in 30oz, 50oz and 65oz Chic Design with Gold Lids for Cookies, Candy, Coffee, Flour, Sugar, Rice, Pasta, Cereal & More, Clear",NON_RIDING_TOY_VEHICLE,B07G7S8767,52150000,Amazon.com,,new,Classee,,$0.00,$24.99,1,American Express - 2008,,,Redacto@gmail.com,04/18/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA069172530000),$24.99,$0.00,$24.99,,,,Redact,USD,
04/18/19,114-6572235-5373005,"mDesign Round Ceramic Refillable Liquid Soap Dispenser Pump Bottle for Bathroom Vanity Countertop, Kitchen Sink - Holds Hand Soap, Dish Soap, Hand Sanitizer, Essential Oils - White/Soft Brass",HOME_BED_AND_BATH,B06XY3191D,47131704,Amazon.com,,new,MetroDecor,,$14.99,$12.99,1,American Express - 2008,,,Redacto@gmail.com,04/18/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA069355752000),$12.99,$0.78,$13.77,,,,Redact,USD,
04/18/19,114-6572235-5373005,"HIC Harold Import Co. HIC Utensil and Kitchen Tool Holder, Fine White Porcelain, 6-Inches",KITCHEN,B000UT7CMK,52151600,Amazon.com,,new,Amazon.com,,$8.40,$17.13,1,American Express - 2008,,,Redacto@gmail.com,04/19/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA072235061000),$17.13,$1.03,$18.16,,,,Redact,USD,
04/18/19,114-6572235-5373005,"Kiera Grace Kieragrace Modern Shelves Maine Simple & Classic Single Decorative Engineered Wood Floating Wall Shelf for Home, Room, & Office, 24"" L x 5"" W x 1.5"" H, White, Inch",SHELF,B00OZJ8Y82,24102000,Amazon.com,,new,Amazon.com,,$19.99,$19.99,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/18/19,114-6572235-5373005,"Acne Treatment Cream With Secret TEA TREE OIL Formula - Perfect For Fighting Breakouts, Spots, Cystic Acne, Acne Scar Removal - See Results in Days Without Dry Skin - by Keeva Organics (1oz)",SKIN_MOISTURIZER,B07FYX11TH,53131613,Amazon.com,,new,Serex Wholesale,,$34.97,$24.97,1,American Express - 2008,,,Redacto@gmail.com,04/18/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA069355752000),$24.97,$0.00,$24.97,,,,Redact,USD,
04/20/19,111-5788640-2708222,"Wire Baskets, Gold 2 Pack Wire Basket, Organizing Storage Crafts Decor Kitchen (Gold Copper)",BASKET,B079W4LBLN,52150000,Amazon.com,,new,Majika Premier,,$0.00,$24.99,2,MasterCard - 3698,,,Redacto@gmail.com,04/20/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA078112253000),$49.98,$0.00,$49.98,,,,Redact,USD,
04/25/19,114-4525147-8661044,,,B00N11K0LY,,Amazon.com,,new,US  Acrylic,,$15.99,$12.50,1,American Express - 2008,,,Redacto@gmail.com,04/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA100765287000),$12.50,$0.00,$12.50,,,,Redact,USD,
04/25/19,114-4525147-8661044,"STORi 6-Piece Stackable Clear Drawer Organizer Set | 6"" x 6"" x 2"" Square Trays | Small Makeup Vanity Storage Bins and Office Desk Drawer Dividers | Made in USA",CADDY,B07NDGYQZN,44000000,Amazon.com,,new,US  Acrylic,,$16.99,$13.99,2,American Express - 2008,,,Redacto@gmail.com,04/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA100874057000),$27.98,$0.00,$27.98,,,,Redact,USD,
04/25/19,114-4525147-8661044,"GERZWY Bathroom Brushed Gold Coat Hook SUS 304 Stainless Steel Single Towel/Robe Clothes Hook for Bath Kitchen Contemporary Hotel Style Wall Mounted 2 Pack,AG1107B-BZ",STORAGE_HOOK,B07KHDFP71,31162600,Amazon.com,,new,GERZ,,$0.00,$17.99,1,American Express - 2008,,,Redacto@gmail.com,04/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA100874057000),$17.99,$0.00,$17.99,,,,Redact,USD,
04/25/19,114-4525147-8661044,"mDesign Modern Square Plastic Refillable Liquid Soap Dispenser Pump Bottle for Bathroom Vanity Countertop, Kitchen Sink - Holds Hand Soap, Dish Soap, Hand Sanitizer, Essential Oils - Clear/Gold",PUMP_DISPENSER,B078J3LS8K,47131704,Amazon.com,,new,MetroDecor,,$9.99,$8.99,1,American Express - 2008,,,Redacto@gmail.com,04/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA101402651000),$8.99,$0.54,$9.53,,,,Redact,USD,
04/25/19,114-4525147-8661044,"mDesign Modern Square Tumbler Cup for Bathroom Vanity Countertops - for Mouthwash/Mouth Rinse, Storing and Organizing Makeup Brushes, Eye Liners, Accessories - Slim Design, 2 Pack - Clear/Gold",TOOTHBRUSH_HOLDER,B0794RZ3SD,52152100,Amazon.com,,new,MetroDecor,,$12.99,$10.99,1,American Express - 2008,,,Redacto@gmail.com,04/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA100874057000),$10.99,$0.66,$11.65,,,,Redact,USD,
04/25/19,114-4525147-8661044,"mDesign Plastic Square Apothecary Jar Storage Organizer Holder for Bathroom Vanity Countertop Shelf Decor - Cotton Swabs, Soap, Makeup, Bath Salts - Lumiere Collection - 2 Pack - Clear/Gold",CADDY,B01MUGK1V0,53131600,Amazon.com,,new,MetroDecor,,$0.00,$12.99,1,American Express - 2008,,,Redacto@gmail.com,04/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA100874057000),$12.99,$0.78,$13.77,,,,Redact,USD,
04/30/19,114-7354271-8944247,LONGKING Peel and Stick Backsplash Tile Shelf-Adhesive Wall Stickers (10 Sheets) Off White Subway Tile with Grey Grout,TILE,B07DRFRPJJ,30131700,Amazon.com,,new,LONGKING,,$39.90,$29.99,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/30/19,114-7354271-8944247,Goldenwarm Brass Cabinet Pulls Kitchen Hardware 15 Pack - 4in Hole Centers Gold Drawer Handles Brass Cabinet Door Handles Bathroom Bar Drawer Pulls 6-2/5Inch Overall Length,HARDWARE_HANDLE,B07JVS5234,53000000,Amazon.com,,new,GoldenwarmDirect,,$0.00,$22.99,1,American Express - 2008,,,Redacto@gmail.com,05/01/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA125944778000),$22.99,$0.00,$22.99,,,,Redact,USD,
04/30/19,114-7354271-8944247,"STANLEY Classic 99 Utility Knife, Retractable, 6-Inch, (10-099)",UTILITY_KNIFE,B00002X204,27110000,Amazon.com,,new,Amazon.com,,$5.95,$4.70,1,American Express - 2008,,,Redacto@gmail.com,05/01/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA126790390000),$4.70,$0.28,$4.98,,,,Redact,USD,
04/30/19,114-7354271-8944247,"Achim Home Furnishings FTVWD22920 Nexus Self Adhesive 20 Vinyl Floor Tiles, 12"" x 12"", Charcoal Grey Wood, Count",TILE,B01HCVG0SK,30131700,Amazon.com,,new,Amazon.com,,$0.00,$12.98,2,American Express - 2008,,,Redacto@gmail.com,05/01/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA126790390000),$25.96,$1.56,$27.52,,,,Redact,USD,
04/30/19,114-7354271-8944247,"DOCHY Tree Swings Hanging Straps 5 feet Outdoor Hammock Straps, Adjustable Safer Nylon Rope Extension Conversion Rope Suspension Kit (Brown)",CARGO_STRAP,B07JV94NNK,31151900,Amazon.com,,new,EEZYCHOIC,,$0.00,$11.58,1,American Express - 2008,,,Redacto@gmail.com,05/01/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA125944778000),$11.58,$0.00,$11.58,,,,Redact,USD,
04/30/19,114-7354271-8944247,"Hammaka Hammock Net Chair, Rope Chair",HAMMOCK,B000ZH00QM,56101600,Amazon.com,,new,Amazon.com,,$59.99,$37.94,1,American Express - 2008,,,Redacto@gmail.com,05/01/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,USPS(9361289687090731541036),$37.94,$2.28,$40.22,,,,Redact,USD,
05/07/19,114-6882086-6513061,,,B07JMF4DFN,,Amazon.com,,new,Daisy Rose,,$0.00,$22.78,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,,,$0.00,$0.00,$0.00,,,,Redact,USD,
05/07/19,114-6882086-6513061,,,B07Q747B9V,,Amazon.com,,new,FairyBridal,,$0.00,$39.99,1,American Express - 2008,,,Redacto@gmail.com,05/09/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA161940777000),$39.99,$0.00,$39.99,,,,Redact,USD,
05/07/19,114-5185302-6109038,,,B07KVZXMFZ,,Amazon.com,,new,Stylebag,,$0.00,$37.88,1,American Express - 2008,,,Redacto@gmail.com,05/08/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,SF Express(959797225745),$37.88,$0.00,$37.88,,,,Redact,USD,
05/07/19,111-6337807-3414601,,,B078WLJDB4,,Amazon.com,,new,vocosi,,$0.00,$52.99,1,American Express - 2008,,,Redacto@gmail.com,05/09/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA161940777000),$52.99,$0.00,$52.99,,,,Redact,USD,
05/30/19,111-4945595-8754661,,,B00BDJNQGW,,Amazon.com,,new,FROM A TO Z,,$0.00,$45.45,1,American Express - 2008,,,Redacto@gmail.com,05/30/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA266560176000),$45.45,$0.00,$45.45,,,,Redact,USD,
05/30/19,111-4945595-8754661,"Proactiv Acne Cleanser - Benzoyl Peroxide Face Wash and Acne Treatment - Daily Facial Cleanser and Hyularonic Acid Moisturizer with Exfoliating Beads - 90 Day Supply, 6 Oz",SKIN_CLEANING_AGENT,B004DOYM2Q,53131613,Amazon.com,,new,Amazon.com,,$34.95,$37.50,1,American Express - 2008,,,Redacto@gmail.com,05/30/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA266551017000),$37.50,$2.25,$39.75,,,,Redact,USD,
05/30/19,111-4945595-8754661,"Anthony Facial Scrub, 8 Fl Oz, Contains Aloe Vera, Sand, Algae, Chamomile, Vitamin C, Soothes, Protects, Refreshes and Removes Dead Skin Cells",SKIN_EXFOLIANT,B00MRFOTHG,53131613,Amazon.com,,new,Amazon.com,,$36.00,$36.00,1,American Express - 2008,,,Redacto@gmail.com,05/30/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA266560176000),$36.00,$2.16,$38.16,,,,Redact,USD,
06/08/19,114-2410897-6751435,"Pernicious Pickling Co., Pickled Red Onions Sweet N Sour, 16 Ounce",PICKLE,B015QA7D3U,50000000,Amazon.com,,new,myPanier | Specialty Food Marketplace,,$0.00,$15.99,1,MasterCard - 3698,,,Redacto@gmail.com,06/10/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,FedEx(787777838474),$15.99,$0.00,$15.99,,,,Redact,USD,
06/14/19,114-8436458-9177042,"Gel Eye Mask with Eye Holes- Hot Cold Compress Pack Eye Therapy | Cooling Eye Mask for Puffy Eyes, Dry Eyes, Headaches, Migraines, Sinus, Dark Circles - Reusable Eye Face Mask | Ergo Gel Bead (Blue)",THERAPEUTIC_THERMAL_COMPRESS,B07N1VGGBC,53131600,Amazon.com,,new,Blanc Group,,$0.00,$8.55,2,MasterCard - 3698,,,Redacto@gmail.com,06/15/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA345448928000),$17.10,$0.00,$17.10,,,,Redact,USD,
06/30/19,112-6574655-5576209,"Japanese Snack Assortment 35 pcs of 27 types Full of""DAGASHI"", OHIMESAMA Snack Selection (M)",COOKIE,B072PWS148,50000000,Amazon.com,,new,Pio Big Bazar,,$0.00,$18.78,1,Visa - 6843,,,Redacto@gmail.com,06/30/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA423563850000),$18.78,$0.00,$18.78,,,,Redact,USD,
07/13/19,112-0002986-4060248,S.J JEWELRY Womens Minimal Jewelry Gift Dainty Tiny Composite 14K Gold Plated/Silver Plated Cross and Anchor/Freshwater Pearls Heart Bracelet Handmade Jewelry,BRACELET,B07BDFTS1S,54100000,Amazon.com,,new,S.J JEWELRY,,$32.99,$9.99,1,American Express - 2008,,,Redacto@gmail.com,07/14/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA490480185000),$9.99,$0.00,$9.99,,,,Redact,USD,
07/13/19,112-0002986-4060248,Outee 12 Pairs Tassel Earrings for Women Fashion Bohemian Earrings Colorful Layered Long Thread Ball Dangly Earrings Tiered Drop Earrings Jewelry Valentine Birthday Party Gifts,EARRING,B07QKDSYYV,54100000,Amazon.com,,new,Outee Official Store,,$0.00,$12.99,1,American Express - 2008,,,Redacto@gmail.com,07/14/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA491870058000),$12.99,$0.00,$12.99,,,,Redact,USD,
07/13/19,112-0002986-4060248,Altitude Boutique Brushed Bar Bracelet for Women(Gold),BRACELET,B0785XPN3X,54100000,Amazon.com,,new,Altitude Boutique,,$0.00,$14.99,1,American Express - 2008,,,Redacto@gmail.com,07/14/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA490480185000),$14.99,$0.00,$14.99,,,,Redact,USD,
07/13/19,112-7359214-3985044,Chic Boho Style Dark Brown Genuine Leather Earrings Leaf Like Leather Earrings Leather Petals,EARRING,B07C7VJRYS,54100000,Amazon.com,,new,Neomark,,$0.00,$5.99,1,American Express - 2008,,,Redacto@gmail.com,07/14/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA490480185000),$5.99,$0.00,$5.99,,,,Redact,USD,
07/15/19,114-0842831-5502647,,,B07H271FTM,,Amazon.com,,new,OH Official Store,,$119.96,$46.72,1,Visa - 6843,,,Redacto@gmail.com,07/17/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA515127675000),$46.72,$0.00,$46.72,,,,Redact,USD,
07/16/19,112-0261713-4369049,Bored and Brilliant: How Spacing Out Can Unlock Your Most Productive and Creative Self,ABIS_BOOK,1250124956,55101500,Amazon.com,2017-09-05 0:00:01,new,Amazon.com,,$26.99,$11.40,1,Visa - 6843,,,Redacto@gmail.com,07/17/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA516505875000),$11.40,$0.68,$12.08,,,,Redact,USD,
07/16/19,112-0261713-4369049,Digital Minimalism: Choosing a Focused Life in a Noisy World,ABIS_BOOK,525536515,55101500,Amazon.com,2019-02-05 0:00:01,new,Amazon.com,,$26.00,$18.61,1,Visa - 6843,,,Redacto@gmail.com,07/17/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA516505875000),$18.61,$1.12,$19.73,,,,Redact,USD,
07/26/19,112-8405746-9805040,"Chinook Seedery Roasted Sunflower Seeds - Jumbo Seeds Keto Snacks - Best For Snack Packs - Gluten Free, Non GMO Snack Food Gifts - 4 ounce (Pack of 6) - Parmesan & Pepper Flavor",NUT_AND_SEED,B017OAFECW,50000000,Amazon.com,,new,TexasFood,,$0.00,$21.95,1,Visa - 6843,,,Redacto@gmail.com,07/29/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,EAST RUTHERFORD,NJ,07073-2140,Shipped,FedEx(788762539237),$21.95,$0.00,$21.95,,,,Redact,USD,
08/19/19,111-9084021-7168215,GRECERELLE Women's Casual Loose Pocket Long Dress Short Sleeve Split Maxi Dresses,DRESS,B0711CSHJH,53100000,Amazon.com,,new,grecerelle,,$34.99,$23.99,1,American Express - 2008,,,Redacto@gmail.com,08/20/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA699806223000),$23.99,$0.00,$23.99,,,,Redact,USD,
08/19/19,111-9084021-7168215,Fruit of the Loom Women's Spaghetti Strap Cotton Pullover Sports Bra,BRA,B07K99J8MK,53101700,Amazon.com,,new,Amazon.com,,$12.50,$10.94,1,American Express - 2008,,,Redacto@gmail.com,08/20/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA700790747000),$10.94,$0.66,$11.60,,,,Redact,USD,
08/19/19,111-9084021-7168215,FUT Women Sexy Thong Panty Waist Cincher Girdle Tummy Control Shapewear Slimmer Body Shaper,UNDERPANTS,B07CPMGYHC,53100000,Amazon.com,,new,FUTATA,,$15.99,$9.99,1,American Express - 2008,,,Redacto@gmail.com,08/20/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA700790747000),$9.99,$0.00,$9.99,,,,Redact,USD,
08/25/19,112-0262421-3929869,"2022 Planner - Year Weekly & Monthly Planner with Tabs, Smooth Faux Leather & Flexible Cover with White Paper, January 2022 to December 2022, Pen Loop, Twin-Wire Binding, 5.85"" x 8.25"", Gray",PLANNER,B07QXJXTXC,14111500,Amazon.com,,new,Kodexlode,,$18.99,$6.99,1,American Express - 2008,,,Redacto@gmail.com,08/26/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z445FW31378769467),$6.99,$0.00,$6.99,,,,Redact,USD,
08/25/19,112-0262421-3929869,"15 Pack Brushed Nickel Cabinet Pulls Drawer Handles - homdiy Cabinet Hardware Stainless Steel Cabinet Handles 4in Hole Centers Cupboard Pull Drawer Pulls, 201SN",HARDWARE_HANDLE,B07GXDDZQT,31162801,Amazon.com,,new,Homdiy,,$0.00,$20.99,3,American Express - 2008,,,Redacto@gmail.com,08/26/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,UPS(1Z445FW31378769467),$62.97,$0.00,$62.97,,,,Redact,USD,
09/01/19,112-4575897-9339439,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$61.99,1,MasterCard - 3698,,,Redacto@gmail.com,09/01/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA769325814000),$61.99,$3.72,$65.71,,,,Redact,USD,
09/13/19,111-5063569-7549864,"Proactiv Acne Cleanser - Benzoyl Peroxide Face Wash and Acne Treatment - Daily Facial Cleanser and Hyularonic Acid Moisturizer with Exfoliating Beads - 90 Day Supply, 6 Oz",SKIN_CLEANING_AGENT,B004DOYM2Q,53131613,Amazon.com,,new,Amazon.com,,$34.95,$31.84,1,Visa - 6843,,,Redacto@gmail.com,09/14/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA837467525000),$31.84,$1.91,$33.75,,,,Redact,USD,
09/24/19,112-8149394-5307401,"La Colombe Vanilla Draft Latte - Cold-Pressed Espresso and Frothed Milk With True Vanilla - Made With Real Ingredients - Grab And Go Coffee , 9 Fl Oz (Pack of 16)",COFFEE,B07BYQN3XS,50201700,Amazon.com,,new,Amazon.com,,$0.00,$44.64,1,Visa - 6843,,,Redacto@gmail.com,09/24/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA895557685000),$44.64,$0.00,$44.64,,,,Redact,USD,
10/14/19,112-3392467-7683457,MAPOL 50- Pack Orange 3-Star Premium Ping Pong Balls Advanced Training Table Tennis Ball,RECREATION_BALL,B00M9334KE,55101500,Amazon.com,,new,great-store,,$13.59,$9.98,1,MasterCard - 3698,,,Redacto@gmail.com,10/15/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1020,Shipped,AMZN_US(TBA015173128000),$9.98,$0.00,$9.98,,,,Redact,USD,
10/14/19,112-3392467-7683457,STIGA Pro Carbon Performance-Level Table Tennis Racket with Carbon Technology for Tournament Play,SPORT_RACKET,B00EFY9F9E,49180000,Amazon.com,,new,Amazon.com,,$109.99,$62.99,1,MasterCard - 3698,,,Redacto@gmail.com,10/15/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1020,Shipped,AMZN_US(TBA015173128000),$62.99,$4.41,$67.40,,,,Redact,USD,
10/15/19,114-8082220-5049010,,,B076P23RWF,,Amazon.com,,new,QuiFit US,,$0.00,$14.95,1,MasterCard - 3698,,,Redacto@gmail.com,10/16/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1020,Shipped,AMZN_US(TBA022137488000),$14.95,$0.00,$14.95,,,,Redact,USD,
10/15/19,114-2057442-5443462,"Optimum Nutrition Pro Complex Whey Protein Powder Blend, Rich Milk Chocolate, 3.35 lbs (Packaging May Vary)",PROTEIN_SUPPLEMENT_POWDER,B00IA9QTIK,50500000,Amazon.com,,new,Amazon.com,,$99.99,$50.39,1,MasterCard - 3698,,,Redacto@gmail.com,10/16/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1020,Shipped,AMZN_US(TBA018918803000),$50.39,$0.00,$50.39,,,,Redact,USD,
10/17/19,112-2094328-7753813,"La Colombe Vanilla Draft Latte - Cold-Pressed Espresso and Frothed Milk With True Vanilla - Made With Real Ingredients - Grab And Go Coffee , 9 Fl Oz (Pack of 16)",COFFEE,B07BYQN3XS,50201700,Amazon.com,,new,Amazon.com,,$50.99,$50.99,1,MasterCard - 3698,,,Redacto@gmail.com,10/18/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA031030041000),$50.99,$0.00,$50.99,,,,Redact,USD,
10/23/19,112-8668225-2733812,"Ozeri ZK14-AB Pronto Digital Multifunction Kitchen and Food Scale, Standard, All Black",WEIGH_SCALE,B01JK4XPVW,52151646,Amazon.com,,new,Amazon.com,,$14.95,$14.95,1,MasterCard - 3698,,,Redacto@gmail.com,10/23/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA066360462000),$14.95,$1.05,$16.00,,,,Redact,USD,
10/23/19,112-8668225-2733812,ThermoPro TP18 Ultra Fast Thermocouple Digital Instant Read Meat Thermometer for Grilling BBQ Smoker Kitchen Food Cooking Thermometer for Oil Deep Fry Candy Thermometer,THERMOMETER,B0793MSG7S,52150000,Amazon.com,,new,i-Tronics,,$69.99,$19.99,1,MasterCard - 3698,,,Redacto@gmail.com,10/23/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA066360462000),$19.99,$1.40,$21.39,,,,Redact,USD,
11/04/19,114-2603659-7161834,"BoxLegend V3 Shirt Folding Board t Shirts Clothes Folder Durable Plastic Laundry folders Folding Boards,Black",SHIRT,B077XV9VZG,53100000,Amazon.com,,new,BoxLegend,,$16.99,$19.99,1,MasterCard - 3698,,,Redacto@gmail.com,11/04/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA139467517000),$19.99,$0.00,$19.99,,,,Redact,USD,
11/05/19,112-5814038-5718613,Mini New Orleans Mardi Gras King Cake 4 OZ,CAKE,B00B382K1A,50000000,Amazon.com,,new,Cajun Supermarket,,$0.00,$17.99,1,American Express - 1004,,,Redacto@gmail.com,11/07/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9405510200830388804411),$17.99,$0.00,$17.99,,,,Redact,USD,
11/12/19,114-9266506-6495444,"Optimum Nutriton Pro Complex, Whey Protein Powder Blend, Creamy Vanilla, 3.31 Pounds (Packaging May Vary)",PROTEIN_SUPPLEMENT_POWDER,B00IA9QUN4,50500000,Amazon.com,,new,Amazon.com,,$99.99,$42.54,1,American Express - 1004,,,Redacto@gmail.com,11/14/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA196189937000),$42.54,$0.00,$42.54,,,,Redact,USD,
11/12/19,112-3710570-2430616,"BlenderBottle Classic Shaker Bottle Perfect for Protein Shakes and Pre Workout, 28-Ounce (2 Pack), Clear/Black",BOTTLE,B06XDCB2DP,24122000,Amazon.com,,new,Amazon.com,,$19.98,$18.40,2,American Express - 1004,,,Redacto@gmail.com,11/13/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA195569419000),$36.80,$2.58,$39.38,,,,Redact,USD,
11/12/19,112-3710570-2430616,"BlenderBottle pro stak ProStak Twist n' Lock Storage Jars Expansion 3-Pak with Pill Tray, All Black",BOTTLE,B01LZEGPZP,24122000,Amazon.com,,new,Amazon.com,,$7.99,$7.99,2,American Express - 1004,,,Redacto@gmail.com,11/13/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA196056924000),$15.98,$1.12,$17.10,,,,Redact,USD,
11/19/19,114-4552000-1688228,,,B01D8DAYII,,Amazon.com,,new,Amazon.com,,$249.99,$134.99,1,American Express - 1004,,,Redacto@gmail.com,11/19/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA243666452000),$134.99,$9.45,$144.44,,,,Redact,USD,
11/19/19,114-4552000-1688228,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$49.99,1,American Express - 1004,,,Redacto@gmail.com,11/19/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA243319718000),$49.99,$3.50,$53.49,,,,Redact,USD,
11/21/19,112-1323179-3550667,"Professional 47""x20""x55"" Photo LED Large Cube Shooting Tents Photography Continuous Lighting Flashery Video Studio Dimmable Lightbox Softbox with 3 Colors PVC Backdrops and Carrying Bag",CAMERA_STAGE_LIGHTING_MODIFIER,B073JC59QK,45121600,Amazon.com,,new,Photographic equipment,,$0.00,$269.99,1,American Express - 1004,,,Redacto@gmail.com,11/23/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-9005,Shipped,UPS(1ZAV17000248542419),$269.99,$0.00,$269.99,,,,Redact,USD,
12/05/19,112-8557705-9881851,Logitech G Pro Wireless Gaming Mouse with Esports Grade Performance,INPUT_MOUSE,B07GCKQD77,43211708,Amazon.com,,new,Amazon.com,,$149.99,$129.99,1,American Express - 1004,,,Redacto@gmail.com,12/11/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,UPS(1Z20472E1361159396),$129.99,$9.10,$139.09,,,,Redact,USD,
12/10/19,112-7858078-0256260,"ChocZero's Keto Bark, Milk Chocolate Hazelnuts, No Added Sugar, Low Carb, No Sugar Alcohols, Non-GMO (6oz per bag, 2 bags)",CHOCOLATE_CANDY,B06XKWR97N,50161800,Amazon.com,,new,ChocZero,,$0.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,12/11/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9261290234802902692152),$14.99,$0.00,$14.99,,,,Redact,USD,
12/12/19,112-2280901-2255411,"3 Book Set of The Kingkiller Chronicle Series (The Name of the Wind, Wise Man's Fear and The Slow Regard of Silent Things)",ABIS_BOOK,B01AYP2012,55111505,Amazon.com,,new,Ambis Enterprises,,$0.00,$30.13,1,American Express - 1004,,,Redacto@gmail.com,12/16/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9205590161060502994360),$30.13,$0.00,$30.13,,,,Redact,USD,
12/12/19,112-4519824-7847434,Survive!: Essential Skills and Tactics to Get You Out of Anywhere - Alive,ABIS_BOOK,61373516,55101500,Amazon.com,2008-11-11 0:00:01,new,Amazon.com,,$19.99,$8.32,1,American Express - 1004,,,Redacto@gmail.com,12/13/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA441624436000),$8.32,$0.58,$8.90,,,,Redact,USD,
12/12/19,112-5512148-9077005,SOTO Pocket Torch (Only – w/o Refillable Lighter),MECHANICAL_LIGHTER,B0046UZRNU,50211600,Amazon.com,,new,Alextohn,,$24.95,$24.99,1,American Express - 1004,,,Redacto@gmail.com,12/12/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9374889677090412770709),$24.99,$0.00,$24.99,,,,Redact,USD,
12/12/19,112-7055299-8707430,"AmScope PS100A Prepared Microscope Slide Set for Basic Biological Science Education, 100 Slides, Set A, Includes Fitted Wooden Case",TEACHING_EQUIPMENT,B0055E5VR8,41122600,Amazon.com,,new,Amazon.com,,$99.96,$49.99,1,American Express - 1004,,,Redacto@gmail.com,12/12/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,UPS(1Z89F4590209621405),$49.99,$3.50,$53.49,,,,Redact,USD,
12/12/19,112-5098336-5622641,"Kershaw Cryo Folding Knife (1555TI); 2.75” 8Cr13MoV Steel Blade, Stainless Steel Handle, Titanium Carbo-Nitride Coating, SpeedSafe Assisted Open, Frame Lock, 4-Position Deep-Carry Pocketclip; 4.1 OZ",KNIFE,B0074FI28Q,27110000,Amazon.com,,new,Amazon.com,,$54.99,$34.84,1,American Express - 1004,,,Redacto@gmail.com,12/12/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA426573763000),$34.84,$2.44,$37.28,,,,Redact,USD,
12/12/19,112-5098336-5622641,Extreme Ownership: How U.S. Navy SEALs Lead and Win (New Edition),ABIS_BOOK,1250183863,55101500,Amazon.com,2017-11-21 0:00:01,new,Amazon.com,,$27.99,$16.79,1,American Express - 1004,,,Redacto@gmail.com,12/12/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA426573763000),$16.79,$1.18,$17.97,,,,Redact,USD,
12/18/19,112-7146892-7784260,"Metene Shower Brush with Soft and Stiff Bristles, Bath Dual-Sided Long Handle Back Scrubber Body Exfoliator for Wet or Dry Brushing",SKIN_CLEANING_BRUSH,B07QKH9GYV,53131600,Amazon.com,,new,ｍetene,,$0.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,12/21/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA517530663000),$14.99,$0.00,$14.99,,,,Redact,USD,
12/18/19,112-6110878-3121811,"Anthony Facial Scrub, 8 Fl Oz, Contains Aloe Vera, Sand, Algae, Chamomile, Vitamin C, Soothes, Protects, Refreshes and Removes Dead Skin Cells",SKIN_EXFOLIANT,B00MRFOTHG,53131613,Amazon.com,,new,Amazon.com,,$36.00,$36.00,1,American Express - 1004,,,Redacto@gmail.com,12/19/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9361289677090412702131),$36.00,$2.52,$38.52,,,,Redact,USD,
12/18/19,112-6110878-3121811,"Anthony Exfoliating Body Scrub – Sea Salt, Vitamin C, And Aloe Vera Deep Cleans, Smooths Rough Patches & Soothes and Protects Skin – Blue Sea Kelp Body Wash 12 Fl Oz",SKIN_EXFOLIANT,B00J4OTK9A,53131600,Amazon.com,,new,Amazon.com,,$28.00,$28.00,1,American Express - 1004,,,Redacto@gmail.com,12/19/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9361289677090412702131),$28.00,$1.96,$29.96,,,,Redact,USD,
12/25/19,113-7283690-0690651,Amazon.com eGift Card,GIFT_CARD,B07YJGNZM5,64151505,Amazon.com,,new,Amazon.com,,$50.00,$75.00,1,American Express - 2008,,,Redacto@gmail.com,12/25/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,,,,Shipped,,$75.00,$0.00,$75.00,,,,Redact,USD,
12/28/19,112-3476243-5680235,"EasyTouch Twist Lancets - 30 G, (100 per Box)",HEALTH_PERSONAL_CARE,B00B58QGVS,41104100,Amazon.com,,new,MedLab Supply Co.,,$7.00,$3.51,1,American Express - 1004,,,Redacto@gmail.com,12/30/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,USPS(9300120111404189686190),$3.51,$0.00,$3.51,,,,Redact,USD,
12/28/19,112-1402895-4859403,"Keto-Mojo 50 Blood Ketone Test Strips, Precision Measurement for Diabetes & Low-Carb Weight Loss, Monitor Your Diabetic & Ketogenic Diet for Nutritional Ketosis, Strips Work Only in Keto-Mojo Meters",MEDICAL_TEST_STRIP,B078B426TM,50000000,Amazon.com,,new,keto-mojo,,$0.00,$49.50,1,American Express - 1004,,,Redacto@gmail.com,12/29/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA559596373000),$49.50,$0.00,$49.50,,,,Redact,USD,
12/28/19,112-1402895-4859403,"KETO-MOJO Bluetooth Ketone & Glucose Blood Testing Kit + APP, 20 Test Strips (10 Each), 1 Meter, 10 Lancets, 1 Lancing Device, Monitor Your Ketosis & Ketogenic Diet",GLUCOSE_METER,B0789G8KTG,41104100,Amazon.com,,new,keto-mojo,,$84.97,$59.99,1,American Express - 1004,,,Redacto@gmail.com,12/29/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA559596373000),$59.99,$0.00,$59.99,,,,Redact,USD,
12/28/19,112-1038513-2212244,"People's Choice Beef Jerky - Old Fashioned - Original - Healthy, Sugar Free, Zero Carb, Gluten Free, Keto Friendly, High Protein Meat Snack - Dry Texture - 2.5 Ounce, 1 Bag (Pack of 3)",JERKY,B07B2Z1S2V,50192100,Amazon.com,,new,People's Choice Beef Jerky,,$0.00,$18.99,1,American Express - 1004,,,Redacto@gmail.com,12/29/19,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA559601424000),$18.99,$0.00,$18.99,,,,Redact,USD,
01/01/20,114-0949720-0560268,"YETI Rambler Vacuum Insulated Stainless Steel Half Gallon Jug with MagCap, Stainless Steel",PITCHER,B072884LGL,52152100,Amazon.com,,new,YETI Authorized,,$99.99,$99.99,1,American Express - 1004,,,Redacto@gmail.com,01/02/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA585394798000),$99.99,$7.00,$106.99,,,,Redact,USD,
01/08/20,112-6326332-1167426,"ChocZero's Keto Bark, Milk Chocolate Hazelnuts, No Added Sugar, Low Carb, No Sugar Alcohols, Non-GMO (6oz per bag, 2 bags)",CHOCOLATE_CANDY,B06XKWR97N,50161800,Amazon.com,,new,ChocZero,,$0.00,$14.99,2,American Express - 1004,,,Redacto@gmail.com,01/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA632722624000),$29.98,$0.00,$29.98,,,,Redact,USD,
01/16/20,111-1583002-4980229,"DHM Detox® - No Days Wasted® - New Look - Dihydromyricetin (DHM Supplement), Milk Thistle, Prickly Pear, B Vitamins, Hydration Electrolytes Blend-Take During Evening for Better Next Day and Recovery",NUTRITIONAL_SUPPLEMENT,B07P9S5Y4M,50500000,Amazon.com,,new,No Days Wasted,,$0.00,$29.99,1,American Express - 2008,,,Redacto@gmail.com,01/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA688831551000),$29.99,$0.00,$29.99,,,,Redact,USD,
02/14/20,112-2723763-9880254,Nero Ball Ultra TM - Dog Training Ball On A Rope - Exercise and Reward Toy for Dogs,PET_TOY,B01FD60BBI,10111300,Amazon.com,,new,Nero Ball,,$21.99,$15.95,1,American Express - 2008,,,Redacto@gmail.com,02/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA891572132000),$15.95,$0.00,$15.95,,,,Redact,USD,
02/17/20,112-5927607-6305831,"Berry Blueberry Conventional, 18 Ounce",FRUIT,B07Q5MPRQ9,50000000,Amazon.com,,new,Whole Foods Market,,$4.99,$5.99,1,American Express - 1004,,,Redacto@gmail.com,02/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,RABBIT(SpOSIOC1359QFRY8223K),$5.99,$0.00,$5.99,,,,Redact,USD,
02/17/20,112-5927607-6305831,White Onion,HERB,B078F6N7KL,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$0.71,1,American Express - 1004,,,Redacto@gmail.com,02/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,RABBIT(SpOSIOC1359QFRY8223K),$0.71,$0.00,$0.71,,,,Redact,USD,
02/17/20,112-5927607-6305831,"Phillips Mushroom Farms, Mushroom Shiitake Organic, 3.5 Ounce",CULINARY_MUSHROOM,B077ZXHWHW,50000000,Amazon.com,,new,Whole Foods Market,,$4.49,$4.99,1,American Express - 1004,,,Redacto@gmail.com,02/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,RABBIT(SpOSIOC1359QFRY8223K),$4.99,$0.00,$4.99,,,,Redact,USD,
02/17/20,112-5927607-6305831,Beef Ribeye Steak Boneless Dry Aged Pasture Raised Step 4,MEAT,B07JB73PTN,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$47.98,1,American Express - 1004,,,Redacto@gmail.com,02/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,RABBIT(SpOSIOC1359QFRY8223K),$47.98,$0.00,$47.98,,,,Redact,USD,
02/19/20,114-8185559-3285008,"Dog Bite Tug Toy with 2 Strong Handles - Tough, Durable Fire Hose Dog Tug for Medium to Large Dogs - Ideal for Tug of War, Fetch, Puppy Training & Fun Interactive Play - Handmade Pull Toys by Bull Fit",PET_TOY,B07HHZPR1Z,10111300,Amazon.com,,new,Bull Fit,,$19.99,$16.99,1,American Express - 1004,,,Redacto@gmail.com,02/20/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA925345291000),$16.99,$0.00,$16.99,,,,Redact,USD,
02/21/20,114-5718960-6717867,"Hi Kiss Dog/Puppy Obedience Recall Training Agility Lead - 15ft 20ft 30ft 50ft 100ft Training Leash - Great for Training, Play, Camping, or Backyard - Black 20ft",LEASH,B07217B1Z7,10111300,Amazon.com,,new,Hikiss Direct,,$0.00,$8.97,1,American Express - 1004,,,Redacto@gmail.com,02/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA937379246000),$8.97,$0.00,$8.97,,,,Redact,USD,
02/21/20,114-0136909-9423438,"RUFFWEAR, Front Range Dog Harness, Reflective and Padded Harness for Training and Everyday, Twilight Gray, Large/X-Large",ANIMAL_HARNESS,B082FPCZGV,10141600,Amazon.com,,new,NetRush,,$39.95,$39.95,1,American Express - 1004,,,Redacto@gmail.com,02/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,DAVIE,FL,33314-1219,Shipped,AMZN_US(TBA936944820000),$39.95,$2.80,$42.75,,,,Redact,USD,
03/05/20,114-8180045-3433050,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$61.79,2,American Express - 1004,,,Redacto@gmail.com,03/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA027296364000),$123.58,$8.66,$132.24,,,,Redact,USD,
03/08/20,112-0944822-8085012,Skimeeze Pool Skimmer- Pool Net Skimmer Cleans Debris and Leaves for In-Ground and Above Ground Pools,OUTDOOR_LIVING,B00AO7JUK0,56101600,Amazon.com,,new,Skimeeze,,$46.10,$35.96,1,American Express - 1004,,,Redacto@gmail.com,03/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090432311245),$35.96,$0.00,$35.96,,,,Redact,USD,
03/08/20,112-3315923-2067421,"ICEFANG Tactical Dog Harness ,Large Size, 2X Metal Buckle,Working Dog MOLLE Vest with Handle,No Pulling Front Leash Clip,Hook and Loop Panel",ANIMAL_HARNESS,B07BYC5F3W,10111300,Amazon.com,,new,ICEFANG Direct,,$0.00,$36.98,1,American Express - 1004,,,Redacto@gmail.com,03/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA071369587000),$36.98,$0.00,$36.98,,,,Redact,USD,
03/08/20,112-7688307-1323403,"Doggie Stylz Set of 2 Reflective Service Dog DO NOT PET Removable Patches with Hook Backing for Working Dog Harnesses & Vests. Durable and Interchangeable - Comes in 3 Sizes Small, Medium and Large",ANIMAL_HARNESS,B07CGMYC6S,10000000,Amazon.com,,new,Doggie Stylz,,$11.99,$11.99,1,American Express - 1004,,,Redacto@gmail.com,03/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9300120111404373679557),$11.99,$0.00,$11.99,,,,Redact,USD,
03/09/20,112-9808335-2033059,"CELSIUS Essential Energy Drink 12 Fl Oz, Sparkling Wild Berry (Pack of 24)",DRINK_FLAVORED,B001534QYW,50202309,Amazon.com,,new,Amazon.com,,$0.00,$40.08,1,American Express - 1004,,,Redacto@gmail.com,03/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA058118629000),$40.08,$0.00,$40.08,,,,Redact,USD,
03/10/20,112-8533515-8595421,"SmartSign Private Property Beware of Dogs Sign with Stake | 21"" Tall Sign & Stake Kit - Property Protected by Video Surveillance Sign For Yard / Lawn | 10x7 Inches Aluminum Metal Sign",SIGNAGE,B07V9R2YF2,55121700,Amazon.com,,new,SmartSign,,$0.00,$15.95,2,American Express - 1004,,,Redacto@gmail.com,03/10/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA062635273000),$31.90,$0.00,$31.90,,,,Redact,USD,
03/12/20,114-9449016-3851439,,,B000OGNQEM,,Amazon.com,,new,🚚ExpressShip,,$39.95,$29.99,1,American Express - 1004,,,Redacto@gmail.com,03/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA082177267000),$29.99,$0.00,$29.99,,,,Redact,USD,
03/13/20,114-3706769-8094622,"Floating Recliner Inflatable Lounge, 71in X 53in",WATER_FLOTATION_DEVICE,B000KHZ044,60141000,Amazon.com,,new,Amazon.com,,$19.99,$18.49,2,American Express - 1004,,,Redacto@gmail.com,03/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA086864237000),$36.98,$2.58,$39.56,,,,Redact,USD,
03/13/20,114-2449464-9655400,,,B07DPWY3PJ,,Amazon.com,,new,TopLevelSupply,,$0.00,$12.95,1,American Express - 1004,,,Redacto@gmail.com,03/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA086864237000),$12.95,$0.00,$12.95,,,,Redact,USD,
03/13/20,112-8254194-0669818,The Woman in the Window: A Novel,ABIS_BOOK,62678418,55101500,Amazon.com,2018-01-02 0:00:01,new,Amazon.com,,$26.99,$12.99,1,American Express - 1004,,,Redacto@gmail.com,03/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA086864237000),$12.99,$0.91,$13.90,,,,Redact,USD,
03/14/20,114-8045687-9117064,Solar Breeze – Automatic Pool Cleaner NX2 Cleaning Robot,OUTDOOR_LIVING,B079DFX9PD,56101600,Amazon.com,,new,Kaspien,,$799.00,$498.00,1,American Express - 1004,,,Redacto@gmail.com,03/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090433825550),$498.00,$34.86,$532.86,,,,Redact,USD,
03/14/20,114-3054456-0313026,"Merrick Power Bites Dog Treats, Real Beef Recipe - 6 oz. Bag",PET_FOOD,B00JAEKHOG,10121806,Amazon.com,,new,Amazon.com,,$6.99,$5.35,1,American Express - 1004,,,Redacto@gmail.com,03/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA091842219000),$5.35,$0.37,$5.72,,,,Redact,USD,
03/15/20,112-5196190-0945068,"iRobot Authentic Replacement Parts- Braava jet m Series Wet Mopping Pads, (7-Pack), White - 4632824",ROBOTIC_VACUUM_CLEANER,B07RKL715Q,47121602,Amazon.com,,new,Amazon.com,,$7.99,$7.98,1,American Express - 1004,,,Redacto@gmail.com,03/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA099376581000),$7.98,$0.56,$8.54,,,,Redact,USD,
03/15/20,114-3581860-4602616,"Dexas MudBuster Portable Dog Paw Cleaner, Large, Green (PW720383)",HAIR_BRUSH,B01MYNCHEC,10111300,Amazon.com,,new,Amazon.com,,$24.99,$16.39,1,American Express - 1004,,,Redacto@gmail.com,03/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA108343116000),$16.39,$1.15,$17.54,,,,Redact,USD,
03/15/20,114-3581860-4602616,"URPOWER Dog Seat Cover with Detachable Mesh Window 100% Waterproof Bench Dog Car Seat Cover for Back Seat Nonslip Pet Rear Seat Protector for Fur & Mud, Washable Dog Hammock for Cars Trucks and SUV",VEHICLE_SEAT_COVER,B07R2JLR4V,25170000,Amazon.com,,new,COCO-VISION,,$0.00,$40.99,1,American Express - 1004,,,Redacto@gmail.com,03/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA108343116000),$40.99,$0.00,$40.99,,,,Redact,USD,
03/16/20,112-1779198-3308259,"Musher's Secret Dog Paw Wax (2.1 Oz): All Season Pet Paw Protection Against Heat, Hot Pavement, Sand, Dirt, Snow - Great for Dogs on Trails and Walks!",PET_HEALTH_CARE,B0002IJQDC,10111300,Amazon.com,,new,Musher's Secret,,$14.99,$12.99,1,American Express - 1004,,,Redacto@gmail.com,03/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z81WV111316780522),$12.99,$0.00,$12.99,,,,Redact,USD,
03/21/20,114-5971928-1057034,"Van Ness Pets Crock Style Heavyweight Jumbo Bowl, 106 OZ Food/Water Dish",PET_FEEDER,B0002ASCD0,10111300,Amazon.com,,new,Amazon.com,,$5.79,$7.99,1,American Express - 1004,,,Redacto@gmail.com,03/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA136265007000),$7.99,$0.56,$8.55,,,,Redact,USD,
03/22/20,112-1535136-6693856,"Gaiam Essentials Premium Yoga Mat with Yoga Mat Carrier Sling (72""L x 24""W x 1/4 Inch Thick)",EXERCISE_MAT,B07H9PZMVV,49211800,Amazon.com,,new,Amazon.com,,$14.99,$14.99,1,American Express - 2008,,,Redacto@gmail.com,03/24/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA159089711000),$14.99,$1.05,$16.04,FALSE,,FALSE,Redact,USD,
03/22/20,112-1535136-6693856,"TheraBand Mini Ball, Small Exercise Ball for Yoga, Pilates, Abdominal Workouts, Shoulder Therapy, Core Strengthening, At-Home Gym & Physical Therapy Tool",RECREATION_BALL,B008DIFJT8,49000000,Amazon.com,,new,Amazon.com,,$14.49,$14.49,1,American Express - 2008,,,Redacto@gmail.com,03/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA151327513000),$14.49,$1.01,$15.50,FALSE,,FALSE,Redact,USD,
03/22/20,112-1535136-6693856,"Soft Dog Muzzle Cover with Dogs Hook & Loop for Small,Medium and Large Dogs, Anti Biting and Chewing, Adjustable, Breathable(M,Blue)",ANIMAL_MUZZLE,B07PB26CBX,10000000,Amazon.com,,new,wintchuk U.S.,,$0.00,$12.97,1,American Express - 2008,,,Redacto@gmail.com,03/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA147287737000),$12.97,$0.00,$12.97,FALSE,,FALSE,Redact,USD,
03/22/20,112-1535136-6693856,Nestle Carnation Malted Milk Chocolate Mix 13 ounces,FLAVORED_DRINK_CONCENTRATE,B007CMSW4U,50000000,Amazon.com,,new,Saberin Direct,,$0.00,$9.77,1,American Express - 2008,,,Redacto@gmail.com,03/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA147287737000),$9.77,$0.00,$9.77,FALSE,,FALSE,Redact,USD,
03/24/20,111-1759952-7829814,Bicycle Mini Decks Playing Cards - Single Deck - (Color May Vary) - Smaller Than Traditional Deck,TABLETOP_GAME,B07C9W8DJK,60141103,Amazon.com,,new,Amazon.com,,$1.99,$1.99,1,American Express - 1004,,,Redacto@gmail.com,03/25/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA163488267000),$1.99,$0.14,$2.13,,,,Redact,USD,
03/24/20,111-1759952-7829814,"Dogit Jawz Dog Waste Scooper, Dog Pooper Scooper for Grass and Gravel Easy Pick Up",PET_SUPPLIES,B00LQHKIJY,10111303,Amazon.com,,new,Amazon.com,,$11.99,$11.99,1,American Express - 1004,,,Redacto@gmail.com,03/25/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1ZW709680349816473),$11.99,$0.84,$12.83,,,,Redact,USD,
03/24/20,111-1759952-7829814,"PopSockets: Phone Grip with Expanding Kickstand, Pop Socket for Phone - White",CELL_PHONE_GRIP,B07NZ1YMRG,43191610,Amazon.com,,new,KMKB Online Sales,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,03/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA164976583000),$9.99,$0.00,$9.99,,,,Redact,USD,
04/01/20,114-0149606-8902643,"CELSIUS Essential Energy Drink 12 Fl Oz, Sparkling Kiwi Guava (Pack of 12)",DRINK_FLAVORED,B07BY3HJPT,50202309,Amazon.com,,new,Amazon.com,,$29.88,$22.56,1,American Express - 1004,,,Redacto@gmail.com,04/02/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA210347454000),$22.56,$0.00,$22.56,,,,Redact,USD,
04/01/20,114-8453954-7237062,"CELSIUS Essential Energy Drink 12 Fl Oz, Sparkling Wild Berry (Pack of 24)",DRINK_FLAVORED,B001534QYW,50202309,Amazon.com,,new,Amazon.com,,$0.00,$40.08,1,American Express - 1004,,,Redacto@gmail.com,04/02/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA000965918801),$40.08,$0.00,$40.08,,,,Redact,USD,
04/02/20,112-9918491-1142666,"T-fal E83407 Pre-Seasoned Nonstick Durable Cast Iron Skillet / Fry pan Cookware, 12-Inch, Black -",SAUTE_FRY_PAN,B00IWSQCI6,52151802,Amazon.com,,new,Home & Cook USA,,$34.99,$29.99,1,American Express - 1004,,,Redacto@gmail.com,04/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1ZX641W50306730360),$29.99,$2.10,$32.09,,,,Redact,USD,
04/03/20,112-2641988-8895465,"5 Pcs of Silicone Chocolate Candy Molds, AIFUDA Puppy Paw & Bone Non-stick Baking Molds Ice Cube Trays for Making Gumdrop Jelly Cake Muffin Cupcake - Pink, Purple, Red",FOOD_PREPARATION_MOLD,B07D33G6JY,52150000,Amazon.com,,new,AIFUDA,,$26.99,$10.59,1,American Express - 1004,,,Redacto@gmail.com,04/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA003627295701),$10.59,$0.00,$10.59,,,,Redact,USD,
04/03/20,111-1181017-4744247,"Coffee Frother, Milk Frother Electric Mini Blender Milk Frother Handheld Frother for Bulletproof Coffee with Stainless Steel Whisk",MILK_FROTHER,B085VX6J2C,52141500,Amazon.com,,new,iHomy,,$0.00,$15.99,1,American Express - 1004,,,Redacto@gmail.com,04/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA003594722801),$15.99,$0.00,$15.99,,,,Redact,USD,
04/03/20,111-8438125-3549046,"Goody Ouchless Womens Hair Scrunchie - 8 Count, Black - Suitable for All Hair Types - Pain-Free Hair Accessories for Women Perfect for Long Lasting Braids, Ponytails and More",HAIR_TIE,B001T8M9RY,53131600,Amazon.com,,new,Amazon.com,,$4.93,$4.88,1,American Express - 1004,,,Redacto@gmail.com,04/04/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA002002570901),$4.88,$0.34,$5.22,,,,Redact,USD,
04/04/20,112-3824820-0577042,,,B07FJ797QN,,Amazon.com,,new,oocome®,,$0.00,$19.69,1,American Express - 1004,,,Redacto@gmail.com,04/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA008797310301),$19.69,$0.00,$19.69,,,,Redact,USD,
04/04/20,114-0149442-2080269,"Philips Hue Econic Outdoor White & Color Wall & Ceiling Light Fixture (Hue Hub Required, Works with Alexa, Apple Homekit & Google Assistant)",LIGHT_FIXTURE,B07NDF3VPH,39111500,Amazon.com,,new,Amazon.com,,$159.99,$159.99,3,American Express - 1004,,,Redacto@gmail.com,04/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA002882473101),$479.97,$33.60,$513.57,,,,Redact,USD,
04/04/20,114-0149442-2080269,"Philips Hue Dusk-to-Dawn Outdoor Motion Sensor for Smart Home, Wireless & Easy to Install (Hue Hub Required, for use with Philips Hue Smart Lights)",MOTION_DETECTOR_DEVICE,B07NDFQF2T,39122200,Amazon.com,,new,Amazon.com,,$49.95,$49.97,1,American Express - 1004,,,Redacto@gmail.com,04/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA003627295701),$49.97,$3.50,$53.47,,,,Redact,USD,
04/06/20,112-4983534-9493852,"Philips Hue Lily White & Color Outdoor Spot Light Base kit (Hue Hub required), 3 Spot Lights with power supply + mount, Works with Alexa, HomeKit & Google Assistant",LIGHT_FIXTURE,B07DBGW73T,39111600,Amazon.com,,new,Amazon.com,,$339.99,$312.41,1,American Express - 1004,,,Redacto@gmail.com,04/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090439758616),$312.41,$21.87,$334.28,,,,Redact,USD,
04/06/20,114-4674104-9971445,Excel-erated Learning: Explaining in plain English how dogs learn and how best to teach them,ABIS_BOOK,1888047070,55101500,Amazon.com,2013-08-30 0:00:01,new,Amazon.com,,$16.95,$16.95,1,American Express - 1004,,,Redacto@gmail.com,04/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA008797310301),$16.95,$1.19,$18.14,,,,Redact,USD,
04/06/20,114-9873167-5177017,Zenith Zoysia Grass Seed (2 Lb.) 100% Pure Seed Grown by Patten Seed Company,PLANT_SEED,B006RSDM3Q,50000000,Amazon.com,,new,Patten Seed Company,,$74.95,$86.00,1,American Express - 1004,,,Redacto@gmail.com,04/07/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(391686662411),$86.00,$6.02,$92.02,,,,Redact,USD,
04/08/20,112-3874347-1549860,Asana Pranayama Mudra Bandha/2008 Fourth Revised Edition,ABIS_BOOK,8186336141,55101500,Amazon.com,2008-08-01 0:00:01,new,campus-media,,$33.00,$19.88,1,American Express - 1004,,,Redacto@gmail.com,04/10/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS,$19.88,$0.00,$19.88,,,,Redact,USD,
04/09/20,112-6848472-1865818,"Philips Hue Lily White & Color Outdoor Spot Light Base kit (Hue Hub required), 3 Spot Lights with power supply + mount, Works with Alexa, HomeKit & Google Assistant",LIGHT_FIXTURE,B07DBGW73T,39111600,Amazon.com,,new,OneDealOutlet Online,,$339.99,$305.91,1,American Express - 1004,,,Redacto@gmail.com,04/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z37Y0596769014451),$305.91,$0.00,$305.91,,,,Redact,USD,
04/10/20,112-0558813-1969844,Google Nest Cam Outdoor 2-Pack - 1st Generation - Weatherproof Outdoor Camera - Surveillance Camera with Night Vision - Control with Your Phone,SECURITY_CAMERA,B01M66NWOQ,46171610,Amazon.com,,new,Alpine Electronic's,,$298.00,$297.99,1,American Express - 1004,,,Redacto@gmail.com,04/10/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z17E2100316794382),$297.99,$0.00,$297.99,,,,Redact,USD,
04/10/20,112-5998606-0585010,"Philips Hue Discover Outdoor White & Color Ambiance Smart Floodlight (Hue Hub Required, Smart Light Works with Alexa, Apple Homekit and Google Assistant)",LIGHT_FIXTURE,B07NDGD11D,39111500,Amazon.com,,new,Amazon.com,,$159.99,$159.99,3,American Express - 1004,,,Redacto@gmail.com,04/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA007035209901),$479.97,$33.60,$513.57,,,,Redact,USD,
04/13/20,112-8571005-3957012,"nuLOOM Hand Woven Chunky Natural Jute Farmhouse Area Rug, 7 ft 6 in x 9 ft 6 in, Off-white",RUG,B00GHZ0B9M,52101500,Amazon.com,,new,Amazon.com,,$349.00,$150.86,0,American Express - 1004,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/13/20,112-8571005-3957012,"Amazon Brand – Rivet Surrey Modern Ceramic Planter Pot with Wood Plant Stand, 13.6""H, White",PLANTER,B07JM6HFW3,56101600,Amazon.com,,new,Amazon.com,,$68.49,$68.44,1,American Express - 1004,,,Redacto@gmail.com,04/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA008616789901),$68.44,$4.79,$73.23,,,,Redact,USD,
04/13/20,112-8571005-3957012,"Nearly Natural 5357 4ft. Golden Cane Palm Tree,Green",ARTIFICIAL_TREE,B00FUNF4NK,56101528,Amazon.com,,new,Amazon.com,,$63.99,$52.28,1,American Express - 1004,,,Redacto@gmail.com,04/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090441824248),$52.28,$3.66,$55.94,,,,Redact,USD,
04/15/20,112-2405579-0289855,"Pulaski DS-D030003-329 Wood Frame Faux Leather Accent Chair, 25.38"" x 28.0"" x 30.5"", Cognac Brown",CHAIR,B076WWQ4NN,56101504,Amazon.com,,new,KART IT,,$320.00,$136.87,2,American Express - 1004,,,Redacto@gmail.com,04/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(392098496808),$273.74,$0.00,$273.74,,,,Redact,USD,
04/16/20,114-3005152-1751420,Aberlite EDC - Premium Beard Straightener Brush for Men - Professional Straightening Tool Heated Comb - for Short & Long Beards  - for Home and Travel (Black),HAIR_IRON,B07MN6LVG2,53131600,Amazon.com,,new,Aberlite,,$0.00,$29.95,1,American Express - 1004,,,Redacto@gmail.com,04/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA011075912201),$29.95,$0.00,$29.95,,,,Redact,USD,
04/16/20,112-9379892-4678633,"blue page Morocco Tufted Boho Throw Pillow Cover, Tassel Design, Soft Fabric, Decorative Small Rectangular Lumbar Pillow Cover for Bedroom, Living Room (12 x 20 Inches, Stripe)",DECORATIVE_PILLOW_COVER,B07X1J33B5,52121512,Amazon.com,,new,blue page,,$0.00,$16.99,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/16/20,112-9379892-4678633,"Two Queens Lane Vegan Faux Leather Throw Pillow Covers ONLY for Couch, Sofa, or Bed, 18 x 18""",DECORATIVE_PILLOW_COVER,B07XC65GMG,52121512,Amazon.com,,new,Lane Retail Group,,$0.00,$21.95,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/16/20,112-9379892-4678633,"18x18 Pillow Cover Morocco Throw Pillow Cover for Couch Bed, Boho Tufted Square Decorative Pillow Cover, Farmhouse Accent Textured Throw Pillow Case",DECORATIVE_PILLOW_COVER,B07V3N6VQK,52121512,Amazon.com,,new,blue page,,$0.00,$16.99,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/16/20,112-9379892-4678633,"18x18 Pillow Cover Morocco Throw Pillow Cover for Couch Bed, Boho Tufted Square Decorative Pillow Cover, Farmhouse Neutral Throw Pillow Case with Tassels, Black Off White",DECORATIVE_PILLOW_COVER,B07V2J6C26,52121512,Amazon.com,,new,blue page,,$0.00,$16.99,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/16/20,112-9379892-4678633,"Inspired Ivory Decorative Throw Pillow Covers - 18 X 18, White, Textured - Sham Pillow Cases for Boho, Shabby Chic, Farmhouse Home Decor - Pearl Set",DECORATIVE_PILLOW_COVER,B07ZQK2G4R,52121512,Amazon.com,,new,Inspired Ivory,,$49.99,$27.97,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/16/20,112-9379892-4678633,"Christopher Knight Home Elam Wood Coffee Table, Walnut",TABLE,B01N9T4K6I,56101500,Amazon.com,,new,Amazon.com,,$0.00,$62.26,1,American Express - 2008,,,Redacto@gmail.com,04/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1ZY8X0800316149571),$62.26,$4.36,$66.62,FALSE,,FALSE,Redact,USD,
04/18/20,112-9290404-7347444,Homall 3 Pieces Patio Chaise Lounge Chair Sets Outdoor Beach Pool PE Rattan Reclining Chair with Folding Table and Cushion (Beige),CHAIR,B07F68PHCQ,56101600,Amazon.com,,new,Homall Direct,,$0.00,$139.99,1,American Express - 2008,,,Redacto@gmail.com,04/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,Fedex(392020162675),$139.99,$0.00,$139.99,,,,Redact,USD,
04/18/20,112-4945337-6993865,"Boho Pillow Covers Set of 4 Throw Pillow Covers for Sofa, Bed or Bench 100% Cotton and Faux Leather Modern Decor Pillow Cases - Aria Set 18 x 18",PILLOWCASE,B07Z46ZN5V,52121512,Amazon.com,,new,Texture and Ink,,$0.00,$39.95,1,American Express - 2008,,,Redacto@gmail.com,04/20/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA013785483101),$39.95,$0.00,$39.95,,,,Redact,USD,
04/18/20,112-5859361-4179430,"EDOW Throw Pillow Inserts, Set of 2 Lightweight Down Alternative Polyester Pillow, Couch Cushion, Sham Stuffer, Machine Washable. (White, 18x18)",PILLOW,B07VDKHLJQ,52121505,Amazon.com,,new,edow,,$0.00,$14.99,2,American Express - 2008,,,Redacto@gmail.com,04/29/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA020612520401),$29.98,$0.00,$29.98,,,,Redact,USD,
04/18/20,112-2128585-8767428,"Wooden Beard Comb & Case, Dual Action Fine & Coarse Teeth, Perfect for use with Balms and Oils, Top Pocket Comb for Beards & Mustaches by Viking Revolution",HAIR_COMB,B06W9MZYFB,53131600,Amazon.com,,new,Viking Revolution,,$12.32,$7.88,1,American Express - 1004,,,Redacto@gmail.com,04/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA215546834000),$7.88,$0.55,$8.43,,,,Redact,USD,
04/19/20,111-7786749-3701049,,,B084GNSH83,,Amazon.com,,new,ZooZooZ,,$0.00,$39.99,1,American Express - 2008,,,Redacto@gmail.com,04/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,SF Express(SF6143131481521),$39.99,$0.00,$39.99,,,,Redact,USD,
04/20/20,111-2350365-8650661,"The Rural Diaries: Love, Livestock, and Big Life Lessons Down on Mischief Farm",ABIS_BOOK,62862758,55101500,Amazon.com,2020-05-05 0:00:01,new,Amazon.com,,$26.99,$18.89,1,American Express - 1004,,,Redacto@gmail.com,05/04/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9341989677090082845881),$18.89,$1.32,$20.21,,,,Redact,USD,
04/21/20,112-2120642-1662601,"U-MAX 7 Piece Outdoor Patio Furniture Set, PE Rattan Wicker Sofa Set, Outdoor Sectional Furniture Chair Set with Khaki Cushions and Tea Table, Brown",FURNITURE,B07ZFBMM45,56101600,Amazon.com,,new,AECOJOY-US,,$599.98,$615.98,1,American Express - 2008,,,Redacto@gmail.com,04/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(392115451408),$615.98,$0.00,$615.98,,,,Redact,USD,
04/21/20,112-9934677-0305014,"Wisteria Lane Outdoor Furniture 5-Piece Wicker Patio Dining Table and Chair Set,Square Tempered Glass Table Top with Umbrella Hole for Backyard,Brown",FURNITURE,B0821LZ7HZ,56000000,Amazon.com,,new,WIN-WIN US,,$369.99,$329.99,1,American Express - 2008,,,Redacto@gmail.com,05/04/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,fedex(392484552368),$329.99,$0.00,$329.99,,,,Redact,USD,
04/22/20,112-4981322-8499413,"Umbra Hub Frame, Wall Mirror for Entryways, Bathrooms, Living Rooms and More, 34-Inch, Brass",HOME_MIRROR,B07NPKZ2RK,56101544,Amazon.com,,new,Amazon.com,,$120.00,$104.99,1,American Express - 2008,,,Redacto@gmail.com,04/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090445301547),$104.99,$7.35,$112.34,,,,Redact,USD,
04/22/20,112-4981322-8499413,"VASAGLE Coat Rack Freestanding, Coat Hanger Stand, Hall Tree with 2 Shelves, for Clothes, Hat, Bag, Industrial Style, Rustic Brown and Black URCR16BX",CLOTHES_RACK,B07CPKCKYS,56101500,Amazon.com,,new,Songmics Direct,,$0.00,$36.99,0,American Express - 2008,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/23/20,112-1742943-5213027,"CROSOFMI Artificial Areca Palm Plant 6.2Feet Fake Tropical Palm Tree, Perfect Faux Dypsis Lutescens Plants in Pot for Indoor Outdoor House Home Office Garden Modern Decoration Housewarming Gift",ARTIFICIAL_TREE,B081MV9KRC,56101528,Amazon.com,,new,CROSOFMI,,$0.00,$151.99,1,American Express - 2008,,,Redacto@gmail.com,04/30/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1ZA602A80311577929),$151.99,$0.00,$151.99,,,,Redact,USD,
04/23/20,112-0303956-9714625,"Decoration Throw Pillow Covers Boho Woven Tufted Gray Stripe Pillowcase Elegant Pillow Sham Cushion Case for Couch, 18 x 18 Inch",DECORATIVE_PILLOW_COVER,B07XF24S9V,52121512,Amazon.com,,new,Shamrockers,,$0.00,$17.99,1,American Express - 1004,,,Redacto@gmail.com,04/29/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA020612520401),$17.99,$0.00,$17.99,,,,Redact,USD,
04/23/20,112-4574246-9768240,"Snugtown Decorative Faux Leather Brown Throw Pillow Cover, Tan Pillow Case for Couch, Sofa, Bed (Brown and Flat, 18x18 Inches)",DECORATIVE_PILLOW_COVER,B07YQ4ZVFD,52121512,Amazon.com,,new,Snugtown,,$15.99,$13.99,1,American Express - 1004,,,Redacto@gmail.com,04/30/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA020818587401),$13.99,$0.00,$13.99,,,,Redact,USD,
04/23/20,112-5479677-1590639,"Amazon Brand – Rivet Surrey Modern Ceramic Planter Pot with Wood Plant Stand, 13.6""H, White",PLANTER,B07JM6HFW3,56101600,Amazon.com,,new,Amazon.com,,$68.49,$68.49,2,American Express - 2008,,,Redacto@gmail.com,04/24/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA016287804601),$136.98,$9.58,$146.56,,,,Redact,USD,
04/23/20,112-3827228-7513806,The Code. the Evaluation. the Protocols: Striving to Become an Eminently Qualified Human,ABIS_BOOK,981618820,55101500,Amazon.com,2020-04-17 0:00:01,new,Amazon.com,,$10.99,$10.99,3,American Express - 1004,,,Redacto@gmail.com,04/29/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA020306207301),$32.97,$2.31,$35.28,,,,Redact,USD,
04/24/20,112-7991729-8710644,How to Win Friends & Influence People,ABIS_BOOK,671027034,55101500,Amazon.com,1998-10-01 0:00:01,new,Amazon.com,,$16.99,$12.89,1,American Express - 1004,,,Redacto@gmail.com,04/30/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA020818587401),$12.89,$0.90,$13.79,,,,Redact,USD,
04/25/20,112-7709457-4150626,"CELSIUS Essential Energy Drink 12 Fl Oz, Sparkling Wild Berry (Pack of 24)",DRINK_FLAVORED,B001534QYW,50202309,Amazon.com,,new,Amazon.com,,$0.00,$42.39,1,American Express - 1004,,,Redacto@gmail.com,04/25/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA017763951601),$42.39,$0.00,$42.39,,,,Redact,USD,
05/03/20,111-8248111-9616240,"Walker Edison 6 Person Mid Century Modern Wood Hairpin Rectangle Dining Room Kitchen Table Set Dining Chairs, 60 Inch, Brown",TABLE,B01IEIX8IU,56101543,Amazon.com,,new,Amazon.com,,$698.99,$223.20,1,American Express - 2008,,,Redacto@gmail.com,05/04/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA220074793000),$223.20,$15.62,$238.82,,,,Redact,USD,
05/03/20,111-8248111-9616240,"Dining Chairs Set of 4 Kitchen Dining Room Chairs Accent Chairs for Living Room Bedroom Mid Century Modern Upholstered Side Chair with Fabric Cushion Seat Back Brown Metal Legs, Grey",CHAIR,B06XGD4S54,56000000,Amazon.com,,new,DHHX Co（ Best Gift Shop ),,$0.00,$199.92,1,American Express - 2008,,,Redacto@gmail.com,05/07/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z9X27A30305730473),$199.92,$0.00,$199.92,,,,Redact,USD,
05/04/20,111-7053171-5852249,,,B07SW7GTKB,,Amazon.com,,new,XUSHI,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,05/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA025177645101),$9.99,$0.00,$9.99,,,,Redact,USD,
05/05/20,112-3271730-9299428,The Self-Sufficient Life and How to Live It: The Complete Back-to-Basics Guide,ABIS_BOOK,1465477357,55101500,Amazon.com,2018-09-04 0:00:01,new,Amazon.com,,$35.00,$21.69,1,American Express - 1004,,,Redacto@gmail.com,05/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028429951601),$21.69,$1.52,$23.21,,,,Redact,USD,
05/06/20,112-3803525-8666633,"Scotts Wizz Spreader - Handheld Power Spreader, Use Year-Round, Covers Up to 2,500 sq. ft.",OUTDOOR_LIVING,B011HY5Q1K,21101804,Amazon.com,,new,Amazon.com,,$11.28,$20.58,1,American Express - 1004,,,Redacto@gmail.com,05/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028638361501),$20.58,$1.44,$22.02,,,,Redact,USD,
05/06/20,112-3803525-8666633,"Scotts Turf Builder Grass Seed Zoysia Grass Seed and Mulch, 5 lb. - Full Sun and Light Shade - Thrives in Heat & Drought - Grows a Tough, Durable, Low-Maintenance Lawn - Seeds up to 2,000 sq. ft.",PLANT_SEED,B00ARYRY1W,10151900,Amazon.com,,new,Amazon.com,,$42.27,$42.27,2,American Express - 1004,,,Redacto@gmail.com,05/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028429951601),$84.54,$5.92,$90.46,,,,Redact,USD,
05/08/20,112-1837388-6801825,Discipline Equals Freedom: Field Manual,ABIS_BOOK,1250156947,55101500,Amazon.com,2017-10-17 0:00:01,new,Amazon.com,,$25.99,$13.72,1,American Express - 1004,,,Redacto@gmail.com,05/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028429951601),$13.72,$0.96,$14.68,,,,Redact,USD,
05/10/20,111-6698139-9086653,Brazilian Blowout | Anti-Frizz Shampoo/Conditioner 2 Pack,HAIR_CLEANER_CONDITIONER,B07KS7RV5Z,53131600,Amazon.com,,new,Amazon.com,,$49.00,$49.00,1,American Express - 2008,,,Redacto@gmail.com,05/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA221926210000),$49.00,$3.43,$52.43,,,,Redact,USD,
05/10/20,111-6698139-9086653,TIGI Catwalk Curls Rock Amplifier 5.07 oz (Pack of 2),HAIR_STYLING_AGENT,B00JIRZJMU,53131602,Amazon.com,,new,LY Berditchev Co.,,$0.00,$15.49,1,American Express - 2008,,,Redacto@gmail.com,05/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA221926210000),$15.49,$1.08,$16.57,,,,Redact,USD,
05/10/20,111-6698139-9086653,"Proactiv Acne Cleanser - Benzoyl Peroxide Face Wash and Acne Treatment - Daily Facial Cleanser and Hyularonic Acid Moisturizer with Exfoliating Beads - 90 Day Supply, 6 Oz",SKIN_CLEANING_AGENT,B004DOYM2Q,53131613,Amazon.com,,new,Amazon.com,,$34.95,$30.74,1,American Express - 2008,,,Redacto@gmail.com,05/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA221926210000),$30.74,$0.00,$30.74,,,,Redact,USD,
05/10/20,111-6698139-9086653,"Locisne 40pcs No Heat Curlers You Can Sleep in, Hair Rollers for Long Hair DIY(5 Colors)",HAIR_ROLLER,B07PB1S3VX,53131600,Amazon.com,,new,Locisne,,$0.00,$19.99,1,American Express - 2008,,,Redacto@gmail.com,05/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA221926210000),$19.99,$0.00,$19.99,,,,Redact,USD,
05/13/20,112-5720362-4263454,"Anthony Exfoliating Body Scrub – Sea Salt, Vitamin C, And Aloe Vera Deep Cleans, Smooths Rough Patches & Soothes and Protects Skin – Blue Sea Kelp Body Wash 12 Fl Oz",SKIN_EXFOLIANT,B00J4OTK9A,53131600,Amazon.com,,new,FGP,,$28.00,$27.95,1,American Express - 1004,,,Redacto@gmail.com,05/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA032793334601),$27.95,$1.96,$29.91,,,,Redact,USD,
05/19/20,112-1043047-1375437,"RUFFWEAR, Front Range Dog Harness, Reflective and Adjustable Soft Padded Harness Vest for Outdoor Training and Everyday, No Pull Pet Harness with 2 Leash Clips",ANIMAL_HARNESS,B082FQ7FQG,10141600,Amazon.com,,new,NetRush,,$39.95,$39.95,1,American Express - 1004,,,Redacto@gmail.com,05/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA040648814201),$39.95,$2.80,$42.75,,,,Redact,USD,
05/20/20,112-7595851-9784244,Coolaroo The Original Cooling Elevated Pet Bed,PET_BED_MAT,B001HX4294,10131508,Amazon.com,,new,Amazon.com,,$43.15,$26.29,1,American Express - 1004,,,Redacto@gmail.com,05/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA040610384301),$26.29,$1.84,$28.13,,,,Redact,USD,
05/22/20,111-6587144-4406633,"Goldenwarm 10 Pack Black Square Bar Cabinet Pull Drawer Handle Stainless Steel Modern Hardware for Kitchen Bathroom Cabinets Cupboard,Center to Center 3in (76mm) Modern Matte Black Drawer Pulls",HARDWARE_HANDLE,B071S8SNJ8,31162801,Amazon.com,,new,GoldenwarmDirect,,$0.00,$26.39,1,American Express - 1004,,,Redacto@gmail.com,05/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA041380487501),$26.39,$0.00,$26.39,,,,Redact,USD,
05/22/20,111-6587144-4406633,"Goldenwarm 10 Pack Black Square Bar Cabinet Pull Drawer Handle Stainless Steel Modern Hardware for Kitchen and Bathroom Cabinets Cupboard,Center to Center 4in(102mm) Cabinet Handles",HARDWARE_HANDLE,B07FN288DS,31162801,Amazon.com,,new,GoldenwarmDirect,,$0.00,$28.99,4,American Express - 1004,,,Redacto@gmail.com,05/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA041509893601),$115.96,$0.00,$115.96,,,,Redact,USD,
05/25/20,114-1545545-9027408,"VHC Brands 6172 Burlap Natural Shower Curtain Unlined 72x72, 72 x 72, Tan",CURTAIN,B00B8WXG7S,30181607,Amazon.com,,new,VHC Brands,,$39.55,$38.95,1,American Express - 1004,,,Redacto@gmail.com,05/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx SmartPost(61297938200731255056),$38.95,$0.00,$38.95,,,,Redact,USD,
05/25/20,114-1966419-4818628,"10Pack Black Square Bar Cabinet Pull Drawer Handle Goldenwarm Stainless Steel Modern Hardware for Kitchen and Bathroom Cabinets Cupboards,Center to Center 3-3/4in (96mm) Kitchen Pulls for Cabinets",HARDWARE_HANDLE,B01N1Q30LQ,31162801,Amazon.com,,new,GoldenwarmDirect,,$0.00,$29.99,3,American Express - 1004,,,Redacto@gmail.com,05/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA045022886601),$89.97,$0.00,$89.97,,,,Redact,USD,
05/25/20,114-1966419-4818628,"Nuvo Lighting SF77/140 Utility Fixture Die Cast Aluminum Durable Outdoor Wall Mount Porch and Patio Light with White Glass Globe, Black",LIGHT_FIXTURE,B002OMEH6K,39111600,Amazon.com,,new,Amazon.com,,$23.99,$20.99,1,American Express - 1004,,,Redacto@gmail.com,05/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA044783282101),$20.99,$1.47,$22.46,,,,Redact,USD,
05/28/20,112-0039345-2608246,Nero Ball Ultra TM - Dog Training Ball On A Rope - Exercise and Reward Toy for Dogs,PET_TOY,B01FD60BBI,10111300,Amazon.com,,new,Nero Ball,,$21.99,$15.95,1,American Express - 1004,,,Redacto@gmail.com,05/28/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA047073304301),$15.95,$0.00,$15.95,,,,Redact,USD,
05/28/20,112-0039345-2608246,"KONG - Extreme Ball - Durable Rubber Dog Toy for Power Chewers, Black - for Medium/Large Dogs",PET_TOY,B004VBC0SY,10111300,Amazon.com,,new,The Barker Company,,$14.49,$13.99,1,American Express - 1004,,,Redacto@gmail.com,05/28/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA047073304301),$13.99,$0.00,$13.99,,,,Redact,USD,
05/28/20,112-7186247-0273844,"Outward Hound Fun Feeder Slo Bowl, Slow Feeder Dog Bowl, Large/Regular, Purple",PET_FEEDER,B00FPKNRG4,10111300,Amazon.com,,new,Amazon.com,,$19.99,$9.86,1,American Express - 1004,,,Redacto@gmail.com,05/28/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA047073304301),$9.86,$0.69,$10.55,,,,Redact,USD,
05/28/20,112-7186247-0273844,"Rubbermaid Commercial Products Bouncer Measuring Cup, 1-Cup, Clear, FG321000CLR",MEASURING_CUP,B000JCCW4G,52151600,Amazon.com,,new,Amazon.com,,$9.82,$6.00,1,American Express - 1004,,,Redacto@gmail.com,05/28/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA047073304301),$6.00,$0.42,$6.42,,,,Redact,USD,
05/29/20,112-4547327-9951405,Nero Ball Ultra TM - Dog Training Ball On A Rope - Exercise and Reward Toy for Dogs,PET_TOY,B01FD60BBI,10111300,Amazon.com,,new,Nero Ball,,$21.99,$15.95,1,American Express - 1004,,,Redacto@gmail.com,05/30/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA048692231101),$15.95,$0.00,$15.95,,,,Redact,USD,
05/30/20,112-5720027-9608250,"Bissell 3624 Spot Clean Professional Portable Carpet Cleaner - Corded , Black",CARPET_UPHOLSTERY_CLEANING_MACHINE,B008DBRFBK,47121600,Amazon.com,,new,Amazon.com,,$159.99,$159.99,1,MasterCard - 7372,,,Redacto@gmail.com,06/01/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z9X26Y90310927999),$159.99,$11.20,$171.19,,,,Redact,USD,
05/30/20,112-4664604-4419418,Roots: The Saga of an American Family (Modern Classics),ABIS_BOOK,517208601,55101500,Amazon.com,2000-09-05 0:00:01,used good,"GF Books, Inc.",,$12.99,$15.68,1,American Express - 1004,,,Redacto@gmail.com,06/02/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9449011899563495166441),$15.68,$0.00,$15.68,,,,Redact,USD,
05/31/20,112-9122428-9242655,"iRobot Authentic Replacement Parts- Clean Base Automatic Dirt Disposal Bags, 3-Pack, Compatible with All Clean Base models, White - 4640235",VACUUM_CLEANER_BAG,B07HJS45XQ,47121607,Amazon.com,,new,Amazon.com,,$16.99,$15.00,1,American Express - 1004,,,Redacto@gmail.com,06/01/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889677090469939920),$15.00,$1.05,$16.05,,,,Redact,USD,
06/02/20,112-0244629-6550668,White Fragility: Why It's So Hard for White People to Talk About Racism,ABIS_BOOK,807047414,55101500,Amazon.com,2018-06-26 0:00:01,new,Amazon.com,,$16.00,$10.98,1,American Express - 1004,,,Redacto@gmail.com,06/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9341989677090084493158),$10.98,$0.77,$11.75,,,,Redact,USD,
06/02/20,112-4696190-0893812,The New Jim Crow: Mass Incarceration in the Age of Colorblindness,ABIS_BOOK,1595586431,55101500,Amazon.com,2012-01-16 0:00:01,new,Amazon.com,,$19.95,$14.71,1,American Express - 1004,,,Redacto@gmail.com,06/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9241990990092021333720),$14.71,$1.03,$15.74,,,,Redact,USD,
06/02/20,112-3907028-7888207,The Mis-Education of the Negro,ABIS_BOOK,1680920677,55101500,Amazon.com,2017-06-05 0:00:01,new,Amazon.com,,$9.99,$9.99,1,American Express - 1004,,,Redacto@gmail.com,06/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9241990990092021468231),$9.99,$0.70,$10.69,,,,Redact,USD,
06/03/20,112-3611532-3991455,Simple Solution Hard Floor Pet Stain and Odor Remover | Dual Action Cleaner for Sealed Hardwood Floors | 32 Ounces,PET_SUPPLIES,B000TZ1X4S,10111303,Amazon.com,,new,Amazon.com,,$11.99,$8.99,1,American Express - 1004,,,Redacto@gmail.com,06/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090460481200),$8.99,$0.63,$9.62,,,,Redact,USD,
06/03/20,112-4662234-2842666,"Dymatize ISO 100 Whey Protein Powder with 25g of Hydrolyzed 100% Whey Isolate, Gluten Free, Fast Digesting, Cinnamon Bun, 25.6 Ounce",PROTEIN_SUPPLEMENT_POWDER,B00TE9AB3O,50500000,Amazon.com,,new,Amazon.com,,$53.94,$29.99,1,American Express - 1004,,,Redacto@gmail.com,06/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960303049037),$29.99,$0.00,$29.99,,,,Redact,USD,
06/11/20,112-0683107-4359421,"Scotts Turf Builder Grass Seed Zoysia Grass Seed and Mulch, 5 lb. - Full Sun and Light Shade - Thrives in Heat & Drought - Grows a Tough, Durable, Low-Maintenance Lawn - Seeds up to 2,000 sq. ft.",PLANT_SEED,B00ARYRY1W,10151900,Amazon.com,,new,Amazon.com,,$42.27,$42.27,1,American Express - 1004,,,Redacto@gmail.com,06/12/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA060616568001),$42.27,$2.96,$45.23,,,,Redact,USD,
06/13/20,112-5236310-1923447,"Mofason Pet Food Mat Waterproof Dog Mat 24”x16” Large – 0.5” inch Raised Edge, Dog Cat Silicone Feeding Placemat Water Bowl Tray for Floors, Nonslip Washable Dog Mat for Food and Water",PET_PLACEMAT,B07559TH1G,10110000,Amazon.com,,new,Mofason,,$29.99,$16.99,1,American Express - 1004,,,Redacto@gmail.com,06/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA061751036801),$16.99,$0.00,$16.99,,,,Redact,USD,
06/13/20,112-5314704-3021838,"Regalo Easy Step Extra Tall Walk Thru Baby Gate, Bonus Kit, Includes 4-Inch Extension Kit, 4 Pack of Pressure Mount Kit and 4 Pack of Wall Cups and Mounting Kit, Black",TEMPORARY_GATE,B001OE1PC8,56121605,Amazon.com,,new,Amazon.com,,$46.99,$44.99,2,American Express - 1004,,,Redacto@gmail.com,06/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z9728Y91316066445),$89.98,$6.30,$96.28,,,,Redact,USD,
06/13/20,112-5314704-3021838,Kassa Large Chalkboard Contact Paper Roll - 17.3” x 96” (8 Feet) - 5 Chalks Included - Chalk Board Paint Alternative Wallpaper - Adhesive Blackboard Wall Decal Vinyl - Black Chalkboard Stickers,WRITING_BOARD,B00LJ4I274,44111900,Amazon.com,,new,Kassa Inc,,$0.00,$12.95,1,American Express - 1004,,,Redacto@gmail.com,06/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090463787972),$12.95,$0.00,$12.95,,,,Redact,USD,
06/13/20,112-5314704-3021838,"KNOBWELL 6 Pack Black Stainless Steel Drawer Knobs and Pulls, Matte Black Cabinet Knobs, Dresser Drawer Knobs for Kitchen and Bathroom - Single Hole 50mm/2""",HARDWARE_HANDLE,B079RWMYFD,31162801,Amazon.com,,new,KNOBWELL,,$0.00,$13.99,1,American Express - 1004,,,Redacto@gmail.com,06/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA062705401001),$13.99,$0.00,$13.99,,,,Redact,USD,
06/13/20,112-5314704-3021838,"KNOBWELL 6 Pack Black Stainless Steel Cabinet Pulls, Kitchen Cabinet Pulls and Handles, Dresser Drawer Pulls, Hole Centers 2-1/2 inch, Overall Length 2-9/10 inch",HARDWARE_HANDLE,B07FDT1V2Z,31162801,Amazon.com,,new,KNOBWELL,,$0.00,$12.99,1,American Express - 1004,,,Redacto@gmail.com,06/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA062058440001),$12.99,$0.00,$12.99,,,,Redact,USD,
06/13/20,112-5314704-3021838,"10Pack Black Square Bar Cabinet Pull Drawer Handle Goldenwarm Stainless Steel Modern Hardware for Kitchen and Bathroom Cabinets Cupboards,Center to Center 3-3/4in (96mm) Kitchen Pulls for Cabinets",HARDWARE_HANDLE,B01N1Q30LQ,31162801,Amazon.com,,new,GoldenwarmDirect,,$0.00,$29.99,1,American Express - 1004,,,Redacto@gmail.com,06/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA062058440001),$29.99,$0.00,$29.99,,,,Redact,USD,
06/14/20,112-5240584-1017004,"AMERICAN PLANT EXCHANGE English Ivy Baltic Trailing Vine Live Plant, 6"" Pot, Indoor/Outdoor Air Purifier",PLANT_SEED,B07NW9R5GG,10160000,Amazon.com,,new,Amazon.com,,$19.99,$19.99,2,American Express - 1004,,,Redacto@gmail.com,06/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,"UPS(1Z69662Y0322289685),UPS(1Z69662Y0330091891)",$39.98,$2.80,$42.78,,,,Redact,USD,
06/14/20,114-1534772-4805830,"Zoysia Plugs - 100 Large Plugs, Drought Tolerant, Low Maintenance",PLANT_SEED,B079LV8JJ8,52150000,Amazon.com,,new,Central Turf and Lawn,,$0.00,$79.96,1,American Express - 1004,,,Redacto@gmail.com,06/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(393849724575),$79.96,$0.00,$79.96,,,,Redact,USD,
06/14/20,114-7564297-2101868,"Corona LG 3720 SodPLUGGER, Red",OUTDOOR_LIVING,B07GX83378,56101600,Amazon.com,,new,Amazon.com,,$29.65,$24.98,1,American Express - 1004,,,Redacto@gmail.com,06/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090464202375),$24.98,$1.75,$26.73,,,,Redact,USD,
06/14/20,114-4578360-2965018,"Yard Butler ID-6C Manual Lawn Coring Aerator - Grass Dethatching Turf Plug Core Aeration Tool - Grass Aerators for Small Yards - Loosen Compacted Soil - Gardening Hand Tools - Gray, 37 Inches",OUTDOOR_LIVING,B00EOMCJD6,55101500,Amazon.com,,new,Amazon.com,,$0.00,$37.38,1,American Express - 1004,,,Redacto@gmail.com,06/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z83WV060324894197),$37.38,$2.62,$40.00,,,,Redact,USD,
06/15/20,114-9135266-7705845,Starmark Swing 'n Fling DuraFoam Ball Dog Toy Large,PET_TOY,B003YHB8E8,10111300,Amazon.com,,new,Amazon.com,,$14.99,$9.89,3,American Express - 1004,,,Redacto@gmail.com,06/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA064091213101),$29.67,$2.07,$31.74,,,,Redact,USD,
06/16/20,112-5347037-7777857,"Doggie Dooley ""The Original In-Ground Dog Waste Disposal System, Black with Green Lid (3800X)",PET_SUPPLIES,B00WMMMIX6,47121700,Amazon.com,,new,Amazon.com,,$59.99,$37.39,1,American Express - 1004,,,Redacto@gmail.com,06/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA064560706101),$37.39,$2.62,$40.01,,,,Redact,USD,
06/18/20,112-2911076-5766625,Mayflower Electronics Desktop Objective2 ODAC Rev B.,HEADPHONES,B00TQ2JDAG,43191609,Amazon.com,,new,MayflowerElectronicsAudio,,$299.99,$259.00,1,American Express - 1004,,,Redacto@gmail.com,06/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA068966931401),$259.00,$0.00,$259.00,,,,Redact,USD,
06/18/20,112-1335156-6020211,Heil Sound PL-2T Overhead Broadcast Boom,MICROPHONE_STAND,B000SZVZ74,52161600,Amazon.com,,new,AVLGear,,$141.70,$149.00,1,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(92055901755477000193179512),$149.00,$0.00,$149.00,,,,Redact,USD,
06/18/20,112-5980163-5623433,"Mogami Gold STUDIO-10 XLR Microphone Cable, XLR-Female to XLR-Male, 3-Pin, Gold Contacts, Straight Connectors, 10 Foot",ELECTRONIC_CABLE,B00JHHZ1UQ,26121600,Amazon.com,,new,DJ Band Supply,,$55.95,$55.95,1,American Express - 1004,,,Redacto@gmail.com,06/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z62WF001309100042),$55.95,$0.00,$55.95,,,,Redact,USD,
06/18/20,112-5980163-5623433,Rode AI-1 USB Audio Interface,AUDIO_MIDI_INTERFACE,B077Y531H1,52161500,Amazon.com,,new,Amazon.com,,$129.00,$129.00,1,American Express - 1004,,,Redacto@gmail.com,06/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z62WF001309100042),$129.00,$9.03,$138.03,,,,Redact,USD,
06/18/20,112-5980163-5623433,"Shure SM7B Vocal Dynamic Microphone for Broadcast, Podcast & Recording, XLR Studio Mic for Music & Speech, Wide-Range Frequency, Warm & Smooth Sound, Rugged Construction, Detachable Windscreen - Black",MICROPHONE,B0002E4Z8M,52161520,Amazon.com,,new,Amazon.com,,$499.00,$399.00,1,American Express - 1004,,,Redacto@gmail.com,06/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA070542579401),$399.00,$27.93,$426.93,,,,Redact,USD,
06/18/20,114-0096613-3785044,Siless 24 Pack Acoustic Panels 1 X 12 X 12 Inches - Acoustic Foam - Studio Foam Wedges - High Density Panels - Soundproof Wedges - Charcoal,SOUND_AND_RECORDING_EQUIPMENT,B082QK7B8N,52160000,Amazon.com,,new,Siless,,$0.00,$21.95,2,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9305520111404937316648),$43.90,$0.00,$43.90,,,,Redact,USD,
06/18/20,114-5940510-2576242,"Command Poster Hanging Strips Value Pack, Small, White, 60-Strips, Decorate Damage-Free",ADHESIVE_TAPES,B00BT0MW4W,60121400,Amazon.com,,new,Amazon.com,,$8.09,$7.88,1,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA067273691501),$7.88,$0.55,$8.43,,,,Redact,USD,
06/19/20,112-6910151-2671421,"Resolve Ultra Pet Stain & Odor Remover Spray, 32oz",HEALTH_PERSONAL_CARE,B07FKKP72W,10111300,Amazon.com,,new,Amazon.com,,$10.00,$7.97,1,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA067124032801),$7.97,$0.56,$8.53,,,,Redact,USD,
06/19/20,112-8678987-6268230,,,B00SG6DWQE,,Amazon.com,,new,GoGo Pet Products,,$5.99,$5.99,1,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(420333129400110200882294368171),$5.99,$0.00,$5.99,,,,Redact,USD,
06/19/20,112-4990835-9554657,"Monoprice Medium Multimedia Desktop Stand, Black Glass (27.6"" x 12.2"" x 3"") - Stand & Riser, Laptop Stand, Desktop TV Stand, Dual Monitors w/ Height Adjustable Legs - Workstream Collection (116360)",TABLE,B01MA61R25,56101500,Amazon.com,,new,Amazon.com,,$27.49,$25.79,1,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA067392373901),$25.79,$1.81,$27.60,,,,Redact,USD,
06/19/20,112-1819168-6652201,"[4 Pack] JOTO Cable Management Sleeve, 19-20 Inch Cord Organizer System with Zipper for TV Computer Office Home Entertainment, Flexible Cable Sleeve Wrap Cover Wire Hider System -Black",CORD_MANAGEMENT_COVER,B015HWXG4M,43211619,Amazon.com,,new,Tech Vendor,,$15.99,$15.99,1,American Express - 1004,,,Redacto@gmail.com,06/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA067392373901),$15.99,$0.00,$15.99,,,,Redact,USD,
06/20/20,112-1899667-9239434,"Wireless Charger, Fast Wireless Charging Stand, 2-in-1 Wireless Charging Station Dock for iPhone 13/12/11/Pro/Max/X/Xs/XR/AirPods2, Samsung Galaxy Note/Watch/Buds",CHARGING_ADAPTER,B07YGTN6W1,26111704,Amazon.com,,new,Chantie,,$49.99,$29.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/24/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z9Y97E31395462135),$29.99,$0.00,$29.99,,,,Redact,USD,
06/20/20,112-1899667-9239434,"Kanto H1 Universal Headphone Stand with Curved Silicone Padding, Black",PORTABLE_ELECTRONIC_DEVICE_STAND,B07XF1VC91,52161500,Amazon.com,2020-01-31 0:00,new,Kanto Living,,$34.99,$31.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA068464615001),$31.99,$0.00,$31.99,,,,Redact,USD,
06/20/20,112-1899667-9239434,HUANUO Dual Monitor Stand - Vertical Stack Screen Free-Standing Holder LCD Desk Mount Fits Two 13 to 32 Inch Computer Monitors with C Clamp Grommet Base,ACCESSORY_OR_PART_OR_SUPPLY,B07T7TSG4L,43212002,Amazon.com,,new,Dream Fit 2020,,$0.00,$49.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA068966931401),$49.99,$0.00,$49.99,,,,Redact,USD,
06/20/20,112-1899667-9239434,"Cable Matters 4K DisplayPort to DisplayPort Cable (DP to DP Cable, Display Port Cable) 10 Feet - 4K 60Hz, 2K 144Hz Monitor Support",ELECTRONIC_CABLE,B00TTQ306M,43202222,Amazon.com,,new,Cable Matters,,$21.99,$11.99,3,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA068464615001),$35.97,$2.52,$38.49,,,,Redact,USD,
06/20/20,112-1899667-9239434,"Acer KG271U bmiippx 27"" WQHD (2560 x 1440) TN Gaming Monitor with AMD FREESYNC Technology (2 x Display & 2 x HDMI Ports) black",MONITOR,B079J4F4ZS,43211900,Amazon.com,,new,Amazon.com,,$249.99,$249.99,2,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA068418231401),$499.98,$35.00,$534.98,,,,Redact,USD,
06/21/20,112-6848866-6845861,"Bissell Professional Pet Urine Eliminator + Oxy Carpet Cleaning Formula, 48 oz, 1990, 48 Ounce, 48 Ounce",CLEANING_AGENT,B06XKKFK35,47131800,Amazon.com,,new,Amazon.com,,$21.99,$21.99,1,American Express - 1004,,,Redacto@gmail.com,06/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090466250893),$21.99,$1.54,$23.53,,,,Redact,USD,
06/21/20,112-2791512-0320236,"Logitech G840 XL Cloth Gaming Mouse Pad, Thin Pad, Stable Rubber Base, Performance-tuned surface, Moderate surface friction, Durable tube for transport - Black",MOUSE_PAD,B0745N6CMD,43211802,Amazon.com,,new,Amazon.com,,$49.99,$49.99,1,American Express - 1004,,,Redacto@gmail.com,06/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA070465827801),$49.99,$3.50,$53.49,,,,Redact,USD,
06/23/20,112-6353478-4437019,"iRobot Authentic Replacement Parts- Braava jet m Series Washable Pads Multi-Pack, (1 Wet & 1 Dry Pad),Gray - 4632811",ROBOTIC_VACUUM_CLEANER,B07RD83P4Q,47121602,Amazon.com,,new,E ZEE ELECTRONICS,,$24.99,$24.45,1,American Express - 1004,,,Redacto@gmail.com,06/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(394157909500),$24.45,$0.00,$24.45,,,,Redact,USD,
06/23/20,112-8195997-2333012,"iRobot Authentic Replacement Parts Jet Hard Floor Cleaning Solution, Compatible with All Braava Robot Mop Accessory, Clear",ROBOTIC_VACUUM_CLEANER,B07RD8356H,47121602,Amazon.com,,new,Amazon.com,,$7.99,$7.99,2,American Express - 1004,,,Redacto@gmail.com,06/28/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA075272228301),$15.98,$1.12,$17.10,,,,Redact,USD,
06/25/20,114-0115598-5444247,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$57.99,1,American Express - 1004,,,Redacto@gmail.com,06/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960303493664),$57.99,$4.06,$62.05,,,,Redact,USD,
06/26/20,114-4812234-7617050,20PACK PM 2.5 Activated Carbon Filter Replacement for Breathing Insert Protective Mask Anti Pollution Washable Cotton for Adult Outdoor Activities,SECURITY_ELECTRONICS,B086YS44P1,46182001,Amazon.com,,new,LotsSavings,,$0.00,$11.89,1,American Express - 1004,,,Redacto@gmail.com,06/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9400111899563356676186),$11.89,$0.00,$11.89,,,,Redact,USD,
06/26/20,113-9903203-4993066,CHOICEMMED Sky Blue Finger Pulse Oximeter - Blood Oxygen Saturation Monitor - SPO2 Pulse Oximeter - Portable Oxygen Sensor with Included Batteries - O2 Saturation Monitor with Carry Pouch,BLOOD_OXYGEN_MONITOR,B01L8NQGR8,42181801,Amazon.com,,new,mibest,,$39.99,$24.95,1,American Express - 1004,,,Redacto@gmail.com,06/27/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA234465053000),$24.95,$0.00,$24.95,,,,Redact,USD,
06/28/20,112-1754763-7296268,"Lawry's Seasoned Salt, 40 OZ",CULINARY_SALT,B06XCZ4PTP,50000000,Amazon.com,,new,GPO Corporation,,$0.00,$14.55,1,American Express - 1004,,,Redacto@gmail.com,06/29/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9300120111404975415362),$14.55,$0.00,$14.55,,,,Redact,USD,
06/29/20,114-6614876-9304229,"Kernel Season's Popcorn Seasoning 2 pack (Ranch, 2.4oz)",SEASONING,B012CWSIXO,50000000,Amazon.com,,new,DailyDealsOnline,,$0.00,$8.37,1,American Express - 1004,,,Redacto@gmail.com,06/30/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889677090478869379),$8.37,$0.00,$8.37,,,,Redact,USD,
06/29/20,113-5677525-7447423,"PetSafe CozyUp Folding Dog Stairs - Pet Stairs for Indoor/Outdoor at Home or Travel - Dog Steps for High Beds - Pet Steps with Siderails, Non-Slip Pads - Durable, Support up to 150 lbs - Large, Gray",ANIMAL_STAIR,B084SXF9Y8,10111300,Amazon.com,,new,Amazon.com,,$59.99,$40.45,1,American Express - 1004,,,Redacto@gmail.com,07/01/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA077692271501),$40.45,$2.83,$43.28,,,,Redact,USD,
07/01/20,112-6421662-3513048,"Lumbar Pillow for Sleeping for Bed Sleeping- Soft Memory Foam Sleeping Pillow for Lower Back, Orthopedic Bed Cushion for Side Sleepers, Lumbar Support Cushion for Leg, Knee & Hip, Grey",BODY_POSITIONER,B07C4YHWFS,52121505,Amazon.com,,new,CompuCleverGEAR,,$0.00,$26.99,1,American Express - 1004,,,Redacto@gmail.com,07/01/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960303593136),$26.99,$0.00,$26.99,,,,Redact,USD,
07/02/20,111-4374210-9307438,"Moonlight4225 Black Lives Matter, v1 18x24-inch One Sided Yard Sign (Outdoor, Weatherproof Corrugated Plastic) Metal H-Stake Included",DECORATIVE_SIGNAGE,B089KQKTB5,55121700,Amazon.com,,new,Moonlight Printing,,$39.95,$21.95,1,American Express - 2008,,,Redacto@gmail.com,07/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9405511298370280076575),$21.95,$0.00,$21.95,,,,Redact,USD,
07/03/20,112-8781734-5191436,"Rechargeable Fabric Shaver, Magictec Lint Remover Sweater Defuzzer Lints Fuzzs Pills Pilling Trimmer for Clothes and Furniture -Battery Operated",LINT_REMOVER,B082VQBLL6,43211708,Amazon.com,,new,B-GLAMOROUS,,$16.99,$13.65,1,American Express - 1004,,,Redacto@gmail.com,07/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889677090479951486),$13.65,$0.00,$13.65,,,,Redact,USD,
07/03/20,112-6586039-0935445,"nuLOOM Moroccan Blythe Accent Rug, 2' x 3', Grey/Off-white",RUG,B01HDWIOVY,52101500,Amazon.com,,new,Amazon.com,,$0.00,$13.47,1,American Express - 2008,,,Redacto@gmail.com,07/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z0W37F60314301043),$13.47,$0.94,$14.41,,,,Redact,USD,
07/03/20,112-6586039-0935445,"nuLOOM Moroccan Blythe Runner Rug, 2 ft 8 in x 8 ft, Grey/Off-white",RUG,B01DW94RL8,52101500,Amazon.com,,new,Amazon.com,,$89.00,$33.66,1,American Express - 2008,,,Redacto@gmail.com,07/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z0W37F60314301043),$33.66,$2.36,$36.02,,,,Redact,USD,
07/05/20,112-9837831-8733003,"BlenderBottle Classic Shaker Bottle Perfect for Protein Shakes and Pre Workout, 28-Ounce (2 Pack), Clear/Black",BOTTLE,B06XDCB2DP,24122000,Amazon.com,,new,Amazon.com,,$19.98,$16.02,1,American Express - 1004,,,Redacto@gmail.com,07/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960303679937),$16.02,$1.12,$17.14,,,,Redact,USD,
07/05/20,112-9837831-8733003,"Optimum Nutrition Gold Standard 100% Whey Protein Powder, Naturally Flavored Chocolate, 1.9 Pound (Packaging May Vary)",PROTEIN_SUPPLEMENT_POWDER,B00QQA0MF6,50500000,Amazon.com,,new,Amazon.com,,$51.49,$29.99,1,American Express - 1004,,,Redacto@gmail.com,07/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960303679937),$29.99,$0.00,$29.99,,,,Redact,USD,
07/07/20,112-6356327-5687438,,,B07NLJ3G99,,Amazon.com,,new,LIGHTS ON DISTRIBUTORS,,$0.00,$59.90,1,American Express - 1004,,,Redacto@gmail.com,07/07/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(394594818757),$59.90,$0.00,$59.90,,,,Redact,USD,
07/07/20,112-2808881-1109819,"Mavogel Cotton Sleep Eye Mask - Updated Design Light Blocking Sleep Mask, Soft and Comfortable Night Eye Mask for Men Women, Eye Blinder for Travel/Sleeping/Shift Work, Includes Travel Pouch, Black",SLEEP_MASK,B07D3M6892,53100000,Amazon.com,,new,Mavogel,,$19.99,$9.98,1,American Express - 1004,,,Redacto@gmail.com,07/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z81FY250329781401),$9.98,$0.00,$9.98,,,,Redact,USD,
07/09/20,112-1667888-8412216,"Propel Berry, Zero Calorie Sports Drinking Water with Electrolytes and Vitamins C&E, 16.9 Fl Oz, Pack of 12",WATER,B002U5A8LY,50202309,Amazon.com,,new,Amazon.com,,$11.00,$6.38,2,American Express - 1004,,,Redacto@gmail.com,07/10/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA085064398801),$12.76,$0.90,$13.66,,,,Redact,USD,
07/10/20,111-9510045-0905033,Come as You Are: The Surprising New Science that Will Transform Your Sex Life,ABIS_BOOK,1476762090,55101500,Amazon.com,2015-03-03 0:00:01,new,Amazon.com,,$17.99,$14.75,1,American Express - 2008,,,Redacto@gmail.com,07/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9341989677090085697746),$14.75,$1.03,$15.78,,,,Redact,USD,
07/13/20,112-3714788-5349807,"UBeesize Phone Tripod, 51"" Adjustable Travel Video Tripod Stand with Cell Phone Mount Holder & Smartphone Bluetooth Remote",CAMERA_TRIPOD,B07QJVB3YF,43191600,Amazon.com,,new,YYBS Collections,,$0.00,$26.99,1,American Express - 1004,,,Redacto@gmail.com,07/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA089114207801),$26.99,$0.00,$26.99,,,,Redact,USD,
07/14/20,112-9919660-0603463,"Enzytabs Septic Tank System Treatment, Billions of Enzyme Producing Bacteria Reduce Bad Odors and Help Prevent Backups, 4 Month Supply (4 tablets)",DRAIN_OPENER_SUBSTANCE,B013FL0730,43211509,Amazon.com,,new,Nuogen Retail,,$0.00,$13.97,1,American Express - 1004,,,Redacto@gmail.com,07/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA090400816801),$13.97,$0.00,$13.97,,,,Redact,USD,
07/15/20,112-2962970-5733808,"Teeter EZ-Up Gravity Boots for Inversion Decompression Stretching, Back Pain Relief and Abdominal Exercise",SPORTING_GOODS,B001BUINPM,49000000,Amazon.com,,new,Teeter,,$99.00,$97.90,1,American Express - 1004,,,Redacto@gmail.com,07/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,FedEx(394902592130),$97.90,$0.00,$97.90,,,,Redact,USD,
07/16/20,112-3562428-1569850,Juniper Books Harry Potter Boxed Set: Slytherin Edition | 7-Volume Hardcover Book Set with Custom Designed Dust Jackets | Author J.K. Rowling | Includes all 7 Harry Potter Series Books,KITCHEN,B01M22IZMH,52150000,Amazon.com,,new,Juniper Books LLC,,$274.99,$274.99,1,American Express - 1004,,,Redacto@gmail.com,07/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA092531436901),$274.99,$0.00,$274.99,,,,Redact,USD,
07/20/20,114-6365782-3917869,Herm Sprenger Black Stainless Steel Prong Dog Training Collar with Swivel Ultra-Plus Pet Pinch Collar No-Pull Collar for Dogs Anti Pull Training Collar Made in Germany 2.25mm x 16in,ANIMAL_COLLAR,B07VMNFVQN,10111300,Amazon.com,,new,lavi holdings,,$0.00,$28.38,1,American Express - 1004,,,Redacto@gmail.com,07/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9400111202555801954758),$28.38,$0.00,$28.38,,,,Redact,USD,
07/20/20,114-0182762-3396256,"SportDOG Brand 425 Remote Trainers - 500 Yard Range E-Collar with Static, Vibrate and Tone - Waterproof, Rechargeable - Including New X-Series, Black (SD-425X)",ANIMAL_COLLAR,B07DZBN74T,10111300,Amazon.com,,new,Amazon.com,,$179.95,$159.95,1,American Express - 1004,,,Redacto@gmail.com,07/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z82E4020329838271),$159.95,$11.20,$171.15,,,,Redact,USD,
07/21/20,112-6376953-6089059,iOttie ITap 2 Magnetic Dashboard Car Mount Holder || Cradle for IPhone Xs Max R 8 Plus 7 Samsung Galaxy S10 E S9 S8 Plus Edge Note 9 & Other Smartphones,PORTABLE_ELECTRONIC_DEVICE_MOUNT,B07JVQNR4D,43191600,Amazon.com,,new,Amazon.com,,$29.95,$24.10,1,American Express - 1004,,,Redacto@gmail.com,07/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889677090485771061),$24.10,$1.69,$25.79,,,,Redact,USD,
07/21/20,112-5954059-8268267,Starbucks Coffee Hazelnut Syrup with Pump Dispenser (Boxed),SYRUP,B00WZEH3I6,50161500,Amazon.com,,new,Champions Specialty Boutique,,$0.00,$17.99,1,American Express - 1004,,,Redacto@gmail.com,07/24/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA099081727801),$17.99,$0.00,$17.99,,,,Redact,USD,
07/21/20,112-5954059-8268267,"Starbucks Cold Brew Coffee, Signature Black, Pitcher Packs, 8.6 Oz, Pack of 3",COFFEE,B07L9WDLQ1,50201700,Amazon.com,,new,Amazon.com,,$25.99,$25.99,1,American Express - 1004,,,Redacto@gmail.com,07/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA097595438401),$25.99,$0.00,$25.99,,,,Redact,USD,
07/28/20,111-2067023-5491449,OMK 2 Pcs Reusable Face Shields (FaceShield_S_2Pack),FACE_SHIELD,B087GBJPRZ,46181702,Amazon.com,,new,Warju Official Store,ISO 13485,$19.88,$19.86,1,American Express - 1004,,,Redacto@gmail.com,07/28/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960304180937),$19.86,$0.00,$19.86,,,,Redact,USD,
07/30/20,112-7589437-3437815,"GREENIES Original Large Natural Dog Dental Care Chews Oral Health Dog Treats, 36 oz. Pack (24 Treats)",PET_FOOD,B006W6YHV4,10121806,Amazon.com,,new,Amazon.com,,$39.99,$33.99,1,American Express - 2008,,,Redacto@gmail.com,07/30/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960304248436),$33.99,$2.38,$36.37,,,,Redact,USD,
08/01/20,112-2068203-9608226,"Scotts Turf Builder Grass Seed Zoysia Grass Seed and Mulch, 5 lb. - Full Sun and Light Shade - Thrives in Heat & Drought - Grows a Tough, Durable, Low-Maintenance Lawn - Seeds up to 2,000 sq. ft.",PLANT_SEED,B00ARYRY1W,10151900,Amazon.com,,new,Amazon.com,,$42.27,$42.27,1,American Express - 1004,,,Redacto@gmail.com,08/02/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1ZWX93600380845288),$42.27,$2.96,$45.23,,,,Redact,USD,
08/03/20,112-1911230-4812215,"CELSIUS HEAT Performance Energy Drink, Cherry Lime, 16 Fl Oz (Pack of 12)",DRINK_FLAVORED,B075RC3WZW,50202309,Amazon.com,,new,Amazon.com,,$20.29,$18.14,1,American Express - 1004,,,Redacto@gmail.com,08/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9361289677090479839634),$18.14,$0.00,$18.14,,,,Redact,USD,
08/05/20,112-9386769-9577805,"TENGA Spinner Cup Male Masturbator, Reusable Spiral Motion and Intimate Pleasure Device Men Masturber, SPN-002 HEXA, 1 Count (Pack of 1)",SEXUAL_WELLNESS,B07F7FNPC7,53131600,Amazon.com,,new,Amazon.com,,$27.00,$27.00,1,American Express - 1004,,,Redacto@gmail.com,08/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA243497295000),$27.00,$1.89,$28.89,,,,Redact,USD,
08/05/20,112-9386769-9577805,"TENGA Spinner Cup Men Masturbator, Reusable Spiral Motion, SPN-00 Tetra",SEXUAL_WELLNESS,B07F7K64VR,53131600,Amazon.com,,new,Amazon.com,,$27.00,$26.97,1,American Express - 1004,,,Redacto@gmail.com,08/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA243497295000),$26.97,$1.89,$28.86,,,,Redact,USD,
08/05/20,112-3939692-8765832,"iRobot Authentic Replacement Parts Jet Hard Floor Cleaning Solution, Compatible with All Braava Robot Mop Accessory, Clear",ROBOTIC_VACUUM_CLEANER,B07RD8356H,47121602,Amazon.com,,new,Amazon.com,,$7.99,$7.99,2,American Express - 1004,,,Redacto@gmail.com,08/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA111183783501),$15.98,$1.12,$17.10,,,,Redact,USD,
08/05/20,112-5828684-0925011,"iRobot Authentic Replacement Parts- Clean Base Automatic Dirt Disposal Bags, 3-Pack, Compatible with All Clean Base models, White - 4640235",VACUUM_CLEANER_BAG,B07HJS45XQ,47121607,Amazon.com,,new,Amazon.com,,$16.99,$14.99,1,American Express - 1004,,,Redacto@gmail.com,08/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889677090490737564),$14.99,$1.05,$16.04,,,,Redact,USD,
08/05/20,112-5828684-0925011,"iRobot Authentic Replacement Parts- Braava jet m Series Washable Wet Mopping Pads, (2-Pack)",UTILITY_SPONGE,B07R9LNW9K,47130000,Amazon.com,,new,Amazon.com,,$24.99,$16.99,1,American Express - 1004,,,Redacto@gmail.com,08/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889677090490737564),$16.99,$1.19,$18.18,,,,Redact,USD,
08/09/20,112-2181567-5317065,"Anthony Exfoliating Body Scrub – Sea Salt, Vitamin C, And Aloe Vera Deep Cleans, Smooths Rough Patches & Soothes and Protects Skin – Blue Sea Kelp Body Wash 12 Fl Oz",SKIN_EXFOLIANT,B00J4OTK9A,53131600,Amazon.com,,new,Anthony Skin,,$28.00,$30.00,1,American Express - 1004,,,Redacto@gmail.com,08/10/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA114457643501),$30.00,$0.00,$30.00,,,,Redact,USD,
08/09/20,112-2181567-5317065,"Anthony Facial Scrub, 8 Fl Oz, Contains Aloe Vera, Sand, Algae, Chamomile, Vitamin C, Soothes, Protects, Refreshes and Removes Dead Skin Cells (2 pack)",SKIN_EXFOLIANT,B01GRS20OM,53131613,Amazon.com,,new,FGP,,$36.00,$36.00,1,American Express - 1004,,,Redacto@gmail.com,08/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA114002813301),$36.00,$2.52,$38.52,,,,Redact,USD,
08/09/20,112-7064462-8416211,Voluspa Baltic Amber Candle | 3 Wick Tin | 12 Oz. | All Natural Wicks and Coconut Wax for Clean Burning,CANDLE,B00UVQIAPA,39112604,Amazon.com,,new,VOLUSPA,,$22.00,$26.00,1,American Express - 1004,,,Redacto@gmail.com,08/10/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA114457643501),$26.00,$1.82,$27.82,,,,Redact,USD,
08/11/20,112-9111953-4779404,Herm Sprenger - Package of 3-2.25mm Black Stainless Steel Spike/Pinch/Prong Extra Link 50530,ANIMAL_COLLAR,B01KPDZWQ8,10000000,Amazon.com,,new,lavi holdings,,$0.00,$12.99,1,American Express - 1004,,,Redacto@gmail.com,08/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA117478239701),$12.99,$0.00,$12.99,,,,Redact,USD,
08/11/20,112-1615663-0594626,"COSORI Air Fryer Oven Combo 5.8QT Max Xl Large Cooker (Cookbook with 100 Recipes), One-Touch Screen with 11 Precise Presets and Shake Reminder, Nonstick and Dishwasher-Safe Square Design Basket, Black",AIR_FRYER,B07GJBBGHG,52140000,Amazon.com,,new,Amazon.com,,$119.99,$119.99,1,American Express - 1004,,,Redacto@gmail.com,08/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA118075800901),$119.99,$8.40,$128.39,,,,Redact,USD,
08/11/20,112-1615663-0594626,"Zojirushi NS-TSC10 5-1/2-Cup (Uncooked) Micom Rice Cooker and Warmer, 1.0-Liter",RICE_COOKERS,B0074CDG6C,52141500,Amazon.com,,new,Amazon.com,,$192.00,$143.82,1,American Express - 1004,,,Redacto@gmail.com,08/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA117691226301),$143.82,$10.07,$153.89,,,,Redact,USD,
08/15/20,112-4082616-9147442,"Maryton Glass Pumice Stone for Feet, Callus Remover and Foot Scrubber & Pedicure Exfoliator Tool Pack of 2",FOOT_SCRAPER,B07SB5WSYP,53131600,Amazon.com,,new,maryton,,$9.99,$7.99,1,American Express - 1004,,,Redacto@gmail.com,08/16/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960304560553),$7.99,$0.00,$7.99,,,,Redact,USD,
08/16/20,112-0412496-7316215,"C H HANSON 15068 Marking Flag, 15"", Blue, 10 pack",BANNER,B000BQULDY,55121715,Amazon.com,,new,Amazon.com,,$3.29,$6.32,1,American Express - 1004,,,Redacto@gmail.com,08/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA246336105000),$6.32,$0.44,$6.76,,,,Redact,USD,
08/24/20,111-7180337-7774620,African Art Wall Decor A Herd of Elephant Vastu Migrate in the Winter Season Black and White Abstract Animal Canvas Wall Art for Office Living Room Contemporary Art Ready to hang 24inchx48inch,WALL_ART,B07YHQ2T8T,60121000,Amazon.com,,new,Kolo Wall Art,,$299.88,$79.89,1,American Express - 2008,,,Redacto@gmail.com,08/26/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z602RA40354626429),$79.89,$0.00,$79.89,,,,Redact,USD,
09/03/20,111-1472836-8042627,Back to Basics: Dog Training by Fabian,ABIS_BOOK,087605582X,55101500,Amazon.com,1997-11-01 0:00:01,used good,ThriftBooks-Reno,,$24.95,$10.46,1,American Express - 1004,,,Redacto@gmail.com,09/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,OSM(9241990172380146029157),$10.46,$0.00,$10.46,,,,Redact,USD,
09/06/20,112-6881300-4361010,,,B07PRPD37Y,,Amazon.com,,new,LIGHTS ON DISTRIBUTORS,,$0.00,$29.90,1,American Express - 1004,,,Redacto@gmail.com,09/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9300120111405243776246),$29.90,$0.00,$29.90,,,,Redact,USD,
09/12/20,112-8520333-2758643,Unsolved Case Files: Cold Case Murder Mystery Game: Who Murdered Harmony Ashcroft? | Can You Solve The Crime?,TOYS_AND_GAMES,B07MPCMXGR,60141100,Amazon.com,,new,Unsolved Case Files,,$0.00,$29.99,1,American Express - 1004,,,Redacto@gmail.com,09/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA147591360601),$29.99,$0.00,$29.99,,,,Redact,USD,
09/16/20,112-6654952-5000263,"SEAVON Dehumidifiers for home, 2800 Cubic Feet (269 sq. ft) Dehumidifiers with 1500ml capacity for Basements, Bathroom, Bedroom, RV, Wardrobe, Auto Shut Off",DEHUMIDIFIER,B07ZH4PT1G,40101902,Amazon.com,,new,Seavon,,$99.99,$49.99,1,American Express - 1004,,,Redacto@gmail.com,09/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA001516887504),$49.99,$0.00,$49.99,,,,Redact,USD,
09/18/20,112-0896064-9061826,"Maximus Body: The Physical and Mental Training Plan That Shreds Your Body, Builds Serious Strength, and Makes You Unstoppably Fit",ABIS_BOOK,1623369908,55101500,Amazon.com,2018-05-08 0:00:01,new,Amazon.com,,$21.99,$18.21,1,American Express - 1004,,,Redacto@gmail.com,09/19/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA003686057704),$18.21,$1.28,$19.49,,,,Redact,USD,
09/20/20,112-2681309-4866647,"ChromaLabel 2 Inch Round Label Removable Color Code Dot Stickers, Inventory Label, 500 Labels per Roll, Green",LABEL,B07VYK4ZCR,14111500,Amazon.com,,new,ChromaLabel,,$0.00,$15.95,1,American Express - 1004,,,Redacto@gmail.com,09/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA005818108004),$15.95,$1.12,$17.07,,,,Redact,USD,
09/21/20,114-5916171-0077821,"Starbucks Nitro Cold Brew, Vanilla Sweet Cream 9.6 fl oz Can (8 Pack) (Packaging May Vary)",COFFEE,B083XB2K68,50201700,Amazon.com,,new,Amazon.com,,$26.99,$21.44,1,American Express - 1004,,,Redacto@gmail.com,09/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA006457071104),$21.44,$0.00,$21.44,,,,Redact,USD,
09/26/20,111-6210544-8073813,Lixada Adult Bike Helmet Ultralight 32 Vents Adjustable Mountain Bike Road Bicycle Helmet Safety Protection with Lining Pad,SPORT_HELMET,B078X9FC9F,46181700,Amazon.com,,new,GreenUCT,,$0.00,$25.98,1,American Express - 2008,,,Redacto@gmail.com,09/29/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA012358529104),$25.98,$0.00,$25.98,,,,Redact,USD,
09/27/20,111-8862991-4274639,"LubeLife Water Based Personal Lubricant for Men and Women, Flavorless 12 Ounce",BODY_LUBRICANT,B07DGQ4QR3,52161500,Amazon.com,,new,CC Wellness LLC,,$11.99,$11.99,1,American Express - 1004,,,Redacto@gmail.com,09/27/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960305281077),$11.99,$0.00,$11.99,,,,Redact,USD,
10/02/20,112-8427924-8783422,"TENGA Spinner Cup Male Masturbator, Reusable Spiral Motion and Intimate Pleasure Device Men Masturber, SPN-002 HEXA, 1 Count (Pack of 1)",SEXUAL_WELLNESS,B07F7FNPC7,53131600,Amazon.com,,new,Amazon.com,,$27.00,$24.90,2,American Express - 1004,,,Redacto@gmail.com,10/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149516204201),$49.80,$4.48,$54.28,,,,Redact,USD,
10/02/20,112-8427924-8783422,"TENGA Spinner Cup Men Masturbator, Reusable Spiral Motion, SPN-00 Tetra",SEXUAL_WELLNESS,B07F7K64VR,53131600,Amazon.com,,new,Amazon.com,,$27.00,$23.57,2,American Express - 1004,,,Redacto@gmail.com,10/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149516204201),$47.14,$4.24,$51.38,,,,Redact,USD,
10/02/20,112-8427924-8783422,"Anthony Facial Scrub, 8 Fl Oz, Contains Aloe Vera, Sand, Algae, Chamomile, Vitamin C, Soothes, Protects, Refreshes and Removes Dead Skin Cells",SKIN_EXFOLIANT,B00MRFOTHG,53131613,Amazon.com,,new,Anthony Skin,,$36.00,$36.00,1,American Express - 1004,,,Redacto@gmail.com,10/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149533608301),$36.00,$3.24,$39.24,,,,Redact,USD,
10/02/20,112-8427924-8783422,Under Eye Patches - 24K Gold Under Eye Mask Anti-Aging Hyaluronic Acid Collagen Under Eye Pads Reducing Dark Circles & Wrinkles Treatment Gel Bags,SKIN_TREATMENT_MASK,B07RFG647Q,53120000,Amazon.com,,new,LeGushe,,$29.99,$14.75,1,American Express - 1004,,,Redacto@gmail.com,10/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149516204201),$14.75,$1.33,$16.08,,,,Redact,USD,
10/03/20,112-1876385-7766667,"Keadic 9Pcs Gundam Model Tools Kit Hobby Building Tools Craft Set for Basic Model Building, Repairing and Fixing",TOY_GUN,B071LB1T5D,60141000,Amazon.com,,new,Keadic,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,10/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149609804501),$9.99,$0.90,$10.89,,,,Redact,USD,
10/03/20,112-1876385-7766667,Zoids Kotobukiya 1/72 Scale Action Plastic Kit High-End Master Model 002 Command Wolf RHI-3,ART_CRAFT_KIT,B000P0G68U,60141000,Amazon.com,,new,Zipang Hobby TOKYO,,$0.00,$122.80,1,American Express - 1004,,,Redacto@gmail.com,10/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149617304901),$122.80,$11.05,$133.85,,,,Redact,USD,
10/03/20,112-1876385-7766667,1/72 RZ-041 Liger Zero Marking Plus Ver. ZOIDS Zoids HMM [ZD121],TOY_FIGURE,B07MGFCSM9,60141000,Amazon.com,,new,LAMBELL,,$0.00,$96.93,1,American Express - 1004,,,Redacto@gmail.com,10/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149609804501),$96.93,$8.72,$105.65,,,,Redact,USD,
10/03/20,112-1876385-7766667,"STARBOND EM-02 Super Fast Thin, Premium Instant CA (Cyanoacrylate Adhesive) Super Glue Plus Extra Cap and Microtips, 2 oz. (for Woodturning, Pen Turning, Hobby, Stabilizing, Finish, Inlay)",BONDING_ADHESIVES,B00C32ME6G,31201600,Amazon.com,,new,Starbond,,$0.00,$10.89,1,American Express - 1004,,,Redacto@gmail.com,10/04/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA149566818301),$10.89,$0.98,$11.87,,,,Redact,USD,
10/06/20,112-8989694-3369802,"USB C to HDMI Cable for Home Office 6ft (4K@60Hz), uni USB Type C to HDMI Cable, Thunderbolt 3 Compatible with MacBook Pro 2021/2020, MacBook Air,iPad Pro 2021, Surface Book 2, Galaxy S20 and More",ELECTRONIC_CABLE,B075V5JK36,43211600,Amazon.com,,new,ccessories,,$29.99,$15.29,1,American Express - 1004,,,Redacto@gmail.com,10/06/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA019096296404),$15.29,$0.00,$15.29,,,,Redact,USD,
10/08/20,112-3504333-9997046,"Super Nail Pure Acetone Polish Remover, 8 Fl Oz",NAIL_POLISH_REMOVER,B004KJ7AGE,53131600,Amazon.com,,new,Beauty 4 Us,,$0.00,$6.53,1,American Express - 1004,,,Redacto@gmail.com,10/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA149773152101),$6.53,$0.59,$7.12,,,,Redact,USD,
10/08/20,112-1052825-1553852,"Gundam Model Builder's Cutting Mat 12""x9""(A4)",CUTTING_BOARD,B01KQ08064,52151606,Amazon.com,,new,Alemon Store,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,10/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA149762677501),$9.99,$0.90,$10.89,,,,Redact,USD,
10/08/20,112-1052825-1553852,"GSI Creos Gundam Marker Value Set -GM01&02&03- (Black, Gray, Brown)",MARKING_PEN,B00HY93GHY,44121700,Amazon.com,,new,ClickJapan com,,$0.00,$9.97,1,American Express - 1004,,,Redacto@gmail.com,10/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA149762677501),$9.97,$0.90,$10.87,,,,Redact,USD,
10/08/20,112-1052825-1553852,"LANSCOERY Clear Acrylic Display Case for Collectibles, Assemble Cube Display Box Stand Dustproof Protection Showcase for Action Figures Toys (4.7x4.7x4.7inch; 12x12x12cm)",HOME,B085NSGDYG,52140000,Amazon.com,,new,LANSCOERY,,$0.00,$15.99,2,American Express - 1004,,,Redacto@gmail.com,10/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z9136F31307857881),$31.98,$2.88,$34.86,,,,Redact,USD,
10/08/20,112-6295281-0113854,"Bob Smith Industries BSI-161H UN-CURE Super Glue Debonder, 1 oz.,Clear",BONDING_ADHESIVES,B0166FFC96,31201636,Amazon.com,,new,Amazon.com,,$0.00,$11.64,1,American Express - 1004,,,Redacto@gmail.com,10/09/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA149773152101),$11.64,$1.05,$12.69,,,,Redact,USD,
10/08/20,112-9751156-6582603,"OGX Extra Strength Refreshing + Invigorating Teatree Mint Dry Scalp Treatment with Witch Hazel Astringent to Help Remove Scalp Buildup, Paraben-Free, Sulfate Surfactant-Free, 4 fl oz",CONDITIONER,B076RY54J9,53131602,Amazon.com,,new,Amazon.com,,$8.99,$8.99,1,American Express - 1004,,,Redacto@gmail.com,10/08/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA149765454201),$8.99,$0.81,$9.80,,,,Redact,USD,
10/08/20,113-0972055-5180201,KOTOBUKIYA ZOIDS EZ-049 BERSERK FUHRER repackaged Ver. 1/72 Scale Plastic Model.,TOY_FIGURE,B07MQV4D7C,60141000,Amazon.com,,new,LAMBELL,,$0.00,$129.99,1,American Express - 1004,,,Redacto@gmail.com,10/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z9136F31307857881),$129.99,$11.70,$141.69,,,,Redact,USD,
10/09/20,113-5787647-3633036,"ZOIDS EZ-015 Iron Kong Yeti Total Length Approx. 11.2 inches (285 mm), 1/72 Scale Plastic Model",ART_CRAFT_KIT,B08468TYNH,60141000,Amazon.com,,new,Zipang Hobby TOKYO,,$0.00,$245.00,1,American Express - 1004,,,Redacto@gmail.com,10/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA149917134901),$245.00,$22.05,$267.05,,,,Redact,USD,
10/09/20,113-6175485-6674617,ZOIDS RZ-041 Liger Zero Panzer Marking Plus Ver. Total Length of About 320mm 1/72 Scale Plastic Model,TOY_FIGURE,B07MTBY7L6,60141000,Amazon.com,,new,ImageAnime New York,,$0.00,$200.00,1,American Express - 1004,,,Redacto@gmail.com,10/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z04E71EA870071018),$200.00,$18.00,$218.00,,,,Redact,USD,
10/09/20,113-6634331-0541811,ZOIDS RZ-041 Liger Zero Schneider Marking Plus Ver. Total Length of About 320mm 1/72 Scale Plastic Model,MODEL,B07M83J26M,60141000,Amazon.com,,new,Japan NOW!!!,,$0.00,$158.53,1,American Express - 1004,,,Redacto@gmail.com,10/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,Japan Post(We manage tracking id),$158.53,$14.27,$172.80,,,,Redact,USD,
10/09/20,113-9254768-0554665,ZOIDS RZ-041 Liger Zero Yeager Marking Plus Ver. Total Length of About 320mm 1/72 Scale Plastic Model,MODEL,B07MQV4J98,60141000,Amazon.com,,new,Worldwide Japan online shop,,$0.00,$135.63,1,American Express - 1004,,,Redacto@gmail.com,10/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,Japan Post(We manage tracking id),$135.63,$12.21,$147.84,,,,Redact,USD,
10/13/20,111-3752757-2264207,Kalon Women’s 4 Pack Racerback Sports Bralette Seamless Wireless Comfort Bra,BRA,B07MX4CPWR,53100000,Amazon.com,,new,Kalon Clothing,,$0.00,$31.99,1,American Express - 2008,,,Redacto@gmail.com,10/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA025711435904),$31.99,$0.00,$31.99,,,,Redact,USD,
10/13/20,111-3752757-2264207,RUNNING GIRL Yoga Tank Tops for Women Built in Shelf Bra B/C Cups Strappy Back Activewear Workout Compression Tops,SHIRT,B07QDR2RQR,53100000,Amazon.com,,new,RUNNING GIRL,,$0.00,$21.99,1,American Express - 2008,,,Redacto@gmail.com,10/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA025680125604),$21.99,$0.00,$21.99,,,,Redact,USD,
10/13/20,111-3752757-2264207,CRZ YOGA Seamless Workout Tank Tops for Women Racerback Athletic Camisole Sports Shirts with Built in Bra,SHIRT,B07V7F9H7M,53100000,Amazon.com,,new,CRZ YOGA,,$0.00,$17.60,1,American Express - 2008,,,Redacto@gmail.com,10/13/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA025680125604),$17.60,$0.00,$17.60,,,,Redact,USD,
10/14/20,112-8430238-2891460,PopSockets: PopGrip with Swappable Top for Phones and Tablets - Black,CELL_PHONE_GRIP,B07P29XQR4,43191610,Amazon.com,,new,BrandHere,,$9.99,$7.99,1,American Express - 2008,,,Redacto@gmail.com,10/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028709123504),$7.99,$0.00,$7.99,,,,Redact,USD,
10/14/20,112-8430238-2891460,"Meifigno Magic Series Compatible with iPhone 11 Case, [Military Grade Drop Tested], Translucent Matte PC with Soft Edges, Shockproof Phone Case Designed for iPhone 11 6.1 inch (2019), Black",CELLULAR_PHONE_CASE,B07WS93W75,43191601,Amazon.com,,new,Meifigno,,$29.99,$9.90,1,American Express - 2008,,,Redacto@gmail.com,10/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028709123504),$9.90,$0.00,$9.90,,,,Redact,USD,
10/14/20,112-8430238-2891460,"Trianium Tempered Glass Screen Protector Designed for Apple iPhone 11 (2019) / iPhone XR (2018), 3 Pack HD 0.25mm Glass 9H Film (w/Alignment Case Tool included)",SCREEN_PROTECTOR,B07FNYD5RQ,43191601,Amazon.com,,new,TrianiumDirect,,$7.99,$6.99,1,American Express - 2008,,,Redacto@gmail.com,10/15/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA028709123504),$6.99,$0.49,$7.48,,,,Redact,USD,
10/16/20,113-1947900-0361843,Rude Boy Dual Action Prostate Massager,SEXUAL_STIMULATION_DEVICE,B000X6MOV4,53131600,Amazon.com,,new,SellLogic,,$60.00,$40.38,1,American Express - 1004,,,Redacto@gmail.com,10/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,DHL Global Mail(9274890223342525259573),$40.38,$3.63,$44.01,,,,Redact,USD,
10/16/20,113-3179902-6212210,"LubeLife Water Based Anal Lubricant for Men and Women, 12 Ounce",BODY_LUBRICANT,B07DGM7QBZ,53131600,Amazon.com,,new,CC Wellness LLC,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,10/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA150116825801),$9.99,$0.90,$10.89,,,,Redact,USD,
10/21/20,114-1171330-0712254,4 Pieces V Neck Cami Bra Padded Seamless Bralette Straps Sleeping Bra for Women Girls,BRA,B083HZB7GR,53100000,Amazon.com,,new,Kaize Network Technology,,$0.00,$22.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,10/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA035297022204),$22.99,$0.00,$22.99,,,,Redact,USD,
10/21/20,114-1171330-0712254,"ICONFLANG iPhone 11 Pro Case with Ring 5.8 inch, Anti-Scratch Case with 360 Degree Rotation Finger Ring holer Kickstand Work with Magnetic Car Mount for Apple iPhone 11 Pro (2019)- Black",CELLULAR_PHONE_CASE,B07X2P3Z25,43191601,Amazon.com,,new,Vatro,,$0.00,$12.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,10/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA035297022204),$12.99,$0.00,$12.99,,,,Redact,USD,
10/21/20,114-1171330-0712254,Oalka Women's Workout Shirts Racerback Yoga Sport Pads Bras Fitness Active Tank Tops Heart Black S,SHIRT,B08K2YB8TV,53100000,Amazon.com,,new,Oalka,,$0.00,$19.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,10/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA035297022204),$19.99,$0.00,$19.99,,,,Redact,USD,
10/31/20,111-2130019-7503448,CRZ YOGA Women's Naked Feeling I Workout Leggings 28 Inches - High Waisted Full-Length Yoga Pants,PANTS,B07S4GN4L1,53100000,Amazon.com,,new,CRZ YOGA,,$0.00,$24.00,1,American Express - 2008,,,Redacto@gmail.com,10/31/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA044421293504),$24.00,$1.44,$25.44,,,,Redact,USD,
11/02/20,114-3998911-8337025,"Boobeen 4 Pack Big Hair Claw - Nonslip Large Claw Clip for Women and Girls Thin Hair, Strong Hold for Thick Hair - Fashion Accessories for Women Girls (S4)",HAIR_CLIP,B08HYSXVXR,53131600,Amazon.com,,new,Boobeen-Beauty,,$0.00,$12.99,1,American Express - 1004,,,Redacto@gmail.com,11/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z6A4F120306763034),$12.99,$1.17,$14.16,,,,Redact,USD,
11/02/20,114-3998911-8337025,Hot Tools Pro Signature Ionic Ceramic Hair Dryer | Lightweight with Professional Blowout Results,HAIR_DRYER,B07S63DQZR,52141703,Amazon.com,,new,Amazon.com,,$56.80,$43.94,1,American Express - 1004,,,Redacto@gmail.com,11/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA150770571001),$43.94,$3.95,$47.89,,,,Redact,USD,
11/02/20,114-3998911-8337025,"Paul Mitchell Pro Tools 427 Paddle Brush, For Blow-Drying + Smoothing Long or Thick Hair",LUXURY_BEAUTY,B0016KWZ2E,53131600,Amazon.com,,new,Amazon.com,,$18.24,$15.50,1,American Express - 1004,,,Redacto@gmail.com,11/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z6A4F120306763034),$15.50,$1.40,$16.90,,,,Redact,USD,
11/02/20,114-3998911-8337025,"Paul Mitchell Pro Tools Detangler Comb, Wide Tooth Comb Detangles Wet or Dry Hair Milky White",HAIR_COMB,B001VGA4P8,53131600,Amazon.com,,new,Amazon.com,,$4.00,$4.00,1,American Express - 1004,,,Redacto@gmail.com,11/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z6A4F120306763034),$4.00,$0.36,$4.36,,,,Redact,USD,
11/03/20,112-3505932-8328266,Jolly Pets Soccer Ball Floating-Bouncing Dog Toy,PET_TOY,B01EMSVV4E,10111300,Amazon.com,,new,EntirelyPets,,$18.99,$18.99,1,American Express - 1004,,,Redacto@gmail.com,11/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA049532143404),$18.99,$1.14,$20.13,,,,Redact,USD,
11/04/20,112-5697891-9011437,,,B07W438FK2,,Amazon.com,,new,Joylab Direct,,$0.00,$19.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,11/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA150869733901),$19.99,$1.80,$21.79,,,,Redact,USD,
11/04/20,112-4705503-5363400,Replacement Charging Base for Philips Sonicare DiamondClean HX9100 HX9340 Toothbrushes Black,CHARGING_ADAPTER,B081KVP1B1,26111700,Amazon.com,,new,JRSMART,,$0.00,$36.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,11/05/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,USPS(9405511899564352717630),$36.99,$3.33,$40.32,,,,Redact,USD,
11/06/20,114-4271353-7149033,,,B08KWKYWM3,,Amazon.com,,new,valiant,,$0.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,11/11/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA151079886801),$14.99,$1.35,$16.34,,,,Redact,USD,
11/12/20,111-6607494-9969821,"Ring Sizer Adjuster for Loose Rings - 12 Pack, 2 Sizes for Different Band Widths – Silicone Ring Size Adjuster - Invisible Ring Guards for Women and Men by 5 STARS UNITED",RING,B0757JLTY7,54101503,Amazon.com,,new,5 STARS UNITED,,$8.99,$7.99,1,American Express - 2008,,,Redacto@gmail.com,11/12/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA056382503704),$7.99,$0.48,$8.47,,,,Redact,USD,
11/16/20,114-5622121-7797028,,,B07PRTFPHJ,,Amazon.com,,new,Sherman Bodega,,$0.00,$20.53,1,American Express - 1004,,,Redacto@gmail.com,11/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,AMZN_US(TBA151510972901),$20.53,$0.00,$20.53,,,,Redact,USD,
11/16/20,111-5250800-6151459,"Baskerville Ultra Dog Muzzle Dogs, Prevents Chewing and Biting, Basket Allows Panting and Drinking-Comfortable, Humane, Adjustable, Lightweight, Durable, 6 Sizes, Black/Tan",ANIMAL_MUZZLE,B00596TFVA,10000000,Amazon.com,,new,Amazon.com,,$24.98,$18.20,1,American Express - 2008,,,Redacto@gmail.com,11/17/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA061164406804),$18.20,$1.09,$19.29,,,,Redact,USD,
11/21/20,111-8329156-0144208,"Crest + Scope Complete Whitening Toothpaste, Minty Fresh, 5.4 oz, Pack of 3",TOOTH_CLEANING_AGENT,B005PLQIQ4,53131502,Amazon.com,,new,Amazon.com,,$8.77,$6.52,1,American Express - 1004,,,Redacto@gmail.com,11/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA276178791000),$6.52,$0.46,$6.98,,,,Redact,USD,
11/21/20,111-8329156-0144208,"Zyrtec 24 Hour Allergy Relief Tablets, Bundle with 1 x 45ct and 1 x 3ct Travel Pack, 48 Piece Assortment",MEDICATION,B084S9VJ6S,51000000,Amazon.com,,new,Amazon.com,,$26.60,$26.60,1,American Express - 1004,,,Redacto@gmail.com,11/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA276178791000),$26.60,$0.00,$26.60,,,,Redact,USD,
11/21/20,111-8329156-0144208,"Scotch Heavy Duty Packaging Tape, 1.88"" x 38.2 yd, Designed for Packing, Shipping and Mailing, Strong Seal on All Box Types, 3"" Core, Clear, 3 Rolls (3850S-3)",ADHESIVE_TAPES,B001BLX9DM,31201517,Amazon.com,,new,Amazon.com,,$0.00,$11.98,1,American Express - 1004,,,Redacto@gmail.com,11/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA276178791000),$11.98,$0.84,$12.82,,,,Redact,USD,
11/21/20,111-8329156-0144208,"Bubble Cushioning Wrap, Metronic 12 Inch x 72 Feet 3/16"" Air Bubble with 20 Fragile Sticker Labels, Bubble Moving Wrap for Packing, Moving Supplies, Small business, Shipping-2 Rolls Clear",PACKING_MATERIAL,B07YBZL27G,44121500,Amazon.com,,new,Jiaro International,,$17.99,$12.99,1,American Express - 1004,,,Redacto@gmail.com,11/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA276178791000),$12.99,$0.00,$12.99,,,,Redact,USD,
11/21/20,111-8329156-0144208,"Packing Paper Sheets for Moving - 5lb - 160 Sheets of Newsprint Paper - Must Have in Your Moving Supplies - 27"" x 17"" - Made in USA",PACKING_MATERIAL,B07Z8G1ZWQ,14111500,Amazon.com,,new,Bryco Goods,,$24.97,$14.97,1,American Express - 1004,,,Redacto@gmail.com,11/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA276178791000),$14.97,$0.00,$14.97,,,,Redact,USD,
11/23/20,111-8030346-2789800,"Hefty Ultra Strong Tall Kitchen Trash Bags, Lavender & Sweet Vanilla Scent, 13 Gallon, 40 Count",WASTE_BAG,B01BZ0M3ZI,47121701,Amazon.com,,new,Amazon.com,,$8.99,$6.27,1,American Express - 1004,,,Redacto@gmail.com,11/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,UPS(1Z6A29960306198862),$6.27,$0.44,$6.71,,,,Redact,USD,
11/24/20,111-7832860-2010615,"Ziploc Gallon Food Storage Freezer Bags, Grip 'n Seal Technology for Easier Grip, Open, and Close, 28 Count",FOOD_STORAGE_BAG,B003UEGZNE,52151506,Amazon.com,,new,Amazon.com,,$0.00,$4.99,1,American Express - 1004,,,Redacto@gmail.com,11/24/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,USPS(9374889764009003796979),$4.99,$0.35,$5.34,,,,Redact,USD,
11/24/20,114-1735647-5320215,"Toshiba Canvio Basics 1TB Portable External Hard Drive USB 3.0, Black - HDTB410XK3AA",COMPUTER_DRIVE_OR_STORAGE,B079D359S6,43201827,Amazon.com,,new,Amazon.com,,$48.99,$46.99,2,American Express - 1004,,,Redacto@gmail.com,11/25/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-2806,Shipped,UPS(1Z6A4F120307339189),$93.98,$8.46,$102.44,,,,Redact,USD,
12/02/20,111-4104314-9266644,"Portable Electric Space Heater, 1500W/750W Ceramic Heater with Thermostat, Heat Up 200 Square Feet in Minutes, Safe and Quiet for Office Room Desk Indoor Use ( Black )",SPACE_HEATER,B07XRQ7GHJ,52141802,Amazon.com,,new,Brilliant Light Inc,,$0.00,$31.99,1,American Express - 2008,,,Redacto@gmail.com,12/03/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA080412844104),$31.99,$1.92,$33.91,,,,Redact,USD,
12/12/20,112-7365970-3986622,,,B089VVLX34,,Amazon.com,,new,Marioss,,$0.00,$63.98,1,American Express - 1004,,,Redacto@gmail.com,12/14/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,FedEx(781387676335),$63.98,$3.84,$67.82,FALSE,,FALSE,Redact,USD,
12/15/20,111-9419175-7794627,ETUDE HOUSE SoonJung 5.5 Foam Cleanser 150ml | Hypoallergenic cleansing foam for sensitive skin | Korean Skin Care,SKIN_CLEANING_AGENT,B085RKX35W,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$9.50,0,American Express - 1004,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,,,$0.00,$0.00,$0.00,,,,Redact,USD,
12/15/20,111-9419175-7794627,"ETUDE HOUSE SoonJung 2x Barrier Intensive Cream 60ml (Old Version)- Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Panthenol and Madecassoside Heals Damaged Skin",SKIN_MOISTURIZER,B072L2344Z,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$13.90,1,American Express - 1004,,,Redacto@gmail.com,12/18/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA103219715304),$13.90,$0.83,$14.73,FALSE,,FALSE,Redact,USD,
12/19/20,112-6092076-1611420,Apple Lightning to Digital AV Adapter,ELECTRONIC_ADAPTER,B009WHV3BM,43211609,Amazon.com,,new,Amazon.com,,$49.00,$44.96,1,American Express - 1004,,,Redacto@gmail.com,12/21/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA107207749404),$44.96,$2.70,$47.66,FALSE,,FALSE,Redact,USD,
12/21/20,111-3886296-5093008,"Fathers Day Gifts, Magnetic Wristband Tool Belts with 15 Strong Magnets for Holding Screws, Nails - Great Gift Tool Organizers Holsters for DIY Handyman, Father/Dad, Husband, Boyfriend, Men - 2 pack",TOOL_BELT,B07XXWW7Q2,24112403,Amazon.com,,new,Lycool,,$29.99,$19.99,1,American Express - 2008,,,Redacto@gmail.com,12/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA111091655704),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
12/21/20,111-3886296-5093008,Womens Wallet RFID Blocking Genuine Leather Zip Around Wallet Clutch Wristlet Travel Long Purse for Women,WALLET,B07NYXWG7H,53121600,Amazon.com,,new,Moflycom,,$0.00,$13.99,1,American Express - 2008,,,Redacto@gmail.com,12/23/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA111593249904),$13.99,$0.84,$14.83,FALSE,,FALSE,Redact,USD,
12/21/20,111-9554852-6074664,Guess Who? Game Original Guessing Game for Kids Ages 6 and Up for 2 Players,BOARD_GAME,B08GD242TJ,60141102,Amazon.com,,new,Amazon.com,,$9.99,$9.97,1,American Express - 2008,,,Redacto@gmail.com,12/22/20,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZL(TBA110676426904),$9.97,$0.60,$10.57,FALSE,,FALSE,Redact,USD,
01/05/21,111-7082233-2086634,Liquid I.V. Hydration Multiplier - Lemon Lime - Hydration Powder Packets | Electrolyte Drink Mix | Easy Open Single-Serving Stick | Non-GMO | 16 Sticks,NUTRITIONAL_SUPPLEMENT,B01IT9NLHW,53131600,Amazon.com,,new,Liquid I.V.,,$24.99,$23.98,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,01/06/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA125530827204),$23.98,$0.00,$23.98,,,,Redact,USD,
01/12/21,111-3549877-6587437,"IBUYKE Industrial L-Shaped Computer Desk with Storage Shelves, Space-Saving Corner Desk Stand for Home Office, Large Modern Gaming Table, Study Workstation, Easy Assembly, Rustic Brown UTMJ053H",DESK,B08BZCTSN3,56000000,Amazon.com,,new,IBUYKE-US,,$0.00,$129.99,1,American Express - 2008 and Gift Certificate/Card,,,Redacto@gmail.com,01/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA294127170000),$129.99,$7.80,$137.79,FALSE,,FALSE,Redact,USD,
01/24/21,111-6293915-1499453,"Convenience Concepts Tucson Console Table with Shelf, Black",TABLE,B01HQ2YUEQ,56101500,Amazon.com,,new,Amazon.com,,$129.36,$85.99,1,American Express - 2008,,,Redacto@gmail.com,01/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,UPS(1Z6V60W30323118061),$85.99,$5.16,$91.15,FALSE,,FALSE,Redact,USD,
01/25/21,112-3806957-4400215,"SportDOG Brand 425 Remote Trainers - 500 Yard Range E-Collar with Static, Vibrate and Tone - Waterproof, Rechargeable - Including New X-Series, Black (SD-425X)",ANIMAL_COLLAR,B07DZBN74T,10111300,Amazon.com,,new,Amazon.com,,$179.95,$149.95,1,American Express - 1004,,,Redacto@gmail.com,01/25/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA147512248104),$149.95,$9.00,$158.95,FALSE,,FALSE,Redact,USD,
01/29/21,111-2815594-2717007,"Ring Sizer Adjuster for Loose Rings - 12 Pack, 2 Sizes for Different Band Widths – Silicone Ring Size Adjuster - Invisible Ring Guards for Women and Men by 5 STARS UNITED",RING,B0757JLTY7,54101503,Amazon.com,,new,5 STARS UNITED,,$0.00,$5.94,1,American Express - 1004,,,Redacto@gmail.com,01/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA152372412204),$5.94,$0.36,$6.30,FALSE,,FALSE,Redact,USD,
01/30/21,112-4719379-0987424,"Gamma2 Vittles Vault Dog Food Storage Container (Durable, Food Safe, BPA Free Storage, Made in the USA with Recycled Materials)Holds up to 50 pounds",FOOD_STORAGE_CONTAINER,B0002DJOOI,10111300,Amazon.com,,new,Amazon.com,,$54.99,$37.95,1,American Express - 1004,,,Redacto@gmail.com,01/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SALINE,MI,48176-1275,Shipped,AMZN_US(TBA152811654304),$37.95,$2.28,$40.23,FALSE,,FALSE,Redact,USD,
01/30/21,112-1787886-7381865,The Intelligent Investor Rev Ed.: The Definitive Book on Value Investing,ABIS_BOOK,60555661,55101500,Amazon.com,2006-02-21 0:00:01,new,Amazon.com,,$24.99,$14.29,1,American Express - 1004,,,Redacto@gmail.com,01/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,FORT LAUDERDALE,FL,33312-4104,Shipped,AMZN_US(TBA152845516304),$14.29,$1.00,$15.29,FALSE,,FALSE,Redact,USD,
01/31/21,112-7601179-9154622,The Intelligent Investor Rev Ed.: The Definitive Book on Value Investing,ABIS_BOOK,60555661,55101500,Amazon.com,2006-02-21 0:00:01,new,Amazon.com,,$24.99,$14.29,1,American Express - 1004,,,Redacto@gmail.com,01/31/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Saline,Michigan,48176,Shipped,AMZN_US(TBA153963449404),$14.29,$0.86,$15.15,FALSE,,FALSE,Redact,USD,
02/05/21,112-3655878-6698621,Back to Basics: Dog Training by Fabian,ABIS_BOOK,087605582X,55101500,Amazon.com,1997-11-01 0:00:01,new,Gem Book,,$24.95,$25.06,1,American Express - 1004,,,Redacto@gmail.com,02/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,GOLETA,CA,93117-1335,Shipped,USPS(9361289724009527628646),$25.06,$1.94,$27.00,FALSE,,FALSE,Redact,USD,
02/06/21,112-5237600-6832213,"Large Dry Erase Wall Calendar - 38"" x 58"" - Undated Blank 2022 Reusable Yearly Calendar - Giant Whiteboard Year Poster - Laminated Office Jumbo 12 Month Calendar",CALENDAR,B01NB8KFFK,44112002,Amazon.com,,new,All Adventure,,$0.00,$79.99,1,American Express - 1004,,,Redacto@gmail.com,02/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z9X27F50304985906),$79.99,$4.80,$84.79,FALSE,,FALSE,Redact,USD,
02/08/21,112-7460711-8073851,"Brother P-touch, PTD210, Easy-to-Use Label Maker, One-Touch Keys, Multiple Font Styles, 27 User-Friendly Templates, White",PRINTER,B013DG2FNW,45100000,Amazon.com,,new,Amazon.com,,$34.99,$34.99,1,American Express - 1004,,,Redacto@gmail.com,02/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA162090188904),$34.99,$2.10,$37.09,FALSE,,FALSE,Redact,USD,
02/08/21,112-7460711-8073851,"Brother Genuine P-touch, TZe-231 2 Pack Tape (TZE2312PK) ½”(0.47”) x 26.2 ft. (8m) 2-Pack Laminated P-Touch Tape, Black on White, Perfect for Indoor or Outdoor Use, Water Resistant, TZE2312PK, TZE231",LABEL,B017RCO1JY,55121611,Amazon.com,,new,Amazon.com,,$34.99,$24.98,1,American Express - 1004,,,Redacto@gmail.com,02/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA162090188904),$24.98,$1.50,$26.48,FALSE,,FALSE,Redact,USD,
02/08/21,112-0408278-0250628,"YOMIFUN Zero Gravity Chair, Lawn Chair Recliner Lounge Chair with Removable Pillow and Side Table, Gray",CHAIR,B08C7WLF56,56101600,Amazon.com,,new,Meto Direct,,$90.99,$68.99,1,American Express - 1004,,,Redacto@gmail.com,02/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z1AV5760333552734),$68.99,$4.14,$73.13,FALSE,,FALSE,Redact,USD,
02/08/21,112-5739769-0499467,"Strongest Door Stopper, Heavy Duty Door Stop Wedge Made of Premium Quality Zinc and Rubber Suits Any Door, Any Floor. Set of 2 Plus Bonus Self Adhesive Wall Protectors",DOORSTOP,B073TXK7B6,31162804,Amazon.com,,new,Buy Gift,,$22.00,$18.98,1,American Express - 1004,,,Redacto@gmail.com,02/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA162860717004),$18.98,$1.14,$20.12,FALSE,,FALSE,Redact,USD,
02/09/21,112-3326144-4582659,"NETGEAR Nighthawk Cable Modem CM1200 - Compatible with all Cable Providers including Xfinity by Comcast, Spectrum, Cox | For Cable Plans Up to 2 Gigabits | 4 x 1G Ethernet ports | DOCSIS 3.1, Black",MODEM,B07Q5VM51Y,43222600,Amazon.com,,new,Amazon.com,,$219.99,$179.99,1,American Express - 1004,,,Redacto@gmail.com,02/11/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA164874723204),$179.99,$10.80,$190.79,FALSE,,FALSE,Redact,USD,
02/11/21,114-2950708-2123429,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$59.63,2,American Express - 1004,,,Redacto@gmail.com,02/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA166232628604),$119.26,$7.16,$126.42,FALSE,,FALSE,Redact,USD,
02/11/21,113-3621487-6841805,"ETUDE HOUSE SoonJung 2x Barrier Intensive Cream 60ml (Old Version)- Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Panthenol and Madecassoside Heals Damaged Skin",SKIN_MOISTURIZER,B072L2344Z,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$13.90,1,American Express - 2008,,,Redacto@gmail.com,02/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA166232628604),$13.90,$0.83,$14.73,FALSE,,FALSE,Redact,USD,
02/11/21,113-1543342-1873038,Brazilian Blowout | Anti-Frizz Shampoo/Conditioner 2 Pack,HAIR_CLEANER_CONDITIONER,B07KS7RV5Z,53131600,Amazon.com,,new,Amazon.com,,$49.00,$49.00,1,American Express - 2008,,,Redacto@gmail.com,02/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA166232628604),$49.00,$2.94,$51.94,FALSE,,FALSE,Redact,USD,
02/11/21,112-0828172-4665856,"2 Pack Tongue Scraper with Travel Case, Bad Breath Treatment for Adults & Kids, Medical Grade 100% Stainless Steel Tongue Scrapers for Oral Care, Easy to Use Tongue Cleaner for Hygiene",HEALTH_PERSONAL_CARE,B01LAY47D0,53131500,Amazon.com,,new,MasterMedi,,$12.00,$9.49,1,American Express - 2008,,,Redacto@gmail.com,02/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA166232628604),$9.49,$0.57,$10.06,FALSE,,FALSE,Redact,USD,
02/12/21,112-5325224-4145068,"iRobot Authentic Replacement Parts Jet Hard Floor Cleaning Solution, Compatible with All Braava Robot Mop Accessory, Clear",ROBOTIC_VACUUM_CLEANER,B07RD8356H,47121602,Amazon.com,,new,Amazon.com,,$7.99,$7.99,3,American Express - 2008,,,Redacto@gmail.com,02/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA167013707404),$23.97,$1.44,$25.41,FALSE,,FALSE,Redact,USD,
02/16/21,113-7113332-7635441,"Nespresso Vertuo Plus Deluxe Coffee and Espresso Maker by De'Longhi, Piano Black with Aeroccino Milk Frother",COFFEE_MAKER,B01N1QSB6J,52141500,Amazon.com,,new,Mlaags,,$249.00,$226.79,1,American Express - 1004,,,Redacto@gmail.com,02/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA170554767804),$226.79,$13.61,$240.40,FALSE,,FALSE,Redact,USD,
02/16/21,113-7113332-7635441,"Nespresso Capsules OriginalLine, Ispirazione Arpeggio Intenso, Dark Roast Coffee, 10 Count (Pack of 5)",COFFEE,B0752GGQG2,50201706,Amazon.com,,new,Amazon.com,,$35.00,$35.00,1,American Express - 1004,,,Redacto@gmail.com,02/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA171151631504),$35.00,$0.00,$35.00,,,,Redact,USD,
02/17/21,112-2486593-1410663,Amazon Basics 3.5mm to 2-Male RCA Adapter Audio Stereo Cable - 8 Feet,ELECTRONIC_CABLE,B01D5H8KO2,26121600,Amazon.com,,new,Amazon.com,,$9.65,$9.65,1,American Express - 1004,,,Redacto@gmail.com,02/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA171151631504),$9.65,$0.58,$10.23,FALSE,,FALSE,Redact,USD,
02/17/21,112-2486593-1410663,,,B07MPF4F68,,Amazon.com,,new,MEXMON LIMITED-US,,$25.99,$14.99,1,American Express - 1004,,,Redacto@gmail.com,02/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA171151631504),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
02/18/21,112-9396251-3198624,"Original HP 910 Black, Cyan, Magenta, Yellow Ink Cartridges (4-pack) | Works with HP OfficeJet 8010, 8020 Series, HP OfficeJet Pro 8020, 8030 Series | Eligible for Instant Ink | 3YQ26AN",INKJET_PRINTER_INK,B07TYW3JPF,44103105,Amazon.com,,new,Amazon.com,,$54.89,$54.89,1,American Express - 2008,,,Redacto@gmail.com,02/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA171698961304),$54.89,$3.29,$58.18,FALSE,,FALSE,Redact,USD,
02/19/21,112-2368939-8948212,"Crystal Light Sugar-Free Zero Calorie Liquid Water Enhancer - Strawberry Lemonade (1.62 fl oz Bottle, Pack of 12)",FLAVORED_DRINK_CONCENTRATE,B00FY42TTM,50000000,Amazon.com,,new,Amazon.com,,$51.12,$35.76,1,American Express - 2008,,,Redacto@gmail.com,02/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA172616985104),$35.76,$0.00,$35.76,,,,Redact,USD,
02/20/21,112-3594262-7505848,"BoxLegend V3 Shirt Folding Board t Shirts Clothes Folder Durable Plastic Laundry folders Folding Boards,Black",SHIRT,B077XV9VZG,53100000,Amazon.com,,new,BoxLegend,,$29.99,$16.99,1,American Express - 2008,,,Redacto@gmail.com,02/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA174654124204),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
02/20/21,111-7396085-8655423,"Zulay Original Milk Frother Handheld Foam Maker for Lattes - Whisk Drink Mixer for Coffee, Mini Foamer for Cappuccino, Frappe, Matcha, Hot Chocolate by Milk Boss (Executive Black)",MILK_FROTHER,B074ZL35DH,52141500,Amazon.com,,new,Zulay Deals,,$0.00,$19.95,1,American Express - 1004,,,Redacto@gmail.com,02/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA174688127104),$19.95,$1.20,$21.15,FALSE,,FALSE,Redact,USD,
02/21/21,113-4409251-2110608,"Nespresso Capsules VertuoLine, Arondio Gran Lungo Americano, Medium Roast Coffee, 30 Count Coffee Pods, Brews 5.0oz",COFFEE,B07M98S5L5,50201706,Amazon.com,,new,Amazon.com,,$30.00,$30.00,1,American Express - 1004,,,Redacto@gmail.com,02/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA174688127104),$30.00,$0.00,$30.00,,,,Redact,USD,
02/21/21,111-5371847-4605808,"Ywlake Magnetic Poster Hanger, 32x24 32x48 32x23 Poster Frame Wood Wooden Magnet Print Posters Dowel Scroll Hanger Hangers Hanging Kit for Walls Map Canvas Artwork (Black, 32"")",WALL_ART,B07RGVLCLD,60121000,Amazon.com,,new,Ywlake,,$0.00,$27.99,1,American Express - 2008,,,Redacto@gmail.com,02/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z05R2R60391799407),$27.99,$1.68,$29.67,FALSE,,FALSE,Redact,USD,
02/22/21,113-1980501-2311443,"Nespresso VertuoLine Espresso, Altissio",COFFEE,B0768VHJP5,50201706,Amazon.com,,new,Amazon.com,,$42.50,$42.50,1,American Express - 1004,,,Redacto@gmail.com,02/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA176220905404),$42.50,$0.00,$42.50,,,,Redact,USD,
02/23/21,111-4891654-2511407,"Foot Peel Mask - for Cracked Heels, Dead Skin & Calluses - Make Your Feet Baby Soft & Get a Smooth Skin, Removes & Repairs Rough Heels, Dry Toe Skin - Exfoliating Peeling Treatment (2 Pack)",SKIN_TREATMENT_MASK,B078WCTVV9,53131600,Amazon.com,,new,eCom Heights,,$0.00,$29.99,1,American Express - 2008,,,Redacto@gmail.com,02/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA176770500804),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
02/24/21,112-9940526-4495446,"Husky 25"" Tape Measure Metallic Red",TAPE_MEASURE,B07KJTL958,27110000,Amazon.com,,new,"LTS Tools, Inc",,$0.00,$16.99,1,American Express - 1004,,,Redacto@gmail.com,02/25/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,USPS(9300120111405884561966),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
02/25/21,112-9451527-8397836,Matty Matheson: Home Style Cookery: A Home Cookbook,ABIS_BOOK,1419747487,55101500,Amazon.com,2020-09-29 0:00:01,new,Amazon.com,,$35.00,$17.50,1,American Express - 1004,,,Redacto@gmail.com,02/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA179466205404),$17.50,$1.05,$18.55,FALSE,,FALSE,Redact,USD,
02/28/21,112-7526756-0533814,Herm Sprenger Black Stainless Steel Prong Dog Training Collar with Swivel Ultra-Plus Pet Pinch Collar No-Pull Collar for Dogs Anti Pull Training Collar Made in Germany 2.25mm x 16in,ANIMAL_COLLAR,B07VMNFVQN,10111300,Amazon.com,,new,"NG, Inc.",,$0.00,$31.93,1,American Express - 1004,,,Redacto@gmail.com,02/28/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA182055522004),$31.93,$1.92,$33.85,FALSE,,FALSE,Redact,USD,
02/28/21,112-6070738-2471427,"Redbarn 4lb Dog Food Roll (Lamb, Beef, and Chicken) | Natural Ingredients with Added Vitamins & Minerals - Shelf Stable Food, Topper or Training Reward | Made in USA",PET_FOOD,B00008DFOG,10121806,Amazon.com,,new,Only Natural Pet,,$9.86,$20.79,5,American Express - 1004,,,Redacto@gmail.com,03/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,FedEx(784237840283),$103.95,$6.25,$110.20,FALSE,,FALSE,Redact,USD,
02/28/21,112-6810035-5618646,"Cramer Team Color Athletic Tape, Easy Tear Tape for Ankle, Wrist, & Injury Taping, Protect & Prevent Injuries, Promote Healing, Athletic Training Supplies, 1.5"" X 10 Yard Roll, Colored AT Tape",WOUND_DRESSING,B00592846Q,42311500,Amazon.com,,new,Amazon.com,,$5.99,$5.99,1,American Express - 1004,,,Redacto@gmail.com,02/28/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA182199148304),$5.99,$0.36,$6.35,FALSE,,FALSE,Redact,USD,
02/28/21,112-6810035-5618646,Starmark Swing 'n Fling DuraFoam Ball Dog Toy,PET_TOY,B003YHB8D4,10111300,Amazon.com,,new,Amazon.com,,$12.99,$12.99,2,American Express - 1004,,,Redacto@gmail.com,02/28/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA182199148304),$25.98,$1.56,$27.54,FALSE,,FALSE,Redact,USD,
03/01/21,112-1353920-6888266,"HARRIS Indoor Window Fly Strip, 12 Pack Sticky Traps Kills Flies",PEST_CONTROL_DEVICE,B0842Y7SFK,10191703,Amazon.com,,new,PF Harris,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,03/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,ANN ARBOR,MI,48108-3326,Shipped,AMZN_US(TBA182829659404),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
03/01/21,113-4488574-0877819,"VASAGLE File Cabinet, Mobile Filing Cabinet with Wheels, 2 Drawers, Open Shelf, for A4, Letter Size, Hanging File Folders, Rustic Brown and Black UOFC71X",CABINET,B086W2HHR7,44000000,Amazon.com,,new,Songmics Direct,,$0.00,$119.99,1,American Express - 2008,,,Redacto@gmail.com,03/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,FedEx-SLM(784260834220),$119.99,$7.20,$127.19,FALSE,,FALSE,Redact,USD,
03/01/21,113-9763539-6971450,"11x14 Picture Frame Set of 9, Black Frames for Photos 8x10 with Mat or 11x14 Without Mat, Table Top and Wall Mounting Decor",PICTURE_FRAME,B08GLS6R6Y,60121400,Amazon.com,,new,AmazingB2C,,$0.00,$49.04,1,American Express - 2008,,,Redacto@gmail.com,03/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA183904188304),$49.04,$2.94,$51.98,FALSE,,FALSE,Redact,USD,
03/02/21,113-6278546-6912257,"Walker Edison Andre Modern Solid Wood Dining Table, 72 Inch, Mahogany",TABLE,B07G62YLVS,56101543,Amazon.com,,new,Amazon.com,,$559.00,$322.00,1,American Express - 2008,,,Redacto@gmail.com,03/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z90W4F60371815395),$322.00,$19.32,$341.32,FALSE,,FALSE,Redact,USD,
03/02/21,113-8019527-4034632,"ZINUS Josh Loveseat Sofa / Easy, Tool-Free Assembly, Beige",SOFA,B07GGF92DM,56101502,Amazon.com,,new,Amazon.com,,$0.00,$462.85,1,American Express - 2008,,,Redacto@gmail.com,03/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA307423421000),$462.85,$27.77,$490.62,FALSE,,FALSE,Redact,USD,
03/04/21,113-2981083-7740234,Herm Sprenger Black Stainless Steel Prong Collar for Dogs 3.2mm 23in (58 cm) with O Ring,ANIMAL_COLLAR,B004C2RBLI,10141600,Amazon.com,,new,Exclusive Dog Supplies Inc.,,$42.49,$49.30,1,American Express - 1004,,,Redacto@gmail.com,03/04/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z16A94A0337609798),$49.30,$2.96,$52.26,FALSE,,FALSE,Redact,USD,
03/05/21,111-4469200-0085823,"DR.NATURE Set of 2 Boho Throw Pillow Covers for Couch Sofa, 18 x 18 Inch Cotton Hand-Woven Tufted Decorative Pillows Covers Bedroom Living Room, Farmhouse Pillow Case with Tassels (Yellow, 18"" x 18"")",DECORATIVE_PILLOW_COVER,B08JJ8GC18,52121512,Amazon.com,,new,MUBIN LLC,,$0.00,$26.99,1,American Express - 2008,,,Redacto@gmail.com,03/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA187490503304),$26.99,$1.62,$28.61,FALSE,,FALSE,Redact,USD,
03/05/21,111-4469200-0085823,IntelliLeash Intelli-Stayk with Special Surface-Lock Technology to Prevent Pulling Out and Bending,PET_SUPPLIES,B015QEBTHW,10111300,Amazon.com,,new,GoodBuys4Me,,$0.00,$19.99,1,American Express - 2008,,,Redacto@gmail.com,03/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA187490503304),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
03/05/21,111-4469200-0085823,Amazon Basics Tie-Out Cable for Dogs,LEASH,B071R2X8GG,10111300,Amazon.com,,new,Amazon.com,,$9.99,$9.99,1,American Express - 2008,,,Redacto@gmail.com,03/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA187490503304),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
03/08/21,111-2175265-6614655,,,B08RY477D9,,Amazon.com,,new,Suibiim,,$0.00,$10.99,1,American Express - 2008,,,Redacto@gmail.com,03/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA191831097604),$10.99,$0.66,$11.65,FALSE,,FALSE,Redact,USD,
03/18/21,111-0061399-7214603,Liquid I.V. Hydration Multiplier - Lemon Lime - Hydration Powder Packets | Electrolyte Drink Mix | Easy Open Single-Serving Stick | Non-GMO | 16 Sticks,NUTRITIONAL_SUPPLEMENT,B01IT9NLHW,53131600,Amazon.com,,new,Liquid I.V.,,$24.99,$23.98,1,American Express - 2008,,,Redacto@gmail.com,03/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA002585873704),$23.98,$0.00,$23.98,,,,Redact,USD,
03/18/21,111-0061399-7214603,"unenow Unisex Non Slip Grip Socks with Cushion for Yoga, Pilates, Barre, Home & Hospital",SOCKS,B07YFH98LG,53102402,Amazon.com,,new,Unenow,,$0.00,$16.99,1,American Express - 2008,,,Redacto@gmail.com,03/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA002585873704),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
03/21/21,112-1495599-2093851,Epsoak Epsom Salt 19 lb. Bulk Bag Magnesium Sulfate USP,BATHWATER_ADDITIVE,B004N7DQHA,53131600,Amazon.com,,new,San Francisco Salt Company,,$0.00,$26.99,1,American Express - 1004,,,Redacto@gmail.com,03/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA005025447004),$26.99,$1.62,$28.61,FALSE,,FALSE,Redact,USD,
03/21/21,112-1495599-2093851,"Coach Soak: Muscle Recovery Bath Soak - Natural Magnesium Muscle Relief & Joint Soother -21 Minerals, Essential Oils & Dead Sea Bath Salts-Absorbs Faster Than Epsom Salt for Soaking (Calming Lavender)",BATHWATER_ADDITIVE,B07W4M3797,53131600,Amazon.com,,new,Coach Soak,,$0.00,$24.95,1,American Express - 1004,,,Redacto@gmail.com,03/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA005025447004),$24.95,$1.50,$26.45,FALSE,,FALSE,Redact,USD,
03/21/21,112-9425384-8401848,Can't Hurt Me: Master Your Mind and Defy the Odds - Clean Edition,ABIS_BOOK,1544507879,55101500,Amazon.com,2020-06-02 0:00:01,new,Amazon.com,,$24.99,$20.56,1,American Express - 2008,,,Redacto@gmail.com,03/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA005252424204),$20.56,$1.23,$21.79,FALSE,,FALSE,Redact,USD,
03/27/21,112-8132689-7061812,"Nespresso VertuoLine Espresso, Altissio",COFFEE,B0768VHJP5,50201706,Amazon.com,,new,Amazon.com,,$42.50,$42.46,1,American Express - 1004,,,Redacto@gmail.com,03/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA011632793104),$42.46,$0.00,$42.46,,,,Redact,USD,
03/27/21,112-8132689-7061812,"Nespresso Capsules VertuoLine, Arondio Gran Lungo Americano, Medium Roast Coffee, 30 Count Coffee Pods, Brews 5.0oz",COFFEE,B07M98S5L5,50201706,Amazon.com,,new,Amazon.com,,$30.00,$30.00,1,American Express - 1004,,,Redacto@gmail.com,03/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA011632793104),$30.00,$0.00,$30.00,,,,Redact,USD,
04/05/21,113-3043295-3702625,"Nespresso Capsules VertuoLine, Barista Flavored Pack, Mild Roast Coffee, 30 Count Coffee Pods, Brews 7.77 Ounce",COFFEE,B0851ZQCX6,50201706,Amazon.com,,new,Amazon.com,,$33.00,$33.00,1,American Express - 2008,,,Redacto@gmail.com,04/06/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA022126850204),$33.00,$0.00,$33.00,,,,Redact,USD,
04/19/21,113-6296482-0324205,The Working Dog Vol. I. - A Complete Anthology of the Breeds,ABIS_BOOK,1445526697,55100000,Amazon.com,2010-11-18 0:00:01,new,Amazon.com,,$35.99,$16.02,1,American Express - 2008,,,Redacto@gmail.com,04/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z2X655F0291770730),$16.02,$0.96,$16.98,FALSE,,FALSE,Redact,USD,
04/28/21,111-6374445-5794600,Bsubseach Women's Long Beach Kimono Cardigan Open Front Bikini Swimsuit Cover Ups with Drawstring,APPAREL,B07MNRNF3Z,53101700,Amazon.com,,new,Bsubseach,,$0.00,$24.99,1,American Express - 2008,,,Redacto@gmail.com,05/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z8Y82R30236858679),$24.99,$1.50,$26.49,FALSE,,FALSE,Redact,USD,
04/28/21,111-6328493-4449041,ZAFUL Women's Strapless Solid Color 2 Pieces Swimsuit Bandeau Bikini Bathing Suit,SWIMWEAR,B07NPXC9DZ,53100000,Amazon.com,,new,ZAFUL US,,$49.99,$31.99,1,American Express - 2008,,,Redacto@gmail.com,04/29/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA045254768204),$31.99,$1.92,$33.91,FALSE,,FALSE,Redact,USD,
04/28/21,111-9058080-8327416,"GOBAM Large Bath Mat Shower Mat Bathroom Floor Mat for Spa Tub and Kitchen, Bamboo (26 x 15.8 x 1.3 inches)",BATHTUB_SHOWER_MAT,B01K48SDME,52101500,Amazon.com,,new,gobam,,$0.00,$41.99,1,American Express - 2008,,,Redacto@gmail.com,04/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA046666074504),$41.99,$2.52,$44.51,FALSE,,FALSE,Redact,USD,
04/28/21,111-4164429-9469045,"Bamboo Bathtub Tray Caddy - Expandable Bath Tray - Adjustable Organizer Tray for Bathroom - Luxury Bath Caddy Tub Table, Bathtub Accessories & Bathroom Gadgets (Natural)",LAP_DESK,B0109CZDFU,56141800,Amazon.com,,new,Home Sleek Home,,$0.00,$34.99,1,American Express - 2008,,,Redacto@gmail.com,05/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1ZX99A741200931437),$34.99,$2.10,$37.09,FALSE,,FALSE,Redact,USD,
05/02/21,112-9936738-5678626,Oribe Serene Scalp Anti-Dandruff Shampoo,SHAMPOO,B0781Z7R88,53131628,Amazon.com,,new,Amazon.com,,$46.00,$45.00,1,American Express - 1004,,,Redacto@gmail.com,05/04/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA050648248704),$45.00,$4.05,$49.05,FALSE,,FALSE,Redact,USD,
05/02/21,112-9936738-5678626,"PILOT Precise V5 Stick Liquid Ink Rolling Ball Stick Pens, Extra Fine Point (0.5mm) Black, 14-Pack (15403)",WRITING_INSTRUMENT,B089X5MDV7,44121701,Amazon.com,,new,Amazon.com,,$22.00,$16.93,1,American Express - 1004,,,Redacto@gmail.com,05/04/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA050648248704),$16.93,$1.52,$18.45,FALSE,,FALSE,Redact,USD,
05/02/21,112-9936738-5678626,"Stone Lain Coupe Dinnerware Set, Service For 4, Black Matte, Matte Black",DISHWARE_PLACE_SETTING,B07XKXQL79,52150000,Amazon.com,,new,Amazon.com,,$59.99,$59.99,1,American Express - 1004,,,Redacto@gmail.com,05/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA049799463404),$59.99,$5.40,$65.39,FALSE,,FALSE,Redact,USD,
05/02/21,112-3901702-8846667,"Stainless Steel Rust Proof Kitchen Dish Drying Rack, Draining Dish Rack with Black Drainboard, A Wide Utensil Holder KINDR002B-9",DRYING_RACK,B08T5XYPLW,52150000,Amazon.com,,new,KINFAUCET,,$0.00,$32.99,1,American Express - 1004,,,Redacto@gmail.com,05/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,USPS(9361289676091381214862),$32.99,$2.97,$35.96,FALSE,,FALSE,Redact,USD,
05/02/21,112-3901702-8846667,"Anthony Facial Scrub, 8 Fl Oz, Contains Aloe Vera, Sand, Algae, Chamomile, Vitamin C, Soothes, Protects, Refreshes and Removes Dead Skin Cells",SKIN_EXFOLIANT,B00MRFOTHG,53131613,Amazon.com,,new,Anthony Skin,,$36.00,$40.00,1,American Express - 1004,,,Redacto@gmail.com,05/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA048894125804),$40.00,$3.60,$43.60,FALSE,,FALSE,Redact,USD,
05/02/21,112-3901702-8846667,"Cenbee Soap Dispensing Dish Brush with 4 Replaceable Heads, Updated No Leaking Soap Dish Scrubber Soap Dispenser, Kitchen Dish Brush with Soap Dispenser for Pot Pan Sink Cleaning(Blue)",CLEANING_BRUSH,B0925P428Q,47130000,Amazon.com,,new,Cenbee,,$0.00,$11.99,1,American Express - 1004,,,Redacto@gmail.com,05/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA049107435904),$11.99,$1.08,$13.07,FALSE,,FALSE,Redact,USD,
05/02/21,112-3901702-8846667,"Hiware 20-Piece Black Silverware Set with Tray, Stainless Steel Flatware Cutlery Set Service for 4, Kitchen Black Utensils Tableware Set for Home Restaurant, Mirror Finish, Dishwasher Safe",FLATWARE,B089YSQTT8,52151700,Amazon.com,,new,HiwareDirect,,$50.00,$29.99,1,American Express - 1004,,,Redacto@gmail.com,05/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA048894125804),$29.99,$2.70,$32.69,FALSE,,FALSE,Redact,USD,
05/02/21,112-9765455-3029808,"Dawn Powerwash Starter Kits, Dish Spray, Dish Detergent, Fresh Scent Bundle, Spray Bottle, 2 Refills…",CLEANING_AGENT,B087ZRVK2X,47131810,Amazon.com,,new,BAYSIDE ONLINE,,$19.99,$16.48,1,American Express - 1004,,,Redacto@gmail.com,05/04/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,FedEx(507449621461),$16.48,$1.48,$17.96,FALSE,,FALSE,Redact,USD,
05/06/21,112-4596495-9946645,Athletic Smelling Salts - Aqua - Twist & Sniff! - Pre-Activated with 100's of Uses Per Bottle - Ammonia Inhalant - Smelling Salt for Weightlifting - by AmmoniaSport,BATHWATER_ADDITIVE,B07MBHXMPL,53131600,Amazon.com,,new,JD&R,,$0.00,$13.95,1,American Express - 1004,,,Redacto@gmail.com,05/07/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,CHICAGO,IL,60657-2912,Shipped,AMZN_US(TBA053283270904),$13.95,$1.43,$15.38,FALSE,,FALSE,Redact,USD,
05/10/21,112-6791718-6813068,"simplehuman 50 Liter / 13 Gallon Semi-Round Kitchen Step Trash Can, Black",TRASH_CAN,B003VWMS5E,47121702,Amazon.com,,new,Amazon.com,,$49.99,$49.98,1,American Express - 1004,,,Redacto@gmail.com,05/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z6V60W30328188674),$49.98,$3.00,$52.98,FALSE,,FALSE,Redact,USD,
05/10/21,112-6791718-6813068,"simplehuman Code P Custom Fit Drawstring Trash Bags in Dispenser Packs, 50-60 Liter / 13.2-15.9 Gallon, White – 60 Liners",WASTE_BAG,B010B2LM4Y,47121701,Amazon.com,,new,Amazon.com,,$27.00,$27.00,1,American Express - 1004,,,Redacto@gmail.com,05/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA058257792404),$27.00,$1.62,$28.62,FALSE,,FALSE,Redact,USD,
05/11/21,111-5297893-0947444,"BENTON Aloe BHA Skin Toner 200ml (6.76 fl. oz.) - Facial Toner for Sensitive Skin with Aloe and BHA, Hydrating Toner for Dry & Rough Skin, Soothing Toner to Maintain Oil-Water Balance, Tighten Pores & Control Sebum",ASTRINGENT_SUBSTANCE,B00GAOBG3A,53131600,Amazon.com,,new,Unicare Products,,$19.00,$16.50,1,American Express - 2008,,,Redacto@gmail.com,05/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA058162807204),$16.50,$0.99,$17.49,FALSE,,FALSE,Redact,USD,
05/11/21,111-1747886-3858661,"ETUDE HOUSE SoonJung 2x Barrier Intensive Cream 60ml (Old Version)- Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Panthenol and Madecassoside Heals Damaged Skin",SKIN_MOISTURIZER,B072L2344Z,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$13.90,1,American Express - 2008,,,Redacto@gmail.com,05/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA058257792404),$13.90,$0.83,$14.73,FALSE,,FALSE,Redact,USD,
05/11/21,112-5484447-3087461,Fullmetal Alchemist Complete Box Set (Fullmetal Alchemist Boxset),ABIS_BOOK,1421541955,55101500,Amazon.com,2011-11-01 0:00:01,new,Amazon.com,,$219.99,$192.14,1,American Express - 1004,,,Redacto@gmail.com,05/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA058602707504),$192.14,$17.29,$209.43,FALSE,,FALSE,Redact,USD,
05/11/21,111-6150112-0957029,Liquid I.V. Hydration Multiplier - Lemon Lime - Hydration Powder Packets | Electrolyte Drink Mix | Easy Open Single-Serving Stick | Non-GMO | 16 Sticks,NUTRITIONAL_SUPPLEMENT,B01IT9NLHW,53131600,Amazon.com,,new,Liquid I.V.,,$24.99,$23.98,1,American Express - 2008,,,Redacto@gmail.com,05/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA330125048000),$23.98,$0.00,$23.98,,,,Redact,USD,
05/12/21,112-5648928-6796242,,,B07PRTFPHJ,,Amazon.com,,new,Cam N-Hut Descendants,,$0.00,$17.66,1,American Express - 1004,,,Redacto@gmail.com,05/13/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA059198700504),$17.66,$0.00,$17.66,,,,Redact,USD,
05/13/21,112-7326367-3355461,"simplehuman Code P Custom Fit Drawstring Trash Bags in Dispenser Packs, 50-60 Liter / 13.2-15.9 Gallon, White – 60 Liners",WASTE_BAG,B010B2LM4Y,47121701,Amazon.com,,new,Amazon.com,,$27.00,$26.32,1,American Express - 1004,,,Redacto@gmail.com,05/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA060665298804),$26.32,$2.37,$28.69,FALSE,,FALSE,Redact,USD,
05/13/21,112-2403543-2089042,"simplehuman 50 Liter / 13 Gallon Semi-Round Kitchen Step Trash Can, Black",TRASH_CAN,B003VWMS5E,47121702,Amazon.com,,new,Amazon.com,,$49.99,$49.98,1,American Express - 1004,,,Redacto@gmail.com,05/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,USPS(9361289676091383617678),$49.98,$4.50,$54.48,FALSE,,FALSE,Redact,USD,
05/13/21,112-6083555-9417022,"TERRO T3206SR Non-Toxic Indoor Spider, Ant, Cockroach, Centipede, and Crawling Insect Trap - 12 Traps",PEST_CONTROL_DEVICE,B07Q697VZK,10190000,Amazon.com,,new,Amazon.com,,$9.89,$9.89,1,American Express - 1004,,,Redacto@gmail.com,05/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA060239390104),$9.89,$0.89,$10.78,FALSE,,FALSE,Redact,USD,
05/14/21,112-3614958-8697064,"Gulrear Portable AC Window Kit for Vertical Window Portable Air Conditioner window seal parts Adjustable Length from 20"" to 55"" Sliding Window AC Vent Kit Fit for Exhaust Hose with 5.0""/13cm Diameter",HARDWARE_TUBING,B07RR79J3Q,40142000,Amazon.com,,new,gulrear,,$0.00,$37.99,1,American Express - 1004,,,Redacto@gmail.com,05/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA060727690104),$37.99,$3.42,$41.41,FALSE,,FALSE,Redact,USD,
05/19/21,111-0064448-3305024,"AMADA HOMEFURNISHING Floating Shelves Black, Wall Shelves with Invisible Brackets for Bedroom, Bathroom, Living Room, Kitchen, Set of 3 - AMFS07",SHELF,B088ZW8427,24102000,Amazon.com,,new,HappyhomeUS,,$36.99,$32.98,1,American Express - 2008,,,Redacto@gmail.com,05/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA066781320304),$32.98,$1.98,$34.96,FALSE,,FALSE,Redact,USD,
05/19/21,111-0064448-3305024,"Light & Pro White Kitchen Curtains, Rod Pocket Curtains, Dining Room Curtains, Cotton Curtain Window Panel, Curtains Wide 50 Inch, Cotton Reverse Tab Top Curtain Panel - 50x108 Inch- Set of 2 Panels",CURTAIN,B079TZX313,52130000,Amazon.com,,new,Lightnpro,,$49.99,$31.99,3,American Express - 2008,,,Redacto@gmail.com,05/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA066781320304),$95.97,$5.76,$101.73,FALSE,,FALSE,Redact,USD,
05/19/21,114-5640682-4062617,"Gooji 4x6 Golden Greenery Thank You Cards (Bulk 36-Pack) Gold Foil, Matching Peel-and-Seal White Envelopes | Assorted Set, Watercolor, Colorful Graphics | Birthday Party, Baby Shower, Weddings",GREETING_CARD,B07RW8ZNV6,14111605,Amazon.com,,new,Gooji LLC,,$20.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,05/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA066781320304),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
05/20/21,111-7741113-0178604,PopSockets: PopGrip with Swappable Top for Phones and Tablets - Black,CELL_PHONE_GRIP,B07P29XQR4,43191610,Amazon.com,,new,KMKB Online Sales,,$9.99,$9.97,1,American Express - 2008,,,Redacto@gmail.com,05/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA069016651304),$9.97,$0.60,$10.57,FALSE,,FALSE,Redact,USD,
05/20/21,111-1720848-9363459,"Wellmet 6-Light Farmhouse Chandelier 38 Inch, Farmhouse Light Fixture for Dining Room, Rustic Industrial Iron Chandeliers Lighting Black for Foyer, Living Room, Kitchen Island, Bedroom",LIGHT_FIXTURE,B07RVFD6NT,39111500,Amazon.com,,new,Funkysunstore,,$0.00,$113.99,1,American Express - 2008,,,Redacto@gmail.com,05/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA068635803304),$113.99,$6.84,$120.83,FALSE,,FALSE,Redact,USD,
05/20/21,111-1720848-9363459,"LNC Black Farmhouse Chandelier for Dining Room, Modern French Country Chandelier, 2-Tier 9-Light Foyer Light Fixture Hanging",LIGHT_FIXTURE,B08DTH4M63,39110000,Amazon.com,,new,LNCHOME,,$0.00,$179.99,1,American Express - 2008,,,Redacto@gmail.com,05/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA068495844604),$179.99,$10.80,$190.79,FALSE,,FALSE,Redact,USD,
05/24/21,114-8696690-9253028,"Paul Mitchell Pro Tools Detangler Comb, Wide Tooth Comb Detangles Wet or Dry Hair Milky White",HAIR_COMB,B001VGA4P8,53131600,Amazon.com,,new,Amazon.com,,$4.00,$4.00,1,American Express - 1004,,,Redacto@gmail.com,05/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA070736264504),$4.00,$0.36,$4.36,FALSE,,FALSE,Redact,USD,
05/24/21,114-8696690-9253028,Oribe Serene Scalp Soothing Leave-On Treatment 1.7 Fl Oz (Pack of 1),CONDITIONER,B078218XML,53131602,Amazon.com,,new,Amazon.com,,$48.00,$48.00,1,American Express - 1004,,,Redacto@gmail.com,05/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA070736264504),$48.00,$4.32,$52.32,FALSE,,FALSE,Redact,USD,
05/24/21,114-8696690-9253028,Oribe Serene Conditioner,CONDITIONER,B0781Z84JZ,53131602,Amazon.com,,new,Amazon.com,,$48.00,$48.00,1,American Express - 1004,,,Redacto@gmail.com,05/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA070736264504),$48.00,$4.32,$52.32,FALSE,,FALSE,Redact,USD,
05/24/21,114-8696690-9253028,Oribe Serene Scalp Exfoliating Scrub 4.2 fl.oz,SKIN_EXFOLIANT,B07VFCWTHJ,53131600,Amazon.com,,new,Amazon.com,,$52.00,$52.00,1,American Express - 1004,,,Redacto@gmail.com,05/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA070736264504),$52.00,$4.68,$56.68,FALSE,,FALSE,Redact,USD,
05/25/21,111-3547464-5529036,"Preston Black and White Houndstooth Dog Collar, Medium and Large Dogs, 1 1/4 Inch Width",ANIMAL_COLLAR,B07JYTKLK9,10000000,Amazon.com,,new,Dragonfly Distributing,,$0.00,$21.95,1,American Express - 1004,,,Redacto@gmail.com,05/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA071911083704),$21.95,$1.32,$23.27,FALSE,,FALSE,Redact,USD,
05/27/21,111-8685972-1189029,"DlandHome 63 inches X-Large Computer Desk, Composite Wood Board, Decent and Steady Home Office Desk/Workstation/Table, BS1-160BB Black Walnut and Black Legs, 1 Pack",DESK,B0785JKGZP,56101700,Amazon.com,,new,DlandHome,,$0.00,$99.99,1,American Express - 2008,,,Redacto@gmail.com,05/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z3Y55R10334813518),$99.99,$6.00,$105.99,FALSE,,FALSE,Redact,USD,
05/31/21,111-7391999-5216249,"BENTOBEN iPhone 11 Pro Case, Slim Silicone 360° Ring Holder Kickstand Support Car Mount Microfiber Liner Shockproof Non-Slip Case for iPhone 11 Pro 5.8""-Midnight Green",CELLULAR_PHONE_CASE,B088618HCK,43191601,Amazon.com,,new,U-geek,,$16.99,$9.99,1,American Express - 2008,,,Redacto@gmail.com,06/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA079245958304),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
06/07/21,112-6146626-3110656,SHAMAN KING Omnibus 2 (Vol. 4-6),ABIS_BOOK,1646512057,55101500,Amazon.com,2021-06-08 0:00:01,new,Amazon.com,,$19.99,$17.99,1,American Express - 1004,,,Redacto@gmail.com,06/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA085394157004),$17.99,$1.62,$19.61,FALSE,,FALSE,Redact,USD,
06/07/21,112-5161781-9873821,"Chef Knife PAUDIN N1 8 inch Kitchen Knife, German High Carbon Stainless Steel Sharp Knife, Professional Meat Knife with Ergonomic Handle and Gift Box for Family & Restaurant",KITCHEN_KNIFE,B07BK4YVB3,52151702,Amazon.com,,new,PaudinStore,,$35.99,$20.79,1,American Express - 1004,,,Redacto@gmail.com,06/07/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA084686421804),$20.79,$1.87,$22.66,FALSE,,FALSE,Redact,USD,
06/07/21,112-5161781-9873821,"COSORI Air Fryer Oven Combo 5.8QT Max Xl Large Cooker (Cookbook with 100 Recipes), One-Touch Screen with 11 Precise Presets and Shake Reminder, Nonstick and Dishwasher-Safe Square Design Basket, Black",AIR_FRYER,B07GJBBGHG,52140000,Amazon.com,,new,Amazon.com,,$119.99,$119.99,1,American Express - 1004,,,Redacto@gmail.com,06/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA085214008604),$119.99,$10.80,$130.79,FALSE,,FALSE,Redact,USD,
06/07/21,112-7063021-4148248,"Reynolds Wrap Aluminum Foil, 200 Square Feet",FOOD_WRAP,B00UNT0Y2M,48102100,Amazon.com,,new,Amazon.com,,$10.03,$11.29,1,American Express - 1004,,,Redacto@gmail.com,06/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA085394157004),$11.29,$1.02,$12.31,FALSE,,FALSE,Redact,USD,
06/07/21,111-6817217-2061812,"Amscan Plastic Cups Big Party Pack , 18 oz. 50ct., Gold",DRINKING_CUP,B004UUK2ZY,52152100,Amazon.com,,new,Amazon.com,,$9.10,$15.62,1,American Express - 1004,,,Redacto@gmail.com,06/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA086194615004),$15.62,$0.94,$16.56,FALSE,,FALSE,Redact,USD,
06/07/21,111-8874554-9460205,"PRO-SPIN Ping Pong Balls - White 3-Star 40+ Table Tennis Balls (Pack of 12,24) | High-Performance ABS Training Balls | Ultimate Durability for Indoor / Outdoor Ping Pong Tables, Competitions, Games",RECREATION_BALL,B078XLCMRD,49000000,Amazon.com,,new,PRO-SPIN® SPORTS,,$14.99,$12.95,1,American Express - 1004,,,Redacto@gmail.com,06/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA085009490904),$12.95,$0.78,$13.73,FALSE,,FALSE,Redact,USD,
06/08/21,112-5940930-0645859,"NANDO'S PERI-PERI Medium - Heat Flavorful Hot Sauce, Gluten Free, Non GMO, Kosher (1 Litre)",SAUCE,B07KTBZ6ZM,50000000,Amazon.com,,new,BMATraders,,$0.00,$19.94,1,American Express - 1004,,,Redacto@gmail.com,06/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA086476043204),$19.94,$0.00,$19.94,,,,Redact,USD,
06/13/21,112-5228978-0317854,"Genuine Winix 116130 Replacement Filter H for 5500-2 Air Purifier , White",HVAC_AIR_FILTER,B01JLT1OR8,40161505,Amazon.com,,new,Amazon.com,,$79.99,$59.63,2,American Express - 1004,,,Redacto@gmail.com,06/13/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA090935526304),$119.26,$7.16,$126.42,FALSE,,FALSE,Redact,USD,
06/13/21,112-7229574-6055460,Cherry Republic Hot Cherry Salsa - High Heat Salsa Mix with Authentic Michigan Cherries - Hot & Spicy Fruit Salsa - Works Great as a Recipe Ingredient & Dip - 2 x 16 Ounces,SAUCE,B07JGPTFQF,50000000,Amazon.com,,new,Airaco,,$0.00,$31.99,1,American Express - 1004,,,Redacto@gmail.com,06/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA091969614504),$31.99,$0.00,$31.99,,,,Redact,USD,
06/13/21,112-7229574-6055460,Anchor Original Chicken Chippy Salt 170g,CULINARY_SALT,B00KO2I0D8,50000000,Amazon.com,,new,TopLife Co.,,$0.00,$10.11,1,American Express - 1004,,,Redacto@gmail.com,06/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA091969614504),$10.11,$0.00,$10.11,,,,Redact,USD,
06/13/21,112-7975823-5251426,"Doc Johnson Good Head Oral Delight Gel, Wild Cherry, 4 Ounce, assorted",HEALTH_PERSONAL_CARE,B000PR9UC2,53131600,Amazon.com,,new,Amazon.com,,$8.07,$8.07,1,American Express - 1004,,,Redacto@gmail.com,06/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA091455696204),$8.07,$0.48,$8.55,FALSE,,FALSE,Redact,USD,
06/13/21,112-9847625-8877843,"Fairlife Core Power Elite 42g High Protein Milk Shakes, Ready to Drink for Workout Recovery, Chocolate, 14 Fl Oz (Pack of 12)",PROTEIN_DRINK,B01DDIRDZA,50500000,Amazon.com,,new,Amazon.com,,$35.88,$35.88,2,American Express - 1004,,,Redacto@gmail.com,06/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA091969614504),$71.76,$0.00,$71.76,,,,Redact,USD,
06/14/21,111-3617710-9947469,10Ft 1 Piece White Chiffon Table Runners 27x120 Inches Sheer Bridal Party Romantic Wedding Reception Decorations,TABLECLOTH,B07C3M3D4J,52121604,Amazon.com,,new,QueenDream,,$0.00,$8.80,1,American Express - 1004,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z099W0V1303593519),$8.80,$0.53,$9.33,FALSE,,FALSE,Redact,USD,
06/14/21,111-3617710-9947469,PAVOI 14K Gold Plated 3mm Cubic Zirconia Classic Tennis Bracelet | Gold Bracelets for Women | Size 6.5-7.5 Inch,BRACELET,B07QWYL28P,54100000,Amazon.com,,new,PAVOI Jewelry,,$59.99,$14.95,1,American Express - 1004,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z099W0V1303593519),$14.95,$0.90,$15.85,FALSE,,FALSE,Redact,USD,
06/14/21,111-9527841-3808258,"Brew Addicts 12oz Coffee Cups Combo - [60 Pack] White Insulated Disposable Hot Cups with Lids, Sleeves and Stirrers for Tea, Hot Chocolate. For To-Go Travel Mug, Parties",DRINKING_CUP,B07GX9NXDF,52150000,Amazon.com,,new,PB Trading,,$23.79,$19.83,1,American Express - 1004,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z099W0V1303593519),$19.83,$1.19,$21.02,FALSE,,FALSE,Redact,USD,
06/14/21,111-9527841-3808258,"100% Compostable 9 Inch Heavy-Duty Plates [125-Pack] Eco-Friendly Disposable White Bagasse Plate, Made of Natural Sugarcane Fibers - 9"" Biodegradable Paper Plates by Stack Man",DISHWARE_PLATE,B09454YVH2,52152000,Amazon.com,,new,Amazon.com,,$15.49,$15.49,1,American Express - 1004,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZL(TBA092775684704),$15.49,$0.93,$16.42,FALSE,,FALSE,Redact,USD,
06/14/21,111-8293637-8035441,"Dunkin' French Vanilla Flavored Coffee, 60 Keurig K-Cup Pods",COFFEE,B00XA4ID0M,50201700,Amazon.com,,new,Amazon.com,,$0.00,$38.44,1,American Express - 1004,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA342354297000),$38.44,$0.00,$38.44,,,,Redact,USD,
06/14/21,111-8293637-8035441,"Chrishine Life Bracelets for Women Inspirational Gifts for Her Teen Girls Personalized Mantra Cuff Birthday Christmas Jewelry, Comes with Box",BRACELET,B08BR47BC8,54100000,Amazon.com,,new,ChrishineLife,,$0.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA342354297000),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
06/15/21,112-0944359-0115446,"iRobot Authentic Replacement Parts- Clean Base Automatic Dirt Disposal Bags, 3-Pack, Compatible with All Clean Base models, White - 4640235",VACUUM_CLEANER_BAG,B07HJS45XQ,47121607,Amazon.com,,new,Amazon.com,,$16.99,$15.59,2,American Express - 1004,,,Redacto@gmail.com,06/16/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093506431504),$31.18,$1.88,$33.06,FALSE,,FALSE,Redact,USD,
06/15/21,112-0944359-0115446,"iRobot Authentic Replacement Parts Jet Hard Floor Cleaning Solution, Compatible with All Braava Robot Mop Accessory, Clear",ROBOTIC_VACUUM_CLEANER,B07RD8356H,47121602,Amazon.com,,new,Amazon.com,,$7.99,$6.77,2,American Express - 1004,,,Redacto@gmail.com,06/16/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093506431504),$13.54,$0.82,$14.36,FALSE,,FALSE,Redact,USD,
06/15/21,112-5976489-5065862,Airisland Finger Stretcher Hand Resistance Bands Hand Extensor Exerciser Finger Grip Strengthener Strength Trainer Gripper Set for Arthritis Carpal Tunnel Exercise Guitar and Rock Climbing 3pcs,HAND_STRENGTHENER,B071JL6PYZ,42251627,Amazon.com,,new,Airisland,,$0.00,$9.99,1,American Express - 2008,,,Redacto@gmail.com,06/16/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093506431504),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
06/15/21,111-4957190-6798631,"HongyiTime 400 PCS Push Pins,Thumb Tacks, Wall Tacks, Tacks, Push Pin,Thumbtack, Flat Push Pins, Thumbtacks Flat, Push Pins for Cork Board, Push Pins for Wall, Board Pins,Tacks and Push Pins (Black)",SHARP_PIN,B086PKD3F1,44122100,Amazon.com,,new,Redbean,,$0.00,$5.29,1,American Express - 2008,,,Redacto@gmail.com,06/16/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093506431504),$5.29,$0.32,$5.61,FALSE,,FALSE,Redact,USD,
06/15/21,111-3188997-0357010,"Artistic Weavers Dallas Vintage Medallion Area Rug,7'10"" x 10',White",RUG,B07TPPBVVK,52101500,Amazon.com,,new,Amazon.com,,$240.00,$139.49,1,American Express - 2008,,,Redacto@gmail.com,06/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1ZE48W150325136357),$139.49,$8.37,$147.86,FALSE,,FALSE,Redact,USD,
06/15/21,111-3507676-2693856,"Quartet Cork Board Bulletin Board, 2' x 3' Framed Corkboard, Oak Frame, Decorative Hanging Pin Board, Perfect for Office & Home Decor, Home School Message Board or Vision Board (35-380352)",PINBOARD,B000AMKKBC,44111907,Amazon.com,,new,Amazon.com,,$15.99,$20.76,1,American Express - 2008,,,Redacto@gmail.com,06/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z076AW90394798857),$20.76,$1.25,$22.01,FALSE,,FALSE,Redact,USD,
06/16/21,111-8747326-9026647,"GG Scandinavian Bran Crispbread All Natural Bran Cracker Packages, 5 count, 3.5-Ounce Packages (Pack of 5)",CRACKER,B003G1ZRTY,50181909,Amazon.com,,new,TheNewMall,,$41.25,$12.30,1,American Express - 2008,,,Redacto@gmail.com,06/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095135647804),$12.30,$0.00,$12.30,,,,Redact,USD,
06/17/21,111-9892131-0321869,"Razer Mamba Elite Wired Gaming Mouse: 16,000 DPI Optical Sensor - Chroma RGB Lighting - 9 Programmable Buttons - Mechanical Switches",INPUT_MOUSE,B07F816PH9,43211708,Amazon.com,,new,Amazon.com,,$89.99,$59.99,1,American Express - 1004,,,Redacto@gmail.com,06/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095135647804),$59.99,$3.60,$63.59,FALSE,,FALSE,Redact,USD,
06/17/21,111-5347465-3724246,10 Pieces 10Ft Chiffon Table Runners White Sheer Rustic Wedding Table Runner Elegant Bridal Party Decorations 27x120 Inches,TABLECLOTH,B07D2BQ8QF,52121604,Amazon.com,,new,QueenDream,,$0.00,$90.80,1,American Express - 1004,,,Redacto@gmail.com,06/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA096085803204),$90.80,$5.45,$96.25,FALSE,,FALSE,Redact,USD,
06/17/21,112-9077797-3700213,Doc Johnson GoodHead - Deep Throat Spray - Numbs Throat - Relaxes Gag Reflex - Wild Cherry - 2 fl. oz.(59 ml),HEALTH_PERSONAL_CARE,B006RNW61K,52120000,Amazon.com,,new,Amazon.com,,$10.40,$10.40,1,American Express - 1004,,,Redacto@gmail.com,06/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA096058836204),$10.40,$0.62,$11.02,FALSE,,FALSE,Redact,USD,
06/19/21,111-1833692-0340265,"Nongshim Shin Black Noodle Soup, Spicy, 3.5 Ounce (Pack of 6)",PACKAGED_SOUP_AND_STEW,B00BEIKRBY,50191500,Amazon.com,,new,Amazon.com,,$30.18,$16.86,1,American Express - 1004,,,Redacto@gmail.com,06/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA097869291204),$16.86,$0.00,$16.86,,,,Redact,USD,
06/20/21,112-4872279-5674633,"SportDOG Brand 425 Remote Trainers - 500 Yard Range E-Collar with Static, Vibrate and Tone - Waterproof, Rechargeable - Including New X-Series, Black (SD-425X)",ANIMAL_COLLAR,B07DZBN74T,10111300,Amazon.com,,new,Amazon.com,,$179.95,$159.95,1,American Express - 1004,,,Redacto@gmail.com,06/21/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA099537832104),$159.95,$9.60,$169.55,FALSE,,FALSE,Redact,USD,
06/21/21,111-7504830-6927431,Ivilon Drapery Window Curtain Rod - End Cap Style Design 1 Inch Pole. 72 to 144 Inch Color Black,HANGING_ROD,B06X9QCX9N,52131702,Amazon.com,,new,Nesheck2,,$58.38,$34.99,1,American Express - 2008,,,Redacto@gmail.com,06/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z1Y706A0369298075),$34.99,$2.10,$37.09,FALSE,,FALSE,Redact,USD,
06/21/21,111-8181229-0829806,"MUBIN Cotton Buffalo Plaid Rug 27.5 x 43 Inches Black and White Check Rugs Hand-Woven Indoor or Outdoor Rugs for Layered Door Mats Washable Carpet for Front Porch, Kitchen, Farmhouse, Entryway",RUG,B07ZMPTS4V,52101500,Amazon.com,,new,MUBIN LLC,,$38.00,$16.49,1,American Express - 2008,,,Redacto@gmail.com,06/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA102628632904),$16.49,$0.99,$17.48,FALSE,,FALSE,Redact,USD,
06/24/21,111-5449322-1801028,HOHIYA 12pc Table Number Holders 12 Inch Place Card Holder Stands Brass Gold Tall for Photos Food Signs Memo Notes Weddings Restaurants Birthdays Party,BOOK_DOCUMENT_STAND,B0826XBJCR,44111500,Amazon.com,,new,HOHIYA SHOP,,$24.99,$20.98,1,American Express - 1004,,,Redacto@gmail.com,06/25/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA105203665704),$20.98,$1.26,$22.24,FALSE,,FALSE,Redact,USD,
06/24/21,111-5449322-1801028,"Quartet Easel Stand, Collapsible, Portable Display Stand for Home School Supplies, Home Office Supply Tripod for Posters, Paintings, Art or White Boards, Base 63"" Max. Height, Supports 5 lbs. (29E)",ART_EASEL,B00006IAT6,60121200,Amazon.com,,new,Amazon.com,,$29.99,$14.89,1,American Express - 1004,,,Redacto@gmail.com,06/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA106157496004),$14.89,$0.89,$15.78,FALSE,,FALSE,Redact,USD,
06/25/21,111-1713550-8396252,Microsoft Xbox Wireless Controller + Wireless Adapter for Windows 10,VIDEO_GAME_CONTROLLER,B078T3R8YS,43211705,Amazon.com,,new,Novel Tech LLC,,$79.95,$99.99,1,American Express - 1004,,,Redacto@gmail.com,06/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA106299646104),$99.99,$6.00,$105.99,FALSE,,FALSE,Redact,USD,
06/26/21,111-2841525-3010604,"Hilife Steamer for Clothes Steamer, Handheld Garment Steamer Clothing Iron 240ml Big Capacity Upgraded Version",GARMENT_STEAMER,B07HF3X6Y4,52141600,Amazon.com,,new,Hilife Direct,,$68.99,$26.99,1,American Express - 2008,,,Redacto@gmail.com,06/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA107201786704),$26.99,$1.62,$28.61,FALSE,,FALSE,Redact,USD,
06/28/21,111-9734127-4117060,SlimMe High-Waisted Tummy Control Shaping Capri Leggings,PANTS,B00EARP8ZQ,53100000,Amazon.com,,new,JustMe Hosiery & Leggings,,$30.00,$19.50,1,American Express - 1004,,,Redacto@gmail.com,06/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA110562260804),$19.50,$1.17,$20.67,FALSE,,FALSE,Redact,USD,
06/28/21,111-9734127-4117060,Nebility Women Waist Trainer Shapewear Tummy Control Body Shaper Shorts Hi-Waist Butt Lifter Thigh Slimmer,UNDERGARMENT_THIGH_SLIMMER,B07QNQQYNJ,53100000,Amazon.com,,new,Nebility,,$34.99,$19.99,1,American Express - 1004,,,Redacto@gmail.com,06/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA110011765404),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
06/29/21,112-1317702-9531436,The Right Stuff Electrolyte Drink additive Std - Retail 10-Pouch Box - Orange Tangerine,HEALTH_PERSONAL_CARE,B00ICTW8TM,50202300,Amazon.com,,new,The Right Stuff® from NASA,,$28.99,$28.99,1,American Express - 2008,,,Redacto@gmail.com,06/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA110815824804),$28.99,$0.00,$28.99,,,,Redact,USD,
07/10/21,111-5835448-1092224,"Carry On, Warrior: The Power of Embracing Your Messy, Beautiful Life",ABIS_BOOK,1451698224,55101500,Amazon.com,2014-04-08 0:00:01,new,Amazon.com,,$17.00,$8.99,1,American Express - 2008,,,Redacto@gmail.com,07/11/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA121336107804),$8.99,$0.54,$9.53,FALSE,,FALSE,Redact,USD,
07/10/21,111-5894450-6861810,"AccuMed Pregnancy Test Strips, 25-Count Individually Wrapped Pregnancy Strips, Early Home Detection Pregnancy Test Kit, Clear HCG Test Results, Over 99% Accurate",MEDICAL_TEST_STRIP,B071YNWRPP,41110000,Amazon.com,,new,BNX AccuMed,,$0.00,$8.99,1,American Express - 2008,,,Redacto@gmail.com,07/11/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA121732303104),$8.99,$0.54,$9.53,FALSE,,FALSE,Redact,USD,
07/11/21,111-8120006-8493055,AllenCOCO Gold Plated Soild Sterling Silver Cubic Zirconia Stud Earrings for Women,EARRING,B07J4KYMSM,54100000,Amazon.com,,new,AllenCOCO jewelry,,$50.00,$17.99,1,American Express - 2008,,,Redacto@gmail.com,07/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA122887571204),$17.99,$1.08,$19.07,FALSE,,FALSE,Redact,USD,
07/14/21,111-7041329-6955436,"Mebbay Acrylic Jewelry Box with 4 Drawers, Velvet Jewelry Organizer for Earring Necklace Ring & Bracelet, Clear Jewelry Display Storage Case for Woman, Creamy White",JEWELRY_STORAGE,B088NRKQHH,44111500,Amazon.com,,new,Monpearl,,$0.00,$23.99,2,American Express - 2008,,,Redacto@gmail.com,07/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA126340180704),$47.98,$2.88,$50.86,FALSE,,FALSE,Redact,USD,
07/20/21,112-7502279-4296224,Breath: The New Science of a Lost Art,ABIS_BOOK,735213615,55101500,Amazon.com,2020-05-26 0:00:01,new,Amazon.com,,$28.00,$16.75,1,American Express - 1004,,,Redacto@gmail.com,07/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA133996343004),$16.75,$1.53,$18.28,FALSE,,FALSE,Redact,USD,
07/20/21,112-7502279-4296224,Relentless: From Good to Great to Unstoppable (Tim Grover Winning Series),ABIS_BOOK,1476714207,55101500,Amazon.com,2014-03-11 0:00:01,new,Amazon.com,,$17.00,$12.45,1,American Express - 1004,,,Redacto@gmail.com,07/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA133996343004),$12.45,$1.14,$13.59,FALSE,,FALSE,Redact,USD,
07/20/21,112-7502279-4296224,"Zulay Original Milk Frother Handheld Foam Maker for Lattes - Whisk Drink Mixer for Coffee, Mini Foamer for Cappuccino, Frappe, Matcha, Hot Chocolate by Milk Boss (Executive Black)",MILK_FROTHER,B074ZL35DH,52141500,Amazon.com,,new,Zulay Deals,"889 certification, Minority-Owned Business, Registered Small Business",$0.00,$14.95,1,American Express - 1004,,,Redacto@gmail.com,07/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA133718527604),$14.95,$1.36,$16.31,FALSE,,FALSE,Redact,USD,
07/22/21,112-4982677-6472234,"KEPLUG Inflatable Sleeping Pad for Camping, Ultralight Waterproof Sleeping Mat w/Pillow, Foot Pump Quick Inflation & Deflation, Thick Air Mattress w/Carrying Bag for Backpacking Hiking Tent Travel",AIR_MATTRESS,B08B3C35VR,49000000,Amazon.com,,new,US-SHUAIFENG-Direct,,$0.00,$30.89,1,American Express - 1004,,,Redacto@gmail.com,07/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA133718527604),$30.89,$2.82,$33.71,FALSE,,FALSE,Redact,USD,
07/23/21,112-1786916-2647454,Cherry Republic Hot Cherry Salsa - Chunky Sweet & High Heat Spicy Jalapeño Sauce (16 Oz Jar),SAUCE,B0173PDXJO,50000000,Amazon.com,,new,Airaco,,$0.00,$17.99,2,American Express - 1004,,,Redacto@gmail.com,07/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA135224380404),$35.98,$0.00,$35.98,,,,Redact,USD,
07/23/21,112-1786916-2647454,"Nando's Medium Peri-Peri Sauce, 125g",SAUCE,B0019MEJPA,50000000,Amazon.com,,new,EpicCo,,$0.00,$7.99,2,American Express - 1004,,,Redacto@gmail.com,07/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA135292277604),$15.98,$0.00,$15.98,,,,Redact,USD,
07/23/21,112-1786916-2647454,Anchor Original Chicken Chippy Salt 170g,CULINARY_SALT,B00KO2I0D8,50000000,Amazon.com,,new,TopLife Co.,,$0.00,$9.00,1,American Express - 1004,,,Redacto@gmail.com,07/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA135465103404),$9.00,$0.00,$9.00,,,,Redact,USD,
07/23/21,112-2607599-0080243,50-Pack Meal Prep Plastic Microwavable Food Containers For Meal Prepping With Lids 28 oz. 1 Compartment Black Rectangular Reusable Storage Lunch Boxes -BPA-Free Food Grade -Freezer & Dishwasher Safe,KITCHEN,B079M174TQ,52152000,Amazon.com,,new,PROMOZE,,$29.99,$24.99,1,American Express - 1004,,,Redacto@gmail.com,07/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA135380732804),$24.99,$2.28,$27.27,FALSE,,FALSE,Redact,USD,
07/26/21,112-4091402-8397066,Ashtanga Yoga: The Practice Manual,ABIS_BOOK,1891252089,55101500,Amazon.com,2007-08-20 0:00:01,new,Amazon.com,,$29.95,$21.97,1,American Express - 1004,,,Redacto@gmail.com,07/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA359561446000),$21.97,$2.00,$23.97,FALSE,,FALSE,Redact,USD,
07/26/21,112-4091402-8397066,Teaching Yoga: Essential Foundations and Techniques,ABIS_BOOK,1556438850,55101500,Amazon.com,2010-05-25 0:00:01,new,Amazon.com,,$24.95,$13.59,1,American Express - 1004,,,Redacto@gmail.com,07/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA359561446000),$13.59,$1.24,$14.83,FALSE,,FALSE,Redact,USD,
07/26/21,112-4469426-6846660,"Gold Bond No Mess Talc-Free Body Powder Spray 7 oz, Fresh Scent",TEXTILE_DEODORIZER,B08W5F2H15,47131811,Amazon.com,,new,Amazon.com,,$9.29,$7.99,1,American Express - 1004,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA138948762904),$7.99,$0.73,$8.72,FALSE,,FALSE,Redact,USD,
07/26/21,112-6428088-3237056,"Seagate Portable 4TB External Hard Drive HDD – USB 3.0 for PC, Mac, Xbox, & PlayStation - 1-Year Rescue Service (STGX4000400)",COMPUTER_DRIVE_OR_STORAGE,B07CRGSR16,43201827,Amazon.com,,new,Amazon.com,,$119.99,$89.99,1,American Express - 1004,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA138518951304),$89.99,$8.21,$98.20,FALSE,,FALSE,Redact,USD,
07/26/21,114-5362907-2457814,LANCROP Women's Sock Walking Shoes - Comfortable Mesh Slip on Easy Sneakers,SHOES,B07HF5PCFV,53111600,Amazon.com,,new,LANCROP,,$0.00,$32.99,1,Gift Certificate/Card,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA138941403704),$32.99,$1.98,$34.97,FALSE,,FALSE,Redact,USD,
07/26/21,114-5362907-2457814,Kate Kasin Half Slips Anti Static for Women Underskirt with Lace Trim,UNDERGARMENT_SLIP,B07F6T437K,53100000,Amazon.com,,new,Kate Kasin,,$0.00,$22.99,1,Gift Certificate/Card,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA139247524504),$22.99,$1.38,$24.37,FALSE,,FALSE,Redact,USD,
07/27/21,111-1065972-6319434,DREAM PAIRS Women's Hi-Chunk High Heel Pump Sandals,SANDAL,B0716BS9TP,53110000,Amazon.com,,new,topshoesUS,,$70.99,$52.99,1,Gift Certificate/Card,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA139464477604),$52.99,$3.18,$56.17,FALSE,,FALSE,Redact,USD,
07/27/21,111-0954758-9073018,Women's Casual Long Open Front Drape Lightweight Duster High Low Hem Maxi Long Sleeve Cardigan(S-3XL),SWEATER,B076F2DWR7,53100000,Amazon.com,,new,POGTMM,,$32.99,$24.99,1,Gift Certificate/Card,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA139387162304),$24.99,$1.50,$26.49,FALSE,,FALSE,Redact,USD,
07/27/21,112-3258723-5469029,"Screen Mom Screen Cleaner Kit for Laptop, Phone Cleaner, iPad, Eyeglass, LED, LCD, TV - Includes 2oz Spray and 2 Purple Cleaning Cloths",COMPUTER_COMPONENT,B074512LY1,43211900,Amazon.com,,new,Mom's Chosen Brands LLC.,,$14.99,$9.99,1,American Express - 2008 and Gift Certificate/Card,,,Redacto@gmail.com,07/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA163098093101),$9.99,$0.91,$10.90,FALSE,,FALSE,Redact,USD,
07/27/21,112-3442202-1382637,"USB C Adapter for MacBook Pro 2020, MacBook Adapter HDMI, MacBook Air M1 USB Multiport USB C Hub with 4K HDMI, Thunderbolt 3 , for MacBook Pro 13""-16"" 2021-2016, MacBook Air 2021-2018",MULTIPORT_HUB,B0827L29DK,43211600,Amazon.com,,new,Hoplaza US,,$26.99,$25.99,1,Gift Certificate/Card,,,Redacto@gmail.com,07/28/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA139669703404),$25.99,$2.37,$28.36,FALSE,,FALSE,Redact,USD,
07/29/21,111-0092011-3044220,12 Pieces Hair Claw Clips Medium Size Hair Claws Hair Styling Accessories in 1.3 Inches for Women Girls,HAIR_CLIP,B07VKC9DPQ,53131600,Amazon.com,,new,Hoocoway,,$0.00,$10.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,07/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142280874804),$10.99,$0.66,$11.65,FALSE,,FALSE,Redact,USD,
07/29/21,111-0092011-3044220,"Jumbo Size Hair Roller sets, Self Grip, Salon Hair Dressing Curlers, 3 Inch Hair Curlers, 3 Size 18 Packs (6XJUMBO+6XLARGE+6XMEDUIEM)",HAIR_ROLLER,B08M3F8TWN,53131600,Amazon.com,,new,EASY life,,$0.00,$11.46,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,07/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142280874804),$11.46,$0.69,$12.15,FALSE,,FALSE,Redact,USD,
08/01/21,112-2386624-7114629,"Amazon Brand - Solimo Gallon Food Storage Bags, 120 Count",FOOD_STORAGE_BAG,B07BJ495GL,52151506,Amazon.com,,new,Amazon.com,,$9.99,$9.78,1,American Express - 1004,,,Redacto@gmail.com,08/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA144958851704),$9.78,$0.89,$10.67,FALSE,,FALSE,Redact,USD,
08/02/21,112-6492665-6484213,,,B07PRTFPHJ,,Amazon.com,,new,GollStore,,$0.00,$17.84,1,American Express - 1004,,,Redacto@gmail.com,08/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA163425530401),$17.84,$0.00,$17.84,,,,Redact,USD,
08/07/21,111-2163851-6749817,"ETUDE SoonJung 2x Barrier Intensive Cream 60ml (21AD) | Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Water-oil Balance Panthenol Heals Damaged Skin | K-beauty",SKIN_MOISTURIZER,B091PN6NPT,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$17.00,1,American Express - 1004,,,Redacto@gmail.com,08/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA151879328904),$17.00,$1.02,$18.02,FALSE,,FALSE,Redact,USD,
08/13/21,112-9651362-1485832,UGREEN Micro USB 3.0 Cable USB 3.0 Type A Male to Micro B Cord Compatible with Samsung Galaxy S5 Note 3 Camera Hard Drive and More 1.5ft,ELECTRONIC_CABLE,B00P0C4M3Y,43202222,Amazon.com,,new,UGREEN GROUP LIMITED,,$5.99,$5.99,1,American Express - 1004,,,Redacto@gmail.com,08/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA157891184804),$5.99,$0.55,$6.54,FALSE,,FALSE,Redact,USD,
08/13/21,112-4881312-4750603,"TECKMAN 10 in 1 Torx Screwdriver Set with T3 T4 T5 T6 T8 T10 Security Torx Bit & ESD Tweezers,Magnetic Screwdrivers Precision Repair Kit for Xbox,PS4,Macbook,Computer,Doorbell & Folding knife",SCREWDRIVER,B07BYCPMR5,27111700,Amazon.com,,new,Teckman,,$15.99,$9.99,1,American Express - 1004,,,Redacto@gmail.com,08/13/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA158276436604),$9.99,$0.91,$10.90,FALSE,,FALSE,Redact,USD,
08/15/21,111-0619712-4467445,"LaCie (LAC9000633) Rugged Mini 4TB External Hard Drive Portable HDD – USB 3.0 USB 2.0 Compatible, Drop Shock Dust Rain Resistant Shuttle Drive, For Mac And PC Computer Desktop and Laptop",COMPUTER_DRIVE_OR_STORAGE,B01789QMUW,43201803,Amazon.com,,new,Amazon.com,,$159.99,$129.99,2,American Express - 1004,,,Redacto@gmail.com,08/15/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA160451226304),$259.98,$23.72,$283.70,FALSE,,FALSE,Redact,USD,
08/16/21,111-7736907-1457000,Gentle Facial Cleanser with Pump Dispenser - 8-fl oz (237 ml),SKIN_CLEANING_AGENT,B00QY1XZ4W,53131613,Amazon.com,,new,Amazon.com,,$10.00,$8.86,1,Gift Certificate/Card,,,Redacto@gmail.com,08/16/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA161036096604),$8.86,$0.53,$9.39,FALSE,,FALSE,Redact,USD,
08/16/21,111-7736907-1457000,Liquid I.V. Hydration Multiplier - Lemon Lime - Hydration Powder Packets | Electrolyte Drink Mix | Easy Open Single-Serving Stick | Non-GMO | 16 Sticks,NUTRITIONAL_SUPPLEMENT,B01IT9NLHW,53131600,Amazon.com,,new,Liquid I.V.,,$24.99,$24.99,1,Gift Certificate/Card,,,Redacto@gmail.com,08/16/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA161036096604),$24.99,$0.00,$24.99,,,,Redact,USD,
08/16/21,111-0547116-3769031,"SANKUU Large 1 Gallon/128oz (When Full) Gallon Water Bottle Motivational with Time Marker & Straw, Leakproof Water Jug Ensure You Drink Daily Water Throughout The Fitness Day",BOTTLE,B08D34Y4TN,52152100,Amazon.com,,new,Modern Cambrian,,$0.00,$19.98,1,American Express - 2008 and Gift Certificate/Card,,,Redacto@gmail.com,08/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA163604964104),$19.98,$1.20,$21.18,FALSE,,FALSE,Redact,USD,
08/16/21,111-7179136-9081804,Pregmate 25 Ovulation Test Strips Predictor Kit (25 Count),MEDICAL_TEST_STRIP,B01G5WCEN2,41110000,Amazon.com,,new,It In Retail,,$34.99,$10.95,1,American Express - 2008 and Gift Certificate/Card,,,Redacto@gmail.com,08/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA163604964104),$10.95,$0.66,$11.61,FALSE,,FALSE,Redact,USD,
08/17/21,112-6060899-6437847,"HOGARLABS 3500 Sq Ft 50 Pint Dehumidifier for Home Basements Bathroom Bedroom, Dehumidifier with Drain Hose for Medium to Large Room, Intelligent Humidity Control Dehumidifier with Laundry Dry",DEHUMIDIFIER,B094JM6S6G,40100000,Amazon.com,,new,MAX.WE US,,$259.99,$239.99,1,American Express - 1004,,,Redacto@gmail.com,08/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA162613451904),$239.99,$14.40,$254.39,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,Stren High Impact Monofilament Fishing Line,FISHING_LINE,B00NWD4NMI,49000000,Amazon.com,,new,Amazon.com,,$8.49,$8.42,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165552289804),$8.42,$0.51,$8.93,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,8 Pieces Halloween Witch Hat Witch Costume Accessory for Halloween Christmas Party,COSTUME_HEADWEAR,B07GJ1PLZV,60141400,Amazon.com,,new,Tatuo US,,$0.00,$12.59,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165552289804),$12.59,$0.76,$13.35,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,"Ogrmar 12 Pack Artificial Assorted Pumpkins, Mini Fake Pumpkins Artificial Vegetables for Halloween,Harvest Thanksgiving Party Decor (White)",ARTIFICIAL_PLANT,B08BF7S47K,56101528,Amazon.com,,new,Orgrimmar Bags,,$0.00,$12.99,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165552289804),$12.99,$0.78,$13.77,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,8 Pieces Halloween Skulls Realistic Looking Skulls Human Skeleton Head Skull for Halloween Bar Home Table Decoration (1.8 x 1.8 x 2.4 Inches),SCULPTURE,B07WPXC686,56140000,Amazon.com,,new,Hsei,,$0.00,$14.99,1,American Express - 2008,,,Redacto@gmail.com,08/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA164976984204),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,"Unscented Black Taper Candles for Home – 10 Inch Tall Clean Burning Candlesticks | Perfect for Weddings, Parties, Home Décor | 12 Pack",CANDLE,B089H1RBNH,39112604,Amazon.com,,new,ShipPro,,$14.99,$10.95,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165699376804),$10.95,$0.66,$11.61,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,Biubee Pack of 5 Halloween Black Creepy Cloth- Spooky Fabric Cloth for Haunted House Halloween Party Doorway Outdoors Decoration (30×72inch),TOYS_AND_GAMES,B07H278ZH1,60140000,Amazon.com,,new,Biubee,,$0.00,$11.99,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA164866864204),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,"HOZZQ DIY Halloween Party Supplies PVC 3D Decorative Scary Bats Wall Decal Wall Sticker, Halloween Eve Decor Home Window Decoration Set, 28pcs, Black",STICKER_DECAL,B01KMB0JPM,60121000,Amazon.com,,new,HOZZQ,,$21.99,$9.99,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165552289804),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
08/19/21,111-8940619-1025867,"900 sqft Spider Webs Halloween Decorations Bonus with 30 Fake Spiders, Super Stretch Cobwebs for Halloween Indoor and Outdoor Party Supplies",PARTY_SUPPLIES,B07HMRZSWT,60000000,Amazon.com,,new,Zpisf,,$19.99,$8.99,1,American Express - 2008,,,Redacto@gmail.com,08/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165552289804),$8.99,$0.54,$9.53,FALSE,,FALSE,Redact,USD,
08/20/21,111-3841760-5650661,Magic Lessons: Book #1 of the Practical Magic Series (1),ABIS_BOOK,1982108851,55101500,Amazon.com,2021-09-07 0:00:01,new,Amazon.com,,$17.00,$13.99,1,American Express - 2008,,,Redacto@gmail.com,09/06/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA183646809904),$13.99,$0.84,$14.83,FALSE,,FALSE,Redact,USD,
08/23/21,112-0380869-7006620,"Nando's Medium PERi-PERi Sauce Jumbo 1 Liter - Medium Heat Flavorful Hot Sauce, Gluten Free, Non GMO, Kosher 36.7 Oz",SAUCE,B09C9VJ2HP,50170000,Amazon.com,,new,Nando's PERi-PERi Authorized Store,,$0.00,$18.99,1,American Express - 2008,,,Redacto@gmail.com,08/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA170014939504),$18.99,$0.00,$18.99,,,,Redact,USD,
08/25/21,111-0631455-1585853,Levi's Women's Ribcage Straight Ankle Jeans,PANTS,B081YX3J23,53100000,Amazon.com,,new,Amazon.com,,$79.50,$69.50,1,American Express - 2008 and Gift Certificate/Card,,,Redacto@gmail.com,08/26/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA171959024104),$69.50,$4.17,$73.67,FALSE,,FALSE,Redact,USD,
08/31/21,112-7991415-7208237,"Nando's Medium PERi-PERi Sauce Jumbo 1 Liter - Medium Heat Flavorful Hot Sauce, Gluten Free, Non GMO, Kosher 36.7 Oz",SAUCE,B09C9VJ2HP,50170000,Amazon.com,,new,Nando's PERi-PERi Authorized Store,,$0.00,$18.99,1,American Express - 1004,,,Redacto@gmail.com,09/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA178211932804),$18.99,$0.00,$18.99,,,,Redact,USD,
08/31/21,111-1906606-7189015,Why Does He Do That?: Inside the Minds of Angry and Controlling Men,ABIS_BOOK,425191656,55101500,Amazon.com,2003-09-02 0:00:01,new,Amazon.com,,$18.00,$15.99,1,American Express - 2008,,,Redacto@gmail.com,09/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA178392769804),$15.99,$0.96,$16.95,FALSE,,FALSE,Redact,USD,
09/08/21,111-1358117-1524245,"EZY DOSE Push Button (7-Day) Pill, Medicine, Vitamin Organizer | Weekly, 2 Times a Day, AM/PM | Large Compartments | Arthritis Friendly | Clear Lids",PERSONAL_PILL_DISPENSER,B001OK1YUA,42192602,Amazon.com,,new,Amazon.com,,$4.99,$5.94,1,American Express - 2008,,,Redacto@gmail.com,09/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA186884694404),$5.94,$0.36,$6.30,FALSE,,FALSE,Redact,USD,
09/08/21,111-1358117-1524245,"24 Packs Duck Bill Clips, Bantoye 3.5 Inches Rustproof Metal Alligator Curl Clips with Holes for Hair Styling, Hair Coloring, Silver",HAIR_CLIP,B07D52FVNJ,53131600,Amazon.com,,new,Bantoye,,$0.00,$6.48,1,American Express - 2008,,,Redacto@gmail.com,09/09/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA186884694404),$6.48,$0.39,$6.87,FALSE,,FALSE,Redact,USD,
09/17/21,113-7671778-4133016,"Maybelline New York Lash Sensational Washable Mascara, Blackest Black, (Packaging May Vary) 0.32 Fl Oz (Pack of 1) , K1714600",MASCARA,B00PFCT2R0,53131619,Amazon.com,,new,Amazon.com,,$7.99,$6.78,1,American Express - 2008,,,Redacto@gmail.com,09/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA196813466004),$6.78,$0.41,$7.19,FALSE,,FALSE,Redact,USD,
09/17/21,113-7671778-4133016,"First Response Early Result Pregnancy Test, 3 Pack (Packaging & Test Design May Vary)",MEDICAL_TEST_STRIP,B000052XHI,41110000,Amazon.com,,new,Amazon.com,,$19.57,$12.98,1,American Express - 2008,,,Redacto@gmail.com,09/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA196813466004),$12.98,$0.78,$13.76,FALSE,,FALSE,Redact,USD,
09/17/21,113-7671778-4133016,"Gooji 4x6 Golden Greenery Thank You Cards (Bulk 36-Pack) Gold Foil, Matching Peel-and-Seal White Envelopes | Assorted Set, Watercolor, Colorful Graphics | Birthday Party, Baby Shower, Weddings",GREETING_CARD,B07RW8ZNV6,14111605,Amazon.com,,new,Gooji LLC,,$20.00,$15.99,1,American Express - 2008,,,Redacto@gmail.com,09/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA196813466004),$15.99,$0.96,$16.95,FALSE,,FALSE,Redact,USD,
09/17/21,113-7671778-4133016,"Earth Rated Dog Poop Bag Holder with Dog Poop Bags, Durable and Guaranteed Leakproof, Unscented, 1 Dispenser and 15 Bags",WASTE_BAG,B08VN2W5NC,10111303,Amazon.com,,new,Oliver's Pet Care,,$0.00,$4.99,1,American Express - 2008,,,Redacto@gmail.com,09/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA196813466004),$4.99,$0.30,$5.29,FALSE,,FALSE,Redact,USD,
09/17/21,113-7671778-4133016,"Earth Rated Dog Poop Bags, Guaranteed Leak Proof and Extra Thick Waste Bag Refill Rolls For Dogs, Unscented, 270 Count",WASTE_BAG,B00LAI9OM6,10111303,Amazon.com,,new,Oliver's Pet Care,,$12.99,$11.99,1,American Express - 2008,,,Redacto@gmail.com,09/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA196813466004),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
09/17/21,113-7671778-4133016,"100 Pcs Disposable Face Masks, 3 Ply Face Masks Black Disposable Mask",SAFETY_MASK,B08M32ZRKY,46182001,Amazon.com,,new,Lement-US,,$0.00,$16.99,1,American Express - 2008,,,Redacto@gmail.com,09/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA196813466004),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
09/20/21,113-5677417-0335463,"Syntus Set of 2 Buffalo Check Throw Pillow Covers Farmhouse Outdoor Plaid Square Pillow Cushion Case Black and White Polyester Linen for Home Easter Decor Car Bed Sofa, 18 x 18 inches",DECORATIVE_PILLOW_COVER,B0824YQ6XQ,52121512,Amazon.com,,new,SyntusDirect,,$10.99,$9.99,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA001526428504),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
09/20/21,113-5677417-0335463,Glamorstar Corset Belt for Women Lace-up Leather Elastic Belts for Dresses Wide Tied Waspie Cincher Belts,APPAREL_BELT,B08XYR557K,53100000,Amazon.com,,new,Glamorstar House,,$24.99,$10.99,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z9YF2211324356964),$10.99,$0.66,$11.65,FALSE,,FALSE,Redact,USD,
09/20/21,113-5677417-0335463,"Ellie Shoes 4"" Ankle Boots with Buckles and Chains.",BOOT,B00187HHTQ,53111500,Amazon.com,,new,Amazon.com,,$55.00,$41.99,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA000784102804),$41.99,$2.52,$44.51,FALSE,,FALSE,Redact,USD,
09/20/21,113-5677417-0335463,JISEN Womens Fingerless Gloves Elbow Lace Up steampunk Costume Arm Warmer,APPAREL_GLOVES,B01DIUCL6E,53100000,Amazon.com,,new,JISEN,,$0.00,$12.99,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z9YF2211324356964),$12.99,$0.78,$13.77,FALSE,,FALSE,Redact,USD,
09/21/21,113-1737543-4172247,"Vital Proteins Collagen Coffee Creamer, Non-dairy & Low Sugar Powder with Collagen Peptides Supplement - Supporting Healthy Hair, Skin, Nails with Energy-Boosting MCTs - Vanilla 10.6oz",NON_DAIRY_CREAM,B07G3RZ4ZT,50201700,Amazon.com,,new,Vital Proteins,,$34.99,$26.92,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA001526428504),$26.92,$0.00,$26.92,,,,Redact,USD,
09/21/21,113-1737543-4172247,"Ziploc Gallon Food Storage Freezer Bags, Grip 'n Seal Technology for Easier Grip, Open, and Close, 28 Count",FOOD_STORAGE_BAG,B003UEGZNE,52151506,Amazon.com,,new,Amazon.com,,$0.00,$4.49,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA000784102804),$4.49,$0.27,$4.76,FALSE,,FALSE,Redact,USD,
09/21/21,113-1737543-4172247,"Proactiv+ Benzoyl Peroxide Wash - Exfoliating Face Wash for Face, Back and Body - Benzoyl Peroxide 2.5% Solution - Creamy and Gentle Moisturizing 90 Day Acne Treatment, 6 Oz",SKIN_EXFOLIANT,B00SUWEZCO,53131613,Amazon.com,,new,Amazon.com,,$48.00,$40.00,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA001526428504),$40.00,$2.40,$42.40,FALSE,,FALSE,Redact,USD,
09/21/21,113-1737543-4172247,"Green Gobbler Liquid Hair Drain Clog Remover, For Toilets, Sinks, Tubs - Septic Safe, 2 Pack",DRAIN_OPENER_SUBSTANCE,B085FS4JTD,50201700,Amazon.com,,new,EcoClean Solutions,,$23.99,$19.99,1,American Express - 2008,,,Redacto@gmail.com,09/22/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA001526428504),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
09/22/21,112-0498357-1605814,"Adult Children of Emotionally Immature Parents: How to Heal from Distant, Rejecting, or Self-Involved Parents",ABIS_BOOK,1626251703,55101500,Amazon.com,2015-06-01 0:00:01,new,Amazon.com,,$18.95,$10.59,1,American Express - 1004,,,Redacto@gmail.com,09/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,UPS(1Z6A4F120311104414),$10.59,$0.97,$11.56,FALSE,,FALSE,Redact,USD,
10/06/21,113-4890353-2472218,,,B07PRTFPHJ,,Amazon.com,,new,Thanksforyourorder,,$0.00,$14.98,1,American Express - 1004,,,Redacto@gmail.com,10/07/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,USPS(9300120111408562327779),$14.98,$0.00,$14.98,,,,Redact,USD,
10/07/21,113-2020779-2578657,"AccuMed Pregnancy Test Strips, 25-Count Individually Wrapped Pregnancy Strips, Early Home Detection Pregnancy Test Kit, Clear HCG Test Results, Over 99% Accurate",MEDICAL_TEST_STRIP,B071YNWRPP,41110000,Amazon.com,,new,BNX AccuMed,,$0.00,$8.99,1,American Express - 2008,,,Redacto@gmail.com,10/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA019283974804),$8.99,$0.54,$9.53,FALSE,,FALSE,Redact,USD,
10/07/21,113-2170436-6589060,Hooded Black Lace Up Robe Costume,COSTUME_OUTFIT,B00K04PW4U,53100000,Amazon.com,,new,Amazon.com,,$44.00,$30.42,1,American Express - 2008,,,Redacto@gmail.com,10/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA019228516204),$30.42,$1.83,$32.25,FALSE,,FALSE,Redact,USD,
10/12/21,112-1405446-8213841,Starmark Swing 'n Fling DuraFoam Ball Dog Toy Large,PET_TOY,B003YHB8E8,10111300,Amazon.com,,new,Amazon.com,,$14.99,$14.99,4,American Express - 1004,,,Redacto@gmail.com,10/12/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA024677024704),$59.96,$3.60,$63.56,FALSE,,FALSE,Redact,USD,
10/17/21,112-5439996-8705042,OXO Good Grips Soap Dispensing Palm Brush Storage Set,CLEANING_BRUSH,B004ZLAGJK,47131600,Amazon.com,,new,Amazon.com,,$10.95,$10.81,1,American Express - 1004,,,Redacto@gmail.com,10/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA030525906404),$10.81,$0.99,$11.80,FALSE,,FALSE,Redact,USD,
10/18/21,112-2600433-7340243,Red Bell Pepper,VEGETABLE,B08DS45Y8W,50400000,Amazon.com,,new,Whole Foods Market,,$0.00,$1.20,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$1.20,$0.00,$1.20,,,,Redact,USD,
10/18/21,112-2600433-7340243,"365 by Whole Foods Market, Tater Puffs Organic, 16 Ounce",PUFFED_SNACK,B074H5H1FC,53100000,Amazon.com,,new,Whole Foods Market,,$0.00,$2.99,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$2.99,$0.00,$2.99,,,,Redact,USD,
10/18/21,112-2600433-7340243,"Organic Romaine Hearts, 3ct",VEGETABLE,B074H5J9G2,53100000,Amazon.com,,new,Whole Foods Market,,$0.00,$3.99,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$3.99,$0.00,$3.99,,,,Redact,USD,
10/18/21,112-2600433-7340243,"365 by Whole Foods Market, Pork Bacon Center Cut Smokehouse Uncured, 12 Ounce",MEAT,B074H5GZ7P,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$5.99,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$5.99,$0.00,$5.99,,,,Redact,USD,
10/18/21,112-2600433-7340243,"365 by Whole Foods Market, Tortillas Corn Flour Blend Organic 6 Count, 10.5 Ounce",BREAD,B07FW7FXX2,50192100,Amazon.com,,new,Whole Foods Market,,$0.00,$2.29,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$2.29,$0.00,$2.29,,,,Redact,USD,
10/18/21,112-2600433-7340243,"365 by Whole Foods Market, Eggs Large Grade A, 12 Count",EGG,B07PFDZ17T,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$4.79,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$4.79,$0.00,$4.79,,,,Redact,USD,
10/18/21,112-2600433-7340243,Orange Bell Pepper,VEGETABLE,B000P6J1A4,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$0.99,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$0.99,$0.00,$0.99,,,,Redact,USD,
10/18/21,112-2600433-7340243,"365 by Whole Foods Market, Spinach Chopped Organic, 16 Ounce",VEGETABLE,B074H5XZTJ,53100000,Amazon.com,,new,Whole Foods Market,,$0.00,$2.39,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$2.39,$0.00,$2.39,,,,Redact,USD,
10/18/21,112-2600433-7340243,"Ann Arbor Tortilla Chips, Tortilla Chip Original, 32 Ounce",SNACK_CHIP_AND_CRISP,B07FYX8T3D,50192100,Amazon.com,,new,Whole Foods Market,,$0.00,$9.49,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$9.49,$0.00,$9.49,,,,Redact,USD,
10/18/21,112-2600433-7340243,"Whole Foods Market, Bread Tuscan, 18 Ounce",BREAD,B07G5KNPM4,50181900,Amazon.com,,new,Whole Foods Market,,$0.00,$4.49,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$4.49,$0.00,$4.49,,,,Redact,USD,
10/18/21,112-2600433-7340243,"Ann Arbor Tortilla Chips, Tortilla Chip Yellow, 8 Ounce",SNACK_CHIP_AND_CRISP,B07FXWRQGN,50192100,Amazon.com,,new,Whole Foods Market,,$0.00,$2.79,1,American Express - 2008,,,Redacto@gmail.com,10/18/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC4913QYGD9130K),RABBIT(SpOSIOC4913QQRQ4073L),RABBIT(SpOSIOC4913PTRM5050N),RABBIT(SpOSIOC4913QLDF1289D)",$2.79,$0.00,$2.79,,,,Redact,USD,
10/18/21,112-6382426-1120263,HOFISH Women's Maternity Pregnancy Seamless Breastfeeding Bras No Underwire,SWEATER,B07GRN4WJG,53100000,Amazon.com,,new,MUMMY STORE,,$0.00,$29.99,1,American Express - 2008,,,Redacto@gmail.com,10/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA032371661604),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
10/18/21,112-6382426-1120263,"EltaMD UV Clear SPF 46 Tinted Face Sunscreen, Broad Spectrum Sunscreen for Sensitive Skin and Acne-Prone Skin, Oil-Free Mineral-Based Sunscreen, Sheer Face Sunscreen with Zinc Oxide, 1.7 oz Pump",SUNSCREEN,B00ZPWR0N8,53131609,Amazon.com,,new,Amazon.com,,$39.00,$39.00,1,American Express - 2008,,,Redacto@gmail.com,10/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA032994020804),$39.00,$2.34,$41.34,FALSE,,FALSE,Redact,USD,
10/19/21,112-4833164-4313802,"Big Brother Dog Bandana,Buffalo Plaid Pet Pregnancy Announcement Scarf",PET_APPAREL,B084TS3NTX,10111300,Amazon.com,,new,EternalTimes,,$0.00,$7.99,2,American Express - 1004,,,Redacto@gmail.com,10/19/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA033058884704),$15.98,$0.96,$16.94,FALSE,,FALSE,Redact,USD,
10/23/21,111-5372717-8287419,"Balhvit Non-Breakable Pet Pooper Scooper for Dogs, Portable Dog Pooper Scooper with Long Handle & High Strength Durable Spring, Foldable Dog Poop Waste Pick Up Rake, Jaw Claw Bin for Grass and Gravel",PET_SUPPLIES,B083FS3NJN,10111303,Amazon.com,,new,GIKEA,,$17.99,$15.20,1,American Express - 1004,,,Redacto@gmail.com,10/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA038913629204),$15.20,$0.91,$16.11,FALSE,,FALSE,Redact,USD,
10/23/21,111-5372717-8287419,Chuckit! Sport Dog Ball Launcher,PET_TOY,B000PKWKMQ,10111300,Amazon.com,,new,Amazon.com,,$8.99,$6.99,1,American Express - 1004,,,Redacto@gmail.com,10/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA038913629204),$6.99,$0.42,$7.41,FALSE,,FALSE,Redact,USD,
10/27/21,111-5095404-1536232,HOFISH Women's Maternity Pregnancy Seamless Breastfeeding Bras No Underwire,NIGHTGOWN_NIGHTSHIRT,B07GRLQKPT,53100000,Amazon.com,,new,Nursing Clothing Online,,$0.00,$29.99,1,American Express - 2008,,,Redacto@gmail.com,10/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA043031854404),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
10/27/21,111-5095404-1536232,"Cribsheet: A Data-Driven Guide to Better, More Relaxed Parenting, from Birth to Preschool (The ParentData Series)",ABIS_BOOK,525559272,55101500,Amazon.com,2020-04-21 0:00:01,new,Amazon.com,,$18.00,$13.89,1,American Express - 2008,,,Redacto@gmail.com,10/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA043031854404),$13.89,$0.83,$14.72,FALSE,,FALSE,Redact,USD,
10/27/21,111-5095404-1536232,Expecting Better: Why the Conventional Pregnancy Wisdom Is Wrong--and What You Really Need to Know (The ParentData Series),ABIS_BOOK,143125702,55101500,Amazon.com,2014-06-24 0:00:01,new,Amazon.com,,$18.00,$13.99,1,American Express - 2008,,,Redacto@gmail.com,10/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA043031854404),$13.99,$0.84,$14.83,FALSE,,FALSE,Redact,USD,
10/27/21,111-5095404-1536232,Bringing Up Bébé: One American Mother Discovers the Wisdom of French Parenting (now with Bébé Day by Day: 100 Keys to French Parenting),ABIS_BOOK,143122967,55101500,Amazon.com,2014-09-30 0:00:01,new,Amazon.com,,$18.00,$15.99,1,American Express - 2008,,,Redacto@gmail.com,10/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA043031854404),$15.99,$0.96,$16.95,FALSE,,FALSE,Redact,USD,
10/27/21,111-5095404-1536232,"Mask Extender, Face Mask Holder, Anti-Slip Mask Buckle Strap (4pcs)",SAFETY_MASK_STRAP_EXTENDER,B08B8X75CC,46182001,Amazon.com,,new,Moserstey,,$0.00,$11.99,1,American Express - 2008,,,Redacto@gmail.com,10/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA043031854404),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
10/27/21,112-9596301-0871456,"SportDOG Brand 425 Remote Trainers - 500 Yard Range E-Collar with Static, Vibrate and Tone - Waterproof, Rechargeable - Including New X-Series, Black (SD-425X)",ANIMAL_COLLAR,B07DZBN74T,10111300,Amazon.com,,new,Amazon.com,,$189.99,$174.95,1,American Express - 1004,,,Redacto@gmail.com,10/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA043174598304),$174.95,$10.50,$185.45,FALSE,,FALSE,Redact,USD,
10/31/21,111-7297938-4761801,"PharMeDoc Pregnancy Pillow, Grey U-Shape Full Body Pillow and Maternity Support - Support for Back, Hips, Legs, Belly for Pregnant Women",BODY_POSITIONER,B07JR3T1G6,52121505,Amazon.com,,new,Doctors-Deals,,$89.99,$42.95,1,Visa - 5239,,,Redacto@gmail.com,11/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048266176304),$42.95,$2.58,$45.53,FALSE,,FALSE,Redact,USD,
10/31/21,111-7297938-4761801,"Sea-Band Anti-Nausea Acupressure Wristband for Motion or Morning Sickness, Adult, 1 Pair (Color May Vary)",MEDICATION,B001F731N0,42000000,Amazon.com,,new,Amazon.com,,$12.50,$8.53,1,Visa - 5239,,,Redacto@gmail.com,11/01/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048266176304),$8.53,$0.51,$9.04,FALSE,,FALSE,Redact,USD,
11/01/21,111-1865533-6533859,Venus Razor 3ct,MANUAL_SHAVING_RAZOR,B0039LMTAQ,53131603,Amazon.com,,new,Amazon.com,,$6.99,$6.69,1,Visa - 5239,,,Redacto@gmail.com,11/02/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA049560409904),$6.69,$0.40,$7.09,FALSE,,FALSE,Redact,USD,
11/02/21,112-3995789-7905000,"YETI Rambler 30 oz Tumbler, Stainless Steel, Vacuum Insulated with MagSlider Lid",DRINKING_CUP,B097CW34WX,52152100,Amazon.com,,new,YETI Authorized,,$42.16,$34.98,1,American Express - 1004,,,Redacto@gmail.com,11/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA168883976501),$34.98,$3.19,$38.17,FALSE,,FALSE,Redact,USD,
11/02/21,112-3995789-7905000,"ZYTC Handle for 30OZ Tumbler,Yeti Rambler Handle Anti Slip Travel Mug Grip BPA Free Cup Holder for Yeti Rambler,Rtic,Ozark Trail,Sic and More Tumbler Mugs",DRINKING_CUP,B07C5NHSCC,52152100,Amazon.com,,new,ZYTC CO LTD,,$0.00,$9.58,1,American Express - 1004,,,Redacto@gmail.com,11/03/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA168883976501),$9.58,$0.87,$10.45,FALSE,,FALSE,Redact,USD,
11/03/21,113-2415251-8568248,"Clorox Disinfecting Wipes Value Pack, 75 Ct Each, Pack of 3 (Package May Vary)",SURFACE_CLEANING_WIPE,B00HSC9F2C,47131502,Amazon.com,,new,Amazon.com,,$11.97,$9.98,1,American Express - 1004,,,Redacto@gmail.com,11/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,UPS(1Z6A4F120312061969),$9.98,$0.91,$10.89,FALSE,,FALSE,Redact,USD,
11/04/21,112-6763369-9153026,We're Pregnant! The First Time Dad's Pregnancy Handbook,ABIS_BOOK,1939754682,55101500,Amazon.com,2018-04-24 0:00:01,new,Amazon.com,,$13.99,$6.38,1,American Express - 1004,,,Redacto@gmail.com,11/04/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA052305812504),$6.38,$0.58,$6.96,FALSE,,FALSE,Redact,USD,
11/14/21,111-7643228-8393839,"Cooraby Christmas Pine Cones Natural Snow 20 Pieces 4-6 cm Pine Cones and 30 Pieces 2-3 cm Micro-Mini Pine Cones for Thanksgiving Decoration, Fall and Christmas Crafts, 50 Pieces Total",HANGING_ORNAMENT,B08HLKH113,52140000,Amazon.com,,new,Coobey,,$0.00,$12.79,1,Visa - 5239,,,Redacto@gmail.com,11/14/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA064933724004),$12.79,$0.77,$13.56,FALSE,,FALSE,Redact,USD,
11/19/21,111-6835632-5793020,"Colace Stool Softener Docusate Sodium caps, 60 Count",MEDICATION,B000270OY8,51000000,Amazon.com,,new,Amazon.com,,$27.27,$16.99,1,Visa - 5239,,,Redacto@gmail.com,11/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA072510549404),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
11/21/21,112-7574793-5347425,The Paleo Gut Healing Cookbook: 75 Nourishing Paleo + AIP Recipes & 10 Practices to Strengthen Digestion,ABIS_BOOK,760371334,55101500,Amazon.com,2021-12-21 0:00:01,new,Amazon.com,,$26.99,$26.99,1,American Express - 1004,,,Redacto@gmail.com,12/20/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA123112219804),$26.99,$1.62,$28.61,FALSE,,FALSE,Redact,USD,
11/21/21,112-7422124-6937861,The Slim Palate Paleo Cookbook,ABIS_BOOK,162860011X,55101500,Amazon.com,2014-03-11 0:00:01,used good,best desicion,,$29.95,$70.80,1,American Express - 1004,,,Redacto@gmail.com,11/23/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA076267790504),$70.80,$4.25,$75.05,FALSE,,FALSE,Redact,USD,
11/24/21,111-0004538-8979429,"BENTON Aloe BHA Skin Toner 200ml (6.76 fl. oz.) - Facial Toner for Sensitive Skin with Aloe and BHA, Hydrating Toner for Dry & Rough Skin, Soothing Toner to Maintain Oil-Water Balance, Tighten Pores & Control Sebum",ASTRINGENT_SUBSTANCE,B00GAOBG3A,53131600,Amazon.com,,new,Nicole & Alyssa,,$19.00,$15.95,1,Visa - 5239,,,Redacto@gmail.com,11/24/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA078657862704),$15.95,$0.96,$16.91,FALSE,,FALSE,Redact,USD,
11/26/21,111-2979961-3074636,"Kidde Carbon Monoxide Detector, AC-Plug-In with Battery Backup, CO Alarm with Replacement Alert",GAS_SMOKE_FIRE_ALARM,B00M48BH30,46191500,Amazon.com,,new,Amazon.com,,$29.99,$11.85,2,Visa - 5239,,,Redacto@gmail.com,11/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA082062135304),$23.70,$1.42,$25.12,FALSE,,FALSE,Redact,USD,
11/27/21,111-5551699-8380246,"Phillips' Milk of Magnesia Liquid Laxative, Wild Cherry, 26 oz, Cramp Free & Gentle Overnight Relief Of Occasional Constipation, #1 Milk of Magnesia Brand (Packaging May Vary)",MEDICATION,B005TM4DMK,51000000,Amazon.com,,new,Amazon.com,,$14.42,$7.29,1,Visa - 5239,,,Redacto@gmail.com,11/28/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA083580616404),$7.29,$0.44,$7.73,FALSE,,FALSE,Redact,USD,
11/27/21,111-3448498-9079432,"Bio-Oil Skincare Body Oil, Moisturizer for Scars and Stretchmarks, Hydrates Skin, Non-Greasy, Dermatologist Recommended, Non-Comedogenic, For All Skin Types, with Vitamin A, E, 4.2 Ounce (Pack of 1",SKIN_MOISTURIZER,B004AI97MA,53131607,Amazon.com,,new,Amazon.com,,$26.47,$14.99,1,Visa - 5239,,,Redacto@gmail.com,11/28/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA083580616404),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
11/29/21,111-4435531-6889810,Casfuy Dog Nail Grinder Upgraded - Professional 2-Speed Electric Rechargeable Pet Nail Trimmer Painless Paws Grooming & Smoothing for Small Medium Large Dogs & Cats (White),PET_SUPPLIES,B07PFCLHKR,10111300,Amazon.com,,new,Casfuy Direct,,$29.99,$21.99,1,Visa - 5239,,,Redacto@gmail.com,11/30/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA086767332304),$21.99,$1.32,$23.31,FALSE,,FALSE,Redact,USD,
12/02/21,112-4540590-8893852,MaaMgic Womens Large Soft Cashmere Feel Pashmina Shawls Wraps Light Scarf,SCARF,B074CRYZJY,53102502,Amazon.com,,new,Baohui Fashion Store,,$0.00,$14.99,1,Visa - 5239,,,Redacto@gmail.com,12/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095188923704),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
12/02/21,112-4540590-8893852,SweatyRocks Womens Long Sleeve Scoop Neck Basic Solid Slim fit Tee Shirt Top,SHIRT,B07WZMSN1S,53100000,Amazon.com,,new,SweatyRocks,,$0.00,$16.89,1,Visa - 5239,,,Redacto@gmail.com,12/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095188923704),$16.89,$1.01,$17.90,FALSE,,FALSE,Redact,USD,
12/02/21,112-4540590-8893852,Rocorose Women's Crewneck Pullover Sweater Dress Elasticity Slim Fit Sweater Knitted Bodycon Dresses,DRESS,B09LQQTWPK,53100000,Amazon.com,,new,Rocorose®,,$40.99,$35.99,1,Visa - 5239,,,Redacto@gmail.com,12/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095188923704),$35.99,$2.16,$38.15,FALSE,,FALSE,Redact,USD,
12/02/21,112-4540590-8893852,Rocorose Women's Turtleneck Ribbed Elbow Long Sleeve Knit Sweater Dress,DRESS,B0177RET3W,53100000,Amazon.com,,new,Rocorose®,,$48.99,$38.99,1,Visa - 5239,,,Redacto@gmail.com,12/05/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095188923704),$38.99,$2.34,$41.33,FALSE,,FALSE,Redact,USD,
12/02/21,112-5347772-3459449,Fixmatti Women's Elegant V Neck Wrap Knit Dresses Batwing Sleeve Backless Slit Maxi Dress with Belted,DRESS,B0828JPLFW,53100000,Amazon.com,,new,witteashop,,$48.99,$48.99,1,Visa - 5239,,,Redacto@gmail.com,12/08/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA100602053004),$48.99,$2.94,$51.93,FALSE,,FALSE,Redact,USD,
12/11/21,112-8366895-0339427,Field Notes for Food Adventure: Recipes and Stories from the Woods to the Ocean,ABIS_BOOK,316497355,55101500,Amazon.com,2021-11-23 0:00:01,new,Amazon.com,,$35.00,$29.99,2,American Express - 1004,,,Redacto@gmail.com,12/11/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA171479255701),$59.98,$5.48,$65.46,FALSE,,FALSE,Redact,USD,
12/11/21,112-8722435-7056235,"MEATER Plus | Smart Meat Thermometer with Bluetooth | 165ft Wireless Range | for The Oven, Grill, Kitchen, BBQ, Smoker, Rotisserie",THERMOMETER,B07H8WTFHW,52150000,Amazon.com,,new,MEATER US,,$99.95,$89.95,2,American Express - 1004,,,Redacto@gmail.com,12/11/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA171479255701),$179.90,$16.42,$196.32,FALSE,,FALSE,Redact,USD,
12/16/21,111-8460189-3016215,"ETUDE SoonJung 2x Barrier Intensive Cream 60ml (21AD) | Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Water-oil Balance Panthenol Heals Damaged Skin | K-beauty",SKIN_MOISTURIZER,B091PN6NPT,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$17.00,1,Visa - 5239,,,Redacto@gmail.com,12/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA118328075204),$17.00,$1.02,$18.02,FALSE,,FALSE,Redact,USD,
12/16/21,111-8460189-3016215,"gonicc Dog & Cat Pets Nail Clippers and Trimmers - with Safety Guard to Avoid Over Cutting, Free Nail File, Razor Sharp Blade - Professional Grooming Tool for Pets",PET_SUPPLIES,B075N83693,10111300,Amazon.com,,new,Gonicc,,$0.00,$13.95,1,Visa - 5239,,,Redacto@gmail.com,12/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA118328075204),$13.95,$0.84,$14.79,FALSE,,FALSE,Redact,USD,
12/16/21,111-8460189-3016215,"BASKERVILLE Ultra Dog Muzzle- Black Size 5, Perfect for Large Dogs, Prevents Chewing and Biting, Basket allows Panting and Drinking-Comfortable, Humane, Adjustable, Lightweight, Durable",ANIMAL_MUZZLE,B0051H45GC,10000000,Amazon.com,,new,Amazon.com,,$0.00,$20.10,1,Visa - 5239,,,Redacto@gmail.com,12/17/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA118328075204),$20.10,$1.21,$21.31,FALSE,,FALSE,Redact,USD,
12/23/21,112-3619000-1109855,"QogriSun 10-Pack Solid Brass Cabinet Knobs, Round Ball Gold Knobs for Dresser Drawer, 1.1-Inch Diameter, Modern Kitchen Hardware, Brushed Brass Finish",HARDWARE_HANDLE,B08QN4TLWN,53000000,Amazon.com,,new,Qogrisun,ISO 9001,$0.00,$37.99,3,American Express - 1004,,,Redacto@gmail.com,12/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,LODA,IL,60948-9634,Shipped,UPS(1Z839R240302804641),$113.97,$7.41,$121.38,FALSE,,FALSE,Redact,USD,
12/25/21,112-1037421-3789854,Voluspa Baltic Amber Candle | Large Glass Jar | 18 Oz | 100 Hour Burn Time | All Natural Wicks and Coconut Wax for Clean Burning | Vegan,CANDLE,B005Z5Q3Z6,39112604,Amazon.com,,new,BH Prime,,$55.93,$45.37,1,American Express - 1004,,,Redacto@gmail.com,01/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,CHICAGO,IL,60654-7232,Shipped,AMZN_US(TBA022750667903),$45.37,$4.65,$50.02,FALSE,,FALSE,Redact,USD,
12/25/21,112-0160150-8043467,"SPLF 12 Pack BPA FREE Reusable Storage Bags (6 Reusable Sandwich Bags, 6 Reusable Snack Bags), Extra Thick Freezer Bags Leakproof Silicone and Plastic Free Lunch Bags for Food Meat Fruit Veggies",FOOD_STORAGE_BAG,B07V4332K7,53120000,Amazon.com,,new,SPLF Direct,,$18.99,$14.99,1,American Express - 1004,,,Redacto@gmail.com,12/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,CHICAGO,IL,60654-7232,Shipped,AMZN_US(TBA022310288903),$14.99,$1.54,$16.53,FALSE,,FALSE,Redact,USD,
12/25/21,112-0160150-8043467,"SPLF 12 Pack BPA FREE Reusable Storage Bags (6 Reusable Sandwich Bags, 6 Reusable Snack Bags), Extra Thick Freezer Bags Leakproof Silicone and Plastic Free Lunch Bags for Food Meat Fruit Veggies",FOOD_STORAGE_BAG,B07V4332K7,53120000,Amazon.com,,new,SPLF Direct,,$18.99,$14.99,2,American Express - 1004,,,Redacto@gmail.com,12/27/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,CHICAGO,IL,60654-7232,Shipped,AMZN_US(TBA022310288903),$29.98,$3.08,$33.06,FALSE,,FALSE,Redact,USD,
12/28/21,112-2983797-4668252,The Complete Anti-Inflammatory Diet for Beginners: A No-Stress Meal Plan with Easy Recipes to Heal the Immune System,ABIS_BOOK,1623159040,55101500,Amazon.com,2017-04-11 0:00:01,new,Amazon.com,,$18.99,$11.39,1,American Express - 1004,,,Redacto@gmail.com,12/29/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,LODA,IL,60948-9634,Shipped,AMZN_US(TBA135130691204),$11.39,$0.74,$12.13,FALSE,,FALSE,Redact,USD,
12/28/21,112-2983797-4668252,The Paleo 30-Day Challenge: A Paleo Cookbook to Lose Weight and Reboot Your Health,ABIS_BOOK,1641529695,55101500,Amazon.com,2020-01-14 0:00:01,new,Amazon.com,,$15.99,$13.43,1,American Express - 1004,,,Redacto@gmail.com,12/29/21,Redact Redacterson,1234 Redact Way,1234 Redact Way,LODA,IL,60948-9634,Shipped,AMZN_US(TBA135130691204),$13.43,$0.87,$14.30,FALSE,,FALSE,Redact,USD,
01/03/22,112-2902749-1159438,"SmartyPants Prenatal Formula Daily Gummy Multivitamin: Vitamin C, D3, & Zinc for Immunity, Gluten Free, Folate, Omega 3 Fish Oil (DHA/EPA), 120 Count (30 Day Supply)",VITAMIN,B00VFYYAC4,50501500,Amazon.com,,new,Amazon.com,,$69.72,$25.49,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142246154404),$25.49,$0.00,$25.49,,,,Redact,USD,
01/03/22,112-2902749-1159438,"PETIT MANON Vertical Fishing Rod Holder - Wall Mounted Fishing Rod Rack Storage, holds up to 6 Fishing Rods",STORAGE_RACK,B08Y8QVZ3N,24102000,Amazon.com,,new,StartDreamUS01,,$0.00,$19.79,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA141289028704),$19.79,$1.19,$20.98,FALSE,,FALSE,Redact,USD,
01/03/22,112-2902749-1159438,CRZ YOGA Women's Y Back Spaghetti Strap Workout Tank Tops - with Built in Shelf Bra Sports Camisole Padded Long Length,SHIRT,B07DFDDF64,53100000,Amazon.com,,new,CRZ YOGA,,$0.00,$26.00,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142246154404),$26.00,$1.56,$27.56,FALSE,,FALSE,Redact,USD,
01/03/22,112-2902749-1159438,Baby Bath Thermometer with Room Thermometer - Famidoc FDTH-V0-22 New Upgraded Sensor Technology for Baby Health Bath Tub Thermometer Floating Toy Thermometer (Blue),THERMOMETER,B07GC7H86T,60141000,Amazon.com,,new,Famidoc Direct US,,$0.00,$13.49,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA141697687904),$13.49,$0.81,$14.30,FALSE,,FALSE,Redact,USD,
01/03/22,112-8482592-3429036,Cultish: The Language of Fanaticism,ABIS_BOOK,62993151,55101500,Amazon.com,2021-06-15 0:00:01,new,Amazon.com,,$27.99,$25.19,1,American Express - 1004,,,Redacto@gmail.com,01/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA143050406504),$25.19,$1.51,$26.70,FALSE,,FALSE,Redact,USD,
01/03/22,111-0901128-3285842,"Unisom SleepTabs, Nighttime Sleep-aid, Doxylamine Succinate, 48 Tablets",HEALTH_PERSONAL_CARE,B002CVV1JO,42000000,Amazon.com,,new,Amazon.com,,$15.67,$11.73,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA143241576504),$11.73,$0.70,$12.43,FALSE,,FALSE,Redact,USD,
01/03/22,111-0901128-3285842,"TUMS Extra Strength Antacid Tablets for Chewable Heartburn & Acid Indigestion Relief, Multiple, Assorted Fruit, 330 Count",MEDICATION,B0035U184O,51000000,Amazon.com,,new,Amazon.com,,$21.38,$10.79,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA143241576504),$10.79,$0.65,$11.44,FALSE,,FALSE,Redact,USD,
01/04/22,112-5262215-4332231,"Little Llama, No Drama (Little Animals, Big Lessons)",ABIS_BOOK,B08X5ZC91H,55101500,Amazon.com,2021-02-23 0:00:01,new,Amazon.com,,$10.99,$10.99,1,American Express - 1004,,,Redacto@gmail.com,01/08/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA146812791704),$10.99,$0.66,$11.65,FALSE,,FALSE,Redact,USD,
01/05/22,112-8521162-4935412,BLUETREE 2 Pack Dog Doorbells Premium Quality Training Potty Great Dog Bells Adjustable Door Bell Dog Bells for Potty Training Your Puppy The Easy Way - 7 Extra Large Loud 1.4 DoorBells,PET_SUPPLIES,B01KG9WMWI,10111300,Amazon.com,,new,papikin,,$20.99,$10.55,1,American Express - 1004,,,Redacto@gmail.com,01/06/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA144738971404),$10.55,$0.63,$11.18,FALSE,,FALSE,Redact,USD,
01/08/22,112-2460783-2744238,"iRobot Authentic Replacement Parts- Clean Base Automatic Dirt Disposal Bags, 3-Pack, Compatible with All Clean Base models, White - 4640235",VACUUM_CLEANER_BAG,B07HJS45XQ,47121607,Amazon.com,,new,Amazon.com,,$16.99,$16.99,1,American Express - 1004,,,Redacto@gmail.com,01/12/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA152473428804),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
01/08/22,112-4680503-8391459,"iRobot Authentic Replacement Parts- Roomba s Series Replenishment Kit, (3 Filters, 3 Corner Brushes, 1 Set of Multi-Surface Rubber Brushes), Green - 4646124",PLUG_STOPPER,B07RLM3RV8,30181600,Amazon.com,,new,Amazon.com,,$74.99,$50.62,1,American Express - 1004,,,Redacto@gmail.com,01/08/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA459538743000),$50.62,$3.04,$53.66,FALSE,,FALSE,Redact,USD,
01/12/22,112-9746334-0581822,"Premium Wood Baby Closet Dividers,Set of 7,from Newborn to 24 Month,Baby Closet Organizers,Nursery Decor,Baby Clothes Organizers (Style-1)",CADDY,B08BL6NMXY,44111500,Amazon.com,,new,UwowS,,$0.00,$13.96,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA153285414204),$13.96,$0.84,$14.80,FALSE,,FALSE,Redact,USD,
01/12/22,112-9746334-0581822,"Wet Brush Speed Dry Hair Brush, Black - Vented Design and Ultra Soft HeatFlex Bristles Are Blow Dry Safe With Ergonomic Handle Manages Tangle and Uncontrollable Hair - Pain-Free",HAIR_BRUSH,B07Q12GWT7,53131600,Amazon.com,,new,Amazon.com,,$12.99,$11.79,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA153285414204),$11.79,$0.71,$12.50,FALSE,,FALSE,Redact,USD,
01/12/22,112-9746334-0581822,ATTRACO Women's Cotton Camisole Shelf Bra Spaghetti Straps Tank Top 2 Packs,SHIRT,B076P95HYL,53100000,Amazon.com,,new,ATTRACO,,$23.99,$20.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA154538146904),$20.99,$1.26,$22.25,FALSE,,FALSE,Redact,USD,
01/12/22,112-9746334-0581822,"Finnhomy Non-Slip Clothes Hangers for Baby and Kids 30-Pack Velvet Hangers with 10 Finger Clips, White",CLOTHES_HANGER,B07ZD231SF,53102507,Amazon.com,,new,HOME NICE,,$0.00,$21.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA154538146904),$21.99,$1.32,$23.31,FALSE,,FALSE,Redact,USD,
01/13/22,112-8786745-1483462,Cinderella Ate My Daughter: Dispatches from the Front Lines of the New Girlie-Girl Culture,ABIS_BOOK,61711535,55101500,Amazon.com,2012-01-31 0:00:01,new,Fifty Third Street Books,,$15.99,$13.59,1,American Express - 1004,,,Redacto@gmail.com,01/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,USPS(9241996901177408132320),$13.59,$0.82,$14.41,FALSE,,FALSE,Redact,USD,
01/17/22,112-3417300-2617047,"CRZ YOGA Womens Brushed Naked Feeling Workout Leggings 25"" / 28""- 7/8 High Waisted Compression Tummy Control Yoga Pants",PANTS,B09C8NSMZR,53100000,Amazon.com,,new,CRZ YOGA,,$26.00,$26.00,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/18/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA160062345404),$26.00,$1.56,$27.56,FALSE,,FALSE,Redact,USD,
01/17/22,112-3417300-2617047,"Aquaphor Lip Repair Stick, Lip Protectant, Moisturizing Lip Balm Multipack, 0.17 Oz, 2 Pack",LIP_BALM,B07SRRLVQ1,53131630,Amazon.com,,new,Amazon.com,,$9.99,$7.87,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/18/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA160079346504),$7.87,$0.47,$8.34,FALSE,,FALSE,Redact,USD,
01/20/22,111-6700107-1538619,Regalo Easy Fit Plastic Adjustable Extra Wide Baby Gate,TEMPORARY_GATE,B071937LW8,56121605,Amazon.com,,new,Amazon.com,,$29.99,$29.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,01/21/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1ZX612350310106798),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
01/21/22,112-5853638-6392265,CRZ YOGA Women's Naked Feeling Workout Leggings 25 Inches - 7/8 High Waist Yoga Tight Pants,PANTS,B09BMT7B8M,53100000,Amazon.com,,new,CRZ YOGA,,$30.00,$30.00,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/21/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA164569762904),$30.00,$1.80,$31.80,FALSE,,FALSE,Redact,USD,
01/21/22,112-5853638-6392265,CRZ YOGA Womens Butterluxe High Waisted Workout Leggings 28'' - High Waisted Full Length Soft Athletic Yoga Pants,PANTS,B09P1HQ1JH,53100000,Amazon.com,,new,CRZ YOGA,,$32.00,$32.00,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA164646807504),$32.00,$1.92,$33.92,FALSE,,FALSE,Redact,USD,
01/23/22,111-2545155-1016258,"beadsland 288 Piece Flat Back Crystal Rhinestones Round Gems,1.3mm-6.5mm,Dark Siam (SS30(6.4-6.6mm))",ART_SUPPLIES,B08GPSBN8X,60120000,Amazon.com,,new,EVERNEW,,$0.00,$7.99,3,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,01/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA166817873604),$23.97,$1.44,$25.41,FALSE,,FALSE,Redact,USD,
01/23/22,111-2545155-1016258,Omoone Women's Oversized Mid Long Denim Jacket Jean Biker Coat,COAT,B07L739HQQ,53100000,Amazon.com,,new,Omoone(7-15 Days Delivery),,$55.99,$37.96,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,01/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA166782516704),$37.96,$2.28,$40.24,FALSE,,FALSE,Redact,USD,
01/23/22,111-2271489-7491448,"22 Pieces Iron on Numbers T Shirt Heat Transfer Numbers 0 to 9 Jersey Numbers Soft Iron on Numbers for Team Uniform Sports T Shirt Football Basketball Baseball (Red,8 Inch)",IRON_ON_TRANSFER_DESIGN,B0956KDN9J,14122203,Amazon.com,,new,TrytoalWest,,$0.00,$11.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,01/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA166782516704),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
01/23/22,111-5286664-4661069,"McCormick Crushed Red Pepper, 4.62 oz",HERB,B0000EYPJS,50000000,Amazon.com,,new,Amazon.com,,$0.00,$5.32,1,Visa - 5239,,,Redacto@gmail.com,01/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA167029625304),$5.32,$0.00,$5.32,,,,Redact,USD,
01/23/22,111-9835766-2261854,"22 Pieces Iron on Numbers T Shirt Heat Transfer Numbers 0 to 9 Jersey Numbers Soft Iron on Numbers for Team Uniform Sports T Shirt Football Basketball Baseball (White,8 Inch)",IRON_ON_TRANSFER_DESIGN,B08QRH32MB,60122000,Amazon.com,,new,TrytoalWest,,$0.00,$11.59,1,American Express - 1004,,,Redacto@gmail.com,01/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA167836510304),$11.59,$0.70,$12.29,FALSE,,FALSE,Redact,USD,
01/23/22,111-7313252-2246604,BBHoping Maternity Tops Ruched Maternity Tunic Round Neck Long Sleeve Classic Shirts,SHIRT,B07X1H8YZ6,53100000,Amazon.com,,new,StrabElla,,$0.00,$18.99,1,American Express - 1004,,,Redacto@gmail.com,01/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA167785364004),$18.99,$1.14,$20.13,FALSE,,FALSE,Redact,USD,
01/25/22,112-8945640-4681037,"Cantonwalker Loose Women's Denim Jean Jacket,Oversize Vintage denim jacket,Long Sleeve Boyfriend Denim Jacket coat…",COAT,B07TD55Z8T,53100000,Amazon.com,,new,Cantonwalker,,$0.00,$42.99,1,American Express - 1004,,,Redacto@gmail.com,01/25/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z8Y82R30384617551),$42.99,$2.58,$45.57,FALSE,,FALSE,Redact,USD,
01/26/22,111-0334846-2597879,HOFISH Women's Maternity Pregnancy Seamless Breastfeeding Bras No Underwire,NIGHTGOWN_NIGHTSHIRT,B07GRPKCLK,53100000,Amazon.com,,new,MUMMY STORE,,$0.00,$25.49,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,01/27/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA171552567104),$25.49,$1.53,$27.02,FALSE,,FALSE,Redact,USD,
01/31/22,112-7808362-3009817,Knee Ability Zero,ABIS_BOOK,B09KNGDYGL,55101500,Amazon.com,2021-11-01 0:00:01,new,Amazon.com,,$24.75,$21.73,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,02/01/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,SUREPOST(1Z819YF63A33934247),$21.73,$1.30,$23.03,FALSE,,FALSE,Redact,USD,
01/31/22,112-7808362-3009817,ATG For Life,ABIS_BOOK,B09MYSNRZL,55101500,Amazon.com,2021-12-01 0:00:01,new,Amazon.com,,$24.75,$20.57,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,02/01/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,SUREPOST(1Z819YF63A33934247),$20.57,$1.23,$21.80,FALSE,,FALSE,Redact,USD,
01/31/22,112-3137573-9824212,"Nesco Deluxe Food VS-12 Vacuum Sealer, 130 Watts, Kit Bags & Viewing Lid, Compact, Silver",VACUUM_SEALER_MACHINE,B01KCK9W1K,52141500,Amazon.com,,new,Amazon.com,,$134.99,$109.99,1,American Express - 1004,,,Redacto@gmail.com,02/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z454R0A0361638748),$109.99,$6.60,$116.59,FALSE,,FALSE,Redact,USD,
01/31/22,112-0385779-8793822,"Traeger Grills Signature Blend 100% All-Natural Wood Pellets for Smokers and Pellet Grills, BBQ, Bake, Roast, and Grill, 20 lb. Bag",SOLID_FIRE_FUEL,B079DHNK3H,15101600,Amazon.com,,new,Amazon.com,,$18.99,$18.95,2,American Express - 1004,,,Redacto@gmail.com,02/01/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1ZY4V2960389674682),$37.90,$2.28,$40.18,FALSE,,FALSE,Redact,USD,
02/02/22,111-7501260-5201855,Behind Closed Doors: A Novel,ABIS_BOOK,1250132363,55101500,Amazon.com,2017-07-03 0:00:01,new,Amazon.com,,$16.99,$8.80,1,Visa - 5239,,,Redacto@gmail.com,02/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA179778108104),$8.80,$0.53,$9.33,FALSE,,FALSE,Redact,USD,
02/03/22,112-5586923-2957814,KISHIBORI SHOYU 12.2 fl oz(360ml). Pure artisan Japanese soy sauce. All natural barrel aged 1 year unadulterated and without preservatives,SAUCE,B005GQYXTC,50000000,Amazon.com,,new,Masterpiece JP,,$17.00,$11.98,1,American Express - 1004,,,Redacto@gmail.com,02/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA181816169904),$11.98,$0.00,$11.98,,,,Redact,USD,
02/05/22,111-8758455-3890634,Living Fully: Dare to Step into Your Most Vibrant Life,ABIS_BOOK,593238338,55101500,Amazon.com,2022-02-08 0:00:01,new,Amazon.com,,$26.00,$16.88,1,Visa - 5239,,,Redacto@gmail.com,02/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA184363144204),$16.88,$1.01,$17.89,FALSE,,FALSE,Redact,USD,
02/05/22,111-4817262-4966609,"Domeboro Medicated Soak Rash Relief (Burow’s Solution), 12 Count (Pack of 1) - Packaging May Vary",MEDICATION,B000GCKCCS,53131600,Amazon.com,,new,Amazon.com,,$14.99,$11.99,1,Visa - 5239,,,Redacto@gmail.com,02/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA184363144204),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
02/07/22,111-9819521-5412201,"Baskerville Ultra Dog Muzzle Dogs, Prevents Chewing and Biting, Basket Allows Panting and Drinking-Comfortable, Humane, Adjustable, Lightweight, Durable, 6 Sizes, Black/Tan",ANIMAL_MUZZLE,B00596TFVA,10000000,Amazon.com,,new,Amazon.com,,$24.98,$21.53,1,Visa - 5239,,,Redacto@gmail.com,02/08/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA185434754804),$21.53,$1.29,$22.82,FALSE,,FALSE,Redact,USD,
02/08/22,111-4096107-8757866,Motherhood Maternity Women's Indigo Blue Super Stretch Secret Fit Belly Skinny Denim Jean,PANTS,B08XGGWDTR,53100000,Amazon.com,,new,Amazon.com,,$39.98,$37.49,1,Visa - 5239,,,Redacto@gmail.com,02/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA186565708304),$37.49,$2.25,$39.74,FALSE,,FALSE,Redact,USD,
02/08/22,111-4096107-8757866,Motherhood Maternity Women's Super Stretch Secret Fit Belly Ankle Skinny Work Pant,PANTS,B01CJX6T2S,53100000,Amazon.com,,new,Amazon.com,,$39.98,$37.49,1,Visa - 5239,,,Redacto@gmail.com,02/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA186565708304),$37.49,$2.25,$39.74,FALSE,,FALSE,Redact,USD,
02/09/22,111-7547185-1668241,BQTQ 5 Pcs V Neck Tube Top Bra Padded Camisole Bra for Women Seamless Bralettes,BRA,B08SMB1SP8,53100000,Amazon.com,,new,BQTQ US,,$0.00,$16.99,1,American Express - 1004,,,Redacto@gmail.com,02/09/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA187452140204),$16.99,$1.02,$18.01,FALSE,,FALSE,Redact,USD,
02/09/22,111-1179521-2788203,"20 Pieces Acrylic Basket Labels Blank Acrylic Organizer Hanging Labels Kitchen Pantry Labels Sign, Basket Labels Clip On with Natural Twine and Chalk Marker for Storage Bins, Wedding Supplies (Clear)",LABEL,B095PF6219,14111537,Amazon.com,,new,JanGeo,,$0.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,02/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA187824997904),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
02/10/22,112-7991820-6820242,"Hatch Rest Baby Sound Machine, Night Light, and Time-to-Rise Sleep Trainer, White Noise Soother, Toddler Kids Alarm Clock, Nightlight, Control remotely via app",LAMP,B06XMRCC94,39111500,Amazon.com,,new,"Hatch Baby, Inc.",,$99.99,$59.99,1,Visa - 5239,,,Redacto@gmail.com,02/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA188689088604),$59.99,$3.60,$63.59,FALSE,,FALSE,Redact,USD,
02/13/22,112-1675148-2653827,"BeetElite Pre Workout Powder for Men & Women - Ultra High Purity Beet Root Powder for Energy & Stamina - Caffeine Free, Creatine Free, Vegan Nitric Oxide Supplement - Black Cherry, 7.1 oz",NUTRITIONAL_SUPPLEMENT,B00V73NRJU,50500000,Amazon.com,,new,humanN,,$39.95,$39.95,1,American Express - 1004,,,Redacto@gmail.com,02/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA193096948704),$39.95,$0.00,$39.95,,,,Redact,USD,
02/14/22,112-8648037-7644247,"116130 Replacement Filter H Compatiable for Winix 5500-2F and Winix AM80, 2 Pack HEPA Filter and 2 Pack Activated Carbon Filter",HVAC_AIR_FILTER,B0969FVJN5,40161505,Amazon.com,,new,FilterEra,,$46.99,$40.99,2,American Express - 1004,,,Redacto@gmail.com,02/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA193238591304),$81.98,$4.92,$86.90,FALSE,,FALSE,Redact,USD,
02/14/22,112-5803443-7871468,Nando's Lemon & Herb Peri-Peri Sauce 125g,SAUCE,B0170DURUC,50000000,Amazon.com,,new,Nando's PERi-PERi Authorized Store,,$0.00,$10.95,2,American Express - 1004,,,Redacto@gmail.com,02/15/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA194051311404),$21.90,$0.00,$21.90,,,,Redact,USD,
02/15/22,112-0001417-5405866,Mizkan Vinegar Sushi Seasoning for Sushi or Salad - 24 fl oz (710ml),VINEGAR,B01ICROLXE,50000000,Amazon.com,,new,Shop Simply Good,,$0.00,$14.99,1,American Express - 1004,,,Redacto@gmail.com,02/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA195212741104),$14.99,$0.00,$14.99,,,,Redact,USD,
02/23/22,112-5739678-3650625,"Bona Hardwood Floor Cleaner Refill, 128 Fl Oz",CLEANING_AGENT,B000ARPVIY,47131800,Amazon.com,,new,Amazon.com,,$21.99,$17.97,1,American Express - 1004,,,Redacto@gmail.com,02/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA004215303104),$17.97,$1.08,$19.05,FALSE,,FALSE,Redact,USD,
02/27/22,111-2086689-1481046,Women's Square Open Toe Heeled Woven Leather Sandals Stiletto Slip On Quilted High Heels,SANDAL,B091YN6HY8,53110000,Amazon.com,,new,NovaPow,,$46.99,$39.99,1,Visa - 5239,,,Redacto@gmail.com,02/28/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA008938514604),$39.99,$2.40,$42.39,FALSE,,FALSE,Redact,USD,
02/27/22,111-1594390-5039402,Steve Madden Women's Kenley Heeled Sandal,SANDAL,B08W8GZWBB,53110000,Amazon.com,,new,Hot Heels Shoetique,,$129.95,$69.99,1,Visa - 5239,,,Redacto@gmail.com,02/28/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,USPS(9261290233786280711364),$69.99,$4.20,$74.19,FALSE,,FALSE,Redact,USD,
03/01/22,112-3565617-6939404,Twelve Hours' Sleep by Twelve Weeks Old: A Step-by-Step Plan for Baby Sleep Success,ABIS_BOOK,525949593,55101500,Amazon.com,2006-01-19 0:00:01,new,Amazon.com,,$22.00,$18.99,1,Visa - 5239,,,Redacto@gmail.com,03/01/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA010663607404),$18.99,$1.14,$20.13,FALSE,,FALSE,Redact,USD,
03/01/22,112-5038409-9392216,Vogue: The Editor's Eye,ABIS_BOOK,1419704400,55101500,Amazon.com,2012-09-25 0:00:01,new,Amazon.com,,$85.00,$51.99,1,American Express - 1004,,,Redacto@gmail.com,03/02/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA011707231604),$51.99,$3.12,$55.11,FALSE,,FALSE,Redact,USD,
03/01/22,112-5038409-9392216,The Little Dictionary of Fashion: A Guide to Dress Sense for Every Woman,ABIS_BOOK,810994615,55101500,Amazon.com,2007-11-01 0:00:01,new,Amazon.com,,$21.99,$15.58,1,American Express - 1004,,,Redacto@gmail.com,03/02/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA011707231604),$15.58,$0.93,$16.51,FALSE,,FALSE,Redact,USD,
03/06/22,112-9689680-8719447,"Stonewall Kitchen Country Ketchup, 16 Ounces",CONDIMENT,B00427PDDQ,50000000,Amazon.com,,new,Stonewall Kitchen,,$9.75,$6.95,2,American Express - 1004,,,Redacto@gmail.com,03/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1Z2W85A50376450958),$13.90,$0.00,$13.90,,,,Redact,USD,
03/06/22,111-5299061-3485863,"Purse Organizer Insert, Felt Bag organizer with zipper, Handbag & Tote Shaper, For Speedy Neverfull Tote, 6 Sizes",CADDY,B07DKBQBTS,53121600,Amazon.com,,new,Gine Lady,,$0.00,$28.88,1,Visa - 5239,,,Redacto@gmail.com,03/06/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA016969485304),$28.88,$1.73,$30.61,FALSE,,FALSE,Redact,USD,
03/07/22,114-0603496-3972254,"VELCRO Brand For Fabrics | Permanent Sticky Back Fabric Tape for Alterations and Hemming | Peel and Stick - No Sewing, Gluing, or Ironing | Pre-Cut Ovals, 1 x 3/4 inch, White - 8 Sets",HOOK_LOOP_FASTENER,B00N0W8FCK,44122109,Amazon.com,,new,Amazon.com,,$6.99,$3.84,1,American Express - 1004,,,Redacto@gmail.com,03/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA018001139004),$3.84,$0.23,$4.07,FALSE,,FALSE,Redact,USD,
03/08/22,111-2777921-2349012,"Umbra Cappa Curtain Rod, Includes 2 Matching Finials, Brackets & Hardware, 36 to 66-Inches, Brass",HANGING_ROD,B06XRPZ876,52131702,Amazon.com,,new,Amazon.com,,$40.00,$24.64,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,03/08/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA502531468000),$24.64,$1.48,$26.12,FALSE,,FALSE,Redact,USD,
03/10/22,112-9375117-8001817,"EFAILY 5pcs Assembly Action Figure Display Holder Base for Gundam,Doll Model Support Stand Compatible with HG RG SD SHF Gundam 1/144 Toy(White)",ITEM_STAND,B085M5H89B,60141000,Amazon.com,,new,EFAILY,,$0.00,$11.39,1,American Express - 1004,,,Redacto@gmail.com,03/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA021280733704),$11.39,$0.68,$12.07,FALSE,,FALSE,Redact,USD,
03/11/22,112-8253641-6091411,"Kate and Laurel Arendahl Traditional Arch Mirror, 19"" x 30.75"" , Gold, Baroque Inspired Wall Decor",HOME_MIRROR,B087GCPXBP,56101500,Amazon.com,,new,Amazon.com,,$119.99,$138.99,1,Visa - 5239,,,Redacto@gmail.com,03/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,UPS(1Z5809600391499794),$138.99,$8.34,$147.33,FALSE,,FALSE,Redact,USD,
03/12/22,112-9823047-2854630,"Nespresso Capsules VertuoLine, Barista Flavored Pack, Mild Roast Coffee, 30 Count Coffee Pods, Brews 7.77 Ounce",COFFEE,B0851ZQCX6,50201706,Amazon.com,,new,Amazon.com,,$37.50,$37.50,1,American Express - 1004,,,Redacto@gmail.com,03/12/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA023449084004),$37.50,$0.00,$37.50,,,,Redact,USD,
03/13/22,112-5793373-7521834,"Philips Hue Bloom White and Color Corded Dimmable LED (Bluetooth & Zigbee) Smart Light Table Lamp, White",LIGHT_BULB,B08CK1L7BZ,39111500,Amazon.com,,new,Quiverr,,$69.99,$69.99,1,American Express - 1004,,,Redacto@gmail.com,03/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA025001486904),$69.99,$4.20,$74.19,FALSE,,FALSE,Redact,USD,
03/16/22,112-3604780-4078645,"Nerf Dog Rubber 3-Ring Tug Dog Toy, Lightweight, Durable and Water Resistant, 10.5 Inches, for Medium/Large Breeds, Single Unit, Blue/Green/Orange",PET_TOY,B07CMC5LGC,10111300,Amazon.com,,new,Amazon.com,,$14.99,$4.99,2,American Express - 1004,,,Redacto@gmail.com,03/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA028078905004),$9.98,$0.60,$10.58,FALSE,,FALSE,Redact,USD,
03/16/22,111-3404101-7027449,"AMBOR Ankle Weights, 1 Pair 2 3 4 5 Lbs Adjustable Leg Weights, Strength Training Ankle Weights for Men Women Kids, Wrist Weights Strap Set for Walking Running Gym Fitness Workout 2 Pack",WEARABLE_WEIGHT,B08FX53G53,49201600,Amazon.com,,new,YSYKJ,,$25.99,$16.14,1,American Express - 1004,,,Redacto@gmail.com,03/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA028981622304),$16.14,$0.97,$17.11,FALSE,,FALSE,Redact,USD,
03/16/22,111-3404101-7027449,"BalanceFrom Anti-Burst and Slip Resistant Exercise Ball Yoga Ball Fitness Ball Birthing Ball with Quick Pump, 2,000-Pound Capacity",RECREATION_BALL,B07S4GQRY1,49000000,Amazon.com,,new,Amazon.com,,$0.00,$17.43,1,American Express - 1004,,,Redacto@gmail.com,03/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA028981622304),$17.43,$1.05,$18.48,FALSE,,FALSE,Redact,USD,
03/16/22,111-3404101-7027449,XIECCX Mini Yoga Balls Exercise Pilates Ball Therapy Ball Balance Ball Bender Ball Barre Equipment 1PC for Home Stability Squishy Training PhysicalCore Training with Pump,RECREATION_BALL,B097MDLPB1,49161500,Amazon.com,,new,Shangshi Store,,$0.00,$9.90,1,American Express - 1004,,,Redacto@gmail.com,03/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA028981622304),$9.90,$0.59,$10.49,FALSE,,FALSE,Redact,USD,
03/20/22,111-5885947-9178605,"Furhaven Pet Bed for Dogs and Cats - Faux Fur and Velvet Sofa-Style Egg Crate Orthopedic Dog Bed, Removable Machine Washable Cover - Smoke Gray, Medium",PET_BED_MAT,B074F318FR,10131508,Amazon.com,,new,Amazon.com,,$32.99,$32.99,1,Visa - 5239,,,Redacto@gmail.com,03/21/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA033114054804),$32.99,$1.98,$34.97,FALSE,,FALSE,Redact,USD,
03/22/22,112-4538018-4465007,"WDF 125pcs Gold Plastic Plates with Disposable Plastic Silverware- Gold Rim Square Plastic Dinnerware include 25 Dinner Plates,25 Salad Plates,25 Forks, 25 Knives,25 Spoons",KITCHEN,B07NXTCXJZ,52150000,Amazon.com,,new,WDF partyware,,$41.28,$35.54,1,Visa - 5239,,,Redacto@gmail.com,03/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA035623016504),$35.54,$2.13,$37.67,FALSE,,FALSE,Redact,USD,
03/22/22,112-4538018-4465007,100 Gold Plastic Cups 9 Oz Clear Plastic Cups Old Fashioned Tumblers Gold Rimmed Cups Fancy Disposable Wedding Cups Elegant Party Cups with Gold Rim,DRINKING_CUP,B079YY714G,52150000,Amazon.com,,new,Home & Party,,$39.99,$22.99,1,Visa - 5239,,,Redacto@gmail.com,03/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA036005645804),$22.99,$1.38,$24.37,FALSE,,FALSE,Redact,USD,
03/22/22,112-4538018-4465007,"100 Pack Disposable White and Gold Paper Cocktail Napkins for Wedding Reception, Polka Dot Party Supplies (5 x 5 In)",DISPOSABLE_NAPKIN,B079KXZLB9,52151500,Amazon.com,,new,Sparkle and Bash,,$0.00,$11.99,1,Visa - 5239,,,Redacto@gmail.com,03/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA035623016504),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
03/22/22,112-4538018-4465007,"Glass Drink Dispenser for Parties - Set of 2-1 Gallon Glass Jar Beverage Dispensers with Stand, Glass Water Dispenser Countertop for Weddings, Sun Tea Jar, Lemonade & Laundry Detergent Dispenser",GRAVITY_BEVERAGE_DISPENSER,B081D8XDCH,52152100,Amazon.com,,new,Amazon.com,,$39.99,$39.99,1,Visa - 5239,,,Redacto@gmail.com,03/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA036005645804),$39.99,$2.40,$42.39,FALSE,,FALSE,Redact,USD,
03/25/22,112-2820061-8100267,"ETUDE SoonJung 2x Barrier Intensive Cream 60ml (21AD) | Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Water-oil Balance Panthenol Heals Damaged Skin | K-beauty",SKIN_MOISTURIZER,B091PN6NPT,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$17.00,1,Visa - 5239,,,Redacto@gmail.com,03/25/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA038304116404),$17.00,$1.02,$18.02,FALSE,,FALSE,Redact,USD,
03/25/22,112-2820061-8100267,Kenra Volume Spray 25 | Super Hold Hairspray | All Hair Types,HAIR_STYLING_AGENT,B0013V5MGW,53131602,Amazon.com,,new,Amazon.com,,$7.49,$7.49,1,Visa - 5239,,,Redacto@gmail.com,03/25/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA038304116404),$7.49,$0.45,$7.94,FALSE,,FALSE,Redact,USD,
03/25/22,112-5165462-1834637,"Nespresso Capsules VertuoLine, Barista Flavored Pack, Mild Roast Coffee, 30 Count Coffee Pods, Brews 7.77 Ounce",COFFEE,B0851ZQCX6,50201706,Amazon.com,,new,Amazon.com,,$37.50,$37.50,1,American Express - 1004,,,Redacto@gmail.com,03/25/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA038248811404),$37.50,$0.00,$37.50,,,,Redact,USD,
03/30/22,112-8478567-4048252,The Spice Way - Premium Aleppo Pepper |4 oz.| Crushed Aleppo Pepper Flakes (Halaby Pepper/Pul Biber/Marash Pepper/Aleppo Chili Flakes) Popular in Turkish and Middle Eastern/Mediterranean cooking,HERB,B071KH1X16,50000000,Amazon.com,,new,The Spice Way,,$7.96,$7.99,1,American Express - 1004,,,Redacto@gmail.com,03/30/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA043920636704),$7.99,$0.00,$7.99,,,,Redact,USD,
04/02/22,111-8454560-1936217,"Boon Twig Grass and Lawn Drying Rack Accessory, White",DRYING_RACK,B007D1SHUY,47111503,Amazon.com,,new,Amazon.com,,$4.99,$4.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$4.99,$0.30,$5.29,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"Munchkin Portable Diaper Changing Kit with Changing Pad and Wipes Case, Grey",BABY_PRODUCT,B0035ER592,56101800,Amazon.com,,new,Amazon.com,,$14.99,$14.75,2,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$29.50,$1.78,$31.28,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,Baby Shusher The Sleep Miracle Sound Machine Rhythmic Human Voice Shushes Baby to Sleep Every Time The Quickest Way to Get Baby to Sleep (Baby Shusher),BABY_PRODUCT,B00D2JN87I,56101800,Amazon.com,,new,The Shusher Company,,$34.99,$34.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048447976804),$34.99,$2.10,$37.09,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,Battery Operated Stroller Fan Flexible Tripod Clip On Fan with 3 Speeds and Rotatable Handheld Personal Fan for Car Seat Crib Bike Treadmill (Black),ELECTRIC_FAN,B085L71L9R,40101600,Amazon.com,,new,airdance,,$35.99,$29.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"Qunlions life Glopole Baby Nail Trimmer File with Light - Safe Electric Nail Clippers Kit for Newborn Infant Toddler Kids Toes and Fingernails - Care, Polish and Trim - Battery Operated (AA), Pink",BEAUTY,B07MDLBSP6,53131600,Amazon.com,,new,slmy2021,,$13.06,$11.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"3-Tier Rolling Carts with Wheels Storage Cart Makeup Cart with Roller Wheels Mobile Storage Organizer for Kitchen, Bathroom, Office, Coffee Bar",UTILITY_CART_WAGON,B089W25HLL,52152000,Amazon.com,,new,Linkbroad,,$65.99,$35.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048117212004),$35.99,$2.16,$38.15,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"Burt's Bees Baby - Hooded Towels, Absorbent Knit Terry, Super Soft Single Ply, 100% Organic Cotton (Little Ducks, 2-Pack)",TOWEL,B081SFS6SW,52120000,Amazon.com,,new,Amazon.com,,$24.95,$26.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$26.99,$1.62,$28.61,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"Frida Mom Hospital Packing Kit for Labor, Delivery, & Postpartum | Nursing Gown, Socks, Peri Bottle, Disposable Underwear, Ice Maxi Pads, Pad Liners, Perineal Foam, Toiletry Bag, 15 Piece Set",HEALTH_PERSONAL_CARE,B07TW86LR8,53131600,Amazon.com,,new,Amazon.com,,$99.99,$99.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA047972800504),$99.99,$6.00,$105.99,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"Philips AVENT Soothie Snuggle Pacifier Holder with Detachable Pacifier, 0m+, Elephant, SCF347/03",PACIFIER,B07HBSTHG6,53131500,Amazon.com,,new,Amazon.com,,$14.99,$14.95,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$14.95,$0.90,$15.85,FALSE,,FALSE,Redact,USD,
04/02/22,111-8454560-1936217,"4moms rockaRoo and mamaRoo Infant Insert, For Baby, Infant, and Toddler, Machine Washable, Cool Mesh Fabric, Modern Design, 1 Count",CHAIR,B07KYZ1G5R,56101504,Amazon.com,,new,Amazon.com,,$39.99,$35.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048510318004),$35.99,$2.16,$38.15,FALSE,,FALSE,Redact,USD,
04/03/22,111-8247865-1210634,"(Pack of 50) Wooden Clothespins About 2-7/8"" Long",CLOTHES_PIN,B0852TXHZG,47111500,Amazon.com,,new,Qianqianmao,,$0.00,$7.33,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048447976804),$7.33,$0.44,$7.77,FALSE,,FALSE,Redact,USD,
04/03/22,111-0289003-0033053,"haakaa Silicone Colostrum Collector Set Reusable Silicone Colostrum Collector for Breastfeeding to Collect, Feed & Store, with 2 Clear Storage Cases & 2 White Cotton Wipe (0.1oz/4ml, 12 PK)",BABY_PRODUCT,B095JZ5RNK,56101800,Amazon.com,,new,Haakaa Official Store,,$0.00,$38.98,1,Visa - 5239,,,Redacto@gmail.com,04/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048446382104),$38.98,$2.34,$41.32,FALSE,,FALSE,Redact,USD,
04/03/22,111-0289003-0033053,haakaa Manual Breast Pump with Base 4oz/100ml+Lid,BREAST_PUMP,B07G43F123,53131600,Amazon.com,,new,Haakaa Official Store,,$34.99,$26.59,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048447976804),$26.59,$1.60,$28.19,FALSE,,FALSE,Redact,USD,
04/03/22,111-0289003-0033053,"Headband Holder for Baby Girl, Hanging Storage Organizer Newborn Headbands, Bow",ACCESSORY,B085ZSL913,53100000,Amazon.com,,new,PACMAXI,,$20.99,$13.99,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048447976804),$13.99,$0.84,$14.83,FALSE,,FALSE,Redact,USD,
04/03/22,111-0289003-0033053,"Aquaphor Baby Healing Ointment Advanced Therapy Skin Protectant, Dry Skin and Diaper Rash Ointment, 7 Oz Tube",SKIN_MOISTURIZER,B0107QP1VE,53131600,Amazon.com,,new,Amazon.com,,$10.49,$9.59,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048447976804),$9.59,$0.58,$10.17,FALSE,,FALSE,Redact,USD,
04/03/22,111-0289003-0033053,"Polysporin First Aid Antibiotic Ointment Without Neomycin, Travel Size, 1 Oz Tube",HEALTH_PERSONAL_CARE,B000GCNDE2,42000000,Amazon.com,,new,Amazon.com,,$12.14,$7.49,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA048447976804),$7.49,$0.45,$7.94,FALSE,,FALSE,Redact,USD,
04/03/22,111-6948473-0073808,"Diaper Rash Cream Spray by Boogie Bottoms, Travel Friendly No-Rub Touch Free Application for Sensitive Skin, from The Maker of Boogie Wipes, Over 200 Sprays per Bottle, 1.7 oz…",SKIN_PROTECTANT,B07DP2C9B7,53131600,Amazon.com,,new,M&A SafeChoice,,$10.00,$21.00,1,Visa - 5239,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,USPS(9300120111409235766543),$21.00,$1.26,$22.26,FALSE,,FALSE,Redact,USD,
04/03/22,112-7063796-1496221,ABC's For the little g's,BOOK,998532207,55101500,Amazon.com,2021-03-16 0:00:01,new,Little Giants | Giant Shorties,,$19.95,$19.56,1,American Express - 1004,,,Redacto@gmail.com,04/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA178563741101),$19.56,$1.78,$21.34,FALSE,,FALSE,Redact,USD,
04/03/22,112-7063796-1496221,123s for The Little gs,ABIS_BOOK,099853224X,55101500,Amazon.com,2021-03-01 0:00:01,new,Amazon.com,,$19.95,$19.95,1,American Express - 1004,,,Redacto@gmail.com,04/03/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA178525515201),$19.95,$1.82,$21.77,FALSE,,FALSE,Redact,USD,
04/03/22,111-6184016-2965805,"Burt's Bees Baby Baby Girls' Mittens, No-Scratch Mitts, 100% Organic Cotton, Set of 3",ACCESSORY,B01LZC5UWF,53100000,Amazon.com,,new,Amazon.com,,$12.95,$12.95,1,Visa - 5239,,,Redacto@gmail.com,04/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA049155334404),$12.95,$0.78,$13.73,FALSE,,FALSE,Redact,USD,
04/03/22,111-6184016-2965805,"Burt's Bees Baby unisex-baby baby-boys Socks, 6-pack Ankle Socks With Non-slip Grips, Made With Organic Cotton",SOCKS,B082WZFQV6,53102402,Amazon.com,,new,Amazon.com,,$13.95,$12.95,1,Visa - 5239,,,Redacto@gmail.com,04/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA049155334404),$12.95,$0.78,$13.73,FALSE,,FALSE,Redact,USD,
04/03/22,111-6184016-2965805,"Burt's Bees Baby - Burp Cloths, 5-Pack Extra Absorbent 100% Organic Cotton Burp Cloths, Cloud White Solids",TOWEL,B00N4PNYRY,53100000,Amazon.com,,new,Amazon.com,,$22.95,$22.00,0,Visa - 5239,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/03/22,111-6344383-3242629,"Lansinoh Stay Dry Disposable Nursing Pads for Breastfeeding, 100 Count",BABY_PRODUCT,B0070SKP1O,53131600,Amazon.com,,new,Amazon.com,,$11.69,$9.70,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050383416604),$9.70,$0.58,$10.28,FALSE,,FALSE,Redact,USD,
04/03/22,111-2743260-9522607,mushie Muslin Baby Swaddle Blanket | 100% Organic Cotton (Sage),BLANKET,B08JGNRVQ8,52121500,Amazon.com,,new,mushie USA,,$0.00,$18.00,0,Visa - 5239,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/03/22,111-2743260-9522607,"Muslin Burp Cloths 10 Pack Large 100% Cotton Hand Washcloths 6 Layers Extra Absorbent and Soft by Comfy Cubs (White, Pack of 10)",TOWEL,B08CBJ2SSQ,52120000,Amazon.com,,new,Comfy Cubs,,$24.95,$17.95,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$17.95,$1.08,$19.03,FALSE,,FALSE,Redact,USD,
04/03/22,111-2743260-9522607,Ely’s & Co. Cotton Muslin Swaddle Blanket 1-Pack for Baby Girl — 100% Cotton Muslin Extra-Large Swaddle Blankets (47” x 47”) Rosewater Pink,BLANKET,B08PMJC5RY,52120000,Amazon.com,,new,A Top Shelf,,$15.00,$15.00,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$15.00,$0.90,$15.90,FALSE,,FALSE,Redact,USD,
04/03/22,111-7116518-4841043,"LifeTree Baby Swaddle Blankets, Muslin Swaddle Blankets Boys Girls Baby Muslin Swaddling Wrap Receiving Blanket Neutral for Newborn, 100% Organic Cotton, Large 47 x 47 inches, Solid Color, 2 Pack",BLANKET,B0932T5LCZ,52120000,Amazon.com,,new,Lebze,,$33.90,$25.90,1,Visa - 5239,,,Redacto@gmail.com,04/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA049155334404),$25.90,$1.55,$27.45,FALSE,,FALSE,Redact,USD,
04/04/22,112-1604549-4963411,,,B00PJCYOWE,,Amazon.com,,new,Amazon.com,,$0.00,$7.17,1,American Express - 1004,,,Redacto@gmail.com,04/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA049535312104),$7.17,$0.00,$7.17,,,,Redact,USD,
04/04/22,111-2613996-5997027,"Tommee Tippee Closer to Nature Baby Bottles | Slow Flow Breast-Like Nipple with Anti-Colic Valve (9oz, 2 Count)",BABY_BOTTLE,B00HR34VF8,42230000,Amazon.com,,new,Amazon.com,,$17.43,$17.43,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$17.43,$1.05,$18.48,FALSE,,FALSE,Redact,USD,
04/04/22,111-2613996-5997027,"Tommee Tippee Closer to Nature Baby Bottle, Anti-Colic, Breast-like Nipple, BPA-Free - Extra Slow Flow, 5 Ounce (4 Count), Translucent (522568)",BABY_BOTTLE,B00K5KVC98,42230000,Amazon.com,,new,Amazon.com,,$31.60,$19.07,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$19.07,$1.14,$20.21,FALSE,,FALSE,Redact,USD,
04/04/22,111-2613996-5997027,"Comotomo Baby Bottle Bundle, Pink, 1 Set",BABY_BOTTLE,B07Q62NL7L,24120000,Amazon.com,,new,Amazon.com,,$59.99,$47.99,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$47.99,$2.88,$50.87,FALSE,,FALSE,Redact,USD,
04/04/22,111-2613996-5997027,"Philips AVENT Fast Baby Bottle Warmer with Smart Temperature Control and Automatic Shut-Off, SCF358/00",BABY_PRODUCT,B0876T9DQZ,52141500,Amazon.com,,new,Amazon.com,,$49.99,$49.95,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$49.95,$3.00,$52.95,FALSE,,FALSE,Redact,USD,
04/04/22,111-2613996-5997027,"Burt's Bees Baby - Bassinet Sheet, Fitted Unisex Boy Girl 100% Organic Jersey Cotton Bassinet Sheet for Standard Bassinet Mattress (Cloud White)",FITTED_SHEET,B07DRBHH2F,52121500,Amazon.com,,new,Amazon.com,,$10.95,$10.99,1,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$10.99,$0.66,$11.65,FALSE,,FALSE,Redact,USD,
04/04/22,111-2613996-5997027,"Burt's Bees Baby - Fitted Crib Sheet, Solid Color, 100% Organic Cotton Crib Sheet for Standard Crib and Toddler Mattresses (Cloud White)",FITTED_SHEET,B00O03RZNS,52121500,Amazon.com,,new,Amazon.com,,$17.95,$17.00,2,Visa - 5239,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050403249604),$34.00,$2.04,$36.04,FALSE,,FALSE,Redact,USD,
04/05/22,111-7545014-4572214,"Munchkin Secure Grip Waterproof Diaper Changing Pad, 16"" x 31""",CHANGING_PAD,B00O64QJOC,56101800,Amazon.com,,new,Amazon.com,,$32.99,$29.99,0,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/05/22,111-7545014-4572214,"Mai Bebe Baby Bow Headbands - 10PCS Baby Girl Nylon Headbands - Bows on Nylon Headband for Newborn, Infant, Toddler, Preemie - Everyday Bebe Collection",HAIRBAND,B08SLBFMKM,53102513,Amazon.com,,new,Mai Bebe,,$50.00,$18.75,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050808901504),$18.75,$1.13,$19.88,FALSE,,FALSE,Redact,USD,
04/05/22,111-7545014-4572214,"Minnebaby Velvet Baby Hangers 30 Pack, Ultra Thin No Slip Nursery Clothes Hangers with 6 Pcs Cute Clothing Dividers for Baby Boys & Girls Closet Organizer, Beige Kids Hangers",CLOTHES_HANGER,B08HQN1GB4,53102507,Amazon.com,,new,Minne Baby,,$26.99,$22.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050806010504),$22.99,$1.38,$24.37,FALSE,,FALSE,Redact,USD,
04/05/22,111-5401989-9905025,"Munchkin Waterproof Changing Pad Liners, 3 Count",CHANGING_PAD_COVER,B009UPUJIY,53102300,Amazon.com,,new,Amazon.com,,$11.99,$9.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA050806010504),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
04/05/22,112-1944994-6385054,The Power Law: Venture Capital and the Art of Disruption,ABIS_BOOK,241356520,55101500,Amazon.com,2022-01-25 0:00:01,new,TheWorldShopUSA,,$0.00,$31.93,1,American Express - 1004,,,Redacto@gmail.com,04/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,USPS(9241990289105002349250),$31.93,$2.91,$34.84,FALSE,,FALSE,Redact,USD,
04/07/22,113-7000597-3918625,Keekaroo Peanut Changer - Vanilla,RECREATION_BALL,B00KSW970Y,56101800,Amazon.com,,new,Keekaroo Brand Products,,$92.95,$129.95,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/08/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,FedEx(271794093546),$129.95,$7.80,$137.75,FALSE,,FALSE,Redact,USD,
04/07/22,113-2412397-0655465,"Portable Changing Pad for Baby|Travel Baby Changing Pads for Moms, Dads|Waterproof Portable Changing Mat with Built-in Pillow|Excellent Baby Shower/Registry Gifts",CHANGING_PAD,B085PXT49C,52121500,Amazon.com,,new,Baby's Pop,,$15.99,$9.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA053350890004),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
04/07/22,113-2412397-0655465,Pro Goleem 2 Pack Satin Soft Crib Sheet Great for Baby with Sensitive Hair Silky Baby Mattress Sheet White and Gray Unisex 52’’x28’’x8’’ Fitted Sheets,BED_LINEN,B07ZQ9L62W,52121509,Amazon.com,,new,Pro Goleem Store,,$23.99,$19.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA053350890004),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
04/07/22,113-2412397-0655465,Pro Goleem Satin Bassinet Sheet 2 Pack Great for Baby Hair Soft Silk Feeling Sheet for Cradle or Bassinet Pad＆Mattress for Boys and Girls Gift for Newborn and Infant White and Gray,FITTED_SHEET,B086MQ1HBT,52121500,Amazon.com,,new,Pro Goleem Store,,$23.99,$19.99,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,04/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA053350890004),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
04/10/22,111-3284162-9494606,"mDesign Soft Fabric Dresser Drawer and Closet Storage Organizer for Child/Kids Room or Nursery - 8 Section Rectangular Organizer - Fun Polka Dot Print, 2 Pack - Pink with White Dots",CADDY,B07D4LTNN1,44111500,Amazon.com,,new,MetroDecor,,$15.98,$12.99,1,Visa - 5239,,,Redacto@gmail.com,04/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA056966085304),$12.99,$0.78,$13.77,FALSE,,FALSE,Redact,USD,
04/10/22,111-3284162-9494606,"mDesign Soft Fabric Dresser Drawer and Closet Storage Organizer for Child/Kids Room or Nursery - Roomy Open Rectangular Compartment Organizer - Fun Polka Dot Print, 4 Pack - Pink/White",CADDY,B07D4L3D3F,44111500,Amazon.com,,new,MetroDecor,,$25.98,$17.99,1,Visa - 5239,,,Redacto@gmail.com,04/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA056966085304),$17.99,$1.08,$19.07,FALSE,,FALSE,Redact,USD,
04/12/22,112-7543238-3045041,Unisex Nike Vapor Crew Football Sock,SOCKS,B01LQZG94S,53102402,Amazon.com,,new,Arezzo G,,$0.00,$19.99,1,American Express - 1004,,,Redacto@gmail.com,04/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,UPS(1Z4W11W40393637950),$19.99,$1.82,$21.81,FALSE,,FALSE,Redact,USD,
04/12/22,111-8292697-2931422,"mDesign Soft Fabric Polka Dot Dresser Drawer and Closet Storage Organizer, Bin for Child/Kids Room, Nursery, Playroom, Bedroom, 4 Pack - Pink/White",HOME,B07WSCHTF8,52140000,Amazon.com,,new,MetroDecor,,$15.98,$9.95,1,American Express - 1004,,,Redacto@gmail.com,04/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA059846515504),$9.95,$0.60,$10.55,FALSE,,FALSE,Redact,USD,
04/12/22,111-8292697-2931422,"mDesign Soft Fabric Dresser Drawer and Closet Storage Organizer for Child/Kids Room or Nursery - Roomy Open Rectangular Compartment Organizer - Fun Polka Dot Print, 4 Pack - Pink/White",CADDY,B07D4L3D3F,44111500,Amazon.com,,new,MetroDecor,,$25.98,$17.99,0,American Express - 1004,,,Redacto@gmail.com,,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,,,$0.00,$0.00,$0.00,,,,Redact,USD,
04/13/22,111-9330368-5161835,"Amazon Basics Kids Velvet, Non-Slip Clothes Hangers, Beige - Pack of 30",CLOTHES_HANGER,B0112W79N0,53102507,Amazon.com,,new,Amazon.com,,$19.99,$19.49,1,American Express - 1004,,,Redacto@gmail.com,04/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA060082278404),$19.49,$1.17,$20.66,FALSE,,FALSE,Redact,USD,
04/13/22,111-5623607-5329862,"mDesign Soft Fabric Polka Dot Dresser Drawer and Closet Storage Organizer for Child/Kids Room, Nursery, Playroom - Divided Organizer Bin - Textured Print, 3 Pack - Pink/White",CADDY,B07VLJTR3W,44111500,Amazon.com,,new,MetroDecor,,$19.98,$13.99,1,Visa - 5239,,,Redacto@gmail.com,04/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA060514359904),$13.99,$0.84,$14.83,FALSE,,FALSE,Redact,USD,
04/13/22,112-2534541-2534620,"Spacesaver Premium Vacuum Storage Bags. 80% More Storage! Hand-Pump for Travel! Double-Zip Seal and Triple Seal Valve! Vacuum Sealer Bags for Comforters, Blankets, Bedding, Clothing! (Jumbo 6-Pack)",STORAGE_BAG,B00X8KSKF6,47121600,Amazon.com,,new,WebDealsDirect,,$0.00,$39.99,1,American Express - 1004,,,Redacto@gmail.com,04/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA060517179004),$39.99,$2.40,$42.39,FALSE,,FALSE,Redact,USD,
04/17/22,112-5922946-9331450,"Nespresso Capsules VertuoLine, Hazelino Muffin, Mild Roast Coffee, 30 Count Coffee Pods, Brews 7.77 Ounce",COFFEE,B0851ZVCGL,50201706,Amazon.com,,new,Amazon.com,,$37.50,$35.99,1,American Express - 1004,,,Redacto@gmail.com,04/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,USPS(9361289735010747793492),$35.99,$0.00,$35.99,,,,Redact,USD,
04/17/22,112-5922946-9331450,GRAVE BEFORE SHAVE™ Beard Wash & Beard Conditioner Pack,CONDITIONER,B07M6MQNNQ,53131602,Amazon.com,,new,MSDesigns,,$30.00,$23.99,1,American Express - 1004,,,Redacto@gmail.com,04/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,USPS(9361289735010747793492),$23.99,$2.19,$26.18,FALSE,,FALSE,Redact,USD,
04/17/22,112-5922946-9331450,Gentle Facial Cleanser with Pump Dispenser - 8-fl oz (237 ml),SKIN_CLEANING_AGENT,B00QY1XZ4W,53131613,Amazon.com,,new,Amazon.com,,$10.00,$8.86,1,American Express - 1004,,,Redacto@gmail.com,04/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,USPS(9361289735010747793492),$8.86,$0.81,$9.67,FALSE,,FALSE,Redact,USD,
04/17/22,112-2306983-1313863,"Home Expressions Premium Velvet Hangers (Pack of 20) - Ultra Thin No Slip Velvet Suit Swivel Hangers - 21"" Extra Wide Strong Velvet Hangers - Space Saving Clothes Hangers - 21x0.2x10 inches (Grey)",CLOTHES_HANGER,B07YSV1YM2,53102507,Amazon.com,,new,Home Expressions Inc,,$0.00,$39.99,2,American Express - 1004,,,Redacto@gmail.com,04/18/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA065660692604),$79.98,$7.30,$87.28,FALSE,,FALSE,Redact,USD,
04/17/22,112-7847330-0112247,"LEVOIT Air Purifier Replacement Filter, Core 400S-RF, H13 True HEPA, White",HVAC_AIR_FILTER,B08SQQK6K7,40161505,Amazon.com,,new,Amazon.com,,$49.99,$49.99,1,American Express - 1004,,,Redacto@gmail.com,04/18/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA065707121804),$49.99,$4.56,$54.55,FALSE,,FALSE,Redact,USD,
04/18/22,112-8589569-3620208,"2-Pack, XL-Large Insulated Grocery shopping bags, Black, reusable bag,thermal zipper,Collapsible,tote,cooler,food transport hot and cold,for instacart,camping,Recycled Material delivery groceries",KITCHEN,B08JCTCLJF,53121608,Amazon.com,,new,xnxy,,$30.00,$15.99,1,American Express - 1004,,,Redacto@gmail.com,04/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA066716820404),$15.99,$1.46,$17.45,FALSE,,FALSE,Redact,USD,
04/18/22,112-8589569-3620208,"Reusable Grocery Shopping Box Bags (3 Pack - Gray). Large, Premium Quality Heavy Duty Tote Bag Set with Extra Long Handles & Reinforced Bottom. Foldable, Collapsible, Durable and Eco Friendly",TOTE_BAG,B06XNLWB3B,53121608,Amazon.com,,new,CGL-Products,,$34.99,$27.99,1,American Express - 1004,,,Redacto@gmail.com,04/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA066716820404),$27.99,$2.55,$30.54,FALSE,,FALSE,Redact,USD,
04/19/22,111-5809292-3865024,Rocco & Roxie Stain & Odor Eliminator for Strong Odor - Enzyme-Powered Pet Odor Eliminator for Home - Carpet Stain Remover for Cats and Dog Pee - Enzymatic Cat Urine Destroyer - Carpet Cleaner Spray,PET_SUPPLIES,B00CKFL93K,10111303,Amazon.com,,new,Rocco & Roxie Supply Co.,,$29.99,$19.97,1,American Express - 1004,,,Redacto@gmail.com,04/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA067248456704),$19.97,$1.20,$21.17,FALSE,,FALSE,Redact,USD,
04/19/22,112-4001730-4785046,"Amber Glass Spray Bottles for Essential Oils, 4oz Empty Small Fine Mist Spray Bottle 2 Pack",SPRAY_BOTTLE,B07QX962YZ,53131600,Amazon.com,,new,Hydior,,$0.00,$6.99,1,American Express - 1004,,,Redacto@gmail.com,04/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA067521913304),$6.99,$0.64,$7.63,FALSE,,FALSE,Redact,USD,
04/19/22,112-4001730-4785046,"Cold Face Eye Mask Ice Pack Reduce Face Puff,Dark Circles,Gel Beads Hot Heat Cold Compress Pack,Face SPA for Woman Sleeping, Pressure, Headaches, Skin Care[Blue]",THERAPEUTIC_THERMAL_COMPRESS,B07HD61PYN,42142100,Amazon.com,,new,YunQiXin,,$18.99,$10.99,1,American Express - 1004,,,Redacto@gmail.com,04/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA067521913304),$10.99,$1.00,$11.99,FALSE,,FALSE,Redact,USD,
04/19/22,112-1779638-7585814,"BlenderBottle pro stak ProStak Twist n' Lock Storage Jars Expansion 3-Pak with Pill Tray, All Black",BOTTLE,B01LZEGPZP,24122000,Amazon.com,,new,Amazon.com,,$7.99,$7.99,3,American Express - 1004,,,Redacto@gmail.com,04/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA067528910104),$23.97,$2.19,$26.16,FALSE,,FALSE,Redact,USD,
04/19/22,112-3403823-7225813,"Crystal Light Sugar-Free Zero Calorie Liquid Water Enhancer - Strawberry Lemonade (1.62 fl oz Bottle, Pack of 12)",FLAVORED_DRINK_CONCENTRATE,B00FY42TTM,50000000,Amazon.com,,new,Amazon.com,,$47.88,$39.36,1,American Express - 1004,,,Redacto@gmail.com,04/20/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SUNNYVALE,CA,94086-6356,Shipped,AMZN_US(TBA067916607504),$39.36,$0.00,$39.36,,,,Redact,USD,
04/22/22,114-8467719-5653841,Motherlove Nipple Cream (1 oz) Organic Lanolin-Free Nipple Balm for Breastfeeding—Benefits Nursing & Pumping Moms,SKIN_CARE_AGENT,B0007CQ726,53131600,Amazon.com,,new,Motherlove Co.,,$11.99,$11.99,1,American Express - 1004,,,Redacto@gmail.com,04/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA070682244304),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
04/22/22,114-8467719-5653841,Simple Wishes X-Small/Large | Hands-Free Breast Pump Bra | Adjustable and Customizable Pumping Bra Fitting for Breastfeeding Pumps | Black,BRA,B004S8MGGQ,53100000,Amazon.com,,new,"Simple Wishes, LLC",Women-Owned Business Enterprise,$39.99,$29.99,1,American Express - 1004,,,Redacto@gmail.com,04/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA070682244304),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
04/22/22,114-8467719-5653841,"Munchkin Portable Diaper Changing Kit with Changing Pad and Wipes Case, Grey",BABY_PRODUCT,B0035ER592,56101800,Amazon.com,,new,Amazon.com,,$14.99,$14.75,1,American Express - 1004,,,Redacto@gmail.com,04/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA070682244304),$14.75,$0.89,$15.64,FALSE,,FALSE,Redact,USD,
04/23/22,111-1233369-2603431,Motherhood Maternity Women's Maternity French Terry Underbelly Short,SHORTS,B07G794TL5,53100000,Amazon.com,,new,Amazon.com,,$24.98,$24.73,1,Visa - 5239,,,Redacto@gmail.com,05/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA072657119104),$24.73,$0.00,$24.73,,,,Redact,USD,
04/23/22,111-1233369-2603431,Kindred Bravely Everyday Maternity Joggers/Lounge Pants for Women,PANTS,B08WX25QBF,53100000,Amazon.com,,new,Kindred Bravely,,$34.99,$34.99,1,Visa - 5239,,,Redacto@gmail.com,05/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA072657119104),$34.99,$0.00,$34.99,,,,Redact,USD,
04/23/22,111-1233369-2603431,Motherhood Maternity Women's Maternity French Terry Underbelly Short,SHORTS,B07GNKFYXS,53100000,Amazon.com,,new,Amazon.com,,$24.98,$24.98,1,Visa - 5239,,,Redacto@gmail.com,05/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA072657119104),$24.98,$0.00,$24.98,,,,Redact,USD,
04/23/22,112-0708458-5233835,Tourna Mesh Carry Bag of 18 Tennis Balls,RECREATION_BALL,B00196P2O8,53120000,Amazon.com,,new,Amazon.com,,$19.99,$19.99,1,American Express - 1004,,,Redacto@gmail.com,04/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA072848584604),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
04/28/22,111-5438572-1461053,"FridaBaby Natural Sleep Vapor Bath Drops for Bedtime Wind Down by Frida Baby, White",BATHWATER_ADDITIVE,B08BSMWMH1,53131600,Amazon.com,,new,Amazon.com,,$9.99,$9.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,04/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA078313223904),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
04/28/22,111-5438572-1461053,"DermaFrida The SkinSoother Baby Bath Silicone Brush by Fridababy | Baby Essential for Dry Skin, Cradle Cap and Eczema (2 Pack)",BABY_PRODUCT,B074ZJSSNB,53131600,Amazon.com,,new,Amazon.com,,$12.99,$8.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,04/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA078313223904),$8.99,$0.54,$9.53,FALSE,,FALSE,Redact,USD,
04/28/22,111-5438572-1461053,"Bumco Baby Essentials for Newborn - Baby Bum Brush Original Diaper Rash Cream Applicator, Soft Flexible Silicone Brush, Unique Gift + Mini Diaper Rash Cream Applicator with Travel Case, Gray & Gray",HEADPHONES,B079K7LBW2,53131600,Amazon.com,,new,Bumco,,$0.00,$14.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,04/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA078313223904),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
05/02/22,111-2668785-2245819,Fruit of the Loom Women's Seamless Underwear (Regular & Plus Size),UNDERPANTS,B01CI4CC2Y,53102303,Amazon.com,,new,Amazon.com,,$16.99,$11.95,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,05/02/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA082458384104),$11.95,$0.72,$12.67,FALSE,,FALSE,Redact,USD,
05/02/22,111-7440924-9703424,"FridaBaby 3-in-1 Nose, Nail + Ear Picker by Frida Baby the Makers of NoseFrida the SnotSucker, Safely Clean Baby's Boogers, Ear Wax & More",BABY_PRODUCT,B087XRDRF6,56101800,Amazon.com,,new,Amazon.com,,$9.99,$9.48,1,Visa - 5239 and Gift Certificate/Card,,,Redacto@gmail.com,05/02/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA082137532704),$9.48,$0.57,$10.05,FALSE,,FALSE,Redact,USD,
05/04/22,111-6380116-5814634,"Lansinoh LatchAssist Inverted Nipple Corrector Nipple Everter, 2 Sizes",BOTTLE_NIPPLE,B00112EIWW,42231808,Amazon.com,,new,Amazon.com,,$8.79,$9.89,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,05/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA084650105604),$9.89,$0.59,$10.48,FALSE,,FALSE,Redact,USD,
05/04/22,111-6380116-5814634,"Medela SoftShells Breast Shells for Flat or Inverted Nipples, Discreet Breast Shells for Your Unique Body, Flexible and Easy to Wear, Made Without BPA",BOTTLE_NIPPLE,B000058DPM,42231807,Amazon.com,,new,Amazon.com,,$20.79,$15.55,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,05/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA084650105604),$15.55,$0.93,$16.48,FALSE,,FALSE,Redact,USD,
05/04/22,112-1360595-7725037,"Nespresso Capsules VertuoLine, Hazelino Muffin, Mild Roast Coffee, 30 Count Coffee Pods, Brews 7.77 Ounce",COFFEE,B0851ZVCGL,50201706,Amazon.com,,new,Amazon.com,,$37.50,$37.50,1,American Express - 1004,,,Redacto@gmail.com,05/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA180545050901),$37.50,$0.00,$37.50,,,,Redact,USD,
05/04/22,112-1360595-7725037,"Storage Drawer Holder Compatible with Nespresso Vertuo Capsules, 48 Big or 90 Small Capacity with 3 Drawer",ITEM_CONTAINER,B09BZHWP4R,56101700,Amazon.com,,new,New England Stories,,$29.99,$24.99,1,American Express - 1004,,,Redacto@gmail.com,05/04/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,SANTA CLARA,CA,95054-1156,Shipped,AMZN_US(TBA180545050901),$24.99,$2.28,$27.27,FALSE,,FALSE,Redact,USD,
05/06/22,112-2771062-2228229,"The Organized Teacher's Guide to Substitute Teaching, Grades K-8, Second Edition",ABIS_BOOK,1260453537,55101500,Amazon.com,2019-06-24 0:00:01,new,Amazon.com,,$25.00,$20.00,1,American Express - 1004,,,Redacto@gmail.com,05/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,LODA,IL,60948-9634,Shipped,AMZN_US(TBA087634066304),$20.00,$1.30,$21.30,FALSE,,FALSE,Redact,USD,
05/11/22,111-4009026-3577811,"CRZ YOGA Womens Lightweight Gym Athletic Workout Shorts Liner 4"" - Quick Dry Running Sport Spandex Shorts Mesh Zipper Pockets",SHORTS,B07DLQJDLG,53100000,Amazon.com,,new,CRZ YOGA,,$28.00,$28.00,1,Visa - 5239,,,Redacto@gmail.com,05/12/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093457111404),$28.00,$1.68,$29.68,FALSE,,FALSE,Redact,USD,
05/11/22,111-2606496-9505035,Motherhood Maternity Women's Maternity French Terry Underbelly Short,SHORTS,B07G7B5LD6,53100000,Amazon.com,,new,Amazon.com,,$24.98,$27.68,1,Visa - 5239,,,Redacto@gmail.com,05/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093655343404),$27.68,$0.00,$27.68,,,,Redact,USD,
05/11/22,111-2606496-9505035,Motherhood Maternity Women's Maternity French Terry Underbelly Short,SHORTS,B07G794TL5,53100000,Amazon.com,,new,Amazon.com,,$24.98,$28.02,1,Visa - 5239,,,Redacto@gmail.com,05/23/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA093655343404),$28.02,$1.68,$29.70,FALSE,,FALSE,Redact,USD,
05/13/22,111-6462478-0634663,Long iPhone Charger Cable 10 Ft Lightning Apple Charging Cord 10ft for iPhone 13/12/11 Pro/X/Xs Max/XR/8 Plus/7/6/5/SE/IPad USB Charge Wire 10 Foot 2Pack,ELECTRONIC_CABLE,B07RB54XJ2,43202222,Amazon.com,,new,BSTOEM,,$15.99,$11.99,1,Visa - 5239,,,Redacto@gmail.com,05/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA095909749104),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
05/14/22,111-8334182-2712233,"BENTON Aloe Soothing Mask Pack 23g 10 Pack - Aloe & Green Tea Leaf Water Contained Hydrating and Cooling Facial Mask Sheet, Fresh Moisturizing and Cooling Effect",SKIN_TREATMENT_MASK,B07GRC7Y8K,53131613,Amazon.com,,new,I’M SHOPAHOLIC,,$0.00,$18.00,1,Visa - 5239,,,Redacto@gmail.com,05/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA096035147504),$18.00,$1.08,$19.08,FALSE,,FALSE,Redact,USD,
05/14/22,111-8334182-2712233,"BENTON Aloe BHA Skin Toner 200ml (6.76 fl. oz.) - Facial Toner for Sensitive Skin with Aloe and BHA, Hydrating Toner for Dry & Rough Skin, Soothing Toner to Maintain Oil-Water Balance, Tighten Pores & Control Sebum",ASTRINGENT_SUBSTANCE,B00GAOBG3A,53131600,Amazon.com,,new,theAlist,,$19.00,$15.00,1,Visa - 5239,,,Redacto@gmail.com,05/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA096035147504),$15.00,$0.90,$15.90,FALSE,,FALSE,Redact,USD,
05/14/22,111-3460523-6985041,"Gold Under Eye Patches | WANDER BEAUTY BAGGAGE CLAIM | Under Eye Mask, Brightens Dark Circles, Hyaluronic Acid Eye Mask - Puffy Under Eye Bags, Fine Lines, Wrinkles, Dullness, Hydrates, Moisturize (1 Pack Contains 6 Pairs of Gold)",SKIN_TREATMENT_MASK,B079VSLGHY,53131600,Amazon.com,,new,b-glowing Authorized Retailer,,$0.00,$26.00,1,Visa - 5239,,,Redacto@gmail.com,05/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA565878397000),$26.00,$1.56,$27.56,FALSE,,FALSE,Redact,USD,
05/15/22,111-7327069-5133053,Motherhood Maternity Women's Maternity French Terry Underbelly Short,SHORTS,B07GNKFYXS,53100000,Amazon.com,,new,Amazon.com,,$24.98,$24.98,1,Visa - 5239,,,Redacto@gmail.com,05/15/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA097503751904),$24.98,$1.50,$26.48,FALSE,,FALSE,Redact,USD,
05/16/22,111-8089307-4420251,"Pearhead Newborn Baby Handprint or Footprint “Clean-Touch” Ink Pad Set of Two, Black",BABY_PRODUCT,B07RB7PJF2,56101800,Amazon.com,,new,Amazon.com,,$9.99,$9.99,1,American Express - 1004,,,Redacto@gmail.com,05/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA099410895804),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
05/16/22,112-0411093-7603402,"Skydo Compatible 910XL Ink Cartridges Replacement for HP 910XL 910 XL Works with OfficeJet Pro 8025e 8035e 8025 8035 8015 8020 8022 8028 Printer (4 Combo Pack, Black/Cyan/Magenta/Yellow)",INKJET_PRINTER_INK,B09WQZJ5JT,44103105,Amazon.com,,new,XuCai ShangWu-US,,$59.97,$56.97,1,American Express - 1004,,,Redacto@gmail.com,05/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA099616563004),$56.97,$3.42,$60.39,FALSE,,FALSE,Redact,USD,
05/16/22,111-0267171-4529010,UPPAbaby Carry-All Parent Organizer,CADDY,B01M59QPSK,44111500,Amazon.com,,new,Amazon.com,,$39.99,$34.99,1,Visa - 5239,,,Redacto@gmail.com,05/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA099356137004),$34.99,$2.10,$37.09,FALSE,,FALSE,Redact,USD,
05/17/22,111-6112316-1753841,"Traditional Medicinals Organic Raspberry Leaf Herbal Tea Caffeine Free, 16 ct. (Pack Of 3)",TEA,B002LMLBXK,50201713,Amazon.com,,new,CPGIO,,$18.99,$18.61,1,Visa - 5239,,,Redacto@gmail.com,05/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA100204189104),$18.61,$0.00,$18.61,,,,Redact,USD,
05/21/22,111-5570763-8732206,"Living Proof Full Dry Volume & Texture Spray, 7.5 fl oz",HAIR_STYLING_AGENT,B09QQWQ1YP,53131602,Amazon.com,,new,"Living Proof, Inc",,$32.00,$32.00,1,Visa - 5239,,,Redacto@gmail.com,05/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA105497181404),$32.00,$1.92,$33.92,FALSE,,FALSE,Redact,USD,
05/21/22,111-5570763-8732206,"Color Wow Raise the Root Thicken + Lift Spray – All-day root lift + volume on wet or dry hair; never sticky or stiff; non-yellowing; heat protection; for all hair types, especially fine, flat hair",HAIR_STYLING_AGENT,B071936ZBD,53131602,Amazon.com,,new,Amazon.com,,$24.00,$24.00,1,Visa - 5239,,,Redacto@gmail.com,05/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA105497181404),$24.00,$1.44,$25.44,FALSE,,FALSE,Redact,USD,
05/22/22,111-7641174-2790638,"SmartyPants Prenatal Formula Daily Gummy Multivitamin: Vitamin C, D3, & Zinc for Immunity, Gluten Free, Folate, Omega 3 Fish Oil (DHA/EPA), 120 Count (30 Day Supply)",VITAMIN,B00VFYYAC4,50501500,Amazon.com,,new,Amazon.com,,$69.72,$28.14,1,Visa - 5239,,,Redacto@gmail.com,05/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA105787554304),$28.14,$0.00,$28.14,,,,Redact,USD,
05/23/22,111-7146855-1984227,"4 Pack Large Gold Hair Claw Clips for Women Thick Hair, Non-Slip Hair Catch Barrette Jaw Clamp, French Design Metal Cross Banana Hair Clips for Women and Girls",HAIR_CLIP,B091DLKQM6,53131600,Amazon.com,,new,Dialekt Direct,,$13.99,$9.90,1,Visa - 5239,,,Redacto@gmail.com,05/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA107514073304),$9.90,$0.59,$10.49,FALSE,,FALSE,Redact,USD,
05/25/22,112-6016146-3316258,Dog Fountain Water Fountain Dog Sprinkler Dog Toys for Large Or Small Dog Bowl Alternative,ANIMAL_WATER_DISPENSER,B07FN5X9MG,10111300,Amazon.com,,new,ABSR INDUSTRIES,,$49.99,$47.77,1,American Express - 1004,,,Redacto@gmail.com,05/25/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA109124508204),$47.77,$2.87,$50.64,FALSE,,FALSE,Redact,USD,
05/26/22,111-4589761-5067410,Buffalo Games - Paris Afternoon - 750 Piece Jigsaw Puzzle,PUZZLES,B08LMBLP44,60141105,Amazon.com,,new,Amazon.com,,$14.99,$14.99,1,Visa - 5239,,,Redacto@gmail.com,05/26/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA110702729404),$14.99,$0.90,$15.89,FALSE,,FALSE,Redact,USD,
05/26/22,111-4589761-5067410,PopSockets: PopGrip with Swappable Top for Phones and Tablets - Black,CELL_PHONE_GRIP,B07P29XQR4,43191610,Amazon.com,,new,Pattern Products,,$9.99,$9.97,1,Visa - 5239,,,Redacto@gmail.com,05/26/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA110702729404),$9.97,$0.60,$10.57,FALSE,,FALSE,Redact,USD,
05/26/22,111-4589761-5067410,"SURPHY Square Silicone Case Compatible with iPhone 11 Pro Case 5.8 inches, Square Edges Liquid Silicone Phone Case (Individual Protection for Each Lens) for iPhone 11 Pro (Light Pink)",CELLULAR_PHONE_CASE,B08YYMQZPV,43191601,Amazon.com,,new,SURPHY®,,$19.99,$13.99,1,Visa - 5239,,,Redacto@gmail.com,05/26/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA110702729404),$13.99,$0.84,$14.83,FALSE,,FALSE,Redact,USD,
06/07/22,111-2519171-0185842,MasterPieces Travel Diary 550 Puzzles Collection - London 550 Piece Jigsaw Puzzle,PUZZLES,B07Q8878RX,60141105,Amazon.com,,new,"MasterPieces, Inc.",,$12.99,$12.99,1,Visa - 5239,,,Redacto@gmail.com,06/07/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA124414416204),$12.99,$0.78,$13.77,FALSE,,FALSE,Redact,USD,
06/11/22,112-7127798-0964249,"BEAR MOUNTAIN Premium BBQ WOODS 100% All-Natural Hardwood Pellets - Gourmet Blend (20 lb. Bag) Perfect for Pellet Smokers, or Any Outdoor Grill-Rich, Smoky Wood-Fired Flavor",SOLID_FIRE_FUEL,B07K8VPT95,53000000,Amazon.com,,new,Spreetail,,$18.99,$21.49,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/11/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,FedEx(274219156463),$21.49,$1.29,$22.78,FALSE,,FALSE,Redact,USD,
06/13/22,111-2987857-3193822,"Butter Dish With Lid, WERTIOO Porcelain Butter Keeper With Handle Cover French Butter Dish Ceramic Butter Holder Container for Countertop, White",BUTTER_DISH,B087JQQ7S7,52150000,Amazon.com,,new,WERTIOO,,$13.90,$12.51,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA597680153000),$12.51,$0.75,$13.26,FALSE,,FALSE,Redact,USD,
06/13/22,111-2987857-3193822,"24K Gold Eye Mask– 20 Pairs - Puffy Eyes and Dark Circles Treatments – Look Less Tired and Reduce Wrinkles and Fine Lines Undereye, Revitalize and Refresh Your Skin",SKIN_TREATMENT_MASK,B07N93XF4Z,53131600,Amazon.com,,new,eCom Heights,,$19.99,$15.99,1,American Express - 1004 and Gift Certificate/Card,,,Redacto@gmail.com,06/13/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA597680153000),$15.99,$0.96,$16.95,FALSE,,FALSE,Redact,USD,
06/13/22,111-8571342-1272229,"Shynerk Baby Car Mirror, Safety Car Seat Mirror for Rear Facing Infant with Wide Crystal Clear View, Shatterproof, Fully Assembled, Crash Tested and Certified",VEHICLE_MIRROR,B07F8HTSKD,52161500,Amazon.com,,new,Shynerk Center,,$50.00,$20.59,1,American Express - 1004,,,Redacto@gmail.com,06/14/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA132803914304),$20.59,$1.24,$21.83,FALSE,,FALSE,Redact,USD,
06/15/22,111-9898999-4089860,"Munchkin Arm & Hammer Pacifier Wipes, 2 Pack, 72 Wipes",SKIN_CLEANING_WIPE,B08NL4M76D,53131600,Amazon.com,,new,Amazon.com,,$7.99,$7.99,1,American Express - 1004,,,Redacto@gmail.com,06/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA135033887204),$7.99,$0.48,$8.47,FALSE,,FALSE,Redact,USD,
06/16/22,111-0567426-4833014,Liquid I.V. Hydration Multiplier - Strawberry - Hydration Powder Packets | Electrolyte Drink Mix | Easy Open Single-Serving Stick | Non-GMO | 16 Sticks,NUTRITIONAL_SUPPLEMENT,B08MVDTLKC,50500000,Amazon.com,,new,Liquid I.V.,,$24.99,$21.99,1,Visa - 5239,,,Redacto@gmail.com,06/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA135266815404),$21.99,$0.00,$21.99,,,,Redact,USD,
06/16/22,111-3493607-0501801,"Lansinoh Momma Breastmilk Feeding Bottle with NaturalWave Slow Flow Nipple, 5 Ounces",BABY_BOTTLE,B00H0DH2NI,42230000,Amazon.com,,new,Preferred Pharmacy Plus,,$7.99,$12.45,1,American Express - 1004,,,Redacto@gmail.com,06/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA135278100004),$12.45,$0.75,$13.20,FALSE,,FALSE,Redact,USD,
06/16/22,111-3927751-9931414,"Lansinoh Momma Breastmilk Feeding Bottle with NaturalWave Slow Flow Nipple, 5 Ounces",BABY_BOTTLE,B00H0DH2NI,42230000,Amazon.com,,new,Preferred Pharmacy Plus,,$7.99,$12.45,1,American Express - 1004,,,Redacto@gmail.com,06/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA135474003004),$12.45,$0.75,$13.20,FALSE,,FALSE,Redact,USD,
06/16/22,111-7348362-9587402,NailFrida The SnipperClipper by Fridababy The Baby Nail Clipper with Safety spyhole for Newborns and up,BABY_PRODUCT,B07CPZNQ4P,53131600,Amazon.com,,new,Amazon.com,,$9.99,$9.99,1,American Express - 1004,,,Redacto@gmail.com,06/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA135662057904),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
06/16/22,112-4240724-5729862,"Small Dry Erase White Board, 16""X12"" Magnetic Desktop Whiteboard for Wall, Hanging Dry Erase Board with Stand, Portable Double-Sided White Board Easel for Kids Drawing Memo to Do List Wall Desk School",WRITING_BOARD,B096VS1MSN,44111900,Amazon.com,,new,shenzhenxuanyingkejiyouxiangongsi,,$35.99,$23.88,1,American Express - 1004,,,Redacto@gmail.com,06/16/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA135819618404),$23.88,$1.43,$25.31,FALSE,,FALSE,Redact,USD,
06/17/22,111-1539481-6798640,"Drop of DiviniTi Sitz Bath for Hemorrhoids Women - Sitz Bath for Postpartum Care - Yoni Steam Seat - Vaginial Steaming Kit - Collapsible, Drain Holes, Flusher Hose - Use w/Sitz Bath Salt",HEALTH_PERSONAL_CARE,B088P9XTZV,53130000,Amazon.com,,new,Drop of DiviniTi,,$0.00,$24.99,1,Visa - 5239,,,Redacto@gmail.com,06/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA601970140000),$24.99,$1.50,$26.49,FALSE,,FALSE,Redact,USD,
06/17/22,111-1539481-6798640,"Lansinoh Sitz Bath Salts Postpartum Essentials, White, 10 Oz",BATHWATER_ADDITIVE,B09J1MWL7H,53102000,Amazon.com,,new,Amazon.com,,$15.99,$14.61,1,Visa - 5239,,,Redacto@gmail.com,06/17/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA601970140000),$14.61,$0.88,$15.49,FALSE,,FALSE,Redact,USD,
06/18/22,111-4125787-8512202,"Munchkin Waterproof Changing Pad Liners, 3 Count",CHANGING_PAD_COVER,B009UPUJIY,53102300,Amazon.com,,new,Amazon.com,,$11.99,$9.99,1,American Express - 1004,,,Redacto@gmail.com,06/18/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA138323441904),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
06/18/22,111-6840589-5664236,"Simple Joys by Carter's Unisex Babies' Short-Sleeve Side Snap Bodysuit, Pack of 5",SHIRT,B083X7F38P,53100000,Amazon.com,,new,Amazon.com,,$20.99,$22.70,1,American Express - 1004,,,Redacto@gmail.com,06/28/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA139007795004),$22.70,$1.36,$24.06,FALSE,,FALSE,Redact,USD,
06/19/22,111-4018945-1093816,"Lansinoh Baby Bottles for Breastfeeding Babies, 5 Ounces, 3 count",BABY_BOTTLE,B00H0DH2NS,42230000,Amazon.com,,new,Amazon.com,,$17.29,$19.99,1,American Express - 1004,,,Redacto@gmail.com,06/19/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA139651371604),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
06/20/22,111-5973810-8800252,"Solly Baby Wrap | Rose Quartz | Baby Carrier | Luxury Newborn Carrier, Baby Holder, Baby Wraps, Baby Sling",BABY_CARRIER,B09QXTTYS2,53121600,Amazon.com,,new,"Solly Baby, LLC",,$76.00,$76.00,1,American Express - 1004,,,Redacto@gmail.com,06/20/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA140427047204),$76.00,$4.56,$80.56,FALSE,,FALSE,Redact,USD,
06/21/22,111-2371704-7457829,It Ends with Us: A Novel (1),ABIS_BOOK,1501110365,55101500,Amazon.com,2016-08-02 0:00:01,new,Amazon.com,,$16.99,$10.26,1,Visa - 5239,,,Redacto@gmail.com,06/21/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA141559032304),$10.26,$0.62,$10.88,FALSE,,FALSE,Redact,USD,
06/21/22,111-9071470-4717048,"Simple Joys by Carter's Baby Girls' Side Snap Bodysuit, Pack of 4",SHIRT,B083XFH8SX,53100000,Amazon.com,,new,Amazon.com,,$20.99,$22.00,1,American Express - 1004,,,Redacto@gmail.com,06/21/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142009737904),$22.00,$1.32,$23.32,FALSE,,FALSE,Redact,USD,
06/21/22,111-9071470-4717048,"Stain Remover for Baby Clothes by Dreft, 3oz Pack of 2 Laundry Stain Remover Spray, Hypoallergenic, Great for Cloth Diapers",LAUNDRY_DETERGENT,B00N6B34K8,47131811,Amazon.com,,new,Nehemiah Brands,,$0.00,$9.99,1,American Express - 1004,,,Redacto@gmail.com,06/21/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142009737904),$9.99,$0.60,$10.59,FALSE,,FALSE,Redact,USD,
06/21/22,111-1014152-0108225,"SwaddleMe Original Swaddle – Preemie Size, Up to 7 Pounds, 1-Pack (Ivory)",BLANKET,B0160R77WA,52120000,Amazon.com,,new,Amazon.com,,$14.99,$12.95,1,Visa - 5239,,,Redacto@gmail.com,06/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA142566852504),$12.95,$0.78,$13.73,FALSE,,FALSE,Redact,USD,
06/21/22,112-5530972-9545060,"Chef'sChoice Trizor XV EdgeSelect Professional Electric Knife Sharpener with 100-Percent Diamond Abrasives and Precision Angle Guides for Straight Edge and Serrated Knives, 3-stage, Gray",KNIFE_SHARPENER,B0018RSEMU,52151624,Amazon.com,,new,Amazon.com,,$150.00,$178.57,1,American Express - 1004,,,Redacto@gmail.com,06/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA142509272404),$178.57,$10.71,$189.28,FALSE,,FALSE,Redact,USD,
06/22/22,111-0848372-1729825,"Frida Baby Fridababy 3-in-1 Humidifier with Diffuser and Nightlight, White",HUMIDIFIER,B07L51PWD2,40101903,Amazon.com,,new,Amazon.com,,$49.99,$46.97,1,Visa - 5239,,,Redacto@gmail.com,06/22/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA143095187404),$46.97,$2.82,$49.79,FALSE,,FALSE,Redact,USD,
06/23/22,112-3823001-0995410,"Fairlife Core Power Elite 42g High Protein Milk Shakes, Ready to Drink for Workout Recovery, Chocolate, 14 Fl Oz (Pack of 12)",PROTEIN_DRINK,B01DDIRDZA,50500000,Amazon.com,,new,Amazon.com,,$35.88,$43.08,1,American Express - 1004,,,Redacto@gmail.com,06/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA145218334204),$43.08,$0.00,$43.08,,,,Redact,USD,
06/24/22,112-6173907-5289060,"Lime Regular Conventional, 1 Each",FRUIT,B07811WM8Z,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$0.79,2,American Express - 1004,,,Redacto@gmail.com,06/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC3006VMHS9300B),RABBIT(SpOSIOC3006UYXR5860O)",$1.58,$0.00,$1.58,,,,Redact,USD,
06/24/22,112-6173907-5289060,"Avocado Hass Bag Organic, 4 Count",FRUIT,B07FYCB4WX,50000000,Amazon.com,,new,Whole Foods Market,,$0.00,$6.99,1,American Express - 1004,,,Redacto@gmail.com,06/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC3006VMHS9300B),RABBIT(SpOSIOC3006UYXR5860O)",$6.99,$0.00,$6.99,,,,Redact,USD,
06/24/22,112-6173907-5289060,Serrano Pepper,HERB,B0787W921K,53100000,Amazon.com,,new,Whole Foods Market,,$0.00,$0.16,1,American Express - 1004,,,Redacto@gmail.com,06/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC3006VMHS9300B),RABBIT(SpOSIOC3006UYXR5860O)",$0.16,$0.00,$0.16,,,,Redact,USD,
06/24/22,112-6173907-5289060,Organic Red Onion,FRUIT,B0787Y45SB,53100000,Amazon.com,,new,Whole Foods Market,,$0.00,$2.29,1,American Express - 1004,,,Redacto@gmail.com,06/24/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,"RABBIT(SpOSIOC3006VMHS9300B),RABBIT(SpOSIOC3006UYXR5860O)",$2.29,$0.00,$2.29,,,,Redact,USD,
06/25/22,111-5287386-5285050,"Proactiv+ Benzoyl Peroxide Wash - Exfoliating Face Wash for Face, Back and Body - Benzoyl Peroxide 2.5% Solution - Creamy and Gentle Moisturizing 90 Day Acne Treatment, 6 Oz",SKIN_EXFOLIANT,B00SUWEZCO,53131613,Amazon.com,,new,Amazon.com,,$48.00,$40.00,1,Visa - 5239,,,Redacto@gmail.com,06/25/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA147059267604),$40.00,$2.40,$42.40,FALSE,,FALSE,Redact,USD,
06/26/22,112-3295209-2419413,"COSORI Air Fryer Oven Combo 5.8QT Max Xl Large Cooker (Cookbook with 100 Recipes), One-Touch Screen with 11 Precise Presets and Shake Reminder, Nonstick and Dishwasher-Safe Square Design Basket, Black",AIR_FRYER,B07GJBBGHG,52140000,Amazon.com,,new,Amazon.com,,$119.99,$119.99,1,American Express - 1004,,,Redacto@gmail.com,06/27/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA149143814204),$119.99,$7.20,$127.19,FALSE,,FALSE,Redact,USD,
06/26/22,112-8721422-6937068,"The Book of Five Rings (Arcturus Ornate Classics, 7)",ABIS_BOOK,1398808741,55101500,Amazon.com,2021-11-15 0:00:01,new,Amazon.com,,$12.99,$11.99,1,Visa - 5239,,,Redacto@gmail.com,06/27/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA148793612304),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
06/28/22,114-6468435-0849839,CRZ YOGA Women's Strappy Longline Sports Bras - Wirefree Padded Medium Impact Workout Crop Tank Top,BRA,B07SPBZQZX,53100000,Amazon.com,,new,CRZ YOGA,,$28.00,$28.00,1,Visa - 5239,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Pending payment,UPS(1Z8F0F430236710711),$28.00,$0.00,$28.00,,,,Redact,USD,
06/28/22,114-9889580-2051425,CRZ YOGA Y Back Racerback Sports Bras for Women - Spaghetti Straps Padded Yoga Sports Bras for Women,BRA,B07S5PVK71,53100000,Amazon.com,,new,CRZ YOGA,,$24.00,$24.00,1,Visa - 5239,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA151304394104),$24.00,$1.44,$25.44,FALSE,,FALSE,Redact,USD,
06/28/22,114-0844722-7143467,CRZ YOGA Women's Strappy Sports Bras Fitness Workout Padded Yoga Bra Criss Cross Back,BRA,B079PZH7KS,53100000,Amazon.com,,new,CRZ YOGA,,$24.00,$24.00,1,Visa - 5239,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA151404382904),$24.00,$1.44,$25.44,FALSE,,FALSE,Redact,USD,
06/28/22,114-0844722-7143467,CRZ YOGA Strappy V Neck Sports Bra for Women - Padded Criss Cross Back Workout Yoga Bra,BRA,B09LQY7CDP,53100000,Amazon.com,,new,CRZ YOGA,,$22.00,$22.00,1,Visa - 5239,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA151404382904),$22.00,$1.32,$23.32,FALSE,,FALSE,Redact,USD,
06/29/22,111-2426034-6269032,"Lansinoh Baby Bottles for Breastfeeding Babies, 5 Ounces, 3 count",BABY_BOTTLE,B00H0DH2NS,42230000,Amazon.com,,new,Amazon.com,,$17.29,$19.99,1,American Express - 1004,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA151781655804),$19.99,$1.20,$21.19,FALSE,,FALSE,Redact,USD,
06/29/22,112-6220347-8517043,"ProsourceFit Exercise Balance Pad, Non-Slip Cushioned Foam Mat & Knee Pad for Fitness and Stability Training, Yoga, Physical Therapy",EXERCISE_BLOCK,B07NXY4VZS,49000000,Amazon.com,,new,Amazon.com,,$29.99,$29.99,1,American Express - 1004,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,USPS(9361289687012404098265),$29.99,$1.80,$31.79,FALSE,,FALSE,Redact,USD,
06/29/22,112-6220347-8517043,ATG For Life,ABIS_BOOK,B09MYSNRZL,55101500,Amazon.com,2021-12-01 0:00:01,new,Amazon.com,,$24.75,$21.90,1,American Express - 1004,,,Redacto@gmail.com,06/30/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,UPS(1ZW4615X1347700987),$21.90,$1.31,$23.21,FALSE,,FALSE,Redact,USD,
06/29/22,112-6220347-8517043,"A2ZCARE LAT Pull Down Cable Machine Attachment - Tricep Press Down Accessories for Home Gym Fitness with Multi Option: V-Handle, Tricep Rope, V-Shaped Bar and Rotating Bar",JUMP_ROPE,B075F51GHD,49000000,Amazon.com,,new,A2ZCare,,$0.00,$20.34,1,American Express - 1004,,,Redacto@gmail.com,06/29/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,Dexter,Mi,48130,Shipped,AMZN_US(TBA152153048004),$20.34,$1.22,$21.56,FALSE,,FALSE,Redact,USD,
06/29/22,112-3406473-7529868,OXO Good Grips Silicone Shower & Tub Drain Protector,HOME,B00OCEBMFM,47131815,Amazon.com,,new,Amazon.com,,$10.95,$11.99,1,American Express - 1004,,,Redacto@gmail.com,06/30/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,USPS(9374889687012825120855),$11.99,$0.72,$12.71,FALSE,,FALSE,Redact,USD,
07/01/22,111-2374747-2554645,"Icona Bay 4x6 Black Picture Frame, Modern Style Wood Composite Frame, Table Top or Wall Mount, Bliss Collection",PICTURE_FRAME,B08HQ7XQ2W,60121400,Amazon.com,,new,Mendota Merchants,889 certification,$12.99,$6.49,1,Visa - 5239,,,Redacto@gmail.com,07/01/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA154768651204),$6.49,$0.39,$6.88,FALSE,,FALSE,Redact,USD,
07/01/22,111-2374747-2554645,"ETUDE SoonJung 2x Barrier Intensive Cream 60ml (21AD) | Hypoallergenic Shea Butter Hydrating Facial Cream for Sensitive Skin, Water-oil Balance Panthenol Heals Damaged Skin | K-beauty",SKIN_MOISTURIZER,B091PN6NPT,53131600,Amazon.com,,new,ETUDE HOUSE Official,,$0.00,$13.60,1,Visa - 5239,,,Redacto@gmail.com,07/01/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA154768651204),$13.60,$0.82,$14.42,FALSE,,FALSE,Redact,USD,
07/03/22,111-5310585-7977026,CRZ YOGA Women's Strappy Longline Sports Bras - Wirefree Padded Medium Impact Workout Crop Tank Top,BRA,B07LBSJR4S,53100000,Amazon.com,,new,CRZ YOGA,,$28.00,$28.00,1,Visa - 5239,,,Redacto@gmail.com,07/06/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Pending payment,AMZN_US(TBA159329805004),$28.00,$1.68,$29.68,FALSE,,FALSE,Redact,USD,
07/05/22,111-7934191-6237811,Nalakai Ring Sling Baby Carrier - Luxury Bamboo and Linen Baby Sling - Baby Wrap,BABY_CARRIER,B071FDM61H,56101803,Amazon.com,,new,Pure Life Company,,$0.00,$64.95,1,Visa - 5239,,,Redacto@gmail.com,07/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA158220750304),$64.95,$3.90,$68.85,FALSE,,FALSE,Redact,USD,
07/05/22,111-0746271-6628228,"BIBS Colour Baby Pacifier 2-Pack | Made in Denmark | BPA Free Dummy Soother, Round Nipple. Natural Rubber Latex, Size 1 (0-6 Months), Blush / Blush",PACIFIER,B08YJ6WYZG,53131500,Amazon.com,,new,BIBS America,,$0.00,$15.99,1,American Express - 1004,,,Redacto@gmail.com,07/05/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA158326279804),$15.99,$0.96,$16.95,FALSE,,FALSE,Redact,USD,
07/06/22,111-9731676-0667408,"Happiest Baby SNOO Sleep Sack - 100% Organic Cotton Baby Swaddle Blanket - Doctor Designed Promotes Healthy Hip Development (White, Small)",BLANKET,B08Y2NLF8W,52121508,Amazon.com,,new,"Happiest Baby, Inc.",,$0.00,$34.95,1,American Express - 1004,,,Redacto@gmail.com,07/06/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA159970472804),$34.95,$2.10,$37.05,FALSE,,FALSE,Redact,USD,
07/09/22,111-3637986-6433034,Gentle Facial Cleanser with Pump Dispenser - 8-fl oz (237 ml),SKIN_CLEANING_AGENT,B00QY1XZ4W,53131613,Amazon.com,,new,Amazon.com,,$10.00,$8.86,1,American Express - 1004,,,Redacto@gmail.com,07/09/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA163978629604),$8.86,$0.53,$9.39,FALSE,,FALSE,Redact,USD,
07/09/22,111-3637986-6433034,"Sky Organics Organic Cotton Swabs for Sensitive Skin, 100% Pure GOTS Certified Organic for Beauty & Personal Care, 500 ct.",BEAUTY,B07CWK4BKV,53131600,Amazon.com,,new,Sky Organics,,$0.00,$8.99,1,American Express - 1004,,,Redacto@gmail.com,07/09/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA163978629604),$8.99,$0.54,$9.53,FALSE,,FALSE,Redact,USD,
07/09/22,111-7551168-9912252,"SmartyPants Women's Formula Gummy Vitamins: Gluten Free, Multivitamin, CoQ10, Folate (Methylfolate), Vitamin K2, Vitamin D3, Biotin, B12, Omega 3 DHA/EPA Fish Oil, 180 count (30 Day Supply)",NUTRITIONAL_SUPPLEMENT,B01B1JPXBE,50501801,Amazon.com,,new,Preferred Pharmacy Plus,889 certification,$64.36,$28.82,1,Visa - 5239,,,Redacto@gmail.com,07/09/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA163929436304),$28.82,$0.00,$28.82,,,,Redact,USD,
07/10/22,111-3605211-0941839,"WaterWipes Biodegradable Original Baby Wipes, 99.9% Water Based Wipes, Unscented & Hypoallergenic for Sensitive Skin, 240 Count (4 packs), Packaging May Vary",SKIN_CLEANING_WIPE,B00INOM4X6,53131600,Amazon.com,,new,Amazon.com,,$16.29,$13.30,1,American Express - 1004,,,Redacto@gmail.com,07/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165277640004),$13.30,$0.80,$14.10,FALSE,,FALSE,Redact,USD,
07/10/22,111-8528863-0641027,DYPER Bamboo Baby Diapers Size Newborn | Natural Honest Ingredients | Cloth Alternative | Day & Overnight | Plant-Based + Eco-Friendly | Hypoallergenic for Sensitive Infant Skin | Unscented - 34 Count,INCONTINENCE_PROTECTOR,B09LN7FC4J,53102305,Amazon.com,,new,DYPER,,$17.99,$17.99,1,American Express - 1004,,,Redacto@gmail.com,07/10/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipped,AMZN_US(TBA165125604404),$17.99,$1.08,$19.07,FALSE,,FALSE,Redact,USD,
07/12/22,111-4699796-0795452,DREAM PAIRS Women's Flat Mules Buckle Pointed Toe Backless Slip on Slides Loafer Shoes,SHOES,B08N1895NB,53110000,Amazon.com,,new,topshoesUS,889 certification,$39.99,$35.99,1,Visa - 5239,,,Redacto@gmail.com,07/12/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipping soon,,$35.99,$1.50,$37.49,FALSE,,FALSE,Redact,USD,
07/12/22,111-4699796-0795452,"WaterWipes Biodegradable Original Baby Wipes, 99.9% Water Based Wipes, Unscented & Hypoallergenic for Sensitive Skin, 540 Count (9 packs), Packaging May Vary",SKIN_CLEANING_WIPE,B008KJQMA0,53131624,Amazon.com,,new,Amazon.com,,$35.69,$22.99,1,Visa - 5239,,,Redacto@gmail.com,07/12/22,Redact Redacterson,1234 Redact Way,1234 Redact Way,DEXTER,MI,48130-8444,Shipping soon,,$22.99,$1.38,$24.37,FALSE,,FALSE,Redact,USD,`;
