import LoadingIcon from "components/App/Dashboard/panels/icons/LoadingIcon";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  min-height: 400px;
`;

const LoadingText = styled.div`
  padding-left: 10px;
`;

function LoadingContainer({ children } : {children: JSX.Element}) {
  const [content, setContent] = useState<JSX.Element>();

  const loadContent = useCallback(async () => {
    await new Promise((r) => setTimeout(r, 50));
    setContent(children);
  }, [children]);

  useEffect(() => {
    loadContent();
  }, [loadContent])

  return content ? 
    content
    :
    <LoadingWrapper>
      <>
        <LoadingIcon size={20} thickness={3} />
        <LoadingText>Loading...</LoadingText>
      </>
    </LoadingWrapper>;
}

export default LoadingContainer;