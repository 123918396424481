import * as msg from "./types"
import { MajorVer, MinorVer } from "./queries"
import { UskoCategory } from "../amazon";

const dayBefore = (d:Date) : Date => {
  return new Date(new Date(d.getTime()).setDate(d.getDate() - 1));
};

const today = new Date();
const yesterday = dayBefore(today);

export const MockMessages : msg.Message[] = [{
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "gclife_placemat",
  credits: 100,
  query: {
    "desc": "Seagrass Placemats",
    "brand": "GC Life",
    "msg": {
      "kind": "msg",
      "message": "Do you have kids in your household? Are you trying to keep your lovely home as clean as possible? Our placemats look great and will protect you from messes and spills. Handmade from natural high-quality seagrass, our mats are the perfect size 43cm x 30cm (17\" x 12\") with 4 or 6 sets. They are washable and dry quickly. Designed to be Non-Slip and easily match any decor style.",
      "link": {
        "kind": "msgLink",
        "url": "https://amzn.to/420bOGT",
        "text": "Learn More"
      }
    },
    "cond": {
      "p": true,
      "kind": "prim"
    },
    "decls": {},
    "vMajor": 0,
    "vMinor": 1
  },
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "candokrisp_almondbutterbar",
  credits: 100,
  query: {
    "desc": "Almond Butter Bar",
    "brand": "CanDo Krisp",
    "msg": {
      "kind": "msg",
      "message": "Try CanDo Krisp Almond Butter Bars!__USKOLINEBREAK__CanDo Krisp is a low carb, low sugar protein bar that makes for a perfectly delicious and healthy meal replacement.__USKOLINEBREAK__It's gluten-free and Keto, too.",
      "link": {
        "kind": "msgLink",
        "url": "https://amzn.to/3JL9Bb5",
        "text": "Learn more on Amazon"
      }
    },
    "cond": {
      "p": true,
      "kind": "prim"
    },
    "decls": {},
    "vMajor": 0,
    "vMinor": 1
  },
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "theraice_icecap",
  credits: 100,
  query: {
    "desc": "Head Gel Ice Cap",
    "brand": "TheraICE",
    "msg": {
      "kind": "msg",
      "message": "Do you suffer from headaches or migraines?__USKOLINEBREAK__TheraICE's form fitting head gel ice cap provides 360° natural cooling while resting comfortably. The product's slip-on design means no messy wraps or uncomfortable ice packs, inserts or awkward band straps.",
      "link": {
        "kind": "msgLink",
        "url": "https://amzn.to/3IRu78t",
        "text": "Learn More"
      },
    },
    "cond": {
      "p": true,
      "kind": "prim"
    },
    "decls": {},
    "vMajor": 0,
    "vMinor": 1
  },
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "homely_broom_mount",
  credits: 100,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
  "desc": "Broom wall mount",
  "brand": "Homely",
  "msg": {
    "kind": "msg",
    "message": "Organize your closet or garage!__USKOLINEBREAK__Homely Center’s sturdy and durable mop and broom holder wall mount is built from high-quality stainless steel. Each 17 inch long broom organizer wall mount offers 3 position racks and 4 hooks, which makes it the ultimate solution for a clutter-free home.",
    "link": {
      "kind": "msgLink",
      "url": "https://amzn.to/41WvD1R",
      "text": "Buy on Amazon"
    }
  },
  "cond": {
    "kind": "pred",
    "op": "gt",
    "rhs": {"kind": "prim", "p": 0 },
    "lhs": {
      "kind": "amazonTotalPurchases",
      "filter": {
        "kind": "filterOr",
        "lhs": {
          "kind": "category",
          "category": UskoCategory.Home,
        },
        "rhs": {
          "kind": "filterOr",
          "lhs": {
            "kind": "category",
            "category": UskoCategory.Tools,
          },
          "rhs": {
            "kind": "category",
            "category": UskoCategory.Office,
          }
        }
      }
    }
  },
  "decls": {},
  "vMajor": 0,
  "vMinor": 1
  },
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "victoria_tortilla_press",
  credits: 100,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    "desc": "Tortilla Press",
    "brand": "Victoria",
    "msg": {
      "kind": "msg",
      "message": "Fresh tortillas every time.__USKOLINEBREAK__Make your own tortillas, patacones, rotis, empanadas, quesadillas, and arepas at home fast and easy.__USKOLINEBREAK__Great for non-gluten and Paleo tortillas too!__USKOLINEBREAK__Victoria has been producing in Colombia since 1939.",
      "link": {
        "kind": "msgLink",
        "url": "https://amzn.to/3yb9T4l",
        "text": "Buy on Amazon"
      }
    },
    "cond": {
      "kind": "pred",
      "op": "gt",
      "rhs": {
        "kind": "prim",
        "p": 0
      },
      "lhs": {
        "kind": "amazonTotalPurchases",
        "filter": {
          "kind": "filterOr",
          "lhs": {
            "kind": "category",
            "category": UskoCategory.Groceries,
          },
          "rhs": {
            "kind": "filterOr",
            "lhs": {
              "kind": "category",
              "category": UskoCategory.Home,
            },
            "rhs": {
              "kind": "category",
              "category": UskoCategory.Appliances,
            }
          }
        }
      }
    },
    "decls": {},
    "vMajor": 0,
    "vMinor": 1
  },
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "redracer_f1_book",
  credits: 100,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    "desc": "F1 Children's Book",
    "brand": "Red Racer Books",
    "msg": {
      "kind": "msg",
      "message": "Hey parents of car-loving kiddos! 🏎️ Introduce your little ones to F1 racing with ABCs of Racing: My First Guide to Formula 1 Racing! 📚 It's not your average ABC book - it's an exciting way to learn with killer illustrations inspired by real-life tracks and situations. 😎 Your little racers will have a blast while they learn the alphabet and get ready for Grand Prix weekend! ⚡ Don't miss out on this premium hardcover book for young (and young at heart ❤️) racing fans. Order ABCs of Racing on Amazon today! 📖",
      "link": {
        "kind": "msgLink",
        "url": "https://amzn.to/3J7HZfP",
        "text": "Buy on Amazon"
      }
    },
    "cond": {
      "kind": "pred",
      "op": "gt",
      "rhs": {"kind": "prim", "p": 0 },
      "lhs": {
        "kind": "amazonTotalPurchases",
        "filter": {
          "kind": "filterOr",
          "lhs": {
            "kind": "category",
            "category": UskoCategory.Toys,
          },
          "rhs": {
            "kind": "category",
            "category": UskoCategory.Baby,
          }
        }
      }
    },
    "decls": {},
    "vMajor": 0,
    "vMinor": 1
  }
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: today,
  active: true,
  id: "1",
  credits: 123,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    desc: "total spend of 1k",
    brand: "Usko Privacy",
    decls: {},
    cond:{
      kind: "pred", op: "gt",
      lhs: { kind: "amazonTotalSpend" },
      rhs: { kind: "prim", p: 1000 }
    },
    msg: {
      kind: "msg",
      message: "👋 Thank you for downloading Usko Privacy! We hope you enjoy your stay.",
      link: { kind: "msgLink", url: "https://uskoprivacy.com", text: "Visit Usko Privacy" },
    },
    vMajor: MajorVer,
    vMinor: MinorVer,
  },
},{
  state: msg.MessageStates.Read,
  interactions: {clicks: [], views: []},
  createdAt: yesterday,
  active: true,
  id: "2",
  credits: 456,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    desc: "total spend of 2k  in 2021",
    brand: "👩🏽‍🚀 👩‍💻👨‍👩‍👧‍👦 emoji test",
    decls: {},
    cond: {
      kind: "pred", op: "gt",
      lhs: {
      kind: "amazonTotalSpend",
      filter: {
        kind: "filterAnd",
        lhs: {
          kind: "orderDate",
          date: new Date("01 Jan 2021").getTime(),
          pred: "gte",
        },
        rhs: {
          kind: "orderDate",
          date: new Date("31 Dec 2021").getTime(),
          pred: "lte",
        },
      }},
      rhs: { kind: "prim", p: 2000 }
    },
    msg: {
      kind: "msg",
      message: ["Congrats you are two thousander ", { kind: "msgLink", url: "https://google.com", text: "and please visit" }, " our website"],
      link: { kind: "msgLink", url: "https://uskoprivacy.com", text: "Visit Usko Privacy" },
    },
    vMajor: MajorVer,
    vMinor: MinorVer,
  },
  res: {
    kind: "msg",
    message: ["Congrats you are two thousander 🤑 ", { kind: "msgLink", url: "https://google.com", text: "please visit" }, " our website"],
    link: { kind: "msgLink", url: "https://uskoprivacy.com", text: "Visit Usko Privacy" },
  },
}, {
  state: msg.MessageStates.Opened,
  interactions: {clicks: [], views: []},
  createdAt: dayBefore(dayBefore(yesterday)),
  active: true,
  id: "3",
  credits: 27,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    desc: "total spend over 500 on groceries in 2021",
    brand: "Usko Privacy",
    decls: {},
    cond: {
      kind: "pred", op: "gt",
      lhs: {
      kind: "amazonTotalSpend",
      filter: {
        kind: "filterAnd",
        lhs: {
          kind: "category",
          category: UskoCategory.Groceries,
        },
        rhs: {
            kind: "filterAnd",
            lhs: {
              kind: "orderDate",
              date: new Date("01 Jan 2021").getTime(),
              pred: "gte",
            },
            rhs: {
              kind: "orderDate",
              date: new Date("31 Dec 2021").getTime(),
              pred: "lte",
            },
        }
      }},
      rhs: { kind: "prim", p: 2000 }
    },
    msg: {
      kind: "msg",
      message: "hey because you spent $x heres a coupon",
      link: { kind: "msgLink", url: "https://uskoprivacy.com", text: "Visit Usko Privacy" },
    },
    vMajor: MajorVer,
    vMinor: MinorVer,
  },
  res: {
    kind: "msg",
    message: "Congrats you are two thousander",
    link: { kind: "msgLink", url: "https://uskoprivacy.com", text: "Visit Usko Privacy" },
  },
},{
  state: msg.MessageStates.Rejected,
  interactions: {clicks: [], views: []},
  createdAt: yesterday,
  active: true,
  id: "4",
  credits: -1000,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    desc: "total spend of 1m (no match)",
    brand: "Usko Privacy",
    decls: {},
    cond:{
      kind: "pred", op: "gt",
      lhs: { kind: "amazonTotalSpend" },
      rhs: { kind: "prim", p: 1000000 }
    },
    msg: {
      kind: "msg",
      message: "Congrats you are a millionaire",
      link: { kind: "msgLink", url: "https://uskoprivacy.com", text: "Visit Usko Privacy" },
    },
    vMajor: MajorVer,
    vMinor: MinorVer,
  },
}, {
  state: msg.MessageStates.Errored,
  interactions: {clicks: [], views: []},
  active: false,
  createdAt: dayBefore(yesterday),
  id: "5",
  credits: 2000,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    desc: "message (errored)",
    brand: "Usko Privacy",
    decls: {},
    cond:{
      kind: "prim", p: true,
    },
    msg: {
      kind: "msg",
      message: "some old message",
    },
    vMajor: MajorVer,
    vMinor: MinorVer,
  },
}, {
  state: msg.MessageStates.Unprocessed,
  interactions: {clicks: [], views: []},
  createdAt: yesterday,
  active: true,
  id: "6",
  credits: 321,
  icons: undefined,
  productImg: undefined,
  heroImg: undefined,
  query: {
    desc: "test with expr in message",
    brand: "Usko Privacy",
    decls: {"spend": {
      kind: "amazonTotalSpend",
      filter: {
        kind: "filterAnd",
        lhs: {
          kind: "orderDate",
          date: new Date("01 Jan 2021").getTime(),
          pred: "gte",
        },
        rhs: {
          kind: "orderDate",
          date: new Date("31 Dec 2021").getTime(),
          pred: "lte",
        },
      },
    }},
    cond: { kind: "pred", op: "gt",
      lhs: { kind: "apply", name: "spend"},
      rhs: { kind: "prim", p: 2000 },
    },
    msg: {
      kind: "msg",
      message: [
        "You have spent $",
        { kind: "apply", name: "spend" },
        " in 2021. congrats. heres a ",
        { kind: "msgLink", text: "coupon", url:"gopher://coupon"},
        ". Join our ",
        { kind: "msgLink", text: "community", url:"discord://zog"},
        " on Discord.",
      ],
    },
    vMajor: MajorVer,
    vMinor: MinorVer,
  },
}];
