import styled from "styled-components";

import BackArrowIcon from "icons/back-arrow-icon.svg";
import { useNavigate } from "react-router-dom";
import GearIcon from "icons/dynamic-icons/GearIcon";

const DashboardNavWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  padding-top: 10px;

  height: 50px;
  background: #F6F5FF;
`;

const CenterWrapper = styled.div`
  display: flex;

  color: #4D4C4C;
`;

const TransactionsNavIcon = styled.img`
  position: absolute;

  left: 15px;
`;

const UskoIconImg = styled.div`
  position: absolute;

  right: 5px;
`;

type ThinNavProps = {
  title?: string,
  goBack?: () => void,
  goToMenu?: () => void
}

function ThinNav({title, goBack, goToMenu}: ThinNavProps) {
  const navigate = useNavigate();
  return <DashboardNavWrapper>
    <TransactionsNavIcon
      onClick={() => goBack ? goBack() : navigate(-1)}
      src={BackArrowIcon}
      alt="Back to the Dashboard Icon"
    />
    <CenterWrapper>{title}</CenterWrapper>
    {
      goToMenu ?
      <UskoIconImg onClick={goToMenu}>
        <GearIcon color={'white'}/>
      </UskoIconImg>
      :
      null
    }
  </DashboardNavWrapper>
}

export default ThinNav;
