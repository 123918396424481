import styled from 'styled-components';

export const ChartSelect = styled.select`
  background: #FFFFFF;
  border: 1.55px solid #B8C3E9;
  border-radius: 35px;
  
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  width: 86.25px; 
  height: 35.5px; 
  padding: 6.75px;
`;

export const StaticGraphFrame = styled.div<any>`
  background-color: #E5E4EE;

  padding: 10px;

  border-radius: 10px;

  padding-bottom: 5px;
`;

export const DisableContentsContainer = styled.div`
  pointer-events: none;
`;
export const GraphWindow = styled.div<any>`
  position: relative;
  display: flex;

  align-items:center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  height: ${props => props.height || '225px'};
  
  ${props => props.disabled ? "pointer-events: none;" : ""}

  margin-top: 19px;
`;

export const GraphCenter = styled.div<any>`
  canvas {
    height: ${props => props.height || '330px'} !important;
    width: ${props => props.width || `calc(100vw - 68px)`} !important;
    max-width: 400px;
  }
`;

export const Panel = styled.div`
  position: relative; 

  margin: 16px;
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;

  color: #4D4C4C;
`;

export const StandAlonePanel = styled.div<any>`
  padding-top: ${props => props.paddingTop ? props.paddingTop: '0px'}
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div``;
export const Right = styled.div``;

export const GroceriesTitle = styled.div`
  align-text: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;

  padding-bottom: 7px;
`;

export const SpendingTitle = styled.div`
  align-text: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;

  padding-bottom: 7px;
`;
export const StatsTitle = styled.div`
  align-text: center;
  padding-bottom: 25px;

  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.5px;
`;
