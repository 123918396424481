import { FlexRow, Left } from "components/App/Dashboard/panelsv2/generics";
import { LargeHeading, SubHeading, SmallText } from "../../styles/generics";
import { usd } from "components/App/Dashboard/panelsv2/utils";
import styled from "styled-components";

const ChartBlurbWrapper = styled.div``;

const ViewTitle = styled(SubHeading)`
  line-height: 1.36125rem;

  padding-left: 1rem;
  padding-right: 0.625rem;
`;

const ViewPrice = styled(LargeHeading)`
  line-height: 2.25rem;

  padding-left: 1rem;
  padding-top: 1.25rem;
`;

const ViewText = styled(SmallText)`
  padding-left: 1rem;
`;

const ViewSpan = styled.span`
  color: #E74F52;
`;
const SelectorText = styled(SubHeading)`
  line-height: 1.36125rem;
`;

type ChartBlurbProps = {
  selector: JSX.Element,
  text: string,
  title: string,
  spending: number,
  delta: number
}

function ChartBlurb({ selector, title, text, spending, delta }: ChartBlurbProps) {
  const negative = delta < 0;

  return <ChartBlurbWrapper>
    <FlexRow>
      <Left style={{display: 'flex', alignItems: 'center'}}>
        <ViewTitle>{title}</ViewTitle>
        <SelectorText>{ selector }</SelectorText>
      </Left>
    </FlexRow>
    <ViewPrice>
      {usd.format(spending)}
    </ViewPrice>
    <ViewText>
      <ViewSpan>
        {usd.format(delta)}
        {negative ? '↓' : '↑'}
      </ViewSpan>
      { text }
    </ViewText>
  </ChartBlurbWrapper>
}

export default ChartBlurb;
