import DatePicker from "react-datepicker";
import styled from "styled-components";

const DatePickWrapper = styled.div`
  display: flex;
  .react-datepicker__input-container {
    input{
      all: unset;
      width: 100px;
  
      text-align: center;
    }
  }
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

type FullDatePickerProps = {
  date: Date,
  setDate: (date: Date) => void
}

function FullDatePicker({ date, setDate } : FullDatePickerProps) {
  return <DatePickWrapper>
    <DatePicker selected={date} onChange={(date:Date) => setDate(date)}/>
  </DatePickWrapper>
}

export default FullDatePicker;