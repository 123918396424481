
export const WEBVIEW_CLICK_LINK = 'hyperlink';
export const WEBVIEW_SET_NATIVE_VARIABLE = 'setNativeVariable';
export const WEBVIEW_READY_EVENT = 'ready';
export const WEBVIEW_RECONNECT_AMAZON = 'reconnectamazon';
export const WEBVIEW_REQUEST_EXPORT_DEBUG_LOGS = 'exportDebugLogs';
export const WEBVIEW_REQUEST_DEBUG_HTML = 'shareDebugHtml';
export const WEBVIEW_REQUEST_IMAGE = 'download';
export const WEBVIEW_REQUEST_REFRESH_DATA = 'refreshAmazonData';
export const WEBVIEW_REQUEST_SHARE = 'shareImage';
export const WEBVIEW_REQUEST_SHARE_WITH_TEXT = 'shareImageWithText';
export const WEBVIEW_MARKETPLACE_CREATE_REFERRAL_LINK = 'marketplaceCreateReferralLink';
export const WEBVIEW_MESSAGES_EVALUATED = 'messagesEvaluated';
export const WEBVIEW_MESSAGE_OPENED = 'messageOpened';
export const WEBVIEW_MESSAGE_CLAIMED = 'messageClaimCredit';
export const WEBVIEW_MESSAGE_INTERACTED = 'messageInteraction';
export const WEBVIEW_SHARE_SCREENSHOT = "shareScreenshot";
export const WEBVIEW_SIGNAL_MARKETPLACE_ITEM_PURCHASED = 'marketplaceItemPurchasedSignal'
export const ANALYTICS = "analytics"
