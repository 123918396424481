import {useEffect, useMemo, useState} from "react";
import { useParams } from "react-router-dom";

import { AmazonData, ItemStatus, StatusItemCounterObj } from "amazon";

import Purchases from "./Purchases";
import Spending from "./Spending";
import NotificationCarousel from "../NotificationCarousel/NotificationCarousel";
import UpdateFinishCard from "./panelsv2/UpdateFinishCard";
import UpdateIndicator from "./panelsv2/UpdateIndicator";

import styled from "styled-components";

import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const DashboardWrapper = styled.div`
  overflow: clip;
`;

const SPENDING = 'spending';
const PURCHASES = 'purchases';

interface DashboardProps {
  data: AmazonData|undefined;
  lastUpdate: Date|undefined;
  showNotificationCarousel: boolean;
  showSharePanel: boolean;
  setShowNotificationCarousel: (b: boolean) => void;
  setShowSharePanel: (b: boolean) => void;
  statusItemCounter: StatusItemCounterObj;
  updateStatus: ItemStatus|undefined;
  updating: boolean;
  versions: {[key: string]: any};
  hasDataLoaded: boolean;
}

function Dashboard({
  hasDataLoaded,
  data,
  lastUpdate,
  showNotificationCarousel,
  showSharePanel,
  setShowNotificationCarousel,
  setShowSharePanel,
  statusItemCounter,
  updateStatus,
  updating,
  versions
  }: DashboardProps) {

  const { dashboard_type } = useParams();
  const [showUpdateFinish, setShowUpdateFinish] = useState(false);

  useEffect(() => {
    MobileEventHandler.analytics("dashboard_screen_wv")
  }, [])

  const panels: { [id: string] : JSX.Element } = useMemo(() => {
    return({
      [SPENDING]: <Spending
        showSharePanel={showSharePanel}
        setShowSharePanel={setShowSharePanel} />,
      [PURCHASES]: <Purchases
        showSharePanel={showSharePanel}
        setShowSharePanel={setShowSharePanel} />,
      //[REVIEWS]: <Review />
    });
  }, [showSharePanel, setShowSharePanel]);

  const type = useMemo(() => {
    return dashboard_type ? dashboard_type : SPENDING;
  }, [dashboard_type]);

  return (
    <DashboardWrapper>
      <UpdateFinishCard
        showUpdateFinish={showUpdateFinish}
        setShowUpdateFinish={setShowUpdateFinish}
      />
      <div style={{paddingTop: 16}}/>
      <NotificationCarousel
        showNotificationCarousel={showNotificationCarousel}
        setShowNotificationCarousel={setShowNotificationCarousel}
        versions={versions}
      />
      {panels[type]}
      <UpdateIndicator
        hasDataLoaded={hasDataLoaded}
        data={data}
        lastUpdate={lastUpdate}
        setShowUpdateFinish={setShowUpdateFinish}
        statusItemCounter={statusItemCounter}
        updateStatus={updateStatus}
        updating={updating}
      />
    </DashboardWrapper>
  );
}

export default Dashboard;
