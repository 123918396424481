import { Chart } from 'chart.js';
import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

function LoadingDoughnutChart() {
  const data = {
    datasets: [{
      data: [1],
      backgroundColor: "#F7F6FF",
      hoverOffset: 4
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    containerId: "loading-doughnut-legend-id",
    layout: {
      padding: 10
    },
    offset: 0,
    spacing: 0,
    borderWidth: 0,
    cutout: '80%',
  };

  const chartRef = useRef<Chart>(null);

  return <Doughnut 
    // @ts-ignore
    ref={chartRef}
    options={options} 
    data={data}
  />;
}

export default LoadingDoughnutChart;
