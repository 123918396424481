import React from "react";
import { AmazonHandler, AmazonReview } from '../../amazon';
import { Message } from '../../messaging'

const AmazonContext = React.createContext({
  // logged in to amazon
  logged_in: true,

  // boolean representing updating status
  updating: false,

  // amazon purchases, may be a limited subset
  amazonHandler: AmazonHandler.newAmazonHandler(''),

  // full set of amazon purchases
  fullHandler: AmazonHandler.newAmazonHandler(''),

  // Reviews a user has left on Amazon
  amazonReviews: [] as AmazonReview[],

  // messages from advertisers
  messages: [] as Message[],

  // function to signal message state changes
  setMessages: function(a:Message[]):void{},

  localClaimedTotal: 0,
  setLocalClaimMessages: function (m: Message): void{},
});

export default AmazonContext;
