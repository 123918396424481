import { AmazonHelpers } from "amazon";
import { Bar } from "react-chartjs-2";
import { GRAPH_NUMBER } from "../../../styles/generics";

type FiveYearPurchaseChartProps = {
  labels: number[],
  tableData: number[]
}

function FiveYearPurchaseChart({ labels, tableData } : FiveYearPurchaseChartProps) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x1: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: GRAPH_NUMBER,
          },
        },
      },
      y1: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          callback: function(value: any) {
            return AmazonHelpers.numberFormat(value);
          },
          font: {
            size: GRAPH_NUMBER,
          },
        },
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        backgroundColor: '#8A7DD7',
        barThickness: 18,
        borderRadius: 50,
        data: tableData,
      }
    ],
  };

  return <Bar options={options} data={data} />
}

export default FiveYearPurchaseChart;
