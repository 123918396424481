import { UskoCategory } from "./categories"
import { Asin } from "../../amazon"

export interface PurchesesByMonthData {
  [year: string]: number[];
}

export type HighlightType = "Largest Purchase" | "Most Purchased" | "Best Deal" | "First Purchased";

export interface HighlightPropData {
  category: HighlightType;
  asin: Asin;
  img: string;
  price: number;
  item: string;
}

export const MonthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export interface SpendingDayOfWeekProps {
  max: number;
  values: number[];
}

export type CategoriesSpendingTableProps = Map<UskoCategory, number>;

export interface StatsStandaloneProps {
  years: number[];
  data: {
    [id: string]: {
      grocery_trips: number;
      avg_cost_trip: number;
      avg_purchases_trip: number;
    };
  };
}
export interface SpendingData {
  delta: number;
  labels: string[]; // years
  data: number[]; // prices
  totalSpend: number;
}

export interface SpendingYearlyOrMonthlyProps {
  yearly: SpendingData;
  monthly: SpendingData;
}