import { UskoCategory } from "amazon";
import {useEffect, useMemo} from "react";
import { useParams } from "react-router-dom";
import { category_titles } from "../Dashboard/categories/categories";
import FilterBar from "./FilterBar";
import { sortOptions } from "./filterOptions";
import TransactionDateRange from "./TransactionDateRange";
import TransactionSettings from "./TransactionSettings";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const CATEGORY = "category";
const SORT = "sort";
const DATE_RANGE = "date-range";

const category_options = [
  "All Categories"
];
const category_text = [
  "⭐ All Categories"
];
Array.prototype.push.apply(
  category_options,
  Object.keys(category_titles)
);
Array.prototype.push.apply(
  category_text,
  Object.keys(category_titles).map((c) => category_titles[UskoCategory[c as keyof typeof UskoCategory]])
);

const panels: { [id: string] : JSX.Element } = {
  [CATEGORY]: <TransactionSettings q={CATEGORY}
    options={category_options}
    text={category_text}
  />,
  [SORT]: <TransactionSettings q={SORT} options={Object.keys(sortOptions)}/>,
  [DATE_RANGE]: <TransactionDateRange />
}

export const panel_titles: { [id: string] : string } = {
  [CATEGORY]: "Categories",
  [SORT]: "Sort",
  [DATE_RANGE]: "Date Range"
}

const nav_items = [CATEGORY, SORT, DATE_RANGE];

function TransactionsFilter() {
  const { transaction_type } = useParams();

  useEffect(() => {
    MobileEventHandler.analytics("transactions_filter_wv")
  }, [])

  const type = useMemo(() => {
    return transaction_type ? transaction_type : CATEGORY;
  }, [transaction_type]);

  return <>
    <FilterBar panels={Object.keys(panels)} selected={nav_items.indexOf(type)}/>
    {panels[type]}
  </>;
}

export default TransactionsFilter
