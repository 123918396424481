import styled from "styled-components";
import { useState } from "react";

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: 2rem;
  height: 0.75rem;
  background: #b3b3b3;
  border-radius: 2rem;
  padding: 0.25rem;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 35px;
    top: 50%;
    left: 0.025rem;
    background: #6b6bd4;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: #b8c3e9;

    &:before {
      transform: translate(0.875rem, -50%);
    }
  }
`;

function Toggle() {
  const [checked, setChecked] = useState(true);

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <Label>
      <Input checked={checked} type="checkbox" onChange={handleChange} />
      <Switch />
    </Label>
  );
}

export default Toggle;
