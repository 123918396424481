import {useCallback, useEffect, useMemo, useState} from 'react';

import "./Inter/inter.css"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

import styled from 'styled-components';

import AmazonContext from '../Contexts/AmazonContext';
import ViewsRouter from './Routes/ViewsRouter';

import BottomNavBar from 'components/App/Nav/BottomNavBar';

import {AmazonData, AmazonHandler, AmazonReview, ItemStatus, MockData} from 'amazon';
import {Message, MessageStates, MockMessages, nativeMessagesToWebView, processMessages} from 'messaging';
import {
  AMAZON_INCREMENTAL_REPORT_EVENT,
  AMAZON_INITIAL_REPORT_EVENT,
  AMAZON_LAST_UPDATE_EVENT,
  AMAZON_LOGIN_STATUS_EVENT,
  AMAZON_REFRESHING_DATA_EVENT,
  MESSAGE_EVENT,
  MARKETPLACE_REF_LINK_LOADING,
  MARKETPLACE_ALL_ITEMS_EVENT,
  MARKETPLACE_ITEMS_EVENT,
  NATIVE_VARIABLES,
  USER_BALANCE,
  VERSIONS_EVENT,
} from '../Bridge/incoming_events';
import {MarketplaceItem} from './Marketplace/marketplaceTypes';
import {WEBVIEW_READY_EVENT} from '../Bridge/outgoing_events';
import {MobileEventHandler} from '../Bridge/MobileEventHandler';

import {Chart as ChartJS, defaults as ChartDefaults, registerables} from 'chart.js';
import LoggedInPrompt from './prompts/LoggedInPrompt';
import NavRouter from 'components/App/Routes/NavRouter';

ChartDefaults.font = {
  ...ChartDefaults.font,
  family: 'Inter',
  style: 'normal',
  weight: '500',
  size: 10
}
ChartDefaults.color = '#4D4C4C';

ChartJS.register(...registerables);


const AppContainer = styled.div`
  font-family: 'Inter', -apple-system, 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700;

  color: #4D4C4C;
`;

function App() {
  const useMocks = new URLSearchParams(window.location.search).get('mocks');
  const incrementalMocks = new URLSearchParams(window.location.search).get('incrementalMocks');
  const useEnvironment = new URLSearchParams(window.location.search).get('environment');

  const [logged_in, setLoggedIn] = useState(true);
  const [loadedData, setLoadedData] = useState<AmazonData|undefined>(undefined);
  const [displayData, setDisplayData] = useState<AmazonData|undefined>(undefined);
  const [progressId, setProgressId] = useState<ReturnType<typeof setInterval>|undefined>(undefined);
  const [prevStatus, setPrevStatus] = useState<ItemStatus|undefined>(undefined);
  const [amazonHandler, setAmazonHandler] = useState(AmazonHandler.newAmazonHandler(''));
  const [messageHandler, setMessageHandler] = useState([] as Message[]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<Date|undefined>(undefined);
  const [hasDataLoaded, setDataLoaded] = useState(false);
  const [localClaimMessages, setLocalClaimMessagesHandler] = useState<Message[]>([])
  const [localClaimedTotal, setLocalClaimedTotal] = useState(0)
  const [pendingBalance, setPendingBalance] = useState<number|undefined>(undefined);
  const [marketplaceAllItems, setMarketplaceAllItems] = useState<MarketplaceItem[]>([]);
  const [marketplaceItems, setMarketplaceItems] =  useState<MarketplaceItem[]>([]);
  const [nativeVariables, setNativeVariables] = useState<{[key: string]: string}[]|undefined>(undefined);
  const [loadingMarketplaceLink, setLoadingMarketplaceLink] = useState<boolean|undefined>(undefined);
  const [versions, setVersions] = useState<{[key: string]: any}>(
    {
      appVersion: null,
      dashboardVersion: null,
      axeVersion: null,
      eulaVersion: null,
      environment: null,
      uskoRegisteredAt: null,
      referralBrand: null,
    }
  );
  const [statusItemCounter, setStatusItemCounter] = useState(
    {
      [ItemStatus.Loading]: 0,
      [ItemStatus.Categorizing]: 0,
      [ItemStatus.Complete]: 0,
    }
  );
  const [earnedCredits, setEarnedCredits] = useState<string|undefined>(undefined)

  const updateLocalClaimed = useCallback((m: Message) => {
    const found = localClaimMessages.find(it => it.id === m.id)
    if (!found) {
      console.log("message will be appended")
      const messages = [...localClaimMessages, m];
      setLocalClaimMessagesHandler(messages)
      const currentTotal = messages.reduce((acc, curr) => acc + curr.credits, 0)
      console.log("Current total...", currentTotal)
      setLocalClaimedTotal(currentTotal)
    } else {
      console.log("Message was found...")
    }
  }, [localClaimMessages, setLocalClaimMessagesHandler, setLocalClaimedTotal])

  const amazonCtx = {
    logged_in,
    updating,
    amazonHandler,
    fullHandler: amazonHandler,
    amazonReviews: [],
    messages: messageHandler,
    setMessages: setMessageHandler,
    localClaimedTotal,
    setLocalClaimMessages: updateLocalClaimed
  }

  useEffect(() => {
    if (!updating) {
        // no change, so close the indicator
        setDisplayData(undefined);
        setLoadedData(undefined);
        setStatusItemCounter({
          [ItemStatus.Loading]: 0,
          [ItemStatus.Categorizing]: 0,
          [ItemStatus.Complete]: 0,
        });
        setLastUpdate(new Date());
    }
    else {
      if(progressId !== undefined) {
        return;
      }
      if(loadedData !== displayData){
        setProgressId(setTimeout(() => {
          setProgressId(undefined);
        }, 2000));
        setDisplayData(loadedData);
      }
    }
  }, [updating, displayData, loadedData, progressId]);

  // Mount handlers.
  useEffect(() => {
    if (useMocks !== null) {
      setAmazonHandler(AmazonHandler.newAmazonHandler(MockData));
      setMessageHandler(MockMessages);
    }
    else {
      // Add handlers for relevant events.
      MobileEventHandler.add_handler(AMAZON_INITIAL_REPORT_EVENT.type, (data) => {
        setAmazonHandler(AmazonHandler.newAmazonHandler(data));
        setDataLoaded(true);
      }, AMAZON_INITIAL_REPORT_EVENT.type);
    }

    if (useEnvironment !== null){
      // Sets version environment to environment search param.
      setVersions(prev => ({...prev, environment: useEnvironment}));
    }
    else {
      // Add handler for the versions event.
      MobileEventHandler.add_handler(VERSIONS_EVENT.type, (data) => {
        setVersions(data);
      }, VERSIONS_EVENT.type);
    }

    MobileEventHandler.add_handler(AMAZON_LOGIN_STATUS_EVENT.type, (data) => {
      setLoggedIn(data && data !== 'false');
    }, AMAZON_LOGIN_STATUS_EVENT.type);

    MobileEventHandler.add_handler(AMAZON_REFRESHING_DATA_EVENT.type, (data) => {
      setUpdating(data);
    }, AMAZON_REFRESHING_DATA_EVENT.type);

    MobileEventHandler.add_handler(AMAZON_LAST_UPDATE_EVENT.type, (data) => {
      setLastUpdate(new Date(data));
    }, AMAZON_LAST_UPDATE_EVENT.type);

    MobileEventHandler.add_handler(USER_BALANCE.type, (data) => {
      setEarnedCredits(data.balance)
    }, USER_BALANCE.type);

    MobileEventHandler.add_handler(NATIVE_VARIABLES.type, (data) => {
      setNativeVariables(data);
    }, NATIVE_VARIABLES.type);

    MobileEventHandler.add_handler(MARKETPLACE_REF_LINK_LOADING.type, (data) => {
      setLoadingMarketplaceLink(data && data.loading !== 'false')
    }, MARKETPLACE_REF_LINK_LOADING.type);

    MobileEventHandler.add_handler(MARKETPLACE_ITEMS_EVENT.type, (data) => {
      setMarketplaceItems(data);
    }, MARKETPLACE_ITEMS_EVENT.type);

    MobileEventHandler.add_handler(MARKETPLACE_ALL_ITEMS_EVENT.type, (data) => {
      setMarketplaceAllItems(data);
    }, MARKETPLACE_ALL_ITEMS_EVENT.type);

    // Set up native bridge.
    window.native_event = (payload: any) => {
      MobileEventHandler.consume_event(payload);
    }

    // Perform native handshake.
    MobileEventHandler.post_event(WEBVIEW_READY_EVENT);
  }, [useMocks, useEnvironment]);

  useEffect(() => {
    MobileEventHandler.add_handler(AMAZON_INCREMENTAL_REPORT_EVENT.type, (data) => {
      // Increment data.
      setAmazonHandler(prevAmazonHandler => {
        const [parsedData, ah] = prevAmazonHandler.appendAmazonData(data);
        if(parsedData.length > 0) {
          setLoadedData(parsedData[0])
          let dataStatus = ItemStatus.Loading;
          if (parsedData.every(it => it.status === ItemStatus.Complete)) {
            dataStatus = ItemStatus.Complete;
          } else if (parsedData.every(it => it.status === ItemStatus.Categorizing)) {
            dataStatus = ItemStatus.Categorizing;
          }

          if (prevStatus !== dataStatus) setPrevStatus(dataStatus);

          const itemStatus = ah.getItemStatus();
          setStatusItemCounter(itemStatus);
        }
        setDataLoaded(true);
        return ah;
      });
    }, AMAZON_INCREMENTAL_REPORT_EVENT.type);
  }, [amazonHandler, prevStatus]);

  useEffect(() => {
    const messages = processMessages(messageHandler, amazonHandler.getOrders(), [] as AmazonReview[]);
    if (messages.length > 0) {
      setMessageHandler(messages);
    }
  }, [amazonHandler, messageHandler]);

  useEffect(() => {
    if(!nativeVariables) return;
    const pendingBalanceNV = nativeVariables.find(variable => variable.name === "pendingBalance");
    if(pendingBalanceNV && pendingBalance === undefined) setPendingBalance(parseInt(pendingBalanceNV.value));
  }, [nativeVariables, pendingBalance]);

  useEffect(() => {
    MobileEventHandler.add_handler(MESSAGE_EVENT.type, data => {
      setMessageHandler(nativeMessagesToWebView(data));
    }, MESSAGE_EVENT.type);
  }, []);

  // logic for simulating incremental loading of data
  useEffect(() => {
    if (incrementalMocks !== null) {
      // configure incremental update interval between mocks
      let interval = parseInt(incrementalMocks);
      if (isNaN(interval)) interval = 150;

      // Takes three passes thru the data:
      // first without categories with item status of "Loading"
      // second and third with categories and item status of "Categorizing" and "Complete"
      // respectively to pseudo simulate the phases of item data scrapping
      let lines = MockData.split('\n');
      // Add internal status of the item as a header, which is added by native
      // to show what state the data being passed is in
      const header = lines[0].concat(',itemInternalStatus');
      const firstTwoYearsLines = [...lines].slice(461, lines.length);
      const remainingLines = [...lines].slice(1, 461);
      // start increment data backwards for most recent data first
      let i = firstTwoYearsLines.length - 1;
      let count = 0;
      // indicates the first pass of the 3 phases of loading the first 2 years of information
      let firstPass = true;
      let pauseIncrementalMocks = false;
      lines = firstTwoYearsLines;
      window.native_event(JSON.stringify({
        type: AMAZON_REFRESHING_DATA_EVENT.type,
        data: true,
      }));
      let intervalId = setInterval(function(){
        if(!pauseIncrementalMocks) {
          // go backwards on the second pass, since charts show most recent results
          let line = lines[i];

          // replace unspsc code with progress code 00 for first pass
          //
          // unfortunately complex to address edge cases in the mock data.
          // We could fix mockdata itself but would have to remember to re-apply
          // any fixups every time we update the mockdata (which we won't)
          // More resilient to do the fixup in code
          if (count < 2) {
            if (line.match(/ABIS_BOOK,[0-9]{8},[0-9]{8},/)) {
              // book isbns are also 8 digit numbers so replace the 2nd one
              line = line.replace(/,([0-9]{8}),[0-9]{8},/, ",$1,00,");
            } else {
              // replace first 8-digit number with 00
              line = line.replace(/,[0-9]{8},/, ",00,");
            }
            // if no replacement, probably means it was one of those empty items
            // so try to fix 5th field to mitigate weirdness w/ category chart
            // otherwise unset categories show up as "unknown"
            if (line === lines[i]) {
              let t = 0;
              line = line.replace(/,/g, match => ++t === 5 ? ",00" : match);
            }
          }
          //Add item internal status from 0 - 2 for the stages of data scrapping for item information
          line = line.concat(`,${count}`);

          const data = header+"\n"+line+"\n";
          i--;
          window.native_event(JSON.stringify({
            type: AMAZON_INCREMENTAL_REPORT_EVENT.type,
            data: data,
          }));
          if (i === -1) {
            if (count === 2) {
              if(!firstPass) {
                window.native_event(JSON.stringify({
                  type: AMAZON_REFRESHING_DATA_EVENT.type,
                  data: false,
                }));
                clearInterval(intervalId);
              } else {
                pauseIncrementalMocks = true;
                window.native_event(JSON.stringify({
                  type: AMAZON_REFRESHING_DATA_EVENT.type,
                  data: false,
                }));
                // reset incremental mocks to go thru the remaining mock data after 20 second delay
                setTimeout(() => {
                  lines = remainingLines;
                  i = lines.length - 1;
                  count = 0;
                  firstPass = false;
                  window.native_event(JSON.stringify({
                    type: AMAZON_REFRESHING_DATA_EVENT.type,
                    data: true,
                  }));
                  pauseIncrementalMocks = false;
                }, 20000);
              }
            } else {
              i = lines.length - 1;
              count++;
            }
          }
        }
      }, interval);
    }
  }, [incrementalMocks]);

  const [lastCheckedBalance, setLastCheckedBalance] = useState<{[key: string]: number}>({
    current: earnedCredits ? Number.parseInt(earnedCredits) + localClaimedTotal : localClaimedTotal,
    pending: pendingBalance || 0,
  });
  const hasWalletUpdates = useMemo(() => {
    if(lastCheckedBalance.current === (earnedCredits ? Number.parseInt(earnedCredits) + localClaimedTotal : localClaimedTotal) &&
      lastCheckedBalance.pending === (pendingBalance || 0)) return false;
    return true;
  }, [earnedCredits, lastCheckedBalance, localClaimedTotal, pendingBalance]);

  return (
    <AppContainer className="App">
      <AmazonContext.Provider value={amazonCtx}>
        <NavRouter
          earnedCredits={earnedCredits}
          hasWalletUpdates={hasWalletUpdates}
          marketplaceAllItems={marketplaceAllItems}
          versions={versions}
        />
        <LoggedInPrompt logged_in={logged_in} />
        <ViewsRouter
          data={displayData}
          earnedCredits={earnedCredits}
          hasDataLoaded={hasDataLoaded}
          lastUpdate={lastUpdate}
          loadingMarketplaceLink={loadingMarketplaceLink}
          marketplaceItems={marketplaceItems}
          marketplaceAllItems={marketplaceAllItems}
          pendingBalance={pendingBalance}
          setLastCheckedBalance={setLastCheckedBalance}
          setLoadingMarketplaceLink={setLoadingMarketplaceLink}
          setPendingBalance={setPendingBalance}
          statusItemCounter={statusItemCounter}
          updateStatus={prevStatus}
          updating={updating}
          versions={versions}
        />
        <BottomNavBar environment={versions.environment} versions={versions} />
      </AmazonContext.Provider>
    </AppContainer>
  );
}
export default App;
