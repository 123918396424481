import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import AmazonContext from "components/Contexts/AmazonContext";

import styled from "styled-components";
import { PageTitle } from "../styles/generics";
import backArrowIcon from "icons/back-arrow-icon.svg";
import coin from "icons/coin.svg";
import SearchIcon from "icons/search-icon.svg";

const CreditsNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 0 1rem;
`;
const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;
const NavTitle = styled(PageTitle)`
  margin: 0;
`;
const BackIcon = styled.img`
  margin-right: 0.625rem;
`;
const NavRight = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;
const CreditsBalance = styled.div<any>`
  min-width: 6.75rem;
  max-width: 12rem;
  height: 2.0625rem;
  display: flex;
  align-items: center;
  background-color: #EFEDFF;
  gap: 0.25rem;
  padding: 1rem;
  border-radius: 0.5rem;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  ${props => props.hasUpdates && `
    outline: 1px solid rgba(107,107,212,1.0);
    animation-duration: 1.5s;
    animation-name: outline-pulse;
    animation-iteration-count: 3;
    animation-timing-function: ease-in-out;

    @keyframes outline-pulse {
      0% {
        outline: 1px solid rgba(107,107,212,1.0);
      }
      50% {
        outline: 1px solid rgba(107,107,212,0.0);
      }
      100% {
        outline: 1px solid rgba(107,107,212,1.0);
      }
    }
  `}
`;
const CreditsNum = styled.span`
  max-width: calc(50vw - 6.6875rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const CoinImg = styled.img`
  height: 0.94rem;
  object-position: center;
  object-fit: fit;
  margin-right: 0.25rem;
`;
const NavSearchIcon = styled.img`
  height: 2.0625rem;
  padding: 0.375rem;
`;

type CreditsNavProps = {
  earnedCredits?: string|undefined;
  goBack?: boolean;
  hasUpdates?: boolean;
  searchType?: string;
  showCredits?: boolean;
  title: string;
}

function CreditsNav({ earnedCredits, goBack,  hasUpdates, searchType, showCredits, title }: CreditsNavProps) {
  const navigate = useNavigate();
  const { localClaimedTotal } = useContext(AmazonContext);
  const creditsBalance = useMemo(() => {
    return earnedCredits ? Number.parseInt(earnedCredits) + localClaimedTotal : localClaimedTotal;
  }, [earnedCredits, localClaimedTotal]);

  return(
    <CreditsNavWrapper>
      <NavLeft>
        {goBack ?
          <BackIcon onClick={() => navigate(-1)} src={backArrowIcon} />
          :
          null}
        <NavTitle>{title}</NavTitle>
      </NavLeft>
      <NavRight>
        {showCredits &&
          <CreditsBalance
            hasUpdates={hasUpdates}
            onClick={() => {
              navigate('/wallet');
            }}
          >
            <CoinImg src={coin} />
            <CreditsNum>{creditsBalance}</CreditsNum> USK
          </CreditsBalance>}
        {searchType &&
          <NavSearchIcon
            onClick={() => searchType === 'marketplace' ? navigate('/marketplace/search') : navigate('/search')}
            src={SearchIcon}
            alt="search-page"
          />}
      </NavRight>
    </CreditsNavWrapper>
  );
}

export default CreditsNav;
