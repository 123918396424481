import * as queries from './queries'

export enum MessageStates {
  // ATTENTION only ever add to the end of this list!
  Unprocessed = 1,
  Rejected,
  Errored,
  Matched,
  Opened,
  Read,
}

export type MessageClicks = {
  when: number;
  url: string;
}

export type MessageViews = {
  when: number;
  duration: number;
}

export type MessageSurvey = {
  answer: string;
  feedback?: string;
}

export type MessageInteractions = {
  clicks: MessageClicks[];
  views: MessageViews[];
  survey?: MessageSurvey;
}

export type Message = {
  query: queries.Query;
  state: MessageStates;
  interactions: MessageInteractions;

  id: string;
  active: boolean;
  createdAt: Date;
  openedAt?: Date;
  credits: number;

  icons: {
    one: string;
    two: string;
    three: string
  } | undefined;

  productImg: string | undefined;

  heroImg: string | undefined;

  // result of evaluating a successful query
  // should be stored so the evaluated data doesn't change
  res?: queries.Expr;
}
