import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_CLICK_LINK } from "components/Bridge/outgoing_events";
import {
  Expr,
  MsgLink,
  Message as TMessage,
  MessageStates,
  postClaimed,
  postClick,
  postView,
} from "messaging";
import AmazonContext from "components/Contexts/AmazonContext";

import MessageFeedback from "./MessageFeedback";

import styled from "styled-components";
import {
  LargeHeading,
  PanelWrapper,
  PanelWidthButton,
  PanelWidthColoredButton,
  SmallText,
  SmallSpan,
} from "../styles/generics";
import uskoBanner from "../../../images/usko-banner.png";
import uskoHeader from "../../../images/USKO-header-logo.png";
import ThinNav from "../Nav/ThinNav";

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 3.75rem;
  overflow: hidden;
`;
const MessageBanner = styled.img`
  height: 9rem;
  width: 100vw;
  object-fit: cover;
  object-position: center;
  margin-bottom: 5rem;
`;
const MessageBannerDefault = styled.div`
  background: linear-gradient(270deg, #7551cb 3.49%, #5172c8 92.38%);
  height: 9rem;
  width: 100vw;
  margin-bottom: 5rem;
`;
const MessageTitlePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 10.4375rem;
  width: calc(100vw - 2rem);
  background-color: #ffffff;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 2rem 0 0;
`;
const MessageTitleText = styled(LargeHeading)`
  height: 4.875rem;
  text-align: center;
  color: #4c4c4c;
`;
const MessageContent = styled(SmallText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 0 1rem;
`;
const MessageMainPanel = styled(PanelWrapper)`
  width: 100%;
  padding: 12rem 2.64rem 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
  margin: 0;
  text-align: center;
`;
const MessageHeader = styled.img`
  height: 14.625rem;
  width: 100%;
  object-fit: contain;
  oject-position: center;
  padding: 0 2rem;
`;
const MessageInlineLink = styled(SmallSpan)`
  color: #6b6bd4;
  text-decoration: underline;
`;

// TODO tighten up post-eval msg type to (string | MsgLink)[]
type z = string | (string | Expr)[];

function renderResult(
  msg: TMessage,
  click: (msg: TMessage, url: string) => void,
  earn: (msg: TMessage) => void,
) {
  let ctr = 0;
  function renderMsgLink(link: MsgLink) {
    return (
      <MessageInlineLink
        key={"messageLink_" + ctr++}
        onClick={() => {
          click(msg, link.url);
        }}
      >
        {link.text}
      </MessageInlineLink>
    );
  }
  function escapeLinebreaks(m:string){
      // TODO temporary! until users have migrated to a non-broken app build
      // Once this is safe to remove we need to update any active campaigns
      // might still be using this scheme
    return m.replaceAll("__USKOLINEBREAK__", "\n\n");
  }
  function renderMsg(m: z) {
    if (typeof m === "string") return escapeLinebreaks(m);
    return m.map((e) => {
      if (typeof e === "string") return escapeLinebreaks(e);
      if (e.kind === "msgLink") return renderMsgLink(e);
      return "unhandled type " + e.kind; // should be caught by typechecker
    });
  }
  const e = msg.res;
  if (!e || e.kind !== "msg") {
    return "Unknown Message";
  }
  const message = renderMsg(e.message);
  return <div style={{whiteSpace: "pre-wrap"}}>{message}</div>;
}

function Message() {
  const navigate = useNavigate();
  const { msgId } = useParams();
  const { messages, setMessages, setLocalClaimMessages } = useContext(AmazonContext);
  const msg = messages.find((m) => m.id === msgId);
  const [msgState, setMessageState] = useState(msg?.state || 0);
  const [showFeedback, setShowFeedback] = useState(!msg?.interactions?.survey);
  //TODO connect brand banner field
  const logos2x : { [key:string]:string } = {
    "Usko Privacy": uskoHeader,
    "Red Racer Books":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/288dbea3-57d4-4bdd-921c-ac8183c22d00/default",
    "Victoria":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/54630ebb-a83f-45ec-356a-4ca5d321af00/default",
    "Homely": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/be84e685-2d82-453e-d731-3aebf441b500/default",
    "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/29e1de38-797a-4300-0364-e1e8599afa00/default",
    "CanDo Krisp": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/68090472-9a72-4c33-314e-3479f5597900/default",
    "GC Life":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/ef210b10-8f5d-4721-72bb-61440e96c700/default",
    "Joi":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/0af3697c-c6af-4cae-488a-ccc98034bc00/default",
    "Gleamin": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/918c7044-f95f-44c7-5096-0949bf69c200/default",
    "NeuroGum": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/07504f59-f79d-4254-d401-f0c904c02d00/default",
    "Cooler Kitchen": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a4de58ac-4178-4701-5d6b-61250b7dae00/default",
    "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/2f45036c-1340-4456-79de-d9c1af228000/default",
    "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/c1328392-52ea-4f8f-fd89-57c76dfefe00/default",
    "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/2507c58b-4a2a-4be6-7dac-1ac148191700/default",
    "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/30b56a87-781f-46cc-59b1-111c582e2500/default",
    "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/2e8ffcd1-aabd-41a1-4419-59ed4b460b00/default",
  };
  const logos1x : { [key:string]:string } = {
    "Usko Privacy": uskoHeader,
    "Red Racer Books":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/288dbea3-57d4-4bdd-921c-ac8183c22d00/default",
    "Victoria":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/54630ebb-a83f-45ec-356a-4ca5d321af00/default",
    "Homely": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/be84e685-2d82-453e-d731-3aebf441b500/default",
    "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/29e1de38-797a-4300-0364-e1e8599afa00/default",
    "CanDo Krisp": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/68090472-9a72-4c33-314e-3479f5597900/default",
    "GC Life":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/ef210b10-8f5d-4721-72bb-61440e96c700/default",
    "Joi": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/0af3697c-c6af-4cae-488a-ccc98034bc00/default",
    "Gleamin": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/918c7044-f95f-44c7-5096-0949bf69c200/default",
    "NeuroGum": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/07504f59-f79d-4254-d401-f0c904c02d00/default",
    "Cooler Kitchen": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a4de58ac-4178-4701-5d6b-61250b7dae00/default",
    "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/2f45036c-1340-4456-79de-d9c1af228000/default",
    "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/c1328392-52ea-4f8f-fd89-57c76dfefe00/default",
    "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/2507c58b-4a2a-4be6-7dac-1ac148191700/default",
    "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/30b56a87-781f-46cc-59b1-111c582e2500/default",
    "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/2e8ffcd1-aabd-41a1-4419-59ed4b460b00/default",
  };
  const heroes2x : { [key:string]:string } = {
    "Usko Privacy": uskoBanner,
    "Red Racer Books": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/d8a4645b-b072-4999-e30e-eb7334562f00/hero2x",
    "Victoria":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/782df035-ce3d-485e-25ba-cbe4f6284100/default",
    "CanDo Krisp": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/7a688492-cf54-47a1-20dd-6954b0f1d600/hero2x",
    "GC Life": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/255ecbae-3cba-4702-4659-dd0554032d00/default",
    "Joi": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f0e7daff-744b-4574-34a9-d659248b4100/hero2x",
    "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/106e0c4a-3d84-41b8-9e0f-07cc21329d00/hero2x",
    "Gleamin": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/4fdda50e-0c45-471d-3abe-29d140a0a200/default",
    "NeuroGum": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/cedd63c6-8f9b-486d-9762-1ccb2e372200/hero2x",
    "Cooler Kitchen": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/55350a80-9f83-4503-ea0d-03ef3bf7a600/default",
    "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/69378fff-7d58-4757-e385-efcba132fc00/default",
    "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f726e7bc-414d-4b8a-662f-f3ba79e42d00/default",
    "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/07355c3c-e622-4f28-8ab6-ffed339dc600/default",
    "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/54e67a1a-1790-48ff-adad-7d6c48f42200/default",
    "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/8d754ec6-c8fe-4b8f-9dc5-e7c8e5c04d00/default",
  };
  const heroes1x : { [key:string]:string } = {
    "Usko Privacy": uskoBanner,
    "Red Racer Books": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/d8a4645b-b072-4999-e30e-eb7334562f00/hero1x",
    "Victoria":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/782df035-ce3d-485e-25ba-cbe4f6284100/default",
    "CanDo Krisp": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/7a688492-cf54-47a1-20dd-6954b0f1d600/hero1x",
    "GC Life": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/255ecbae-3cba-4702-4659-dd0554032d00/default",
    "Joi": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f0e7daff-744b-4574-34a9-d659248b4100/hero1x",
    "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/106e0c4a-3d84-41b8-9e0f-07cc21329d00/hero1x",
    "Gleamin": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/4fdda50e-0c45-471d-3abe-29d140a0a200/default",
    "NeuroGum": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/cedd63c6-8f9b-486d-9762-1ccb2e372200/hero1x",
    "Cooler Kitchen": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/55350a80-9f83-4503-ea0d-03ef3bf7a600/default",
    "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/69378fff-7d58-4757-e385-efcba132fc00/default",
    "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f726e7bc-414d-4b8a-662f-f3ba79e42d00/default",
    "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/07355c3c-e622-4f28-8ab6-ffed339dc600/default",
    "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/54e67a1a-1790-48ff-adad-7d6c48f42200/default",
    "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/8d754ec6-c8fe-4b8f-9dc5-e7c8e5c04d00/default",
  };
  const logo1x = msg?.productImg ?? logos1x[msg?.query.brand || ""];
  const logo2x = msg?.productImg ?? logos2x[msg?.query.brand || ""];
  const hero1x = msg?.heroImg ?? heroes1x[msg?.query.brand || ""];
  const hero2x = msg?.heroImg ?? heroes2x[msg?.query.brand || ""];

  const { link, linkText } = useMemo(() => {
    if (!msg || !msg.res || msg.res.kind !== "msg") {
      return { link: undefined, linkText: undefined };
    }
    const link = msg.res.link ? msg.res.link.url : undefined;
    const linkText = msg.res.link ? msg.res.link.text : undefined;
    return { link, linkText };
  }, [msg]);

  useEffect(() => {
    MobileEventHandler.analytics("message_details_wv")
  }, [])

  const earn = useCallback((msg: TMessage) => {
    // TODO prevent exit or whatever
    if (msg.state !== MessageStates.Opened || !msg.active) return;
    msg.state = MessageStates.Read;
    setMessageState(msg.state);
    setMessages([...messages]);
    postClaimed(msg);
    setLocalClaimMessages(msg)
  }, [setMessages, messages, setLocalClaimMessages]);

  const click = (msg: TMessage, url: string) => {
    postClick(msg, url);
    MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, url);
  };

  const leave = useCallback((msg: TMessage) => {
    const start = new Date();
    return () => {
      postView(msg, start);
      earn(msg); // For now, earn immediately
    };
  }, [earn]);

  //when component dismounts, call the leave function if msg exists
  useEffect(() => {
    return msg ? leave(msg) : undefined;
  }, [msg, leave]);

  // TODO fill out with a proper no message found template
  if (!msg || !msg.res) {
    return (
      <div>
        No Message Found<div onClick={() => navigate(-1)}>Go Back</div>
      </div>
    );
  }

  return (
    <MessageWrapper>
      <ThinNav title={msg.query.brand} goBack={() => navigate(-1)} />
      {hero1x ? <MessageBanner src={hero1x} srcSet={`${hero1x} 1x, ${hero2x} 2x`} /> : <MessageBannerDefault />}
      <MessageTitlePanel>
        {logo1x ? (
          <MessageHeader src={logo1x} srcSet={`${logo1x} 1x, ${logo2x} 2x`} />
        ) : (
          <MessageTitleText>{msg.query.brand}</MessageTitleText>
        )}
      </MessageTitlePanel>
      <MessageContent>
        <MessageMainPanel>{renderResult(msg, click, earn)}</MessageMainPanel>
        {link && linkText ? (
          <PanelWidthColoredButton
            onClick={() => {
              if (msgState === MessageStates.Opened) {
                MobileEventHandler.analytics("click_message_link_wv")
                earn(msg);
              }
              click(msg, link);
            }}
          >
            {linkText}
          </PanelWidthColoredButton>
        ) : null}
        {showFeedback ? (
          <MessageFeedback
            click={click}
            msg={msg}
            setShowFeedback={setShowFeedback}
            showFeedback={showFeedback}
          />
        ) : null}
        <PanelWidthButton onClick={() => navigate(-1)}>Back to Inbox</PanelWidthButton>
      </MessageContent>
    </MessageWrapper>
  );
}

export default Message;
