import styled from "styled-components";
import { IconBadge } from "../../components/App/styles/generics";

const InboxIconWrapper = styled.div`
  position: relative;
`;
const InboxBadge = styled(IconBadge)`
`;

function InboxIcon({ color, badge }: { color?: string, badge?: boolean }) {
  return(
    <InboxIconWrapper>
      <svg width="23" height="20" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6623 11.0247H22.3258C22.6576 11.0247 22.9182 10.764 22.9182 10.4323C22.9182 10.1005 22.6576 9.83987 22.3258 9.83987H11.6623C11.3306 9.83987 11.0699 10.1005 11.0699 10.4323C11.0699 10.764 11.3306 11.0247 11.6623 11.0247Z" fill={!color ? '#a0a0a0' : color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6623 14.283H22.3258C22.6576 14.283 22.9182 14.0223 22.9182 13.6906C22.9182 13.3588 22.6576 13.0982 22.3258 13.0982H11.6623C11.3306 13.0982 11.0699 13.3588 11.0699 13.6906C11.0699 14.0223 11.3306 14.283 11.6623 14.283Z" fill={!color ? '#a0a0a0' : color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6623 17.5413H22.3258C22.6576 17.5413 22.9182 17.2806 22.9182 16.9489C22.9182 16.6171 22.6576 16.3565 22.3258 16.3565H11.6623C11.3306 16.3565 11.0699 16.6171 11.0699 16.9489C11.0699 17.2806 11.3306 17.5413 11.6623 17.5413Z" fill={!color ? '#a0a0a0' : color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.4159 14.2356C33.4159 13.501 33.0723 12.8138 32.4917 12.3636L28.404 9.20006V6.91333C28.404 5.61001 27.3377 4.54366 26.0344 4.54366H22.3969L18.4396 1.48679C17.5865 0.835132 16.4017 0.835132 15.5486 1.48679L11.5912 4.54366H7.95378C6.65046 4.54366 5.58411 5.61001 5.58411 6.91333V9.20006L1.49644 12.3636C0.915867 12.8138 0.572266 13.501 0.572266 14.2356V28.6313C0.572266 29.9347 1.63862 31.001 2.94193 31.001H31.058C32.3732 31.001 33.4277 29.9347 33.4277 28.6313V14.2356H33.4159ZM3.10781 14.8636L10.7263 20.764L3.10781 26.6645V14.8636ZM12.8116 22.3754L15.5367 24.4844C16.3898 25.1479 17.5865 25.1479 18.4396 24.4844L21.1647 22.3754L29.0083 28.4536H4.968L12.8235 22.3754H12.8116ZM23.2381 20.764L30.8566 14.8636V26.6764L23.2381 20.764ZM29.1268 12.9797L28.3922 13.5484V12.411L29.1268 12.9797ZM18.2263 4.53181H15.7618L16.9941 3.5721L18.2263 4.53181ZM8.13151 7.07921H25.8566V15.5152L16.9941 22.3754L8.13151 15.5152V7.07921ZM5.58411 13.5366L4.84952 12.9678L5.58411 12.3991V13.5366Z" fill={!color ? '#a0a0a0' : color}/>
      </svg>
      {badge ?
        <InboxBadge/>
        :
        null}
    </InboxIconWrapper>
  );
}

export default InboxIcon;
