enum Directions {
  Up = 'up',
  Down = 'down',
}

interface ThumbsProps {
  direction?: string;
  color?: string;
}

function Thumbs({ direction, color }: ThumbsProps) {
  const svgTransform = direction === Directions.Down ? "rotate(180) translate(-37, -40)" : "translate(0)";

  return(
    <svg width="37" height="45" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path transform={svgTransform} d="M27.8687 35H8.44375V12.6L20.6062 0L22.3125 1.35625C22.4875 1.50208 22.6187 1.70625 22.7062 1.96875C22.7937 2.23125 22.8375 2.55208 22.8375 2.93125V3.36875L20.8687 12.6H33.95C34.65 12.6 35.2625 12.8625 35.7875 13.3875C36.3125 13.9125 36.575 14.525 36.575 15.225V18.8125C36.575 19.0167 36.5969 19.2281 36.6406 19.4469C36.6844 19.6656 36.6625 19.8771 36.575 20.0813L31.0625 32.7687C30.8 33.3813 30.3698 33.9062 29.7719 34.3438C29.174 34.7812 28.5396 35 27.8687 35ZM11.0688 32.375H28.4375L33.95 19.2937V15.225H17.6313L19.95 4.33125L11.0688 13.6937V32.375ZM11.0688 13.6937V32.375V13.6937ZM8.44375 12.6V15.225H2.625V32.375H8.44375V35H0V12.6H8.44375Z" fill={color}/>
    </svg>
  );
}

export default Thumbs;
