import { FixedSizeList as List } from "react-window";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AmazonHelpers, UskoCategory } from "amazon";
import { Left, Right } from "components/App/Dashboard/panels/generics";
import { DisableContentsContainer, FlexRow } from "../Dashboard/panelsv2/generics";
import { dateOptions, isTypeTransactionDateOption, isTypeTransactionSort, sortOptions, TransactionSort } from "./filterOptions";

import AmazonContext from "components/Contexts/AmazonContext";

import SettingButton from "./SettingButton";
import CategoryModalDropdown from "../Dashboard/modals/CategoryModalDropdown";

import UpDownArrowIcon from 'icons/up-down-arrow.svg';
import CalendarIcon from 'icons/calendar.svg';

import styled from "styled-components";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const TransactionsWrapper = styled.div`

`;

const TransactionEmptyRow = styled.div`
  padding: 16px;
  font-weight: 500;
`;

const TransactionItem = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  padding-left: 16px;
  padding-right: 16px;

  border-bottom: 0.5px solid #4c4c4c4c;

  height: 75px;
`;

const TransactionItemImgWrapper = styled.div`
  width: 31px;
  height: 31px;
`;

const TransactionItemImg = styled.img`
  max-width: 31px;
  max-height: 31px;
  margin-right: 9px;
  border-radius: 100px;
  overflow: hidden;
`;

const TransactionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const TransactionTitle = styled(TransactionText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TransactionList = styled(List)`
  overscroll-behavior: none;
`;

function Transactions() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { amazonHandler, updating } = useContext(AmazonContext);
  const [category, setCategory] = useState<UskoCategory | "All Categories">("All Categories");
  const [dateRange, setDateRange] = useState(params.get(`dateRange`) || "Custom");
  const [startDate, setStartDate] = useState(new Date(params.get(`startDate`) || 0));
  const [endDate, setEndDate] = useState(new Date(params.get(`endDate`) || new Date()));
  const [sort, setSort] = useState<TransactionSort>("Newest");
  const listHeight = useMemo(() => {
    const uiHeight = updating ? 340 : 220;
    return window.innerHeight - uiHeight;
  }, [updating]);

  useEffect(() => {
    MobileEventHandler.analytics("transactions_wv")
  }, [])

  useEffect(() => {
    const p: { [id: string] : string | null} = {
      dateRange: params.get('dateRange'),
      startDate: params.get('startDate'),
      endDate: params.get('endDate'),
      category: params.get('category'),
      sort: params.get('sort')
    };

    Object.keys(p).forEach(key => {
      const v = p[key];
      if (v) p[key] = decodeURIComponent(v);
    });

    if (p.dateRange) setDateRange(p.dateRange);
    if (p.startDate) setStartDate(new Date(p.startDate));
    if (p.endDate) setEndDate(new Date(p.endDate));
    if (p.category) setCategory(p.category as UskoCategory);
    if (p.sort && isTypeTransactionSort(p.sort)) setSort(p.sort);
    if (!p.dateRange && !p.startDate && !p.endDate) setDateRange("All Time");
  }, [params]);

  useEffect(() => {
    if (dateRange && isTypeTransactionDateOption(dateRange)){
      const [start, end] = dateOptions[dateRange]();
      setStartDate(start);
      setEndDate(end);
    }
  }, [dateRange]);

  const customText = useMemo(() => {
    const start = startDate.toISOString().split('T')[0].replaceAll('-', '/');
    const end = endDate.toISOString().split('T')[0].replaceAll('-', '/');
    return `${start} - ${end}`;
  }, [endDate, startDate]);

  const dateFilteredHandler = useMemo(() => amazonHandler.sliceDateRange(startDate, endDate), [amazonHandler, endDate, startDate]);

  const sortedOrder = useMemo(() => {
    return dateFilteredHandler.sortOrders(sortOptions[sort].type, sortOptions[sort].order, sortOptions[sort].agg);
  }, [dateFilteredHandler, sort]);

  const orders = useMemo(() => {
    return category !== "All Categories" ?
      sortedOrder.filter((o) => o.category === category):
      sortedOrder;
  }, [category, sortedOrder]);

  // TODO: Find out what the right types for this callback is supposed to be
  //@ts-ignore
  const generateListItem = useCallback(({data, index, style}) => {
    const order = data[index];
    const imgsrc = AmazonHelpers.asin_to_image(order.asin || "", 250);
    return <TransactionItem
      style={style}
      key={"transaction_" + index}
      onClick={() => navigate(`/product/${order.asin}`)}
    >
      <Left style={{ display: "flex", alignItems: "center" }}>
        <TransactionItemImgWrapper>
          <TransactionItemImg src={imgsrc} alt="Product" />
        </TransactionItemImgWrapper>
        <div style={{marginLeft: 10, width: `calc(100vw - 200px)`}}>
          <TransactionTitle>{order.title}</TransactionTitle>
          <TransactionText>{sortOptions[sort].agg ? null : order.orderDate.toDateString()}</TransactionText>
        </div>
      </Left>
      <Right style={{textAlign: 'right'}}>
        <TransactionText>{AmazonHelpers.priceFormat(order.price)}</TransactionText>
        {order.quantity && order.quantity > 1 ? <TransactionText>({AmazonHelpers.priceFormat(order.price / order.quantity)} each)</TransactionText>: null}
        {order.quantity && order.quantity > 1 ? <TransactionText>Qty x{order.quantity}</TransactionText>: null}
      </Right>
    </TransactionItem>
  }, [navigate, sort]);

  return <TransactionsWrapper>
    <FlexRow style={{ padding: 16, alignItems: 'center' }}>
      <Left onClick={() => navigate(`/purchases/filter/category?${params.toString()}`)}>
        <DisableContentsContainer>
          <CategoryModalDropdown
            selected={category}
            setSelected={setCategory}
          />
        </DisableContentsContainer>
      </Left>
      <Right></Right>
    </FlexRow>
    <FlexRow style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Left style={{ display: 'flex'}}>
        <div
          style={{ marginRight: 8 }}
          onClick={() => navigate(`/purchases/filter/sort?${params.toString()}`)}
        >
          <SettingButton
            text={sort ? sort : 'Sort'}
            icon={UpDownArrowIcon}
            alt={"Sort Options"}
          />
        </div>
        <div
          onClick={() => navigate(`/purchases/filter/date-range?${params.toString()}`)}
        >
          <SettingButton
            text={dateRange === "Custom" ? customText : dateRange}
            icon={CalendarIcon}
            alt={"Date Range"}
          />
        </div>
      </Left>
    </FlexRow>
    {orders.length ?
      <TransactionList
        itemSize={75}
        height={listHeight}
        width={'100%'}
        itemData={orders}
        itemCount={orders.length}
      >
        {generateListItem}
      </TransactionList>
      :
      <TransactionEmptyRow>
        No Transactions
      </TransactionEmptyRow>
    }
  </TransactionsWrapper>
}

export default Transactions;
