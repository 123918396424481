export enum OS {
  ANDROID,
  IOS,
  WEB
}

interface ProcessedVersion {
  major: number
  minor: number
  patch: number
}

export class Platform {
  public static getOS = () => {
    const { UskoBridge, webkit } = window;
    if (UskoBridge) return OS.ANDROID;
    if (webkit) return OS.IOS;
    return OS.WEB;
  }

  public static hasShareTextCapability = (versions: {[key: string]: any}): boolean => {
    const os = Platform.getOS();
    const version = versions.appVersion as string;
    const processedVersion = Platform.processVersion(version);
    const numericVersion = Platform.numericVersion(processedVersion);

    if (os === OS.IOS) {
      // 1.11.1
      return numericVersion >= 111001;
    }
    if (os === OS.ANDROID) {
      // 0.2.10
      return numericVersion >= 2010;
    }
    return false;
  }

  public static hasMarketplace = (versions: {[key: string]: any}): boolean => {
    const os = Platform.getOS();
    const version = versions.appVersion as string;
    const processedVersion = Platform.processVersion(version);
    const numericVersion = Platform.numericVersion(processedVersion);

    if (os === OS.IOS) {
      // 1.15.0
      return numericVersion >= 115000;
    }
    if (os === OS.ANDROID) {
      // 0.6.0
      return numericVersion >= 6000;
    }
    return false;
  }

  private static processVersion = (version: string): ProcessedVersion => {
    const split = version.split(".");
    const len = split.length;
    let major = 0;
    let minor = 0;
    let patch = 0;
    if (len > 0) major = parseInt(split[0]);
    if (len > 1) minor = parseInt(split[1]);
    if (len > 2) patch = parseInt(split[2]);

    return { major, minor, patch };
  }

  private static numericVersion = (processedVersion: ProcessedVersion) => {
    return processedVersion.major * 100000 + processedVersion.minor * 1000 + processedVersion.patch;
  }
}
