import * as Types from "../types";
import { AmazonHandler, UskoCategory } from "../../amazon";
import currency from "currency.js";

const shortDatePrinter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
});

const numFormat = new Intl.NumberFormat();

const usdFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const nonDecimalUsdFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export class AmazonHelpers {
  static asin_to_image = (asin: string, size: number) => {
    return `https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL${size}`;
  };

  static asin_to_url = (asin: string) => {
    return `https://www.amazon.com/dp/${asin}/ref=sr_1_1`;
  };

  /*
  This gets passed a number and returns a NumberFormat object that is formated
  using locale conventions for decimal points and thousands-separators
  */
  static numberFormat = (number: number) => {
    return numFormat.format(number);
  };

  /*
  This gets passed a number and returns a NumberFormat object that is formated
  to display usd conventions for dollar sign, decimal points, and
  thousands-separators
  */
  static priceFormat = (price: number) => {
    return usdFormat.format(price);
  };

  /*
  Helper function gets passed a number and returns a NumberFormat object that
  is formatted to display usd convenctions for dollar sign, thousands-separators
  but no decimal points.
  */
  static noDecimalUsdFormat = (number: number) => {
    return nonDecimalUsdFormat.format(number);
  };

  static dayOfYear = (date: Date) =>
    //@ts-ignore
    Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);

  static SplitOrdersByDay(orders: Types.AmazonData[]) {
    const yearData: { [id: number]: { [id: number]: Types.AmazonData[] } } = {};

    orders.forEach((o) => {
      const year = o.orderDate.getFullYear();
      const day = AmazonHelpers.dayOfYear(o.orderDate);

      if (!yearData[year]) {
        yearData[year] = [];
      }

      if (!yearData[year][day]) {
        yearData[year][day] = [];
      }

      yearData[year][day].push(o);
    });

    return yearData;
  }

  static SplitOrdersByMonth(orders: Types.AmazonData[]) {
    const monthData: Types.AmazonData[][] = [];

    for (let i = 0; i < 12; i++) {
      monthData.push([]);
    }

    orders.forEach((o) => {
      const month = o.orderDate.getMonth();

      if (monthData[month]) {
        monthData[month].push(o);
      }
    });

    return monthData;
  }

  static SplitOrdersByYear(
    orders: Types.AmazonData[],
    years?: number[],
  ): { [id: number]: Types.AmazonData[] } {
    const yearData: { [id: number]: Types.AmazonData[] } = {};

    orders.forEach((o) => {
      const year = o.orderDate.getFullYear();
      if (years && !years.includes(year)) {
        return;
      }

      if (!yearData[year]) {
        yearData[year] = [];
      }

      if (yearData[year]) {
        yearData[year].push(o);
      }
    });

    return yearData;
  }

  static FormatStatsByYear(ah :AmazonHandler , years?: number[], category?: UskoCategory): Types.OrderStatsByYear[] {
    const stats: Types.OrderStatsByYear[] = [];
    const orders = category
      ? ah.getOrdersByCategory(category)
      : ah.getOrders();

    let yearsData: any = {};
    let yearsChosen: number[] = [];

    if (years) {
      yearsChosen = years;
    } else {
      for (const order of orders) {
        if (!order.orderDate) continue;
        const date = new Date(order.orderDate);
        const y = date.getFullYear();

        if (!yearsData[`${y}`]) {
          yearsData[`${y}`] = [];
        }
      }
    }

    for (const y of Object.keys(yearsChosen)) {
      yearsData[`${y}`] = [];
    }

    for (const order of orders) {
      if (!order.orderDate) continue;
      const year = new Date(order.orderDate).getFullYear();
      yearsData[`${year}`].push(order);
    }

    for (const year of Object.keys(yearsData)) {
      let quantity = 0;
      let totalSpending = 0;

      const y = yearsData[`${year}`];

      y.forEach((o: any) => {
        const q = o.quantity;
        quantity += q;
        totalSpending += o.price;
      });

      stats.push({
        year: Number(year),
        orders: quantity,
        totalSpending: totalSpending,
        averageCost: totalSpending / quantity,
      });
    }

    return stats;
  }

  static FormatStatsByMonth(orders: Types.AmazonData[]): Types.OrderStatsByMonth[] {
    const stats: Types.OrderStatsByMonth[] = [];
    const months: [Types.AmazonData[]] = [[]];

    for (let i = 1; i < 12; i++) {
      months.push([]);
    }

    for (const order of orders) {
      if (!order.orderDate) continue;
      const month = new Date(order.orderDate).getMonth();
      months[month].push(order);
    }

    for (let i = 0; i < 12; i++) {
      let quantity = 0;
      let totalSpending = 0;

      months[i].forEach((o) => {
        quantity += o.quantity;
        totalSpending += o.price;
      });

      stats.push({
        month: i + 1,
        orders: quantity,
        totalSpending: totalSpending,
      });
    }

    return stats;
  }

  static SortOrders(sort: Types.AmazonSort, sortOrder: Types.SortOrder) {
    return function innerSort(a: any, b: any) {
      if (!a.hasOwnProperty(sort) || !b.hasOwnProperty(sort)) {
        return 0;
      }

      const varA = typeof a[sort] === "string" ? a[sort].toUpperCase() : a[sort];

      const varB = typeof b[sort] === "string" ? b[sort].toUpperCase() : b[sort];

      let comparison = 0;

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return sortOrder === "DESC" ? comparison * -1 : comparison;
    };
  }

  static CalculateSpending(orders: Types.AmazonData[]): Types.OrderStats {
    let totalSpending = 0;
    let quantity = 0;

    if (!orders) return { orders:0, totalSpending };

    orders.forEach((o) => {
      const q = Number(o.quantity);
      if (!Number.isNaN(q) && Number.isSafeInteger(q))
        quantity += q;
      totalSpending += o.price || 0;
    });

    return { orders: quantity, totalSpending: totalSpending };
  }

  static GetPurchasesByMonthData(orders: Types.AmazonData[]): Types.PurchesesByMonthData {
    const sortedByDate = orders.sort(AmazonHelpers.SortOrders("orderDate", "DESC"));

    const years: Types.PurchesesByMonthData = {};

    for (const order of sortedByDate) {
      if (!order.orderDate) continue;
      const date = new Date(order.orderDate);
      const year = date.getFullYear();

      if (!years[year]) {
        years[year] = [];

        for (let i = 0; i < 12; i++) {
          years[year].push(0);
        }
      }

      const q = Number(order.quantity);
      years[year][date.getMonth()] += q;
    }

    return years;
  }

  static CsvDollarsToCurrency(n: string): [string, number] {
    return [n.substring(0, 1), currency(n).value];
  }

  static formatInflationDateRange(d: Types.ItemInflation): string {
    let startDate = d.minOrder.orderDate;
    let endDate = d.maxOrder.orderDate;
    // swap for deflation
    if (startDate.getTime() > endDate.getTime()) {
      let tmp = startDate;
      startDate = endDate;
      endDate = tmp;
    }
    return `${shortDatePrinter.format(startDate)} - ${shortDatePrinter.format(endDate)}`;
  }
}
