import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import { MarketplaceItem } from '../Marketplace/marketplaceTypes';

import CreditsNav from "components/App/Nav/CreditsNav";
import MarketplaceSearch from "components/App/Marketplace/MarketplaceSearch";
import ThinNav from "components/App/Nav/ThinNav";
import SearchBar from "components/App/Nav/SearchBar";
import QueryPreserveBar from "components/App/Nav/QueryPreserveBar";

type ViewsRouterProps = {
  earnedCredits?: string|undefined;
  hasWalletUpdates: boolean;
  marketplaceAllItems: MarketplaceItem[];
  versions: { [key: string]: any };
}

function ViewsRouter({ earnedCredits, hasWalletUpdates, marketplaceAllItems, versions }: ViewsRouterProps) {
  const navigate = useNavigate();
  return <Routes>
    <Route path="dashboard/purchases"
      element={<CreditsNav earnedCredits={earnedCredits} hasUpdates={hasWalletUpdates} searchType={'dashboard'} showCredits={true} title={`Purchases`} />}
    />
    <Route path="dashboard/spending"
      element={<CreditsNav earnedCredits={earnedCredits} hasUpdates={hasWalletUpdates} searchType={'dashboard'} showCredits={true} title={`Spending`} />}
    />
		<Route path="earn" element={<></>} />
    <Route path="faq" element={<CreditsNav earnedCredits={earnedCredits} hasUpdates={hasWalletUpdates} goBack={true} showCredits={true} title={`Credits FAQ`} />} />
    <Route path="product/:asin" element={<ThinNav />} />
    <Route path="purchases">
      <Route index element={<ThinNav title={`Purchases`} goBack={() => navigate("/dashboard/purchases")}/>}  />
      <Route path="filter/:transaction_type" element={<QueryPreserveBar title={"Filter"} url={`/purchases`} />} />
    </Route>
    <Route path="settings" element={<></>} />
    <Route path="search" element={<SearchBar versions={versions} />} />
    <Route path="share" element={<ThinNav title={`Share your analytics`} />} />
    <Route path="inflation" element={<ThinNav />} />
    <Route path="spending">
      <Route path="by-date/:by" element={<ThinNav title={`Spending by Date`} />} />
      <Route path="date-filter" element={<ThinNav title={`Date Filter Settings`} />} />
      <Route path="by-category/:by" element={<ThinNav title={`Spending by Category`} goBack={() => navigate("/dashboard/spending")} goToMenu={() => navigate(`/spending/category-filter`)} />} />
      <Route path="category-filter" element={<ThinNav title={`Date Comparison`}/>} />
      <Route path="by-trend" element={<ThinNav title={`Yearly Spending Trends`} />} />
    </Route>
    <Route path="marketplace">
      <Route index element={<CreditsNav earnedCredits={earnedCredits} hasUpdates={hasWalletUpdates} searchType={'marketplace'} showCredits={true} title={`Discover`} />} />
      <Route path="search">
        <Route index element={<MarketplaceSearch marketplaceAllItems={marketplaceAllItems} />} />
        <Route path="filter" element={<ThinNav title={`Filter`} goBack={() => navigate(-1)} />} />
      </Route>
    </Route>
    <Route path="redirect/marketplace/:asin" element={<CreditsNav earnedCredits={earnedCredits} hasUpdates={hasWalletUpdates} goBack={true} showCredits={true} title={''} />} />
    <Route path="messages/filter/:filterOption" element={<CreditsNav earnedCredits={earnedCredits} hasUpdates={hasWalletUpdates} showCredits={true} title={`Offers`} />} />
    <Route path="messages/message/:msgId" element={<></>} />
    <Route path="wallet" element={<CreditsNav goBack={true} title={'Wallet Balance'} />} />
    <Route path="*" element={null} />
  </Routes>
}

export default ViewsRouter;
