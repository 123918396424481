import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AmazonHelpers, UskoCategory } from "amazon";

import styled from "styled-components";
import { FlexRow, Left } from "../Dashboard/panels/generics";
import { MicroCopy, SmallHeading, SmallText } from "../styles/generics";

import coin from "icons/coin.svg";
import BackArrowIcon from "icons/back-arrow-icon.svg";
import downChevron from "icons/down-chevron-icon.svg";
import greenDollarSign from "icons/green-dollar.svg";
import star from "icons/star.svg";
import SearchIcon from "icons/search-icon.svg";
import uskissaWand from "images/uskissa-wand.svg";
import XIcon from "icons/x-icon.svg";

import { MarketplaceItem } from "./marketplaceTypes";
import { FuseMarketSearch } from "./fuseMarketplaceSearch"; 

const SearchBarWrapper = styled.div<any>`
  ${(props) =>
    props.focused
      ? `
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #F6F5FF;
    width: 100%;
    height: 100%;
    max-height: 100%;

    // react boostrap buttons seem to hold some value of z-index greater than 1;
    z-index: 9999;
  `
      : ""}
`;

const SearchBarRow = styled.div`
  position: relative;

  padding: 8px 16px;
  background-color: #f6f5ff;

  top: 0;
`;

const MarketplaceCategoryFilterBar = styled(SmallHeading)`
  width: 100%;
  max-height: 4.4375rem;
  display: flex;
  align-items: center;
  padding: 1.4375rem 1rem 1.3125rem;
  text-transform: capitalize;
`;
const CategoryText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const DownChevronImg = styled.img`
  margin-left: 0.875rem;
`;

const SearchBarInputBox = styled.div`
  padding: 0.625rem;

  margin-left: 2rem;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;

  background: #ffffff;
  border-radius: 0.9375rem;

  border: none;
`;

const SearchBarInput = styled.input`
  all: unset;

  width: calc(100vw - 115px);
`;

const SearchResultScreen = styled.ul<any>`
  list-style: none;

  padding-left: 0px;

  background-color: #f6f5ff;
`;

const SearchResultsContainer = styled.div`
  max-height: calc(100vh - 167px);
  overflow-y: scroll;
`;

const SearchResultItemWrapper = styled.li`
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0.625rem 1rem;
  border-bottom: 1px solid #E8E8E8;

  :hover {
    background-color: lightgrey;
    transition: 1s;
  }
`;
const SearchResultItemInnerWrapper = styled.div`
  width: calc(100vw - 2rem);
  height: 4.625rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 1.125rem;
`;
const SearchResultImg = styled.img`
  width: 62px;
  height: 62px;

  border-radius: 15px;

  margin-right: 14px;
`;
const SearchResultDetailsWrapper = styled.div`
  height: 100%;
  width: 18rem;
`;
const SeachResultsPriceRatingWrapper = styled.div`
  height: 0.625rem;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 0.625rem;
  letter-spacing: -0.03125rem;
  margin-bottom: 0.25rem;
`;
const SearchResultsPriceWrapper = styled.div`
`;
const SearchResultsRatingWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchResultsRatingImg = styled.img`
  width: 0.625rem;
  height: 0.625rem;
`;
const SearchResultsDescription = styled(MicroCopy)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1rem;
  letter-spacing: -0.03125rem;
  margin-bottom: 0.5rem;
`;
const SearchResultsEarnCashBackWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const SearchResultsEarnWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6.25rem;
  background-color: rgba(222, 137, 1, 0.10);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.125rem 0.3125rem;
`;
const SearchResultsCoinImg = styled.img`
  margin-right: 0.25rem;
`;
const SearchResutlsCashBackWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6.25rem;
  background-color: rgba(109, 196, 183, 0.10);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.125rem 0.3125rem;
`;
const SearchResultsDollarImg = styled.img`
  margin-right: 0.25rem;
`;

const SearchSuggestionsItemWrapper = styled.li`
  padding: 8px 16px 8px 16px;

  :hover {
    background-color: lightgrey;
    transition: 1s;
  }
`;

const SearchResultTitle = styled.div`
  padding: 1.25rem 1rem 0 1rem;

  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.36125rem;
`;


const SearchResultText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;

const BackIcon = styled.img`
  position: absolute;

  left: 15px;
`;
const SearchPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;

  background: linear-gradient(180deg, #9d7de9 0%, #6890fa 100%);
`;
const SearchPlaceholderImg = styled.img`
  width: 67.79%;
`;
const SearchPlaceholderText = styled(SmallText)`
  color: #ffffff;
  padding-bottom: 1rem;
  text-align: center;
`;

const NONE = "none";
const SEARCH_HISTORY = "history";
const SEARCH_SUGGESTIONS = "suggestions";
const SEARCH_RESULTS = "results";

// const RESULTS_PER_PAGE = 10;
const SEARCH_HISTORY_LIMIT = 10;

const searchPlaceholder = (
  <SearchPlaceholderWrapper>
    <SearchPlaceholderImg src={uskissaWand} />
    <SearchPlaceholderText>
      Didn't see what you are looking for? Let's see what amazing cash back deals we can find for you!<br />
      To make your search most effective, don’t search for brands, use keywords instead. For example, instead of tide, search for laundry detergent.
    </SearchPlaceholderText>
  </SearchPlaceholderWrapper>
);

const searchTitle: {
  [id: string]: string;
} = {
  history: "Recent",
  suggestions: "Items",
  results: "Results",
};

type ResultType = {
  asin: string,
  coinValue: string,
  company: string,
  img: string,
  price: string,
  productName: string,
  rating: string,
};

type MarketplaceSearchProps = {
  marketplaceAllItems: MarketplaceItem[];
}

function MarketplaceSearch({ marketplaceAllItems }: MarketplaceSearchProps) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const filterToTitle = useMemo(() => {
    const temp = new Map();
    const titles = Object.values(UskoCategory);
    Object.keys(UskoCategory).forEach((k, i) => temp.set(k, titles[i]));
    return temp;
  }, []);
  const searchFilter = params.get('filter');
  const filteredItems = useMemo(() => {
    if(filterToTitle.get(searchFilter)) return [...marketplaceAllItems].filter(i => i.productCategory === filterToTitle.get(searchFilter));
    return marketplaceAllItems;
  }, [filterToTitle, marketplaceAllItems, searchFilter]);
  const [pastMarketSearches, setPastMarketSearches] = useState<string[]>([]);
  const [results, setResults] = useState<ResultType[]>([]);
  const [searching, setSearching] = useState(false);
  const SearchBarRef = useRef<any>(null);
  const initialTerm = new URLSearchParams(window.location.search).get('q') || '';
  const initialState = initialTerm === '' ? NONE : SEARCH_RESULTS;
  const [searchTerm, setSearchTerm] = useState(initialTerm);
  const [searchState, setSearchState] = useState(initialState);

  useEffect(() => {
    const storedSearches =
      localStorage
        .getItem("pastMarketSearches")
        ?.split("\n")
        .filter((e) => e.length) || [];
    setPastMarketSearches(storedSearches);
    setTimeout(() => {
      SearchBarRef.current?.blur();
      SearchBarRef.current?.focus();
    }, 100);
  }, []);

  useEffect(() => {
    const inputTerm = params.get('input');
    if(SearchBarRef && inputTerm) {
      setSearchTerm(inputTerm);
      setSearchState(SEARCH_SUGGESTIONS);
    }
  }, [params, SearchBarRef])

  // Prevent scrolling when input is active.
  useEffect(() => {
    if (searchState !== NONE) document.body.classList.add("no-scroll");
    else document.body.classList.remove("no-scroll");
  }, [searchState]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    if (searchTerm.length <= 0) {
      return;
    }

    setResults(FuseMarketSearch.search(searchTerm, filteredItems, 20).map((f: any) => {
      return {
        asin: f.item.asin,
        coinValue: f.item.uskoCoinValue,
        company: f.item.companyName,
        img: f.item.imageEncodedString,
        price: f.item.price,
        productName: f.item.productName,
        rating: f.item.averageRating,
      };
    }));
  }, [filteredItems, searchTerm, marketplaceAllItems]);

  const handleOnFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!searchTerm.length) setSearchState(SEARCH_HISTORY);
    },
    [searchTerm.length],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length) setSearchState(SEARCH_SUGGESTIONS);
      else setSearchState(SEARCH_HISTORY);
      setSearchTerm(event.target.value);
    },
    [],
  );

  const getNewSearchHistoryOrder = useCallback(
    (prevPastSearches: string[]) => {
      const copyOfSearches = prevPastSearches.map((e) => e);
      const index = copyOfSearches.indexOf(searchTerm);

      // If searcTerm is at the front. Do nothing.
      if (index !== 0) {
        // If value is not in the list, no need to shift it.
        if (index !== -1) {
          copyOfSearches.splice(index, 1);
        }

        copyOfSearches.unshift(searchTerm);
      }

      if (copyOfSearches.length > SEARCH_HISTORY_LIMIT) {
        copyOfSearches.pop();
      }

      return copyOfSearches;
    },
    [searchTerm],
  );

  const handleSaveSearchHistory = useCallback(() => {
    setPastMarketSearches((prevPastSearches) => {
      const copyOfSearches = getNewSearchHistoryOrder(prevPastSearches);
      localStorage.setItem("pastMarketSearches", copyOfSearches.join("\n"));
      return copyOfSearches;
    });
  }, [getNewSearchHistoryOrder]);

  const handleSearch = useCallback(
    async (term: string) => {
      handleSaveSearchHistory();
      setSearchState(SEARCH_RESULTS);
      setSearching(true);

      await new Promise((r) => setTimeout(r, 50));

      const searchResults = FuseMarketSearch.search(term, marketplaceAllItems, 20).map((f: any) => {
        return {
          asin: f.item.asin,
          coinValue: f.item.uskoCoinValue,
          company: f.item.companyName,
          img: f.item.imageEncodedString,
          price: f.item.price,
          productName: f.item.productName,
          rating: f.item.averageRating,
        };
      });

      setSearching(false);
      setResults(searchResults);
    },
    [marketplaceAllItems, handleSaveSearchHistory],
  );

  const handleClickProduct = useCallback(
    (id: string) => {
      const copyOfSearches = getNewSearchHistoryOrder(pastMarketSearches);
      localStorage.setItem("pastMarketSearches", copyOfSearches.join("\n"));
      const url = new URL(window.location.href);
      url.searchParams.set('q', searchTerm);
      window.history.replaceState({}, '', url);
      navigate(`/redirect/marketplace/${id}`);
    },
    [getNewSearchHistoryOrder, navigate, pastMarketSearches, searchTerm],
  );

  // TODO just remove specific handling for the 'enter' key ??
  const handleEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // If not enter, then ignore.
      if (event.key === "Enter" && searchTerm.length) {
        handleSaveSearchHistory();
      }
    },
    [handleSaveSearchHistory, searchTerm],
  );

  const handleClickSearchHistory = useCallback(
    (str: string) => {
      setSearchTerm(str);
      handleSearch(str);
    },
    [handleSearch],
  );

  const generateRemoveHistoryFunction = useCallback((i: number) => {
    return () => {
      setPastMarketSearches((prevPastSearches) => {
        const copyOfSearches = prevPastSearches.map((e) => e);
        copyOfSearches.splice(i, 1);
        localStorage.setItem("pastMarketSearches", copyOfSearches.join("\n"));
        return copyOfSearches;
      });
    };
  }, []);

  const generateSearchHistory = useCallback(
    (pastMarketSearches: string[]) => {
      return pastMarketSearches.map((search, i) => {
        return (
          <li
            key={`history` + i}
            style={{ display: "flex", marginTop: 16, padding: "0 16px 0 16px" }}
          >
            <img
              style={{ paddingRight: 27 }}
              src={XIcon}
              alt="x icon"
              onClick={generateRemoveHistoryFunction(i)}
            />
            <SearchResultText
              style={{ width: "100%" }}
              onClick={() => handleClickSearchHistory(search)}
            >
              {search}
            </SearchResultText>
          </li>
        );
      });
    },
    [generateRemoveHistoryFunction, handleClickSearchHistory],
  );

  const generateResults = useCallback(
    (results: ResultType[]) => {
      return results.map((result, i) => {
        return (
          <SearchResultItemWrapper
            onClick={() => handleClickProduct(result.asin)}
            key={"result" + i}
          >
            <SearchResultItemInnerWrapper>
              <SearchResultImg
                src={result.img}
                alt="Product"
              />
              <SearchResultDetailsWrapper>
                <SeachResultsPriceRatingWrapper>
                  <SearchResultsPriceWrapper>
                    {AmazonHelpers.priceFormat(parseFloat(result.price.replace(/[^\d.-]+/g, '')))}
                  </SearchResultsPriceWrapper>
                  <SearchResultsRatingWrapper>
                    <SearchResultsRatingImg src={star} />
                    {result.rating}
                  </SearchResultsRatingWrapper>
                </SeachResultsPriceRatingWrapper>
                <SearchResultsDescription>
                  {result.productName}
                </SearchResultsDescription>
                <SearchResultsEarnCashBackWrapper>
                  <SearchResultsEarnWrapper>
                    <SearchResultsCoinImg src={coin} />
                    Earn {result.coinValue} USK
                  </SearchResultsEarnWrapper>
                  <SearchResutlsCashBackWrapper>
                    <SearchResultsDollarImg src={greenDollarSign} />
                    {AmazonHelpers.priceFormat(parseInt(result.coinValue) * 0.025)} back
                  </SearchResutlsCashBackWrapper>
                </SearchResultsEarnCashBackWrapper>
              </SearchResultDetailsWrapper>
            </SearchResultItemInnerWrapper>
          </SearchResultItemWrapper>
        );
      });
    },
    [handleClickProduct],
  );

  const generateSearchResults = useCallback(() => {
    let resultsBlock: JSX.Element[] = [];

    if (searchState === SEARCH_HISTORY) {
      resultsBlock = generateSearchHistory(pastMarketSearches);
    }

    if (searchState === SEARCH_HISTORY && !pastMarketSearches.length) return searchPlaceholder;

    if (searchState === SEARCH_SUGGESTIONS) {
      resultsBlock = generateResults(results);
    }

    if (searchState === SEARCH_RESULTS) {
      resultsBlock = generateResults(results);
    }

    if (!resultsBlock.length && !searching) {
      resultsBlock = [
        <SearchSuggestionsItemWrapper key={"no_match_row"}>
          <SearchResultText>No Matches</SearchResultText>
        </SearchSuggestionsItemWrapper>,
      ];
    }

    const searchText = searching ? "Waiting for" : results.length;
    return (
      <SearchResultScreen>
        <SearchResultTitle>
          {(searchState === SEARCH_RESULTS ? searchText + " " : "") + searchTitle[searchState]}
        </SearchResultTitle>
        <SearchResultsContainer>
          {searchState === SEARCH_RESULTS ? <div style={{ height: 14 }}></div> : null}
          {resultsBlock}
        </SearchResultsContainer>
      </SearchResultScreen>
    );
  }, [generateResults, generateSearchHistory, pastMarketSearches, results, searchState, searching]);

  return (
    <SearchBarWrapper focused={searchState !== NONE}>
      <SearchBarRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Left style={{ alignItems: "center", display: "flex", whiteSpace: "nowrap" }}>
            <BackIcon
              onClick={() => navigate('/marketplace')}
              src={BackArrowIcon}
              alt="Back to the Marketplace Icon"
            />
            <SearchBarInputBox>
              <img style={{ paddingRight: 15 }} src={SearchIcon} alt="magnifying glass icon" />
              <SearchBarInput
                id="market-searchbar"
                type="text"
                placeholder="Search marketplace"
                value={searchTerm}
                onChange={handleOnChange}
                onKeyDown={handleEnter}
                onFocus={handleOnFocus}
                autoComplete="off"
                ref={SearchBarRef}
              />
            </SearchBarInputBox>
          </Left>
        </FlexRow>
      </SearchBarRow>
      <MarketplaceCategoryFilterBar
        onClick={() => {
          navigate(`/marketplace/search/filter?selected=${searchFilter ? searchFilter : 'All'}&input=${searchTerm}`)
        }}
      >
        <CategoryText>
          {searchFilter && filterToTitle.get(searchFilter) ? filterToTitle.get(searchFilter) : 'All Categories'}
        </CategoryText>
        <DownChevronImg src={downChevron} />
      </MarketplaceCategoryFilterBar>
      {searchState !== NONE ? generateSearchResults() : null}
    </SearchBarWrapper>
  );
}

export default MarketplaceSearch;
