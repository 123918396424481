import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UskoCategory } from "amazon";
import { category_titles } from "../Dashboard/categories/categories";

import styled from "styled-components";
import { FieldInput } from "../styles/generics";
import checkmark from "icons/checkmark-outlined.svg";

const MarketplaceSearchFilterWrapper = styled(FieldInput)`
  background-color: #F6F5FF;
  width: 100%;
  height: 100%;
  max-height: 100%;
`;
const FilterItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.1875rem 1rem;
  border-bottom: 1px solid #E4E4E4;
`;
const CheckMarkImg = styled.img`
`;

function MarketplaceSearchFilter() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const selected = params.get('selected');
  const inputTerm = params.get('input');
  const filterToTitle = useMemo(() => {
    const temp = new Map();
    temp.set('All', '⭐ All Categories');
    const titles = Object.values(category_titles);
    Object.keys(UskoCategory).forEach((k, i) => temp.set(k, titles[i]));
    temp.delete('Processing');
    return temp;
  }, []);

  return(
    <MarketplaceSearchFilterWrapper>
      {Array.from(filterToTitle.keys()).map((key, i) => (
        <FilterItem
          key={`market_search_filter_item_${i}`}
          onClick={() => {
            navigate(`/marketplace/search?filter=${key}&input=${inputTerm}`)
          }}
        >
          {filterToTitle.get(key)}
          {(selected === key) && <CheckMarkImg src={checkmark} />}
        </FilterItem>
      ))}
    </MarketplaceSearchFilterWrapper>
  );
}

export default MarketplaceSearchFilter;
