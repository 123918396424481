import { UskoCategory } from "../../../../../amazon";
import { category_titles } from "components/App/Dashboard/categories/categories";
import { useMemo } from "react";
import styled from "styled-components";
import { SmallText } from "../../../styles/generics";

const CategoryComparisonWrapper = styled.div`
  padding: 16px;
`;

const FlexRow = styled.div`
  display: flex;
`;

const Left = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  padding-right: 1rem;

  width: 45%;
`;

const Middle = styled.div`
  width: 35%;
`;

const Right = styled.div`
  display: flex;
  width: 20%;

  justify-content: right;
`;

const Header = styled(SmallText)`
  font-weight: 700;
`;

const ItemRow = styled(SmallText)`
  display: flex;

  padding-top: 16px;
`;

const TextLeft = styled.span`
  color: rgba(76, 76, 76, 0.5);
`;

const ChangeContainer = styled.div<any>`
  background-color: ${props => props.color};
  border-radius: 50px;

  padding: 5px;
`;

type CategoryComparisonChartProps = {
  labels: UskoCategory[],
  first: number[],
  second: number[],
  firstDate: string,
  secondDate: string
}

const colors = {
  infinite: 'yellow',
  increase: '#FFB6B6',
  decrease: '#D1F274',
  same: '#C4ABFF'
}

function CategoryComparisonChart({labels, first, second, firstDate, secondDate}: CategoryComparisonChartProps) {
  const rows = useMemo(() => {
    return labels.map((l, i) => {
      const percentage = (second[i] - first[i]) * 100 / first[i];
      const change = isNaN(percentage) || percentage === 0 ?
        "Same"
        :
        isFinite(percentage) ?
        Math.round(percentage) + '%'
        :
        percentage.toString();
      const color = isNaN(percentage) || percentage === 0 ?
        colors.same
        :
        !isFinite(percentage) ?
        colors.infinite
        :
        percentage > 0 ? 
        colors.increase
        :
        colors.decrease;
      return <ItemRow key={`cat_comp_row_${i}`}>
        <Left>{category_titles[l]}</Left>
        <Middle><TextLeft>${Math.round(first[i])}-</TextLeft>${Math.round(second[i])}</Middle>
        <Right><ChangeContainer color={color}>{change}</ChangeContainer></Right>
      </ItemRow>
    })
  }, [first, labels, second]);
  
  return <CategoryComparisonWrapper>
    <FlexRow>
      <Left><Header>Categories</Header></Left>
      <Middle><Header><TextLeft>{firstDate}</TextLeft>-{secondDate}</Header></Middle>
      <Right><Header>Change</Header></Right>
    </FlexRow>
    {rows}
  </CategoryComparisonWrapper>
}

export default CategoryComparisonChart;
