import {ReactElement, useContext, useEffect, useMemo} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FixedSizeList as List } from "react-window";

import AmazonContext from "components/Contexts/AmazonContext";
import { Message, MessageStates, postOpened } from "messaging";

import styled from "styled-components";
import { MicroCopy, SmallText, SubHeading, TabLinkText } from "../styles/generics";
import uskoLogo from "../../../images/USKO-avatar.jpg";
import InboxFilter from "./InboxFilter";

import { dayBefore } from "../utils/generics";
import { getBrandInitials } from "./inboxUtils";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const MessageItem = styled(SmallText)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 1rem;

  border-bottom: 0.5px solid #4c4c4c4c;

  height: 75px;
  gap: 1.125rem;
`;
const MessageItemAvatar = styled.img`
  height: 3.125rem;
  width: 3.125rem;
  object-fit: cover;
  object-position: center;
  border-radius: 6.25rem;
`;
const MessageItemAvatarDefault = styled(SubHeading)`
  height: 3.125rem;
  width: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6B6BD4;
  color: #FFFFFF;
  border-radius: 6.25rem;
  text-align: center;
`;
const MessageItemDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: calc(100vw - 6.25rem);
  padding: 1rem 0;
`;
const MessageItemDetailsTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const MessageItemDetailsBottom = styled.div<any>`
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${props => props.state >= MessageStates.Opened ? "normal" : "bold" };
`;
const MessageItemDateText = styled(SmallText)`
  width: 4.6875rem;
  text-align: right;
  text-transform: capitalize;
`;
const MessageItemStatus = styled.div`
  display: flex;
  background-color: #FFFFFF;
  align-items: center;
  border-radius: 1.9375rem;
  padding: 0.125rem 0.5rem;
  gap: 0.375rem;
`;
const StatusIndicator = styled.div`
  display: inline-block;
  border-radius: 100%;
  width: 0.375rem;
  height: 0.375rem;
  vertical-align: middle;
`;
const AvailableStatusIndicator = styled(StatusIndicator)`
  border: 0.1rem solid ${props => props.color};
  border-right: 0.245rem solid ${props => props.color};
`;
const MissedStatusIndicator = styled(StatusIndicator)`
  border: 0.1rem solid ${props => props.color};
`;
const EarnedStatusIndicator = styled(StatusIndicator)`
  background-color: ${props => props.color};
`;
const MessageItemStatusText = styled(MicroCopy)`
  text-transform: capitalize;
`;
const MessageItemBrandIndicatorGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 18.1875rem;
`;
const MessageItemBrandText = styled(TabLinkText)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const today = new Date().toDateString();
const yesterday = dayBefore(new Date()).toDateString();

function isMissedMessage(m: Message): boolean {
  return m.state < MessageStates.Read && !m.active;
}

function isActiveMessage(m: Message): boolean {
  return (m.state === MessageStates.Matched || m.state === MessageStates.Opened) && m.active;
}

function isEarnedMessage(m: Message): boolean {
  return m.state === MessageStates.Read;
}

const availableColor = "#6DC4B7";
const earnedColor = "#6B6BD4";
const missedColor = "#EEA924";

function getStatus(msg: Message): { status: string, messageStatusIndicator: ReactElement<any, any> } {
  if (isMissedMessage(msg)) return {
    status: "missed",
    messageStatusIndicator: <MissedStatusIndicator color={missedColor}></MissedStatusIndicator>
  };
  if (isEarnedMessage(msg)) return {
    status: "earned",
    messageStatusIndicator: <EarnedStatusIndicator color={earnedColor}></EarnedStatusIndicator>
  };
  return {
    status: "available",
    messageStatusIndicator: <AvailableStatusIndicator color={availableColor}></AvailableStatusIndicator>
  };
}

interface InboxProps {
  earnedCredits?: string
}

function Inbox(props: InboxProps){
  const navigate = useNavigate();
  const { earnedCredits } = props;
  const { filterOption } = useParams();
  const { messages, setMessages, localClaimedTotal } = useContext(AmazonContext);
  const filteredMessages = useMemo(() => {
    const descSortedMessages = [...messages].sort((msgA, msgB) => msgB.createdAt.getTime() - msgA.createdAt.getTime());
    
    // pin the intro usko message to the top
    const indexToPin = descSortedMessages.findIndex(msg => (msg.id === 'campaign_sS5zLX9o17c2WGsm8uGX8r' || msg.id === 'campaign_7VdQovtq3At2ZkUDRgmNj3'));
    const msgToPin = indexToPin !== -1 ? descSortedMessages.splice(indexToPin, 1) : undefined;
    if(msgToPin) descSortedMessages.unshift(msgToPin[0]);
    
    if (filterOption && ['available', 'earned', 'missed'].includes(filterOption)){
      return descSortedMessages.filter(m => getStatus(m).status === filterOption);
    }
    return [...descSortedMessages];
  }, [messages, filterOption]);

  useEffect(() => {
    MobileEventHandler.analytics("inbox_wv")
  }, [])

  const earnedFromMessages = messages.reduce((acc, m) => {
    return isEarnedMessage(m) ? m.credits + acc : acc;
  }, 0);
  const availableTotal = messages.reduce((acc, m) => {
    return isActiveMessage(m) ? m.credits + acc : acc;
  }, 0);
  const missedTotal = messages.reduce((acc, m) => {
    return isMissedMessage(m) ? m.credits + acc : acc;
  }, 0);

  const generateMessageItem = ({data, index, style}: {data: Message[], index: number, style: any}) => {
    const msg = data[index];
    const icons3x : { [key:string]:string } = {
      "Usko Privacy": uskoLogo,
      "Red Racer Books": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/6d4353a2-81aa-40ab-fed5-b23d7c299500/icon3x",
      "Victoria": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f466e5d7-80ef-4c47-0282-24e8c3ec8000/icon3x",
      "Homely": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/51668e92-377c-4bbb-9f2d-769bcabd4d00/icon3x",
      "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/587cc729-813a-42b0-69a3-8f9b35619800/icon3x",
      "CanDo Krisp":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/0f85696f-d799-4359-1ca7-cde587e71200/icon3x",
      "GC Life":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/8906ddd1-35ca-4cb7-252c-bbe41a049500/icon3x",
      "Joi":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/511cbb37-f159-4120-1f83-966944de2f00/icon3x",
      "Gleamin":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a8302985-615e-4309-71d9-4d8f5b217e00/icon3x",
      "NeuroGum":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/662cda50-ed03-4a43-4ccd-79ecd31d8c00/icon3x",
      "Cooler Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/d174581c-3ddd-4e70-0f65-866c7f737800/icon3x",
      "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/324a2439-7cb7-4f49-a035-55c501185c00/icon3x",
      "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/e32d627e-aba4-4420-15cb-54b79405a300/icon3x",
      "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a1b16600-05a7-44e8-93bf-da8690e98d00/icon3x",
      "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/cdac5114-4a88-4595-1897-3ac9b9368700/icon3x",
      "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/4864ddfc-3193-4c82-f39e-d07a402d7e00/icon3x"
    };
    const icons2x : { [key:string]:string } = {
      "Usko Privacy": uskoLogo,
      "Red Racer Books": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/6d4353a2-81aa-40ab-fed5-b23d7c299500/icon2x",
      "Victoria": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f466e5d7-80ef-4c47-0282-24e8c3ec8000/icon2x",
      "Homely": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/51668e92-377c-4bbb-9f2d-769bcabd4d00/icon2x",
      "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/587cc729-813a-42b0-69a3-8f9b35619800/icon2x",
      "CanDo Krisp":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/0f85696f-d799-4359-1ca7-cde587e71200/icon2x",
      "GC Life":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/8906ddd1-35ca-4cb7-252c-bbe41a049500/icon2x",
      "Joi":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/511cbb37-f159-4120-1f83-966944de2f00/icon2x",
      "Gleamin":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a8302985-615e-4309-71d9-4d8f5b217e00/icon2x",
      "NeuroGum":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/662cda50-ed03-4a43-4ccd-79ecd31d8c00/icon2x",
      "Cooler Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/d174581c-3ddd-4e70-0f65-866c7f737800/icon2x",
      "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/324a2439-7cb7-4f49-a035-55c501185c00/icon2x",
      "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/e32d627e-aba4-4420-15cb-54b79405a300/icon2x",
      "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a1b16600-05a7-44e8-93bf-da8690e98d00/icon2x",
      "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/cdac5114-4a88-4595-1897-3ac9b9368700/icon2x",
      "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/4864ddfc-3193-4c82-f39e-d07a402d7e00/icon2x",
    };
    const icons1x : { [key:string]:string } = {
      "Usko Privacy": uskoLogo,
      "Red Racer Books": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/6d4353a2-81aa-40ab-fed5-b23d7c299500/icon1x",
      "Victoria": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/f466e5d7-80ef-4c47-0282-24e8c3ec8000/icon1x",
      "Homely": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/51668e92-377c-4bbb-9f2d-769bcabd4d00/icon1x",
      "TheraICE": "https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/587cc729-813a-42b0-69a3-8f9b35619800/icon1x",
      "CanDo Krisp":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/0f85696f-d799-4359-1ca7-cde587e71200/icon1x",
      "GC Life":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/8906ddd1-35ca-4cb7-252c-bbe41a049500/icon1x",
      "Joi":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/511cbb37-f159-4120-1f83-966944de2f00/icon1x",
      "Gleamin":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a8302985-615e-4309-71d9-4d8f5b217e00/icon1x",
      "NeuroGum":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/662cda50-ed03-4a43-4ccd-79ecd31d8c00/icon1x",
      "Cooler Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/d174581c-3ddd-4e70-0f65-866c7f737800/icon1x",
      "Nature's Craft":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/324a2439-7cb7-4f49-a035-55c501185c00/icon1x",
      "Bebe":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/e32d627e-aba4-4420-15cb-54b79405a300/icon1x",
      "Elizabeth Mott":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/a1b16600-05a7-44e8-93bf-da8690e98d00/icon1x",
      "Zulay Kitchen":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/cdac5114-4a88-4595-1897-3ac9b9368700/icon1x",
      "Aloderma":"https://imagedelivery.net/2QJRULTUGa5vNOJGJk5anw/4864ddfc-3193-4c82-f39e-d07a402d7e00/icon1x",
    };

    const [icon1x, icon2x, icon3x] = [
      msg.icons?.one ?? icons1x[msg.query.brand],
      msg.icons?.two ?? icons2x[msg.query.brand],
      msg.icons?.three ?? icons3x[msg.query.brand]
    ];
    const msgDate = (d: Date) : string => {
      const dateOnly = d.toDateString();
      if(dateOnly === today) return "today";
      if(dateOnly === yesterday) return "yesterday";
      return dateOnly.split(" ").slice(1,3).join(" ");
    };
    function escapeLinebreaks(m:string){
      // TODO temporary! until users have migrated to a non-broken app build
      // Once this is safe to remove we need to update any active campaigns
      // might still be using this scheme
      return m.replaceAll("__USKOLINEBREAK__", " ");
    }
    const msgDetailsPreview: () => string = () => {
      if(msg && msg.res && msg.res.kind === "msg" ){
        const m = msg.res.message;
        if (typeof m === "string") return escapeLinebreaks(m);
        return m.map((e) => {
          if (typeof e === "string") return escapeLinebreaks(e);
          if (e.kind === "msgLink") return e.text;
          return "unhandled type " + e.kind; // should be caught by typechecker
        }).join("");
      }
      return "";
    };
    const { status, messageStatusIndicator } = getStatus(msg);

    return <MessageItem
      style={style}
      key={"messageItem_" + index}
      onClick={() => {
        if (msg.state === MessageStates.Matched) {
          msg.state = MessageStates.Opened;
          data[index] = msg;
          setMessages(messages);
          if (msg.active) postOpened(msg); // TODO update native state but don't notify
        }
        navigate(`/messages/message/${msg.id}`);
      }}
    >
      {icon1x ?
        <MessageItemAvatar src={icon1x} srcSet={`${icon1x} 1x, ${icon2x} 2x, ${icon3x} 3x`} />
        :
        <MessageItemAvatarDefault>
          {icon1x ? null : getBrandInitials(msg.query.brand)}
        </MessageItemAvatarDefault>}
      <MessageItemDetails>
        <MessageItemDetailsTop>
          <MessageItemBrandIndicatorGroup>
            <MessageItemBrandText>
              {msg.query.brand}
            </MessageItemBrandText>
            <MessageItemStatus>
              {messageStatusIndicator}
              <MessageItemStatusText>
                {status} - {msg.credits}
              </MessageItemStatusText>
            </MessageItemStatus>
          </MessageItemBrandIndicatorGroup>
          <MessageItemDateText>
            {msgDate(msg.createdAt)}
          </MessageItemDateText>
        </MessageItemDetailsTop>
        <MessageItemDetailsBottom state={msg.state}>
          {msgDetailsPreview()}
        </MessageItemDetailsBottom>
      </MessageItemDetails>
    </MessageItem>
  };

  const backendBalance = earnedCredits ? parseInt(earnedCredits, 10) + localClaimedTotal : undefined

  const inboxFilterParams = {
    earnedTotal: backendBalance ?? earnedFromMessages,
    missedTotal, availableTotal,
    availableColor, earnedColor, missedColor,
    selected: filterOption
  };

  return(
    <>
      <InboxFilter {...inboxFilterParams} />
      <List
        itemSize={82}
        height={window.innerHeight - 186.81}
        width={'100%'}
        itemData={filteredMessages}
        itemCount={filteredMessages.length}
      >
        {generateMessageItem}
      </List>
    </>
  );
}

export default Inbox;
