import styled from "styled-components";
import { IconBadge } from "../../components/App/styles/generics";

const ExploreIconWrapper = styled.div`
  position: relative;
`;
const ExploreBadge = styled(IconBadge)`
`;

function ExploreIcon({ color, badge }: { color?: string, badge?: boolean }) {
  return(
    <ExploreIconWrapper>
      <svg width="23" height="20" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector" d="M7.4376 25.1924L18.9168 19.532L24.5772 8.05286L13.098 13.7133L7.4376 25.1924ZM16.0272 18.1862C15.5786 18.1862 15.2025 18.0345 14.8991 17.731C14.5956 17.4275 14.4438 17.0515 14.4438 16.6029C14.4438 16.1543 14.5956 15.7782 14.8991 15.4747C15.2025 15.1713 15.5786 15.0195 16.0272 15.0195C16.4758 15.0195 16.8518 15.1713 17.1553 15.4747C17.4588 15.7782 17.6105 16.1543 17.6105 16.6029C17.6105 17.0515 17.4588 17.4275 17.1553 17.731C16.8518 18.0345 16.4758 18.1862 16.0272 18.1862ZM16.0272 32.4362C13.8633 32.4362 11.8182 32.0206 9.89176 31.1893C7.96538 30.3581 6.28308 29.2234 4.84489 27.7852C3.40669 26.347 2.27197 24.6647 1.44072 22.7383C0.609473 20.8119 0.193848 18.7668 0.193848 16.6029C0.193848 14.4126 0.609473 12.3543 1.44072 10.4279C2.27197 8.50148 3.40669 6.82578 4.84489 5.40078C6.28308 3.97578 7.96538 2.84766 9.89176 2.01641C11.8182 1.18516 13.8633 0.769531 16.0272 0.769531C18.2175 0.769531 20.2758 1.18516 22.2022 2.01641C24.1286 2.84766 25.8043 3.97578 27.2293 5.40078C28.6543 6.82578 29.7824 8.50148 30.6136 10.4279C31.4449 12.3543 31.8605 14.4126 31.8605 16.6029C31.8605 18.7668 31.4449 20.8119 30.6136 22.7383C29.7824 24.6647 28.6543 26.347 27.2293 27.7852C25.8043 29.2234 24.1286 30.3581 22.2022 31.1893C20.2758 32.0206 18.2175 32.4362 16.0272 32.4362ZM16.0272 30.0612C19.7744 30.0612 22.9543 28.7484 25.5668 26.1227C28.1793 23.497 29.4855 20.3237 29.4855 16.6029C29.4855 12.8556 28.1793 9.67578 25.5668 7.06328C22.9543 4.45078 19.7744 3.14453 16.0272 3.14453C12.3063 3.14453 9.13308 4.45078 6.50739 7.06328C3.88169 9.67578 2.56885 12.8556 2.56885 16.6029C2.56885 20.3237 3.88169 23.497 6.50739 26.1227C9.13308 28.7484 12.3063 30.0612 16.0272 30.0612Z" fill={!color ? '#a0a0a0' : color}/>
      </svg>
      {badge ?
        <ExploreBadge />
        :
        null}
    </ExploreIconWrapper>
  );
}

export default ExploreIcon;
