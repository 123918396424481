import styled from "styled-components";

import { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AmazonContext from "../../Contexts/AmazonContext";
import { MessageStates } from "messaging";
import { Platform } from "../../Bridge/Platform";

import BagIcon from "icons/dynamic-icons/BagIcon";
import ExploreIcon from "icons/dynamic-icons/ExploreIcon";
import GearIcon from "icons/dynamic-icons/GearIcon";
import InboxIcon from "icons/dynamic-icons/InboxIcon";
import SpendingIcon from 'icons/dynamic-icons/SpendingIcon';

const BarContainer = styled.div`
  position: fixed;
  bottom: 0;

  display: flex;

  height: 60px;
  width: 100%;

  background: #F6F5FF;
  -webkit-box-shadow: 0px -4px 14px rgba(138, 138, 138, 0.25);
  -moz-box-shadow: 0px -4px 14px rgba(138, 138, 138, 0.25);
  box-shadow: 0px -4px 14px rgba(138, 138, 138, 0.25);
  z-index: 99;
`;

const BarItem = styled.div<any>`
  display: flex;
  flex-direction: column;

  text-align: center;
  align-items: center;
  justify-content: center;

  width: 50%;

  font-weight: 500;
  font-size: 11px;
  line-height: 10px;
`;

const NavSpan = styled.span`
  margin-top: 5px;

  color: ${props => props.color ? props.color : '#C2C3C3'};
`;

const BottomPadding = styled.div`
  height: 55px;
`;

type BarItemProps = {
  title: string,
  url?: string,
  icon: (color: string, badge?: boolean) => JSX.Element
};

const routes: {[id: string]: BarItemProps} = {
  '/dashboard/spending' : {
    title: 'Spending',
    url: '/dashboard/spending',
    icon: (color: string) => {
      return <SpendingIcon color={color} />
    }
  },
  '/dashboard/purchases' : {
    title: 'Items',
    url: '/dashboard/purchases',
    icon: (color: string) => {
      return <BagIcon color={color} />
    }
  },
  '/marketplace' : {
    title: 'Discover',
    url: '/marketplace',
    icon: (color: string) => {
      return <ExploreIcon color={color} />
    }
  },
  '/messages' : {
    title: 'Offers',
    icon: (color: string, hasUnopened: boolean|undefined) => {
      return <InboxIcon color={color} badge={hasUnopened}/>
    }
  },
  '/settings': {
    title: 'Settings',
    icon: (color: string) => {
      return <GearIcon color={color} />
    }
  }
}

type BottomNavBarProps = {
  environment: string|null;
  versions: {[key: string]: any};
}

function BottomNavBar({ environment, versions }: BottomNavBarProps) {
  const navigate = useNavigate();
  const { messages } = useContext(AmazonContext);
  const route_order = useMemo(() => {
    if(environment === 'staging' || (versions.appVersion && Platform.hasMarketplace(versions))) {
      return ['/messages', '/dashboard/spending', '/marketplace', '/dashboard/purchases', '/settings'];
    }
    return ['/messages', '/dashboard/spending', '/dashboard/purchases', '/settings'];
  }, [versions, environment]);
  const hasUnopened = useMemo(() => messages.findIndex((msg) => msg.state === MessageStates.Matched) !== -1, [messages]);

  let active_route = '';

  for (let i=0; i < route_order.length ; i++) {
    const r = route_order[i];
    if (window.location.pathname.indexOf(r) === 0) {
      active_route = r;
    }
  }

  let isOnboarding = window.location.pathname.indexOf('/onboarding') === 0;

  return isOnboarding ?
  null
  :
  <>
    <BarContainer>
      {
        route_order.map((r: string) => {
          const color = active_route === r ? '#6B6BD4' : '#A0A0A0';
          const route_props = routes[r];
          const url = route_props.url ? route_props.url : r;
          return <BarItem key={r} onClick={() => navigate(url)}>
            {r === '/messages' ? route_props.icon(color, hasUnopened) : route_props.icon(color)}
            <NavSpan color={color}>{route_props.title}</NavSpan>
          </BarItem>;
        })
      }
    </BarContainer>
    <BottomPadding />
  </>;
}

export default BottomNavBar;
