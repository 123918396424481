import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  },
  elements: {
    point: {
      radius: 0
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: false,
      },
      ticks: {
        callback: (value: any, index:any, ticks:any) => {return null}
      }
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: false,
      },
      ticks: {
        callback: (value:any, index:any, ticks:any) => {return null}
      }
    }
  }
};

type SpendingForecastLineChartProps = {
  labels: number[],
  lastYear: number[],
  thisYear: number[]
}

function SpendingForecastLineChart({labels, lastYear, thisYear}: SpendingForecastLineChartProps) {
  const data = useMemo(() => ({
    labels,
    datasets: [
      {
        label: 'This Year',
        data: thisYear,
        borderColor: 'rgb(108,97,212)',
        backgroundColor: 'rgba(108,97,212,0.5)'
      },
      {
        label: 'Last Year',
        data: lastYear,
        borderColor: 'rgb(183,182,207)',
        backgroundColor: 'rgba(183, 182, 207, 0.5)'
      },
    ],
  }), [labels, lastYear, thisYear]);
  return <Line options={options} data={data} />;
}

export default SpendingForecastLineChart;