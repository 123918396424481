import styled from "styled-components";

export const MIN_SWIPE_DISTANCE = 75;

export const XLHeading = styled.div`
  font-weight: 700;
  font-size: 3rem;
`;

export const LargeHeading = styled.div`
  font-weight: 700;
  font-size: 1.875rem;
`;

export const MediumHeading = styled.div`
  font-weight: 700;
  font-size: 1.625rem;
`;

export const SmallHeading = styled.div`
  font-weight: 700;
  font-size: 1.375rem;
`;

export const SubHeading = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
`;

export const NormalText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3125rem;
`;

export const SmallText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;

export const SmallSpan = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;

export const MicroCopy = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const ButtonText = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const TabLinkText = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
`;

export const FieldInput = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const GRAPH_NUMBER = 10;

export const PageTitle = styled(SmallHeading)`
  line-height: 1.664375rem;
  /* identical to box height */
  letter-spacing: -0.03125rem;

  margin: 1rem;
`;

export const PanelWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 0.5rem;
  margin: 0.9375rem;
  padding: 1rem;
`;

export const PanelTitle = styled(SubHeading)`
  line-height: 1.36125rem;
`;

export const PanelText = styled(SmallText)`
`;

export const PanelBlurb = styled.div`
  background: #EFEDFF;
  border-radius: 0.5rem;
  flex-grow: 1;

  padding: 0.625rem;

  margin-right: 1rem;
  :last-child {
    margin-right: 0;
  }
`;

export const PanelBlurbHeroText = styled(SmallHeading)<any>`
  line-height: 1.664375rem;
  color: ${props => props.color ? props.color : 'black'};
`;

export const IconBadge = styled.div`
  position: absolute;
  bottom: -0.2rem;
  right: -0.2rem;
  height: 0.5rem;
  width: 0.5rem;
  background-color: #d85e31;
  border-radius: 100%;
`;

export const LoadingModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0);
  z-index: 1;
`;

export const LoadingModalMessage = styled(PanelText)`
  text-align: center;
  background-color: white;
  width: 92.5%;
  border-radius: 0.5rem;
  box-shadow: 0px -4px 14px rgba(138, 138, 138, 0.25);
  padding: 0.5rem;
  z-index: 1;
`;

export const LoadingPricePill = styled.div`
  background-color: #F7F6FF;
  height: 1.3125rem;
  width: 4.125rem;
  border-radius: 6.25rem;
`;

export const PanelWidthButton = styled(ButtonText)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 52px;

  color: #4C4C4C;
  background-color: #F6F5FF;

  border: 2.5px solid #4C4C4C4C;
  border-radius: 15px;
`;

export const PanelWidthColoredButton = styled(PanelWidthButton)`
  color: white;
  background: linear-gradient(270deg, #7551CB 3.49%, #5172C8 92.38%);
  border: none;
`;
