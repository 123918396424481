import { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AmazonData, ItemStatus, StatusItemCounterObj } from "amazon";

import Dashboard from "../Dashboard/Dashboard";
import Settings from "components/App/Settings/Settings";
import Share from "components/App/Share/Share";
import Earn from "components/App/Earn/Earn";
import LoadingContainer from "components/App/LoadingContainer";

import Marketplace from "components/App/Marketplace/Marketplace";
import MarketplaceSearchFilter from "components/App/Marketplace/MarketplaceSearchFilter";
import { MarketplaceItem } from "components/App/Marketplace/marketplaceTypes";
import RedirectPage from "components/App/Marketplace/RedirectPage";

import ChartSettings from "components/App/UniquePages/Charts/ChartSettings";
import FAQPage from "components/App/UniquePages/FAQPage";
import FullShoppingReportIntro from "components/App/UniquePages/Onboarding/OnboardingIntroPage";
import FullShoppingReport from "components/App/UniquePages/Onboarding/OnboardingPage";
import InflationPage from "components/App/UniquePages/Inflation/InflationPage";
import ProductPage from "components/App/UniquePages/Product/ProductPage";
import SpendingComparisonView from "components/App/UniquePages/Charts/SpendingComparisonView";
import SpendingByCategoryView from "components/App/UniquePages/Charts/SpendingByCategoryView";
import SpendingTrendView from "components/App/UniquePages/Charts/SpendingTrendView";
import Wallet from "components/App/UniquePages/Wallet";

import Transactions from "components/App/Transactions/Transactions";
import TransactionsFilter from "../Transactions/TransactionsFilter";

import ShoppingReportIntro from "components/App/ShoppingReport/Intro";
import ShoppingReport from "components/App/ShoppingReport/Report";

import Inbox from "components/App/Messages/Inbox";
import Message from "components/App/Messages/Message";

const dateOptions = [
  {
    url: `/spending/by-date/weekly`,
    text: `Weekly`
  },
  {
    url: `/spending/by-date/monthly`,
    text: `Monthly`
  },
  {
    url: `/spending/by-date/yearly`,
    text: `Yearly`
  },
];

const categoryOptions = [
  {
    url: `/spending/by-category/monthly`,
    text: `Monthly`
  },
  {
    url: `/spending/by-category/yearly`,
    text: `Yearly`
  },
];

interface ViewsRouterProps{
  data: AmazonData|undefined;
  earnedCredits?: string;
  hasDataLoaded: boolean;
  lastUpdate: Date|undefined;
  loadingMarketplaceLink: boolean|undefined;
  marketplaceItems: MarketplaceItem[];
  marketplaceAllItems: MarketplaceItem[];
  pendingBalance: number|undefined;
  setLastCheckedBalance: React.Dispatch<React.SetStateAction<{[key: string]: number}>>;
  setLoadingMarketplaceLink: (b: boolean|undefined) => void;
  setPendingBalance: React.Dispatch<React.SetStateAction<number|undefined>>;
  statusItemCounter: StatusItemCounterObj;
  updateStatus: ItemStatus|undefined;
  updating: boolean;
  versions: {[key: string]: any};
}

function ViewsRouter({
  data,
  earnedCredits,
  hasDataLoaded,
  lastUpdate,
  loadingMarketplaceLink,
  marketplaceItems,
  marketplaceAllItems,
  pendingBalance,
  setLastCheckedBalance,
  setLoadingMarketplaceLink,
  setPendingBalance,
  statusItemCounter,
  updateStatus,
  updating,
  versions,
}: ViewsRouterProps) {

  const [showSharePanel, setShowSharePanel] = useState<boolean>(true);
  const [showNotificationCarousel, setShowNotificationCarousel] = useState<boolean>(true);

  return <Routes>
    <Route path="/" element={<Navigate to="dashboard/spending" />} />
    <Route path="dashboard/:dashboard_type"
      element={<Dashboard
        hasDataLoaded={hasDataLoaded}
        data={data}
        lastUpdate={lastUpdate}
        showNotificationCarousel={showNotificationCarousel}
        showSharePanel={showSharePanel}
        setShowNotificationCarousel={setShowNotificationCarousel}
        setShowSharePanel={setShowSharePanel}
        statusItemCounter={statusItemCounter}
        updateStatus={updateStatus}
        updating={updating}
        versions={versions}
      />}
    />
	  <Route path="earn" element={<Earn />} />
    <Route path="faq" element={<FAQPage />} />
    <Route path="/full-shopping-report-intro" element={<FullShoppingReportIntro />} />
    <Route path="/full-shopping-report" element={<FullShoppingReport />} />
    <Route path="inflation" element={<InflationPage />} />
    <Route path="marketplace">
      <Route index element={<Marketplace earnedCredits={earnedCredits} marketplaceAllItems={marketplaceAllItems} marketplaceItems={marketplaceItems} />} />
      <Route path="search">
        <Route index element={null} />
        <Route path="filter" element={<MarketplaceSearchFilter />} />
      </Route>
    </Route>
    <Route path="messages">
      <Route index element={<Navigate replace to="/messages/filter/all" />} />
      <Route path="filter/:filterOption" element={<Inbox earnedCredits={earnedCredits} />} />
      <Route path="message/:msgId" element={<Message />} />
    </Route>
    <Route path="/onboarding-intro" element={<ShoppingReportIntro />} />
    <Route path="/onboarding" element={<ShoppingReport />} />
    <Route path="product/:asin" element={<ProductPage />} />
    <Route path="purchases">
      <Route index element={<Transactions />} />
      <Route path="filter/:transaction_type" element={<TransactionsFilter />} />
    </Route>
    <Route path="redirect/marketplace/:asin"
      element={
        <RedirectPage loadingMarketplaceLink={loadingMarketplaceLink} marketplaceAllItems={marketplaceAllItems} setLoadingMarketplaceLink={setLoadingMarketplaceLink} setPendingBalance={setPendingBalance} />
    }/>
    <Route path="search" element={null}/>
    <Route path="settings" element={<Settings />} />
		<Route path="share" element={<Share versions={versions} />} />
    <Route path="spending">
      <Route path="by-date/:by" element={<LoadingContainer><SpendingComparisonView /></LoadingContainer> } />
      <Route path="date-filter" element={<ChartSettings options={dateOptions} />} />
      <Route path="by-category/:by" element={<LoadingContainer><SpendingByCategoryView /></LoadingContainer> } />
      <Route path="category-filter" element={<ChartSettings options={categoryOptions} />} />
      <Route path="by-trend" element={<LoadingContainer><SpendingTrendView /></LoadingContainer> } />
    </Route>
    <Route path="wallet" element={<Wallet earnedCredits={earnedCredits} pendingBalance={pendingBalance} setLastCheckedBalance={setLastCheckedBalance} />} />
  </Routes>
}

export default ViewsRouter;
