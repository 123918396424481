import { useEffect, useRef, useState, useMemo, useContext } from "react";
import Toggle from "./Toggle";
import styled from "styled-components";

import { AmazonHelpers } from "amazon";
import AmazonContext from "components/Contexts/AmazonContext";

import { NATIVE_IMAGE_DOWNLOAD } from "../../../Bridge/incoming_events";
import {
  WEBVIEW_REQUEST_IMAGE,
  WEBVIEW_REQUEST_SHARE,
  WEBVIEW_REQUEST_SHARE_WITH_TEXT
} from "../../../Bridge/outgoing_events";
import { MobileEventHandler } from "../../../Bridge/MobileEventHandler";

import { PanelWidthColoredButton } from "../../styles/generics";
import CatLogo from "../../../../images/cat.svg";
import ItemsLogo from "../../../../images/items.svg";
import SpendingLogo from "../../../../images/spending.svg";
import UskoLogo from "../../../../images/usko-light.png";
import { Platform, OS } from "../../../Bridge/Platform";

const GenerateImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex
  flexDirection: column;
  justifyContent: space-between;
  padding: 1rem;
  gap: 1rem;
  z-index: 1;
`;
const FilterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #4c4c4c;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 4.65rem;
`;
const FilterTitle = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 2rem 0 0.5rem 0;
  padding: 1rem 0rem 0rem;
`;
const FilterRow = styled.label`
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 0rem;
  border-bottom: 1px solid #c2c3c3;
  font-size: 14px;
`;
const FilterBottomRow = styled.label`
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 0rem;
  font-size: 14px;
`;
const FilterText = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
`;
const ShareButton = styled(PanelWidthColoredButton)`
  margin-top: 1rem;
`;
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

function roundRect(ctx, x, y, width, height, radius = 10, fill = false, stroke = true) {
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.strokeStyle = "#fff";
    ctx.stroke();
  }
}

const writeText = (ctx, info, style = {}) => {
  const { text, x, y } = info;
  const { fontSize = 20, fontFamily = "Inter", color = "black", textAlign = "left", textBaseline = "top" } = style;

  ctx.beginPath();
  ctx.font = `${style.fontWeight || "bold"} ${fontSize}px ${fontFamily}`;

  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.fillStyle = color;

  ctx.fillText(text, x, y);
  ctx.stroke();
};

const drawCircleImage = (ctx, imgsrc, xPosition, yPosition, width, height) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.src = imgsrc;

  image.onload = function () {
    ctx.save();
    ctx.beginPath();
    ctx.arc(xPosition + width / 2, yPosition + height / 2, width / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(image, xPosition, yPosition, width, height);
    ctx.beginPath();
    ctx.arc(xPosition, yPosition, 25, 0, Math.PI * 2, true);
    ctx.clip();
    ctx.closePath();
    ctx.restore();
  };
};
const drawStandardImage = (ctx, imgsrc, xPosition, yPosition, width, height) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.src = imgsrc;
  image.onload = function () {
    ctx.drawImage(image, xPosition, yPosition, width, height);
  };
};

const createTopThird = (ctx, payload) => {
  roundRect(ctx, payload.x, payload.y, 250, 220);
  ctx.beginPath();
  ctx.arc(payload.x + 60, payload.y + 60, 40, 0, 4 * Math.PI);
  ctx.fillStyle = "#fff";
  ctx.fill();
  drawStandardImage(ctx, payload.image, payload.x + 35, payload.y + 35, 50, 50);
  writeText(
    ctx,
    { text: payload.title, x: payload.x + 20, y: payload.y + 120 },
    { fontSize: 32, color: "#fff", fontWeight: "bold" }
  );
  writeText(
    ctx,
    { text: payload.text, x: payload.x + 20, y: payload.y + 160 },
    { fontSize: 26, color: "#fff", fontWeight: "normal" }
  );
};

const createTopThirdYearsAmazon = (ctx, payload) => {
  roundRect(ctx, payload.x, payload.y, 250, 220);
  ctx.beginPath();
  ctx.arc(payload.x + 60, payload.y + 60, 40, 0, 4 * Math.PI);
  ctx.fillStyle = "#fff";
  ctx.fill();

  writeText(
    ctx,
    { text: payload.count, x: payload.x + (payload.count > 9 ? 36 : 50), y: payload.y + 44 },
    { fontSize: 40, color: "#5172C8", fontWeight: "bold" }
  );
  writeText(
    ctx,
    { text: payload.title, x: payload.x + 20, y: payload.y + 120 },
    { fontSize: 32, color: "#fff", fontWeight: "bold" }
  );
  writeText(
    ctx,
    { text: payload.titleTwo, x: payload.x + 20, y: payload.y + 160 },
    { fontSize: 32, color: "#fff", fontWeight: "bold" }
  );
};

/* function created to take in a string and split into 3 lines with max string length of maxLength */
function split3lines(string, maxLength){
	const wordArray = string.split(" ");
	let firstLine = "";
	let secondLine = "";
	let thirdLine = "";

	for(let i=0;i<wordArray.length;i++){
			if((firstLine + wordArray[i]).length < maxLength){
					firstLine += ` ${wordArray[i]}`;
					continue;
			}
			if((secondLine + wordArray[i]).length < maxLength){
					secondLine += ` ${wordArray[i]}`;
					continue;
			}
			if((thirdLine + wordArray[i]).length < maxLength){
					thirdLine += ` ${wordArray[i]}`;
					continue;
			}
			break;
	}

	return { firstLine: firstLine, secondLine: secondLine, thirdLine: thirdLine };
}

const createBottomThird = (ctx, payload) => {
  roundRect(ctx, payload.x, payload.y, 250, 420);
  drawCircleImage(ctx, payload.image, payload.x + 75.0, payload.y + 30, 100, 100);

  const labelFirstLine = payload.label.split(" ").slice(0, 2).join(" ");
  const labelSecondLine = payload.label.split(" ").slice(2, 4).join(" ");

	const { firstLine: nameFirstLine, secondLine: nameSecondLine, thirdLine: nameThirdLine } = split3lines(payload.name, 13);

  writeText(
    ctx,
    { text: payload.title, x: payload.x + 125, y: payload.y + 160 },
    { fontSize: 32, color: "#fff", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: labelFirstLine, x: payload.x + 125, y: payload.y + 200 },
    { fontSize: 26, color: "#fff", fontWeight: "normal", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: labelSecondLine, x: payload.x + 125, y: payload.y + 240 },
    { fontSize: 26, color: "#fff", fontWeight: "normal", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameFirstLine, x: payload.x + 125, y: payload.y + 280 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameSecondLine, x: payload.x + 125, y: payload.y + 320 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameThirdLine, x: payload.x + 125, y: payload.y + 360 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
};

const createBottomHalf = (ctx, payload) => {
  roundRect(ctx, payload.x, payload.y, 390, 420);
  drawCircleImage(ctx, payload.image, payload.x + 150, payload.y + 30, 100, 100);

  const labelFirstLine = payload.label.split(" ").slice(0, 2).join(" ");
  const labelSecondLine = payload.label.split(" ").slice(2, 4).join(" ");

  const nameFirstLine = payload.name.split(" ").slice(0, 2).join(" ");
  const nameSecondLine = payload.name.split(" ").slice(2, 4).join(" ");
  const nameThirdLine = payload.name.split(" ").slice(4, 6).join(" ");
  writeText(
    ctx,
    { text: payload.title, x: payload.x + 200, y: payload.y + 160 },
    { fontSize: 32, color: "#fff", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: labelFirstLine, x: payload.x + 200, y: payload.y + 200 },
    { fontSize: 26, color: "#fff", fontWeight: "normal", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: labelSecondLine, x: payload.x + 200, y: payload.y + 240 },
    { fontSize: 26, color: "#fff", fontWeight: "normal", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameFirstLine, x: payload.x + 200, y: payload.y + 280 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameSecondLine, x: payload.x + 200, y: payload.y + 320 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameThirdLine, x: payload.x + 200, y: payload.y + 360 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
};

const createBottomFull = (ctx, payload) => {
  roundRect(ctx, payload.x, payload.y, 810, 420);
  drawCircleImage(ctx, payload.image, payload.x + 350, payload.y + 30, 100, 100);

  const labelFirstLine = payload.label.split(" ").slice(0, 2).join(" ");
  const labelSecondLine = payload.label.split(" ").slice(2, 4).join(" ");

  const nameFirstLine = payload.name.split(" ").slice(0, 2).join(" ");
  const nameSecondLine = payload.name.split(" ").slice(2, 4).join(" ");
  const nameThirdLine = payload.name.split(" ").slice(4, 6).join(" ");
  writeText(
    ctx,
    { text: payload.title, x: payload.x + 400, y: payload.y + 160 },
    { fontSize: 32, color: "#fff", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: labelFirstLine, x: payload.x + 400, y: payload.y + 200 },
    { fontSize: 26, color: "#fff", fontWeight: "normal", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: labelSecondLine, x: payload.x + 400, y: payload.y + 240 },
    { fontSize: 26, color: "#fff", fontWeight: "normal", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameFirstLine, x: payload.x + 400, y: payload.y + 280 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameSecondLine, x: payload.x + 400, y: payload.y + 320 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
  writeText(
    ctx,
    { text: nameThirdLine, x: payload.x + 400, y: payload.y + 360 },
    { fontSize: 26, color: "#63DBFA", fontWeight: "bold", textAlign: "center" }
  );
};

function GenerateImage({ versions }) {
  const [showSectionOne, setShowSectionOne] = useState(true);
  const [showSectionTwo, setShowSectionTwo] = useState(true);
  const [showSectionThree, setShowSectionThree] = useState(true);
  const [mostExpensiveImgSrc, setMostExpensiveImgSrc] = useState(null);
  const [mostPurchasedImgSrc, setMostPurchasedImgSrc] = useState(null);
  const [firstPurchaseImgSrc, setFirstPurchaseImgSrc] = useState(null);
  const canvasRef = useRef([]);

	const { amazonHandler } = useContext(AmazonContext);
	const years = useMemo(() =>
			amazonHandler.years.length
	, [amazonHandler]);
	const totalHistory = useMemo(() =>
			amazonHandler.getTotalSpending()
	, [amazonHandler]);
	const highlights = useMemo(() =>
			amazonHandler.getHighlightsPanelProps()
	, [amazonHandler]);
	const mostOrderedQuantity = useMemo(() =>
			amazonHandler.getOrdersByAsin(highlights[1].asin).length
	, [amazonHandler, highlights]);

  const mostExpensiveImgRequest = useMemo(() =>
    {
      return {
        id: "mostExpensiveImgRequest",
        url: highlights[0].img,
      };
    }
  , [highlights]);
  const mostPurchasedImgRequest = useMemo(() =>
    {
      return {
        id: "mostPurchasedImgRequest",
        url: highlights[1].img,
      };
    }
  , [highlights]);
  const firstPurchaseImgRequest = useMemo(() =>
    {
      return {
        id: "firstPurchaseImgRequest",
        url: highlights[2].img,
      };
    }
  , [highlights]);

  useEffect(() => {
    if (highlights){
      MobileEventHandler.add_handler(NATIVE_IMAGE_DOWNLOAD.type, (data) => {
        if(data.id === 'mostExpensiveImgRequest'){
          setMostExpensiveImgSrc(`data:image/png;base64, ${data.data}`);
        }
        if(data.id === 'mostPurchasedImgRequest'){
          setMostPurchasedImgSrc(`data:image/png;base64, ${data.data}`);
        }
        if(data.id === 'firstPurchaseImgRequest'){
          setFirstPurchaseImgSrc(`data:image/png;base64, ${data.data}`);
        }
      }, NATIVE_IMAGE_DOWNLOAD.type);
   }
  }, [highlights, mostExpensiveImgSrc, mostPurchasedImgSrc, firstPurchaseImgSrc]);

  useEffect(() => {
    MobileEventHandler.post_event(WEBVIEW_REQUEST_IMAGE, mostExpensiveImgRequest);
    MobileEventHandler.post_event(WEBVIEW_REQUEST_IMAGE, mostPurchasedImgRequest);
    MobileEventHandler.post_event(WEBVIEW_REQUEST_IMAGE, firstPurchaseImgRequest);
  }, [highlights, firstPurchaseImgRequest, mostExpensiveImgRequest, mostPurchasedImgRequest]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    /*
    ctx.fillStyle = "#ccc";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    */

    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, "#7551CB");
    gradient.addColorStop(1, "#5172C8");

    // Fill with gradient
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const catSvg = new Image();
    catSvg.src = CatLogo;

    catSvg.onload = () => {
      ctx.drawImage(catSvg, 100, 560, 320, 240);
    };

    const uskoLogo = new Image();
    uskoLogo.src = UskoLogo;

    uskoLogo.onload = () => {
      ctx.drawImage(uskoLogo, 72, 320, 240, 86);
    };

    writeText(ctx, { text: "My", x: 80, y: 80 }, { fontSize: 56, color: "#fff", fontWeight: "bold" });
    writeText(ctx, { text: "Amazon", x: 180, y: 80 }, { fontSize: 56, color: "#63DBFA", fontWeight: "bold" });
    writeText(ctx, { text: "purchase", x: 80, y: 160 }, { fontSize: 56, color: "#63DBFA", fontWeight: "bold" });
    writeText(ctx, { text: "statistics!", x: 80, y: 240 }, { fontSize: 56, color: "#fff", fontWeight: "bold" });
    writeText(
      ctx,
      { text: "Stats generated with", x: 80, y: 420 },
      { fontSize: 26, color: "#fff", fontWeight: "normal" }
    );
    writeText(
      ctx,
      { text: "@uskoprivacy", x: 80, y: 470 },
      { fontSize: 26, color: "#fff", fontWeight: "normal" }
    );
    writeText(
      ctx,
      { text: "uskoprivacy.com", x: 80, y: 520 },
      { fontSize: 26, color: "#fff", fontWeight: "normal" }
    );

    createTopThird(ctx, {
      title: `${totalHistory.orders?.toLocaleString("en-US") || 0} items`,
      text: "Total purchased",
      x: 510,
      y: 80,
      image: ItemsLogo,
    });
    createTopThird(ctx, {
      title: `$${totalHistory.totalSpending?.toLocaleString("en-US") || 0}`,
      text: "Total spent",
      x: 790,
      y: 80,
      image: SpendingLogo,
    });
    createTopThirdYearsAmazon(ctx, {
      title: `Years on`,
      titleTwo: `Amazon`,
      count: years,
      x: 1070,
      y: 80,
    });

    const SECTIONONE_PAYLOAD_OBJECT = {
      title: `${AmazonHelpers.priceFormat(highlights[0].price)}`,
      label: "Most expensive purchase",
      name: highlights[0].item,
      image: mostExpensiveImgSrc,
    };
    const SECTIONTWO_PAYLOAD_OBJECT = {
      title: `${AmazonHelpers.numberFormat(mostOrderedQuantity)}x`,
      label: "Most often purchased",
      name: highlights[1].item,
      image: mostPurchasedImgSrc,
    };
    const SECTIONTHREE_PAYLOAD_OBJECT = {
      title: `${AmazonHelpers.priceFormat(highlights[2].price)}`,
      label: "First purchased item",
      name: highlights[2].item,
      image: firstPurchaseImgSrc,
    };

    if (showSectionOne && showSectionTwo && showSectionThree) {
      createBottomThird(ctx, {
        ...SECTIONONE_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });

      createBottomThird(ctx, {
        ...SECTIONTWO_PAYLOAD_OBJECT,
        x: 790,
        y: 320,
      });

      createBottomThird(ctx, {
        ...SECTIONTHREE_PAYLOAD_OBJECT,
        x: 1070,
        y: 320,
      });
    } else if (showSectionOne && showSectionTwo && !showSectionThree) {
      createBottomHalf(ctx, {
        ...SECTIONONE_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });
      createBottomHalf(ctx, {
        ...SECTIONTWO_PAYLOAD_OBJECT,
        x: 930,
        y: 320,
      });
    } else if (showSectionOne && !showSectionTwo && showSectionThree) {
      createBottomHalf(ctx, {
        ...SECTIONONE_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });
      createBottomHalf(ctx, {
        ...SECTIONTHREE_PAYLOAD_OBJECT,
        x: 930,
        y: 320,
      });
    } else if (!showSectionOne && showSectionTwo && showSectionThree) {
      createBottomHalf(ctx, {
        ...SECTIONTWO_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });
      createBottomHalf(ctx, {
        ...SECTIONTHREE_PAYLOAD_OBJECT,
        x: 930,
        y: 320,
      });
    } else if (showSectionOne && !showSectionTwo && !showSectionThree) {
      createBottomFull(ctx, {
        ...SECTIONONE_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });
    } else if (!showSectionOne && showSectionTwo && !showSectionThree) {
      createBottomFull(ctx, {
        ...SECTIONTWO_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });
    } else if (!showSectionOne && !showSectionTwo && showSectionThree) {
      createBottomFull(ctx, {
        ...SECTIONTHREE_PAYLOAD_OBJECT,
        x: 510,
        y: 320,
      });
    }
  }, [
		totalHistory,
    firstPurchaseImgSrc,
    highlights,
    mostExpensiveImgSrc,
    mostOrderedQuantity,
    mostPurchasedImgSrc,
    showSectionOne,
    showSectionTwo,
    showSectionThree,
    years,
  ]);

  return (
    <GenerateImageWrapper>
      <div style={{ width: "100%", fontFamily: "'Inter', sans-serif" }}>
        <canvas
          ref={canvasRef}
          width={1400}
          height={800}
          style={{ width: "100%", display: "block", marginTop: "1rem" }}
        />

        <FilterOptionsWrapper>
          <FilterTitle>Filter shared information</FilterTitle>
          <FilterRow>
            <FilterText>Most Expensive Purchase</FilterText>
            <ToggleWrapper onChange={() => setShowSectionOne((prev) => !prev)}>
              <Toggle />
            </ToggleWrapper>
          </FilterRow>
          <FilterRow>
            <FilterText>Most Often Purchased</FilterText>
            <ToggleWrapper onChange={() => setShowSectionTwo((prev) => !prev)}>
              <Toggle />
            </ToggleWrapper>
          </FilterRow>
          <FilterBottomRow>
            <FilterText>First Purchased Item</FilterText>
            <ToggleWrapper onChange={() => setShowSectionThree((prev) => !prev)}>
              <Toggle />
            </ToggleWrapper>
          </FilterBottomRow>
          <ShareButton
            onClick={() => {
              let canvas = canvasRef.current;
              const imgBase64 = canvas.toDataURL().split(';base64,')[1];
              const shareText = Platform.hasShareTextCapability(versions);
              if (shareText) {
                MobileEventHandler.post_event(WEBVIEW_REQUEST_SHARE_WITH_TEXT, {
                  image: imgBase64,
                  text: "These are my Amazon stats from Usko Privacy! What are yours?"
                });
              } else {
                MobileEventHandler.post_event(WEBVIEW_REQUEST_SHARE, imgBase64);
              }
            }}
          >
            Share
          </ShareButton>
        </FilterOptionsWrapper>
      </div>
    </GenerateImageWrapper>
  );
};

export default GenerateImage;
