import { useState } from "react";
import { MarketplaceItem } from "./marketplaceTypes";
import { FixedSizeList as List } from "react-window";

import styled from "styled-components";
import { TabLinkText } from "../styles/generics";
import ItemCard from "./ItemCard";

const EndCardWrapper = styled.div`
  display: flex;
  height: 17rem;
  width: 10rem;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #e4e4e4;
`;
const EndCardMsg = styled(TabLinkText)`
  text-align: center;
`;

type ItemCarouselPanelProps = {
  allItemsSorted: Map<string, MarketplaceItem[]>|undefined;
  category: string;
  itemStack: MarketplaceItem[];
}

function ItemCarouselPanel({ allItemsSorted, category, itemStack }: ItemCarouselPanelProps) {
  const endCard = { endcard: "true" };
  const [carouselStack, setCarouselStack] = useState<MarketplaceItem[]>([...itemStack, endCard]);
  const [endCardMsg, setEndCardMsg] = useState('show more items');

  return(
    <List
      itemSize={170}
      layout={"horizontal"}
      direction={"ltr"}
      height={275}
      width={window.innerWidth - 16}
      itemData={carouselStack}
      itemCount={carouselStack.length}
    >
      {({data, index, style}: {data: MarketplaceItem[], index: number, style: any}) => {
        const item = data[index];
        if(item.endcard === "true") return (
          <div
            style={style}
            key={`${category}_endCard`}
          >
            <EndCardWrapper
              onClick={() => {
                if(allItemsSorted !== undefined) {
                  const allCategoryItemsSorted = allItemsSorted.get(category);
                  if(allCategoryItemsSorted) setCarouselStack(allCategoryItemsSorted);
                }
              }}
            >
              <EndCardMsg>{endCardMsg}</EndCardMsg>
            </EndCardWrapper>
          </div>
        );
        return <ItemCard innerStyle={style} item={item} key={`${category}_itemCard_${index}`}/>
      }}
    </List>
  );
}

export default ItemCarouselPanel;
