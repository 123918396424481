import { category_titles, load_image } from "components/App/Dashboard/categories/categories";

function SvgComponent(props) {
  if (props.type){
    return <img 
      style={{ height: props.height, width: props.width}} 
      src={load_image(category_titles[props.type])} 
      alt={props.type}
    />
  }
  return null;
}

export default SvgComponent;
