import React, { useCallback, useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import { FlexRow, Left } from "../Dashboard/panels/generics";
import { SmallText } from "../styles/generics";

import SearchIcon from "icons/search-icon.svg";
import uskissaWand from "images/uskissa-wand.svg";
import XIcon from "icons/x-icon.svg";
import { AmazonHelpers, AmazonSearch, Asin } from "../../../amazon";
import { useNavigate } from "react-router-dom";
import AmazonContext from "components/Contexts/AmazonContext";
import BackArrowIcon from "icons/back-arrow-icon.svg";

import DevModal from "../DevModal/DevModal";

const SearchBarWrapper = styled.div<any>`
  ${(props) =>
    props.focused
      ? `
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #F6F5FF;
    width: 100%;
    height: 100%;
    max-height: 100%;

    // react boostrap buttons seem to hold some value of z-index greater than 1;
    z-index: 9999;
  `
      : ""}
`;

const SearchBarRow = styled.div`
  position: relative;

  padding: 8px 16px;
  background-color: #f6f5ff;

  top: 0;
`;

const SearchBarInputBox = styled.div`
  padding: 0.625rem;

  margin-left: 2rem;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;

  background: #ffffff;
  border-radius: 0.9375rem;

  border: none;
`;

const SearchBarInput = styled.input`
  all: unset;

  width: calc(100vw - 115px);
`;

const SearchResultScreen = styled.ul<any>`
  list-style: none;

  padding-left: 0px;

  background-color: #f6f5ff;
`;

const SearchResultsContainer = styled.div`
  max-height: calc(100vh - 96px);
  overflow-y: scroll;
`;

const SearchResultItemWrapper = styled.li`
  display: flex;
  align-items: center;

  padding-left: 16px;
  padding-right: 16px;

  :hover {
    background-color: lightgrey;
    transition: 1s;
  }
`;

const SearchSuggestionsItemWrapper = styled.li`
  padding: 8px 16px 8px 16px;

  :hover {
    background-color: lightgrey;
    transition: 1s;
  }
`;

const SearchResultTitle = styled.div`
  padding: 1.25rem 1rem 0 1rem;

  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.36125rem;
`;

const SearchResultImg = styled.img`
  width: 62px;
  height: 62px;

  border-radius: 15px;

  margin-right: 14px;
`;

const SearchResultText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;

const SearchResultItemText = styled.div`
  padding-top: 0.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;

const BackIcon = styled.img`
  position: absolute;

  left: 15px;
`;
const SearchPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;

  background: linear-gradient(180deg, #9d7de9 0%, #6890fa 100%);
`;
const SearchPlaceholderImg = styled.img`
  width: 67.79%;
`;
const SearchPlaceholderText = styled(SmallText)`
  color: #ffffff;
  padding-bottom: 1rem;
  text-align: center;
`;

const NONE = "none";
const SEARCH_HISTORY = "history";
const SEARCH_SUGGESTIONS = "suggestions";
const SEARCH_RESULTS = "results";

// const RESULTS_PER_PAGE = 10;
const SEARCH_HISTORY_LIMIT = 10;

const searchPlaceholder = (
  <SearchPlaceholderWrapper>
    <SearchPlaceholderImg src={uskissaWand} />
    <SearchPlaceholderText>
      Looking for an old purchase? Search your entire Amazon history.
    </SearchPlaceholderText>
  </SearchPlaceholderWrapper>
);

const searchTitle: {
  [id: string]: string;
} = {
  history: "Recent",
  suggestions: "Purchases",
  results: "Results",
};

type ResultType = {
  asin: Asin;
  img?: string;
  title?: string;
  purchase_date?: string;
  price?: number;
};

type SearchBarProps = {
  versions: {[key: string]: any};
}

function SearchBar({ versions }: SearchBarProps) {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  // const [page, setPage] = useState(1);
  const [pastSearches, setPastSearches] = useState<string[]>([]);
  const [results, setResults] = useState<ResultType[]>([]);
  const [searching, setSearching] = useState(false);
  const [showDevModal, setShowDevModal] = useState(false);
  const SearchBarRef = useRef<any>(null);

  const initialTerm = new URLSearchParams(window.location.search).get('q') || '';
  const initialState = initialTerm === '' ? NONE : SEARCH_RESULTS;
  const [searchTerm, setSearchTerm] = useState(initialTerm);
  const [searchState, setSearchState] = useState(initialState);

  useEffect(() => {
    const storedSearches =
      localStorage
        .getItem("pastSearches")
        ?.split("\n")
        .filter((e) => e.length) || [];
    setPastSearches(storedSearches);
    setTimeout(() => {
      SearchBarRef.current?.blur();
      SearchBarRef.current?.focus();
    }, 100);
  }, []);

  // Prevent scrolling when input is active.
  useEffect(() => {
    if (searchState !== NONE) document.body.classList.add("no-scroll");
    else document.body.classList.remove("no-scroll");
  }, [searchState]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    if (searchTerm.length <= 0) {
      return;
    }

    setResults(AmazonSearch.search(searchTerm, amazonHandler, 20).map((f: any) => {
      return {
        asin: f.item.asin,
        img: f.item.asin,
        title: f.item.title,
        purchase_date: f.item.orderDate?.toDateString(),
        price: f.item.price,
      };
    }));

  }, [searchTerm, amazonHandler]);

  const handleOnFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!searchTerm.length) setSearchState(SEARCH_HISTORY);
    },
    [searchTerm.length],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length) setSearchState(SEARCH_SUGGESTIONS);
      else setSearchState(SEARCH_HISTORY);
      setSearchTerm(event.target.value);
    },
    [],
  );

  const getNewSearchHistoryOrder = useCallback(
    (prevPastSearches: string[]) => {
      const copyOfSearches = prevPastSearches.map((e) => e);
      const index = copyOfSearches.indexOf(searchTerm);

      // If searcTerm is at the front. Do nothing.
      if (index !== 0) {
        // If value is not in the list, no need to shift it.
        if (index !== -1) {
          copyOfSearches.splice(index, 1);
        }

        copyOfSearches.unshift(searchTerm);
      }

      if (copyOfSearches.length > SEARCH_HISTORY_LIMIT) {
        copyOfSearches.pop();
      }

      return copyOfSearches;
    },
    [searchTerm],
  );

  const handleSaveSearchHistory = useCallback(() => {
    setPastSearches((prevPastSearches) => {
      const copyOfSearches = getNewSearchHistoryOrder(prevPastSearches);
      localStorage.setItem("pastSearches", copyOfSearches.join("\n"));
      return copyOfSearches;
    });
  }, [getNewSearchHistoryOrder]);

  const handleSearch = useCallback(
    async (term: string) => {
      handleSaveSearchHistory();
      setSearchState(SEARCH_RESULTS);
      setSearching(true);

      await new Promise((r) => setTimeout(r, 50));

      const searchResults = AmazonSearch.search(term, amazonHandler, 20).map((f: any) => {
        return {
          asin: f.item.asin,
          img: f.item.asin,
          title: f.item.title,
          purchase_date: f.item.orderDate?.toDateString(),
          price: f.item.price,
        };
      });

      setSearching(false);
      setResults(searchResults);
    },
    [amazonHandler, handleSaveSearchHistory],
  );

  const handleClickProduct = useCallback(
    (id: string) => {
      const copyOfSearches = getNewSearchHistoryOrder(pastSearches);
      localStorage.setItem("pastSearches", copyOfSearches.join("\n"));
      const url = new URL(window.location.href);
      url.searchParams.set('q', searchTerm);
      window.history.replaceState({}, '', url);
      navigate(`/product/${id}`);
    },
    [getNewSearchHistoryOrder, navigate, pastSearches, searchTerm],
  );

  // TODO just remove specific handling for the 'enter' key ??
  const handleEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // If not enter, then ignore.
      if (event.key === "Enter" && searchTerm.length) {
        if (searchTerm.toLowerCase() === "uskodebug") {
          SearchBarRef.current.blur();
          setShowDevModal(true);
          return;
        }
        handleSaveSearchHistory();
      }
    },
    [handleSaveSearchHistory, searchTerm],
  );

  const handleClickSearchHistory = useCallback(
    (str: string) => {
      setSearchTerm(str);
      handleSearch(str);
    },
    [handleSearch],
  );

  const generateRemoveHistoryFunction = useCallback((i: number) => {
    return () => {
      setPastSearches((prevPastSearches) => {
        const copyOfSearches = prevPastSearches.map((e) => e);
        copyOfSearches.splice(i, 1);
        localStorage.setItem("pastSearches", copyOfSearches.join("\n"));
        return copyOfSearches;
      });
    };
  }, []);

  const generateSearchHistory = useCallback(
    (pastSearches: string[]) => {
      return pastSearches.map((search, i) => {
        return (
          <li
            key={`history` + i}
            style={{ display: "flex", marginTop: 16, padding: "0 16px 0 16px" }}
          >
            <img
              style={{ paddingRight: 27 }}
              src={XIcon}
              alt="x icon"
              onClick={generateRemoveHistoryFunction(i)}
            />
            <SearchResultText
              style={{ width: "100%" }}
              onClick={() => handleClickSearchHistory(search)}
            >
              {search}
            </SearchResultText>
          </li>
        );
      });
    },
    [generateRemoveHistoryFunction, handleClickSearchHistory],
  );

  const generateResults = useCallback(
    (results: ResultType[]) => {
      return results.map((result, i) => {
        return (
          <SearchResultItemWrapper
            onClick={() => handleClickProduct(result.asin)}
            key={"result" + i}
          >
            <SearchResultImg
              src={result.img ? AmazonHelpers.asin_to_image(result.img, 250) : ""}
              alt="Product"
            />
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "calc(100% - 76px)",
            }}>
              <SearchResultItemText
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                {result.title}
              </SearchResultItemText>
              <SearchResultItemText
                style={{
                  fontSize: 13,
                }}
              >
                Purchased on {result.purchase_date}
              </SearchResultItemText>
              <SearchResultItemText
                style={{
                  fontSize: 13,
                }}
              >
                ${result.price}
              </SearchResultItemText>
            </div>
          </SearchResultItemWrapper>
        );
      });
    },
    [handleClickProduct],
  );

  const generateSearchResults = useCallback(() => {
    let resultsBlock: JSX.Element[] = [];

    if (searchState === SEARCH_HISTORY) {
      resultsBlock = generateSearchHistory(pastSearches);
    }

    if (searchState === SEARCH_HISTORY && !pastSearches.length) return searchPlaceholder;

    if (searchState === SEARCH_SUGGESTIONS) {
      resultsBlock = generateResults(results);
    }

    if (searchState === SEARCH_RESULTS) {
      resultsBlock = generateResults(results);
    }

    if (!resultsBlock.length && !searching) {
      resultsBlock = [
        <SearchSuggestionsItemWrapper key={"no_match_row"}>
          <SearchResultText>No Matches</SearchResultText>
        </SearchSuggestionsItemWrapper>,
      ];
    }

    const searchText = searching ? "Waiting for" : results.length;
    return (
      <SearchResultScreen>
        <SearchResultTitle>
          {(searchState === SEARCH_RESULTS ? searchText + " " : "") + searchTitle[searchState]}
        </SearchResultTitle>
        <SearchResultsContainer>
          {searchState === SEARCH_RESULTS ? <div style={{ height: 14 }}></div> : null}
          {resultsBlock}
        </SearchResultsContainer>
      </SearchResultScreen>
    );
  }, [generateResults, generateSearchHistory, pastSearches, results, searchState, searching]);

  return (
    <SearchBarWrapper focused={searchState !== NONE}>
      <SearchBarRow>
        <FlexRow style={{ alignItems: "center" }}>
          <Left style={{ alignItems: "center", display: "flex", whiteSpace: "nowrap" }}>
            <BackIcon
              onClick={() => navigate(-1)}
              src={BackArrowIcon}
              alt="Back to the Dashboard Icon"
            />
            <SearchBarInputBox>
              <img style={{ paddingRight: 15 }} src={SearchIcon} alt="magnifying glass icon" />
              <SearchBarInput
                id="product-searchbar"
                type="text"
                placeholder="Search items"
                value={searchTerm}
                onChange={handleOnChange}
                onKeyDown={handleEnter}
                onFocus={handleOnFocus}
                autoComplete="off"
                ref={SearchBarRef}
              />
            </SearchBarInputBox>
          </Left>
        </FlexRow>
      </SearchBarRow>
      {searchState !== NONE ? generateSearchResults() : null}
      {showDevModal && <DevModal setShowDevModal={setShowDevModal} versions={versions} />}
    </SearchBarWrapper>
  );
}

export default SearchBar;
