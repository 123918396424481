import { UskoCategory } from 'amazon';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import { category_titles } from '../categories/categories';

const CarouselTitle = styled.div`
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: -0.5px;
`;

type CategoryModalDropdownProps = {
  selected: UskoCategory | "All Categories",
  setSelected: Dispatch<SetStateAction<UskoCategory | "All Categories">>,

}

const CategoryModalDropdown = ({ selected, setSelected }: CategoryModalDropdownProps) => {
  const categoryKeys = Object.keys(category_titles);

  const options = useMemo(() => {
    const list = [
      <Dropdown.Item key={"All Categories"} eventKey={"All Categories"}>
        ⭐ All Categories
      </Dropdown.Item>
    ];

    categoryKeys.forEach((c)=> {
      const uskCKey = UskoCategory[c as keyof typeof UskoCategory]
        list.push(
          <Dropdown.Item key={c} eventKey={c}>
            {category_titles[uskCKey]}
          </Dropdown.Item>
        );
    });

    return list;
  }, [categoryKeys]);

  const handleOnSelect = useCallback((eventKey: any, event: any) => {
    setSelected(eventKey);
  }, [setSelected]);

  return <Dropdown onSelect={handleOnSelect}>
    <Dropdown.Toggle style={{all: 'unset', display: 'flex', alignItems: 'center'}}>
      <CarouselTitle>{selected ? selected : "All Categories"}</CarouselTitle>
    </Dropdown.Toggle>
    <Dropdown.Menu style={{ overflow: 'scroll', height: 558, padding: 15 }}>
      {options}
    </Dropdown.Menu>
  </Dropdown>
}

export default CategoryModalDropdown;