import { AmazonHelpers } from "../../../../amazon";
import { useContext, useMemo } from "react";
import { FlexRow, GraphCenter, GraphWindow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, PanelText, PanelBlurb, PanelBlurbHeroText, SmallHeading } from "../../styles/generics";
import { usd } from "./utils";

import AmazonContext from "components/Contexts/AmazonContext";
import FiveYearSpendChart from "./charts/FiveYearSpendChart";
import ViewAllArrow from "./buttons/ViewAllArrow";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SpendingText = styled(SmallHeading)`
`;

const SpendPrice = styled(PanelBlurbHeroText)`
`;

const SpendText = styled(PanelText)`
`;

const DeltaText = styled(PanelText)`
  padding-top: 5px;
`;

function FiveYearSpendPanel() {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const { years, data } = useMemo(() => {
    const ordersByYear = AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders());
    const years = Object.keys(ordersByYear)
      .map((year) => parseInt(year))
      .slice(-5);
    const data = years
      .map((year) => AmazonHelpers.CalculateSpending(ordersByYear[year]))
      .map((stats) => stats.totalSpending);
    return { years, data };
  }, [amazonHandler]);

  // Basic validation. Don't render if data and labels are not passed into the component.
  if (!data || !data.length) {
    console.log("WARNING: Missing {data} for FiveYearSpendPanel. Graph did not render.");
    return null;
  }
  // Basic validation. Don't render if data and labels are not passed into the component.
  if (!years || !years.length) {
    console.log("WARNING: Missing {years} for FiveYearSpendPanel. Graph did not render.");
    return null;
  }

  // Clamp indices in case we have less than 2 years
  let yearCount = years.length;
  let lastIdx = yearCount - 1;
  let secondToLastIdx = lastIdx - 1;
  if (secondToLastIdx < 0) {
    // means repeated total spending blurb for that single year
    // but whatever
    lastIdx = 0;
    secondToLastIdx = 0;
  }

  return <PanelWrapper>
    <FlexRow>
      <Left>
        <PanelTitle>Total Spent ({yearCount} Years)</PanelTitle>
      </Left>
      <Right
        onClick={() => navigate(`/spending/by-date/yearly`)}
      >
        <ViewAllArrow/>
      </Right>
    </FlexRow>
    <SpendingText>{usd.format(data.reduce((acc, n) => acc + n))}</SpendingText>
    <DeltaText>
      Total spending over the last { yearCount } years
    </DeltaText>
    <GraphWindow height={'150px'}>
      <GraphCenter height={'150px'}>
        <FiveYearSpendChart labels={years} tableData={data}/>
      </GraphCenter>
    </GraphWindow>
    <div style={{ display: 'flex', marginTop: 10 }}>
      <PanelBlurb>
        <SpendPrice color={'#5ECAD9'}>
          {usd.format(data[secondToLastIdx])}
        </SpendPrice>
        <SpendText>
          {years[secondToLastIdx]} Spent
        </SpendText>
      </PanelBlurb>
      <PanelBlurb>
        <SpendPrice color={'#6B6BD4'}>
          {usd.format(data[lastIdx])}
        </SpendPrice>
        <SpendText>
          {years[lastIdx]} Spent
        </SpendText>
      </PanelBlurb>
    </div>
  </PanelWrapper>
}

export default FiveYearSpendPanel;
