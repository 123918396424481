import { AmazonHelpers, ItemInflation } from "../../../../amazon"
import AmazonContext from "components/Contexts/AmazonContext";
import { Left, Right } from "components/App/Dashboard/panels/generics";
import SortIcon from 'icons/sort-icon.svg';

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useMemo, useState} from "react";
import {MobileEventHandler} from "../../../Bridge/MobileEventHandler";

enum sortKeys{
  InflationDesc = "Inflation",
  InflationAsc = "Deflation" ,
}

type SortFuncs = {
  [id in sortKeys] : (a : ItemInflation, b: ItemInflation) => number
}

const sortFuncs : SortFuncs = {
  [sortKeys.InflationDesc] : (a, b) => {
    return b.inflation - a.inflation;
  },
  [sortKeys.InflationAsc] : (a, b) => {
    return a.inflation - b.inflation;
  }
}


const InflationTable = styled.ul`
  padding: 16px;
  font-size: 14px;
`

const InflationItemRow = styled.li`
  display: flex;

  align-items: center;
  justify-content: space-between;

  border-bottom: 0.5px solid lightgrey;
  :last-child { border-bottom: none; }

  height: 66px;

  cursor: pointer;
`;

// TODO  combine with PurchasesStandAlonePanel
const InflationItemName = styled.div`
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.5px;

  max-width: calc(100vw - 160px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// TODO combine with PurchasesStandAlonePanel
const InflationItemImgWrapper = styled.div`
  width: 31px;
  height: 31px;
`;

// TODO combine with PurchasesStandAlonePanel
const InflationItemImg = styled.img`
  max-width: 31px;
  max-height: 31px;
  margin-right: 9px;
  border-radius: 100px;
  overflow: hidden;
`;

const InflationDateRange = styled.div`
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #8A7DD7;
`;

const InflationPct = styled.div`
  line-height: 39px;
  /* identical to box height, or 280% */
  letter-spacing: -0.7px;
  color: #8A7DD7;
`;


const InflationSelector = styled.select`
  all: unset;
  font-weight: 600;
  font-size: 14px;

  color: #6B6BD4;
`;


const InflationPanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  padding-bottom: 0px;

  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: -0.5px;
`;


const InsufficientData = <div>
  <InflationPanelTitle>Purchase Inflation</InflationPanelTitle>
  <InflationTable>
    <InflationItemRow>
      Insufficient data. Inflation calculations need multiple purchases of the same item at different prices.
    </InflationItemRow>
  </InflationTable>
</div>;

function panelTitle(setSortOption: any) {
  return (
      <InflationPanelTitle>
        <Left>
          Purchase Inflation
        </Left>
        <Right style={{ display: "flex", alignItems: "right" }}>
          { inflationSelectorRow(setSortOption) }
        </Right>
      </InflationPanelTitle>
  );
}

function inflationSelectorRow(setSortOption:any) {
    const selectOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSortOption(e.target.value as sortKeys);
    };
    const sortOptions = Object.keys(sortFuncs).map((opt) => {
      return <option key={opt} value={opt}>Sort By: {opt}</option>;
    })
    return <span>
        <img style={{paddingRight: 10}} src={SortIcon} alt="Sort Icon" />
        <InflationSelector onChange={selectOption}>
          {sortOptions}
        </InflationSelector>
    </span>;
}

function inflationRow(navigate : any, d : ItemInflation, i: number) {
  const asin = d.minOrder.asin;
  const img = AmazonHelpers.asin_to_image(asin, 250) || "";
  const title = d.minOrder.title || "Unknown"; // TODO make title non-optional with unknown as default
  const inflation = AmazonHelpers.numberFormat(Math.round(d.inflation));
  const dateRange = AmazonHelpers.formatInflationDateRange(d);

  return (
      <InflationItemRow key={"inflation_"+i} onClick={()=>navigate(`/product/${asin}`)}>
        <Left style={{ display: "flex", alignItems: "center" }}>
            <InflationItemImgWrapper>
              <InflationItemImg src={img} alt="Product" />
            </InflationItemImgWrapper>
            <div>
              <InflationItemName>{title}</InflationItemName>
              <InflationDateRange>{dateRange}</InflationDateRange>
            </div>
        </Left>
        <Right>
          <InflationPct>{inflation}%</InflationPct>
        </Right>
      </InflationItemRow>
  );
}

function InflationPage() {
  const navigate = useNavigate();
  const { amazonHandler } = useContext(AmazonContext);
  const data = useMemo(() => amazonHandler.getInflationTableProps(), [amazonHandler]);

  useEffect(() => {
    MobileEventHandler.analytics("inflation_screen_wv")
  }, [])

  const [sortOption, setSortOption] = useState(sortKeys.InflationDesc);
  const sorted = data.sort(sortFuncs[sortOption])
  if (sorted.length === 0) {
    return InsufficientData;
  }

  return (
    <div>
      { panelTitle(setSortOption) }

      <InflationTable>
        {sorted.map((d, i) => inflationRow(navigate, d, i))}
      </InflationTable>
    </div>
  );
}

export default InflationPage;
