import { UskoCategory } from "./categories"

export type Asin = string

export enum ItemStatus {
  Loading = '0',
  Categorizing = '1',
  Complete = '2',
}

export interface StatusItemCounterObj {
  [ItemStatus.Loading]: number;
  [ItemStatus.Categorizing]: number;
  [ItemStatus.Complete]: number;
}

export interface AmazonData {
  orderDate: Date;
  orderId?: string;
  title?: string;
  category: UskoCategory;
  seller?: string;
  quantity: number;
  // Total price including quantity multiplier and taxes
  price: number;
  // Price of a single product in the order
  pricePerUnit: number;
  currency?: string;
  unspsc: string;
  asin: Asin;
  status: ItemStatus;
  itemType: "GROCERY" | "STANDARD" | undefined
}

// TODO fill this out once we have reviews
export type AmazonReview = {
  asin: Asin;
  stars: number;
}

export type ProductPurchases = Map<Asin, AmazonData[]>

export interface OrderStats {
  orders: number;
  totalSpending: number;
}

export interface OrderStatsByMonth extends OrderStats {
  month: number;
}

export interface OrderStatsByYear extends OrderStats {
  year: number;
  averageCost: number
}

export interface ItemInflation {
  minOrder: AmazonData;
  maxOrder: AmazonData;
  inflation: number;
}

export type AmazonSort =
  | "id"
  | "orderDate"
  | "title"
  | "category"
  | "seller"
  | "quantity"
  | "price"
  | "currency";

export type SortOrder = "ASC" | "DESC";

export const EmptyOrder: AmazonData = {
  orderDate: new Date(),
  orderId: "",
  title: "",
  category: UskoCategory.Unknown,
  seller: "",
  quantity: 0,
  price: 0.0,
  pricePerUnit: 0.0,
  currency: "",
  unspsc: "",
  asin: "",
  status: ItemStatus.Complete,
  itemType: undefined
};
