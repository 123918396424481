import { useEffect, useRef } from "react";

import { MarketplaceItem } from "components/App/Marketplace/marketplaceTypes";
import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_SET_NATIVE_VARIABLE } from  "components/Bridge/outgoing_events";


import styled from "styled-components";
import { PanelWidthButton, PanelWidthColoredButton, SmallHeading, SmallText } from "../styles/generics";
import uskissaSearch from "images/uskissa-search.svg";

const ModalWrapper = styled.div<any>`
  width: 100vw;
  height: 100vh;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 2rem;
  background-color: rgba(0,0,0,0.4);
`;
const ModalCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5.4375rem;
  background-color: #fff;
  padding: 1rem 1rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
`;
const UskissaSearchImg = styled.img`
  width: 25%;
  padding: 0.25rem;
  border: 2px solid #7551CB;
  border-radius: 50%;
`;
const ModalMsg = styled.div`
`;
const ModalTitle = styled(SmallHeading)`
  text-align: center;
`;
const ModalBody = styled(SmallText)`
  text-align: center;
  padding-bottom: 1rem;
`;

type PurchaseConfirmationModalProps = {
  asin: string|undefined;
  movedToFG: boolean|undefined;
  marketplaceAllItems: MarketplaceItem[];
  setMovedToFG: (b: boolean|undefined) => void;
  setPendingBalance: React.Dispatch<React.SetStateAction<number|undefined>>;
  setShowConfetti: (b: boolean) => void;
}

function PurchaseConfirmationModal({ asin, movedToFG, marketplaceAllItems, setMovedToFG, setPendingBalance, setShowConfetti }: PurchaseConfirmationModalProps) {
  const modalWrapperRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if(modalWrapperRef.current && movedToFG) {
      modalWrapperRef.current.style.display = "flex";
    }
    if(modalWrapperRef.current && !movedToFG) {
      modalWrapperRef.current.style.display = "none";
    }
  },[movedToFG]);
  return(
    <ModalWrapper ref={modalWrapperRef}>
      <ModalCard>
        <UskissaSearchImg src={uskissaSearch} />
        <ModalMsg>
          <ModalTitle>Did you purchase the item?</ModalTitle>
          <ModalBody>If you purchased the item we will confirm purchase and credit your balance.</ModalBody>
        </ModalMsg>
        <PanelWidthColoredButton
          onClick={() => {
            setMovedToFG(undefined);
            setShowConfetti(true);
            const purchasedItem = [...marketplaceAllItems].find(item => item.asin === asin);
            // TODO: add outgoing event to signal native with item in payload
            if(purchasedItem && purchasedItem.uskoCoinValue) setPendingBalance(prevBalance => {
              const newPending = prevBalance ? prevBalance + parseInt(purchasedItem.uskoCoinValue) : parseInt(purchasedItem.uskoCoinValue);
              const sendData = {
                name: "pendingBalance",
                value: newPending.toString()
              };
              MobileEventHandler.post_event(WEBVIEW_SET_NATIVE_VARIABLE, sendData);
              return newPending;
            });
          }}
        >
          Yes
        </PanelWidthColoredButton>
        <PanelWidthButton
          onClick={() => {
            setMovedToFG(undefined);
          }}
        >
          No
        </PanelWidthButton>
      </ModalCard>
    </ModalWrapper>
  );
}

export default PurchaseConfirmationModal;
