import { useNavigate } from "react-router-dom";
import { AmazonHelpers } from "amazon";

import styled from "styled-components";
import { MicroCopy } from "../styles/generics";
import coin from "icons/coin.svg";
import star from "icons/star.svg";

const ItemCardWrapper = styled.div<any>`
  display: flex;
  width: 10rem;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #FFF;
`;
const ItemImg = styled.div<any>`
  width: 8.0rem;
  height: 8.0rem;
  border-radius: 0.5rem;
  background: url(${props => props.url ? props.url : ''});
  background-position: center;
  background-size: cover;
`;
const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const ItemDetailsTopRow = styled.div`
  display: flex;
  align-items: center;
  color: #4C4C4C;
  font-family: Inter;
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: -0.03125rem;
  line-height: 0.625rem;
  gap: 0.5rem;
`;
const ItemRating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
`;
const ItemDesc = styled(MicroCopy)`
  min-height: 2rem;
  height; 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1rem;
  letter-spacing: -0.03125rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ItemEarn = styled.div`
  height: 1.25rem;
  width: 100%;
  display: flex;
  padding: 0.125rem 0.3125rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: rgba(222, 137, 1, 0.10);
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
  letter-spacing: -0.03125rem;
`;
const ItemPrice = styled.div`
  width: 100%;
  color: #4C4C4C;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
  text-transform: lowercase;
`;
const CoinImg = styled.img`
  height: 0.60125rem;
  object-fit: cover;
  object-position: center;
  margin-right: 0.25rem;
`;
const StarImg = styled.img`
  height: 0.5rem;
  object-fit: cover;
  object-position: center;
  margin-right: 0.25rem;
`;

const uskoToUSDConversion = 0.025;

type ItemCardProps = {
  key: string;
  item: { [key: string]: string };
  innerStyle: any;
}

function ItemCard({ item, innerStyle }: ItemCardProps) {
  const navigate = useNavigate();

  return(
    <div style={{
      ...innerStyle
    }}>
      <ItemCardWrapper
        onClick={() => {
          navigate(`/redirect/marketplace/${item.asin}`);
        }}
      >
        <ItemImg url={item.imageEncodedString} />
        <ItemDetails>
          <ItemDetailsTopRow>
            {AmazonHelpers.priceFormat(parseFloat(item.price.replace(/[^\d.-]+/g, '')))}
            <ItemRating>
              <StarImg src={star} />
              {Number.parseFloat(item.averageRating).toFixed(1)}
            </ItemRating>
          </ItemDetailsTopRow>
          <ItemDesc>{item.productName}</ItemDesc>
        </ItemDetails>
        <ItemEarn>
          <CoinImg src={coin} />
          {`earn ${item.uskoCoinValue} USK`}
        </ItemEarn>
        <ItemPrice>{AmazonHelpers.priceFormat(Number.parseInt(item.uskoCoinValue) * uskoToUSDConversion)} back</ItemPrice>
      </ItemCardWrapper>
    </div>
  );
}

export default ItemCard;
