import { useEffect, useState, useMemo } from 'react';

import styled from "styled-components"
import { SmallText, TabLinkText } from "../../styles/generics";
import checkmark from "../../../../icons/checkmark.svg";
import LoadingIcon from "../panels/icons/LoadingIcon";

import { AmazonData, AmazonHelpers, ItemStatus, StatusItemCounterObj } from "amazon";

const IndicatorWrapper = styled.div<any>`
  position: sticky;
  bottom: 4.6rem;
  height: ${({ showIndicator }) => showIndicator ? '6.25rem' : '0rem'};
  width: calc(100vw - 2rem);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(107, 107, 212, 0.36);
  padding: ${({ showIndicator }) => showIndicator ? '0.75rem 1rem 1rem' : '0rem'};
  margin: ${({ showIndicator }) => showIndicator ? '1rem' : '0rem'};
  outline: ${({ showLastStepMsg, showOutline }) => showLastStepMsg || showOutline ? '2px solid #6b6bd4' : '0px solid #6b6bd4'};
  border-radius: 0.5rem;
  z-index: 99;
  -webkit-transition: 0.25s ease-in;
  -moz-transition: 0.25s ease-in;
  -o-transition: 0.25s ease-in;
  transition: 0.25s ease-in;
`;
const IndicatorContents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  width: 100%;
  gap: 0.5rem;
  padding: 0;
`;
const IndicatorTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`;
const IndicatorTitle = styled(TabLinkText)`
  letter-spacing: -0.035rem;
`;
const IndicatorBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  letter-spacing: -0.03125rem;
`;
const IndicatorItemImg = styled.img`
  width: 2.40625rem;
  height: 2.40625rem;
  object-position: center;
  object-fit: contain;
  border-radius: 3.125rem;
  background-color: #FFFFFF;
  margin-right: 0.5rem;
`;
const IndicatorItemDescription = styled(SmallText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: calc(100% - 8rem);
  max-width: 11.59375rem;
  overflow: hidden;
`;

const IndicatorDateCounterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 2.625rem;
  width: 5rem;
  text-align: right;
`;
const IndicatorCheckmark = styled.img`
  width: 2.40625rem;
  height: 2.40625rem;
  padding: 0.84375rem 0.59375rem 0.75rem 0.6875rem;
  background-color: #EFEDFF;
  border-radius: 6.25rem;
`;
const IndicatorItemDate = styled(TabLinkText)`
`;
const IndicatorItemCounter = styled(SmallText)`
`;
const IndicatorCompleteBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;
const IndicatorCompleteText = styled(SmallText)`
`;
const IndicatorLastStepText = styled(SmallText)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;
interface UpdateIndicatorProps {
  data: AmazonData|undefined;
  lastUpdate: Date|undefined;
  setShowUpdateFinish: (b: boolean) => void;
  statusItemCounter: StatusItemCounterObj;
  updateStatus: ItemStatus|undefined;
  updating: boolean;
  hasDataLoaded: boolean;
}

function UpdateIndicator({
  hasDataLoaded,
  data,
  lastUpdate,
  setShowUpdateFinish,
  statusItemCounter,
  updateStatus,
  updating
  } : UpdateIndicatorProps) {

  const [showIndicator, setShowIndicator] = useState(false);
  const [showLastStepMsg, setShowLastStepMsg] = useState(false);
  const [showOutline, setShowOutline] = useState(false);
  const [indicatorStatus, setIndicatorStatus] = useState<ItemStatus|undefined>(undefined);
  const [dataYears, setDataYears] = useState<number[]>([]);

  const dataDate = useMemo(() => {
    if(!data) return null;
    const tempDate = data.orderDate.toString().split(' ').slice(1,4);
    tempDate.splice(1,1);
    return tempDate.join(' ');
  }, [data]);

  // String representing range of years the incoming data is from
  const yearsRange = useMemo(() => {
    if(dataYears.length < 1) return '';
    if(dataYears.length === 1) return dataYears[0].toString();
    return (`${dataYears[0]}-${dataYears[dataYears.length - 1]}`);
  }, [dataYears]);

  const isComplete = data === undefined;

  useEffect(() => {
    if(data){
      if(updateStatus !== indicatorStatus) {
        setIndicatorStatus(updateStatus);
        if(updateStatus === ItemStatus.Complete){
          setShowLastStepMsg(true);
          setTimeout(() => setShowLastStepMsg(false), 5000);
        }
      }

      // if year of incoming data is not in dataYears, add it and sort the list (asc)
      if(dataYears.indexOf(data.orderDate.getFullYear()) < 0){
        setDataYears(prev => {
          const prevYears = [...prev];
          prevYears.push(data.orderDate.getFullYear());
          return prevYears.sort((a,b) => a - b);
        });
      }
    }
  },[data, dataYears, indicatorStatus, updateStatus]);

  useEffect(() => {
    if(updating && data && !showIndicator){
      setShowIndicator(true);
      setShowOutline(true);
      setTimeout(() => setShowOutline(false), 3000);
    }
    if(!updating && !data && showIndicator){
      // close indicator after set amount of time
      setTimeout(() => setShowIndicator(false), 3000);
      setTimeout(() => setShowUpdateFinish(true), 3000);
      setIndicatorStatus(undefined);
      setDataYears([]);
    }
  }, [data, showIndicator, setShowUpdateFinish, updating]);

  return(
    <>
    <IndicatorWrapper showIndicator={showIndicator && hasDataLoaded} showLastStepMsg={showLastStepMsg} showOutline={showOutline}>
      {!isComplete && !showLastStepMsg && hasDataLoaded ?
        <IndicatorContents>
          <IndicatorTitleRow>
            <IndicatorTitle>
              {indicatorStatus === ItemStatus.Loading && `Retrieving orders from ${yearsRange}`}
              {indicatorStatus === ItemStatus.Categorizing && `Retrieving item prices ${yearsRange}`}
              {indicatorStatus === ItemStatus.Complete && `Sorting into categories ${yearsRange}`}
            </IndicatorTitle>
            <LoadingIcon size={11} thickness={2} color={'#4C4C4C'} />
          </IndicatorTitleRow>
          <IndicatorBody>
            <IndicatorItemImg src={AmazonHelpers.asin_to_image(data.asin, 250)} />
            <IndicatorItemDescription>
              {data.title || "Unknown Item"}
            </IndicatorItemDescription>
            <IndicatorDateCounterSection>
              <IndicatorItemDate>
                {dataDate}
              </IndicatorItemDate>
              <IndicatorItemCounter>
                {indicatorStatus === ItemStatus.Loading
                  && `${AmazonHelpers.numberFormat(statusItemCounter[ItemStatus.Loading])} items`}
                {indicatorStatus === ItemStatus.Categorizing
                  && `${AmazonHelpers.numberFormat(statusItemCounter[ItemStatus.Categorizing])} items`}
                {indicatorStatus === ItemStatus.Complete
                  && `${AmazonHelpers.numberFormat(
                    statusItemCounter[ItemStatus.Loading] + statusItemCounter[ItemStatus.Categorizing])} left`}
              </IndicatorItemCounter>
            </IndicatorDateCounterSection>
          </IndicatorBody>
        </IndicatorContents>
        :
        null
      }
      {isComplete && showIndicator && hasDataLoaded ?
        <IndicatorContents>
          <IndicatorTitleRow>
            <IndicatorTitle>
              Dashboard is up to date with all purchase history.
            </IndicatorTitle>
          </IndicatorTitleRow>
          <IndicatorCompleteBody>
            <IndicatorCheckmark src={checkmark} />
            <IndicatorCompleteText>
              {`Last updated ${lastUpdate && lastUpdate.toLocaleString().split(',').slice(0,1)}.`}
            </IndicatorCompleteText>
          </IndicatorCompleteBody>
        </IndicatorContents>
        :
        null
      }
      {showLastStepMsg && !isComplete && hasDataLoaded ?
        <IndicatorContents>
          <IndicatorLastStepText>
            <span>Last step! <b>{statusItemCounter[ItemStatus.Categorizing]}</b> left to sort until your <b>Amazon shopping report</b></span>
          </IndicatorLastStepText>
        </IndicatorContents>
        :
        null
      }
    </IndicatorWrapper>
  </>
  );
}

export default UpdateIndicator;
