import { category_colors, category_titles } from "components/App/Dashboard/categories/categories";
import { usd } from "../utils";
import { UskoCategory } from "../../../../../amazon";
import { FlexRow, Left, Right } from "../generics";
import { MicroCopy } from "../../../styles/generics";

import styled from "styled-components";

const SpendingTableWrapper = styled.div`

`;

const SpendingCircle = styled.div<any>`
  width: 10px;
  height: 10px;

  border-radius: 100px;
  margin-right: 8px;

  background: ${props => props.color};
`;

const SpendingText = styled(MicroCopy)`
`;

const SpendingPrice = styled(MicroCopy)`
`;

type SpendingTableChartProps = {
  labels: UskoCategory[],
  tableData: number[]
}

function SpendingTableChart({ labels, tableData }: SpendingTableChartProps) {
  const SpendingRow = (label: UskoCategory, data: number, index: number) => {
    return <FlexRow key={`spending_` + index}>
      <Left style={{ display: 'flex', alignItems:'center', marginBottom: 5, marginTop: 5 }}>
        <SpendingCircle color={category_colors[label]}/>
        <SpendingText>
          {category_titles[label]}
        </SpendingText>
      </Left>
      <Right style={{ display: 'flex', alignItems: 'center'}}>
        <SpendingPrice>
          {usd.format(data)}
        </SpendingPrice>
      </Right>
    </FlexRow>
  }

  return <SpendingTableWrapper>
    {labels.map((l, i) => SpendingRow(l, tableData[i], i))}
  </SpendingTableWrapper>
}

export default SpendingTableChart;
