import { InteractionItem } from "chart.js";
import { MONTHLY, WEEKLY, YEARLY } from "components/App/UniquePages/Charts/SpendingComparisonView";
import { useCallback, useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getStepSize } from "../utils";
import { GRAPH_NUMBER } from "../../../styles/generics";

type WeeklySpendingChartProps = {
  labels: string[],
  tableData: {[id: string]: number[]},
  type: string,
  year?: number
}

function WeeklySpendingHorizontalChart({labels, tableData, type, year}: WeeklySpendingChartProps) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y1: {
        grid: {
          display: false,
        },
        ticks: {
          crossAlign: 'far' as const,
          font: {
            size: GRAPH_NUMBER,
          },
        }
      },
      x1: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          stepSize: getStepSize(tableData.thisYear.concat(tableData.lastYear)),
          callback: function(value: any) {
            return '';
          },
        }
      }
    },
    indexAxis: 'y' as const
  }

  const data = {
    labels,
    datasets: tableData.lastYear.length ? [
      {
        backgroundColor: '#8A7DD7',
        barThickness: 15,
        barPrecentage: 0.5,
        borderColor: '#00000000',
        borderRadius: 100,
        borderWidth: 2,
        data: tableData.thisYear,
      }
    ]:
    [
      {
        backgroundColor: '#8A7DD7',
        barThickness: 15,
        barPrecentage: 0.5,
        borderColor: '#00000000',
        borderRadius: 100,
        borderWidth: 2,
        data: tableData.thisYear,
      },
    ]
  };
  
  const chartRef = useRef();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const handleYearly = useCallback((activeElement: InteractionItem) => {
    const year = activeElement.datasetIndex ? 
      parseInt(labels[activeElement.index]) - 5
      : 
      parseInt(labels[activeElement.index]);
    const url = `/spending/by-date/${MONTHLY}`;
    params.delete(`year`);
    params.append(`year`, year.toString());

    navigate(url + '?' + params.toString());
  }, [labels, navigate, params]);

  const handleMonthly = useCallback((activeElement: InteractionItem) => {
    const yearSelected = (year || new Date().getFullYear()) - activeElement.datasetIndex;
    const month = activeElement.index;

    const start = new Date(yearSelected, month, 1);
    const end = new Date(yearSelected, month + 1, 0);

    const q = new URLSearchParams();
    q.append(`startDate`, start.toISOString().split('T')[0].replaceAll('-', '/'));
    q.append(`endDate`, end.toISOString().split('T')[0].replaceAll('-', '/'));

    const url = `/purchases`;
    navigate(url + '?' + q.toString());
  }, [navigate, year]);

  const handleWeekly = useCallback((activeElement: InteractionItem) => {
    const yearSelected = (year || new Date().getFullYear()) - activeElement.datasetIndex;
    const label = labels[activeElement.index];

    const [startStr, endDay] = label.split('-');
    const [month, startDay] = startStr.split(' ');

    const start = new Date(`${yearSelected} ${month} ${startDay}`);
    const end = new Date(`${yearSelected} ${month} ${parseInt(endDay) + 1}`);

    const q = new URLSearchParams();
    q.append(`startDate`, start.toISOString().split('T')[0].replaceAll('-', '/'));
    q.append(`endDate`, end.toISOString().split('T')[0].replaceAll('-', '/'));

    const url = `/purchases`;
    navigate(url + '?' + q.toString());
  }, [labels, navigate, year]);

  const onClick = useCallback((event: any) => {
    if (!chartRef.current) return;
    
    const activeElement = getElementAtEvent(chartRef.current, event)[0];
    if (type === YEARLY) handleYearly(activeElement);
    if (type === MONTHLY) handleMonthly(activeElement);
    if (type === WEEKLY) handleWeekly(activeElement);
  }, [handleMonthly, handleWeekly, handleYearly, type]);

  return <Bar 
    ref={chartRef}
    options={options} 
    data={data}
    onClick={onClick}
  />
}

export default WeeklySpendingHorizontalChart;
