import { AmazonHelpers, ItemStatus } from "amazon";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FlexRow, Left, Right } from "./generics";
import { PanelWrapper, PanelTitle, SmallText, LoadingPricePill } from "../../styles/generics";

import styled from "styled-components";
import AmazonContext from "components/Contexts/AmazonContext";
import ViewAllArrow from "./buttons/ViewAllArrow";

const PurchasesWrapper = styled(PanelWrapper)`
  padding: 0;
`;

const PurchasesPanel = styled.div`
  margin-top: 5px;

  padding: 16px;

  left: 16px;

  background: #ffffff;
  border-radius: 15px;
`;


// for linting. uncomment when ready to use again
const PurchaseWeekText = styled(PanelTitle)`
  // identical to box height
  letter-spacing: -0.03125rem;
`;

const PurchasesItemRow = styled.div<any>`
  display: flex;

  align-items: center;
  justify-content: space-between;

  border-top: 0.5px solid lightgrey;
  :first-child { border-top: none; }

  height: 66px;

  cursor: pointer;

  opacity: ${props => props.status ===  ItemStatus.Complete ? '1' : '0.75'};
`;

const PurchaseItemImgWrapper = styled.div`
  width: 31px;
  height: 31px;
`;

const PurchaseItemImg = styled.img`
  max-width: 31px;
  max-height: 31px;
  margin-right: 9px;
  border-radius: 100px;
  overflow: hidden;
`;

const PurchaseItemName = styled(SmallText)`
  margin-left: 10px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.5px;

  max-width: calc(100vw - 160px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PurchasePrice = styled(SmallText)`
  font-weight: 700;
  line-height: 39px;
  /* identical to box height, or 280% */
  letter-spacing: -0.7px;
`;

const PurchasePricePill = styled(LoadingPricePill)`
  width: 2.5rem;
`;

function RecentPurchasesPanel() {
  const { amazonHandler } = useContext(AmazonContext);
  const navigate = useNavigate();
  const data = useMemo(() => amazonHandler.getRecentPurchaseProps(), [amazonHandler]);

  // Basic validation. Don't render if data and labels are not passed into the component.
  if (!data) {
    console.log("WARNING: Missing {data} for PurchasesStandAlonePanel. Graph did not render.");
    return null;
  }

  return (
    <PurchasesWrapper>
      <PurchasesPanel>
        {/* <PurchasesPanelLoadingBar>
          <LoadingIcon size={14} thickness={2} />
        <span style={{paddingLeft: 5}}>Gathering your most recent purchases...</span>
        </PurchasesPanelLoadingBar> */}
        <FlexRow>
          <Left>
            <PurchaseWeekText>Recent Purchases</PurchaseWeekText>
          </Left>
          <Right
              onClick={() => {
                navigate('/purchases');
              }}
          >
            <ViewAllArrow />
          </Right>
        </FlexRow>
        <div>
          {data.map((purchase, i) => (
            <PurchasesItemRow
              key={"purchase_" + i}
              onClick={() => navigate(`/product/${purchase.asin}`)}
              status={purchase.status}
            >
              <Left style={{ display: "flex", alignItems: "center" }}>
                <PurchaseItemImgWrapper>
                  <PurchaseItemImg src={purchase.img} alt="Product" />
                </PurchaseItemImgWrapper>
                <PurchaseItemName>{purchase.name}</PurchaseItemName>
              </Left>
              <Right style={{ marginLeft: "auto" }}>
                {purchase.status === ItemStatus.Loading && purchase.price === 0 ?
                  <PurchasePricePill />
                  :
                  <PurchasePrice>{AmazonHelpers.priceFormat(purchase.price)}</PurchasePrice>}
              </Right>
            </PurchasesItemRow>
          ))}
        </div>
      </PurchasesPanel>
    </PurchasesWrapper>
  );
}

export default RecentPurchasesPanel;
