import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 8.22878px;
  background: #EFEDFF;
  border-radius: 82.2878px;
  margin-bottom: 16px;
`;

const ButtonText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

const ButtonIcon = styled.img`
  padding-left: 8px;
`;

type SettingButtonProps = {
  text: string
  icon: string
  alt: string
}

function SettingButton({ text, icon, alt }: SettingButtonProps) {
  return <ButtonWrapper>
    <ButtonText>
      {text}
    </ButtonText>
    <ButtonIcon src={icon} alt={alt}/>
  </ButtonWrapper>;
}

export default SettingButton;