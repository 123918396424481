import {useContext, useEffect, useMemo, useState} from "react";
import { ViewWrapper } from "./generics";
import styled from "styled-components";

import AmazonContext from "components/Contexts/AmazonContext";
import { AmazonHelpers } from "../../../../amazon";
import { GraphCenter, GraphWindow } from "components/App/Dashboard/panelsv2/generics";
import { SubHeading } from "../../styles/generics";
import SpendingTrendChart from "components/App/Dashboard/panelsv2/charts/SpendingTrendChart";
import SpendingTrendTableChart from "components/App/Dashboard/panelsv2/charts/SpendingTrendTableChart";
import {MobileEventHandler} from "../../../Bridge/MobileEventHandler";

const num_of_years = 5;

const colors = ['#6B6BD4', '#5ECAD9', '#F7BB8B', '#79B94B', '#D79AFF'];

const ViewTitle = styled(SubHeading)`
  line-height: 1.36125rem;

  padding-left: 1rem;
  padding-right: 0.625rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;


function SpendingTrendView() {
  const { amazonHandler } = useContext(AmazonContext);
  const [onClicks, setOnClicks] = useState<(() => void)[]>([]);

  const years = useMemo(() => amazonHandler.getYears().reverse().slice(0, num_of_years).reverse(), [amazonHandler]);

  useEffect(() => {
    MobileEventHandler.analytics("spending_by_trend_wv")
  }, [])

  const { spending, trend } = useMemo(() => {
    const yearData = AmazonHelpers.SplitOrdersByDay(amazonHandler.getOrders());

    const trend: number[][] = years.map(() => []);
    const spending: number[] = years.map(() => 0);

    for (let day = 0; day < 365; day ++) {
      years.forEach((y, index) => {
        spending[index] += AmazonHelpers.CalculateSpending(yearData[y][day] || []).totalSpending;
        trend[index].push(spending[index]);
      });
    }

    years.forEach((y, index) => {
      if (yearData[y][365]) {
        spending[index] += AmazonHelpers.CalculateSpending(yearData[y][365] || []).totalSpending;
      }
      trend[index].push(spending[index]);
    });

    return { spending, trend };
  }, [amazonHandler, years]);

  return <ViewWrapper>
    <ViewTitle>Yearly Spending: {years[0]} - {years[years.length-1]}</ViewTitle>
    <GraphWindow height={'200px'}>
      <GraphCenter height={'200px'} width={`calc(100vw - 32px)`}>
        <SpendingTrendChart
          labels={years}
          chartData={trend}
          colors={colors}
          setOnClicks={setOnClicks}
        />
      </GraphCenter>
    </GraphWindow>
    <SpendingTrendTableChart labels={years} spending={spending} trend_colors={colors} onClicks={onClicks}/>
  </ViewWrapper>;
}

export default SpendingTrendView;
