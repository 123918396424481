import { Asin } from "../../../../amazon";
import { AmazonHelpers } from "amazon";
import AmazonContext from "components/Contexts/AmazonContext";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PanelTitle, SmallText, MicroCopy } from "../../styles/generics";
import { StandAlonePanel } from "./generics";

const HighlightsPanelTitle = styled(PanelTitle)`
  letter-spacing: -0.03125rem;

  padding-left: 1rem;

  padding-bottom: 1.3125rem;
`;

const HighlightsRowWrapper = styled.div`
  display: flex;

  padding-left: 1rem;

  width: 100vw;

  overflow: scroll;
`;

const Highlight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 12px;

  width: 139px;
  height: 143px;

  background: #FFFFFF;
  border-radius: 15px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  margin-right: 16px;
`;

const HighlightItemTitle = styled(SmallText)`
  font-weight: 700;
  line-height: 1.0625rem;
  /* identical to box height */
  letter-spacing: -0.03125rem;
`;

const HighlightItemImg = styled.img`
  max-height: 40px;
  max-width: 130px;
`;

const HighlightPriceText = styled(SmallText)`
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.5px;
`;

const HighlightItemName = styled(MicroCopy)`
  letter-spacing: -0.5px;

  text-overflow: ellipsis;
  white-space: nowrap;
  width: 119px;
  overflow: hidden;
`;

const HighlightText = styled.div`
  display: flex;
  flex-direction: column;
`;

type HighlightPanelProps = {
  category: string,
  asin: Asin,
  img: string,
  price: number,
  item: string
}

function HighlightPanel({
  category,
  asin,
  img,
  price,
  item
}: HighlightPanelProps) {
  const navigate = useNavigate();
  return <Highlight onClick={() => navigate(`/product/${asin}`)}>
    <HighlightItemTitle>{category}</HighlightItemTitle>
    <HighlightItemImg src={img}/>
    <HighlightPriceText>{AmazonHelpers.priceFormat(price)}</HighlightPriceText>
    <HighlightItemName>{item}</HighlightItemName>
  </Highlight>
}

function HighlightsStandAlonePanel() {
  //#TODO get rest of highlights
  const { amazonHandler } = useContext(AmazonContext);
  const data = useMemo(() => amazonHandler.getHighlightsPanelProps(), [amazonHandler]);
  const { orders, totalSpending} = useMemo(() => amazonHandler.getTotalSpending(), [amazonHandler]);
  const years = useMemo(() => amazonHandler.getYears(), [amazonHandler]);

  // Basic validation. Don't render if data and labels are not passed into the component.
  if (!data) {
    console.log("WARNING: Missing {data} for HighlightsStandAlonePanel. Graph did not render.");
    return null;
  }

  return <StandAlonePanel>
    <HighlightsPanelTitle>Purchase Highlights</HighlightsPanelTitle>
    <HighlightsRowWrapper>
      {
        data.map(({
          category,
          asin,
          img,
          price,
          item
        }, i) => <HighlightPanel
          key={'highlight_' + i}
          category={category}
          asin={asin}
          img={img}
          price={price}
          item={item}
        />)
      }
      <Highlight>
        <HighlightText>
          You've made <div>{orders}</div> lifetime purchases!
        </HighlightText>
      </Highlight>
      <Highlight>
        <HighlightText>
          You've spent <div>{AmazonHelpers.priceFormat(totalSpending)}</div> in lifetime purchases!
        </HighlightText>
      </Highlight>
      <Highlight>
        <HighlightText>
          You've spent <div>{years.length}</div> years on amazon!
        </HighlightText>
      </Highlight>
    </HighlightsRowWrapper>
  </StandAlonePanel>
}

export default HighlightsStandAlonePanel;
