import { useMemo } from "react";
import styled from "styled-components";

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1rem;
`;
const IndicatorDotBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  min-width: 2.5rem;
`;
const Dot = styled.div<any>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  ${props => props.active ?
    'background-color: #6B6BD4;' : 'background-color: #C2C3C3;'}
`;
const RTriangle = styled.div<any>`
  height: 0.5rem;
  width: 1rem;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 50% 50%);
  ${props => props.active ?
    'background-color: #C2C3C3;' : 'background-color: #FFF;'}
`;
const LTriangle = styled.div<any>`
  height: 0.5rem;
  width: 1rem;
  clip-path: polygon(50% 0, 50% 50%, 50% 100%, 0% 50%);
  ${props => props.active ?
    'background-color: #C2C3C3;' : 'background-color: #FFF;'}
`;

type CarouselIndicatorProps = {
  currentSlide: number;
  totalSlides: number;
}

function CarouselIndicator({ currentSlide, totalSlides }: CarouselIndicatorProps) {
  const numDots = useMemo(() => {
    if(totalSlides < 3){
      return new Array(totalSlides).fill(<Dot />);
    }
    return new Array(3).fill(<Dot />);
  }, [totalSlides]);

  return(
    <IndicatorWrapper>
      <>
        {(currentSlide > 2 && currentSlide !== totalSlides) ? <LTriangle active={true} /> : <LTriangle />}
        <IndicatorDotBox>
          {numDots.map((e, i) => {
            if((currentSlide === 1 && i === 0) ||
              (totalSlides === 2 && currentSlide === 2 && i === 1) ||
              (currentSlide === totalSlides && i === 2) ||
              (currentSlide !== 1 && currentSlide !== totalSlides && i === 1)) {
              return <Dot active={true} key={`dot_${i}`}/>;
            }
            return <Dot key={`dot_${i}`}/>;
          })}
        </IndicatorDotBox>
        {(currentSlide < totalSlides - 1 && currentSlide !== 1) ? <RTriangle active={true} /> : <RTriangle />}
      </>
    </IndicatorWrapper>
  );
}

export default CarouselIndicator;
