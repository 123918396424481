import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div``;
export const Right = styled.div``;

export const GraphWindow = styled.div<any>`
  position: relative;
  display: flex;

  align-items:center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  height: ${props => props.height || '225px'};
  
  ${props => props.disabled ? "pointer-events: none;" : ""}

  margin-top: 19px;
`;

export const GraphCenter = styled.div<any>`
  canvas {
    height: ${props => props.height || '330px'} !important;
    width: ${props => props.width || `calc(100vw - 68px)`} !important;
    max-width: 400px;
  }
`;

export const ChartSelect = styled.select`
  background: #FFFFFF;
  border: 1.55px solid #B8C3E9;
  border-radius: 35px;
  
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  width: 86.25px;
  height: 35.5px;
  padding: 6.75px;
`;

export const NoStyleSelect = styled.select`
  all: unset;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3125rem;
`;

export const DisableContentsContainer = styled.div`
  pointer-events: none;
`;
