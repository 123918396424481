import { Dispatch, SetStateAction, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { getStepSize, months } from '../utils';
import { GRAPH_NUMBER } from "../../../styles/generics";

type SpendingForecastLineChartProps = {
  labels: number[],
  chartData: number[][],
  colors: string[],
  setOnClicks: Dispatch<SetStateAction<(() => void)[]>>
}

function SpendingTrendChart({labels, chartData, colors, setOnClicks}: SpendingForecastLineChartProps) {
  const data: any = useMemo(() => ({
    labels: [...Array(366).keys()],
    datasets: labels.map((l, i) => {
      return {
        label: l,
        data: chartData[i],
        borderColor: colors[i],
        backgroundColor: colors[i]
      };
    }),
  }), [labels, chartData, colors]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      },
      elements: {
        point: {
          radius: 0
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: false,
          },
          ticks: {
            stepSize: Math.round(365/12),
            callback: (value: any, index:any, ticks:any) => {return months[Math.round(value * 12/365)]},
          }
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            stepSize: getStepSize(labels.map((l, i) => chartData[i][365])),
            callback: (value:any, index:any, ticks:any) => {return '$' + value},
            crossAlign: 'far' as const,
            font: {
              size: GRAPH_NUMBER,
            },
          }
        }
      }
    }
  }, [chartData, labels]);

  const plugins = useMemo(() => [
    {
      afterDraw: (chart: any, args: any, options: any) => {

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        const onClicks = items.map((item: any) => {
          return () => {
            const {type} = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            }
            chart.update();
          };
        });

        setOnClicks(onClicks);
      }
    }
  ], [setOnClicks]);
  return <Line
    options={options}
    data={data}
    //@ts-ignore
    plugins={plugins}
  />;
}

export default SpendingTrendChart;
