import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MobileEventHandler } from '../../Bridge/MobileEventHandler';
import { APP_MOVED_TO_FOREGROUND } from "../../Bridge/incoming_events";
import { WEBVIEW_MARKETPLACE_CREATE_REFERRAL_LINK } from "../../Bridge/outgoing_events";
import { MarketplaceItem } from "components/App/Marketplace/marketplaceTypes";

import PurchaseConfirmationModal from "./PurchaseConfirmationModal";

import styled from 'styled-components';
import { PanelWidthButton, PanelWidthColoredButton, SmallHeading } from '../styles/generics';
import BackArrowIcon from 'icons/back-arrow-icon.svg';
import searchingMarketplace from 'images/searchingMarketplace.gif';
import uskissaFail from 'images/uskissa-fail.svg';
import uskissaSuccess from 'images/uskissa-success.svg';
import RedirectPageConfetti from 'lotties/RedirectPageConfetti';

const RedirectPageWrapper = styled.div`
  position: fixed;
  top: 3.664375rem;
  left: 0px;
  background-color: #F6F5FF;
  width: 100%;
  height: 100%;
  max-height: 100%;

  // react boostrap buttons seem to hold some value of z-index greater than 1;
  z-index: 9999;
`;
const NavRow = styled.div`
  height: 3.125rem;
  display: flex;
  align-items: center;
  padding: 0.71875rem 0.9375rem;
  gap: 0.5rem;
`;
const BackArrowImg = styled.img`
`;
const RedirectBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.9375rem;
`;
const MessageCard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 0.5rem;
  opacity: 0.9;
  background: #FFF;
  margin-top: 5.4375rem;
  padding: 1rem;
`;
const MessageTitle = styled(SmallHeading)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03125rem;
  text-align: center;
`;
const MessageBody = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: -0.03125rem;
  text-align: center;
`;
const BackToMarketplaceButton = styled(PanelWidthButton)`
  color: #6B6BD4;
  border: 1px solid #6B6BD4;
`;
const SearchingMarketplaceImg = styled.img`
  height: 15.875rem;
  margin-bottom: 4rem;
`;
const UskissaSuccessImg = styled.img`
  padding: 2.5rem;
`;
const UskissaFailImg = styled.img`
  padding: 2.5rem;
`;

type RedirectPageProps = {
  loadingMarketplaceLink: boolean|undefined;
  marketplaceAllItems: MarketplaceItem[];
  setLoadingMarketplaceLink: (b: boolean|undefined) => void;
  setPendingBalance: React.Dispatch<React.SetStateAction<number|undefined>>;
}

function RedirectPage({ loadingMarketplaceLink, marketplaceAllItems, setLoadingMarketplaceLink, setPendingBalance }: RedirectPageProps) {
  const { asin } = useParams();
  const navigate = useNavigate();
  const [movedToFG, setMovedToFG] = useState<boolean|undefined>(undefined);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  useEffect(() => {
    MobileEventHandler.add_handler(APP_MOVED_TO_FOREGROUND.type, (data) => {
      setMovedToFG(JSON.parse(data.foreground));
    }, APP_MOVED_TO_FOREGROUND.type);
    return () => {
      MobileEventHandler.remove_handler(APP_MOVED_TO_FOREGROUND.type, APP_MOVED_TO_FOREGROUND.type);
    }
  },[]);

  useEffect(() => {
    if(asin) {
      MobileEventHandler.post_event(WEBVIEW_MARKETPLACE_CREATE_REFERRAL_LINK, {
        asin: asin
      });
    }
  },[asin]);

  useEffect(() => {
    return(() => {
      setLoadingMarketplaceLink(undefined);
    });
  },[setLoadingMarketplaceLink]);

  return(
    <RedirectPageWrapper>
      {showConfetti && <RedirectPageConfetti setShowConfetti={setShowConfetti} />}
      <RedirectBody>
        <MessageCard>
          <MessageTitle>
            {loadingMarketplaceLink === undefined && `Redirecting to Amazon`}
            {loadingMarketplaceLink === true && `Thanks for shopping with Usko!`}
            {loadingMarketplaceLink === false && `Something went wrong!`}
          </MessageTitle>
          <MessageBody>
            {loadingMarketplaceLink === undefined && `We are negotiating the best price before we send you to Amazon`}
            {loadingMarketplaceLink === true && `If you purchased this item on Amazon, we will update your Usko credits with your cash back amount in the next 2 weeks.`}
            {loadingMarketplaceLink === false && `Sorry, we can't access the best deal right now. Try back in a few minutes.`}
          </MessageBody>
          {loadingMarketplaceLink === undefined &&
            <SearchingMarketplaceImg src={searchingMarketplace} />}
          {loadingMarketplaceLink === true &&
            <UskissaSuccessImg src={uskissaSuccess} />}
          {loadingMarketplaceLink === false &&
            <UskissaFailImg src={uskissaFail} />}
          {loadingMarketplaceLink === true &&
            <BackToMarketplaceButton onClick={() => navigate(-1)}>
              Back to Marketplace
            </BackToMarketplaceButton>}
          {loadingMarketplaceLink === false &&
            <PanelWidthColoredButton
              onClick={() => {
                setLoadingMarketplaceLink(undefined);
                MobileEventHandler.post_event(WEBVIEW_MARKETPLACE_CREATE_REFERRAL_LINK, {
                  asin: asin
                });
              }}
            >
              Try again
            </PanelWidthColoredButton>}
        </MessageCard>
      </RedirectBody>
      <PurchaseConfirmationModal asin={asin} marketplaceAllItems={marketplaceAllItems} movedToFG={movedToFG} setMovedToFG={setMovedToFG} setPendingBalance={setPendingBalance} setShowConfetti={setShowConfetti} />
    </RedirectPageWrapper>
  );
}

export default RedirectPage;
