import { useState } from "react";

import { MobileEventHandler } from "../../Bridge/MobileEventHandler";
import { WEBVIEW_REQUEST_EXPORT_DEBUG_LOGS, WEBVIEW_REQUEST_DEBUG_HTML } from "../../Bridge/outgoing_events";

import styled from "styled-components";
import { PanelWidthButton, PanelWidthColoredButton, SmallText } from "../styles/generics";

const DevModalWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
`;
const DevModalCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  min-height: 25rem;
  width: 70%;
  padding: 2rem 1rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
`;
const DevModalCardResults = styled(SmallText)`
`;
const DevModalCardButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface DevModalProps{
  setShowDevModal: (b: boolean) => void;
  versions: {[key: string]: any};
}

function DevModal({ setShowDevModal, versions }: DevModalProps){
  const [results, setResults] = useState('');

  return(
    <DevModalWrapper>
      <DevModalCard>
        <DevModalCardResults>
          {`app version: ${versions.appVersion}`}<br/>
          {`dashboard version: ${versions.dashboardVersion}`}<br/>
          {`axe version: ${versions.axeVersion}`}<br/>
          {`eula version: ${versions.eulaVersion}`}<br/>
          {`environment: ${versions.environment}`}<br/>
          {`usko registered at: ${versions.uskoRegisteredAt}`}<br/>
          {results}
        </DevModalCardResults>
        <DevModalCardButtonRow>
          <PanelWidthColoredButton
            onClick={() => MobileEventHandler.post_event(WEBVIEW_REQUEST_EXPORT_DEBUG_LOGS)}
          >
            Export Debug Logs
          </PanelWidthColoredButton>
          <PanelWidthColoredButton
            onClick={() => MobileEventHandler.post_event(WEBVIEW_REQUEST_DEBUG_HTML)}
          >
            Export Debug Share Sheet
          </PanelWidthColoredButton>
          <PanelWidthButton
            onClick={() => setShowDevModal(false)}
          >
            Exit Dev Options
          </PanelWidthButton>
        </DevModalCardButtonRow>
      </DevModalCard>
    </DevModalWrapper>
  );
}

export default DevModal;
