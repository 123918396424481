import styled from "styled-components";
import { SmallHeading, SubHeading } from "../styles/generics";
import EarningsIcon from "icons/dynamic-icons/EarningsIcon";
import uskoLogo from "../../../images/usko-light.png";
import {useEffect} from "react";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const EarnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-family: "Inter", sans-serif;
  background: linear-gradient(180deg, #9d7de9 -3.25%, #6890fa 45.4%);
  text-align: left;
  line-height: 1.3;
	z-index: -1;
  h2 {
    padding: 0.5rem 1rem;
  }
  p {
    padding: 0.5rem 1rem;
  }
`;
const EarnRoadMapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin-bottom: 2rem;
`;
const EarnRoadMapBorderLeft = styled.div`
  position: absolute;
  left: 2.3rem;
  border-left: 7px dashed #38c8bf;
  height: 95%;
  pointer-events: none;
`;
const EarnRow = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: center;
  z-index: 0;
`;
const EarnRoadMapTitle = styled(SmallHeading)`
  margin-left: 5rem;
  margin-bottom: 1rem;
`;
const EarnRoadmapSubtitle = styled(SubHeading)`
  margin-bottom: 0.5rem;
`;
const EarnNumber = styled.div`
  border-radius: 3rem;
  height: 3rem;
  width: 3rem;
  background-color: #fff;
  color: #6f8df7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 700;
  flex-shrink: 0;
  margin-right: 2rem;
`;
const EarnLogo = styled.img`
  margin: 2rem 1rem;
  width: 10rem;
`;

const ROADMAP_DATA = [
  { title: "Amazon Purchase Analytics", text: "Spend money smarter with detailed reports of what you buy." },
  { title: "In-app Messaging", text: "Opt in to receive specialized promotions from brands you love" },
  { title: "Uskoin Token mint", text: "Limited users will gain early access to our Web 3 features" },
  { title: "Self-Monetization Tools", text: "Profit directly from your data by exchanging it for Uskoins" },
  { title: "Crypto Withdrawals", text: "Turn your Uskoins into real money in crypto marketplaces" },
  { title: "Full Launch", text: "All features will be made available to every user" },
];

function Earn() {
  useEffect(() => {
    MobileEventHandler.analytics("earn_wv")
  }, [])
  return (
    <EarnWrapper className="Earn">
      <EarnLogo src={uskoLogo} alt="usko logo" />
      <EarningsIcon />
      <h2>Usko is the first platform that allows you to earn money based on your verified purchase history.</h2>
      <p>
        Earning with Usko is super simple! Brands pay to send you messages. You control how often you’d like to receive
        them, and whether you want to interact with the brand.
      </p>
      <EarnRoadMapWrapper>
        <EarnRoadMapBorderLeft />
        <EarnRoadMapTitle>Road Map</EarnRoadMapTitle>
        {ROADMAP_DATA.map((val, idx) => {
          return (
            <EarnRow key={idx}>
              <EarnNumber>{idx + 1}</EarnNumber>
              <div>
                <EarnRoadmapSubtitle>{val.title}</EarnRoadmapSubtitle>
                <div>{val.text}</div>
              </div>
            </EarnRow>
          );
        })}
      </EarnRoadMapWrapper>
    </EarnWrapper>
  );
}

export default Earn;
