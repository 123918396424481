import {useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { NormalText } from "../../styles/generics";
import {MobileEventHandler} from "../../../Bridge/MobileEventHandler";

type ChartSettingsProps = {
  options: {url: string, text: string}[]
}

const Row = styled(NormalText)`
  display: flex;

  align-items: center;

  padding-left: 32px;

  border-bottom: 1px solid #E4E4E4;

  height: 56px;
`;

function ChartSettings({options}:ChartSettingsProps) {
  const navigate = useNavigate();
  const rows = useMemo(() => {
    return options.map((o) => {
      return <Row onClick={() => navigate(o.url)}>
        {o.text}
      </Row>
    });
  }, [navigate, options])

  useEffect(() => {
    MobileEventHandler.analytics("spending_by_date_filter_wv")
  }, [])

  return <>
    {rows}
  </>;
}

export default ChartSettings;
