import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { PanelWrapper, SubHeading, SmallText, PanelWidthColoredButton } from "../../styles/generics";
import ExitIconButton from "../../../../icons/dynamic-icons/ExitIconButton";
import Confetti from "../../../../lotties/Confetti";

const UpdateFinishCardWrapper = styled(PanelWrapper)<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #9D7DE9 0%, #6890FA 100%);
  border-radius: 0.5rem;
  height: ${({ showCard }) => showCard ? '14.9375rem' : '0rem'};
  overflow: hidden;
  gap: 1rem;
  -webkit-transition: 0.25s ease-in;
  -moz-transition: 0.25s ease-in;
  -o-transition: 0.25s ease-in;
  transition: 0.25s ease-in;
  ${({ showCard }) => showCard ?
  ``
  :
  `padding: 0;
  margin: 0 1rem;`}
`;
const UpdateFinishTitleRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const UpdateFinishTitleText = styled(SubHeading)`
  width: 17.375rem;
  color: #FFFFFF;
  letter-spacing: -0.03125rem;
`;
const UpdateFinishBody = styled(SmallText)`
  width: 100%;
  color: #FFFFFF;
  letter-spacing: -0.03125rem;
`;
const UpdateFinishButton = styled(PanelWidthColoredButton)`
  height: 3.25rem;
  margin-top: auto;
`;

interface UpdateFinishCardProps{
  showUpdateFinish: boolean;
  setShowUpdateFinish: (b: boolean) => void;
}

function UpdateFinishCard({ showUpdateFinish, setShowUpdateFinish }: UpdateFinishCardProps){
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCard, setShowCard] = useState(false);

  // shows card, starts confetti animation and removes after animation finishes
  // to allow for card interaction
  useEffect(() => {
    if(showUpdateFinish){
      setShowConfetti(true);
      setShowCard(true);
      setTimeout(() => setShowConfetti(false), 4000);
    }
  }, [showUpdateFinish]);

  return(
    <UpdateFinishCardWrapper showCard={showCard}>
      {showConfetti && <Confetti/>}
      <UpdateFinishTitleRow>
        <UpdateFinishTitleText>
          Hooray! Your Amazon shopping report is here.
        </UpdateFinishTitleText>
        <ExitIconButton
          color={'#FFFFFF'}
          bgColor={'#B9A6FD'}
          onClick={() => {
            setShowCard(false);
            setShowUpdateFinish(false)
          }}
        />
      </UpdateFinishTitleRow>
      <UpdateFinishBody>
        What's your most shopped category? Your Amazon shopping history is now synced.
        Check out your shopping report to learn more about your habits.
      </UpdateFinishBody>
      <UpdateFinishButton
        onClick={() => navigate('/full-shopping-report')}
      >
        Let's go!
      </UpdateFinishButton>
    </UpdateFinishCardWrapper>
  );
}

export default UpdateFinishCard;
