
import { AmazonData, AmazonReview } from "../amazon";
import { Message, Expr, Env, runQuery, isQueryNoMatch, isQueryError, MessageStates, MessageInteractions } from ".";

import * as events from '../components/Bridge/outgoing_events';
import { MobileEventHandler } from '../components/Bridge/MobileEventHandler';

export function processMessages(messages:Message[], amazonPurchases: AmazonData[], amazonReviews:AmazonReview[]): Message[] {
  const env : Env = { amazonReviews, amazonPurchases };
  // for rejected / errors we want to see if more data or a reload might have improved things
  const statesToProcess = [ MessageStates.Unprocessed, MessageStates.Rejected, MessageStates.Errored ];
  const toProcess = messages.filter(m => statesToProcess.includes(m.state));
  if (toProcess.length === 0) {
    return [];
  }
  const matched : Message[] = [];
  const rejected : Message[] = [];
  // TODO closure capture from env for res
  const results : { id: string, res: Expr }[] = [];
  toProcess.forEach(m => {
    const res = runQuery(m.query, env);
    if (isQueryNoMatch(res)) {
      m.state = MessageStates.Rejected;
      rejected.push(m);
    } else if (isQueryError(res)) m.state = MessageStates.Errored;
    else if (res.kind === "msg"){
      m.state = MessageStates.Matched;
      m.res = res;
      results.push({id: m.id, res: res});
      matched.push(m);
    } else {
      console.log("Unsure how to handle result of ", res.kind);
      m.state = MessageStates.Errored;
    }
  });

  // post evaluations to backend
  if (matched.length > 0 || rejected.length > 0) {
    // backend expects { matches: [ids], nonMatches: [ids] } so construct that
    const r = {
      results, api: {
        matches: matched.map(m => m.id),
        nonMatches: rejected.map(m => m.id),
      },
    };
    MobileEventHandler.post_event(events.WEBVIEW_MESSAGES_EVALUATED, r);
  }

  const states = [ MessageStates.Matched, MessageStates.Opened, MessageStates.Read ];
  return messages.filter(m => states.includes(m.state));
}

function messageFromNative(data:any) : Message {
  // TODO validate more of the received query structure

  const emptyQuery = {
    desc: "empty query",
    cond: { kind: "prim", p: false },
    msg:  { kind: "msg", message: "none" },
    vMajor: 0, vMinor: 0,
  }
  const id = data.id ? data.id : "";
  const query = data.query ? data.query : emptyQuery;
  let state = MessageStates.Unprocessed;
  let res = undefined;
  if (id !== "" && query.desc !== "empty query" && data.result) {
    state = MessageStates.Matched;
    res = data.result;
  }

  let createdAt = new Date(0);
  if (data.createdAt) {
    createdAt = new Date(data.createdAt * 1000);
  }
  let openedAt = undefined;
  if (data.opened && state === MessageStates.Matched) {
    state = MessageStates.Opened;
    openedAt = new Date(data.opened);
  }
  if (data.claimed && state === MessageStates.Opened) {
    state = MessageStates.Read;
  }
  let interactions: MessageInteractions = {
    clicks: [],
    views: []
  };
  if (data.interactions && state >= MessageStates.Opened) {
    interactions = data.interactions;
  }
  let active = false;
  if (data.active) {
    active = true;
  }
  let credits = 0;
  if (data.credits && typeof data.credits === "string") {
    credits = Number(data.credits);
  }

  return {
    id,
    query,
    state,
    interactions,
    active,
    createdAt,
    openedAt,
    credits,
    res,
    icons: data.query?.icons,
    heroImg: data.query?.heroImg,
    productImg: data.query?.productImg
  }
}

export function nativeMessagesToWebView(data:any[]) : Message[] {
  return data.map(d => messageFromNative(d));
}

export function postOpened(m:Message){
  const r = {id: m.id};
  MobileEventHandler.post_event(events.WEBVIEW_MESSAGE_OPENED, r);
}

export function postClaimed(m:Message){
  const r = {id: m.id};
  MobileEventHandler.post_event(events.WEBVIEW_MESSAGE_CLAIMED, r);
}
export function postClick(m:Message, url:string){
  const when = new Date().getTime();
  m.interactions.clicks.push({when, url});
  const r = { id: m.id, payload: m.interactions };
  MobileEventHandler.post_event(events.WEBVIEW_MESSAGE_INTERACTED, r);
}
export function postSurvey(m:Message, a:string, f?:string){
  m.interactions.survey = {answer:  a, feedback: f};
  const r = {id: m.id, payload: m.interactions };
  MobileEventHandler.post_event(events.WEBVIEW_MESSAGE_INTERACTED, r);
}
export function postView(m:Message, startDate:Date){
  const start = startDate.getTime();
  const end = new Date().getTime();
  m.interactions.views.push({when: start, duration: end - start});
  const r = { id: m.id, payload: m.interactions };
  MobileEventHandler.post_event(events.WEBVIEW_MESSAGE_INTERACTED, r);
}
