import { RELEVANT_INCOMING_EVENTS } from "./incoming_events";
import {ANALYTICS, WEBVIEW_CLICK_LINK} from "./outgoing_events";

export class MobileEventHandler {
  static consume_event(payload: any): void {
    try {
      const { type, data } = typeof payload === "string" ? JSON.parse(payload) : payload;
      const handlers = RELEVANT_INCOMING_EVENTS[type]?.handlers || [];

      handlers.forEach((h) => h.handler(data));
    } catch (e) {
      console.error(`\nParsing mobile event error\n`);
      console.error(e);
    }
  }

  static analytics(name: string, data: Record<string, string> | undefined = undefined) {
    MobileEventHandler.post_event(ANALYTICS, {
      name,
      params: data
    })
  }

  static post_event(event_type: string, data: any | undefined = undefined): void {
    const { UskoBridge, webkit } = window;
    const payload = { event_type, data };

    if (UskoBridge) UskoBridge.postMessage(JSON.stringify(payload));
    if (webkit) webkit.messageHandlers.webViewHandler.postMessage(JSON.stringify(payload));
    if (!UskoBridge && !webkit) {
      // probably running locally with dev nodejs
      console.log("POSTING EVENT:", event_type, "WITH DATA", JSON.stringify(data));
      if (event_type === WEBVIEW_CLICK_LINK) {
        window.location.href = data;
      }
    }
  }

  // Adds a handler, with a string label identifier.
  // If label already exists, replace the handler instead.
  static add_handler(type: string, f: (data: any) => void, label: string) {
    if (!RELEVANT_INCOMING_EVENTS[type]) {
      console.log(`type: ${type}  is not a relevant event being listened to.`);
      return;
    }

    const index = RELEVANT_INCOMING_EVENTS[type].handlers.map((h) => h.label).indexOf(label);

    // If label is not in list.
    if (index === -1) {
      RELEVANT_INCOMING_EVENTS[type].handlers.push({
        handler: f,
        label
      });
      return;
    }

    RELEVANT_INCOMING_EVENTS[type].handlers[index].handler = f;
  }

  // Removes the handler wit hthe given label identifier.
  static remove_handler(type: string, label: string) {
    if (!RELEVANT_INCOMING_EVENTS[type]) {
      console.log(`type: ${type}  is not a relevant event being listened to.`);
      return;
    }

    // Fetch Handler Index.
    const index = RELEVANT_INCOMING_EVENTS[type].handlers.map((h) => h.label).indexOf(label);

    if (index === -1) {
      console.log(`type: ${type} does not have the relevant label: ${label}`);
      return;
    }

    RELEVANT_INCOMING_EVENTS[type].handlers.splice(index, 1);
  }

  static reset_handlers() {
    Object.keys(RELEVANT_INCOMING_EVENTS).forEach((type) => {
      RELEVANT_INCOMING_EVENTS[type].handlers = [];
    })
  }
}
