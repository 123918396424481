import { AmazonHelpers, UskoCategory } from "amazon";
import AmazonContext from "components/Contexts/AmazonContext";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PanelWrapper, PanelTitle, SmallText } from "../../styles/generics";
import { category_titles, load_image } from "../categories/categories";

const PurchaseHighlightsPanelWrapper = styled(PanelWrapper)`
  padding: 0;
`;

const HighlightWrapper = styled.div`
  background: #F9F9FF;
  border-radius: 15px;

  flex-grow: 1;
  width: 50%;
  max-width: 50%;

  padding: 8px;

  border: 8px solid #ffffffff;
`;

const HighlightTitle = styled(PanelTitle)`
  padding: 1rem 1rem 0 1rem;
`;

const HighlightItemImg = styled.img`
  max-height: 50px;
  max-width: 130px;

  margin-bottom: 9px;
`;

const HighlightItemText = styled(SmallText)`
  line-height: 17px;
`;

const HighlightItemTitle = styled(HighlightItemText)`
  font-weight: 700;
`;

const HighlightItemName = styled(HighlightItemText)`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type HighlightItemProps = {
  category: string,
  img: string,
  price: number,
  item: string,
  query: string
}

function HighlightItem({
  category,
  img,
  price,
  item,
  query
}: HighlightItemProps) {
  const navigate = useNavigate();
  return <HighlightWrapper
    onClick={() => navigate(`/purchases?${query}`)}
  >
    <HighlightItemImg src={img} />
    <HighlightItemTitle>{category}</HighlightItemTitle>
    <HighlightItemText>{AmazonHelpers.priceFormat(price)}</HighlightItemText>
    <HighlightItemName>{item}</HighlightItemName>
  </HighlightWrapper>
}

function PurchaseHighlightsPanel() {
  const { amazonHandler } = useContext(AmazonContext);
  const data = useMemo(() => amazonHandler.getHighlightsPanelProps(), [amazonHandler]);
  const [cat, cat_spend] = useMemo(() => {
    const catData = amazonHandler.getCategoriesSpendingTableProps();
    let cat:UskoCategory = UskoCategory.Unknown;
    let spend = 0;
    [...catData.entries()].forEach(([k, v]) => {
      if (v > spend && k !== UskoCategory.Unknown && k !== UskoCategory.Processing){
        spend = v;
        cat = k;
      }
    });
    return [cat, spend];
  }, [amazonHandler]);

  const cat_img = useMemo(() => load_image(category_titles[cat]), [cat]);
  const unknown_img = load_image(category_titles[UskoCategory.Unknown]);

  const categoryLinks : {[id : string]: string} = useMemo(() => ({
    'Largest Purchase': 'sort=Most Expensive',
    'Most Purchased': 'sort=Most Purchased',
    'First Purchased': 'sort=Oldest',
    'Largest Category': `category=${encodeURIComponent(cat)}`
  }), [cat]);

  return <PurchaseHighlightsPanelWrapper>
    <HighlightTitle>
      Purchase Highlights
    </HighlightTitle>
    <div style={{ display: 'flex', padding: 8, flexWrap: 'wrap' }}>
      {
        data.map(({
          category,
          asin,
          img,
          price,
          item
        }, i) => <HighlightItem
          key={'highlight_' + i}
          category={category}
          img={asin ? img : unknown_img}
          price={price}
          item={item}
          query={categoryLinks[category]}
        />)
      }
      <HighlightItem
        category={'Largest Category'}
        img={cat_img}
        price={cat_spend}
        item={cat}
        query={categoryLinks['Largest Category']}
      />
    </div>
  </PurchaseHighlightsPanelWrapper>;
}

export default PurchaseHighlightsPanel;
