import styled from "styled-components";
import Lottie from "react-lottie";
import confettiData from "./data/confetti";

const ConfettiWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
`;

function Confetti(){
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: confettiData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return(
    <ConfettiWrapper>
      <Lottie
        options={animationOptions}
        width={360}
        height={223}
        isClickToPauseDisabled={true}
      />
    </ConfettiWrapper>
  );
}

export default Confetti;
