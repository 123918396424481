import { useEffect } from "react";
import { MobileEventHandler } from "components/Bridge/MobileEventHandler";
import { WEBVIEW_CLICK_LINK } from "components/Bridge/outgoing_events";
import styled from "styled-components";

import ExitIconButton from "../../../icons/dynamic-icons/ExitIconButton";
import { MicroCopy, PanelWrapper, SmallText } from "../styles/generics";
import CarouselPanel from "../styles/CarouselPanel";

const NotificationWrapper = styled(PanelWrapper)<any>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  min-width: calc(100vw - 2rem);
  padding-bottom: 2rem;
  margin: 0 1rem;
`;
const NotificationTitleRow = styled(SmallText)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-weight: 700;
`;
const NotificationMessageWrapper = styled(MicroCopy)`
  width: calc(100% - 1.5rem);
`;
const NotificationMessageLinkSpan = styled.a`
`;

type Notification = {
  title: string;
  message: JSX.Element;
}

interface NotificationCarouselProps {
  showNotificationCarousel: boolean;
  setShowNotificationCarousel: (b: boolean) => void;
  versions: {[key: string]: any};
}

const notificationStack: Notification[] = [
  {
    title: "Earn Usko Credits",
    message: <NotificationMessageWrapper>
        Click the Discover tab to purchase products that pay you up to 18% cash back. Stay tuned for information on how to cash out your credits later this month. 
        <br/><br/>
        Join our&nbsp;
        <NotificationMessageLinkSpan
          onClick={() =>
            MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://www.instagram.com/p/CxV67TQLPV-/?igshid=MzRlODBiNWFlZA==')
          }
        >
          Instagram
        </NotificationMessageLinkSpan>
        &nbsp;or&nbsp;
        <NotificationMessageLinkSpan
          onClick={() =>
            MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://x.com/uskoapp/status/1703841276037038253?s=46&t=RvStx_uGtEiOKVRRbP8COg')
          }
        >
          Twitter
        </NotificationMessageLinkSpan>
        &nbsp;group to stay tuned!
      </NotificationMessageWrapper>
  },
  {
    title: "Get $25 to shop on Amazon!",
    message:<NotificationMessageWrapper>
      <NotificationMessageLinkSpan
        onClick={() =>
          MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://lnkd.in/eSw7GfJJ')
        }
      >
        Schedule
      </NotificationMessageLinkSpan>
      &nbsp;a 15-minute feedback call with our team and receive a $25 gift card after the call.
      <br/><br/>
      Please join our community on&nbsp;
      <NotificationMessageLinkSpan
        onClick={() =>
          MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://www.facebook.com/groups/1325954855023508')
        }
      >
        Facebook
      </NotificationMessageLinkSpan>
      &nbsp;or&nbsp;
      <NotificationMessageLinkSpan
        onClick={() =>
          MobileEventHandler.post_event(WEBVIEW_CLICK_LINK, 'https://discord.gg/etJmt2KBzG')
        }
      >
        Discord
      </NotificationMessageLinkSpan>
    </NotificationMessageWrapper>
  },
];

function NotificationCarousel({ showNotificationCarousel, setShowNotificationCarousel, versions }: NotificationCarouselProps) {
  useEffect(() => {
    if(versions.referralBrand) notificationStack.unshift(
      {
        title: `Welcome ${versions.referralBrand} customer!`,
        message: <NotificationMessageWrapper>
            We've added 200 credits (~$5) to your account. Click the offers tab to see your balance and to read messages that will earn you more credits
            <br/>
            <br/>
            Join our next Usko Cash Out Day for a chance to exchange your credits for gift cards.
          </NotificationMessageWrapper>
      }
    );

    return () => {
      if(versions.referralBrand) notificationStack.shift();
    }
  }, [versions]);

  return(
    <>
      {showNotificationCarousel ?
        <CarouselPanel numSlides={notificationStack.length}>
          <>
            {notificationStack.map((notification, i) => {
              return(
                <NotificationWrapper key={`notification_${i}`}>
                  <ExitIconButton
                    absolute={true}
                    color={'#6B6BD4'}
                    bgColor={'#EFEDFF'}
                    onClick={() => setShowNotificationCarousel(false)}
                  />
                  <NotificationTitleRow>
                    {notification.title}
                  </NotificationTitleRow>
                  {notification.message}
                </NotificationWrapper>
              );
            })}
          </>
        </CarouselPanel>
        :
        null
      }
    </>
  );
}

export default NotificationCarousel;
