import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { TabLinkText } from "../styles/generics";

const InboxFilterWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1.5rem 1rem 0.774375rem;
  width: 100%;
  border-bottom: 0.5px solid #4c4c4c4c;
  text-align: center;
`;

const InboxFilterTotals = styled.div<any>`
  color: ${props => props.name === "earned" ? 'white' : '#4C4C4C' };
  background: ${props => props.name === "earned" ? 'linear-gradient(90deg, #7090F3, #9481E4)'  : '#EFEDFF'};
  border-bottom-left-radius: 0.742988rem;
  border-bottom-right-radius: 0.742988rem;
  border-top: 1px solid whitesmoke;
`

const InboxFilterCredits = styled.span`
  font-weight: normal;
`
// removed 'missed' from filterOptions as it has been deprecated, logic is present if use returns
const filterOptions = ['available', 'earned'];
const FilterOption = styled(TabLinkText)<any>`
  background-color: white;
  color: #4C4C4C;
  border-radius: 0.742988rem;
  border: ${props => props.selected ? '1px solid #6B6BD4' : '1px solid #EFEDFF'};
  flex-grow: 1;
`;

const StatusIndicator = styled.div`
  display:inline-block;
  border-radius:100%;
  width: 0.475rem;
  height: 0.475rem;
  vertical-align: middle;
  margin-right: 0.4rem;
`

const AvailableStatusIndicator = styled(StatusIndicator)`
  border: 0.1rem solid ${props => props.color};
  border-right: 0.245rem solid ${ props => props.color};
`

const MissedStatusIndicator = styled(StatusIndicator)`
  border: 0.1rem solid ${props => props.color};
`

const EarnedStatusIndicator = styled(StatusIndicator)`
  background-color: ${props => props.color};
`

interface InboxFilterProps {
  selected: string | undefined;

  availableTotal: number;
  earnedTotal: number;
  missedTotal: number;

  availableColor: string;
  earnedColor: string;
  missedColor: string;
}

function InboxFilter(props: InboxFilterProps) {
  const navigate = useNavigate();

  const { availableTotal, earnedTotal, missedTotal } = props;
  const totals : { [key:string]:number} = {
    "available": availableTotal,
    "earned": earnedTotal,
    "missed": missedTotal,
  };

  const { availableColor, missedColor, earnedColor } = props;

  const indicator : {[key:string]:any} = {
    'available': <AvailableStatusIndicator color={availableColor}>&nbsp;</AvailableStatusIndicator>,
    'earned': <EarnedStatusIndicator color={earnedColor}>&nbsp;</EarnedStatusIndicator>,
    'missed':<MissedStatusIndicator color={missedColor}>&nbsp;</MissedStatusIndicator>,
  };

  const display : {[key:string]:string} = {
    'available':'Available',
    'earned':'Earned',
    'missed':'Missed',
  }

  const { selected } = props;

  return(
    <InboxFilterWrapper>
      {filterOptions.map((option, i) => {
        return (
          <FilterOption
            key={'InboxFilterOption_' + i}
            selected={(option === selected)}
            onClick={() => {
              (option === selected) ?
              navigate('/messages/filter/all')
              :
              navigate(`/messages/filter/${option}`)
            }}
          >
            {indicator[option]}
            {display[option]}

            <InboxFilterTotals name={option}>{totals[option]} <InboxFilterCredits>Credits</InboxFilterCredits></InboxFilterTotals>
          </FilterOption>
        )
      })}
    </InboxFilterWrapper>
  );
}

export default InboxFilter;
