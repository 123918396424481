import { UskoCategory } from "../../../../amazon";

//@ts-ignore
const templates = require.context('icons/Category Icons Assets/', true, /\.(svg)$/);

export function load_image(type: string) {
  try {
    return templates(`./${type}.svg`);
  }
  catch {
    return null;
  }
}

export const category_colors: {[id in UskoCategory]: string} = {
  [UskoCategory.Appliances]: "#FEBDCE",
  [UskoCategory.Apps]: "#FCC79D",
  [UskoCategory.Arts]: "#ABCDEF", // TODO pick the color
  [UskoCategory.Automotive]: "#9BE2EC",
  [UskoCategory.Baby]: "#BACDFF",
  [UskoCategory.Beauty]: "#B3E291",
  [UskoCategory.Books]: "#AAD5FF",
  [UskoCategory.CDs]: "#DADD7A",
  [UskoCategory.Cells]: "#E3ADFD",
  [UskoCategory.Clothing]: "#F7BB8B",
  [UskoCategory.Collectibles]: "#DCCFFF",
  [UskoCategory.Computers]: "#9BDEE2",
  [UskoCategory.Electronics]: "#B6CAFF",
  [UskoCategory.Garden]: "#91EAFD",
  [UskoCategory.Groceries]: "#F3CCE6",
  [UskoCategory.Handmade]: "#FEDCBA", // TODO pick the color
  [UskoCategory.Health]: "#B3E4C1",
  [UskoCategory.Home]: "#E4A6E2",
  [UskoCategory.Industrial]: "#C4ABFF",
  [UskoCategory.Kindle]: "#BBE5FB",
  [UskoCategory.Luggage]: "#B3F1CC",
  [UskoCategory.Movies]: "#D1F274",
  [UskoCategory.Musical]: "#FFB6B6",
  [UskoCategory.Office]: "#FFD6E0",
  [UskoCategory.Pet]: "#E6DEC1",
  [UskoCategory.Sports]: "#E2B7FE",
  [UskoCategory.Tools]: "#EDEDB4",
  [UskoCategory.Toys]: "#B0F8C4",
  [UskoCategory.Video]: "#CCF3EA",
  [UskoCategory.Processing]: "#808080",
  [UskoCategory.Other]: '#C4C4C4',
  [UskoCategory.Unknown]: '#FDDDAD'
};

export const category_titles: {[id in UskoCategory]: string} = {
  [UskoCategory.Appliances]: "⏲ Appliances",
  [UskoCategory.Apps]: "👾 Apps & Games",
  [UskoCategory.Arts]: "🎨"+ UskoCategory.Arts,
  [UskoCategory.Automotive]: "🚗 Automotive Parts & Accessories",
  [UskoCategory.Baby]: "👶 Baby",
  [UskoCategory.Beauty]: "💄 Beauty and Personal Care",
  [UskoCategory.Books]: "📚 Books",
  [UskoCategory.CDs]: "💿 CD & Vinyls",
  [UskoCategory.Cells]: "📱 Cell Phones & Accessories",
  [UskoCategory.Clothing]: "👚 Clothing, Shoes, Jewelry",
  [UskoCategory.Collectibles]: "🖼 Collectibles & Fine Art",
  [UskoCategory.Computers]: "💻 Computers",
  [UskoCategory.Electronics]: "📷 Electronics",
  [UskoCategory.Garden]: "🌱 Garden & Outdoors",
  [UskoCategory.Groceries]: "🛒 Grocery & Gourmet Food",
  [UskoCategory.Handmade]: "✂️" + UskoCategory.Handmade,
  [UskoCategory.Health]: "🏥 Health, Household & Baby Care",
  [UskoCategory.Home]: "🏠 Home & Kitchen",
  [UskoCategory.Industrial]: "📈 Industrial & Scientific",
  [UskoCategory.Kindle]: "📖 Kindle",
  [UskoCategory.Luggage]: "✈️ Luggage & Travel Gear",
  [UskoCategory.Movies]: "🎬 Movies & TV",
  [UskoCategory.Musical]: "🎵 Musical Instruments",
  [UskoCategory.Office]: "📌 Office Products",
  [UskoCategory.Pet]: "🐶 Pet Supplies",
  [UskoCategory.Sports]: "⚽️ Sports & Outdoors",
  [UskoCategory.Tools]: "🛠 Tools & Home Improvement",
  [UskoCategory.Toys]: "🤖 Toys & Games",
  [UskoCategory.Video]: "🎮 Video Games",
  [UskoCategory.Processing]: "🌀 Processing",
  [UskoCategory.Other]: "✨ Other",
  [UskoCategory.Unknown]: "❓ Unknown"
};
