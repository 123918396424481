import * as csvParse from "papaparse";
import * as Types from "../types";
import { ItemStatus, StatusItemCounterObj } from "../types";
import * as Categories from "./categories";
import { AmazonHelpers } from "./helpers";

export class AmazonHandler {
  private amazonData: Types.AmazonData[] = [];
  private years: number[] = [];
  private products: Types.ProductPurchases = new Map();

  private constructor(
    amazonData: Types.AmazonData[],
    years: number[],
    products: Types.ProductPurchases,
  ) {
    this.amazonData = amazonData;
    this.years = years;
    this.products = products;
  }

  static newAmazonHandler(csv: string): AmazonHandler {
    const ah = new AmazonHandler([], [], new Map());
    ah.loadCSV(csv);
    return ah;
  }

  static loadAmazonHandler(amazonData: Types.AmazonData[]): AmazonHandler {
    const products: Types.ProductPurchases = new Map();
    const years: number[] = [];
    if (!amazonData) return new AmazonHandler([], years, products);
    amazonData.forEach((o) => {
      const year = o.orderDate.getFullYear();
      if (!years.find((y) => y === year)) {
        years.push(year);
      }

      // build products map if asin exists
      if (o.asin !== undefined) {
        const prevPurchases = products.get(o.asin);
        if (prevPurchases === undefined) {
          products.set(o.asin, [o]);
        } else {
          prevPurchases.push(o);
        }
      }
    });

    return new AmazonHandler(amazonData, years, products);
  }

  // this is horrible, a fake copy that still mutates the underlying data
  // javascript array API is garbage. needed for react
  appendAmazonData(csv: string): [Types.AmazonData[], AmazonHandler] {
    const ah = new AmazonHandler(this.amazonData, this.years, this.products);
    const data = ah.loadCSV(csv);
    return [data, ah];
  }

  // TODO better error handling all around; this could leave us in a half-loaded state which is bad
  private loadCSV(csv: string): Types.AmazonData[] {
    const orders : Types.AmazonData[] = [];
    try {
      const jsonArray: any = csvParse.parse(csv, {
        skipEmptyLines: true,
        header: true,
        delimiter: ",",
      });
      const isFullUpdate = this.amazonData.length === 0;

      for (const data of jsonArray.data) {
        const [currency, price] = AmazonHelpers.CsvDollarsToCurrency(data["Item Total"] || "$0");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, pricePerUnit] = AmazonHelpers.CsvDollarsToCurrency(
          data["Purchase Price Per Unit"] || "$0",
        );
        const date = new Date(data["Order Date"] || "1970/1/1");
        const year = date.getFullYear();
        const asin = data["ASIN/ISBN"] || ("" as Types.Asin);
        const unspsc = data["UNSPSC Code"] || "";
        const status: Types.ItemStatus = data["itemInternalStatus"] || Types.ItemStatus.Complete;

        if (!this.years.find((y) => y === year)) {
          this.years.push(year);
        }

        const order: Types.AmazonData = {
          orderId: data["Order ID"],
          orderDate: date,
          category: Categories.categoryLookup(unspsc),
          title: data.Title,
          quantity: Number(data.Quantity),
          price: price,
          pricePerUnit: pricePerUnit,
          seller: data.Seller,
          currency: currency,
          unspsc: unspsc,
          asin: asin,
          status: status,
          itemType: data["itemType"]
        };

        // Gift card reloads would double-count later spending so ignore those
        // nb: tt would be better to match by ASIN but unfortunately
        // there seem to be several and not clear which one(s) are used
        if (order.title === "Amazon.com Gift Card Balance Reload") {
          continue;
        }

        function updateOrder<Key extends keyof Types.AmazonData>(p: Types.AmazonData, key: Key, value: Types.AmazonData[Key]) {
          p[key] = value;
        }
        let orderUpdated = false;

        // build products map if asin exists
        if (order.asin !== undefined) {
          const prevPurchases = this.products.get(order.asin);
          if (prevPurchases === undefined) {
            this.products.set(order.asin, [order]);
          } else {
            // Check if an order ID exists for given asin
            // If so, replace the item. Otherwise, append the item
            ///
            // Always append if doing a full update, because
            // CSVs may have repeated (order ID, asin) items.
            // CSVs are canonical and we trust those, so always append.
            const p = prevPurchases.find(p => p.orderId === order.orderId);
            if (isFullUpdate || p === undefined) prevPurchases.push(order);
            else {
              let k : keyof Types.AmazonData;
              for (k in p) {
                updateOrder(p, k, order[k]);
              }
              orderUpdated = true;
            }
          }
        }

        if (order.itemType === "GROCERY") {
          const idx = orders.findIndex(it => it.orderId === order.orderId && it.asin.startsWith("GROCERY_ITEM_"))
          if (idx > -1) orders.splice(idx, 1)
        }

        orders.push(order);

        // Don't append if an existing order has been updated in-place
        if (!orderUpdated) {
          const idx = this.amazonData.findIndex(it => it.orderId === order.orderId && it.asin.startsWith("GROCERY_ITEM_"))
          if (idx > -1) this.amazonData.splice(idx, 1)
          this.amazonData.push(order);
        }
      }

    } catch (e) {
      console.error(`\nParsing data error\n`);
      console.error(e);
    }
    return orders;
  }

  sliceDateRange(from: Date, to: Date): AmazonHandler {
    const orders: Types.AmazonData[] = [];
    const products: Types.ProductPurchases = new Map();

    const yearSet: Map<number, boolean> = new Map();
    const fromMs = from.getTime(),
      toMs = to.getTime();
    const fullOrders = this.getOrders();

    // TODO this is super slow, make it fast
    // (search for endpoints over ordered list, then run over only that)
    fullOrders.forEach((order: Types.AmazonData) => {
      const t = order.orderDate.getTime();
      if (t < fromMs || t > toMs) {
        return;
      }
      yearSet.set(order.orderDate.getFullYear(), true);
      orders.push(order);

      const prevPurchases = products.get(order.asin);
      if (prevPurchases === undefined) {
        products.set(order.asin, [order]);
      } else {
        prevPurchases.push(order);
      }
    });

    return new AmazonHandler(orders, [...yearSet.keys()], products);
  }

  getOrders() {
    return this.amazonData;
  }

  getOrdersByCategory(category: Types.UskoCategory) {
    return this.getOrders().filter((o) => o.category === category);
  }

  getOrdersByAsin(asin: string): Types.AmazonData[] {
    return this.products.get(asin) || [];
  }

  sortOrders(sort: Types.AmazonSort, direction: Types.SortOrder = "DESC", agg?: boolean) {
    const orders = agg ? [...this.products.entries()].map(([k, v]) => {
      const o: Types.AmazonData = { ...v[0] };
      o.quantity = v[0].quantity;
      o.price = v[0].price;

      v.slice(1).forEach((order: Types.AmazonData) => {
        o.quantity += order.quantity;
        o.price += order.price;
      });
      return o;
    })
    : this.getOrders();
    return orders.sort(AmazonHelpers.SortOrders(sort, direction));
  }

  getOrdersByDate() {
    return this.sortOrders("orderDate");
  }

  getStatsByMonth(): Types.OrderStatsByMonth[] {
    return AmazonHelpers.FormatStatsByMonth(this.getOrdersByDate());
  }

  getYears() {
    return this.years.map((y) => y)?.sort();
  }

  getTotalSpending(): Types.OrderStats {
    return AmazonHelpers.CalculateSpending(this.amazonData);
  }

  getPurchasesByMonth() {
    const monthData = AmazonHelpers.GetPurchasesByMonthData(this.getOrders());
    const years: string[] = Object.keys(monthData);

    return {
      years,
      data: monthData,
    };
  }

  getRecentPurchaseProps() {
    const orders = this.getOrdersByDate().slice(0, 4);

    return orders.map((o) => {
      return {
        status: o.status,
        asin: o.asin || "",
        img: AmazonHelpers.asin_to_image(o.asin || "", 250) || "",
        name: o.title || "Unknown",
        price: o.price,
      };
    });
  }

  getHighlightsPanelProps() {
    const mode = (arr: Types.AmazonData[]): Types.AmazonData => {
      const mode: { [id: string]: number } = {};
      let max = arr[0],
        count = 0;

      for (let i = 0; i < arr.length; i++) {
        const item = arr[i].title;

        if (!item) {
          continue;
        }

        if (mode[item]) {
          mode[item] += arr[i].quantity;
        } else {
          mode[item] = 1;
        }

        if (count < mode[item]) {
          max = arr[i];
          count = mode[item];
        }
      }

      return max;
    };

    const sortedOrders = this.sortOrders("price", "DESC");
    const largest_order = sortedOrders[0];
    const most_order = mode(sortedOrders);
    const first_order = this.sortOrders('orderDate', 'ASC')[0];

    const largestPurchase: Types.HighlightPropData = {
      category: "Largest Purchase",
      asin: largest_order?.asin,
      img: AmazonHelpers.asin_to_image(largest_order?.asin, 250),
      item: largest_order?.title || "Unknown",
      price: largest_order?.price || 0,
    };

    const mostPurchased: Types.HighlightPropData = {
      category: "Most Purchased",
      asin: most_order?.asin,
      img: AmazonHelpers.asin_to_image(most_order?.asin, 250),
      item: most_order?.title || "Unknown",
      price: most_order?.price || 0,
    };

    const firstPurchased: Types.HighlightPropData = {
      category: "First Purchased",
      asin: first_order?.asin,
      img: AmazonHelpers.asin_to_image(first_order?.asin, 250),
      item: first_order?.title || "Unknown",
      price: first_order?.price || 0,
    };

    return [
      {
        ...largestPurchase,
      },
      {
        ...mostPurchased,
      },
      {
        ...firstPurchased,
      }
    ];
  }

  getSpendingDayOfWeekProps(): Types.SpendingDayOfWeekProps {
    const orders = this.getOrdersByDate();

    const days: number[] = [];

    for (let i = 0; i < 7; i++) {
      days[i] = 0;
    }

    for (const item of orders) {
      const day = item.orderDate?.getDay();
      if (!day) continue;
      days[day] += item.price;
    }

    const maxValue = Number(days.sort((a, b) => b - a)[0].toFixed(2));

    return {
      max: Math.ceil(maxValue / 1000) * 1000,
      values: days.map((d) => Number(d.toFixed(2))),
    };
  }

  getCategoriesSpendingTableProps(): Types.CategoriesSpendingTableProps {
    const categorySpend: Types.CategoriesSpendingTableProps = new Map();
    this.getOrders().forEach((order: Types.AmazonData) => {
      categorySpend.set(order.category, (categorySpend.get(order.category) || 0) + order.price);
    });
    return categorySpend;
  }

  getStatsStandaloneProps(): Types.StatsStandaloneProps {
    const orders = this.getOrdersByCategory(Types.UskoCategory.Groceries);
    const yearly: Types.StatsStandaloneProps = {
      years: this.getYears().reverse(),
      data: {},
    };

    const yearStats: any = {};

    for (const year of this.years) {
      yearStats[year] = { total: 0, trips: 0, purchases: 0 };

      for (const o of orders) {
        if (o.orderDate && o.orderDate.getFullYear() === year) {
          if (!isNaN(o.price)) yearStats[year].total += o.price;
          if (!isNaN(o.quantity)) yearStats[year].purchases += o.quantity;
          yearStats[year].trips++;
        }
      }
    }

    for (const year of yearly.years) {
      const { trips, total, purchases } = yearStats[year];
      yearly.data[year] = { avg_cost_trip: 0, avg_purchases_trip: 0, grocery_trips: 0 };

      const avgCostTrip = Number((total / trips).toFixed(2));
      const avgPurchasesTrip = Number((purchases / trips).toFixed(2));

      yearly.data[year].avg_cost_trip = isNaN(avgCostTrip) ? 0 : avgCostTrip;
      yearly.data[year].avg_purchases_trip = isNaN(avgPurchasesTrip) ? 0 : avgPurchasesTrip;
      yearly.data[year].grocery_trips = trips;
    }

    return yearly;
  }

  getInflationTableProps(): Types.ItemInflation[] {
    const inflationItems: Types.ItemInflation[] = [];
    const hasPriceDiff = function (acc: Types.ItemInflation, elem: Types.AmazonData) {
      // filter out 1000%+ differences, assuming those reflect large discounts rather than inflation
      if (
        elem.pricePerUnit < acc.minOrder.pricePerUnit &&
        acc.maxOrder.pricePerUnit / elem.pricePerUnit < 10
      ) {
        return { ...acc, minOrder: elem };
      }
      if (elem.pricePerUnit > acc.maxOrder.pricePerUnit) {
        // should really never happen if initial acc is a max elem but be safe
        return { ...acc, maxOrder: elem };
      }
      return acc;
    };
    this.products.forEach((orders: Types.AmazonData[]) => {
      if (orders.length > 1) {
        // find largest order to avoid seeding a bad minimum for inflation. Also remove "free" items
        // XXX This stuff is tricky. If making changes, add a test case.
        const filteredOrders: Types.AmazonData[] = [];
        const maxOrder = orders.reduce((acc, curr) => {
          if (curr.pricePerUnit <= 0.0) {
            return acc;
          }
          filteredOrders.push(curr);
          if (curr.pricePerUnit > acc.pricePerUnit) {
            return curr;
          }
          return acc;
        }, orders[0]);
        if (filteredOrders.length <= 0) {
          return;
        }
        const initial = { minOrder: maxOrder, maxOrder: maxOrder, inflation: 0.0 };
        const infl = filteredOrders.reduce(hasPriceDiff, initial);
        if (infl.minOrder.pricePerUnit !== infl.maxOrder.pricePerUnit) {
          if (infl.minOrder.orderDate > infl.maxOrder.orderDate) {
            // we have deflation
            infl.inflation = -(1.0 - infl.minOrder.pricePerUnit / infl.maxOrder.pricePerUnit) * 100;
          } else {
            infl.inflation = (infl.maxOrder.pricePerUnit / infl.minOrder.pricePerUnit - 1.0) * 100;
          }
          //if inflation rounds to zero, don't add to inflationItems
          if(Math.round(infl.inflation) !== 0) inflationItems.push(infl);
        }
      }
    });
    return inflationItems;
  }

  getSpendingYearlyOrMonthlyProps(
    category?: Types.UskoCategory,
  ): Types.SpendingYearlyOrMonthlyProps {
    const yearlyStats = category
      ? AmazonHelpers.FormatStatsByYear(this, [new Date().getFullYear()], category)
      : AmazonHelpers.FormatStatsByYear(this);

    // Calculate yearly delta
    let yearlyDelta =
      yearlyStats.length > 0 ? yearlyStats[yearlyStats.length - 1].totalSpending : 0;

    if (yearlyStats.length > 1) {
      const latestDelta = yearlyStats[yearlyStats.length - 1].totalSpending;
      const prevDelta = yearlyStats[yearlyStats.length - 2].totalSpending;

      yearlyDelta = latestDelta - prevDelta;
    }

    const yearlySpending =
      yearlyStats.length > 0 ? yearlyStats[yearlyStats.length - 1].totalSpending : 0;

    const yearly: Types.SpendingData = {
      data: yearlyStats.map((s) => s.totalSpending),
      labels: yearlyStats.map((s) => `${s.year}`),
      totalSpend: yearlySpending,
      delta: Number(yearlyDelta.toFixed(2)),
    };

    const monthlyStats = category
      ? AmazonHelpers.FormatStatsByMonth(this.getOrdersByCategory(category))
      : this.getStatsByMonth();

    // Calculate monthly total spending
    const monthlyTotalSpend =
      monthlyStats.length > 0 ? monthlyStats[monthlyStats.length - 1].totalSpending : 0;

    // Calculate monthly delta
    let monthlyDelta = monthlyTotalSpend;

    if (monthlyStats.length > 1) {
      const latestMonthDelta = monthlyStats[monthlyStats.length - 1].totalSpending;
      const prevMonthDelta = monthlyStats[monthlyStats.length - 2].totalSpending;

      monthlyDelta = latestMonthDelta - prevMonthDelta;
    }

    const monthly: Types.SpendingData = {
      data: monthlyStats.map((m) => m.totalSpending),
      delta: Number(monthlyDelta.toFixed(2)),
      labels: Types.MonthLabels,
      totalSpend: monthlyTotalSpend,
    };

    return { monthly: monthly, yearly: yearly };
  }

  getItemStatus(): StatusItemCounterObj {
    return this.amazonData.reduce((acc, data) => {
      return {
        ...acc,
        [data.status]: acc[data.status] + 1,
      }
    }, {
      [ItemStatus.Loading]: 0,
      [ItemStatus.Categorizing]: 0,
      [ItemStatus.Complete]: 0,
    });
  }
}
