import { useMemo } from "react";
import { UskoCategory } from "amazon";
import { category_titles } from "../Dashboard/categories/categories";

import { MarketplaceItem } from "./marketplaceTypes";
import ItemCarouselPanel from "./ItemCarouselPanel";

import styled from "styled-components";

const ItemCarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const CategoryTitle = styled.div`
  padding: 0.53125rem 0;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.03125rem;
`;

type ItemCarouselProps = {
  category: string;
  itemStack: MarketplaceItem[];
  allItemsSorted: Map<string, MarketplaceItem[]>|undefined;
}

function ItemCarousel({ category, itemStack, allItemsSorted }: ItemCarouselProps) {
  const categoryStack = Object.values(UskoCategory);
  const categoryToTitles = useMemo(() => {
    const temp = new Map();
    const titleStack = Object.values(category_titles);
    categoryStack.forEach((cat, index) => {
      temp.set(cat, titleStack[index]);
    });
    return temp;
  }, [categoryStack]);

  return(
    <ItemCarouselWrapper>
      <CategoryTitle>{categoryToTitles.get(category)}</CategoryTitle>
      <ItemCarouselPanel allItemsSorted={allItemsSorted} category={category} itemStack={itemStack} />
    </ItemCarouselWrapper>
  );
}

export default ItemCarousel;
