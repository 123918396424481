export enum UskoCategory {
  Appliances = "Appliances",
  Apps = "Apps & Games",
  Arts = "Arts, Crafts, & Sewing",
  Automotive = "Automotive Parts & Accessories",
  Baby = "Baby",
  Beauty = "Beauty & Personal Care",
  Books = "Books",
  CDs = "CDs & Vinyl",
  Cells = "Cell Phones & Accessories",
  Clothing = "Clothing, Shoes and Jewelry",
  Collectibles = "Collectibles & Fine Art",
  Computers = "Computers",
  Electronics = "Electronics",
  Garden = "Garden & Outdoor",
  Groceries = "Grocery & Gourmet Food",
  Handmade = "Handmade",
  Health = "Health, Household & Baby Care",
  Home = "Home & Kitchen",
  Industrial = "Industrial & Scientific",
  Kindle = "Kindle",
  Luggage = "Luggage & Travel Gear",
  Movies = "Movies & TV",
  Musical = "Musical Instruments",
  Office = "Office Products",
  Pet = "Pet Supplies",
  Sports = "Sports & Outdoors",
  Tools = "Tools & Home Improvement",
  Toys = "Toys & Games",
  Video = "Video Games",
  Processing = "Processing",
  Other = "Other",
  Unknown = "Unknown",
}

export const UskoCategoryMap = new Map<string, UskoCategory>([
  ["00", UskoCategory.Processing],
  ["10", UskoCategory.Garden],
  ["1010", UskoCategory.Pet],
  ["1011", UskoCategory.Pet],
  ["1012", UskoCategory.Pet],
  ["1013", UskoCategory.Pet],
  ["1014", UskoCategory.Pet],
  ["11", UskoCategory.Garden],
  ["1110", UskoCategory.Industrial],
  ["111219", UskoCategory.Arts],
  ["111220", UskoCategory.Arts],
  ["1113", UskoCategory.Arts],
  ["1114", UskoCategory.Arts],
  ["1115", UskoCategory.Arts],
  ["1116", UskoCategory.Arts],
  ["1117", UskoCategory.Industrial],
  ["1118", UskoCategory.Industrial],
  ["1119", UskoCategory.Industrial],
  ["12", UskoCategory.Industrial],
  ["13", UskoCategory.Industrial],
  ["14", UskoCategory.Office],
  ["1410", UskoCategory.Arts],
  ["141015", UskoCategory.Industrial],
  ["141116", UskoCategory.Arts],
  ["141216", UskoCategory.Health],
  ["15", UskoCategory.Industrial],
  ["20", UskoCategory.Industrial],
  ["201214", UskoCategory.Tools],
  ["201215", UskoCategory.Tools],
  ["201216", UskoCategory.Tools],
  ["201217", UskoCategory.Sports],
  ["201218", UskoCategory.Tools],
  ["201219", UskoCategory.Tools],
  ["201220", UskoCategory.Tools],
  ["201221", UskoCategory.Tools],
  ["201222", UskoCategory.Tools],
  ["21", UskoCategory.Garden],
  ["2111", UskoCategory.Sports],
  ["22", UskoCategory.Industrial],
  ["23", UskoCategory.Industrial],
  ["2312", UskoCategory.Arts],
  ["2313", UskoCategory.Arts],
  ["2314", UskoCategory.Arts],
  ["2318", UskoCategory.Appliances],
  ["24", UskoCategory.Industrial],
  ["241115", UskoCategory.Health],
  ["25", UskoCategory.Automotive],
  ["2511", UskoCategory.Industrial],
  ["2512", UskoCategory.Industrial],
  ["2513", UskoCategory.Industrial],
  ["2515", UskoCategory.Industrial],
  ["2516", UskoCategory.Industrial],
  ["251817", UskoCategory.Industrial],
  ["251915", UskoCategory.Industrial],
  ["251916", UskoCategory.Industrial],
  ["2520", UskoCategory.Industrial],
  ["26", UskoCategory.Industrial],
  ["261011", UskoCategory.Automotive],
  ["261012", UskoCategory.Automotive],
  ["261013", UskoCategory.Automotive],
  ["261014", UskoCategory.Automotive],
  ["261015", UskoCategory.Automotive],
  ["261017", UskoCategory.Automotive],
  ["261019", UskoCategory.Automotive],
  ["2611", UskoCategory.Automotive],
  ["2612", UskoCategory.Electronics],
  ["261218", UskoCategory.Automotive],
  ["27", UskoCategory.Tools],
  ["2712", UskoCategory.Industrial],
  ["271218", UskoCategory.Tools],
  ["2714", UskoCategory.Automotive],
  ["30", UskoCategory.Industrial],
  ["3011", UskoCategory.Tools],
  ["3012", UskoCategory.Tools],
  ["3013", UskoCategory.Tools],
  ["3014", UskoCategory.Tools],
  ["3015", UskoCategory.Tools],
  ["3016", UskoCategory.Tools],
  ["3017", UskoCategory.Tools],
  ["3018", UskoCategory.Tools],
  ["3019", UskoCategory.Tools],
  ["31", UskoCategory.Industrial],
  ["3115", UskoCategory.Tools],
  ["3116", UskoCategory.Tools],
  ["3117", UskoCategory.Tools],
  ["3118", UskoCategory.Automotive],
  ["3120", UskoCategory.Tools],
  ["3121", UskoCategory.Tools],
  ["3122", UskoCategory.Tools],
  ["312615", UskoCategory.Home],
  ["32", UskoCategory.Electronics],
  ["39", UskoCategory.Home],
  ["391224", UskoCategory.Industrial],
  ["40", UskoCategory.Industrial],
  ["4010", UskoCategory.Home],
  ["401020", UskoCategory.Industrial],
  ["41", UskoCategory.Industrial],
  ["42", UskoCategory.Health],
  ["4212", UskoCategory.Industrial],
  ["43", UskoCategory.Electronics],
  ["4321", UskoCategory.Computers],
  ["4323", UskoCategory.Apps],
  ["44", UskoCategory.Office],
  ["45", UskoCategory.Electronics],
  ["451015", UskoCategory.Office],
  ["451016", UskoCategory.Office],
  ["451017", UskoCategory.Office],
  ["451018", UskoCategory.Arts],
  ["4514", UskoCategory.Arts],
  ["46", UskoCategory.Industrial],
  ["4617", UskoCategory.Electronics],
  ["4618", UskoCategory.Clothing],
  ["4621", UskoCategory.Office],
  ["47", UskoCategory.Health],
  ["4710", UskoCategory.Industrial],
  ["4711", UskoCategory.Appliances],
  ["48", UskoCategory.Industrial],
  ["4810", UskoCategory.Home],
  ["481015", UskoCategory.Appliances],
  ["49", UskoCategory.Sports],
  ["4910", UskoCategory.Collectibles],
  ["50", UskoCategory.Groceries],
  ["51", UskoCategory.Health],
  ["52", UskoCategory.Appliances],
  ["5210", UskoCategory.Home],
  ["5212", UskoCategory.Home],
  ["5213", UskoCategory.Home],
  ["5215", UskoCategory.Home],
  ["521522", UskoCategory.Health],
  ["5216", UskoCategory.Electronics],
  ["5217", UskoCategory.Tools],
  ["53", UskoCategory.Clothing],
  ["5312", UskoCategory.Luggage],
  ["5313", UskoCategory.Beauty],
  ["531315", UskoCategory.Health],
  ["531316", UskoCategory.Health],
  ["531317", UskoCategory.Arts],
  ["531318", UskoCategory.Health],
  ["5314", UskoCategory.Arts],
  ["54", UskoCategory.Clothing],
  ["55", UskoCategory.Books],
  ["5511", UskoCategory.Kindle],
  ["551116", UskoCategory.Computers],
  ["5512", UskoCategory.Office],
  ["56", UskoCategory.Home],
  ["561016", UskoCategory.Garden],
  ["561017", UskoCategory.Office],
  ["561018", UskoCategory.Health],
  ["5611", UskoCategory.Office],
  ["561118", UskoCategory.Home],
  ["561119", UskoCategory.Industrial],
  ["561121", UskoCategory.Home],
  ["561123", UskoCategory.Other],
  ["5612", UskoCategory.Other],
  ["561220", UskoCategory.Industrial],
  ["5613", UskoCategory.Other],
  ["5614", UskoCategory.Arts],
  ["57", UskoCategory.Health],
  ["5701", UskoCategory.Sports],
  ["570101", UskoCategory.Health],
  ["5702", UskoCategory.Other],
  ["5704", UskoCategory.Toys],
  ["5705", UskoCategory.Groceries],
  ["5708", UskoCategory.Electronics],
  ["60", UskoCategory.Musical],
  ["6010", UskoCategory.Books],
  ["601010", UskoCategory.Arts],
  ["601011", UskoCategory.Arts],
  ["601012", UskoCategory.Arts],
  ["601013", UskoCategory.Arts],
  ["601014", UskoCategory.Arts],
  ["601016", UskoCategory.Arts],
  ["6011", UskoCategory.Arts],
  ["601110", UskoCategory.Office],
  ["601111", UskoCategory.Office],
  ["601112", UskoCategory.Office],
  ["601113", UskoCategory.Collectibles],
  ["601114", UskoCategory.Collectibles],
  ["6012", UskoCategory.Arts],
  ["601210", UskoCategory.Collectibles],
  ["601212", UskoCategory.Collectibles],
  ["6014", UskoCategory.Toys],
  ["64", UskoCategory.Office],
  ["6410", UskoCategory.Other],
  ["6411", UskoCategory.Other],
  ["70", UskoCategory.Garden],
  ["71", UskoCategory.Industrial],
  ["72", UskoCategory.Industrial],
  ["7215", UskoCategory.Tools],
  ["721531", UskoCategory.Industrial],
  ["721532", UskoCategory.Industrial],
  ["721533", UskoCategory.Industrial],
  ["721534", UskoCategory.Industrial],
  ["721535", UskoCategory.Industrial],
  ["721536", UskoCategory.Industrial],
  ["721537", UskoCategory.Industrial],
  ["721539", UskoCategory.Industrial],
  ["721540", UskoCategory.Industrial],
  ["721541", UskoCategory.Industrial],
  ["721542", UskoCategory.Industrial],
  ["721543", UskoCategory.Industrial],
  ["721544", UskoCategory.Industrial],
  ["721545", UskoCategory.Industrial],
  ["721546", UskoCategory.Industrial],
  ["73", UskoCategory.Industrial],
  ["76", UskoCategory.Industrial],
  ["77", UskoCategory.Industrial],
  ["78", UskoCategory.Industrial],
  ["80", UskoCategory.Other],
  ["8010", UskoCategory.Industrial],
  ["81", UskoCategory.Industrial],
  ["8111", UskoCategory.Computers],
  ["8112", UskoCategory.Other],
  ["8113", UskoCategory.Other],
  ["8116", UskoCategory.Other],
  ["8117", UskoCategory.Other],
  ["82", UskoCategory.Arts],
  ["8210", UskoCategory.Other],
  ["8211", UskoCategory.Other],
  ["83", UskoCategory.Industrial],
  ["8311", UskoCategory.Electronics],
  ["8312", UskoCategory.Other],
  ["8313", UskoCategory.Other],
  ["8314", UskoCategory.Other],
  ["84", UskoCategory.Other],
  ["85", UskoCategory.Other],
  ["8511", UskoCategory.Health],
  ["8512", UskoCategory.Health],
  ["8514", UskoCategory.Health],
  ["86", UskoCategory.Other],
  ["861315", UskoCategory.Arts],
  ["861316", UskoCategory.Arts],
  ["90", UskoCategory.Other],
  ["901019", UskoCategory.Industrial],
  ["9013", UskoCategory.Arts],
  ["91", UskoCategory.Beauty],
  ["911018", UskoCategory.Clothing],
  ["911019", UskoCategory.Clothing],
  ["911115", UskoCategory.Clothing],
  ["911116", UskoCategory.Clothing],
  ["911117", UskoCategory.Other],
  ["911118", UskoCategory.Other],
  ["911119", UskoCategory.Other],
  ["92", UskoCategory.Other],
  ["93", UskoCategory.Other],
  ["94", UskoCategory.Other],
  ["95", UskoCategory.Industrial],
]);
