// excluding this file from linting as it seems to be WIP
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AmazonData, AmazonReview, UskoCategory } from "../amazon";
import * as LL from "./ll";
import * as ipaddr from "ipaddr.js";

export const MajorVer = 0;
export const MinorVer = 1;

export type Query = {
  desc: string;
  brand: string;
  decls: { [name: string]: Expr }; // TODO use an array
  cond: Expr;
  msg: Expr;
  vMajor: number;
  vMinor: number;
};

// TODO replace these by proper variables
type Calc = AmazonTotalPurchases | AmazonTotalSpend | AmazonPurchases | AmazonReviews;

export type Expr =
  | If
  | Calc
  | Pred
  | Let
  | Apply
  | Msg
  | MsgLink
  | Prim
  | QueryNoMatch
  | QueryError;

type PrimTypes = string | number | boolean | AmazonData | AmazonReview;
type Prim = {
  // TODO PrimTypes[] not homogeously type-safe; should be PrimTypes<T>[]
  //      Doing so bleeds over into Expr so we're not ready for that yet
  p: PrimTypes | PrimTypes[];
  kind: "prim";
};

type AmazonProductFields = Title | Category | Asin | OrderDate;

type AmazonProductSelectFields = AmazonProductFields;

type AmazonProductFilterFields =
  | AmazonProductFields
  | FilterAnd<AmazonProductFilterFields>
  | FilterOr<AmazonProductFilterFields>;

type Title = {
  title: string;
  kind: "title";
};

type Category = {
  category: UskoCategory;
  kind: "category";
};
type Asin = {
  asin: string;
  kind: "asin";
};
type OrderDate = {
  date: number; // TODO use a Date but need to handle deserializaton
  pred: PredOp;
  kind: "orderDate";
};
interface FilterAnd<T> {
  lhs: T;
  rhs: T;
  kind: "filterAnd";
}
interface FilterOr<T> {
  lhs: T;
  rhs: T;
  kind: "filterOr";
}

// Returns count of purchases
type AmazonTotalPurchases = {
  filter?: AmazonProductFilterFields;
  kind: "amazonTotalPurchases";
};
// Returns total spend
type AmazonTotalSpend = {
  filter?: AmazonProductFilterFields;
  kind: "amazonTotalSpend";
};
type AmazonPurchases = {
  filter?: AmazonProductFilterFields;
  select?: AmazonProductFields; // this one is a bit of a hack
  kind: "amazonPurchases";
};
// Returns count of revews
type AmazonReviews = {
  r: Asin | ReviewStars | FilterAnd<AmazonReviews> | FilterOr<AmazonReviews>;
  kind: "amazonReviews";
};
type ReviewStars = {
  stars: number;
  pred: PredOp;
  kind: "reviewStars";
};

type PredOp = "eq" | "neq" | "lt" | "gt" | "lte" | "gte" | "and" | "or";
type Pred = {
  op: PredOp;
  lhs: Expr;
  rhs: Expr;
  kind: "pred";
};

type If = {
  cond: Expr;
  succ: Expr;
  fail: Expr;
  kind: "if";
};

type Let = {
  name: string;
  e1: Expr;
  e2: Expr;
  kind: "let";
};

type Apply = {
  name: string;
  kind: "apply";
};

type QueryNoMatch = {
  kind: "queryNoMatch";
};

export type QueryError = {
  err: string;
  kind: "queryError";
};

export type MsgLink = {
  kind: "msgLink";
  url: string;
  text: string;
};

type MixedMessageBody = (string | Expr)[];

type Msg = {
  message: string | MixedMessageBody;
  link?: MsgLink;
  kind: "msg";
};

export type Env = {
  amazonPurchases: AmazonData[];
  amazonReviews: AmazonReview[];
  vars?: LL.LinkedList<Expr>;
};

function isPrim(e: Expr): e is Prim {
  return e.kind === "prim";
}
function isBoolean(e: Expr): e is { p: boolean; kind: "prim" } {
  return e.kind === "prim" && typeof e.p === "boolean";
}
function isString(e: Expr): e is { p: string; kind: "prim" } {
  return e.kind === "prim" && typeof e.p === "string";
}
function isNumber(e: Expr): e is { p: number; kind: "prim" } {
  return e.kind === "prim" && typeof e.p === "number";
}
function isList<T extends PrimTypes>(e: Expr): e is { p: T[]; kind: "prim" } {
  return e.kind === "prim" && typeof e.p === "object" && (e.p as any).push !== undefined;
}
function isAmazonData(e: Prim["p"]): e is AmazonData {
  return typeof e === "object" && (e as any).asin !== undefined;
}
function isAmazonReview(e: Prim["p"]): e is AmazonReview {
  return typeof e === "object" && (e as any).stars !== undefined;
}
function isMsgTextMixed(e: Msg["message"]): e is MixedMessageBody {
  return typeof e === "object" && (e as any).push !== undefined;
}
function isValidURL(s: string): string | undefined {
  function isUnicastIPv4(s: string) {
    const u = ipaddr.IPv4.parse(s);
    return u.range() === "unicast";
  }
  function isUnicastIPv6(s: string) {
    const u = ipaddr.IPv6.parse(s);
    // technically could allow 6to4 and stuff
    // but would need to do another round of checks
    // for private address ranges etc (see unit tests)
    return u.range() === "unicast";
  }
  try {
    const u = new URL(s);
    if (u.protocol !== "http:" && u.protocol !== "https:") {
      return "invalid protocol";
    }
    const h = u.hostname.toLowerCase();
    if (h.endsWith(".ts.net") || h.includes("localhost") || h.endsWith(".local")) {
      return "invalid hostname";
    }
    if (ipaddr.IPv4.isValid(h) && !isUnicastIPv4(h)) {
      return "invalid ipaddr in hostname";
    }
    if (h.startsWith("[") && h.endsWith("]")) {
      // possible ipv6 address, unwrap brackets
      const ht = h.slice(1, -1);
      if (ipaddr.IPv6.isValid(ht) && !isUnicastIPv6(ht)) {
        return "invalid ipv6addr in hostname";
      }
    }
    if (ipaddr.IPv6.isValid(h) && !isUnicastIPv6(h)) {
      return "invalid ipv6addr in hostname";
    }
  } catch (e) {
    return "invalid URL";
  }
  return undefined;
}
function isEmptyString(s: string | undefined): boolean {
  return !s || s.trim() === "";
}
export function isQueryError(e: Expr): e is QueryError {
  return e.kind === "queryError";
}
export function isQueryNoMatch(e: Expr): e is QueryNoMatch {
  return e.kind === "queryNoMatch";
}
export function queryError(err: string): QueryError {
  return {
    err: err,
    kind: "queryError",
  };
}
function primNumber(n: number): Prim {
  return {
    p: n,
    kind: "prim",
  };
}
function primBoolean(b: boolean): Prim {
  return {
    p: b,
    kind: "prim",
  };
}
function primList(p: PrimTypes[]): Prim {
  return {
    p: p,
    kind: "prim",
  };
}

function store(env: Env, name: string, e: Expr) {
  return {
    vars: LL.add(name, e, env.vars),
    amazonPurchases: env.amazonPurchases,
    amazonReviews: env.amazonReviews,
  };
}

function evalPredOp(
  op: PredOp,
  a: string | number | boolean,
  b: string | number | boolean,
): boolean {
  const at = typeof a;
  const bt = typeof b;
  if (at !== bt) {
    return false; // TODO should be an error (even better, a type error)
  }
  switch (op) {
    case "eq":
      return a === b;
    case "neq":
      return a !== b;
    case "lt":
      return a < b;
    case "gt":
      return a > b;
    case "lte":
      return a <= b;
    case "gte":
      return a >= b;
    case "and":
      if (typeof a !== "boolean" || typeof b !== "boolean") {
        return false;
      }
      return a && b;
    case "or":
      if (typeof a !== "boolean" || typeof b !== "boolean") {
        return false;
      }
      return a || b;
    default:
      const _exhaustiveCheck: never = op;
      return false; // should never happen
  }
}
function evalSelectFields(f: AmazonProductSelectFields, p: AmazonData): PrimTypes {
  switch (f.kind) {
    case "title":
      return p.title || "";
    case "category":
      return p.category;
    case "asin":
      return p.asin;
    case "orderDate":
      return p.orderDate.getTime();
    default:
      const _exhaustiveCheck: never = f;
      return p.title || "usko:somethingwrong"; // should never happen
  }
}

function evalFilterFields(f: AmazonProductFilterFields | undefined, p: AmazonData): boolean {
  if (f === undefined) return true;
  switch (f.kind) {
    case "title":
      // TODO ensure p.title lowercase at construction time
      return (p.title || "").toLowerCase().indexOf(f.title) !== -1;
    case "category":
      return p.category === f.category;
    case "asin":
      return p.asin === f.asin;
    case "orderDate":
      return evalPredOp(f.pred, p.orderDate.getTime(), f.date);
    case "filterAnd":
      return evalFilterFields(f.lhs, p) && evalFilterFields(f.rhs, p);
    case "filterOr":
      return evalFilterFields(f.lhs, p) || evalFilterFields(f.rhs, p);
    default:
      const _exhaustiveCheck: never = f;
  }
  return false;
}

export function evalExpr(e: Expr, env: Env): Expr {
  switch (e.kind) {
    case "if":
      const c = evalExpr(e.cond, env);
      if (!isBoolean(c)) {
        return queryError("Conditional expression was not a boolean");
      }
      if (evalExpr(e.cond, env)) {
        return evalExpr(e.succ, env);
      } else {
        return evalExpr(e.fail, env);
      }
    case "amazonTotalPurchases": {
      let totalPurchases = 0;
      env.amazonPurchases.forEach((purchase) => {
        if (evalFilterFields(e.filter, purchase)) {
          totalPurchases += purchase.quantity;
        }
      });
      return primNumber(totalPurchases);
    }
    case "amazonTotalSpend": {
      let totalSpend = 0;
      env.amazonPurchases.forEach((purchase) => {
        if (evalFilterFields(e.filter, purchase)) {
          totalSpend += purchase.price;
        }
      });
      return primNumber(totalSpend);
    }
    case "amazonPurchases": {
      let res: PrimTypes[] = [];
      env.amazonPurchases.forEach((purchase) => {
        if (evalFilterFields(e.filter, purchase)) {
          if (e.select) res.push(evalSelectFields(e.select, purchase));
          else res.push(purchase);
        }
      });
      return primList(res);
    }
    case "amazonReviews":
      {
        let totalMatches = 0;
        env.amazonReviews.forEach((review) => {
          // TODO finish
        });
        return primNumber(totalMatches);
      }
      // eslint-disable-next-line no-unreachable
      return queryError("unimplemented but try removing this should be exhaustive");
    case "pred":
      // TODO type checking for all this
      const lhs = evalExpr(e.lhs, env);
      const rhs = evalExpr(e.rhs, env);
      if (!isPrim(lhs) || !isPrim(rhs)) {
        return queryError("predicate did not have primitive type for lhs or rhs");
      }
      switch (e.op) {
        case "eq":
          return primBoolean(lhs.p === rhs.p);
        case "neq":
          return primBoolean(lhs.p !== rhs.p);
        case "lt":
          return primBoolean(lhs.p < rhs.p);
        case "gt":
          return primBoolean(lhs.p > rhs.p);
        case "lte":
          return primBoolean(lhs.p <= rhs.p);
        case "gte":
          return primBoolean(lhs.p >= rhs.p);
        case "and":
          if (!(isBoolean(lhs) && isBoolean(rhs))) {
            return queryError("and-predicate did not have boolean term");
          }
          return primBoolean(lhs.p && rhs.p);
        case "or":
          if (!(isBoolean(lhs) && isBoolean(rhs))) {
            return queryError("or-predicate did not have boolean term");
          }
          return primBoolean(lhs.p || rhs.p);
      }
    // eslint-disable-next-line no-fallthrough
    case "let": {
      const env1 = store(env, e.name, evalExpr(e.e1, env));
      return evalExpr(e.e2, env1);
    }
    case "apply": {
      const e1 = LL.find(e.name, env.vars);
      if (!e1) return queryError("Unbound variable " + e.name);
      return evalExpr(e1, env);
    }
    case "msg":
      // reify any variables
      if (!isMsgTextMixed(e.message)) return e;
      try {
        const message = e.message.map((m) => {
          if (typeof m === "string") return m;
          const m1 = evalExpr(m, env);
          if (m1.kind === "msgLink") return m;
          if (m1.kind === "queryError") {
            throw new Error(m1.err);
          }
          if (!isPrim(m1)) {
            throw new Error("Unrecognized type in message body:" + m1);
          }
          if (isBoolean(m1) || isString(m1)) {
            return m1.p.toString();
          }
          if (isNumber(m1)) {
            if (0 === m1.p % 1) return m1.p.toString();
            return m1.p.toFixed(2);
          }
          throw new Error("Disallowed prim type in message body"); // TODO better type reporting
        });
        const m: Msg = { kind: "msg", message, link: e.link };
        return m;
      } catch (z: any) {
        // TODO shouldn't cast as any
        return queryError(z.message);
      }
    case "prim":
    case "msgLink":
    case "queryNoMatch":
    case "queryError":
      return e;
    default:
      const _exhaustiveCheck: never = e;
  }
  return queryError("unknown expr " + JSON.stringify(e));
}

type varTypeInfo = {
  type: primTypes;
  usage: number;
};
type typeEnv = {
  vars?: LL.LinkedList<varTypeInfo>;
};

type primTypes = "string" | "number" | "boolean" | "amazonData" | "amazonReview";
type primArr<T> = T extends primTypes ? `${T}[]` : never;
type exprType = primArr<primTypes> | primTypes | "error" | "msg" | "msgLink";
function typeOf(e: Expr, tenv: typeEnv): exprType {
  switch (e.kind) {
    case "prim":
      // TODO this is not gonna work since these are run-time values
      // TODO make this exhaustive
      if (typeof e.p === "string") return "string";
      else if (typeof e.p === "number") return "number";
      else if (typeof e.p === "boolean") return "boolean";
      else if (typeof e.p === "object" && (e.p as any).asin !== undefined) {
        return "amazonData";
      } else if (typeof e.p === "object" && (e.p as any).stars !== undefined) {
        return "amazonReview";
      }
      //
      // TODO rest of prim types
      break;
    case "if":
      typeMatches(typeOf(e.cond, tenv), "boolean"); // TODO better error reporting
      const [tSucc, tFail] = [typeOf(e.succ, tenv), typeOf(e.fail, tenv)];
      typeMatchesWithError(tSucc, tFail);
      return tSucc === "error" ? tFail : tSucc;
    case "amazonTotalPurchases":
      // TODO typecheck filter fields
      // things like: do order date predicates make sense? (eg, are they reversed)?
      return "number";
    case "amazonTotalSpend":
      // TODO typecheck filter fields
      return "number";
    case "amazonPurchases":
      // TODO typecheck filter fields
      if (!e.select) {
        return "amazonData[]";
      }
      const sel = e.select;
      switch (sel.kind) {
        case "title":
          return "string[]";
        case "category":
          return "string[]";
        case "asin":
          return "string[]";
        case "orderDate":
          return "number[]";
        default:
          const _exhaustiveCheck: never = sel;
          throw new Error("non exhaustive selection: "); // TODO better error reporting; get the kind
      }
    case "amazonReviews":
      return "amazonReview";
    case "pred":
      const [tlhs, trhs] = [typeOf(e.lhs, tenv), typeOf(e.rhs, tenv)];
      typeMatches(tlhs, trhs);
      switch (e.op) {
        case "eq":
        case "neq":
          return "boolean";
        case "lt":
        case "gt":
        case "gte":
        case "lte":
          typeStringOrNumber(tlhs);
          return "boolean";
        case "and":
        case "or":
          typeBoolean(tlhs);
          return "boolean";
        default:
          const _exhaustiveCheck: never = e.op;
          throw new Error("non exhaustive pred op"); // TODO better error reporting; get the op
      }
    case "let": {
      if (isEmptyString(e.name)) throw new Error("empty let name");
      const t1 = typeOf(e.e1, tenv);
      if (!typePrim(t1)) {
        throw new Error("let type not a prim: " + e.name + " was a " + t1);
      }
      // disallow shadowing for now
      if (LL.find(e.name, tenv.vars)) {
        throw new Error("shadowed let: " + e.name);
      }
      // TODO count applications in e2, error if let is unused
      const info = { type: t1, usage: 0 };
      const tenv1 = { vars: LL.add(e.name, info, tenv.vars) };
      return typeOf(e.e2, tenv1);
    }
    case "apply": {
      if (isEmptyString(e.name)) throw new Error("empty apply name");
      const info = LL.find(e.name, tenv.vars);
      if (!info) throw new Error("unrecognized reference " + e.name);
      return info.type;
    }
    case "msg":
      if (e.link) {
        typeMatches(typeOf(e.link, tenv), "msgLink");
      }
      if (isMsgTextMixed(e.message)) {
        if (e.message.length <= 0) throw new Error("message body is empty");
        e.message.forEach((m) => {
          if (typeof m === "string") {
            if (isEmptyString(m)) {
              throw new Error("message body element is empty");
            }
          } else {
            const t1 = typeOf(m, tenv);
            // for now, limit to types we know will serialize OK
            const okTypes = ["msgLink", "string", "number", "boolean"];
            if (!okTypes.includes(t1)) {
              throw new Error("message body has unsupported type " + t1);
            }
          }
        });
      } else if (isEmptyString(e.message)) {
        throw new Error("message is empty");
      }
      return "msg";
    case "msgLink":
      if (!e.text || e.text.trim() === "") {
        throw new Error("message link text empty");
      }
      if (!e.url) {
        throw new Error("message link url empty");
      }
      const err = isValidURL(e.url);
      if (err !== undefined) {
        throw new Error("message link url " + err);
      }
      return "msgLink";
    case "queryNoMatch":
    case "queryError":
      return "error";
    default:
      const _exhaustiveCheck: never = e;
  }
  throw new Error("unknown type " + e);
}
function typeMatches(t1: exprType, t2: exprType) {
  if (t1 !== t2) {
    throw new Error("mismatched type: expected " + t2 + " got " + t1);
  }
}
function typeStringOrNumber(t: exprType) {
  if (!(t === "string" || t === "number")) {
    throw new Error("type not a string or number: " + t);
  }
}
function typeBoolean(t: exprType) {
  if (t !== "boolean") {
    throw new Error("type not a boolean: " + t);
  }
}
function typePrim(t: exprType): t is primTypes {
  const t1: primTypes = t as primTypes; // horrible
  switch (t1) {
    case "string":
    case "number":
    case "boolean":
    case "amazonData":
    case "amazonReview":
      return true;
    default:
      const _exhaustiveCheck: never = t1;
      return false;
  }
}
function typeMatchesWithError(t1: exprType, t2: exprType) {
  if (t1 === "error" || t2 === "error") {
    return;
  }
  if (t1 !== t2) {
    throw new Error("mismatched type: expected " + t2 + " got " + t1);
  }
}

export function typecheck(e: Expr): QueryError | undefined {
  try {
    typeOf(e, {});
  } catch (e: any) {
    // TODO shouldn't cast as any
    return queryError(e.message);
  }
}

export function typecheckQuery(q: Query): QueryError | undefined {
  try {
    // typecheck decls and add them to type environment
    const tenv: typeEnv = {};
    if (typeof q.decls !== "object") throw new Error("missing decls");
    Object.keys(q.decls).forEach((k) => {
      const type = typeOf(q.decls[k], tenv);
      if (!typePrim(type)) {
        throw new Error("decl not a prim: " + k + " was a " + type);
      }
      // NB: shadowing is structurally not possible with decl as an object
      // unfortunately this means we can't check for shadowing
      // TODO count applications for each decl, error if unused
      const info = { type, usage: 0 };
      tenv.vars = LL.add(k, info, tenv.vars);
    });
    typeBoolean(typeOf(q.cond, tenv));
    typeMatches(typeOf(q.msg, tenv), "msg");
  } catch (e: any) {
    // TODO shouldn't cast as any
    return queryError(e.message);
  }
}

function humanizeFilter(f: AmazonProductFilterFields | undefined): string {
  if (!f) return "";
  switch (f.kind) {
    case "filterAnd": {
      // Special-case date handling
      if (f.lhs.kind === "orderDate" && f.rhs.kind === "orderDate") {
        if (f.lhs.pred === "gte" && f.rhs.pred === "lt") {
          const startDate = new Date(f.lhs.date);
          const endDate = new Date(f.rhs.date);
          // check for first of the month
          if (startDate.getDate() === 1 && endDate.getDate() === 1) {
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();
            // first month of each year? then it's a yearly range
            if (startDate.getMonth() === 0 && endDate.getMonth() === 0) {
              if (endYear === startYear + 1) {
                // only one year
                return `bought in ${startYear}`;
              }
              // spanning multiple years
              return `bought between ${startYear} and ${endYear - 1}`;
            }
            // between certain months
            const startMonth = startDate.toLocaleString("default", {
              month: "long",
            });
            const endMonth = endDate.toLocaleString("default", {
              month: "long",
            });
            return `bought between ${startMonth} ${startYear} and ${endMonth} ${endYear}`;
          }
        }
      }

      const lhs = humanizeFilter(f.lhs);
      const rhs = humanizeFilter(f.rhs);
      return `${lhs} and ${rhs}`;
    }
    case "filterOr": {
      const lhs = humanizeFilter(f.lhs);
      const rhs = humanizeFilter(f.rhs);
      return `(${lhs}) or (${rhs})`;
    }
    case "category":
      return `category is ${f.category}`;
    case "asin":
      // TODO neater way to do asin?
      return `asin is ${f.asin}`;
    case "title":
      return `purchase title contains '${f.title}'`;
    case "orderDate": {
      let pred = "unassigned";
      let rangeOp = false;
      switch (f.pred) {
        case "eq":
          pred = "on";
          break;
        case "neq":
          pred = "not on";
          break;
        case "lt":
        case "lte":
          pred = "before";
          rangeOp = true;
          break;
        case "gt":
        case "gte":
          pred = "since";
          rangeOp = true;
          break;
        case "and":
        case "or":
          // TODO add to typechecker
          pred = "lol no please -" + f.pred;
      }

      // prettify dates
      const date = new Date(f.date);
      let dateString = date.toISOString().split("T")[0];
      // start of the year
      if (date.getMonth() === 0 && date.getDate() === 1 && rangeOp) {
        dateString = date.getFullYear().toString();
      } else if (date.getDate() === 1 && rangeOp) {
        const month = date.toLocaleString("default", { month: "long" });
        dateString = `${month} ${date.getFullYear()}`;
      }
      return `bought ${pred} ${dateString}`;
    }
    default:
      const _exhaustiveCheck: never = f;
  }
  return "UNKNOWN FILTER";
}

export function humanize(e: Expr): string {
  switch (e.kind) {
    case "prim": {
      if (typeof e.p === "string") return e.p;
      if (typeof e.p === "number") return e.p.toString();
      // TODO make asin more human readable ?
      if (isAmazonData(e.p)) return e.p.asin;
      if (isAmazonReview(e.p)) return `${e.p.stars}-star review of ${e.p.asin}`;
      return "UNKNOWN PRIM";
    }
    case "if": {
      const cond = humanize(e.cond);
      const succ = humanize(e.succ);
      const fail = humanize(e.fail);
      return `if (${cond})\nthen(${succ})\nelse(${fail})`;
    }
    case "amazonTotalPurchases": {
      let filter = humanizeFilter(e.filter);
      if (filter !== "") filter = " where " + filter;
      return `Number of purchases on Amazon${filter}`;
    }
    case "amazonTotalSpend": {
      let filter = humanizeFilter(e.filter);
      if (filter !== "") filter = " where " + filter;
      return `Spending on Amazon${filter}`;
    }
    case "amazonPurchases": {
      if (e.select) {
        // TODO
      }
      let filter = humanizeFilter(e.filter);
      if (filter !== "") filter = " where " + filter;
      return `Amazon purchases${filter}`;
    }
    case "amazonReviews": {
      // TODO finish
      return "Amazon reviews";
    }
    case "pred": {
      const lhs = humanize(e.lhs);
      const rhs = humanize(e.rhs);
      return `${lhs} ${e.op} ${rhs}`;
    }
    case "let": {
      const e1 = humanize(e.e1);
      const e2 = humanize(e.e2);
      return `let ${e.name} = ${e1} in ${e2}`;
    }
    case "apply": {
      return `$${e.name}`;
    }
    case "msg": {
      const link = e.link ? `(${humanize(e.link)})` : "";
      const message = !isMsgTextMixed(e.message)
        ? e
        : e.message.map((e) => {
            if (typeof e === "string") return e;
            return humanize(e);
          });
      return `message ${message} ${link}`;
    }
    case "msgLink": {
      return `link (${e.text}) (${e.url})`;
    }
    case "queryNoMatch":
    case "queryError":
      return "error";
    default:
      const _exhaustiveCheck: never = e;
  }
  throw new Error("unknown type " + e);
}

export function runQuery(q: Query, env: Env): Expr {
  if (q.vMajor !== MajorVer) {
    return queryError("Mismatched major versions: got " + q.vMajor + " expected " + MajorVer);
  }
  if (q.vMinor > MinorVer) {
    return queryError("Mismatched minor versions: got " + q.vMinor + " expected " + MinorVer);
  }

  if (typeof q.decls === "object") {
    Object.keys(q.decls).forEach((k) => {
      env = store(env, k, evalExpr(q.decls[k], env));
    });
  }

  const cond = evalExpr(q.cond, env);
  if (!isBoolean(cond) || !cond.p) {
    return { kind: "queryNoMatch" };
  }
  return evalExpr(q.msg, env);
}
