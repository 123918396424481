import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { AmazonHelpers, ItemInflation } from "../../../../amazon"
import { FlexRow, Left, Right } from "./generics";

import { PanelWrapper, PanelTitle, SmallText, LoadingModalWrapper, LoadingModalMessage, LoadingPricePill } from "../../styles/generics";
import LoadingIcon from "../panels/icons/LoadingIcon";

import ViewAllArrow from "./buttons/ViewAllArrow";
import AmazonContext from "components/Contexts/AmazonContext";

const InflationTable = styled.ul`
  padding: 0;
	margin: 0;

  left: 16px;

  font-size: 0.875rem;

  background: #ffffff;
  border-radius: 0.9375rem;
`

const InflationItemRow = styled.li`
  display: flex;

  align-items: center;
  justify-content: space-between;

  border-bottom: 0.5px solid lightgrey;
  :last-child { border-bottom: none; }

  height: 66px;

  cursor: pointer;
`;

// TODO  combine with PurchasesStandAlonePanel
const InflationItemName = styled(SmallText)`
  margin-left: 10px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.5px;

  max-width: calc(100vw - 192px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// TODO combine with PurchasesStandAlonePanel
const InflationItemImgWrapper = styled.div`
  width: 31px;
  height: 31px;
`;

// TODO combine with PurchasesStandAlonePanel
const InflationItemImg = styled.img`
  max-width: 31px;
  max-height: 31px;
  margin-right: 9px;
  border-radius: 100px;
  overflow: hidden;
`;

const InflationDateRange = styled(SmallText)`
  margin-left: 10px;
  font-weight: 700;
  line-height: 17px;
  color: #8A7DD7;
`;

const LoadingTable = styled.div`
  position: relative;
  padding-top: 0.5rem;
`;

const LoadingTitle = styled(PanelTitle)`
`

const shimmerAnimation = keyframes`
  0% { opacity: 100%; }
  50% { opacity: 40%; }
  100% { opacity: 100%; }
`;

const LoadingTableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem 0;
  border-bottom: 0.5px solid #EAE9F1;
`;

const LoadingTableLeft = styled.div<any>`
  background-color: #F7F6FF;
  height: 1.3125rem;
  width: 55%; 
  border-radius: 6.25rem;
  animation-name: ${props => props.shimmer ? shimmerAnimation : 'none'};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`;

const LoadingTableRight = styled(LoadingPricePill)<any>`
  animation-name: ${props => props.shimmer ? shimmerAnimation : 'none'};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`;

const InsufficientData = (icon: boolean, shimmer: boolean,  msg: string) => {
  return(
    <>
      <PanelWrapper>
        <FlexRow>
          <Left style={{ display: 'flex', alignItems: 'center' }}>
            <LoadingTitle>
              Purchase Inflation
            </LoadingTitle>
          </Left>
          <Right>
            {icon ?
              <LoadingIcon size={20} thickness={3} />
              :
              null}
          </Right>
        </FlexRow>
        <LoadingTable>
          <LoadingModalWrapper>
            <LoadingModalMessage>{msg}</LoadingModalMessage>
          </LoadingModalWrapper>
          {Array(5).fill('').map((r, i) => {
            return(
              <LoadingTableRow key={i}>
                <LoadingTableLeft shimmer={shimmer}/>
                <LoadingTableRight shimmer={shimmer}/>
              </LoadingTableRow>
            );
          })}
        </LoadingTable>
      </PanelWrapper>
    </>
  );
};

function inflationRow(navigate : any, d : ItemInflation, i: number) {
  const asin = d.minOrder.asin;
  const img = AmazonHelpers.asin_to_image(asin, 250) || "";
  const title = d.minOrder.title || "Unknown"; // TODO make title non-optional with unknown as default
  const inflation = AmazonHelpers.numberFormat(Math.round(d.inflation));
  const dateRange = AmazonHelpers.formatInflationDateRange(d);
  const rowClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation(); // halt parent element
    navigate(`/product/${asin}`);
  };

  return (
      <InflationItemRow key={"inflation_"+i} onClick={rowClick}>
        <Left style={{ display: "flex", alignItems: "center" }}>
            <InflationItemImgWrapper>
              <InflationItemImg src={img} alt="Product" />
            </InflationItemImgWrapper>
            <div>
              <InflationItemName>{title}</InflationItemName>
              <InflationDateRange>{dateRange}</InflationDateRange>
            </div>
        </Left>
        <Right>
          <div>{inflation}%</div>
        </Right>
      </InflationItemRow>
  );
}


function InflationPanel() {
  const navigate = useNavigate();
  const { amazonHandler, updating } = useContext(AmazonContext);
  const data = useMemo(() => amazonHandler.getInflationTableProps(), [amazonHandler]);

  const sorted = data.sort((a, b) => b.inflation - a.inflation).slice(0, 5);
  const loadingRowsFill = useMemo(() => {
    return (sorted.length < 5 && updating) ?
      Array(5 - sorted.length).fill(
        <LoadingTableRow>
          <LoadingTableLeft shimmer={true} />
          <LoadingTableRight shimmer={true} />
        </LoadingTableRow>
      )
      :
      null;
  },[sorted, updating]);

  if(sorted.length === 0 && updating){
    return InsufficientData(true, true, 'Purchase inflation compares price difference of similar items yearly, might take some time');
  }

  if (sorted.length === 0) {
    return InsufficientData(false, false, 'There is not enough data yet for this panel');
  }

  return (
		<PanelWrapper>
			<FlexRow>
				<Left style={{ display: 'flex', alignItems: 'center' }}>
					<PanelTitle>
						Purchase Inflation
					</PanelTitle>
				</Left>
				<Right onClick={() => navigate('/inflation')}>
					<ViewAllArrow />
				</Right>
			</FlexRow>
      <InflationTable>
        {sorted.map((d, i) => inflationRow(navigate, d, i))}
        {loadingRowsFill}
      </InflationTable>
		</PanelWrapper>
  )
}


export default InflationPanel;
