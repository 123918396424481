import {useContext, useEffect, useMemo, useState} from "react";
import { AmazonHandler, AmazonHelpers, UskoCategory } from "amazon";
import { ViewWrapper } from "./generics";
import { months } from "components/App/Dashboard/panelsv2/utils";
import { useParams } from "react-router-dom";
import { FlexRow, Left, NoStyleSelect, Right } from "components/App/Dashboard/panelsv2/generics";
import { SubHeading } from "../../styles/generics";
import { category_titles } from "components/App/Dashboard/categories/categories";
import { SortReturn, MapEntry, srtFuncs } from "./sortOptions";

import AmazonContext from "components/Contexts/AmazonContext";
import ChartBlurb from "./ChartBlurb";
import DateDropdown from "components/App/Dashboard/modals/DateDropdown";
import styled from "styled-components";
import CategoryComparisonChart from "components/App/Dashboard/panelsv2/charts/CategoryComparisonChart";

import SortIcon from 'icons/sort-icon.svg';
import {MobileEventHandler} from "../../../Bridge/MobileEventHandler";

const SubTitle = styled(SubHeading)`
  line-height: 1.36125rem;
`;

const NoStyleSelectWithCaret = styled(NoStyleSelect)`
  -webkit-appearance: auto;
  -moz-appearance: auto;
`;

const OptionText = styled.option`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.1875rem;

  color: #4C4C4C;
`;

const MONTHLY = 'monthly';
const YEARLY = 'yearly';

function SpendingByCategoryView() {
  const { by } = useParams();
  const [sortOption, setSortOption] = useState("Change ↓");

  const { amazonHandler } = useContext(AmazonContext);
  const years = useMemo(() => amazonHandler.getYears().reverse(), [amazonHandler]);

  const [year_start, setYearStart] = useState(new Date().getFullYear() - 1);
  const [year_end, setYearEnd] = useState(new Date().getFullYear());
  const [month_start, setMonthStart] = useState(months[new Date().getMonth()]);
  const [month_end, setMonthEnd] = useState(months[new Date().getMonth()]);

  const yearHandlers = useMemo(() => {
    const yearData = AmazonHelpers.SplitOrdersByYear(amazonHandler.getOrders());
    const yearHandlers: {[id: number]: AmazonHandler} = {};
    years.forEach((y) => {
      yearHandlers[y] = AmazonHandler.loadAmazonHandler(yearData[y]);
    });

    return yearHandlers;
  }, [amazonHandler, years]);

  useEffect(() => {
    MobileEventHandler.analytics("spending_by_category_wv")
  }, [])

  const monthHandlers = useMemo(() => {
    const monthHandlers: {[id: number]: { [id: number] : AmazonHandler }} = {};
    years.forEach((y) => {
      monthHandlers[y] = AmazonHelpers
        .SplitOrdersByMonth(yearHandlers[y].getOrders())
        .map((m) => AmazonHandler.loadAmazonHandler(m))
    });
    return monthHandlers;
  }, [yearHandlers, years]);

  const { first, second } = useMemo(() => {
    const firstHandler = by === YEARLY ?
      yearHandlers[year_start]
      :
      by === MONTHLY ?
      monthHandlers[year_start]?.[months.indexOf(month_start)]
      :
      null;
    const secondHandler = by === YEARLY ?
      yearHandlers[year_end]
      :
      by === MONTHLY ?
      monthHandlers[year_end]?.[months.indexOf(month_end)]
      :
      null;

    return {
      first:{
        data: firstHandler?.getCategoriesSpendingTableProps(),
        spending: firstHandler?.getTotalSpending().totalSpending || 0
      },
      second: {
        data: secondHandler?.getCategoriesSpendingTableProps(),
        spending: secondHandler?.getTotalSpending().totalSpending || 0
      }
    };

  }, [by, monthHandlers, month_end, month_start, yearHandlers, year_end, year_start]);

  const selector = useMemo(() => {
    return by === MONTHLY ?
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <DateDropdown
          month={month_start}
          months={months}
          setMonth={setMonthStart}
          year={year_start}
          setYear={setYearStart}
        />
        <div style={{ padding: 5}}>vs</div>
        <DateDropdown
          month={month_end}
          months={months}
          setMonth={setMonthEnd}
          year={year_end}
          setYear={setYearEnd}
        />
      </div>
      :
      by === YEARLY ?
      <span>
        <NoStyleSelectWithCaret
          value={year_start}
          onChange={selectYearStart}
        >
          {years.map((y) => <OptionText value={y} key={y}>{y}</OptionText>)}
        </NoStyleSelectWithCaret>
        vs
        <NoStyleSelectWithCaret
          value={year_end}
          onChange={selectYearEnd}
        >
          {years.map((y) => <OptionText value={y} key={y}>{y}</OptionText>)}
        </NoStyleSelectWithCaret>
      </span>
      :
      <>{null}</>;
  },[by, month_end, month_start, year_end, year_start, years]);

  const { delta, negative } = useMemo(() => {
    return {
      delta: second.spending - first.spending,
      negative: second.spending < first.spending
    }
  }, [first.spending, second.spending])

  const text = useMemo(() => {
    return `${negative ? 'less' : 'more'} than ${year_start}`;
  }, [negative, year_start]);

  const { labels, firstData, secondData } : SortReturn = useMemo(() => {
    const labels : UskoCategory[] = [];
    const firstData : number[] = [];
    const secondData: number[] = [];
    const data: MapEntry[] = Object.keys(category_titles)
      .map((c) => {
        const uskCKey = UskoCategory[c as keyof typeof UskoCategory];
        return [uskCKey, first.data?.get(uskCKey) || 0, second.data?.get(uskCKey) || 0]
      }

      );

    const sorted: MapEntry[] = data.sort(srtFuncs[sortOption]);
    sorted.forEach((v) => {
      labels.push(v[0]);
      firstData.push(v[1]);
      secondData.push(v[2]);
    });
    return { labels, firstData, secondData };
  }, [first, second, sortOption]);

  const { firstDateText, secondDateText } = useMemo(() => {
    return {
      firstDateText: by === MONTHLY ? `${month_start} ${year_start}` : by === YEARLY ? `${year_start}` : ``,
      secondDateText: by === MONTHLY ? `${month_end} ${year_end}` : by === YEARLY ? `${year_end}` : ``
    }
  }, [by, month_end, month_start, year_end, year_start]);

  function selectOption (e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setSortOption(value);
  }

  function selectYearStart (e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setYearStart(parseInt(value));
  }

  function selectYearEnd (e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setYearEnd(parseInt(value));
  }

  return <ViewWrapper>
    <ChartBlurb
      selector={selector}
      title={`Compare Spending:`}
      text={text}
      spending={first.spending}
      delta={delta}
    />
    <FlexRow style={{ padding: 16}}>
      <Left style={{display: 'flex', alignItems: 'center'}}>
        <SubTitle>All Categories</SubTitle>
      </Left>
      <Right>
        <img style={{paddingRight: 10}} src={SortIcon} alt="Sort Icon" />
        <NoStyleSelect
          style={{ textAlign: 'right'}}
          value={sortOption}
          onChange={selectOption}
        >
          {Object.keys(srtFuncs).map((opt) => {
            const starting_i = opt.indexOf("(Starting");
            const ending_i = opt.indexOf("(Ending");
            const text = starting_i !== -1 ?
              opt.substring(0, starting_i) + year_start + opt.substring(opt.indexOf(')') + 1)
              :
              ending_i !== - 1 ?
              opt.substring(0, ending_i) + year_end + opt.substring(opt.indexOf(')') + 1)
              :
              opt;
            return <option key={opt} value={opt}>{text}</option>
          })}
        </NoStyleSelect>
      </Right>
    </FlexRow>
    <CategoryComparisonChart
      labels={labels}
      first={firstData}
      second={secondData}
      firstDate={firstDateText}
      secondDate={secondDateText}
    />
  </ViewWrapper>
}

export default SpendingByCategoryView;
