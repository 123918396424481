import {useEffect, useState} from "react";

import { Message as TMessage, postSurvey } from "messaging";

import styled from "styled-components";
import { ButtonText, PanelWidthButton, PanelWrapper, SmallText } from "../styles/generics";
import SwipeToClosePanel from "../styles/SwipeToClosePanel";

import ExitIconButton from "../../../icons/dynamic-icons/ExitIconButton";
import Thumbs from "../../../icons/dynamic-icons/Thumbs";
import {MobileEventHandler} from "../../Bridge/MobileEventHandler";

const MessageFeedbackWrapper = styled(PanelWrapper)`
  min-height: 10.75rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 0.9375rem;
  text-align: center;
  padding: 2rem 2.25rem;
  margin: 0;
`;
const MessageFeedbackTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const MessageFeedbackText = styled(SmallText)`
  width: 100%;
  letter-spacing: -0.5px;
  text-align: left;
`;
const ThumbsRow = styled.div`
  display: flex;
  gap: 2.25rem;
  margin-top: 1rem;
`;
const ThumbsButton = styled.div`
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EFEDFF;
  border-radius: 100%;
  margin-top: 1rem  0 0;
`;
const MoreFeedback = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const MoreFeedbackButton = styled(PanelWidthButton)`
  color: #6B6BD4;
  background-color: #FFFFFF;
  border: 2.5px solid #6B6BD4;
  text-transform: capitalize;
  margin-top: 0.25rem;
`;
/* moreFeedback response currently linked to external form
const MoreFeedbackTextArea = styled.textarea`
  color: #4D4C4C;
  font-size: 1rem;
  width: 100%;
  padding: 1rem 1.25rem;
  border: 2.5px solid #4D4C4C4C;
  border-radius: 0.9375rem;
  margin: 1.5rem 0;
  overflow: hidden;
`;
*/

enum ThumbFeedback {
  Up = 'thumbs up',
  Down = 'thumbs down',
}

interface MessageFeedbackProps {
  click: (msg: TMessage, url: string) => void;
  msg: TMessage;
  setShowFeedback: (b: boolean) => void;
  showFeedback: boolean;
}

function MessageFeedback({ click, msg, showFeedback, setShowFeedback }: MessageFeedbackProps) {
  // TODO figure out correct actions for response
  const [ response, setResponse ] = useState<string | undefined>(undefined);
  const [ moreFeedback ] = useState(false);
  /* moreFeedback response currently linked to external form
  const moreFeedbackTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const [ moreFeedbackResponse, setMoreFeedbackResponse ] = useState('');

  useAutoSizeTextArea(moreFeedbackTextAreaRef.current, moreFeedbackResponse, 92);
  */

  return(
    <SwipeToClosePanel
      showPanel={showFeedback}
      setShowPanel={setShowFeedback}
      handleClose={() => {
        if(!response && !msg.interactions.survey) postSurvey(msg, "closed")
      }}
    >
      <MessageFeedbackWrapper>
        {!response ?
          <>
            <MessageFeedbackTitleRow>
              <ButtonText>Was this relevant to you?</ButtonText>
              <ExitIconButton
                color="#4D4C4C"
                bgColor="#FFFFFF"
                onClick={() => {
                  MobileEventHandler.analytics("message_feedback_close")
                  if(!response && !msg.interactions.survey) postSurvey(msg, "closed");
                  setShowFeedback(false);
                }}/>
            </MessageFeedbackTitleRow>
            <ThumbsRow>
              <ThumbsButton
                onClick={() => {
                  MobileEventHandler.analytics("message_feedback_positive")
                  postSurvey(msg, ThumbFeedback.Up);
                  setResponse(ThumbFeedback.Up)
              }}>
                <Thumbs direction="up" color="#000000"/>
              </ThumbsButton>
              <ThumbsButton
                onClick={() => {
                  MobileEventHandler.analytics("message_feedback_negative")
                  postSurvey(msg, ThumbFeedback.Down);
                  setResponse(ThumbFeedback.Down)
              }}>
                <Thumbs direction="down" color="#000000"/>
              </ThumbsButton>
            </ThumbsRow>
          </>
          :
          null}
        {response === ThumbFeedback.Down && !moreFeedback ?
          <>
            <MessageFeedbackTitleRow>
              <ButtonText>Thank you for the feedback!</ButtonText>
              <ExitIconButton
                color="#4D4C4C"
                bgColor="#FFFFFF"
                onClick={() => {
                  setShowFeedback(false)
                }}/>
            </MessageFeedbackTitleRow>
            <MoreFeedback>
              <MessageFeedbackText>Help us improve our overall app experience!</MessageFeedbackText>
              <MoreFeedbackButton
                onClick={() => {
                  click(msg, "https://forms.gle/H7DKgnUHWLVAMRHV6");
                  /* moreFeedback response currently linked to external form
                  setShowMoreFeedback(true)
                  */
              }}>
                share feedback
              </MoreFeedbackButton>
            </MoreFeedback>
          </>
          :
          null}
        {response === ThumbFeedback.Up && moreFeedback === false ?
          <>
            <MessageFeedbackTitleRow>
              <ButtonText>Thank you for the feedback!</ButtonText>
                <ExitIconButton
                  color="#4D4C4C"
                  bgColor="#FFFFFF"
                  onClick={() => setShowFeedback(false)} />
            </MessageFeedbackTitleRow>
            <ThumbsRow>
              <ThumbsButton>
                <Thumbs direction="up" color="#6B6BD4"/>
              </ThumbsButton>
            </ThumbsRow>
          </>
          :
          null}
        {/* moreFeedback response currently linked to external form
        {moreFeedback ?
          <>
            <MessageFeedbackTitleRow>
              <ButtonText>Thank you for the feedback!</ButtonText>
              <ExitIconButton
                color="#4D4C4C"
                bgColor="#FFFFFF"
                onClick={() => {
                  setShowFeedback(false)
                }}/>
            </MessageFeedbackTitleRow>
            <MoreFeedback>
              <MessageFeedbackText>Why didn't this offer interest you?</MessageFeedbackText>
              <MoreFeedbackTextArea
                ref={moreFeedbackTextAreaRef}
                placeholder="write feedback here..."
                onChange={(e) => setMoreFeedbackResponse(e.target.value)}
              />
              <MoreFeedbackButton
                onClick={() => {
                  postSurvey(msg, ThumbFeedBack.Down, moreFeedbackResponse);
                  setShowFeedback(false);
              }}>
                submit feedback
              </MoreFeedbackButton>
            </MoreFeedback>
          </>
          :
          null}
          */}
      </MessageFeedbackWrapper>
    </SwipeToClosePanel>
  );
}

export default MessageFeedback;
