import { useCallback, useEffect, useState } from "react";
import { dateOptions, date_option_types, isTypeTransactionDateOption, TransactionDateOptionType } from "./filterOptions";
import FullDatePicker from "../Dashboard/modals/FullDatePicker";

import styled from "styled-components";
import { FlexRow, Left, Right } from "../Dashboard/panelsv2/generics";

import CalendarPurple from 'icons/calendar-purple.svg';
import { useNavigate, useSearchParams } from "react-router-dom";

const TransactionTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  padding: 16px;
`;

const TransactionBubble = styled.div<any>`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  white-space: nowrap;

  margin: 8px;
  padding: 8.22878px;
  background: #EFEDFF;
  border-radius: 82.2878px;
  border: 1px solid ${props => props.active ? ' #6B6BD4' : 'rgb(0,0,0,0)'};
  
  ${props => props.active ? 'color: #6B6BD4;' : ''}
`;

const TransactionDatePickWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 16px;

  gap: 33px;
  background: #FFFFFF;
  border: 2px solid #B8C3E9;
  border-radius: 15px;
`;

const DateRow = styled(FlexRow)`
  align-items: center; 
  width: 100%;
`;

const TransactionDateLabel = styled.div`
  border-right: 2px solid #B8C3E9;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  padding: 20px;
  width: 77px;
`;

const TransactionDateIcon = styled.img`
  margin-right: 13.5px;
`;

function TransactionDateRange() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [startDate, setStartDate] = useState(new Date(params.get(`startDate`) || 0));
  const [endDate, setEndDate] = useState(new Date(params.get(`endDate`) || new Date()));
  const [dateRange, setDateRange] = useState("Custom");

  useEffect(() => {
    const p: {[id: string]: string | null} = {
      dateRange: params.get('dateRange'),
      startDate: params.get('startDate'),
      endDate: params.get('endDate'),
    };

    Object.keys(p).forEach(key => {
      const v = p[key];
      if (v) p[key] = decodeURIComponent(v);
    });

    if (p.dateRange) setDateRange(p.dateRange);
    if (p.startDate) setStartDate(new Date(p.startDate));
    if (p.endDate) setEndDate(new Date(p.endDate));
    if (!p.dateRange && !p.startDate && !p.endDate) setDateRange("All Time");
  }, [params]);

  useEffect(() => {
    if (dateRange && isTypeTransactionDateOption(dateRange)) {
      const [start, end] = dateOptions[dateRange]();
      setStartDate(start);
      setEndDate(end);
    }
  }, [dateRange]);

  const getQueryStr = useCallback((q: string, date_option: TransactionDateOptionType) => {
    params.delete(`startDate`);
    params.delete(`endDate`);
    params.get(q) ? params.set(q, encodeURIComponent(date_option)) : params.append(q, encodeURIComponent(date_option));
    return params.toString();
  }, [params]);

  const setCustomStartDate = useCallback((date: Date) => {
    const newParams = new URLSearchParams(params.toString());
    newParams.delete(`dateRange`);
    newParams.delete(`startDate`);
    newParams.append(`startDate`, date.toISOString().split('T')[0].replaceAll('-', '/'));
    navigate(`/purchases/filter/date-range?${newParams.toString()}`);
    setStartDate(date);
    setDateRange("Custom");
  }, [navigate, params]);

  const setCustomEndDate = useCallback((date: Date) => {
    const newParams = new URLSearchParams(params.toString());
    newParams.delete(`dateRange`);
    newParams.delete(`endDate`);
    newParams.append(`endDate`, date.toISOString().split('T')[0].replaceAll('-', '/'));
    navigate(`/purchases/filter/date-range?${newParams.toString()}`);
    setEndDate(date);
    setDateRange("Custom");
  }, [navigate, params]);

  return <>
    <TransactionTitle>Presets</TransactionTitle>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        date_option_types.map((date_option: TransactionDateOptionType) => <TransactionBubble
          key={`dateOptions_${date_option}`}
          active={date_option === dateRange}
          onClick={() => navigate(`/purchases/filter/date-range?${getQueryStr('dateRange', date_option)}`)}
        >
          {date_option}
        </TransactionBubble> )
      }
    </div>
    <TransactionTitle>Custom date range</TransactionTitle>
    <TransactionDatePickWrapper>
      <DateRow>
        <Left style={{ display: 'flex', alignItems: 'center' }}>
          <TransactionDateLabel>
            From:
          </TransactionDateLabel>
          <FullDatePicker date={startDate} setDate={setCustomStartDate}/>
        </Left>
        <Right>
          <TransactionDateIcon src={CalendarPurple} alt="Start Date"/>
        </Right>
      </DateRow>
    </TransactionDatePickWrapper>
    <TransactionDatePickWrapper>
      <DateRow>
        <Left style={{ display: 'flex', alignItems: 'center' }}>
          <TransactionDateLabel>
            To:
          </TransactionDateLabel>
          <FullDatePicker date={endDate} setDate={setCustomEndDate}/>
        </Left>
        <Right>
          <TransactionDateIcon src={CalendarPurple} alt="End Date"/>
        </Right>
      </DateRow>
    </TransactionDatePickWrapper>
  </>;
}

export default TransactionDateRange;
