import styled from "styled-components";
import Lottie from "react-lottie";
import confettiData from "./data/confetti";

const ConfettiWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 35.28025rem;
  background-color: transparent;
  pointer-events: none;
  z-index: 98;
`;

function RedirectPageConfetti({ setShowConfetti }){
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: confettiData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return(
    <ConfettiWrapper>
      <Lottie
        options={animationOptions}
        width={'100vw'}
        height={'100%'}
        isClickToPauseDisabled={true}
      />
    </ConfettiWrapper>
  );
}

export default RedirectPageConfetti;
