import { UskoCategory } from "amazon";

export interface SortReturn {
  labels: UskoCategory[],
  firstData: number[],
  secondData: number[]
}

export type MapEntry = [ UskoCategory, number, number ];

export type SortFuncs = {
  [id: string] : (a : MapEntry, b : MapEntry) => number
}

export const srtFuncs : SortFuncs = {
  "Spending (Starting Year) ↓" : (a, b) => {
    return b[1] - a[1];
  },
  "Spending (Starting Year) ↑" : (a, b) => {
    return a[1] - b[1];
  },
  "Spending (Ending Year) ↓" : (a, b) => {
    return b[2] - a[2];
  },
  "Spending (Ending Year) ↑" : (a, b) => {
    return a[2] - b[2];
  },
  "Category ↓" : (a, b) => {
    return a[0].localeCompare(b[0]);
  },
  "Category ↑" : (a, b) => {
    return b[0].localeCompare(a[0]);
  },
  "Change ↓" : (a, b) => {
    let v1 = (a[2] - a[1])/ a[1];
    let v2 = (b[2] - b[1])/ b[1];
    v1 = isNaN(v1) ? 0 : v1;
    v2 = isNaN(v2) ? 0 : v2;
    let output = v1 - v2;
    output = isNaN(output) ? 0 : output;
    return output;
  },
  "Change ↑" : (a, b) => {
    let v1 = (a[2] - a[1])/ a[1];
    let v2 = (b[2] - b[1])/ b[1];
    v1 = isNaN(v1) ? 0 : v1;
    v2 = isNaN(v2) ? 0 : v2;
    let output = v2 - v1;
    output = isNaN(output) ? 0 : output;
    return output;
  },
}