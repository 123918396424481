import styled from "styled-components";

export const ViewWrapper = styled.div`
  padding-top: 20px;
`;

export const ChartTitle = styled.div`
  margin: 16px;

  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: -0.5px;
`;